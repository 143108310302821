import React, { useState } from 'react';
import {
    FormControl,
    useToast,
    Flex,
    Box,
    FormLabel,
    Input,
    Text,
    Button,
    VStack,
    Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Icon,
} from '@chakra-ui/react';
import { FaCamera } from 'react-icons/fa';
import axiosInstance from '../../components/axiosInstance';

const PFPEdit = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        const reader = new FileReader();
        reader.onload = () => {
            setPreviewImage(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const handleUpload = async () => {
        const authToken = localStorage.getItem('auth_token');
        if (!selectedFile) return;
        const uploadFormData = new FormData();
        uploadFormData.append('profilePicture', selectedFile);
        setIsLoading(true);
        try {
            await axiosInstance.post('/user/upload-profile-picture', uploadFormData, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                },
            });
            toast({
                title: 'Profile Picture Updated',
                description: 'Your profile picture has been successfully updated.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            onClose();
        } catch (error) {
            console.error('Error uploading file:', error);
            toast({
                title: 'Error',
                description: 'There was an error uploading the profile picture.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Button
                leftIcon={<Icon as={FaCamera} />}
                colorScheme="green"
                onClick={onOpen}
                size="xs"
                variant="outline"
            >
                Change Profile Picture
            </Button>

            <Modal isOpen={isOpen} onClose={onClose} size="lg">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Change Profile Picture</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={6}>
                            {previewImage ? (
                                <Image
                                    src={previewImage}
                                    alt="Preview"
                                    borderRadius="full"
                                    boxSize="200px"
                                    objectFit="cover"
                                    mb={4}
                                />
                            ) : (
                                <Box
                                    borderRadius="full"
                                    boxSize="200px"
                                    bg="green.50"
                                    display="flex"
                                    alignItems="center"
                                    border={"2px"}
                                    borderColor={"green.100"}
                                    justifyContent="center"
                                    mb={4}
                                >
                                    <Icon as={FaCamera} boxSize="50px" color="green.500" />
                                </Box>
                            )}
                            <FormControl>
                                <Input
                                    id="pfp-file-upload"
                                    type="file"
                                    onChange={handleFileChange}
                                    accept="image/*"
                                    hidden
                                />
                                <FormLabel htmlFor="pfp-file-upload" cursor="pointer">
                                    <Button
                                        leftIcon={<Icon as={FaCamera} />}
                                        colorScheme="green"
                                        size="lg"
                                        variant="outline"
                                        width="full"
                                        as="span"
                                    >
                                        Choose Image
                                    </Button>
                                </FormLabel>
                            </FormControl>
                            <Button
                                colorScheme="green"
                                size="lg"
                                onClick={handleUpload}
                                isLoading={isLoading}
                                disabled={!selectedFile}
                                width="full"
                            >
                                Upload
                            </Button>
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default PFPEdit;