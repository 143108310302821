import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import Dashboard from './pages/Employer/Dashboard';
import Login from './pages/Welcome Pages/Login';
import Marketing from './pages/Welcome Pages/Marketing';
import JobListings from './pages/Employer/JobListings';
import { extendTheme, ChakraProvider } from '@chakra-ui/react';
import Settings from './pages/Employer/Settings';
import SignUp from './pages/Welcome Pages/SignUp';
import JobCreation from './pages/Employer/Job Creation/JobCreation';
import EditJob from './pages/Employer/EditJob';
import JobBoard from './pages/Job Seeker/JobBoard';
import ProtectedRoute from './components/ProtectedRoute';
import axios from 'axios';
import CreateCompany from './pages/Employer/CreateCompany';
import CompleteSignUp from './pages/Welcome Pages/CompleteSignUp';
import RecruiterToolBox from './pages/Recruiter/RecruiterToolBox';
import Job from './pages/Recruiter/Job';
import JobSeekerHome from './pages/Job Seeker/JobSeekerHome';
import Applicants from './pages/Employer/Applicants';
import Talent from './pages/Employer/TalentPool';
import JobSeekerForm from './components/JobSeekerForm';
import SkillAssessment from './pages/Employer/Job Creation/SkillAssessment';
import TakeAssessment from './pages/Job Seeker/TakeAssessment';
import LiveFeed from './pages/Job Seeker/LiveFeed';
import Profile from './pages/Job Seeker/Profile';
import AccountPlan from './pages/Employer/AccountPlan';
import EvaluationsList from './pages/Employer/EvaluationsList';
import WithSidebarLayout from './components/WithSideBarLayout';
import WithHeaderLayout from './components/WithHeaderLayout';
import PointsPoolManagement from './pages/Admin/PointsPoolManagement';
import TalentSignUp from './pages/Welcome Pages/TalentSignUp';
import ForgotPassword from './pages/Welcome Pages/ForgotPassword';
import CompanyJobs from './pages/Employer/CompanyJobs';
import ReferralManagement from './pages/Admin/ReferralManagement';
import ManualUpload from './pages/Employer/ManualUpload';
import AddTalent from './pages/Employer/AddTalent';
import TalentInvite from './pages/Welcome Pages/TalentInvite';
import ResetPassword from './pages/Welcome Pages/ResetPassword';
import ManageTalent from './pages/Employer/ManageTalent';
import AIinterview from './pages/Job Seeker/preInterview';
import JobPage from './pages/[jobId]';
import PrivacyPolicy from './pages/Welcome Pages/PrivacyPolicy';
import TermsOfService from './pages/Welcome Pages/TermsOfService';
import DirectResumeUpload from './components/DirectResumeUpload';
import TokenInfo from './pages/Welcome Pages/TokenInfo';
import { UserSignUp } from './pages/Welcome Pages/UserSignUp';
import UserLogin from './pages/Welcome Pages/UserLogin';
import UserHome from './pages/User/UserHome';
import AddResume from './pages/User/UserResume';
import PublicProfile from './pages/User/PublicProfile';
import { useAtom } from 'jotai';
import { socketAtom } from './socketAtom';
import { io } from 'socket.io-client';
import AddBusiness from './pages/User/AddBusiness';
import OpenPositions from './pages/Business/OpenPositions';
import CreateProject from './pages/Business/Freelance/CreateProject';
import OpenProjects from './pages/Business/Freelance/OpenProjects';
import MessagingPage from './components/MessagingPage';
import FreelancerProposals from './pages/Business/Freelance/Proposals';
import UserJobBoard from './pages/User/UserJobBoard';
import UserJobListings from './pages/User/UserJobListings';
import OrganizationsPage from './pages/User/OrganizationsPage';
import ActiveProjects from './pages/Business/Freelance/ActiveContracts';
import ClosedProjects from './pages/Business/Freelance/ClosedContracts';
import SkillEvaluation from './pages/User/SkillEvaluation';


const theme = extendTheme({
  components: {
    Heading: {
      baseStyle: {
        fontFamily: "'Poppins', sans-serif",
      },
    },
    Text: {
      baseStyle: {
        fontFamily: "'Poppins', sans-serif",
      },
    },
    Link: {
      baseStyle: {
        fontFamily: "'Poppins', sans-serif",
      },
    },
    Button: {
      baseStyle: {
        fontFamily: "'Poppins', sans-serif",
      },
    },
  },
  colors: {
    brand: {
      pink: '#EF476F',
      yellow: '#FFD166',
      green: '#01BF02',
      blue: '#118AB2',
      darkBlue: 'black',
    },
  },
  breakpoints: {
    xs: "20em",
    sm: "40em",
    md: "68em",
    lg: "92em",
    xl: "124em",
  },
});

if (localStorage.getItem('auth_token')) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('auth_token');
}

function App() {

  const [socket, setSocket] = useAtom(socketAtom);


  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_API_URL, { autoConnect: false });
    newSocket.connect();
    setSocket(newSocket);
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Router>
        {/* <Header onLogout={handleLogout} /> */}
        <Routes>
          <Route path="/signup" element={<SignUp />} />
          <Route path="/user-signup" element={<UserSignUp />} />
          <Route path="/talentsignup" element={<TalentSignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/user-login" element={<UserLogin />} />
          <Route path="/upload-widget" element={<DirectResumeUpload />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/talent-invite/:jobSeekerId" element={<TalentInvite />} />
          <Route path="/" element={<Marketing />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/tos" element={<TermsOfService />} />
          <Route path="/token-info" element={<TokenInfo />} />
          <Route path="/complete-signup" element={<CompleteSignUp />} />
          <Route path="/job/:jobId/:referralId" element={<WithHeaderLayout><Job /></WithHeaderLayout>} />
          <Route path="/job/:jobId" element={<WithHeaderLayout><Job /></WithHeaderLayout>} />
          <Route path="/SSRjob/:jobId" element={<WithHeaderLayout><JobPage /></WithHeaderLayout>} />
          <Route path="/feed" element={<LiveFeed />} />
          <Route path="/:companyName" element={<CompanyJobs />} />
          <Route path="/:businessName" element={<CompanyJobs />} />

          {/* Protected Routes */}
          <Route
            path="/create-company"
            element={<ProtectedRoute><CreateCompany /></ProtectedRoute>}
          />
          <Route
            path="/create-business"
            element={<ProtectedRoute><AddBusiness /></ProtectedRoute>}
          />
          <Route
            path="/select-plan"
            element={<ProtectedRoute><AccountPlan /></ProtectedRoute>}
          />
          <Route
            path="/edit-jobseeker"
            element={<ProtectedRoute><JobSeekerForm /></ProtectedRoute>}
          />
          <Route
            path="/home"
            element={<ProtectedRoute><JobSeekerHome /></ProtectedRoute>}
          />
          <Route
            path="/user-home"
            element={<ProtectedRoute><UserHome /></ProtectedRoute>}
          />
          <Route
            path="/add-resume"
            element={<ProtectedRoute><AddResume /></ProtectedRoute>}
          />
          <Route
            path="/toolbox"
            element={<ProtectedRoute><RecruiterToolBox /></ProtectedRoute>}
          />
          <Route
            path="/dashboard"
            element={<ProtectedRoute><WithSidebarLayout><Dashboard /></WithSidebarLayout></ProtectedRoute>}
          />
          <Route
            path="/organizations"
            element={<ProtectedRoute><WithSidebarLayout><OrganizationsPage /></WithSidebarLayout></ProtectedRoute>}
          />
          <Route
            path="/job-creation"
            element={<ProtectedRoute><WithSidebarLayout><JobCreation /></WithSidebarLayout></ProtectedRoute>}
          />
          <Route
            path="/joblistings"
            element={<ProtectedRoute><WithSidebarLayout><JobListings /></WithSidebarLayout></ProtectedRoute>}
          />
          <Route
            path="/job-listings/:businessId"
            element={<ProtectedRoute><WithSidebarLayout><UserJobListings /></WithSidebarLayout></ProtectedRoute>}
          />
          <Route
            path="/create-project"
            element={<ProtectedRoute><WithSidebarLayout><CreateProject /></WithSidebarLayout></ProtectedRoute>}
          />
          <Route
            path="/open-projects"
            element={<ProtectedRoute><WithSidebarLayout><OpenProjects /></WithSidebarLayout></ProtectedRoute>}
          />
          <Route
            path="/active-projects"
            element={<ProtectedRoute><WithSidebarLayout><ActiveProjects /></WithSidebarLayout></ProtectedRoute>}
          />
          <Route
            path="/closed-projects"
            element={<ProtectedRoute><WithSidebarLayout><ClosedProjects /></WithSidebarLayout></ProtectedRoute>}
          />
          <Route
            path="/proposals"
            element={<ProtectedRoute><WithSidebarLayout><FreelancerProposals /></WithSidebarLayout></ProtectedRoute>}
          />
          <Route
            path="/messaging"
            element={<ProtectedRoute><WithSidebarLayout><MessagingPage /></WithSidebarLayout></ProtectedRoute>}
          />
          <Route
            path="/open-positions"
            element={<ProtectedRoute><WithSidebarLayout><OpenPositions /></WithSidebarLayout></ProtectedRoute>}
          />
          <Route
            path="/talent-pool"
            element={<ProtectedRoute><WithSidebarLayout><Talent /></WithSidebarLayout></ProtectedRoute>}
          />
          <Route
            path="/talent/:jobSeekerId"
            element={<ProtectedRoute><Profile /></ProtectedRoute>}
          />
          <Route
            path="/user/:userId"
            element={<ProtectedRoute><PublicProfile /></ProtectedRoute>}
          />
          <Route
            path="/applicants/:jobId"
            element={<ProtectedRoute><WithSidebarLayout><Applicants /></WithSidebarLayout></ProtectedRoute>}
          />
          <Route
            path="/skill-assessment/:jobId"
            element={<ProtectedRoute><WithSidebarLayout><SkillAssessment /></WithSidebarLayout></ProtectedRoute>}
          />
          <Route
            path="/take-assessment/:applicantId"
            element={<ProtectedRoute><TakeAssessment /></ProtectedRoute>}
          />
          <Route
            path="/ai-interview/:applicantId"
            element={<ProtectedRoute><AIinterview /></ProtectedRoute>}
          />
          <Route
            path="/manage-talent"
            element={<ProtectedRoute><WithSidebarLayout><ManageTalent /></WithSidebarLayout></ProtectedRoute>}
          />
          <Route
            path="/settings"
            element={<ProtectedRoute><WithSidebarLayout><Settings /></WithSidebarLayout></ProtectedRoute>}
          />
          <Route
            path="/add-talent"
            element={<ProtectedRoute><WithSidebarLayout><AddTalent /></WithSidebarLayout></ProtectedRoute>}
          />
          <Route
            path="/manual-upload"
            element={<ProtectedRoute><WithSidebarLayout><ManualUpload /></WithSidebarLayout></ProtectedRoute>}
          />
          <Route
            path="/edit-job/:jobId"
            element={<ProtectedRoute><WithSidebarLayout><EditJob /></WithSidebarLayout></ProtectedRoute>}
          />
          <Route
            path="/reset-password"
            element={<ProtectedRoute><ResetPassword /></ProtectedRoute>}
          />
          <Route
            path="/evaluations/:jobId"
            element={<ProtectedRoute><WithSidebarLayout><EvaluationsList /></WithSidebarLayout></ProtectedRoute>}
          />
          <Route
            path="/Jobboard"
            element={<ProtectedRoute><JobBoard /></ProtectedRoute>}
          />
          <Route
            path="/skill-evaluation"
            element={<ProtectedRoute><SkillEvaluation /></ProtectedRoute>}
          />
          <Route
            path="/UserJobboard"
            element={<ProtectedRoute><UserJobBoard /></ProtectedRoute>}
          />
          <Route
            path="/pointspool"
            element={<ProtectedRoute><PointsPoolManagement /></ProtectedRoute>}
          />
          <Route
            path="/referral-management"
            element={<ProtectedRoute><ReferralManagement /></ProtectedRoute>}
          />

          {/* Add the wildcard route here */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;

// 3.224.2.210
// JobJar.ai