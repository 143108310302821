import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../components/axiosInstance';
import {
    Box,
    Flex,
    useToast,
    Text,
    Button,
    List,
    ListItem,
    Heading,
    Icon,
    Grid,
    Badge,
    Avatar,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    IconButton,
    Stack,
    Divider,
    Wrap,
} from '@chakra-ui/react';
import { FaFileAlt, FaCheck, FaTimes, FaArrowLeft } from 'react-icons/fa';
import Background from '../../../assets/Background.webp';
import { faMessage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function OpenProjects() {
    const toast = useToast();
    const [openProjects, setOpenProjects] = useState([]);
    const [selectedProposal, setSelectedProposal] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedProject, setSelectedProject] = useState(null);
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchOpenProjects = async () => {
            try {
                const response = await axiosInstance.get('/open-projects');
                setOpenProjects(response.data);
                setLoading(false);
            } catch (error) {
                toast({
                    title: 'Error fetching open projects.',
                    description: 'Unable to load open projects. Please try again later.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        };

        fetchOpenProjects();
    }, [toast]);

    const handleProjectClick = (project) => {
        setSelectedProject(project);
        setSelectedProposal(null);
    };

    const handleProposalClick = (proposal) => {
        setSelectedProposal(proposal);
    };

    const handleBackClick = () => {
        setSelectedProposal(null);
        setActiveTabIndex(1);
    };

    const handleMessageClick = (projectRoomIdentifier) => {
        navigate(`/messaging?projectRoom=${projectRoomIdentifier}`);
    };

    const handleAcceptProposal = async (proposalId) => {
        console.log('proposalId', proposalId);
        try {
            await axiosInstance.post(`/proposals/${proposalId}/accept`);
            toast({
                title: 'Proposal Accepted',
                description: 'The proposal has been accepted.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            // Refresh the project details after accepting a proposal
            const response = await axiosInstance.get(`/open-projects`);
            setSelectedProject(response.data);
        } catch (error) {
            toast({
                title: 'Error accepting proposal',
                description: 'An error occurred while accepting the proposal. Please try again.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleRejectProposal = async (proposalId) => {
        try {
            await axiosInstance.post(`/proposals/${proposalId}/reject`);
            toast({
                title: 'Proposal Rejected',
                description: 'The proposal has been rejected.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            // Refresh the project details after rejecting a proposal
            const response = await axiosInstance.get(`/open-projects/${selectedProject._id}`);
            setSelectedProject(response.data);
        } catch (error) {
            toast({
                title: 'Error rejecting proposal',
                description: 'An error occurred while rejecting the proposal. Please try again.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    return (
        <Flex direction="column" minH="100vh" w="100%" pl="300px" bgImage={Background} bgSize="cover" bgRepeat="no-repeat" bgPosition="center">
            <Flex flex="1" w="100%" p={8}>
                <Box w="30%" bg="white" borderRadius="md" p={4} boxShadow="lg" h={"fit-content"} overflowY="auto">
                    <Heading size="md" mb={4}>Open Projects</Heading>
                    <Divider mb={4} />
                    {loading ? (
                        <Text>Loading...</Text>
                    ) : openProjects.length > 0 ? (
                        <List>
                            {openProjects?.map((project) => (
                                <ListItem
                                    key={project._id}
                                    onClick={() => handleProjectClick(project)}
                                    cursor="pointer"
                                    borderRadius="md"
                                    mb={1}
                                    p={4}
                                    _hover={{ bg: "gray.100" }}
                                    bg={selectedProject?._id === project._id ? "gray.100" : "white"}
                                >
                                    <Heading size="sm">{project.name}</Heading>
                                    <Text fontSize={"sm"} color="gray.500" mt={1}>{project.description}</Text>
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <Text>No open projects found.</Text>
                    )}
                </Box>
                <Box flex="1" ml={8} bg="white" borderRadius="md" p={8} boxShadow="lg" h={"fit-content"} overflowY="auto">
                    {selectedProject ? (
                        selectedProposal ? (
                            <Box>
                                <Flex align="start" mb={4}>
                                    <IconButton
                                        icon={<FaArrowLeft />}
                                        aria-label="Go back"
                                        onClick={handleBackClick}
                                        mr={4}
                                    />
                                    <Flex w={"100%"} justify="space-between" align="start">
                                        <Box>
                                            <Heading size="lg">{selectedProposal.firstName}'s Proposal</Heading>
                                            <Text mb={4}>{selectedProposal.introduction}</Text>
                                        </Box>
                                        <Stack direction="column" spacing={4}>
                                            <Flex w={"100%"} justify={"flex-end"}>
                                                <Text fontSize="2xl" fontWeight="bold" color="blue.500">
                                                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(selectedProposal.totalCost)}
                                                </Text>
                                            </Flex>
                                            <Stack direction="row" spacing={4}>
                                                <IconButton
                                                    icon={<FaCheck />}
                                                    colorScheme="green"
                                                    aria-label="Accept Proposal"
                                                    onClick={() => handleAcceptProposal(selectedProposal._id)}
                                                />
                                                <IconButton
                                                    icon={<FaTimes />}
                                                    colorScheme="red"
                                                    aria-label="Reject Proposal"
                                                    onClick={() => handleRejectProposal(selectedProposal._id)}
                                                />
                                                <Button
                                                    leftIcon={<FontAwesomeIcon icon={faMessage} />}
                                                    colorScheme="blue"
                                                    onClick={() => handleMessageClick(selectedProposal.connectionId)}
                                                >
                                                    Message
                                                </Button>
                                            </Stack>
                                        </Stack>
                                    </Flex>
                                </Flex>
                                <Heading size="md" mb={2}>Milestones</Heading>
                                {selectedProposal?.milestones?.map((milestone, index) => (
                                    <Box ml={4} key={index} mb={4}>
                                        <Heading size="sm">{milestone.title}</Heading>
                                        <Text mb={2}>{milestone.description}</Text>
                                        <Flex w={"100%"} justify={"flex-end"}>
                                            <Stack direction="row" spacing={4}>
                                                <Box>
                                                    <Text fontSize="sm" fontWeight="bold" color="gray.500">Start Date</Text>
                                                    <Text>{new Date(milestone.startDate).toLocaleDateString()}</Text>
                                                </Box>
                                                <Box>
                                                    <Text fontSize="sm" fontWeight="bold" color="gray.500">End Date</Text>
                                                    <Text>{new Date(milestone.endDate).toLocaleDateString()}</Text>
                                                </Box>
                                                <Box>
                                                    <Text fontSize="sm" fontWeight="bold" color="gray.500">Cost</Text>
                                                    <Text>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(milestone.cost)}</Text>
                                                </Box>
                                            </Stack>
                                        </Flex>
                                    </Box>
                                ))}
                                <Heading size="md" mb={2}>Rationale</Heading>
                                <Text mb={4}>{selectedProposal.rationale}</Text>
                                <Heading size="md" mb={2}>Financials</Heading>

                                <Box>
                                    <Text fontSize="sm" fontWeight="bold" color="gray.500">Total Cost</Text>
                                    <Text>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(selectedProposal.totalCost)}
                                    </Text>
                                </Box>

                            </Box>
                        ) : (
                            <Tabs index={activeTabIndex} onChange={(index) => setActiveTabIndex(index)} id="tabs">
                                <TabList>
                                    <Tab>Details</Tab>
                                    <Tab>Proposals</Tab>
                                </TabList>
                                <TabPanels>
                                    <TabPanel>
                                        <Heading size="lg" mb={4}>{selectedProject.name}</Heading>
                                        <Text fontSize="md" color="gray.600" mb={6}>{selectedProject.description}</Text>
                                        <Stack direction="row" spacing={4} mb={6}>
                                            <Box>
                                                <Text fontSize="sm" fontWeight="bold" color="gray.500">Duration</Text>
                                                <Text>{selectedProject.duration}</Text>
                                            </Box>
                                            <Box>
                                                <Text fontSize="sm" fontWeight="bold" color="gray.500">Budget Range</Text>
                                                <Text>{selectedProject.budgetRange}</Text>
                                            </Box>
                                            <Box>
                                                <Text fontSize="sm" fontWeight="bold" color="gray.500">Required JobJar Tokens</Text>
                                                <Text>{Number(selectedProject.tokensRequired).toLocaleString()}</Text>
                                            </Box>
                                        </Stack>
                                        <Box>
                                            <Text fontSize="sm" fontWeight="bold" color="gray.500" mb={2}>Skills</Text>
                                            <Wrap>
                                                {selectedProject?.skills?.map((skill) => (
                                                    <Badge key={skill} colorScheme="blue" fontSize="sm">{skill}</Badge>
                                                )) || []}
                                            </Wrap>
                                        </Box>
                                    </TabPanel>
                                    <TabPanel>
                                        <Heading size="lg" mb={4}>Proposals</Heading>
                                        {selectedProject.proposals && selectedProject.proposals.length > 0 ? (
                                            selectedProject?.proposals?.map((proposal) => (
                                                <Box
                                                    key={proposal._id}
                                                    bg="gray.50"
                                                    p={4}
                                                    borderRadius="md"
                                                    mb={6}
                                                    cursor="pointer"
                                                    onClick={() => handleProposalClick(proposal)}
                                                >
                                                    <Flex w={"100%"} align="start" justify={"space-between"}>
                                                        <Flex align="center" mb={4}>
                                                            <Avatar name={proposal.firstName} src={proposal.PFP} size="md" mr={4} />
                                                            <Box>
                                                                <Text fontWeight="bold" fontSize="xl">{proposal.firstName}</Text>
                                                                <Text fontSize="md" color="gray.500">{proposal.freelancerId.title}</Text>
                                                            </Box>
                                                        </Flex>
                                                        <Text fontSize="2xl" fontWeight="bold" color="blue.500">
                                                            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(proposal.totalCost)}
                                                        </Text>
                                                    </Flex>
                                                    <Text mb={4}>{proposal.introduction}</Text>
                                                    <Flex justify="flex-end" align="center">
                                                        <Stack direction="row" spacing={4}>
                                                            <IconButton
                                                                icon={<FaCheck />}
                                                                colorScheme="green"
                                                                aria-label="Accept Proposal"
                                                                onClick={() => handleAcceptProposal(proposal._id)}
                                                            />
                                                            <IconButton
                                                                icon={<FaTimes />}
                                                                colorScheme="red"
                                                                aria-label="Reject Proposal"
                                                                onClick={() => handleRejectProposal(proposal._id)}
                                                            />
                                                            <Button
                                                                leftIcon={<FontAwesomeIcon icon={faMessage} />}
                                                                colorScheme="blue"
                                                                onClick={() => handleMessageClick(proposal.connectionId)}
                                                            >
                                                                Message
                                                            </Button>
                                                        </Stack>
                                                    </Flex>
                                                </Box>
                                            ))
                                        ) : (
                                            <Text>No proposals yet.</Text>
                                        )}
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        )
                    ) : (
                        <Flex justify="center" align="center" h="100%">
                            <Text fontSize="xl" color="gray.500">Select a project to view details</Text>
                        </Flex>
                    )}
                </Box>
            </Flex>
        </Flex>
    );
}

export default OpenProjects;