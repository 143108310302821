import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Box, Button, Divider, Flex, Heading, HStack, Text, useToast } from '@chakra-ui/react';
import axios from 'axios';

const stripePromise = loadStripe("pk_live_51MXrqLEDfGoNCpuzG4DPVOWnJaoNRusee6UxEgrMIAwAiylcZYMOcXjJysOwCvl2ed4I3XAtsmr87t9vAGBLlTHb006oEEfL6v");

// Component for handling card details input
const CardDetailsForm = ({ setStoredCard }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const toast = useToast();
    const token = localStorage.getItem('auth_token');

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        if (!stripe || !elements) {
            console.log('Stripe has not loaded');
            setIsSubmitting(false);
            return;
        }

        const card = elements.getElement(CardElement);

        try {
            const result = await stripe.createPaymentMethod({
                type: 'card',
                card: card,
            });

            if (result.error) {
                throw result.error;
            }

            const updateResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/talent/update-card`, {
                paymentMethodId: result.paymentMethod.id,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (updateResponse.data.success) {
                toast({
                    title: "Card updated successfully.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                setStoredCard(result.paymentMethod);
                card.clear();
            } else {
                // Handle the case where the server response was not successful
                toast({
                    title: "Update failed",
                    description: updateResponse.data.message || "Failed to update card information.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error('Error:', error);
            toast({
                title: "Error",
                description: error.response?.data?.message || "An error occurred. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
        setIsSubmitting(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <CardElement />
            <Flex w={"100%"} justify={"flex-end"}>
                <Button
                    size={"sm"}
                    mt={4}
                    bg={"#118AB2"}
                    color={"white"}
                    _hover={{ bg: "#01BF02" }}
                    type="submit"
                    isLoading={isSubmitting}
                    loadingText="Submitting"
                    disabled={isSubmitting}
                >
                    Add Card
                </Button>
            </Flex>
        </form>
    );
};

const BillingInformation = () => {
    const [storedCard, setStoredCard] = useState(null); // Renamed for clarity
    const token = localStorage.getItem('auth_token');

    // Function to retrieve stored card information
    const retrieveCard = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/talent/retrieve-card`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.data && response.data.length > 0) {
                // Assuming the response contains an array of cards
                setStoredCard(response.data[0]); // Set the first card as the stored card
            }
        } catch (error) {
            console.error('Error retrieving card:', error);
            // Handle errors (e.g., show a notification)
        }
    };

    useEffect(() => {
        retrieveCard();
    }, []); // Retrieve card information when the component mounts

    return (
        <Box p={5} boxShadow="md" bg="white" borderRadius="lg">
            <Heading size="md" mb={4}>Billing Information</Heading>
            <Divider my={3} />
            <Box position="relative" p={3} bg="gray.50" borderRadius="md" boxShadow="inner">
                {storedCard ? (
                    <Box
                        p={4}
                        bg="#118AB2"
                        borderRadius="md"
                        boxShadow="sm"
                        color="white"
                        mb={4}
                    >
                        <Text fontSize="sm" fontWeight="semibold">Card on File</Text>
                        <Box mt={2} p={2} bg="black" borderRadius="md">
                            <Text fontSize="xl">•••• •••• •••• {storedCard.card.last4}</Text>
                            <HStack>
                                <Text>{storedCard.card.brand.toUpperCase()}</Text>
                                <Text fontSize="md">Expires {storedCard.card.exp_month}/{storedCard.card.exp_year}</Text>
                            </HStack>
                        </Box>
                    </Box>
                ) : (
                    <Text mb={4}>No card on file.</Text>
                )}
                <Elements stripe={stripePromise}>
                    <CardDetailsForm setStoredCard={setStoredCard} />
                </Elements>
            </Box>
        </Box>
    );
};


export default BillingInformation;
