import React, { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, Select, Textarea } from '@chakra-ui/react';

const ConnectModal = ({ onClose, onSubmit }) => {
    const [reason, setReason] = useState('');
    const [comment, setComment] = useState('');

    const reasons = [
        'network', 'collaborate', 'get industry insights', 'get career guidance'
    ];

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({ reason, comment });
        onClose();
    };

    return (
        <Modal isOpen={true} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Connect with User</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={handleSubmit}>
                    <ModalBody>
                        <Select placeholder="Select a reason..." onChange={(e) => setReason(e.target.value)} required>
                            {reasons.map(r => <option key={r} value={r}>{r}</option>)}
                        </Select>
                        <Textarea placeholder="Additional Comment (optional)" value={comment} onChange={(e) => setComment(e.target.value)} />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" type="submit">Submit Connection Request</Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    );
};

export default ConnectModal;