import React, { useState, useEffect } from 'react';
import {
    VStack, Image, Spacer, Box, Divider, Flex, HStack, Heading, Link, Text, Select, Button, Textarea, Avatar, Stack,
} from '@chakra-ui/react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faList, faGears, faSortDown, faChartLine, faBuilding, faPeopleGroup, faFileContract, faBriefcase } from '@fortawesome/free-solid-svg-icons';
import axiosInstance from './axiosInstance';
import { FaList, FaUsers, FaPlus } from 'react-icons/fa';
import Icon from '../assets/White Jar Logo.png';
import Footer from './Footer';
import { socketAtom } from '../socketAtom';
import { useAtom } from 'jotai';

const ParentSideBar = () => {
    const navigate = useNavigate();
    const [selectedJobId, setSelectedJobId] = useState('');
    const [userId, setUserId] = useState('');
    const [generatedScript, setGeneratedScript] = useState('');
    const [typingStatus, setTypingStatus] = useState({});
    const location = useLocation();
    // If mostRecentMessage is meant to be an array of messages
    const [mostRecentMessages, setMostRecentMessages] = useState([]);
    const [projectRooms, setProjectRooms] = useState([]);
    const [socket] = useAtom(socketAtom);
    const [unreadCounts, setUnreadCounts] = useState({});
    const currentPath = location.pathname;
    const [companyInfo, setCompanyInfo] = useState({});
    const businessId = localStorage.getItem('businessId');
    const jobListingsPath = businessId ? `/job-listings/${businessId}` : '/organizations';

    useEffect(() => {
        const fetchProjectRoomsAndMessages = async () => {
            const token = localStorage.getItem('auth_token');
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };
            try {
                const roomsResponse = await axiosInstance.get('/projectRooms');
                const fetchedProjectRooms = roomsResponse.data.connectionIds;
                const messages = [];
                let unreadCountsTemp = {};
                for (let roomName of fetchedProjectRooms) {
                    try {
                        const response = await axiosInstance.post('/message-list/', { roomName }, { headers });
                        // Expecting the response to be directly the message object
                        if (response.data.latestMessage) messages.push(response.data.latestMessage);
                        unreadCountsTemp[roomName] = response.data.unreadCount;
                    } catch (error) {
                        console.error(`Error fetching message for room ${roomName}:`, error);
                    }

                }
                setMostRecentMessages(messages);
                setUnreadCounts(unreadCountsTemp);
                fetchedProjectRooms.forEach((projectRoom) => {
                    if (projectRoom) {
                        socket.emit('joinProjectRoom', { roomName: projectRoom });
                    }
                });

            } catch (error) {
                console.error('Error fetching project rooms and messages:', error);
            }
        };

        fetchProjectRoomsAndMessages();

    }, [socket]);

    const fetchBusiness = async () => {
        localStorage.getItem('businessId')
        if (businessId) {
            try {
                // Corrected to use `get` method and updated the endpoint to include `api`
                const response = await axiosInstance.get(`/business/${businessId}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
                    },
                });

                setCompanyInfo(response.data.business);

            } catch (error) {
                console.error(error); // It's a good practice to log the actual error for debugging
            }
        }
    };

    useEffect(() => {
        fetchBusiness();
    }, []);

    useEffect(() => {
        const typingTimers = {};

        const handleTyping = ({ roomName, senderId }) => {
            if (senderId === userId) return;

            setTypingStatus(prev => ({ ...prev, [roomName]: true }));

            if (typingTimers[roomName]) {
                clearTimeout(typingTimers[roomName]);
            }

            typingTimers[roomName] = setTimeout(() => {
                setTypingStatus(prev => ({ ...prev, [roomName]: false }));
                delete typingTimers[roomName];
            }, 2000);
        };

        socket.on('typing', handleTyping);

        return () => {
            socket.off('typing', handleTyping);
            Object.values(typingTimers).forEach(clearTimeout);
        };
    }, [socket, userId]);

    useEffect(() => {
        const handleNewMessage = (message) => {
            if (message.senderId !== userId) {
                setUnreadCounts(prevCounts => ({
                    ...prevCounts,
                    [message.roomName]: (prevCounts[message.roomName] || 0) + 1
                }));
            }
        };

        socket.on('newProjectMessage', handleNewMessage);

        return () => {
            socket.off('newProjectMessage', handleNewMessage);
        };
    }, [socket, userId, projectRooms]);

    useEffect(() => {
        socket.on('newProjectMessage', ({ roomName, text, senderId, senderPFP, senderFirstName, recipientId, connectionId, fileUrl, fileType }) => {
            if (!isMessagingPageActive()) {
                setProjectRooms((projectRooms) =>
                    projectRooms?.map((projectRoom) => {
                        if (projectRoom.roomName === roomName) {
                            if (senderId !== userId && !isMessagingPageActive(`/messaging?projectRoom=${projectRoom}`)) {
                                setUnreadCounts((prevCounts) => ({
                                    ...prevCounts,
                                    [roomName]: (prevCounts[roomName] || 0) + 1,
                                }));
                            }
                            return {
                                ...projectRoom,
                                mostRecentMessage: { text, senderId, senderPFP, senderFirstName, recipientId, connectionId, fileUrl, fileType },
                            };
                        }
                        return projectRoom;
                    })
                );
            }
        });

        return () => {
            socket.off('newProjectMessage');
        };
    }, [socket, userId, location]);

    const handleMessageClick = (projectRoomIdentifier) => {
        console.log('Navigating to messaging with roomName:', projectRoomIdentifier);

        setUnreadCounts((prevCounts) => ({
            ...prevCounts,
            [projectRoomIdentifier]: 0,
        }));

        markMessagesAsRead(projectRoomIdentifier);

        navigate(`/messaging?projectRoom=${projectRoomIdentifier}`);
    };

    const markMessagesAsRead = async (roomName) => {
        try {
            await axiosInstance.post('/messages/markAsRead', { roomName });
            setUnreadCounts((prevCounts) => ({
                ...prevCounts,
                [roomName]: 0,
            }));
        } catch (error) {
            console.error('Error marking messages as read:', error);
        }
    };

    const isActive = (...paths) => {
        return paths.some(path => {
            if (path.includes(':')) {
                // Handle dynamic paths
                const baseRoute = path.split('/:')[0];
                return currentPath.startsWith(baseRoute);
            }
            return currentPath === path;
        });
    };

    const isMessagingPageActive = () => {
        const searchParams = new URLSearchParams(location.search);
        const projectRoom = searchParams.get('projectRoom');

        return projectRooms.some(room =>
            location.pathname === '/messaging' &&
            projectRoom === room
        );
    };

    const TypingIndicator = () => {
        return (
            <Flex ml={2} mb={4} alignItems="center">
                <Flex>
                    <Box
                        as="span"
                        w={1}
                        h={1}
                        bg="#01bf02"
                        borderRadius="full"
                        mr={1}
                        animation="bounce 1s infinite"
                    />
                    <Box
                        as="span"
                        w={1}
                        h={1}
                        bg="#01bf02"
                        borderRadius="full"
                        mr={1}
                        animation="bounce 1s infinite"
                        style={{ animationDelay: '0.2s' }}
                    />
                    <Box
                        as="span"
                        w={1}
                        h={1}
                        bg="#01bf02"
                        borderRadius="full"
                        animation="bounce 1s infinite"
                        style={{ animationDelay: '0.4s' }}
                    />
                </Flex>
            </Flex>
        );
    };

    const handleNavigateHome = async () => {
        navigate('/user-home')
    };

    const handleLogout = async () => {
        const refreshToken = localStorage.getItem('refresh_token');
        if (refreshToken) {
            try {
                // Notify the server to invalidate the refresh token
                await axiosInstance.post('/logout', { refreshToken });
            } catch (error) {
                console.error('Error during logout:', error);
            }
        }

        // Remove tokens and user information from localStorage
        localStorage.removeItem('auth_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('user_role');
        localStorage.removeItem('userId');
        localStorage.removeItem('jobId');
        localStorage.removeItem('role');
        localStorage.removeItem('email');
        localStorage.removeItem('businessId');
        localStorage.removeItem('referralRole');
        localStorage.clear();
        navigate('/login')
    };

    return (
        <HStack w={"300px"} align={"flex-start"} position="fixed" height="100vh">
            <VStack
                bg="black"
                p={4}
                spacing={4}
                alignItems="center"
                width="70px"
                color="white"
                height="100vh"
                position="fixed"
                left={0}
                top={0}
            >
                <Image m={2} src={Icon} cursor={"pointer"} onClick={handleNavigateHome} />

                <Divider />

                <Flex
                    justify="center"
                    w="100%"
                    p={3}
                    cursor="pointer"
                    borderRadius={isActive('/organizations') ? 'full' : ''}
                    bg={isActive('/organizations') ? 'whiteAlpha.400' : ''}
                    as="button"
                    onClick={() => {
                        navigate('/organizations');
                    }}
                >
                    <FontAwesomeIcon icon={faBuilding} size="1x" />
                </Flex>

                <Flex
                    justify="center"
                    w="100%"
                    p={3}
                    cursor="pointer"
                    borderRadius={isActive('/job-listings/:businessId', '/job-creation', '/applicants/:jobId', '/skill-assessment/:jobId', '/evaluations/:jobId', '/edit-job/:jobId') ? 'full' : ''}
                    bg={isActive('/job-listings/:businessId', '/job-creation', '/applicants/:jobId', '/skill-assessment/:jobId', '/evaluations/:jobId', '/edit-job/:jobId') ? 'whiteAlpha.400' : ''}
                    as="button"
                    onClick={() => {
                        const businessId = localStorage.getItem('businessId');
                        if (businessId) {
                            navigate(`/job-listings/${businessId}`);
                        } else {
                            navigate('/organizations');
                        }
                    }}
                >
                    <FontAwesomeIcon w="100%" icon={faList} size="1x" />
                </Flex>
                {/*
                <Flex
                    justify="center"
                    w="100%"
                    p={3}
                    borderRadius={isActive('/create-project', '/open-projects', '/messaging', '/proposals', '/closed-projects', '/active-projects') ? 'full' : ''}
                    bg={isActive('/create-project', '/open-projects', '/messaging', '/proposals', '/closed-projects', '/active-projects') ? 'whiteAlpha.400' : ''}
                    as="button"
                    onClick={() => navigate('/open-projects')}
                    cursor="pointer"
                >
                    <FontAwesomeIcon w="100%" icon={faFileContract} size="1x" />
                </Flex>
                
                <Flex
                    justify="center"
                    w="100%"
                    p={3}
                    _hover={{ bg: "whiteAlpha.400", borderRadius: "full" }}
                    borderRadius={isActive('/dashboard') ? 'full' : ''}
                    bg={isActive('/dashboard') ? 'whiteAlpha.400' : ''}
                    as="button" onClick={() => navigate('/dashboard')} cursor="pointer">
                    <FontAwesomeIcon icon={faChartLine} size="1x" />
                </Flex>
                */}

                <Flex
                    justify="center"
                    w="100%"
                    p={3}
                    borderRadius={isActive('/talent-pool') ? 'full' : ''}
                    bg={isActive('/talent-pool') ? 'whiteAlpha.400' : ''}
                    as="button"
                    onClick={() => navigate('/talent-pool')}
                    cursor="pointer"
                >
                    <FontAwesomeIcon w="100%" icon={faPeopleGroup} size="1x" />
                </Flex>

                <Flex
                    justify="center"
                    w="100%"
                    p={3}
                    _hover={{ bg: "whiteAlpha.400", borderRadius: "full" }}
                    as="button"
                    onClick={() => navigate(`/${companyInfo.businessName}`)}
                    cursor="pointer"
                >
                    <FontAwesomeIcon icon={faBriefcase} size="1x" />
                </Flex>

                <Spacer />

                <Flex
                    justify="center"
                    w="100%"
                    p={3}
                    _hover={{ bg: "whiteAlpha.400", borderRadius: "full" }}
                    borderRadius={isActive('/settings') ? 'full' : ''}
                    bg={isActive('/settings') ? 'whiteAlpha.400' : ''} as="button" onClick={() => navigate('/settings')} cursor="pointer">
                    <FontAwesomeIcon icon={faGears} size="1x" />
                </Flex>

                <Flex _hover={{ bg: "whiteAlpha.400", borderRadius: "full" }} justify={"center"} w={"100%"} p={3} as="button" onClick={handleLogout} cursor="pointer">
                    <FontAwesomeIcon icon={faSignOutAlt} size="1x" />
                </Flex>
            </VStack>
            <VStack
                bg="#424242"
                p={4}
                spacing={6}
                alignItems="center"
                width="230px"
                h={"100vh"}
                left={70}
                top={0}
                position="fixed"
                color="white"
            >
                <Box w={"100%"}>
                    <Flex justify={"center"}>
                        <Heading fontWeight="md">
                            {companyInfo.company?.companyName}
                        </Heading>
                    </Flex>
                    {companyInfo.company?.logo && (
                        <Flex mb={4} justify={"center"}>
                            <Image src={companyInfo.company.logo} maxW={"200px"} maxH={"100px"} />
                        </Flex>
                    )}
                    {companyInfo.employer?.firstName && (
                        <Flex justify={"center"}>
                            <Text fontSize={"xl"} fontWeight={"semibold"}>Hi, {companyInfo.employer.firstName}!</Text>
                        </Flex>
                    )}
                </Box>

                {isActive('/job-listings/:businessId', '/job-creation', '/applicants/:jobId', '/skill-assessment/:jobId', '/evaluations/:jobId', '/edit-job/:jobId') && (
                    <>
                        <Heading size={"sm"}>Open Positions</Heading>
                        <VStack justify={"flex-start"}>
                            <HStack>
                                <RouterLink to={jobListingsPath} aria-label="Go to Job Listings">
                                    <Link
                                        leftIcon={<FaList />} fontSize="lg"
                                        _hover={{ color: "white", fontWeight: "semibold" }}
                                        aria-label="Job Listings"
                                        textDecoration={isActive(jobListingsPath) ? 'underline' : 'none'}
                                        color={'white'}
                                    >
                                        {isActive(jobListingsPath) ? (
                                            <HStack>
                                                <Image m={2} h={"25px"} w={"fit-content"} src={Icon} alt="Icon" />
                                                <Divider orientation='vertical' height="20px" borderColor="gray.100" />
                                                <Text>Job Listings</Text>
                                            </HStack>
                                        ) : (
                                            <Text>Job Listings</Text>
                                        )}
                                    </Link>
                                </RouterLink>
                                {isActive('/skill-assessment/:jobId', '/applicants/:jobId', '/evaluations/:jobId', '/edit-job/:jobId') && (
                                    <>
                                        <FontAwesomeIcon icon={faSortDown} />
                                    </>
                                )}
                            </HStack>
                            {isActive('/skill-assessment/:jobId') && (
                                <>
                                    <HStack>
                                        <Image m={2} h={"25px"} w={"fit-content"} src={Icon} /><Divider orientation='vertical' height="20px" borderColor="gray.100" /><Text fontSize={"medium"} textDecoration={"underline"}>Skill Assessment</Text>
                                    </HStack>
                                </>
                            )}

                            {isActive('/applicants/:jobId') && (
                                <>
                                    <HStack>
                                        <Image m={2} h={"25px"} w={"fit-content"} src={Icon} /><Divider orientation='vertical' height="20px" borderColor="gray.100" /><Text fontSize={"medium"} textDecoration={"underline"}>Applicants</Text>
                                    </HStack>
                                </>
                            )}

                            {isActive('/evaluations/:jobId') && (
                                <>
                                    <HStack>
                                        <Image m={2} h={"25px"} w={"fit-content"} src={Icon} /><Divider orientation='vertical' height="20px" borderColor="gray.100" /><Text fontSize={"medium"} textDecoration={"underline"}>Evaluations</Text>
                                    </HStack>
                                </>
                            )}

                            {isActive('/edit-job/:jobId') && (
                                <>
                                    <HStack>
                                        <Image m={2} h={"25px"} w={"fit-content"} src={Icon} /><Divider orientation='vertical' height="20px" borderColor="gray.100" /><Text fontSize={"medium"} textDecoration={"underline"}>Edit Job</Text>
                                    </HStack>
                                </>
                            )}
                        </VStack>
                        <RouterLink to="/job-creation" aria-label="Go to Job Creation">
                            <Link
                                leftIcon={<FaPlus />} fontSize="lg"
                                _hover={{ color: "white", fontWeight: "semibold" }}
                                aria-label="Create Job"
                                textDecoration={isActive('/job-creation') ? 'underline' : 'none'}
                                color={'white'}

                            >
                                {isActive('/job-creation') ? (
                                    <HStack>
                                        <Image m={2} h={"25px"} w={"fit-content"} src={Icon} />
                                        <Divider orientation='vertical' height="20px" borderColor="gray.100" />
                                        <Text>Create Job</Text>
                                    </HStack>
                                ) : (
                                    <><Text>Create Job</Text></>
                                )}
                            </Link>
                        </RouterLink>

                        {/*
                            isActive('/job-listings') && (
                                <>
                                    <Text fontSize="sm" my={4}>
                                        Generate an embeddable code for a job listing to include on your website:
                                    </Text>
                                    <Select size={"sm"} borderRadius={"md"} placeholder="Select a Job" onChange={(e) => setSelectedJobId(e.target.value)} value={selectedJobId}>
                                        {companyInfo?.activeJobs?.length ? (
                                            companyInfo.activeJobs.map((job) => (
                                                <option key={job._id} value={job._id}>{job.jobTitle}</option>
                                            ))
                                        ) : (
                                            <option disabled>No active jobs found</option>
                                        )}
                                    </Select>
                                    <Button my={4} colorScheme="blue" onClick={generateEmbedScript} isDisabled={!selectedJobId || !companyInfo.website}>
                                        Generate Embed Code
                                    </Button>
                                    {generatedScript && (
                                        <Textarea
                                            value={generatedScript}
                                            rows={5}
                                            readOnly
                                            my={4}
                                            placeholder="Your embed code will appear here..."
                                        />
                                    )}
                                    {!companyInfo.website && (
                                        <Text color="red.500" mt={4}>Error: Company website URL is missing. Please update your company information.</Text>
                                    )}
                                </>
                            )
                                    */}

                    </>
                )}
                {isActive('/talent-pool', '/add-talent', '/manual-upload', '/manage-talent') && (
                    <>
                        <RouterLink to="/talent-pool" aria-label="Go to Talent Pool">
                            <Link
                                leftIcon={<FaUsers />} fontSize="lg"
                                _hover={{ color: "white", fontWeight: "semibold" }}
                                aria-label="Talent Pool"
                                textDecoration={isActive('/talent-pool') ? 'underline' : 'none'}
                                color={'white'}
                            >
                                {isActive('/talent-pool') ? (
                                    <HStack>
                                        <Image m={2} h={"25px"} w={"fit-content"} src={Icon} />
                                        <Divider orientation='vertical' height="20px" borderColor="gray.100" />
                                        <Text>Talent Pool</Text>
                                    </HStack>
                                ) : (
                                    <><Text>Talent Pool</Text></>
                                )}
                            </Link>
                        </RouterLink>
                        {/*
                        <RouterLink to="/manage-talent" aria-label="Manage Talent">
                            <Link
                                leftIcon={<FaUsers />} fontSize="lg"
                                _hover={{ color: "white", fontWeight: "semibold" }}
                                aria-label="Add Talent"
                                textDecoration={isActive('/manage-talent') ? 'underline' : 'none'}
                                color={'white'}
                            >
                                {isActive('/manage-talent') ? (
                                    <HStack>
                                        <Image m={2} h={"25px"} w={"fit-content"} src={Icon} />
                                        <Divider orientation='vertical' height="20px" borderColor="gray.100" />
                                        <Text>Manage Talent</Text>
                                    </HStack>
                                ) : (
                                    <><Text>Manage Talent</Text></>
                                )}
                            </Link>
                        </RouterLink>
                        <RouterLink to="/add-talent" aria-label="Add Talent">
                            <Link
                                leftIcon={<FaUsers />} fontSize="lg"
                                _hover={{ color: "white", fontWeight: "semibold" }}
                                aria-label="Add Talent"
                                textDecoration={isActive('/add-talent') ? 'underline' : 'none'}
                                color={'white'}
                            >
                                {isActive('/add-talent', '/manual-upload') ? (
                                    <HStack>
                                        <Image m={2} h={"25px"} w={"fit-content"} src={Icon} />
                                        <Divider orientation='vertical' height="20px" borderColor="gray.100" />
                                        <Text>Add Talent</Text>
                                    </HStack>
                                ) : (
                                    <><Text>Add Talent</Text></>
                                )}
                            </Link>
                                </RouterLink>*/}
                    </>
                )}
                {isActive('/organizations') && (
                    <>
                        <RouterLink to="/organizations" aria-label="Select Organization">
                            <Link
                                leftIcon={<FaUsers />} fontSize="lg"
                                _hover={{ color: "white", fontWeight: "semibold" }}
                                aria-label="Select Organization"
                                textDecoration={isActive('/organizations') ? 'underline' : 'none'}
                                color={'white'}
                            >
                                {isActive('/organizations') ? (
                                    <HStack>
                                        <Image m={2} h={"25px"} w={"fit-content"} src={Icon} />
                                        <Divider orientation='vertical' height="20px" borderColor="gray.100" />
                                        <Text>Select Organization</Text>
                                    </HStack>
                                ) : (
                                    <><Text>Select Organization</Text></>
                                )}
                            </Link>
                        </RouterLink>
                    </>
                )}
                {isActive('/dashboard') && (
                    <>
                        <RouterLink to="/dashboard" aria-label="See Reporting">
                            <Link
                                leftIcon={<FaUsers />} fontSize="lg"
                                _hover={{ color: "white", fontWeight: "semibold" }}
                                aria-label="Talent Pool"
                                textDecoration={isActive('/dashboard') ? 'underline' : 'none'}
                                color={'white'}
                            >
                                {isActive('/dashboard') ? (
                                    <HStack>
                                        <Image m={2} h={"25px"} w={"fit-content"} src={Icon} />
                                        <Divider orientation='vertical' height="20px" borderColor="gray.100" />
                                        <Text>Dashboard</Text>
                                    </HStack>
                                ) : (
                                    <><Text>Dashboard</Text></>
                                )}
                            </Link>
                        </RouterLink>
                    </>
                )}
                {isActive('/create-project', '/open-projects', '/messaging', '/active-projects', '/proposals', '/closed-projects') && (
                    <>
                        <Heading size={"sm"}>Freelance Dashboard</Heading>
                        <RouterLink to="/create-project" aria-label="Create a Freelance Project">
                            <Link
                                leftIcon={<FaUsers />} fontSize="lg"
                                _hover={{ color: "white", fontWeight: "semibold" }}
                                aria-label="Talent Pool"
                                textDecoration={isActive('/create-project') ? 'underline' : 'none'}
                                color={'white'}
                            >
                                {isActive('/create-project') ? (
                                    <HStack>
                                        <Image m={2} h={"25px"} w={"fit-content"} src={Icon} />
                                        <Divider orientation='vertical' height="20px" borderColor="gray.100" />
                                        <Text>Create Project</Text>
                                    </HStack>
                                ) : (
                                    <><Text>Create Project</Text></>
                                )}
                            </Link>
                        </RouterLink>
                        <RouterLink to="/open-projects" aria-label="Manage Talent">
                            <Link
                                leftIcon={<FaUsers />} fontSize="lg"
                                _hover={{ color: "white", fontWeight: "semibold" }}
                                aria-label="Add Talent"
                                textDecoration={isActive('/open-projects') ? 'underline' : 'none'}
                                color={'white'}
                            >
                                {isActive('/open-projects') ? (
                                    <HStack>
                                        <Image m={2} h={"25px"} w={"fit-content"} src={Icon} />
                                        <Divider orientation='vertical' height="20px" borderColor="gray.100" />
                                        <Text>Open Projects</Text>
                                    </HStack>
                                ) : (
                                    <><Text>Open Projects</Text></>
                                )}
                            </Link>
                        </RouterLink>
                        <RouterLink to="/active-projects" aria-label="Active Contracts">
                            <Link
                                leftIcon={<FaUsers />} fontSize="lg"
                                _hover={{ color: "white", fontWeight: "semibold" }}
                                aria-label="Active Contracts"
                                textDecoration={isActive('/active-projects') ? 'underline' : 'none'}
                                color={'white'}
                            >
                                {isActive('/active-projects') ? (
                                    <HStack>
                                        <Image m={2} h={"25px"} w={"fit-content"} src={Icon} />
                                        <Divider orientation='vertical' height="20px" borderColor="gray.100" />
                                        <Text>Active Contracts</Text>
                                    </HStack>
                                ) : (
                                    <><Text>Active Contracts</Text></>
                                )}
                            </Link>
                        </RouterLink>
                        <RouterLink to="/closed-projects" aria-label="Closed Projects">
                            <Link
                                leftIcon={<FaUsers />} fontSize="lg"
                                _hover={{ color: "white", fontWeight: "semibold" }}
                                aria-label="Closed Projects"
                                textDecoration={isActive('closed-projects') ? 'underline' : 'none'}
                                color={'white'}
                            >
                                {isActive('/closed-projects') ? (
                                    <HStack>
                                        <Image m={2} h={"25px"} w={"fit-content"} src={Icon} />
                                        <Divider orientation='vertical' height="20px" borderColor="gray.100" />
                                        <Text>Closed Contracts</Text>
                                    </HStack>
                                ) : (
                                    <><Text>Closed Contracts</Text></>
                                )}
                            </Link>
                        </RouterLink>
                        <RouterLink to="/proposals" aria-label="View Your Proposals">
                            <Link
                                leftIcon={<FaUsers />} fontSize="lg"
                                _hover={{ color: "white", fontWeight: "semibold" }}
                                aria-label="View Your Proposals"
                                textDecoration={isActive('/proposals') ? 'underline' : 'none'}
                                color={'white'}
                            >
                                {isActive('/proposals') ? (
                                    <HStack>
                                        <Image m={2} h={"25px"} w={"fit-content"} src={Icon} />
                                        <Divider orientation='vertical' height="20px" borderColor="gray.100" />
                                        <Text>Your Proposals</Text>
                                    </HStack>
                                ) : (
                                    <><Text>Your Proposals</Text></>
                                )}
                            </Link>
                        </RouterLink>
                    </>
                )}
                {/*
                <Heading mt={4} size="sm">Freelancer Messages</Heading>
                <Divider mt={-2} />
                <Stack w={"100%"} mt={-2} spacing={4}>
                    {mostRecentMessages.map((message) => (
                        <Link
                            key={message.connectionId}
                            fontSize="lg"
                            w={"100%"}
                            borderRadius={"lg"}
                            p={2}
                            bg={"black"}
                            _hover={{
                                color: "white",
                                fontWeight: "semibold",
                            }}
                            onClick={() => handleMessageClick(message.connectionId)} // Assuming this function is intended for handling click on a room
                            display="flex"
                            alignItems="center"
                        >
                            <Flex direction={"column"}>
                                <Flex>
                                    <Avatar
                                        src={message?.senderPFP}
                                        size="sm"
                                        name={message?.senderFirstName}
                                        color="white"
                                        mr={2}
                                    />
                                    <Text fontWeight="bold">{message?.senderFirstName}</Text>
                                </Flex>
                                <Box flex="1">
                                    {message && (
                                        <Text mb={2} fontSize={"sm"}>
                                            {message?.text?.length > 100
                                                ? `${message.text.substring(0, 100)}...`
                                                : message.text}
                                        </Text>
                                    )}
                                    <Flex mt={2}>
                                        {typingStatus[message.connectionId] && <TypingIndicator />}
                                    </Flex>
                                </Box>
                            </Flex>
                            {unreadCounts[message.connectionId] > 0 && (
                                <Box
                                    bg="red.500"
                                    color="white"
                                    borderRadius="full"
                                    fontSize="xs"
                                    fontWeight="bold"
                                    px={2}
                                    py={1}
                                    ml={2}
                                >
                                    {unreadCounts[message.connectionId]}
                                </Box>
                            )}
                        </Link>
                    ))}
                </Stack>
                            */}
                <Spacer />
                <Box w={"100%"}>
                    <Footer />
                </Box>
            </VStack >
        </HStack>
    );
}

export default ParentSideBar;
