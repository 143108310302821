import React from 'react';
import { Box, Link, VStack, Image, Divider } from '@chakra-ui/react';
import Logo from '../assets/whitelogo.png';

const Footer = () => {

    return (
        <Box w={"100%"} alignItems={'center'} mt={5}>
            <VStack justify={"space-between"} w={"100%"}>
                <Link href="/contact-us" fontSize="xs" color="white">
                    Contact Us
                </Link>
                <Link href="/terms" fontSize="xs" color="white">
                    Terms & Conditions
                </Link>
                <Divider mt={4} mb={4} />
                <Image mb={2} src={Logo} w={"80%"} />
                {/* <Select
                variant="outline"
                width="auto"
                defaultValue="en"
                mt={4}
                bg="whiteAlpha.800" // This is just a white color with some transparency, but you can replace it with a light blue if desired.
                onChange={handleLanguageChange}
            >
                <option value="en">English</option>
                <option value="es">Español</option>
                <option value="fr">Français</option>
                <option value="de">Deutsch</option>
    </Select> */}
            </VStack>
        </Box>
    );
};

export default Footer;