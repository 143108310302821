import React, { useState } from 'react';
import { Box, Heading, Input, Button, Flex, HStack } from '@chakra-ui/react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const CompleteSignUp = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [yourTitle, setYourTitle] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');

        const data = {
            firstName,
            lastName,
            phoneNumber,
            yourTitle,
            newPassword,
            role: "employer",
            token
        };

        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/api/complete-signup', data);
            console.log('Sign-up completed successfully');
            if (response.status === 200) {
                navigate('/login');
            }
        } catch (error) {
            console.error('Error completing sign-up:', error);
        }
    };

    return (
        <Flex w={"100%"} justify={"center"} p={4}>
            <Box maxW={"700px"}>
                <Heading as="h1" size="lg" mb={4}>
                    Complete Your Sign-Up
                </Heading>

                <form onSubmit={handleSubmit}>
                    <HStack>
                        <Input
                            type="text"
                            placeholder="First Name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            mb={2}
                            required
                        />
                        <Input
                            type="text"
                            placeholder="Last Name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            mb={2}
                            required
                        />
                    </HStack>
                    <HStack>
                        <Input
                            type="text"
                            placeholder="Phone Number"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            mb={2}
                            required
                        />
                        <Input
                            type="text"
                            placeholder="Your Title"
                            value={yourTitle}
                            onChange={(e) => setYourTitle(e.target.value)}
                            mb={2}
                            required
                        />
                    </HStack>
                    <Input
                        type="password"
                        placeholder="New Password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        mb={4}
                        required
                    />
                    <Flex w={"100%"} justify={"flex-end"}>
                        <Button type="submit" bg={"#118AB2"} color={"white"} _hover={{ bg: "#01BF02" }}>
                            Complete Sign-Up
                        </Button>
                    </Flex>
                </form>
            </Box>
        </Flex >
    );
};

export default CompleteSignUp;
