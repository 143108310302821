// MessagesContainer.js
import React, { useEffect, useRef } from 'react';
import { VStack, Flex, Box, Image, Text } from '@chakra-ui/react';
import moment from 'moment'; // Import moment for formatting dates
import ReactLinkify from 'react-linkify';

const MessagesContainer = ({ messages, currentUserId }) => {
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }

    useEffect(scrollToBottom, [messages]);

    if (messages.length === 0) {
        return (
            <Flex width="100%" justifyContent="center" alignItems="center" height="500px">
                <Text>No messages yet.</Text>
            </Flex>
        );
    }

    const linkDecorator = (href, text, key) => (
        <a href={href} key={key} target="_blank" rel="noopener noreferrer">
            {text}
        </a>
    );

    return (
        <Box
            width="100%"
            mb={4}
            maxHeight="500px"
            overflowY="auto"
            borderRadius="md"
            css={{
                '&::-webkit-scrollbar': {
                    width: '10px',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'skyblue',
                    borderRadius: '10px',
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,.3)',
                },
            }}
        >
            <VStack
                align="stretch"
                spacing={4}
                mb={4}
                p={3}
                width="100%"  // Ensure it takes the full width
            >
                {messages.map((message, index) => (
                    <Flex
                        key={index}
                        justifyContent={message.senderId === currentUserId ? 'flex-end' : 'flex-start'}
                        mt={4}
                        direction="column"
                        alignItems={message.senderId === currentUserId ? 'flex-end' : 'flex-start'}
                    >
                        <Box
                            p={3}
                            borderRadius="lg"
                            bg={message.senderId === currentUserId ? '#02bf01' : 'gray.100'}
                            color={message.senderId === currentUserId ? 'white' : 'black'}
                            maxWidth="80%"
                            wordBreak="break-word"
                            shadow="md"
                        >
                            <Text fontSize="sm" opacity={0.8}>
                                {message.senderFirstName} {message.senderLastName}
                            </Text>
                            <ReactLinkify componentDecorator={linkDecorator}>
                                <Text>{message.text}</Text>
                            </ReactLinkify>
                        </Box>
                        <Flex alignItems="center">
                            <Text justifyContent={message.senderId === currentUserId ? 'flex-end' : 'flex-start'} fontSize="xs" ml={2} opacity={0.5}>
                                {moment(message.createdAt).fromNow()}
                            </Text>
                        </Flex>
                    </Flex>
                ))}
            </VStack>
            <div ref={messagesEndRef} />
        </Box>
    );
};

export default MessagesContainer;
