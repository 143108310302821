import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
    Box, Flex, SimpleGrid, Heading, Divider, Button, FormControl, FormLabel,
    Input, Textarea, Text, useToast, InputGroup, InputLeftAddon, Image, HStack, InputRightElement,
    Modal, ModalBody, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalCloseButton,
    useDisclosure, Icon, VStack, ListIcon, ListItem, List, Stack, Radio, RadioGroup
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaEnvelope, FaPhone, FaQuestionCircle, FaUpload } from 'react-icons/fa';
import { faFacebookF, faLinkedinIn, faInstagram, faDiscord, faYoutube, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import TeamManagement from '../../components/TeamManagement';
import EmployerSettings from '../../components/EmployerSettings';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axiosInstance from '../../components/axiosInstance';

const stripePromise = loadStripe("pk_live_51MXrqLEDfGoNCpuzG4DPVOWnJaoNRusee6UxEgrMIAwAiylcZYMOcXjJysOwCvl2ed4I3XAtsmr87t9vAGBLlTHb006oEEfL6v");

const CardDetailsForm = ({ setStoredCard }) => {
    const stripe = useStripe();
    const elements = useElements();
    const token = localStorage.getItem('auth_token');
    const [selectedAccountType, setSelectedAccountType] = useState('user');
    const businessId = localStorage.getItem('businessId');
    const toast = useToast();

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet
            return;
        }

        const card = elements.getElement(CardElement);
        const result = await stripe.createPaymentMethod({
            type: 'card',
            card: card,
        });   
        if (result.error) {
            console.error("Error creating payment method:", result.error.message);
            toast({
                title: "Error",
                description: result.error.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }  

        if (result.error) {
            toast({
                title: "Error",
                description: result.error.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            console.error("Payment Method creation error:", result.error.message);
        } else {
            try {
                const url = selectedAccountType === 'user'
                    ? process.env.REACT_APP_API_URL + '/api/user/update-card'
                    : process.env.REACT_APP_API_URL + `/api/${businessId}/update-card`;

                const updateResponse = await axios.post(url, {
                    paymentMethodId: result.paymentMethod.id,
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (updateResponse.status === 200) {
                    toast({
                        title: "Card updated successfully.",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    });
                    // Update the stored card details in state
                    setStoredCard(result.paymentMethod);
                    elements.getElement(CardElement).clear();
                }
            } catch (error) {
                console.error('Error updating card:', error);
                toast({
                    title: "Update failed.",
                    description: "An error occurred. Please try again.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        }
    };
    return (
        <form onSubmit={handleSubmit}>
            <CardElement />
            <FormControl mt={4}>
                <FormLabel>Select Account Type</FormLabel>
                <RadioGroup value={selectedAccountType} onChange={setSelectedAccountType}>
                    <Stack direction="row">
                       {/* <Radio value="user">User Account</Radio> */}
                        <Radio value="business">Business Account</Radio>
                    </Stack>
                </RadioGroup>
            </FormControl>
            <Flex w={"100%"} justify={"flex-end"}>
                <Button size={"sm"} mt={4} bg={"#118AB2"} color={"white"} _hover={{ bg: "#01BF02" }} type="submit" disabled={!stripe}>
                    Add Card
                </Button>
            </Flex>
        </form>
    );
};

const BillingInformation = ({ storedCard, setStoredCard }) => {
    return (
        <Box p={5} boxShadow="md" bg="white" borderRadius="lg">
            <Heading size="md" mb={4}>Billing Information</Heading>
            <Divider my={3} />
            <Box position="relative" p={3} bg="gray.50" borderRadius="md" boxShadow="inner">
                {storedCard ? (
                    <Box
                        p={4}
                        bg="#118AB2"
                        borderRadius="md"
                        boxShadow="sm"
                        color="white"
                        mb={4}
                    >
                        <Text fontSize="sm" fontWeight="semibold">Card on File</Text>
                        <Box mt={2} p={2} bg="black" borderRadius="md">
                            <Text fontSize="xl">•••• •••• •••• {storedCard.card.last4}</Text>
                            <HStack>
                                <Text>{storedCard.card.brand.toUpperCase()}</Text>
                                <Text fontSize="md">Expires {storedCard.card.exp_month}/{storedCard.card.exp_year}</Text>
                            </HStack>
                        </Box>
                    </Box>
                ) : (
                    <Text mb={4}>No card on file.</Text>
                )}
                <Elements stripe={stripePromise}>
                    <CardDetailsForm setStoredCard={setStoredCard} />
                </Elements>
            </Box>
        </Box>
    );
};

const Settings = () => {
    const [businessName, setCompanyName] = useState("");
    const [fileName, setFileName] = useState('');
    const [companyId, setCompanyId] = useState("");
    const [companyDescription, setCompanyDescription] = useState('');
    const [companyWebsite, setCompanyWebsite] = useState('');
    const [billingEmail, setBillingEmail] = useState("");
    const [isEditingProfile, setEditingProfile] = useState(false);
    const [companyAddress, setCompanyAddress] = useState({
        street: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
    });
    const [companyMainContact, setCompanyMainContact] = useState("");
    const [companyLogo, setCompanyLogo] = useState("");
    const [socialMediaLinks, setSocialMediaLinks] = useState([]);
    const [certificationsAndAwards, setCertificationsAndAwards] = useState([]);
    const [setCompanyCertificationsAndAwards] = useState([]);
    const [setCompanySocialMediaLinks] = useState([]);
    const [newLogoFile, setNewLogoFile] = useState(null);
    const [storedCard, setStoredCard] = useState(null);
    const [confirmInput, setConfirmInput] = useState("");
    const { isOpen, onOpen, onClose } = useDisclosure();

    const token = localStorage.getItem('auth_token');
    const businessId = localStorage.getItem('businessId');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCompanyInfo = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + `/api/company/${businessId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const companyData = response.data;
                setCompanyName(companyData.businessName);
                setCompanyId(companyData._id);
                setCompanyDescription(companyData.description);
                setCompanyWebsite(companyData.website);
                setCompanyAddress(companyData.address);
                setCompanyMainContact(companyData.mainContact);
                setCompanyLogo(companyData.logo);
                setBillingEmail(companyData.billingEmail);
                setSocialMediaLinks(companyData.socialMediaLinks || []);
                setCertificationsAndAwards(companyData.certificationsAndAwards || []);
                setCompanyCertificationsAndAwards(companyData.companyCertificationsAndAwards);
                setCompanySocialMediaLinks(companyData.companySocialMediaLinks);
            } catch (error) {
                console.error('Error fetching company info:', error);
            }
        };

        const fetchCardDetails = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + `/api/retrieve-card/${businessId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.data.length > 0) {
                    setStoredCard(response.data[0]); // Assuming you want to display the first card
                }
            } catch (error) {
                console.error('Error fetching card details:', error);
            }
        };

        fetchCardDetails();
        fetchCompanyInfo();
    }, [token]);

    const toggleEditProfile = () => {
        setEditingProfile(!isEditingProfile);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setNewLogoFile(file);
            setFileName(file.name);
        }
    };

    const addCertificationOrAward = () => {
        setCertificationsAndAwards([...certificationsAndAwards, { name: "" }]);
    };

    const updateCertificationOrAward = (index, newName) => {
        const updatedAwards = [...certificationsAndAwards];
        updatedAwards[index].name = newName;
        setCertificationsAndAwards(updatedAwards);
    };

    const toast = useToast();

    const getSocialMediaIcon = (name) => {
        const icons = {
            X: faXTwitter,
            Facebook: faFacebookF,
            LinkedIn: faLinkedinIn,
            Instagram: faInstagram,
            Website: faGlobe,
            Discord: faDiscord,
            YouTube: faYoutube,
        };

        const icon = icons[name];
        return icon ? <FontAwesomeIcon icon={icon} /> : null;
    };

    const saveProfileInfo = async () => {
        try {
            const response = await axios.put(process.env.REACT_APP_API_URL + '/api/company', {
                businessName: businessName,
                description: companyDescription,
                website: companyWebsite,
                address: companyAddress,
                mainContact: companyMainContact,
                logo: companyLogo,
                socialMediaLinks: socialMediaLinks,
                certificationsAndAwards: certificationsAndAwards
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                toast({
                    title: "Updated successfully.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: "Update failed.",
                    description: "Please try again.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error('Error updating company info:', error);

            if (error.response && error.response.status === 403) {
                toast({
                    title: "Update failed.",
                    description: "You do not have permission to make this change.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: "Update failed.",
                    description: "An error occurred. Please try again.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        }
    };

    const handleLogoSubmit = async () => {
        if (!newLogoFile) return;

        const formData = new FormData();
        formData.append('newLogo', newLogoFile);

        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/company/${companyId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                setCompanyLogo(response.data.company.logo);
                toast({
                    title: "Logo updated successfully.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error('Error updating logo:', error);
            toast({
                title: "Update failed.",
                description: "An error occurred. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const addSocialMediaLink = () => {
        setSocialMediaLinks([...socialMediaLinks, { name: "", link: "" }]);
    };

    // Handler to update a specific social media link
    const updateSocialMediaLink = (index, updatedLink) => {
        const updatedLinks = [...socialMediaLinks];
        updatedLinks[index] = updatedLink;
        setSocialMediaLinks(updatedLinks);
    };

    const handleDeleteAccount = async () => {
        if (confirmInput === businessName) {
            try {
                const response = await axiosInstance.delete(`/company/${companyId}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });

                if (response.status === 200) {
                    toast({
                        title: "Account deleted successfully.",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    });
                    navigate('/signup');
                }
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    toast({
                        title: 'Error',
                        description: 'You do not have permission to make this change.',
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    });
                } else {
                    toast({
                        title: "Deletion failed.",
                        description: "An error occurred. Please try again.",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                }
            }
        } else {
            toast({
                title: "Incorrect confirmation.",
                description: "Please type the correct company name to confirm.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }

        setConfirmInput("");
        onClose();
    };

    return (
        <Flex direction="column" minH="100vh">
            <Flex flex="1" w="100%" pl="300px">
                <Box flex="1" p={5} bg="gray.100">
                    <SimpleGrid columns={[1, null, 2]} spacing={10}>

                        {/* Profile Management */}
                        <Box h={"auto"} borderRadius="lg" p={5} boxShadow="md" bg="white">
                            <Flex justifyContent="space-between">
                                <Heading size="md">Profile Management</Heading>
                                <Button size="sm" onClick={toggleEditProfile}>
                                    {isEditingProfile ? 'Cancel' : 'Edit'}
                                </Button>
                            </Flex>
                            <Divider my={3} />

                            {isEditingProfile ? (
                                <>
                                    <FormControl id="businessName">
                                        <FormLabel>Business Name</FormLabel>
                                        <Input
                                            type="text"
                                            value={businessName}
                                            onChange={(e) => setCompanyName(e.target.value)}
                                        />
                                    </FormControl>
                                    <FormControl id="companyDescription" mt={4}>
                                        <FormLabel>Company Description</FormLabel>
                                        <Textarea
                                            value={companyDescription}
                                            onChange={(e) => setCompanyDescription(e.target.value)}
                                        />
                                    </FormControl>
                                    <FormControl id="companyWebsite" mt={4}>
                                        <FormLabel>Company Website</FormLabel>
                                        <Input
                                            type="text"
                                            value={companyWebsite}
                                            onChange={(e) => setCompanyWebsite(e.target.value)}
                                        />
                                    </FormControl>
                                    <FormControl id="companyAddress" mt={4}>
                                        <FormLabel>Street Address</FormLabel>
                                        <Input
                                            type="text"
                                            value={companyAddress.street}
                                            onChange={(e) => setCompanyAddress({ ...companyAddress, street: e.target.value })}
                                        />
                                    </FormControl>
                                    <FormControl id="companyCity" mt={4}>
                                        <FormLabel>City</FormLabel>
                                        <Input
                                            type="text"
                                            value={companyAddress.city}
                                            onChange={(e) => setCompanyAddress({ ...companyAddress, city: e.target.value })}
                                        />
                                    </FormControl>
                                    <FormControl id="companyState" mt={4}>
                                        <FormLabel>State</FormLabel>
                                        <Input
                                            type="text"
                                            value={companyAddress.state}
                                            onChange={(e) => setCompanyAddress({ ...companyAddress, state: e.target.value })}
                                        />
                                    </FormControl>
                                    <FormControl id="companyCountry" mt={4}>
                                        <FormLabel>Country</FormLabel>
                                        <Input
                                            type="text"
                                            value={companyAddress.country}
                                            onChange={(e) => setCompanyAddress({ ...companyAddress, country: e.target.value })}
                                        />
                                    </FormControl>
                                    <FormControl id="companyPostalCode" mt={4}>
                                        <FormLabel>Postal Code</FormLabel>
                                        <Input
                                            type="text"
                                            value={companyAddress.postalCode}
                                            onChange={(e) => setCompanyAddress({ ...companyAddress, postalCode: e.target.value })}
                                        />
                                    </FormControl>
                                    <FormControl id="companyMainContact" mt={4}>
                                        <FormLabel>Main Contact</FormLabel>
                                        <Input
                                            type="text"
                                            value={companyMainContact}
                                            onChange={(e) => setCompanyMainContact(e.target.value)}
                                        />
                                    </FormControl>
                                    <FormControl id="billingEmail" mt={4}>
                                        <FormLabel>Billing Email</FormLabel>
                                        <Input
                                            type="email"
                                            value={billingEmail}
                                            onChange={(e) => setBillingEmail(e.target.value)}
                                        />
                                    </FormControl>
                                    <FormControl id="companyLogo" mt={4}>
                                        <FormLabel>Company Logo</FormLabel>
                                        <VStack>
                                            {fileName && <Text ml={3}>{fileName}</Text>}
                                            <HStack justify={"center"} align={"flex-end"} w={"100%"}>
                                                <Flex alignItems="center">
                                                    <Box>
                                                        <Input
                                                            type="file"
                                                            onChange={handleFileChange}
                                                            hidden
                                                            accept="image/*"
                                                            id="file-upload"
                                                        />
                                                        <Button
                                                            leftIcon={<FaUpload />}
                                                            onClick={() => document.getElementById('file-upload').click()}
                                                        >
                                                            Upload Logo
                                                        </Button>
                                                    </Box>
                                                </Flex>
                                                <Button
                                                    bg={"#118AB2"} color={"white"} _hover={{ bg: "#01BF02" }}
                                                    onClick={handleLogoSubmit}
                                                    disabled={!newLogoFile}
                                                >
                                                    Update Logo
                                                </Button>
                                            </HStack>
                                        </VStack>
                                    </FormControl>
                                    {/* Social Media Links */}
                                    {socialMediaLinks.map((link, index) => (
                                        <InputGroup key={index}>
                                            <Input
                                                placeholder="Social Media Name"
                                                value={link.name}
                                                mt={4}
                                                mr={2}
                                                onChange={(e) => updateSocialMediaLink(index, { ...link, name: e.target.value })}
                                            />
                                            <Input
                                                placeholder="Social Media Link"
                                                value={link.link}
                                                mt={4}
                                                onChange={(e) => updateSocialMediaLink(index, { ...link, link: e.target.value })}
                                            />
                                        </InputGroup>
                                    ))}
                                    <Flex mb={4} mt={2} justify={"flex-end"} w={"100%"}>
                                        <Button bg={"#118AB2"} color={"white"} _hover={{ bg: "#01BF02" }} onClick={addSocialMediaLink}>Add Social Media Link</Button>
                                    </Flex>
                                    {/* Certifications and Awards */}
                                    {certificationsAndAwards.map((award, index) => (
                                        <Input
                                            key={index}
                                            placeholder="Certification/Award Name"
                                            value={award.name}
                                            onChange={(e) => updateCertificationOrAward(index, e.target.value)}
                                        />
                                    ))}
                                    <Flex mt={2} justify={"flex-end"} w={"100%"}>
                                        <Button bg={"#118AB2"} color={"white"} _hover={{ bg: "#01BF02" }} onClick={addCertificationOrAward}>Add Certification/Award</Button>
                                    </Flex>
                                    <Flex mt={2} justify={"flex-end"} w={"100%"}>
                                        <Button
                                            mt={4}
                                            bg={"#118AB2"} color={"white"} _hover={{ bg: "#01BF02" }}
                                            onClick={() => {
                                                saveProfileInfo();
                                                handleLogoSubmit();
                                                toggleEditProfile();
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </Flex>
                                </>
                            ) : (
                                <>
                                    {/* New Display Fields */}
                                    <HStack align={"flex-start"} justify={"space-between"} w={"100%"}>
                                        <Box>
                                            <Text mt={3}><strong>Business Name:</strong> {businessName}</Text>
                                            <Text mt={3}><strong>Main Contact:</strong>  {companyMainContact}</Text>
                                            <Text mt={3}><strong>Billing Email:</strong>  {billingEmail}</Text>
                                        </Box>
                                        <Flex w={"50%"}>
                                            <Text mt={3}><strong>Company Logo:</strong>  </Text> {companyLogo && <Image ml={4} src={companyLogo} alt="Company Logo" style={{ maxWidth: '100px', maxHeight: '100px' }} />}
                                        </Flex>
                                    </HStack>
                                    <Text mt={3}><strong>Company Description:</strong>  {companyDescription}</Text>
                                    <HStack align={"flex-start"} justify={"space-between"} w={"100%"}>
                                        <Text mt={3}>
                                            {getSocialMediaIcon("Website")} <strong>Company Website:</strong>  {companyWebsite}
                                        </Text>
                                        <HStack align={"flex-end"} justify={"flex-start"} w={"50%"}>
                                            <Text mt={3}><strong>Social Media:</strong> </Text>
                                            {socialMediaLinks.map((link, index) => (
                                                <HStack key={index} spacing="2">
                                                    <a href={`${link.link.startsWith('http://') || link.link.startsWith('https://') ? '' : 'https://'}${link.link}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer">
                                                        <Box as="span">
                                                            {getSocialMediaIcon(link.name)}
                                                        </Box>
                                                    </a>
                                                </HStack>
                                            ))}
                                        </HStack>
                                    </HStack>
                                    <Text mt={3}><strong>Street Address:</strong>  {companyAddress.street}</Text>
                                    <HStack align={"flex-end"} justify={"space-between"} w={"100%"}>
                                        <Text mt={3}><strong>City:</strong>  {companyAddress.city}</Text>
                                        <Text mt={3}><strong>State:</strong>  {companyAddress.state}</Text>
                                        <Text mt={3}><strong>Country:</strong>  {companyAddress.country}</Text>
                                        <Text mt={3}><strong>Postal Code:</strong>  {companyAddress.postalCode}</Text>
                                    </HStack>

                                    <Text mt={3}><strong>Certifications/Awards:</strong> </Text>
                                    {certificationsAndAwards.map((award, index) => (
                                        <Text key={index}>{award.name}</Text>
                                    ))}
                                </>
                            )}
                        </Box>

                        <EmployerSettings />

                        {/* Billing Information */}
                        <BillingInformation storedCard={storedCard} setStoredCard={setStoredCard} />

                        <TeamManagement />

                        {/* Support and Help */}
                        <Box p={5} boxShadow="md" bg="white" borderRadius="lg">
                            <Heading size="md">Support and Help</Heading>
                            <Divider my={3} />
                            <HStack w={"100%"} justify={"space-evenly"}>
                                {/* Contact Information */}
                                <Box>
                                    <Text fontWeight="bold">Contact Us</Text>
                                    <List spacing={2} my={2}>
                                        <ListItem>
                                            <ListIcon as={FaEnvelope} color="blue.500" />
                                            <Link href="mailto:support@jobjar.ai" ml={2} style={{ color: '#118AB2' }}>
                                                Support@JobJar.Ai
                                            </Link>
                                        </ListItem>
                                        {/*
                                        <ListItem>
                                            <ListIcon as={FaPhone} color="green.500" />
                                            +1 (123) 456-7890
                                        </ListItem>
                                    */}
                                    </List>
                                </Box>

                                {/* FAQ Section */}
                                <Box mt={4}>
                                    <Text fontWeight="bold">Frequently Asked Questions</Text>
                                    <Button
                                        rightIcon={<FaQuestionCircle />}
                                        bg={"#118AB2"}
                                        color={"white"}
                                        _hover={{ bg: "#01BF02" }}
                                        variant="outline"
                                        mt={2}
                                        onClick={() => {/* Implement navigation to FAQ page */ }}
                                    >
                                        View FAQs
                                    </Button>
                                </Box>
                            </HStack>
                        </Box>

                        {/* Account Deactivation */}
                        <Box p={5} borderRadius="lg" boxShadow="md" bg="white">
                            <Heading size="md">Account Deactivation</Heading>
                            <Divider my={3} />
                            <Button colorScheme="red" onClick={onOpen}>Delete Account</Button>

                            {/* Confirmation Modal */}
                            <Modal isOpen={isOpen} onClose={onClose}>
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalHeader>Confirm Account Deactivation</ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        <Text>Type the company name to confirm:</Text>
                                        <Input
                                            value={confirmInput}
                                            onChange={(e) => setConfirmInput(e.target.value)}
                                            placeholder="Company Name"
                                        />
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button colorScheme="red" mr={3} onClick={handleDeleteAccount}>
                                            Confirm
                                        </Button>
                                        <Button variant="ghost" onClick={onClose}>Cancel</Button>
                                    </ModalFooter>
                                </ModalContent>
                            </Modal>
                        </Box>


                    </SimpleGrid>
                </Box>
            </Flex>
        </Flex>
    );
};

export default Settings;