import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axiosInstance from "../../components/axiosInstance"; // Custom axios instance
import JobDetails from '../../components/JobDetails';
import JobDetailsLiveFeed from '../../components/JobDetailsLiveFeed';
import { Box, Flex } from '@chakra-ui/react';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const Job = () => {
    const { jobId, referralId } = useParams();
    const query = useQuery();
    const referralRole = query.get('referralRole');
    const [selectedJob, setSelectedJob] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/api/job/${jobId}`);
                setSelectedJob(response.data);
            } catch (error) {
                console.error("Failed to fetch job details:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [jobId]);

    useEffect(() => {
        localStorage.setItem('jobId', jobId);
        localStorage.setItem('userId', referralId);
    }, [jobId, referralId]);

    if (loading) {
        return <p>Loading job details...</p>;
    }

    if (!selectedJob) {
        return <p>Job not found or failed to load details.</p>;
    }

    function generateJobPostingJSONLD(selectedJob) {
        const validThroughDate = new Date();
        validThroughDate.setDate(validThroughDate.getDate() + 30);

        const companyInfo = selectedJob.companyId;

        // Prepare the JSON-LD structure
        const structuredData = {
            "@context": "http://schema.org/",
            "@type": "JobPosting",
            "title": selectedJob.jobTitle,
            "description": selectedJob.description,
            "identifier": {
                "@type": "PropertyValue",
                "name": "JobJar.Ai",
                "value": selectedJob.id
            },
            "datePosted": new Date(selectedJob.createdAt).toISOString(),
            "validThrough": validThroughDate.toISOString(),
            "employmentType": selectedJob.employmentType,
            "hiringOrganization": {
                "@type": "Organization",
                "name": companyInfo?.companyName,
                "sameAs": companyInfo?.website,
                "logo": companyInfo?.logo
            },
            "jobLocation": {
                "@type": "Place",
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": selectedJob.streetAddress,
                    "addressLocality": selectedJob.locationCity,
                    "addressRegion": selectedJob.locationState,
                    "postalCode": selectedJob.postalCode,
                    "addressCountry": selectedJob.locationCountry
                }
            },
            "applicantLocationRequirements": {
                "@type": "Country",
                "name": selectedJob.locationCountry
            },
            "jobLocationType": selectedJob.workLocationType === "Remote" ? "TELECOMMUTE" : null,
            "baseSalary": getBaseSalary(selectedJob),
        };

        // Add the jobLocationType if the job is remote
        if (selectedJob.workLocationType === "Remote") {
            structuredData.jobLocationType = "TELECOMMUTE";
        }
        console.log(structuredData)

        return structuredData;
    }

    function getBaseSalary(job) {
        // Handle hourly payment
        if (job.paymentType === 'hourly' && job.paymentDetails && job.paymentDetails.amount) {
            return {
                "@type": "MonetaryAmount",
                "currency": "USD",
                "value": {
                    "@type": "QuantitativeValue",
                    "value": job.paymentDetails.amount,
                    "unitText": "HOUR"
                }
            };
        }
        // Handle salary payment
        else if (job.paymentType === 'salary' && job.paymentDetails && job.paymentDetails.amount) {
            return {
                "@type": "MonetaryAmount",
                "currency": "USD",
                "value": {
                    "@type": "QuantitativeValue",
                    "value": job.paymentDetails.amount,
                    "unitText": "YEAR" // Assuming annual salary
                }
            };
        }
        // Default case for undefined or other payment types
        else {
            return null;
        }
    }


    const JobPostingStructuredData = ({ selectedJob }) => {
        const structuredData = generateJobPostingJSONLD(selectedJob);
        return (
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
            />
        );
    };

    return (
        <Flex w={"100%"}>
            <Box
                position="sticky"
                top="10" // This sets the top position when it starts sticking.
                w="40%"
                mb={"10"}
                p={4}
                h={"fit-content"}
                overflow="hidden"
                zIndex={10} // Ensure it stays above other content.
            >
                <JobDetailsLiveFeed />
            </Box>

            <JobPostingStructuredData selectedJob={selectedJob} />
            <JobDetails selectedJob={selectedJob} />
        </Flex>
    );
};

export default Job;