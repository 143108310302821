import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios'; // Import Axios
import axiosInstance from '../../components/axiosInstance';
import jwt_decode from 'jwt-decode'; // Import jwt_decode
import {
    Box, Heading, Flex, VStack, HStack, Button, useToast, Text, Center, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    useDisclosure,
    SimpleGrid,
    Spacer
} from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import { useParams, Link } from 'react-router-dom'; // Import Link from React Router
import Background from '../../assets/Background.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircleUser, faDollarSign, faFile, faFileInvoice, faIndustry, faLocationDot, faPen, faPeopleGroup, faRecycle, faSliders, faTrashAlt, faUsers } from '@fortawesome/free-solid-svg-icons';
import { color } from 'framer-motion';
import { faFileLines } from '@fortawesome/free-regular-svg-icons';

function UserJobListings() {
    const { businessId } = useParams();
    localStorage.getItem('business_id', businessId);
    const [activeJobListings, setActiveJobListings] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [filledJobListings, setFilledJobListings] = useState([]);
    const [currentJob, setCurrentJob] = useState(null);
    const [referrals, setReferrals] = useState([]);
    const [newApplicantCounts, setNewApplicantCounts] = useState({});
    const [applicantCounts, setApplicantCounts] = useState({});
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [tempExpenses, setTempExpenses] = useState({});
    const [expenseData, setExpenseData] = useState({
        advertisingFees: 0,
        recruiterFees: 0,
        travelCosts: 0,
        internalRecruiterSalary: 0,
        softwareCosts: 0,
        referralBonuses: 0,
        miscellaneousCosts: 0,
    });

    useEffect(() => {
        fetchJobs(); // Fetch jobs when the component mounts
    }, []); // Run this effect only once on component mount

    const fetchedJobIdsRef = useRef(new Set());

    useEffect(() => {
        const fetchAllJobApplicants = async () => {
            for (const job of activeJobListings) {
                if (!fetchedJobIdsRef.current.has(job._id)) {
                    fetchReferralsAndApplicantsForJob(job._id);
                    fetchJobApplicants(job._id);
                    fetchedJobIdsRef.current.add(job._id);
                }
            }
        };

        fetchAllJobApplicants();
    }, [activeJobListings]);

    useEffect(() => {
        const sortedActiveJobs = [...activeJobListings].sort((a, b) => {
            const countA = newApplicantCounts[a._id] || 0;
            const countB = newApplicantCounts[b._id] || 0;
            return countB - countA;
        });

        setActiveJobListings(sortedActiveJobs);
    }, [newApplicantCounts]);

    const fetchJobs = async () => {
        try {
            const token = localStorage.getItem('auth_token');
            if (!token) {
                console.error('Auth token is not available in local storage.');
                return;
            }

            const activeResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/jobs/${businessId}/active`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const inactiveResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/jobs/${businessId}/inactive`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            const { data: activeData } = activeResponse;
            const { data: inactiveData } = inactiveResponse;

            if (Array.isArray(activeData) && Array.isArray(inactiveData)) {
                // Filter only active jobs
                const filteredActiveJobs = activeData.filter(job => job.active);

                setActiveJobListings(filteredActiveJobs);
                setFilledJobListings(inactiveData);

                // Existing code for fetching referrals and applicants...
            } else {
                console.error('Data is not an array:', activeData, inactiveData);
            }
        } catch (error) {
            console.error('Failed to fetch jobs:', error);
        }
    };

    // Function to copy referral link to clipboard
    const copyToClipboard = (link) => {
        navigator.clipboard.writeText(link)
            .then(() => {
                // Show a success toast/notification
                toast({
                    title: 'Link Copied',
                    description: 'The referral link has been copied to your clipboard.',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
            })
            .catch(err => {
                console.error('Error copying link: ', err);
                // Optionally: Show an error toast/notification
                toast({
                    title: 'Error',
                    description: 'Failed to copy the link.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            });
    };

    const fetchReferralsAndApplicantsForJob = async (jobId) => {
        try {
            const token = localStorage.getItem('auth_token');

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/companyLink/${jobId}`, {
                headers: { "Authorization": `Bearer ${token}` }
            });

            setReferrals(prevReferrals => ({
                ...prevReferrals,
                [jobId]: response.data
            }));

            // Fetch applicants
            const applicantResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/employer/getApplicants/${jobId}`, {
                headers: { "Authorization": `Bearer ${token}` }
            });
            const newApplicantCount = applicantResponse.data.filter(applicant => applicant.isFresh).length;

            // Update newApplicantCounts state
            setNewApplicantCounts(prevCounts => ({
                ...prevCounts,
                [jobId]: newApplicantCount
            }));
        } catch (error) {
            if (error.response && error.response.status === 404) {
                // Handle no new applicants case, possibly by setting the applicant count to 0
                setNewApplicantCounts(prevCounts => ({
                    ...prevCounts,
                    [jobId]: 0
                }));
            } else {
                // Handle other errors
                console.error('Error fetching referrals or applicants:', error);
                toast({
                    title: "Error fetching data.",
                    description: "There was an issue fetching the data.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        }
    };

    const fetchJobApplicants = async (jobId) => {
        try {
            const token = localStorage.getItem('auth_token');
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/employer/getJobApplicants/${jobId}`, {
                headers: { "Authorization": `Bearer ${token}` }
            });

            // Assuming that the server returns a 200 status with a count of 0 when there are no applicants.
            setApplicantCounts(prevCounts => ({
                ...prevCounts,
                [jobId]: response.data.totalCount || 0
            }));
        } catch (error) {
            if (error.response && error.response.status !== 404) {
                // Handle other errors, not 404 since we are now assuming that 404 should not be returned by the server for this case
                console.error('Error fetching applicants:', error);
                toast({
                    title: "Error fetching data.",
                    description: "There was an issue fetching the data.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
            // If a 404 is received, which shouldn't happen as per the new server setup, we can simply log it and not update the state
        }
    };

    useEffect(() => {
        // Logic to save to local storage whenever state changes
        localStorage.setItem('activeJobs', JSON.stringify(activeJobListings));
        localStorage.setItem('filledJobs', JSON.stringify(filledJobListings));
    }, [activeJobListings, filledJobListings]);

    const toast = useToast();

    const handleMarkCompleted = async (id) => {
        try {
            const token = localStorage.getItem('auth_token');
            await axios.post(process.env.REACT_APP_API_URL + '/api/jobs/inactive', { jobId: id }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            // If successful, update the state
            const job = activeJobListings.find((job) => job._id === id);
            setFilledJobListings((prevFilledJobs) => [...prevFilledJobs, job]);
            setActiveJobListings((prevActiveJobs) => prevActiveJobs.filter((job) => job._id !== id));

            // Display success toast
            toast({
                title: "Job marked as completed.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });

        } catch (error) {
            console.error('Failed to mark job as completed:', error);
            // Display error toast
            toast({
                title: "Error updating job status.",
                description: "Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleDeleteJob = async (id) => {
        try {
            const token = localStorage.getItem('auth_token');
            await axiosInstance.delete(`/jobs/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            // Remove the job from the filledJobListings state
            setFilledJobListings((prevFilledJobs) => prevFilledJobs.filter((job) => job._id !== id));

            // Display success toast
            toast({
                title: "Job deleted successfully.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Failed to delete job:', error);

            // Check for 403 Forbidden error
            if (error.response && error.response.status === 403) {
                toast({
                    title: 'Permission Error',
                    description: 'You do not have permission to make this change.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                // Display generic error toast for other errors
                toast({
                    title: "Error deleting job.",
                    description: "Please try again.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        }
    };

    const handleReactivate = async (id) => {
        try {
            const token = localStorage.getItem('auth_token');
            await axios.post(process.env.REACT_APP_API_URL + '/api/jobs/reactivate', { jobId: id }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            // If successful, update the state
            const job = filledJobListings.find((job) => job._id === id);
            setActiveJobListings((prevActiveJobs) => [...prevActiveJobs, job]);
            setFilledJobListings((prevFilledJobs) => prevFilledJobs.filter((job) => job._id !== id));

            // Display success toast
            toast({
                title: "Job reactivated.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });

        } catch (error) {
            console.error('Failed to reactivate job:', error);
            // Display error toast
            toast({
                title: "Error reactivating job.",
                description: "Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const openExpenseModal = async (job) => {
        console.log('openExpenseModal Job', job);
        setCurrentJob(job);
        onOpen();
        setTempExpenses({
            advertisingFees: '',
            recruiterFees: '',
            travelCosts: '',
            internalRecruiterSalary: '',
            softwareCosts: '',
            referralBonuses: '',
            miscellaneousCosts: ''
        });
    }

    const closeModal = () => {
        setCurrentJob(null);
        onClose();
        setTempExpenses({
            advertisingFees: '',
            recruiterFees: '',
            travelCosts: '',
            internalRecruiterSalary: '',
            softwareCosts: '',
            referralBonuses: '',
            miscellaneousCosts: ''
        });
    };

    const handleExpenseChange = (e) => {
        const { name, value } = e.target;
        setTempExpenses(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleAddExpense = async () => {
        setIsSaving(true);
        if (!currentJob) return;

        // Parse and update expenseData only here
        const updatedExpenses = {};
        for (const key in tempExpenses) {
            updatedExpenses[key] = parseFloat(tempExpenses[key]) || 0;
        }

        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/jobs/updateExpenses/${currentJob._id}`, { recruitmentCosts: updatedExpenses });
            console.log('Expenses updated', response);

            const updatedJobs = activeJobListings.map(job => job._id === currentJob._id ? { ...job, recruitmentCosts: updatedExpenses } : job);
            setActiveJobListings(updatedJobs);

            closeModal();
        } catch (error) {
            console.error('Error updating expenses:', error);
        }
        setIsSaving(false);
    };

    return (
        <Flex direction="column" h="100vh" w="100%">
            {/* Background container */}
            <Box
                position="fixed"
                top={0}
                left={0}
                right={0}
                bottom={0}
                bgImage={Background}
                bgSize="cover"
                bgRepeat="no-repeat"
                bgPosition="center"
                zIndex={-1}
            />
            {/* Content container */}
            <Flex flex="1" w="100%" justify={"center"} pl="300px">
                <Flex direction="column" p={2} w="100%" overflowY="scroll">
                    <VStack p={2} w="100%" spacing={4}>
                        {/* Active Job Listings */}
                        {activeJobListings && (
                            <VStack p={4} bg="blackAlpha.600" w="fit-content" borderRadius="2xl">
                                <Flex w={"100%"} justify={"flex-start"}>
                                    <Heading textColor={"white"} mb={4}>Active Jobs</Heading>
                                </Flex>
                                <SimpleGrid maxH={"fit-content"} overflowY={"scroll"} columns={{ md: 1, lg: 2 }} spacing={6}>
                                    {activeJobListings.map((job) => (
                                        <Flex
                                            bg={"whiteAlpha.600"}
                                            key={job._id}
                                            direction={"column"}
                                            mb={4}
                                            w={"100%"}
                                            shadow={newApplicantCounts[job._id] > 0 ? "dark-lg" : "xl"}
                                            borderRadius="lg"
                                            _hover={{ backgroundColor: "whiteAlpha.400" }}
                                        >
                                            <HStack justify={"space-between"} w={"100%"}>

                                                <Flex m={2} mr={4} justify={"flex-start"}>
                                                    <Link to={`/job/${job._id}`}>
                                                        <Heading
                                                            color={"white"}
                                                            fontSize={"2xl"}
                                                            fontWeight={"semibold"}
                                                        >
                                                            {job.jobTitle.length > 30 ? `${job.jobTitle.substring(0, 30)}...` : job.jobTitle}
                                                        </Heading>

                                                    </Link>
                                                </Flex>

                                                <Flex m={2} mr={4}>
                                                    <FontAwesomeIcon color={"white"} size='md' icon={faIndustry} />
                                                    <Text color={"white"} fontSize={"sm"} ml={2}>{job.industry}</Text>
                                                </Flex>
                                                <Flex m={2}>
                                                    <FontAwesomeIcon color={"white"} size='md' icon={faLocationDot} />
                                                    <Text color={"white"} fontSize={"sm"} ml={2}>{job.locationCity}, {job.locationState}</Text>
                                                </Flex>


                                                <Flex justify={"flex-end"} mr={4}>
                                                    {referrals[job._id] && referrals[job._id].length > 0 && referrals[job._id][0].referralLink ? (
                                                        <>
                                                            <Center>
                                                                <Box>
                                                                    <Link to={referrals[job._id][0].referralLink}>
                                                                        <Button size={"sm"} _hover={{ color: 'white' }} color={"lightgrey"} variant='link' mr={4} >View Job</Button>
                                                                    </Link>
                                                                </Box>

                                                                <Center>
                                                                    <Button size={"sm"} _hover={{ color: 'white' }} shadow={"lg"} variant='outline' color={"lightgrey"} leftIcon={<CopyIcon />} onClick={() => copyToClipboard(referrals[job._id][0].referralLink)}>
                                                                        Share Job
                                                                    </Button>
                                                                </Center>
                                                            </Center>
                                                        </>
                                                    ) : (
                                                        <Text mr={4} color={"white"}>Referral link not available</Text>
                                                    )}
                                                </Flex>
                                            </HStack>

                                            <HStack p={1} m={2} justify={"space-between"} align={"center"} h={"50px"} borderRadius={"2xl"} bg={"blackAlpha.700"}>
                                                {/*   <Flex justify={"flex-start"} w={"100%"}>
                                                    <Text fontSize={"xl"} fontWeight={"semibold"} color={"lightgrey"} mr={2}>Total Applicants: {applicantCounts[job._id] || 0}</Text>
                                                </Flex>
                                                <Flex justify={"flex-start"} w={"100%"} >
                                                    {newApplicantCounts[job._id] > 0 && (
                                                        <Text fontSize={"xl"} color={"white"} >
                                                            New Applicants: {newApplicantCounts[job._id] || 0}
                                                        </Text>
                                                    )}
                                                </Flex> */}
                                                <Flex w={"fit-content"} ml={4} justify={"space-between"} >
                                                    {/* Existing Buttons */}
                                                    <Button size={"sm"} _hover={{ bg: "black", color: "white", border: "2px", borderColor: "white" }} mr={2} bg={"#188AB2"} color={"white"} onClick={() => handleMarkCompleted(job._id)}>
                                                        <Box mr={2}>
                                                            <FontAwesomeIcon icon={faCheck} />
                                                        </Box>
                                                        Mark Completed
                                                    </Button>
                                                    <Link
                                                        to={{
                                                            pathname: `/applicants/${job._id}`,
                                                            state: { jobTitle: job.jobTitle }
                                                        }}
                                                    >
                                                        <Button size={"sm"} _hover={{ bg: "black", color: "white", border: "2px", borderColor: "white" }} mr={2} bg={newApplicantCounts[job._id] > 0 ? "#FFD166" : "whiteSmoke"}>
                                                            <Box mr={2}><FontAwesomeIcon icon={faUsers} /></Box>
                                                            <Box mr={2}>
                                                                View Applicants
                                                            </Box>
                                                            <HStack w={"fit-content"} shadow={"lg"} p={1} bg={"black"} borderRadius={"full"}>
                                                                <Box>
                                                                    <FontAwesomeIcon color='white' icon={faPeopleGroup} />
                                                                </Box>

                                                                <Text color={"white"}>
                                                                    {newApplicantCounts[job._id] || 0}/{applicantCounts[job._id] || 0}
                                                                </Text>
                                                            </HStack>
                                                        </Button>
                                                    </Link>
                                                </Flex>
                                                <Flex w={"100%"} ml={4} justify={"center"} >
                                                    <Link
                                                        to={{
                                                            pathname: `/evaluations/${job._id}`,
                                                            state: { jobTitle: job.jobTitle }
                                                        }}
                                                    >
                                                        <Button size={"sm"} _hover={{ bg: "black", color: "white", border: "2px", borderColor: "white" }} mr={2} bg={"pink.100"}>
                                                            <Box><FontAwesomeIcon size='lg' icon={faSliders} /></Box>
                                                        </Button>
                                                    </Link>
                                                    <Link
                                                        to={{
                                                            pathname: `/skill-assessment/${job._id}`,
                                                            state: { jobTitle: job.jobTitle }
                                                        }}
                                                    >
                                                        <Button size={"sm"} _hover={{ bg: "black", color: "white", border: "2px", borderColor: "white" }} mr={2} bg={"#FFD166"}>
                                                            <Box><FontAwesomeIcon size='lg' icon={faFileLines} /></Box>
                                                        </Button>
                                                    </Link>

                                                    <Button size={"sm"} _hover={{ bg: "black", color: "white", border: "2px", borderColor: "white" }} mr={2} bg={"#01BF02"} onClick={() => openExpenseModal(job)}>
                                                        <Box><FontAwesomeIcon size='lg' icon={faDollarSign} /></Box>
                                                    </Button>

                                                    <Modal size='xl' isOpen={isOpen} onClose={onClose}>
                                                        <ModalOverlay />
                                                        <ModalContent>
                                                            <ModalHeader>Add expenses to job: {currentJob?.jobTitle}</ModalHeader>
                                                            <ModalCloseButton />
                                                            <ModalBody pb={2}>
                                                                <FormControl>
                                                                    <SimpleGrid columns={{ base: 2 }} spacing={5}>
                                                                        <Box>
                                                                            <FormLabel mt={4}>Advertising Fees</FormLabel>
                                                                            <Input
                                                                                name="advertisingFees"
                                                                                placeholder="Advertising Fees"
                                                                                type="number"
                                                                                value={tempExpenses.advertisingFees || ''}
                                                                                onChange={handleExpenseChange}
                                                                            />
                                                                        </Box>
                                                                        <Box>
                                                                            <FormLabel mt={4}>Recruiter Fees</FormLabel>
                                                                            <Input
                                                                                name="recruiterFees"
                                                                                placeholder="Recruiter Fees"
                                                                                type="number"
                                                                                value={tempExpenses.recruiterFees || ''}
                                                                                onChange={handleExpenseChange}
                                                                            />
                                                                        </Box>
                                                                        <Box>
                                                                            <FormLabel mt={4}>Travel Costs</FormLabel>
                                                                            <Input
                                                                                name="travelCosts"
                                                                                placeholder="Travel Costs"
                                                                                type="number"
                                                                                value={tempExpenses.travelCosts || ''}
                                                                                onChange={handleExpenseChange}
                                                                            />
                                                                        </Box>
                                                                        <Box>
                                                                            <FormLabel mt={4}>Internal Recruiter Salary</FormLabel>
                                                                            <Input
                                                                                name="internalRecruiterSalary"
                                                                                placeholder="Internal Recruiter Salary"
                                                                                type="number"
                                                                                value={tempExpenses.internalRecruiterSalary || ''}
                                                                                onChange={handleExpenseChange}
                                                                            />
                                                                        </Box>
                                                                        <Box>
                                                                            <FormLabel mt={4}>Software Costs</FormLabel>
                                                                            <Input
                                                                                name="softwareCosts"
                                                                                placeholder="Software Costs"
                                                                                type="number"
                                                                                value={tempExpenses.softwareCosts || ''}
                                                                                onChange={handleExpenseChange}
                                                                            />
                                                                        </Box>
                                                                        <Box>
                                                                            <FormLabel mt={4}>Referral Bonuses</FormLabel>
                                                                            <Input
                                                                                name="referralBonuses"
                                                                                placeholder="Referral Bonuses"
                                                                                type="number"
                                                                                value={tempExpenses.referralBonuses || ''}
                                                                                onChange={handleExpenseChange}
                                                                            />
                                                                        </Box>
                                                                        <Box>
                                                                            <FormLabel mt={4}>Miscellaneous Costs</FormLabel>
                                                                            <Input
                                                                                name="miscellaneousCosts"
                                                                                placeholder="Miscellaneous Costs"
                                                                                type="number"
                                                                                value={tempExpenses.miscellaneousCosts || ''}
                                                                                onChange={handleExpenseChange}
                                                                            />
                                                                        </Box>
                                                                    </SimpleGrid>
                                                                </FormControl>
                                                            </ModalBody>
                                                            <ModalFooter>
                                                                <Button isLoading={isSaving} colorScheme="blue" mr={3} onClick={() => handleAddExpense(job)}>
                                                                    Save
                                                                </Button>
                                                                <Button onClick={closeModal}>Cancel</Button>
                                                            </ModalFooter>
                                                        </ModalContent>
                                                    </Modal>
                                                </Flex>
                                                <Flex justify={"flex-end"}>
                                                    <Link to={`/edit-job/${job._id}`}>
                                                        <Button size={"sm"} _hover={{ bg: "black", color: "white", border: "2px", borderColor: "white" }} mr={2} colorScheme="">
                                                            <Box mr={2}><FontAwesomeIcon icon={faPen} /></Box>
                                                            Edit Job
                                                        </Button>
                                                    </Link>
                                                </Flex>
                                            </HStack>
                                        </Flex>
                                    ))}
                                </SimpleGrid>
                            </VStack>
                        )}
                        {filledJobListings.length > 0 && (
                            <VStack p={4} bg="whiteAlpha.800" w="100%" borderRadius="2xl">
                                <Flex w={"100%"} justify={"flex-start"}>
                                    <Heading textColor={"black"} mb={4}>Completed Jobs</Heading>
                                </Flex>
                                {filledJobListings.map((job) => (
                                    <Box
                                        bg={newApplicantCounts[job._id] > 0 ? "white" : "whiteAlpha.600"}
                                        key={job._id}
                                        p={3}
                                        mb={4}
                                        w={"100%"}
                                        shadow={newApplicantCounts[job._id] > 0 ? "dark-lg" : "lg"}
                                        borderRadius="lg"
                                        _hover={{ backgroundColor: "whiteAlpha.400" }}
                                    >
                                        <HStack w={"100%"}>
                                            <Flex p={1} w={"100%"} align={"center"} justify={"flex-start"}>
                                                <Flex m={2} justify={"flex-start"}>
                                                    <Link to={`/job/${job._id}`}>
                                                        <Heading fontWeight={"md"} >{job.jobTitle}</Heading>
                                                    </Link>
                                                </Flex>
                                                <Flex m={2} justify={"flex-start"}>
                                                    <Text>{job.industry}</Text>
                                                </Flex>
                                                <Text>-</Text>
                                                <Flex m={2} justify={"flex-start"}>
                                                    <Text>{job.locationCity}, {job.locationState}</Text>
                                                </Flex>
                                            </Flex>
                                            <Flex w={"100%"} align={"center"} justify={"flex-end"}>
                                                <HStack w={"fit-content"} shadow={"xl"} p={4} bg={newApplicantCounts[job._id] > 0 ? "#FFD166" : "blackAlpha.100"} borderRadius={"full"} mr={1}>
                                                    <Box mr={2}>
                                                        <FontAwesomeIcon icon={faPeopleGroup} />
                                                    </Box>
                                                    <Text>Applicants</Text>
                                                    <>
                                                        {newApplicantCounts[job._id] || 0}/{applicantCounts[job._id] || 0}
                                                    </>
                                                </HStack>
                                                <Button ml={2} mr={2} colorScheme="red" onClick={() => handleDeleteJob(job._id)}>
                                                    <Box mr={2}><FontAwesomeIcon icon={faTrashAlt} /></Box>
                                                    Delete Job
                                                </Button>
                                            </Flex>
                                        </HStack>
                                        <HStack justify={"space-between"} align={"center"} h={"75px"} w={"100%"} borderRadius={"lg"} bg={"black"} >
                                            <HStack justify={"space-between"} w={"100%"} align={"center"}>
                                                <Flex ml={4} w={"fit-content"}>
                                                    <Button mr={2} bg="#118AB2" color={"white"} onClick={() => handleReactivate(job._id)}>
                                                        <Box mr={2}><FontAwesomeIcon icon={faRecycle} /></Box>
                                                        Reactivate
                                                    </Button>
                                                    <Link
                                                        to={{
                                                            pathname: `/applicants/${job._id}`,
                                                            state: { jobTitle: job.jobTitle }
                                                        }}
                                                    >
                                                        <Button mr={2} bg="lightgrey">
                                                            <Box mr={2}><FontAwesomeIcon icon={faCircleUser} /></Box>
                                                            View Applicants
                                                        </Button>
                                                    </Link>
                                                    <Link
                                                        to={{
                                                            pathname: `/evaluations/${job._id}`,
                                                            state: { jobTitle: job.jobTitle }
                                                        }}
                                                    >
                                                        <Button mr={2} bg={"pink.100"}>
                                                            <Box mr={2}><FontAwesomeIcon icon={faSliders} /></Box>
                                                            Evaluations
                                                        </Button>
                                                    </Link>
                                                    <Link
                                                        to={{
                                                            pathname: `/skill-assessment/${job._id}`,
                                                            state: { jobTitle: job.jobTitle }
                                                        }}
                                                    >
                                                        <Button bg={"#FFD166"}>
                                                            <Box mr={2}><FontAwesomeIcon icon={faFile} /></Box>
                                                            Skill Assessment
                                                        </Button>
                                                    </Link>
                                                    {/* New Button to Edit Job */}
                                                    <Link to={`/edit-job/${job._id}`}>
                                                        <Button mr={2} colorScheme="">
                                                            <Box mr={2}><FontAwesomeIcon icon={faPen} /></Box>
                                                            Edit Job
                                                        </Button>
                                                    </Link>
                                                </Flex>
                                                <Flex w={"fit-content"} justify={"end"} mr={4} spacing={2}>
                                                    {referrals[job._id] && referrals[job._id].length > 0 && referrals[job._id][0].referralLink ? (
                                                        <>
                                                            <Center>
                                                                <Box>
                                                                    <Link to={referrals[job._id][0].referralLink}>
                                                                        <Button _hover={{ color: 'white' }} color={"lightgrey"} variant='link' mr={4} >View Job</Button>
                                                                    </Link>
                                                                </Box>

                                                                <Center>
                                                                    <Button size="sm" _hover={{ color: 'white' }} shadow={"lg"} variant='outline' color={"lightgrey"} leftIcon={<CopyIcon />} onClick={() => copyToClipboard(referrals[job._id][0].referralLink)}>
                                                                        Share Job
                                                                    </Button>
                                                                </Center>
                                                            </Center>
                                                        </>
                                                    ) : (
                                                        <Text color={"white"}>Referral link not available</Text>
                                                    )}
                                                </Flex>
                                            </HStack>
                                        </HStack>
                                    </Box>
                                ))}
                            </VStack>
                        )}
                    </VStack>
                </Flex>
            </Flex>
        </Flex>
    );
}

export default UserJobListings;