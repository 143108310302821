// ProtectedRoute.js
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import axiosInstance from './axiosInstance';

const ProtectedRoute = ({ children }) => {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const checkAuth = async () => {
            const token = localStorage.getItem('auth_token');
            if (!token) {
                // No token found, redirect to login
                navigate('/login');
                return;
            }

            try {
                const decodedToken = jwtDecode(token);
                const currentTime = Date.now() / 1000;

                if (decodedToken.exp < currentTime) {
                    // Token is expired, try refreshing it
                    try {
                        await axiosInstance.post('/refresh', {
                            refreshToken: localStorage.getItem('refresh_token')
                        });
                        // Token refresh successful, set isAuthenticated to true
                        setIsAuthenticated(true);
                    } catch (error) {
                        console.error('Error refreshing token:', error);
                        navigate('/login');
                    }
                } else {
                    // Token is valid
                    setIsAuthenticated(true);
                }
            } catch (error) {
                console.error('Error decoding token:', error);
                navigate('/login');
            }
        };

        checkAuth();
    }, [navigate]);

    if (isAuthenticated) {
        return children;
    } else {
        // Optionally, render a loading spinner or message here
        return null;
    }
};

export default ProtectedRoute;
