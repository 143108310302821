// src/components/JobSeekerForm.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box, FormControl, FormLabel, Input, Select, Stack, Button, Textarea,
    Checkbox, Tag, TagLabel, TagCloseButton, InputGroup,
    Flex, Heading, HStack, VStack, InputLeftElement, InputRightAddon,
    Tabs, TabList, TabPanels, Tab, TabPanel, Divider, Text, Image, Center, Grid, SimpleGrid
} from '@chakra-ui/react';
import axios from 'axios';
import CreatableSelect from 'react-select/creatable';
import InputMask from 'react-input-mask';
import SkillsInput from './SkillInput';
import ProfilePictureUpload from './PFPUpload';
import Background from '../assets/Background.webp';
import IndustryExperienceInput from './IndustryExperienceInput';
import Header from './Header';
import axiosInstance from './axiosInstance';
import BillingInformation from './JobSeekerPayment';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const JobSeekerForm = () => {
    // Initialize all fields based on your schema
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        PFP: String,
        phoneNumber: '',
        candidateSummary: '',
        desiredSalary: '',
        desiredHourlyRate: '',
        earningsType: '',
        currentSalary: '',
        currentHourlyRate: '',
        workLocationType: '',
        dreamJob: '',
        idealJobs: [],
        availability: '',
        preferredLocation: {
            preferredCity: '',
            preferredState: '',
        },
        employmentType: '',
        dealBreakers: {
            earningsType: false,
            currentSalary: false,
            currentHourlyRate: false,
            desiredSalary: false,
            desiredHourlyRate: false,
            workLocationType: false,
            preferredLocation: {
                preferredCity: false,
                preferredState: false,
            },
            employmentType: false,
        },
        interestedIndustries: [],
        interestedJobTitles: [],
        newIndustry: '',
        skills: [],
        skillLevels: [],
        education: [],
        licenses: [],
        workHistory: [],
        streetAddress: '',
        locationCity: '',
        locationState: '',
        zipCode: null,
        LocationCountry: '',
        socialProfiles: [],
        languages: [],
        industryExperience: [{
            industry: '',
            yearsExperience: '',
            notableProjects: [{
                projectName: '',
                description: '',
                duration: '',
                role: '',
            }],
            technologiesUsed: [],
        }],
        portfolioURL: '',
        certifications: [{
            name: '',
            issuingOrganization: '',
            dateIssued: null,
            expirationDate: null,
        }],

        softwareExperience: [],
        hobbies: [],
        securityClearance: {
            level: '',
            issuedBy: '',
            issueDate: null,
            expirationDate: null
        },
        careerTraining: [],
        endorsements: [],
        awards: [],
        publications: [],
    });
    const [jobSeeker, setJobSeeker] = useState({});
    const [industryOptions, setIndustryOptions] = useState([]);
    const [isIndustryLoading, setIsIndustryLoading] = useState(false);
    const [isDismissing, setIsDismissing] = useState(false);
    const [earningsType, setEarningsType] = useState('salary');
    const [jobTitleOptions, setJobTitleOptions] = useState([]);
    const [isJobTitleLoading, setIsJobTitleLoading] = useState(false);
    const authToken = localStorage.getItem('auth_token');

    const fetchJobSeekerData = async () => {
        try {
            const response = await axiosInstance.get('/jobseekers/me', {
                headers: { 'Authorization': `Bearer ${authToken}` }
            });
            const jobSeekerData = response.data;
            setJobSeeker(jobSeekerData);

            setFormData({
                // Basic information
                name: jobSeekerData.firstName + ' ' + jobSeekerData.lastName || '',
                email: jobSeekerData.email || '',
                PFP: jobSeekerData.PFP || '',
                phoneNumber: jobSeekerData.phoneNumber || '',
                candidateSummary: jobSeekerData.jobSeekerDetails.candidateSummary || '',

                // Employment and location preferences
                desiredSalary: jobSeekerData.jobSeekerDetails.desiredSalary || '',
                desiredHourlyRate: jobSeekerData.jobSeekerDetails.desiredHourlyRate || '',
                earningsType: jobSeekerData.jobSeekerDetails.earningsType || '',
                currentSalary: jobSeekerData.jobSeekerDetails.currentSalary || '',
                currentHourlyRate: jobSeekerData.jobSeekerDetails.currentHourlyRate || '',
                workLocationType: jobSeekerData.jobSeekerDetails.workLocationType || '',
                dreamJob: jobSeekerData.jobSeekerDetails.dreamJob || '',
                idealJobs: jobSeekerData.jobSeekerDetails.idealJobs || [],
                availability: jobSeekerData.availability || '',
                preferredLocation: {
                    preferredCity: jobSeekerData.jobSeekerDetails.preferredLocation?.preferredCity || '',
                    preferredState: jobSeekerData.jobSeekerDetails.preferredLocation?.preferredState || '',
                },
                employmentType: jobSeekerData.jobSeekerDetails.employmentType || '',

                // Deal breakers
                dealBreakers: {
                    workLocationType: jobSeekerData.jobSeekerDetails.dealBreakers?.workLocationType || false,
                    preferredLocation: {
                        preferredCity: jobSeekerData.jobSeekerDetails.dealBreakers?.preferredLocation?.preferredCity || false,
                        preferredState: jobSeekerData.jobSeekerDetails.dealBreakers?.preferredLocation?.preferredState || false,
                    },
                    employmentType: jobSeekerData.jobSeekerDetails.dealBreakers?.employmentType || false,
                },

                // Interests
                interestedIndustries: jobSeekerData.jobSeekerDetails.interestedIndustries || [],
                interestedJobTitles: jobSeekerData.jobSeekerDetails.interestedJobTitles || [],

                // Skills and experiences
                skills: jobSeekerData.jobSeekerDetails.skills || [],
                skillLevels: jobSeekerData.jobSeekerDetails.skillLevels || [],
                education: jobSeekerData.jobSeekerDetails.education || [],
                licenses: jobSeekerData.jobSeekerDetails.licenses || [],
                workHistory: jobSeekerData.jobSeekerDetails.workHistory || [],
                streetAddress: jobSeekerData.jobSeekerDetails.streetAddress || '',
                locationCity: jobSeekerData.jobSeekerDetails.locationCity || '',
                locationState: jobSeekerData.jobSeekerDetails.locationState || '',
                zipCode: jobSeekerData.jobSeekerDetails.zipCode || null,
                LocationCountry: jobSeekerData.jobSeekerDetails.LocationCountry || '',
                socialProfiles: jobSeekerData.jobSeekerDetails.socialProfiles || [],
                languages: jobSeekerData.jobSeekerDetails.languages || [],
                industryExperience: jobSeekerData.jobSeekerDetails.industryExperience || [],
                portfolioURL: jobSeekerData.jobSeekerDetails.portfolioURL || '',
                certifications: jobSeekerData.jobSeekerDetails.certifications || [],
                softwareExperience: jobSeekerData.jobSeekerDetails.softwareExperience || [],
                hobbies: jobSeekerData.jobSeekerDetails.hobbies || [],
                securityClearance: jobSeekerData.jobSeekerDetails.securityClearance || {},
                careerTraining: jobSeekerData.jobSeekerDetails.careerTraining || [],
                endorsements: jobSeekerData.jobSeekerDetails.endorsements || [],
                awards: jobSeekerData.jobSeekerDetails.awards || [],
                publications: jobSeekerData.jobSeekerDetails.publications || [],
            });
        } catch (error) {
            console.error('Error fetching job seeker data:', error);
        }
    };

    useEffect(() => {
        fetchJobSeekerData();
    }, []);

    useEffect(() => {
        const fetchIndustries = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/industries/search`);
                const formattedIndustries = response.data.map(industry => ({
                    label: industry.industry,
                    value: industry.industry
                }));
                setIndustryOptions(formattedIndustries);
            } catch (error) {
                console.error(error);
            }
        };
        fetchIndustries();
    }, []);

    const debounce = (fn, delay) => {
        let timeoutId;
        return function (...args) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                fn(...args);
            }, delay);
        };
    };

    useEffect(() => {
        // Function to fetch job titles
        const fetchJobTitles = async () => {
            setIsJobTitleLoading(true);
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + '/api/jobtitles');
                setJobTitleOptions(response.data.map(jobTitle => ({
                    label: jobTitle.title,
                    value: jobTitle.title
                })));
            } catch (error) {
                console.error(error);
            } finally {
                setIsJobTitleLoading(false);
            }
        };

        fetchJobTitles();
    }, []);

    const handleJobTitleSearch = debounce(async (inputValue) => {
        if (!inputValue || inputValue.length < 3) {
            return;
        }
        setIsJobTitleLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/jobtitles/search?query=${inputValue}`);
            const formattedResponse = response.data.map(job => ({
                label: job.title,
                value: job.title
            }));
            setJobTitleOptions(formattedResponse);
        } catch (error) {
            console.error(error);
        } finally {
            setIsJobTitleLoading(false);
        }
    }, 300);

    const handleJobTitleChange = (newValue, actionMeta) => {
        if (actionMeta.action === 'create-option' || actionMeta.action === 'select-option') {
            const newJobTitles = newValue.map(option => option.value); // Transform the array of objects to an array of strings
            setFormData(prevState => ({
                ...prevState,
                interestedJobTitles: [...prevState.interestedJobTitles, ...newJobTitles],
                newJobTitle: '' // Clear the input after adding a job title
            }));
        }
    };

    const handleHobbiesChange = (e) => {
        const { value } = e.target;

        // Split the string by commas and trim each item to remove leading/trailing spaces
        const hobbiesArray = value.split(',').map(item => item.trim());

        setFormData({
            ...formData,
            hobbies: hobbiesArray,
        });
    };

    // Function to add a new endorsement with default values
    const handleAddEndorsement = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            endorsements: [...prevFormData.endorsements, { endorsedBy: '', endorsement: '' }],
        }));
    };

    // Function to handle endorsement field changes
    const handleEndorsementChange = (index, e) => {
        const updatedEndorsements = formData.endorsements.map((endorsement, i) => {
            if (i === index) {
                return { ...endorsement, [e.target.name]: e.target.value };
            }
            return endorsement;
        });
        setFormData({ ...formData, endorsements: updatedEndorsements });
    };

    // Function to remove an endorsement
    const handleRemoveEndorsement = (index) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            endorsements: prevFormData.endorsements.filter((_, i) => i !== index),
        }));
    };

    const handleIndustrySearch = debounce(async (inputValue) => {
        if (!inputValue || inputValue.length < 3) {
            return;
        }
        setIsIndustryLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/industries/search?query=${inputValue}`);
            const formattedResponse = response.data.map(ind => ({
                label: ind.industry,
                value: ind.industry
            }));
            setIndustryOptions(formattedResponse);
        } catch (error) {
            console.error(error);
        } finally {
            setIsIndustryLoading(false);
        }
    }, 300);

    const handleIndustryChange = (newValue, actionMeta) => {
        if (actionMeta.action === 'create-option' || actionMeta.action === 'select-option') {
            setFormData(prevState => ({
                ...prevState,
                interestedIndustries: [...prevState.interestedIndustries, newValue.value], // store just the string value
                newIndustry: ''
            }));
        }
    };

    const handleRemoveInterestedIndustry = (index) => {
        setFormData(prevState => ({
            ...prevState,
            interestedIndustries: prevState.interestedIndustries.filter((_, i) => i !== index)
        }));
    };

    const handleEarningsTypeChange = (e) => {
        setEarningsType(e.target.value);
    };

    const handleRemoveInterestedJobTitle = (index) => {
        setFormData(prevState => ({
            ...prevState,
            interestedJobTitles: prevState.interestedJobTitles.filter((_, i) => i !== index)
        }));
    };

    const [isLoading, setIsLoading] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleLicenseChange = (e) => {
        const { value } = e.target;

        // Split the string by commas and trim each item to remove leading/trailing spaces
        const licensesArray = value.split(',').map(item => item.trim());

        setFormData({
            ...formData,
            licenses: licensesArray,
        });
    };

    const handleLanguageChange = (e) => {
        const { value } = e.target;

        // Split the string by commas and trim each item to remove leading/trailing spaces
        const languagesArray = value.split(',').map(item => item.trim());

        setFormData({
            ...formData,
            languages: languagesArray,
        });
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            dealBreakers: {
                ...prevState.dealBreakers,
                [name]: checked,
            },
        }));
    };

    const handleDealBreakerChange = (e, field, nested = false) => {
        setFormData(prevState => ({
            ...prevState,
            dealBreakers: nested
                ? {
                    ...prevState.dealBreakers,
                    preferredLocation: {
                        ...prevState.dealBreakers.preferredLocation,
                        [field]: e.target.checked,
                    }
                }
                : {
                    ...prevState.dealBreakers,
                    [field]: e.target.checked,
                }
        }));
    };

    // Function to add a new award with default values
    const handleAddAward = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            awards: [...prevFormData.awards, { title: '', awardedBy: '', dateAwarded: '' }],
        }));
    };

    // Function to handle award field changes
    const handleAwardChange = (index, e) => {
        const updatedAwards = formData.awards.map((award, i) => {
            if (i === index) {
                return { ...award, [e.target.name]: e.target.value };
            }
            return award;
        });
        setFormData({ ...formData, awards: updatedAwards });
    };

    // Function to remove an award
    const handleRemoveAward = (index) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            awards: prevFormData.awards.filter((_, i) => i !== index),
        }));
    };

    // Function to add a new publication with default values
    const handleAddPublication = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            publications: [...prevFormData.publications, { title: '', publishedBy: '', publicationDate: '', url: '' }],
        }));
    };

    // Function to handle publication field changes
    const handlePublicationChange = (index, e) => {
        const updatedPublications = formData.publications.map((publication, i) => {
            if (i === index) {
                return { ...publication, [e.target.name]: e.target.value };
            }
            return publication;
        });
        setFormData({ ...formData, publications: updatedPublications });
    };

    // Function to remove a publication
    const handleRemovePublication = (index) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            publications: prevFormData.publications.filter((_, i) => i !== index),
        }));
    };

    const handleAddNestedItem = (key) => {
        setFormData(prevState => ({
            ...prevState,
            [key]: [...prevState[key], {}]
        }));
    };

    const handleNestedInputChange = (e, nestedArrayKey, index, propertyName) => {
        const { value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [nestedArrayKey]: prevState[nestedArrayKey].map((item, idx) => {
                if (idx === index) {
                    return { ...item, [propertyName]: value };
                }
                return item;
            }),
        }));
    };

    const handlePreferredLocationChange = (e) => {
        const { name, value } = e.target; // 'name' should be 'preferredCity' or 'preferredState'
        setFormData(prevState => ({
            ...prevState,
            preferredLocation: {
                ...prevState.preferredLocation,
                [name]: value,
            },
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const [firstName, lastName] = formData.name.split(' ');

        // Restructure formData to match the jobSeekerDetails schema
        const updatedData = {
            firstName: firstName || '',
            lastName: lastName || '',
            email: formData.email,
            phoneNumber: formData.phoneNumber,
            streetAddress: formData.streetAddress,
            locationCity: formData.locationCity,
            locationState: formData.locationState,
            zipCode: formData.zipCode,
            availability: formData.availability,
            jobSeekerDetails: {
                candidateSummary: formData.candidateSummary,
                earningsType: formData.earningsType,
                currentSalary: formData.currentSalary,
                currentHourlyRate: formData.currentHourlyRate,
                desiredSalary: formData.desiredSalary,
                desiredHourlyRate: formData.desiredHourlyRate,
                workLocationType: formData.workLocationType,
                dreamJob: formData.dreamJob,
                idealJobs: formData.idealJobs,
                preferredLocation: formData.preferredLocation,
                employmentType: formData.employmentType,
                dealBreakers: formData.dealBreakers,
                interestedIndustries: formData.interestedIndustries,
                interestedJobTitles: formData.interestedJobTitles,
                skills: formData.skills,
                skillLevels: formData.skillLevels,
                education: formData.education,
                licenses: formData.licenses,
                workHistory: formData.workHistory,
                streetAddress: formData.streetAddress,
                locationCity: formData.locationCity,
                locationState: formData.locationState,
                zipCode: formData.zipCode,
                LocationCountry: formData.LocationCountry,
                socialProfiles: formData.socialProfiles,
                languages: formData.languages,
                industryExperience: formData.industryExperience,
                portfolioURL: formData.portfolioURL,
                certifications: formData.certifications,
                softwareExperience: formData.softwareExperience,
                hobbies: formData.hobbies,
                securityClearance: formData.securityClearance,
                careerTraining: formData.careerTraining,
                endorsements: formData.endorsements,
                awards: formData.awards,
                publications: formData.publications,
            }
        };

        try {
            const response = await axiosInstance.put(
                '/jobseekers/update',
                updatedData,
                {
                    headers: {
                        'Authorization': `Bearer ${authToken}`
                    }
                }
            );
            console.log(response.data);
        } catch (error) {
            console.error(error);
        }
        setIsLoading(false);
    };

    const handleDismiss = async () => {
        setIsDismissing(true);
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/dismiss`, {}, {
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });
            await fetchJobSeekerData();
            console.log(response.data);
        } catch (error) {
            console.error('Error dismissing message:', error);
            // Handle error
        }
        setIsDismissing(false);
    };

    const navigate = useNavigate();

    const handleBack = () => {
        navigate('/user-home');
    };

    return (
        <Box as="form" onSubmit={handleSubmit} minH={"100vh"} p={5} bg={"gray.100"} //bgImage={Background} bgSize="cover" bgRepeat="no-repeat" bgPosition="center"
        >
            <Header />

            <Button mb={4} onClick={handleBack} bg="#FFD166" _hover={{ bg: "#01BF02" }}>
                Back to Home
            </Button>
            {jobSeeker.firstLogin &&
                <Flex w={"100%"} justify={"center"}>
                    <Flex p={4} mb={4} h={"50px"} shadow={"lg"} bg={"#EF476F"} align={"center"} borderRadius={"xl"}>
                        <FontAwesomeIcon size='xl' color='white' icon={faCircleExclamation} />
                        <Text ml={4} fontSize={"xl"} color={"white"}>
                            Please double check and update your information.
                        </Text>
                        <Button isLoading={isDismissing} size={"sm"} ml={4} onClick={handleDismiss}>
                            Dismiss
                        </Button>
                    </Flex>
                </Flex>
            }
            <Tabs p={4} bg={"whiteAlpha.800"} borderRadius={"2xl"} bgColor={"gray.300"} variant="enclosed" size="md">
                <TabList>
                    <Tab m={1} border={"1px"} borderColor={"white"} borderRadius={"lg"} shadow={"md"} color='white' bg={"black"} _selected={{ color: 'white', bg: '#118AB2' }} >Current Information</Tab>
                    <Tab m={1} border={"1px"} borderColor={"white"} borderRadius={"lg"} shadow={"md"} color='white' bg={"black"} _selected={{ color: 'white', bg: '#118AB2' }} >Resume</Tab>
                    <Tab m={1} border={"1px"} borderColor={"white"} borderRadius={"lg"} shadow={"md"} color='white' bg={"black"} _selected={{ color: 'white', bg: '#118AB2' }} >Skills</Tab>
                    <Tab m={1} border={"1px"} borderColor={"white"} borderRadius={"lg"} shadow={"md"} color='white' bg={"black"} _selected={{ color: 'white', bg: '#118AB2' }} >Education</Tab>
                    <Tab m={1} border={"1px"} borderColor={"white"} borderRadius={"lg"} shadow={"md"} color='white' bg={"black"} _selected={{ color: 'white', bg: '#118AB2' }} >Work History</Tab>
                    <Divider orientation='vertical' m={2} h={"auto"} border={"1px"} borderColor={"black"} />
                    <Center>
                        <Tab m={1} border={"1px"} borderColor={"white"} borderRadius={"lg"} shadow={"md"} color='black' bg={"#FFD166"} _selected={{ color: 'white', bg: '#118AB2' }} >Job Preferences</Tab>
                        <Text color={"#118AB2"}>*Required for SmartMatch Recruiter</Text>
                    </Center>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <Stack spacing={4}>
                            <Box bg={"whiteAlpha.800"} borderRadius={"2xl"} p={4}>
                                <Heading size={"lg"}>Current Information:</Heading>
                                <HStack spacing={4} mt={4} justify="center" w="full">
                                    <Box shadow="xl" bg="#118AB2" borderRadius="xl" p={4}>
                                        <ProfilePictureUpload setFormData={setFormData} formData={formData} />
                                    </Box>
                                    {formData.PFP && (
                                        <Image
                                            mb={4}
                                            shadow="lg"
                                            boxSize="150px"
                                            borderRadius="full"
                                            src={formData.PFP}
                                            alt="Profile"
                                        />
                                    )}
                                </HStack>
                                <HStack mt={2} justify={"space-evenly"}>
                                    <FormControl>
                                        <FormLabel htmlFor="name">Name</FormLabel>
                                        <Input
                                            id="name"
                                            bg={"white"}
                                            name="name"
                                            value={formData.name}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel htmlFor="email">Email</FormLabel>
                                        <Input
                                            type="email"
                                            bg={"white"}
                                            id="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel htmlFor="phoneNumber">Phone Number</FormLabel>
                                        <InputMask
                                            mask="(999) 999-9999"
                                            value={formData.phoneNumber}
                                            onChange={handleInputChange}
                                            disabled={false}
                                            maskChar=" "
                                        >
                                            {() => <Input
                                                id="phoneNumber"
                                                bg={"white"}
                                                type="text"
                                                name="phoneNumber"
                                            />}
                                        </InputMask>
                                    </FormControl>
                                </HStack>
                                <HStack mt={2}>
                                    <FormControl>
                                        <FormLabel htmlFor="streetAddress">Street Address</FormLabel>
                                        <Input
                                            bg={"white"}
                                            id="streetAddress"
                                            name="streetAddress"
                                            value={formData.streetAddress}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel htmlFor="locationCity">City</FormLabel>
                                        <Input
                                            bg={"white"}
                                            id="locationCity"
                                            name="locationCity"
                                            value={formData.locationCity}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel htmlFor="locationState">State</FormLabel>
                                        <Input
                                            bg={"white"}
                                            id="locationState"
                                            name="locationState"
                                            value={formData.locationState}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel htmlFor="zipCode">Zip Code</FormLabel>
                                        <Input
                                            bg={"white"}
                                            id="zipCode"
                                            name="zipCode"
                                            value={formData.zipCode}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                </HStack>
                                <Divider mt={4} mb={4} />
                                <FormControl>
                                    <FormLabel htmlFor="candidateSummary">Candidate Summary</FormLabel>
                                    <Textarea
                                        id="candidateSummary"
                                        bg={"white"}
                                        name="candidateSummary"
                                        value={formData.candidateSummary}
                                        onChange={handleInputChange}
                                    />
                                </FormControl>
                                <HStack justify={"space-evenly"} mt={2}>
                                    <FormControl>
                                        <FormLabel htmlFor="availability">Availability</FormLabel>
                                        <Select
                                            id="availability"
                                            bg={"white"}
                                            name="availability"
                                            value={formData.availability}
                                            onChange={handleInputChange}
                                        >
                                            <option value="" disabled>Select your status</option>
                                            <option value="Employed">Employed</option>
                                            <option value="Looking for work">Looking for work</option>
                                        </Select>
                                    </FormControl>

                                    {/* Employment and Location Preferences */}
                                    <FormControl>
                                        <FormLabel htmlFor="earningsType">Current Earnings Type</FormLabel>
                                        <Select
                                            id="earningsType"
                                            bg={"white"}
                                            name="earningsType"
                                            value={formData.earningsType}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Select an option</option>
                                            <option value="Salary">Salary</option>
                                            <option value="Hourly">Hourly</option>
                                        </Select>
                                    </FormControl>
                                    {formData.earningsType === "Salary" && (
                                        <FormControl>
                                            <FormLabel htmlFor="currentSalary">Current Salary</FormLabel>
                                            <InputGroup>
                                                <InputLeftElement
                                                    pointerEvents='none'
                                                    color='gray.500'
                                                    fontSize='1.2em'
                                                    children='$'
                                                />
                                                <Input
                                                    type="text"
                                                    bg={"white"}
                                                    id="currentSalary"
                                                    name="currentSalary"
                                                    value={formData.currentSalary}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter amount"
                                                />
                                                <InputRightAddon children="per year" />
                                            </InputGroup>
                                        </FormControl>
                                    )}

                                    {formData.earningsType === "Hourly" && (
                                        <FormControl>
                                            <FormLabel htmlFor="currentHourlyRate">Current Hourly Rate</FormLabel>
                                            <InputGroup>
                                                <InputLeftElement
                                                    pointerEvents='none'
                                                    color='gray.500'
                                                    fontSize='1.2em'
                                                    children='$'
                                                />
                                                <Input
                                                    type="text"
                                                    bg={"white"}
                                                    id="currentHourlyRate"
                                                    name="currentHourlyRate"
                                                    value={formData.currentHourlyRate}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter amount"
                                                />
                                                <InputRightAddon children="per hour" />
                                            </InputGroup>
                                        </FormControl>
                                    )}
                                </HStack>
                            </Box>
                        </Stack >
                    </TabPanel>
                    <TabPanel>
                        <Box maxH={"800px"} overflowY={"scroll"} bg={"whiteAlpha.800"} borderRadius={"2xl"} p={4}>
                            <Heading size={"lg"}>Details:</Heading>
                            <VStack mt={2} spacing={4}>
                                <HStack w={"100%"}>
                                    {/* Licenses Section */}
                                    <FormControl>
                                        <FormLabel htmlFor="licenses">Licenses</FormLabel>
                                        <Input
                                            bg={"white"}
                                            id="licenses"
                                            name="licenses"
                                            value={Array.isArray(formData.licenses) ? formData.licenses.join(', ') : ''}
                                            onChange={handleLicenseChange}
                                            placeholder="Enter licenses separated by commas"
                                        />
                                    </FormControl>

                                    {/* Languages Section */}
                                    <FormControl>
                                        <FormLabel htmlFor="languages">Languages</FormLabel>
                                        <Input
                                            bg={"white"}
                                            id="languages"
                                            name="languages"
                                            value={Array.isArray(formData.languages) ? formData.languages.join(', ') : ''}
                                            onChange={handleLanguageChange}
                                            placeholder="Enter languages separated by commas"
                                        />
                                    </FormControl>

                                    <FormControl>
                                        <FormLabel htmlFor="portfolioURL">Portfolio URL</FormLabel>
                                        <Input
                                            bg={"white"}
                                            id="portfolioURL"
                                            name="portfolioURL"
                                            value={formData.portfolioURL}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>

                                    <FormControl>
                                        <FormLabel htmlFor="hobbies">Hobbies</FormLabel>
                                        <Input
                                            bg={"white"}
                                            id="hobbies"
                                            name="hobbies"
                                            value={Array.isArray(formData.hobbies) ? formData.hobbies.join(', ') : ''}
                                            onChange={handleHobbiesChange}
                                            placeholder="Enter hobbies separated by commas"
                                        />
                                    </FormControl>
                                </HStack>
                                <HStack w={"100%"}>
                                    <FormControl>
                                        <FormLabel>Social Profiles</FormLabel>
                                        {formData.socialProfiles.map((profile, index) => (
                                            <Grid templateColumns="repeat(2, 1fr)" gap={4} key={index} mb={2}>
                                                <Input
                                                    placeholder="Platform"
                                                    bg={"white"}
                                                    value={profile.platform}
                                                    onChange={(e) => handleNestedInputChange(e, 'socialProfiles', index, 'platform')}
                                                />
                                                <Input
                                                    placeholder="URL"
                                                    bg={"white"}
                                                    value={profile.url}
                                                    onChange={(e) => handleNestedInputChange(e, 'socialProfiles', index, 'url')}
                                                />
                                            </Grid>
                                        ))}
                                        <Button
                                            bg={"#118AB2"}
                                            _hover={{ bg: "#01BF02" }}
                                            color={"white"}
                                            size={"xs"}
                                            onClick={() => handleAddNestedItem('socialProfiles')}>
                                            Add Social Profile
                                        </Button>
                                    </FormControl>

                                    <IndustryExperienceInput
                                        formData={formData}
                                        setFormData={setFormData}
                                    />
                                </HStack>
                                <FormControl>
                                    <FormLabel>Certifications</FormLabel>
                                    {formData.certifications.map((certification, index) => (
                                        <Box key={index} mb={2}>
                                            <Input
                                                placeholder="Certification Name"
                                                bg={"white"}
                                                value={certification.name}
                                                onChange={(e) => handleNestedInputChange(e, 'certifications', index, 'name')}
                                            />
                                            {/* Additional fields for issuingOrganization, dateIssued, expirationDate */}
                                        </Box>
                                    ))}
                                    <Button bg={"#118AB2"} _hover={{ bg: "#01BF02" }} color={"white"} size={"xs"} onClick={() => handleAddNestedItem('certifications')}>Add Certification</Button>
                                </FormControl>

                                <FormControl>
                                    <FormLabel>Software Experience</FormLabel>
                                    <Grid templateColumns="repeat(6, 1fr)" gap={4}>
                                        {formData.softwareExperience.map((software, index) => (
                                            <React.Fragment key={index}>

                                                <Input
                                                    placeholder="Software Name"
                                                    bg={"white"}
                                                    value={software.softwareName}
                                                    onChange={(e) => handleNestedInputChange(e, 'softwareExperience', index, 'softwareName')}
                                                />
                                            </React.Fragment>
                                        ))}
                                    </Grid>
                                    <Button
                                        mt={4}
                                        bg={"#118AB2"}
                                        _hover={{ bg: "#01BF02" }}
                                        color={"white"}
                                        size={"sm"}
                                        onClick={() => handleAddNestedItem('softwareExperience')}>
                                        Add Software Experience
                                    </Button>
                                </FormControl>
                                <HStack w={"100%"}>
                                    <FormControl>
                                        <FormLabel>Security Clearance</FormLabel>
                                        {/* Assuming securityClearance is not an array but a single object */}
                                        <Input
                                            placeholder="Security Clearance Level"
                                            bg={"white"}
                                            value={formData.securityClearance.level}
                                            onChange={(e) => handleNestedInputChange(e, 'securityClearance', 'level')}
                                        />
                                        {/* Fields for issuedBy, issueDate, expirationDate */}
                                    </FormControl>

                                    <FormControl>
                                        <FormLabel>Career Training</FormLabel>
                                        {formData.careerTraining.map((training, index) => (
                                            <Box key={index} mb={2}>
                                                <Input
                                                    placeholder="Program Name"
                                                    bg={"white"}
                                                    value={training.programName}
                                                    onChange={(e) => handleNestedInputChange(e, 'careerTraining', index, 'programName')}
                                                />
                                                {/* Fields for institution, completionDate */}
                                            </Box>
                                        ))}
                                        <Button bg={"#118AB2"} _hover={{ bg: "#01BF02" }} color={"white"} size={"xs"} onClick={() => handleAddNestedItem('careerTraining')}>Add Career Training</Button>
                                    </FormControl>
                                </HStack>
                                <HStack w={"100%"}>
                                    <FormControl>
                                        <FormLabel>Endorsements</FormLabel>
                                        {formData.endorsements.map((endorsement, index) => (
                                            <Flex key={index} direction="column" mb={4}>
                                                <Input
                                                    placeholder="Endorsed By"
                                                    bg="white"
                                                    value={endorsement.endorsedBy}
                                                    name="endorsedBy"
                                                    onChange={(e) => handleEndorsementChange(index, e)}
                                                    mb={2}
                                                />
                                                <Textarea
                                                    placeholder="Endorsement"
                                                    bg="white"
                                                    value={endorsement.endorsement}
                                                    name="endorsement"
                                                    onChange={(e) => handleEndorsementChange(index, e)}
                                                    mb={2}
                                                />
                                                <Button
                                                    bg={"#EF476F"}
                                                    onClick={() => handleRemoveEndorsement(index)}>
                                                    Remove
                                                </Button>
                                            </Flex>
                                        ))}
                                        <Button
                                            mt={2}
                                            bg="#118AB2"
                                            size={"xs"}
                                            _hover={{ bg: "#01BF02" }}
                                            color="white"
                                            onClick={handleAddEndorsement}>
                                            Add Endorsement
                                        </Button>
                                    </FormControl>

                                    <FormControl>
                                        <FormLabel>Awards</FormLabel>
                                        {formData.awards.map((award, index) => (
                                            <Flex key={index} direction="column" mb={4}>
                                                <Input
                                                    placeholder="Award Title"
                                                    bg="white"
                                                    value={award.title}
                                                    name="title"
                                                    onChange={(e) => handleAwardChange(index, e)}
                                                    mb={2}
                                                />
                                                <Input
                                                    placeholder="Awarded By"
                                                    bg="white"
                                                    value={award.awardedBy}
                                                    name="awardedBy"
                                                    onChange={(e) => handleAwardChange(index, e)}
                                                    mb={2}
                                                />
                                                <Input
                                                    placeholder="Date Awarded"
                                                    bg="white"
                                                    value={award.dateAwarded}
                                                    name="dateAwarded"
                                                    onChange={(e) => handleAwardChange(index, e)}
                                                    mb={2}
                                                />
                                                <Button
                                                    bg={"#EF476F"}
                                                    color={"white"}
                                                    _hover={{ bg: "red" }}
                                                    onClick={() => handleRemoveAward(index)}>
                                                    Remove
                                                </Button>
                                            </Flex>
                                        ))}
                                        <Button
                                            mt={2}
                                            bg="#118AB2"
                                            size={"xs"}
                                            _hover={{ bg: "#01BF02" }}
                                            color="white"
                                            onClick={handleAddAward}>
                                            Add Award
                                        </Button>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Publications</FormLabel>
                                        {formData.publications.map((publication, index) => (
                                            <Flex key={index} direction="column" mb={4}>
                                                <Input
                                                    placeholder="Publication Title"
                                                    bg="white"
                                                    value={publication.title}
                                                    name="title"
                                                    onChange={(e) => handlePublicationChange(index, e)}
                                                    mb={2}
                                                />
                                                <Input
                                                    placeholder="Published By"
                                                    bg="white"
                                                    value={publication.publishedBy}
                                                    name="publishedBy"
                                                    onChange={(e) => handlePublicationChange(index, e)}
                                                    mb={2}
                                                />
                                                <Input
                                                    type="date"
                                                    placeholder="Publication Date"
                                                    bg="white"
                                                    value={publication.publicationDate}
                                                    name="publicationDate"
                                                    onChange={(e) => handlePublicationChange(index, e)}
                                                    mb={2}
                                                />
                                                <Input
                                                    placeholder="URL"
                                                    bg="white"
                                                    value={publication.url}
                                                    name="url"
                                                    onChange={(e) => handlePublicationChange(index, e)}
                                                    mb={2}
                                                />
                                                <Button
                                                    bg={"#EF476F"}
                                                    color={"white"}
                                                    _hover={{ bg: "red" }}
                                                    onClick={() => handleRemovePublication(index)}>
                                                    Remove
                                                </Button>
                                            </Flex>
                                        ))}
                                        <Button
                                            mt={2}
                                            size={"xs"}
                                            bg="#118AB2"
                                            _hover={{ bg: "#01BF02" }}
                                            color="white"
                                            onClick={handleAddPublication}>
                                            Add Publication
                                        </Button>
                                    </FormControl>
                                </HStack>
                            </VStack>
                        </Box>
                    </TabPanel>
                    <TabPanel>
                        {/* Skills Section */}
                        <Flex Flex justify={"center"} w={"100%"}>
                            <Box bg={"blackAlpha.200"} borderRadius={"2xl"} p={4} mb={2} maxH={"850px"}>
                                <SkillsInput formData={formData} setFormData={setFormData} />
                            </Box>
                        </Flex>
                    </TabPanel>
                    <TabPanel>
                        <Stack spacing={4}>
                            <Box>
                                <FormControl>
                                    <FormLabel>Educational Background</FormLabel>
                                    {Array.isArray(formData.education) && formData.education.length > 0 ? (
                                        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={5}>
                                            {formData.education.map((item, index) => (
                                                <Box bg={"whiteAlpha.800"} borderRadius={"2xl"} p={4} key={index} mb={2}>
                                                    <Input
                                                        placeholder="Institution"
                                                        bg={"white"}
                                                        value={item.institution}
                                                        onChange={(e) => handleNestedInputChange(e, 'education', index, 'institution')}
                                                    />
                                                    <Input
                                                        placeholder="Degree"
                                                        bg={"white"}
                                                        value={item.degree}
                                                        onChange={(e) => handleNestedInputChange(e, 'education', index, 'degree')}
                                                        mt={2}
                                                    />
                                                    <Input
                                                        placeholder="Field of Study"
                                                        bg={"white"}
                                                        value={item.fieldOfStudy}
                                                        onChange={(e) => handleNestedInputChange(e, 'education', index, 'fieldOfStudy')}
                                                        mt={2}
                                                    />
                                                    {/* Additional fields for startDate, endDate, and description can be added here */}
                                                </Box>
                                            ))}
                                        </SimpleGrid>
                                    ) : (
                                        <Text>No education data available.</Text>
                                    )}
                                    <Button
                                        mt={4}
                                        bg={"#118AB2"}
                                        _hover={{ bg: "#01BF02" }}
                                        color={"white"}
                                        size={"md"}
                                        onClick={() => handleAddNestedItem('education')}
                                    >
                                        Add Education
                                    </Button>
                                </FormControl>

                            </Box>
                        </Stack>
                    </TabPanel>
                    <TabPanel>
                        <Stack spacing={4}>
                            <Box>
                                <FormControl>
                                    <FormLabel>Work History</FormLabel>
                                    {Array.isArray(formData.workHistory) && formData.workHistory.length > 0 ? (
                                        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={5}>
                                            {formData.workHistory.map((item, index) => (
                                                <Box bg={"whiteAlpha.800"} borderRadius={"2xl"} p={4} key={index}>
                                                    <Input
                                                        placeholder="Title"
                                                        bg={"white"}
                                                        value={item.title}
                                                        onChange={(e) => handleNestedInputChange(e, 'workHistory', index, 'title')}
                                                    />
                                                    <Input
                                                        placeholder="Company"
                                                        bg={"white"}
                                                        value={item.company}
                                                        onChange={(e) => handleNestedInputChange(e, 'workHistory', index, 'company')}
                                                        mt={2}
                                                    />
                                                    <Input
                                                        placeholder="Dates"
                                                        bg={"white"}
                                                        value={item.dates}
                                                        onChange={(e) => handleNestedInputChange(e, 'workHistory', index, 'dates')}
                                                        mt={2}
                                                    />
                                                    {/* Additional fields like locationCity, locationState can be added similarly */}
                                                </Box>
                                            ))}
                                        </SimpleGrid>
                                    ) : (
                                        <Text>No work history data available.</Text>
                                    )}
                                    <Button
                                        mt={4}
                                        bg={"#118AB2"}
                                        _hover={{ bg: "#01BF02" }}
                                        color={"white"}
                                        size={"md"}
                                        onClick={() => handleAddNestedItem('workHistory')}
                                    >
                                        Add Work History
                                    </Button>
                                </FormControl>
                            </Box>
                        </Stack>
                    </TabPanel>
                    <TabPanel>
                        <Stack spacing={4}>
                            <Box bg={"whiteAlpha.800"} borderRadius={"2xl"} p={4}>
                                <Heading size={"lg"}>Desired Setup:</Heading>
                                <HStack align={"flex-start"} mt={2} justify={"space-evenly"}>
                                    <FormControl>
                                        <FormLabel htmlFor="earningsType">Desired Earning Type</FormLabel>
                                        <Select
                                            id="earningsType"
                                            bg={"white"}
                                            name="earningsType"
                                            value={earningsType}
                                            onChange={handleEarningsTypeChange}
                                        >
                                            <option value="">Select an option</option>
                                            <option value="hourly">Desired Hourly Rate</option>
                                            <option value="salary">Desired Salary</option>
                                        </Select>
                                    </FormControl>
                                    {earningsType === 'salary' && (
                                        <FormControl>
                                            <FormLabel htmlFor="desiredSalary">Desired Salary</FormLabel>
                                            <InputGroup>
                                                <InputLeftElement
                                                    pointerEvents='none'
                                                    color='gray.500'
                                                    fontSize='1.2em'
                                                    children='$'
                                                />
                                                <Input
                                                    type="text"
                                                    bg={"white"}
                                                    id="desiredSalary"
                                                    name="desiredSalary"
                                                    value={formData.desiredSalary}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter amount"
                                                />
                                                <InputRightAddon children="per year" />
                                            </InputGroup>
                                            <Checkbox
                                                isChecked={formData.dealBreakers.earningsType}
                                                onChange={(e) => handleDealBreakerChange(e, 'earningsType')}
                                            >
                                                Earnings Type Dealbreaker
                                            </Checkbox>
                                        </FormControl>
                                    )}

                                    {earningsType === 'hourly' && (
                                        <FormControl>
                                            <FormLabel htmlFor="desiredHourlyRate">Desired Hourly Rate</FormLabel>
                                            <InputGroup>
                                                <InputLeftElement
                                                    pointerEvents='none'
                                                    color='gray.500'
                                                    fontSize='1.2em'
                                                    children='$'
                                                />
                                                <Input
                                                    type="text"
                                                    bg={"white"}
                                                    id="desiredHourlyRate"
                                                    name="desiredHourlyRate"
                                                    value={formData.desiredHourlyRate}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter amount"
                                                />
                                                <InputRightAddon children="per hour" />
                                            </InputGroup>
                                            <Checkbox
                                                isChecked={formData.dealBreakers.earningsType}
                                                onChange={(e) => handleDealBreakerChange(e, 'earningsType')}
                                            >
                                                Earnings Type Dealbreaker
                                            </Checkbox>
                                        </FormControl>
                                    )}
                                    <Flex w={"100%"}>
                                        <FormControl>
                                            <FormLabel htmlFor="workLocationType">Work Location Type</FormLabel>
                                            <Select
                                                id="workLocationType"
                                                bg={"white"}
                                                name="workLocationType"
                                                value={formData.workLocationType}
                                                onChange={handleInputChange}
                                            >
                                                <option value="" disabled>Select an option</option>
                                                <option value="Remote">Remote</option>
                                                <option value="Hybrid">Hybrid</option>
                                                <option value="On-site">On-site</option>
                                            </Select>
                                            <Checkbox
                                                isChecked={formData.dealBreakers.workLocationType}
                                                onChange={(e) => handleCheckboxChange(e, 'workLocationType')}
                                                name="workLocationType"
                                            >
                                                Dealbreaker?
                                            </Checkbox>
                                        </FormControl>
                                    </Flex>
                                </HStack>
                            </Box>
                            <Box bg={"whiteAlpha.800"} borderRadius={"2xl"} p={4}>
                                <Heading size={"lg"}>Preferences:</Heading>
                                <HStack>
                                    <FormControl>
                                        <FormLabel htmlFor="employmentType">Employment Type</FormLabel>
                                        <Select
                                            id="employmentType"
                                            bg={"white"}
                                            name="employmentType"
                                            value={formData.employmentType}
                                            onChange={handleInputChange}
                                        >
                                            <option value="" disabled>Select an option</option>
                                            <option value="Full-time">Full-time</option>
                                            <option value="Part-time">Part-time</option>
                                            <option value="Contract">Contract</option>
                                        </Select>
                                        <Checkbox
                                            isChecked={formData.dealBreakers.employmentType}
                                            onChange={(e) => handleDealBreakerChange(e, 'employmentType')}
                                        >
                                            Employment Type Dealbreaker
                                        </Checkbox>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel htmlFor="preferredCity">Preferred City</FormLabel>
                                        <Input
                                            type="text"
                                            bg={"white"}
                                            id="preferredCity"
                                            name="preferredCity" // Just the field name, without dot notation
                                            value={formData.preferredLocation.preferredCity}
                                            onChange={handlePreferredLocationChange}
                                        />
                                        <Checkbox
                                            isChecked={formData.dealBreakers.preferredLocation.preferredCity}
                                            onChange={(e) => handleDealBreakerChange(e, 'preferredCity', true)}
                                        >
                                            Preferred City Dealbreaker
                                        </Checkbox>

                                    </FormControl>
                                    <FormControl>
                                        <FormLabel htmlFor="preferredState">Preferred State</FormLabel>
                                        <Input
                                            type="text"
                                            bg={"white"}
                                            id="preferredState"
                                            name="preferredState" // Just the field name, without dot notation
                                            value={formData.preferredLocation.preferredState}
                                            onChange={handlePreferredLocationChange}
                                        />
                                        <Checkbox
                                            isChecked={formData.dealBreakers.preferredLocation.preferredState}
                                            onChange={(e) => handleDealBreakerChange(e, 'preferredState', true)}
                                        >
                                            Preferred State Dealbreaker
                                        </Checkbox>
                                    </FormControl>

                                </HStack>
                            </Box>
                            <Box bg={"whiteAlpha.800"} borderRadius={"2xl"} p={4}>
                                <Heading size={"lg"}>Interests:</Heading>
                                <FormControl>
                                    <FormLabel htmlFor="interestedIndustries">Interested Industries</FormLabel>
                                    {formData.interestedIndustries.map((industry, index) => (
                                        <Tag bg={"blue.100"} key={index} m={1}>
                                            <TagLabel>{industry}</TagLabel>
                                            <TagCloseButton onClick={() => handleRemoveInterestedIndustry(index)} />
                                        </Tag>
                                    ))}
                                    <CreatableSelect
                                        isClearable
                                        placeholder="Select or add industry"
                                        onChange={handleIndustryChange}
                                        onInputChange={handleIndustrySearch}
                                        options={industryOptions}
                                        value=""
                                        formatCreateLabel={(inputValue) => `+ Add "${inputValue}"`}
                                        isLoading={isIndustryLoading}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel mt={2} htmlFor="interestedJobTitles">Interested Jobs</FormLabel>
                                    {formData.interestedJobTitles.map((jobTitle, index) => (
                                        <Tag bg={"blue.100"} key={index} m={1}>
                                            <TagLabel>{jobTitle}</TagLabel>
                                            <TagCloseButton onClick={() => handleRemoveInterestedJobTitle(index)} />
                                        </Tag>
                                    ))}
                                    <CreatableSelect
                                        isClearable
                                        isMulti
                                        isLoading={isJobTitleLoading}
                                        onInputChange={handleJobTitleSearch}
                                        onChange={handleJobTitleChange}
                                        options={jobTitleOptions}
                                        value=""
                                        placeholder="Type to search job titles..."
                                    />
                                </FormControl>
                            </Box>
                        </Stack>
                    </TabPanel>
                    <TabPanel>
                        <Stack spacing={4}>
                            <Box bg={"blackAlpha.200"} borderRadius={"2xl"} p={4}>

                            </Box>
                        </Stack>
                    </TabPanel>
                    <TabPanel>
                        <Stack spacing={4}>
                            <Box bg={"blackAlpha.200"} borderRadius={"2xl"} p={4}>

                            </Box>
                        </Stack>
                    </TabPanel>
                    <TabPanel>
                        <Stack spacing={4}>
                            <Box bg={"blackAlpha.200"} borderRadius={"2xl"} p={4}>

                            </Box>
                        </Stack>
                    </TabPanel>
                    <TabPanel>
                        <Stack spacing={4}>
                            <Box bg={"blackAlpha.200"} borderRadius={"2xl"} p={4}>

                            </Box>
                        </Stack>
                    </TabPanel>
                    <TabPanel>
                        <Stack spacing={4}>
                            <Box bg={"blackAlpha.200"} borderRadius={"2xl"} p={4}>

                            </Box>
                        </Stack>
                    </TabPanel>
                </TabPanels>
            </Tabs>
            <Flex mt={4} w={"98%"} justify={"flex-end"}>
                <Button type="submit" bg={"#118AB2"} _hover={{ bg: "#01BF02" }} color={"white"} isLoading={isLoading}>Submit</Button>
            </Flex>
        </Box >
    );
};

export default JobSeekerForm;