// CriteriaSelection.js
import React, { useState } from 'react';
import { Checkbox, VStack, Button, Heading, SimpleGrid, Box, Divider, Center } from '@chakra-ui/react';

const CriteriaSelection = ({ evaluationItems, onSubmit }) => {
  const [selectedCriteria, setSelectedCriteria] = useState({});

  const handleCheckboxChange = (criteria) => {
    setSelectedCriteria(prev => ({
      ...prev,
      [criteria]: !prev[criteria]
    }));
  };

  const handleSubmit = () => {
    const selectedItems = evaluationItems.filter(item => selectedCriteria[item.criteria]);
    onSubmit(selectedItems);
  };

  return (
    <Box h={"auto"} overflowY={"scroll"} bg={"white"} p={5} shadow="md" borderWidth="1px" borderRadius="lg">
      <Heading size="md">Select Evaluation Criteria</Heading>
      <VStack mt={2} w={"100%"} justify={"center"} spacing={4}>
        <Divider />
        <Center>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
            {evaluationItems.map(item => (
              <Checkbox
                sx={{ '.chakra-checkbox__control': { borderColor: '#118AB2' }, '&:hover .chakra-checkbox__control': { borderColor: '#118AB2', boxShadow: '0 0 0 1px #118AB2' } }}
                key={item.criteria}
                onChange={() => handleCheckboxChange(item.criteria)}
                size="lg"
              >
                {item.criteria}
              </Checkbox>
            ))}
          </SimpleGrid>
        </Center>
        <Button size={"md"} mt={4} bg={"#118AB2"} color={"white"} _hover={"#01BF02"} onClick={handleSubmit}>Generate Evaluation Form</Button>
      </VStack>
    </Box>
  );
};

export default CriteriaSelection;
