import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import {
    Box,
    Flex,
    Text,
    Image,
    Button,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Input,
    useColorModeValue,
    Stack,
    SimpleGrid
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { ChatIcon } from '@chakra-ui/icons';
import useCurrentUser from './useCurrentUser';
import axiosInstance from './axiosInstance';

const token = localStorage.getItem('auth_token');
const socket = io(process.env.REACT_APP_API_URL, {
    query: {
        token: token
    },
    autoConnect: false
});

const ChatModal = ({ isOpen, onClose, connection }) => {
    const currentUserId = useCurrentUser();
    const endOfMessagesRef = useRef(null);
    const token = localStorage.getItem('auth_token');
    const [message, setMessage] = useState('');

    const [chat, setChat] = useState([]);

    const fetchMessages = async () => {
        const connectionId = connection._id;
        if (!connection || !connection._id) {
            return; // Return early if connection is not defined
        }

        try {
            const response = await axiosInstance.get(`/get-messages/${connectionId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setChat(response.data.messages);

        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    };

    useEffect(() => {
        // Ensure that the currentUserId and connection are defined
        if (currentUserId && connection && connection._id) {
            socket.connect();
            socket.emit('join_connection_chat', { connectionId: connection._id, userId: currentUserId });

            // Define a function that updates the chat when a new message arrives
            const handleNewMessage = (newMessage) => {
                setChat(currentChat => [...currentChat, newMessage]);
            };

            // Set up the listener for new messages
            socket.on('new_message', handleNewMessage);

            // Specify how to clean up after this effect
            return () => {
                socket.off('new_message', handleNewMessage);
                socket.disconnect();
            };
        }
    }, [currentUserId, connection]);

    const sendMessage = async () => {
        if (message.trim()) {
            const newMessage = {
                connectionId: connection._id,
                message: message.trim(),
                senderRole: localStorage.getItem('user_role'),
                recipientId: connection.requesterId,
                sender: currentUserId,
            };

            setMessage('');

            try {
                const response = await axiosInstance.post('/save-message', newMessage, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setChat(prevChat => [...prevChat, response.data.newMessage]);
            } catch (error) {
                console.error('Error saving message:', error);
            }
        }
    };

    useEffect(() => {
        endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [chat]);

    const isMessageFromCurrentUser = (msgSenderId) => {
        return msgSenderId === currentUserId;
    };

    const currentUserBgColor = useColorModeValue('#01BF02', 'white');
    const otherUserBgColor = useColorModeValue('#118AB2', 'white');

    const currentUserTextColor = useColorModeValue('black', 'black');
    const otherUserTextColor = useColorModeValue('white', 'white');

    useEffect(() => {
        if (isOpen && connection) {
            fetchMessages();
        }
    }, [isOpen, connection]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Chat with {connection.requesterName}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Stack
                        maxH={"800px"}
                        w={"100%"}
                        overflowY={"scroll"}
                        spacing={4}
                        p={4}
                    >
                        {chat.length > 0 ? (
                            chat.map((msg, index) => (
                                msg ? (
                                    <Box
                                        key={index}
                                        ref={index === chat.length - 1 ? endOfMessagesRef : null}
                                        bg={isMessageFromCurrentUser(msg.sender) ? currentUserBgColor : otherUserBgColor}
                                        p={2}
                                        borderRadius="md"
                                        alignSelf={isMessageFromCurrentUser(msg.sender) ? 'flex-end' : 'flex-start'}
                                    >
                                        <Text fontWeight="semibold" color={isMessageFromCurrentUser(msg.sender) ? currentUserTextColor : otherUserTextColor}>
                                            {msg.senderName}
                                        </Text>
                                        <Text color={isMessageFromCurrentUser(msg.sender) ? currentUserTextColor : otherUserTextColor}>
                                            {msg.content}
                                        </Text>
                                    </Box>
                                ) : null
                            ))
                        ) : (
                            <Text alignSelf="center">Write your first message</Text>
                        )}
                    </Stack>
                    <Box mt={4} p={2} borderRadius={"xl"} w={"100%"} bg={"blackAlpha.200"}>
                        <Input
                            placeholder="Type your message here..."
                            value={message}
                            bg={"white"}
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    sendMessage();
                                }
                            }}
                        />
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

const ConnectionListItem = ({ connection, onChatStart, hasNewMessages }) => (
    <Flex align="center" justify="space-between" p={2} bg={"white"} borderRadius="lg" shadow="md" m={1}>
        {
            connection.requesterPFP ? (
                <Image src={connection.requesterPFP} w="45px" h="45px" borderRadius="full" />
            ) : (
                <Box
                    w="45px"
                    h="45px"
                    borderRadius="full"
                    bg="#01BF02" // Change this to match your preferred default avatar background color
                    color="white" // Change this to match your preferred initials text color
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    fontSize="lg" // Adjust font size as needed
                >
                    {connection.requesterName.split(' ').map(name => name[0]).join('')}
                </Box>
            )
        }

        <Box flex="1" ml={4}>
            <Text fontWeight="bold">
                <Link
                    to={`/user/${connection.requesterId}`}
                    style={{ textDecoration: 'none', color: 'black' }}
                    _hover={{ textDecoration: 'underline' }}
                >
                    {connection.requesterName}
                </Link>
            </Text>
            <Text fontSize="sm" color="gray.600">{connection.requesterRole}</Text>
        </Box>
        <Button size="sm" bg="#01BF02" color="white" leftIcon={<ChatIcon />} onClick={() => onChatStart(connection)}>
            Chat
            {hasNewMessages && <Box as="span" ml={2} w={2} h={2} bg="red.500" borderRadius="full" />}
        </Button>
    </Flex>
);

const ConnectionsList = ({ connections }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [activeConnection, setActiveConnection] = React.useState(null);
    const [newMessageIndicator, setNewMessageIndicator] = useState({});

    const handleChatStart = (connection) => {
        setActiveConnection(connection);
        setNewMessageIndicator(prev => ({ ...prev, [connection._id]: false })); // Reset indicator when chat starts
        onOpen();
    };

    useEffect(() => {
        // Listen for new messages
        socket.on('new_message', (message) => {
            console.log('New message received:', message);
            // Check if the message is for the current user and not the active connection
            if (message.connectionId && message.sender !== message.connectionId !== activeConnection?._id) {
                setNewMessageIndicator(prev => ({ ...prev, [message.connectionId]: true }));
            }
        });

        // Cleanup on unmount
        return () => {
            socket.off('new_message');
        };
    }, [activeConnection]);

    return (
        <Box w="100%">
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                {connections.map((connection) => (
                    <ConnectionListItem
                        key={connection._id}
                        connection={connection}
                        onChatStart={handleChatStart}
                        hasNewMessages={!!newMessageIndicator[connection._id]} // Ensure the value is a boolean
                    />
                ))}
            </SimpleGrid>

            {/* Chat Modal */}
            {activeConnection && (
                <ChatModal isOpen={isOpen} onClose={onClose} connection={activeConnection} />
            )}
        </Box>
    );
};

export default ConnectionsList;