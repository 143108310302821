// pages/job/[jobId].js
import React from 'react';
import axios from 'axios';
import JobDetails from '../components/JobDetails';

export async function getServerSideProps(context) {
    const { params } = context;
    const jobId = params.jobId;

    let jobData = null;
    let companyInfo = null;

    try {
        // Fetch job details
        const jobResponse = await fetch(`http://localhost:3001/api/job/${jobId}`);
        if (jobResponse.ok) {
            jobData = await jobResponse.json();
        } else {
            console.error('Job not found for jobId:', jobId);
        }

        // Fetch company details if job is found and companyId is available
        if (jobData && jobData.companyId) {
            const companyResponse = await fetch(`http://localhost:3001/api/company/${jobData.companyId}`);
            if (companyResponse.ok) {
                companyInfo = await companyResponse.json();
            } else {
                console.error('Company not found for companyId:', jobData.companyId);
            }
        }
    } catch (error) {
        console.error('Error fetching job or company details:', error);
    }

    return {
        props: {
            selectedJob: jobData,
            companyInfoForGoogle: companyInfo,
        },
    };
}

const JobPage = ({ selectedJob, companyInfoForGoogle }) => {
    return (
        <JobDetails selectedJob={selectedJob} companyInfoForGoogle={companyInfoForGoogle} />
    );
};

export default JobPage;
