import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
    FormControl,
    FormLabel,
    Box,
    Input,
    Button,
    Flex,
    Grid,
    List,
    ListItem
} from '@chakra-ui/react';

const IndustryExperienceInput = ({ formData, setFormData }) => {
    const [newIndustry, setNewIndustry] = useState('');
    const [industrySuggestions, setIndustrySuggestions] = useState([]);
    const debounceTimeoutRef = useRef();
    const [industriesData, setIndustriesData] = useState([]);

    // Function to handle the input change for new industries
    const handleNewIndustryChange = (e) => {
        const inputValue = e.target.value;
        setNewIndustry(inputValue);

        // Clear previous debounce timeout
        clearTimeout(debounceTimeoutRef.current);

        // Set up a new debounce timeout
        debounceTimeoutRef.current = setTimeout(() => {
            if (inputValue.trim() !== '') {
                // Filter industries that match the user input
                const filteredIndustries = industriesData.filter((industry) =>
                    industry.industry.toLowerCase().includes(inputValue.toLowerCase())
                );
                setIndustrySuggestions(filteredIndustries);
            } else {
                setIndustrySuggestions([]); // Clear suggestions when input is empty
            }
        }, 500); // Adjust the debounce time as needed
    };

    useEffect(() => {
        // Fetch industries from the backend when the component mounts
        axios.get(process.env.REACT_APP_API_URL + '/api/industries/search')
            .then((response) => {
                setIndustriesData(response.data); // Assuming response.data is an array of industries
            })
            .catch((error) => {
                console.error('Error fetching industries:', error);
            });
    }, []);

    // Function to select an industry from the suggestions
    const handleSelectSuggestion = (suggestion) => {
        // Ensure the suggestion is an object that matches the schema
        const industryExperienceEntry = {
            industry: suggestion.industry, // Make sure you're accessing the 'industry' property which should be a string
            yearsExperience: 0, // Default to 0 or another appropriate value
            notableProjects: [], // Default to empty array or another appropriate value
            technologiesUsed: [] // Default to empty array or another appropriate value
        };

        const updatedIndustries = [...formData.industryExperience, industryExperienceEntry];
        setFormData({ ...formData, industryExperience: updatedIndustries });
        setNewIndustry(''); // Clear the input field
        setIndustrySuggestions([]); // Clear the suggestions
    };

    // Function to handle adding a new industry
    const handleAddIndustry = () => {
        if (newIndustry.trim() === '') return;

        // Create an object that matches the JobSeeker schema for industryExperience
        const industryExperienceEntry = {
            industry: newIndustry,
            yearsExperience: 0, // Default to 0 or another appropriate value
            notableProjects: [], // Default to empty array or another appropriate value
            technologiesUsed: [] // Default to empty array or another appropriate value
        };

        const updatedIndustries = [...formData.industryExperience, industryExperienceEntry];
        setFormData({ ...formData, industryExperience: updatedIndustries });

        setNewIndustry(''); // Clear the input field
    };

    // Function to handle removing an existing industry
    const handleRemoveIndustry = (index) => {
        const updatedIndustries = formData.industryExperience.filter((_, i) => i !== index);
        setFormData({ ...formData, industryExperience: updatedIndustries });
    };

    const handleYearsExperienceChange = (index, years) => {
        const updatedIndustries = formData.industryExperience.map((item, idx) => {
            if (idx === index) {
                return { ...item, yearsExperience: years };
            }
            return item;
        });

        setFormData({ ...formData, industryExperience: updatedIndustries });
    };

    useEffect(() => {
        // Clear the debounce timeout when the component unmounts
        return () => {
            clearTimeout(debounceTimeoutRef.current);
        };
    }, []);

    return (
        <FormControl id="industry-experience">
            <FormLabel color={"white"}>Industry Experience in Years</FormLabel>
            <Box w={"100%"} mb={4}>
                <Grid maxH={"300px"} overflowY={"scroll"} templateColumns="repeat(3, 1fr)" gap={4}>
                    {formData.industryExperience.map((industry, index) => (
                        <Flex key={index} alignItems="center" gridGap={2}>
                            <Input
                                name={`industryExperience[${index}].industry`}
                                placeholder="Industry..."
                                value={industry.industry}
                                bg="#118AB2"
                                color="white"
                                w={"70%"}
                                flex="1"
                                readOnly
                            />
                            <Input
                                name={`industryExperience[${index}].yearsExperience`}
                                placeholder="Years..."
                                type="number"
                                bg={"white"}
                                w={"30%"}
                                value={industry.yearsExperience}
                                onChange={(e) => handleYearsExperienceChange(index, e.target.value)}
                            />
                            <Button bg={"#EF476F"}
                                color={"white"}
                                _hover={{ bg: "red" }} size="xs" onClick={() => handleRemoveIndustry(index)}>
                                X
                            </Button>
                        </Flex>
                    ))}
                </Grid>
                <Flex align="center" justify="flex-end" mt={4} position="relative">
                    <Input
                        placeholder="Add an industry..."
                        value={newIndustry}
                        bg="white"
                        onChange={handleNewIndustryChange}
                    />
                    <Button color={"white"} ml={4} bg="#118AB2" _hover={{ bg: "#01BF02" }} size="sm" onClick={handleAddIndustry}>
                        + Industry
                    </Button>
                    {industrySuggestions.length > 0 && (
                        <List position="absolute" top="100%" mt={1} width="100%" borderRadius={"lg"} zIndex="10" bg="white" boxShadow="lg">
                            {industrySuggestions.map((suggestion, index) => (
                                <ListItem
                                    key={index}
                                    p={2}
                                    cursor="pointer"
                                    _hover={{ bg: 'gray.100' }}
                                    onClick={() => handleSelectSuggestion(suggestion)} // Pass the whole suggestion object
                                >
                                    {suggestion.industry}
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Flex>
            </Box>
        </FormControl>
    );
};

export default IndustryExperienceInput;