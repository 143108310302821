import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {
    Box, Flex, VStack, Button, Heading, List, ListItem, useToast,
    FormControl, FormLabel, StackDivider, HStack,
    Slider, SliderTrack, SliderFilledTrack, SliderThumb, Radio
} from '@chakra-ui/react';

const stripePromise = loadStripe('pk_live_51MXrqLEDfGoNCpuzG4DPVOWnJaoNRusee6UxEgrMIAwAiylcZYMOcXjJysOwCvl2ed4I3XAtsmr87t9vAGBLlTHb006oEEfL6v');

const PaymentForm = ({
    selectedPlan,
    onSuccessfulPayment,
    setStep,
    prevStep,
    billingCycle,
    monthlyCost,
    annualCost,
    premiumUsage,
}) => {
    const stripe = useStripe();
    const elements = useElements();
    const toast = useToast();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handlePaymentSubmission = async () => {
        if (!stripe || !elements || isSubmitting) {
            return;
        }

        setIsSubmitting(true);
        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            toast({
                title: "Error",
                description: error.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            setIsSubmitting(false); // Reset on error
            return;

        } else {

            const getOrCreateStripeCustomer = async () => {
                const token = localStorage.getItem('auth_token');
                const headers = { 'Authorization': `Bearer ${token}` };
                const response = await axios.post(process.env.REACT_APP_API_URL + '/api/create-or-retrieve-stripe-customer', {}, { headers });
                return response.data.customerId;
            };

            const customerId = await getOrCreateStripeCustomer();

            // After creating the payment method
            await axios.post(process.env.REACT_APP_API_URL + '/api/attach-payment-method', {
                paymentMethodId: paymentMethod.id,
                customerId: customerId
            });

            // Assuming you have the cost to charge from the state
            const costToCharge = billingCycle === 'monthly' ? monthlyCost : annualCost;

            try {
                const amountToChargeInCents = Math.round(costToCharge * 100);
                // Call your backend to create a PaymentIntent
                const { data: paymentIntent } = await axios.post(process.env.REACT_APP_API_URL + '/create-payment-intent', {
                    paymentMethodId: paymentMethod.id,
                    amount: amountToChargeInCents,
                    customerId: customerId
                });

                // Confirm the payment on the client
                const confirmPayment = await stripe.confirmCardPayment(paymentIntent.client_secret);

                if (confirmPayment.error) {
                    // Handle payment errors
                    toast({
                        title: "Payment Error",
                        description: confirmPayment.error.message,
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                } else {
                    // The payment has been processed!
                    if (confirmPayment.paymentIntent.status === 'succeeded') {

                        const paymentDetails = {
                            stripeCustomerId: customerId,
                            accountType: selectedPlan,
                            billingCycle: billingCycle,
                            customPricing: {
                                monthly: monthlyCost,
                                annual: annualCost,

                            },
                            featureLimits: {
                                // Set the feature limits based on the selected plan
                                // This is just an example, adjust according to your logic
                                revealLimit: selectedPlan === 'Premium' ? premiumUsage.monthlyReveals : null,
                                uploadLimit: selectedPlan === 'Premium' ? premiumUsage.manualUploads : null,
                                smsLimit: selectedPlan === 'Premium' ? premiumUsage.monthlySms : null,
                                emailLimit: selectedPlan === 'Premium' ? premiumUsage.monthlyEmails : null,
                            },
                        };
                        try {
                            // Assuming you're using JWT and it's stored in localStorage or similar
                            const token = localStorage.getItem('auth_token'); // Replace with your actual token key
                            const headers = {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`
                            };

                            // Send the payment details to the server
                            const updateResponse = await axios.put(process.env.REACT_APP_API_URL + '/api/company/update-payment-info', paymentDetails, { headers });

                            if (updateResponse.status === 200) {
                                // If successful, you can navigate to the dashboard or show a success message
                                onSuccessfulPayment();
                            } else {
                                // Handle any other HTTP status codes as needed
                                toast({
                                    title: "Update Failed",
                                    description: updateResponse.data.message,
                                    status: "error",
                                    duration: 5000,
                                    isClosable: true,
                                });
                            }
                        } catch (error) {
                            // Handle errors in communicating with the backend
                            console.error('Error updating company payment info:', error);
                            toast({
                                title: "Payment Info Update Error",
                                description: error.message,
                                status: "error",
                                duration: 5000,
                                isClosable: true,
                            });
                        }
                    }
                }
            } catch (error) {
                // Handle errors in creating the PaymentIntent or confirming the payment
                toast({
                    title: "Payment Processing Error",
                    description: error.message,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        }
        setIsSubmitting(false);
    };

    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                fontSize: '18px', // Increase font-size
                color: '#424770',
                '::placeholder': {
                    color: '#aab7c4'
                },
            },
            invalid: {
                color: '#9e2146',
            },
        },
    };

    return (
        <Flex justify={"center"} w={"100%"}>
            <Box w={"40%"}>
                <VStack w={"100%"} spacing={4}>
                    <Heading mb={4} fontSize="lg">{`${selectedPlan} Payment Information`}</Heading>
                    <FormControl>
                        <FormLabel>Add a Credit Card</FormLabel>
                        <CardElement mt={4} mb={4} options={CARD_ELEMENT_OPTIONS} />
                    </FormControl>
                    <Flex w={"100%"} justify={"space-between"}>
                        <Button mt={4} colorScheme={selectedPlan === 'Premium' ? 'teal' : 'orange'} onClick={() => setStep(prevStep)}>
                            Go Back
                        </Button>
                        <Button
                            mt={4}
                            colorScheme={selectedPlan === 'Premium' ? 'orange' : 'teal'}
                            onClick={handlePaymentSubmission}
                            isLoading={isSubmitting}
                            disabled={isSubmitting}
                        >
                            Complete Signup
                        </Button>
                    </Flex>
                </VStack>
            </Box>
        </Flex>
    );
};

const AccountPlan = () => {
    const [step, setStep] = useState(1);
    const [prevStep, setPrevStep] = useState(1);
    const [selectedPlan, setSelectedPlan] = useState('');
    const [billingCycle, setBillingCycle] = useState('monthly');
    const toast = useToast();
    const navigate = useNavigate();

    const handleBillingCycleChange = (event) => {
        setBillingCycle(event.target.value);
    };

    const [premiumUsage, setPremiumUsage] = useState({
        monthlyReveals: 10,
        monthlySms: 1000,
        monthlyEmails: 1000,
        manualUploads: 500,
    });

    const calculateCost = () => {
        const monthlyCost =
            premiumUsage.monthlyReveals * 7.50 +
            premiumUsage.monthlySms * 0.02 +
            premiumUsage.monthlyEmails * 0.01 +
            premiumUsage.manualUploads * 0.04;
        const annualCost = monthlyCost * 12 * 0.85;

        return { monthlyCost, annualCost };
    };

    const { monthlyCost, annualCost } = calculateCost();

    const planOptions = {
        Freemium: {
            title: 'Freemium Model',
            features: [
                'Pay-Per-Reveal: $10 per applicant reveal, with an optional incentive bonus.',
                'Job Posting Benefits: Free job posts with distribution to platforms like Google, Indeed, Greenhouse.',
                'Applicant Requirements: Applicants must create an account to apply.',
                'Skill assessments and grading.',
                'Job post summary generation.',
                'AI scoring and matching.',
                'Access to an ATS/CRM platform.',
                'On-platform messaging.',
                'Unlimited team members and permissions.'
            ],
            buttonText: 'Select Freemium',
            buttonColorScheme: 'teal',
        },
        Premium: {
            title: 'Premium Model',
            features: [
                'Includes all benefits of the Freemium Version',
                'Enhanced Visibility: Jobs receive a badge and preferential placement.',
                'Direct Communication: SMS and email options to contact applicants directly.',
                'ATS Integration: Employers can upload ZIP files or documents for ATS processing (with monthly or annual limits).',
                'Simplified Application Process: Applicants can apply directly without creating an account, using resume upload on job listing pages.',
                'Reveal Allotment: Set number of reveals per month/year, with additional incentive bonuses charged separately.',
                'Custom Pricing: Determined based on specific employer needs.'
            ],
            buttonText: 'View Pricing',
            buttonColorScheme: 'orange',
        }
    };

    const premiumPlan = {
        Premium: {
            title: 'Premium Model',
            features: [
                'Includes all benefits of the Freemium Version',
                'Enhanced Visibility: Jobs receive a badge and preferential placement.',
                'Direct Communication: SMS and email options to contact applicants directly.',
                'ATS Integration: Employers can upload ZIP files or documents for ATS processing (with monthly or annual limits).',
                'Simplified Application Process: Applicants can apply directly without creating an account, using resume upload on job listing pages.',
                'Reveal Allotment: Set number of reveals per month/year, with additional incentive bonuses charged separately.',
                'Custom Pricing: Determined based on specific employer needs.'
            ],
            buttonText: 'Go Back',
            buttonColorScheme: 'orange',
        }
    };

    const handlePlanSelection = (planKey) => {
        setSelectedPlan(planKey);
        setPrevStep(step);
        setStep(planKey === 'Premium' ? 2 : 3);
        toast({
            title: `${planKey} Plan Selected.`,
            description: "You can now proceed to the next steps.",
            status: "success",
            duration: 5000,
            isClosable: true,
        });

        // Set the step to the payment info step for Freemium or the additional details step for Premium
        setStep(planKey === 'Premium' ? 2 : 3);
    };

    const handlePremiumDetailsSubmission = () => {
        setPrevStep(step);
        setStep(3);
    };

    // JSX for Step 2 - Collecting Premium plan details
    const renderPremiumDetailsForm = () => (
        <Flex justify={"center"} align={"center"} w={"100%"}>
            {Object.entries(premiumPlan).map(([planKey, { title, features, buttonText, buttonColorScheme }]) => (
                <VStack
                    key={planKey}
                    p={5}
                    boxShadow="md"
                    borderWidth="1px"
                    borderRadius="lg"
                    divider={<StackDivider borderColor="gray.200" />}
                    spacing={4}
                    align="stretch"
                    m="2"
                >
                    <Heading size="lg" textAlign="center">{title}</Heading>
                    <List spacing={3}>
                        {features.map((feature, index) => (
                            <ListItem key={index}>{feature}</ListItem>
                        ))}
                    </List>
                    <Button
                        colorScheme={buttonColorScheme}
                        onClick={() => setStep(1)}
                    >
                        {buttonText}
                    </Button>
                </VStack>
            ))}
            <Flex justify={"center"} w={"100%"}>
                <Box w={"60%"}>
                    <VStack spacing={4}>
                        <Heading fontSize="lg">Monthly Premium Plan Details</Heading>

                        <FormControl>
                            <FormLabel>Applicants Revealed: {premiumUsage.monthlyReveals}</FormLabel>
                            <Slider min={10} max={100} step={5}
                                value={premiumUsage.monthlyReveals}
                                onChange={(value) => setPremiumUsage({ ...premiumUsage, monthlyReveals: value })}
                            >
                                <SliderTrack>
                                    <SliderFilledTrack />
                                </SliderTrack>
                                <SliderThumb />
                            </Slider>
                        </FormControl>

                        <FormControl>
                            <FormLabel>Manual Uploads: {premiumUsage.manualUploads}</FormLabel>
                            <Slider min={500} max={10000} step={250}
                                value={premiumUsage.manualUploads}
                                onChange={(value) => setPremiumUsage({ ...premiumUsage, manualUploads: value })}
                            >
                                <SliderTrack>
                                    <SliderFilledTrack />
                                </SliderTrack>
                                <SliderThumb />
                            </Slider>
                        </FormControl>

                        <FormControl>
                            <FormLabel>SMS Texts: {premiumUsage.monthlySms}</FormLabel>
                            <Slider min={1000} max={10000} step={1000}
                                value={premiumUsage.monthlySms}
                                onChange={(value) => setPremiumUsage({ ...premiumUsage, monthlySms: value })}
                            >
                                <SliderTrack>
                                    <SliderFilledTrack />
                                </SliderTrack>
                                <SliderThumb />
                            </Slider>
                        </FormControl>

                        <FormControl>
                            <FormLabel>Emails: {premiumUsage.monthlyEmails}</FormLabel>
                            <Slider min={1000} max={10000} step={1000}
                                value={premiumUsage.monthlyEmails}
                                onChange={(value) => setPremiumUsage({ ...premiumUsage, monthlyEmails: value })}
                            >
                                <SliderTrack>
                                    <SliderFilledTrack />
                                </SliderTrack>
                                <SliderThumb />
                            </Slider>
                        </FormControl>

                        {/* Display Calculated Costs */}
                        <VStack spacing={4} align="stretch">
                            <Heading fontSize="lg">Monthly Premium Plan Details</Heading>
                            <HStack justify={"space-between"} w={"100%"}>
                                <FormControl>
                                    <FormLabel>
                                        <Radio value="monthly" isChecked={billingCycle === 'monthly'} onChange={handleBillingCycleChange}>
                                            Monthly (${monthlyCost.toFixed(2)})
                                        </Radio>
                                    </FormLabel>
                                </FormControl>
                                <FormControl>
                                    <FormLabel>
                                        <Radio value="annual" isChecked={billingCycle === 'annual'} onChange={handleBillingCycleChange}>
                                            Annual (save 15%) (${annualCost.toFixed(2)})
                                        </Radio>
                                    </FormLabel>
                                </FormControl>
                            </HStack>
                            <Button colorScheme="orange" onClick={handlePremiumDetailsSubmission} setStep={setStep}>
                                Continue to Payment
                            </Button>
                        </VStack>
                    </VStack>
                </Box>
            </Flex >
        </Flex>
    );

    const renderPaymentForm = () => {
        // Calculate costs just before rendering the payment form to ensure they are up-to-date
        const { monthlyCost, annualCost } = calculateCost();

        return (
            <Elements stripe={stripePromise}>
                <PaymentForm
                    selectedPlan={selectedPlan}
                    onSuccessfulPayment={() => navigate('/dashboard')}
                    setStep={setStep}
                    prevStep={prevStep}
                    billingCycle={billingCycle}
                    monthlyCost={monthlyCost}
                    annualCost={annualCost}
                    premiumUsage={premiumUsage}
                />
            </Elements>
        );
    };

    return (
        <Box p={5}>
            {step === 1 && (
                <Flex direction={{ base: 'column', md: 'row' }} align="center" justify="center" py={10}>
                    {Object.entries(planOptions).map(([planKey, { title, features, buttonText, buttonColorScheme }]) => (
                        <VStack
                            key={planKey}
                            p={5}
                            boxShadow="md"
                            borderWidth="1px"
                            borderRadius="lg"
                            divider={<StackDivider borderColor="gray.200" />}
                            spacing={4}
                            align="stretch"
                            m="2"
                            width={{ base: '80%', md: '40%' }}
                        >
                            <Heading size="lg" textAlign="center">{title}</Heading>
                            <List spacing={3}>
                                {features.map((feature, index) => (
                                    <ListItem key={index}>{feature}</ListItem>
                                ))}
                            </List>
                            <Button
                                colorScheme={buttonColorScheme}
                                onClick={() => handlePlanSelection(planKey)}
                            >
                                {buttonText}
                            </Button>
                        </VStack>
                    ))}
                </Flex>
            )}
            {step === 2 && selectedPlan === 'Premium' && renderPremiumDetailsForm()}
            {step === 3 && renderPaymentForm()}
        </Box>
    );
};

export default AccountPlan;
