import React, { useState } from 'react';
import { FormControl, useToast, Flex, Box, FormLabel, Input, Text, Button, VStack } from '@chakra-ui/react';
import axiosInstance from './axiosInstance';

const ProfilePictureUpload = ({ setFormData, formData }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async (event) => {
        const authToken = localStorage.getItem('auth_token');
        event.preventDefault();
        if (!selectedFile) return;

        const uploadFormData = new FormData();
        uploadFormData.append('profilePicture', selectedFile);

        setIsLoading(true);
        try {
            const response = await axiosInstance.post('/upload-profile-picture', uploadFormData, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                },
            });
            // Update state with the new profile image URL
            setFormData(currentFormData => ({
                ...currentFormData,
                PFP: response.data.PFP
            }));
            toast({
                title: 'Profile Picture Updated',
                description: 'Your profile picture has been successfully updated.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error uploading file:', error);
            toast({
                title: 'Error',
                description: 'There was an error uploading the profile picture.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Flex align="center">
            <VStack>
                <Flex align={"center"} maxW={"400px"}>
                    <Text fontWeight={"thin"}>{selectedFile ? selectedFile.name : ''}</Text>
                </Flex>
                <Flex w={"fit-content"}>
                    <FormControl display="flex" justifyContent={"center"} alignItems="center">
                        <FormLabel htmlFor='file-upload' cursor="pointer" mr={2}>
                            <Box p={2} mt={2} mb={2} borderRadius={"lg"} bg={"whiteAlpha.800"}>
                                <Text fontWeight={"semibold"}>Change Profile Picture: + Choose file (jpg, png)</Text>
                            </Box>
                        </FormLabel>
                        <Input id='file-upload' type="file" onChange={handleFileChange} accept="image/*" hidden />
                    </FormControl>
                    {selectedFile && (
                        <Button w={"100px"} bg="#01BF02"
                            color="white"
                            _hover={{ bg: "#118AB2" }} onClick={handleUpload} isLoading={isLoading} disabled={!selectedFile}>
                            {isLoading ? 'Uploading...' : 'Upload'}
                        </Button>
                    )}
                </Flex>
            </VStack>
        </Flex>
    );
};

export default ProfilePictureUpload;