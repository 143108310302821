import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Box, Button, FormControl, Flex, FormLabel, Textarea, VStack, Heading, useToast, color } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../components/axiosInstance';
import Background from '../../../assets/Background.webp';

const SkillAssessment = () => {
    const { jobId } = useParams();
    const token = localStorage.getItem('auth_token');
    const [jobDetails, setJobDetails] = useState({ title: '', description: '' });
    const [assessmentId, setAssessmentId] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);
    const [questions, setQuestions] = useState({
        question1: '',
        question2: '',
        question3: '',
        question4: '',
        question5: ''
    });
    const toast = useToast();
    const navigate = useNavigate();

    useEffect(() => {
        fetchJobDetails();
        fetchAssessment();
    }, [jobId]);

    const fetchJobDetails = async () => {
        try {
            const response = await axiosInstance.get(`/jobs/${jobId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            // Now setting the complete jobDetails state with all the information needed for the AI prompt
            setJobDetails({
                title: response.data.jobTitle,
                description: response.data.description,
                industry: response.data.industry, // Make sure to include this field in your Job model
                customCriteria: response.data.customCriteria // This should be an array of criteria objects
            });
        } catch (error) {
            console.error('Error fetching job details:', error);
            toast({
                title: 'Error',
                description: 'Failed to fetch job details.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    // To GET the existing skill assessment
    const fetchAssessment = async () => {
        try {
            const response = await axiosInstance.get(`/skill-assessments/${jobId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            // Check if the data exists and has the questions
            if (response.data && response.data.question1) {
                setQuestions({
                    question1: response.data.question1,
                    question2: response.data.question2,
                    question3: response.data.question3,
                    question4: response.data.question4,
                    question5: response.data.question5,
                });
                setAssessmentId(response.data._id);
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setAssessmentId(null);
                console.log('No existing assessment found, ready to create a new one.');
            } else {
                console.error('Error fetching skill assessment:', error);
                toast({
                    title: 'Error',
                    description: 'Failed to fetch skill assessment.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        }
    };

    // To POST to the AI service for generating initial assessment content
    const generateAssessmentWithAI = async (jobId) => {
        setIsGenerating(true);
        try {
            const response = await axiosInstance.post('/generate-skill-assessment', {
                jobId,
                jobTitle: jobDetails.title,
                description: jobDetails.description,
                industry: jobDetails.industry, // Assuming you have industry in jobDetails
                customCriteria: jobDetails.customCriteria, // Assuming you have customCriteria in jobDetails
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });

            // Assuming the AI returns an object with keys question1, question2, etc.
            if (response.data && response.data.generatedQuestions) {
                // Assuming the AI returns an object with keys question1, question2, etc.
                const generatedQuestions = typeof response.data.generatedQuestions === 'string'
                    ? JSON.parse(response.data.generatedQuestions)
                    : response.data.generatedQuestions;
                setQuestions({
                    question1: generatedQuestions.question1 || '',
                    question2: generatedQuestions.question2 || '',
                    question3: generatedQuestions.question3 || '',
                    question4: generatedQuestions.question4 || '',
                    question5: generatedQuestions.question5 || '',
                });
            } else {
                // Handle the case where the data is not as expected
                console.error('Unexpected response structure:', response.data);
                toast({
                    title: 'Error',
                    description: 'The AI service returned an unexpected response.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error('Error generating assessment with AI:', error);
            toast({
                title: 'Error',
                description: 'Failed to generate assessment.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setIsGenerating(false);
    };

    const handleQuestionChange = (e) => {
        const { name, value } = e.target;
        setQuestions(prevQuestions => ({
            ...prevQuestions,
            [name]: value
        }));
    };

    const handleSave = async () => {
        setIsSaving(true);
        // Construct the assessment data
        const assessmentData = {
            jobId, // Assuming jobId is coming from the useParams hook
            question1: questions.question1,
            question2: questions.question2,
            question3: questions.question3,
            question4: questions.question4,
            question5: questions.question5
        };

        try {
            let response;
            if (assessmentId) {
                // If we have an assessmentId, we update the existing assessment
                response = await axiosInstance.put(`/skill-assessments/${assessmentId}`, assessmentData, {
                    headers: { Authorization: `Bearer ${token}` },
                });
            } else {
                // If we don't have an assessmentId, we create a new assessment
                response = await axiosInstance.post('/skill-assessments', assessmentData, {
                    headers: { Authorization: `Bearer ${token}` },
                });
            }
            // Show a success toast with the response data
            toast({
                title: 'Assessment Saved',
                description: 'Your skill assessment has been saved successfully.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            // Optionally, handle the response data
            console.log(response.data);
        } catch (error) {
            console.error('Error saving skill assessment:', error);
            // Handle error
            toast({
                title: 'Error',
                description: 'Failed to save the skill assessment.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
        await new Promise((resolve) => setTimeout(resolve, 700));
        setIsSaving(false);
        navigate('/job-listings');
    };

    return (
        <Flex direction="column" h="100vh" w="100%">
            {/* Background container */}
            <Box
                position="fixed"
                top={0}
                left={0}
                right={0}
                bottom={0}
                bgImage={Background}
                bgSize="cover"
                bgRepeat="no-repeat"
                bgPosition="center"
                zIndex={-1}
            />
            {/* Content container */}
            <Flex flex="1" w="100%" justify={"center"} pl="300px">
                <Flex p={4} w={"70%"} justifyContent={"center"}>
                    <Box mt={4} bg={"whiteAlpha.800"} borderRadius={"2xl"} w={"100%"} h={"fit-content"} >
                        <VStack p={5} spacing={5}>
                            <Flex w={"100%"} justify={"space-between"} >
                                <Link
                                    to={{
                                        pathname: `/job-listings`,
                                    }}
                                >
                                    <Button _hover={{ bg: "black", color: "white", border: "2px", borderColor: "white" }} bg={"#FFD166"}>
                                        Back
                                    </Button>
                                </Link>
                            </Flex>
                            <Heading as="h1">Skill Assessment for {jobDetails.title}</Heading>
                            {Object.keys(questions).map((key, index) => (
                                <FormControl key={key}>
                                    <FormLabel>Question {index + 1}</FormLabel>
                                    <Textarea
                                        name={key}
                                        h={"fit-content"}
                                        bg={"white"}
                                        value={questions[key]}
                                        onChange={handleQuestionChange}
                                        placeholder={`Enter question ${index + 1}`}
                                        size='lg'
                                    />
                                </FormControl>
                            ))}
                            <Flex w={"100%"} justify={"end"}>
                                <Button
                                    isLoading={isGenerating}
                                    mr={4}
                                    bg={"#118AB2"}
                                    _hover={{ bg: "black", color: "white", border: "2px", borderColor: "white" }}
                                    color={"white"}
                                    loadingText='Generating...'
                                    onClick={() => generateAssessmentWithAI(jobId)}
                                >
                                    Generate Assessment
                                </Button>

                                <Button
                                    isLoading={isSaving}
                                    _hover={{ bg: "black", color: "white", border: "2px", borderColor: "white" }}
                                    loadingText="Savings...." bg={"#01BF02"} color={"white"} onClick={handleSave}>
                                    Save Assessment
                                </Button>
                            </Flex>
                        </VStack>
                    </Box>
                </Flex >
            </Flex>
        </Flex >
    );
};

export default SkillAssessment;