import React, { useState, useEffect, useRef } from 'react';
import { Progress, Flex, IconButton, Tooltip } from '@chakra-ui/react';
import { FaPlay, FaPause, FaDownload } from 'react-icons/fa';

const CustomAudioPlayer = ({ audioURL }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const audioRef = useRef(null);

    // Update the audio source and reset state when audioURL changes
    useEffect(() => {
        // Reset the audio reference with the new URL
        audioRef.current = new Audio(audioURL);
        setIsPlaying(false);
        setProgress(0);

        // Add event listeners for playing and pausing
        const audio = audioRef.current;
        audio.onplay = () => setIsPlaying(true);
        audio.onpause = () => setIsPlaying(false);

        return () => {
            audio.onplay = null;
            audio.onpause = null;
        };
    }, [audioURL]);

    useEffect(() => {
        if (isPlaying) {
            audioRef.current.play();
        } else {
            audioRef.current.pause();
        }
    }, [isPlaying]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (audioRef.current) {
                const { currentTime, duration } = audioRef.current;
                const progressTime = (currentTime / duration) * 100;
                setProgress(progressTime);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const togglePlayPause = () => {
        setIsPlaying(!isPlaying);
    };

    return (
        <Flex direction="column" align="center">
            <Flex align="center">
                <Tooltip label={isPlaying ? 'Pause' : 'Play'} fontSize="md">
                    <IconButton
                        icon={isPlaying ? <FaPause /> : <FaPlay />}
                        onClick={togglePlayPause}
                        size="md"
                        color={"#01bf02"}
                        borderColor={"#01bf02"}
                        variant="outline"
                        isRound
                        mr={2}
                    />
                </Tooltip>
                {/* Download Button */}
                <Tooltip label="Download" fontSize="md">
                    <a href={audioURL} download={`audio-file.mp3`}>
                        <IconButton
                            icon={<FaDownload />}
                            size="md"
                            colorScheme="blue"
                            variant="outline"
                            isRound
                        />
                    </a>
                </Tooltip>
            </Flex>
        </Flex>
    );
};

export default CustomAudioPlayer;
