import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box, Text, Tabs, TabList, Grid, TabPanels, Tab, TabPanel, Button, Textarea, Flex,
    Badge, Tooltip, HStack, Center, useDisclosure, Divider, VStack, Drawer, DrawerBody,
    DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, Link, Modal,
    ModalBody, ModalOverlay, FormControl, FormLabel, ModalContent, ModalHeader, Input, ModalFooter,
    ModalCloseButton, Select, Tag, useToast, Spinner, Container, SimpleGrid, InputLeftElement, InputGroup, Icon, Checkbox, Heading
} from '@chakra-ui/react';
import { EmailIcon, AddIcon, ChevronRightIcon } from '@chakra-ui/icons';
import MessageModal from '../../components/MessageModal';
import { io } from "socket.io-client";
import _ from 'lodash';
import Background from '../../assets/Background.webp';
import axiosInstance from '../../components/axiosInstance';
import { socketAtom } from '../../socketAtom';
import { useAtom } from 'jotai';
import EvaluationForm from '../../components/EvaluationForm';
import CriteriaSelection from '../../components/CriteriaSelection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faListOl, faMagnifyingGlass, faPeopleArrows, faSpinner, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { FaSearch } from 'react-icons/fa';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import CustomAudioPlayer from '../../components/CustomerAudioPlayer';


const socket = io();

const evaluationItems = [
    { criteria: 'Teamwork' },
    { criteria: 'Problem Solving' },
    { criteria: 'Communication Skills' },
    { criteria: 'Adaptability' },
    { criteria: 'Leadership' },
    { criteria: 'Attention to Detail' },
    { criteria: 'Creativity' },
    { criteria: 'Work Ethic' },
    { criteria: 'Time Management' },
    { criteria: 'Technical Knowledge' },
    { criteria: 'Conflict Resolution' },
    { criteria: 'Customer Service' },
    { criteria: 'Project Management' },
    { criteria: 'Innovation' },
    { criteria: 'Cultural Fit' },
    { criteria: 'Interpersonal Skills' },
    { criteria: 'Analytical Thinking' },
    { criteria: 'Resilience' },
    { criteria: 'Initiative' },
    { criteria: 'Professionalism' },
    { criteria: 'Emotional Intelligence' },
    { criteria: 'Strategic Thinking' },
    { criteria: 'Collaboration' },
    { criteria: 'Learning Agility' },
    { criteria: 'Integrity and Ethics' },
    // Add any industry-specific skills here, if needed
];

const Applicants = () => {
    const { jobId } = useParams();
    const token = localStorage.getItem('auth_token');
    const [isLoading, setIsLoading] = useState(false);
    const [comparing, setComparing] = useState(false);
    const [isShortlist, setIsShortlist] = useState(false);
    const [isFavoriting, setIsFavoriting] = useState({});
    const [interview, setInterview] = useState(null);
    const [applicants, setApplicants] = useState([]);
    const [showHighMatchOnly, setShowHighMatchOnly] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredApplicants, setFilteredApplicants] = useState([]);
    const [currentApplicant, setCurrentApplicant] = useState(null);
    const [subTotal, setSubTotal] = useState(null);
    const drawerDisclosure = useDisclosure();
    const [socket] = useAtom(socketAtom);
    const [comparisons, setComparisons] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [rejectionLetter, setRejectionLetter] = useState('');
    const [applicantId, setApplicantId] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const messageModalDisclosure = useDisclosure();
    const [existingEvaluation, setExistingEvaluation] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [messages, setMessages] = useState([]);
    const [assessmentId, setAssessmentId] = useState(null);
    const [selectedApplicants, setSelectedApplicants] = useState([]);
    const [questions, setQuestions] = useState(null);
    const [note, setNote] = useState('');
    const [comments, setComments] = useState([]);
    const [unreadCounts, setUnreadCounts] = useState({});
    const [isButtonDisabled] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [selectedTab, setSelectedTab] = useState(null);
    const [applicantTabName, setApplicantTabName] = useState('');
    const [favorites, setFavorites] = useState({});
    const [job, setJob] = useState(null);
    const [tabs, setTabs] = useState([
        { name: 'Applicants', applicants: [] },
        { name: 'Revealed Applicants', applicants: [] },
    ]);
    const newTabDisclosure = useDisclosure();
    const [newTabName, setNewTabName] = useState('');
    const [employerId, setEmployerId] = useState(null);
    const [user, setUser] = useState({});
    const jobTitle = applicants[0]?.job?.jobTitle || '';
    const company = applicants[0]?.job?.company || null;
    const [currentTabApplicants, setCurrentTabApplicants] = useState([]);
    const toast = useToast();
    const businessId = localStorage.getItem('businessId');
    const navigate = useNavigate();

    const fetchEmployerId = async () => {
        try {
            const response = await axiosInstance.get('/employerId', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setEmployerId(response.data.employerId);
        } catch (error) {
            console.error('Error fetching employerId:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get('/user');
                setUser(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error('Failed to fetch user:', error);
                setIsLoading(false);
            }
        };

        fetchData();

        const interval = setInterval(fetchData, 3 * 60 * 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        fetchEmployerId();
        fetchTabs();
    }, [token, jobId]);

    // Function to fetch SkillAssessment ID and Questions
    const fetchAssessmentIdAndQuestions = async () => {
        try {
            const response = await axiosInstance.get(`/skill-assessments/get-id/${jobId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if (response.data.assessmentId) {
                setAssessmentId(response.data.assessmentId);
                setQuestions({
                    question1: response.data.questions.question1,
                    question2: response.data.questions.question2,
                    question3: response.data.questions.question3,
                    question4: response.data.questions.question4,
                    question5: response.data.questions.question5,
                });
                console.log('Skill Assessment ID and Questions fetched:', response.data);
            } else {
                console.log('Skill Assessment not found for jobId:', jobId);
                setAssessmentId(null); // Reset the state if not found
                // Reset questions here as well
            }
        } catch (error) {
            console.error('Error fetching skill assessment data:', error);
            setAssessmentId(null);
        }
    };

    useEffect(() => {
        let filtered = applicants;

        // Filter by match score if checkbox is checked
        if (showHighMatchOnly) {
            filtered = filtered.filter(applicant => applicant.aiJobMatchScan?.matchScore > 74);
        }

        // Filter by name if there is a search term
        if (searchTerm) {
            filtered = filtered.filter(applicant =>
                applicant.jobSeeker?.firstName.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        if (searchTerm) {
            filtered = filtered.filter(applicant =>
                applicant.jobSeeker?.lastName.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        // Sort by favorited status and match score
        filtered.sort((a, b) => {
            // Assuming `employerId` is in the correct scope and is the logged-in user's ID
            const aFavorited = a.favorited?.some(fav => fav.userId && fav.userId.$oid === employerId);
            const bFavorited = b.favorited?.some(fav => fav.userId && fav.userId.$oid === employerId);
            if (aFavorited && !bFavorited) return -1;
            if (!aFavorited && bFavorited) return 1;
            return b.aiJobMatchScan?.matchScore - a.aiJobMatchScan?.matchScore;
        });

        setFilteredApplicants(filtered);
    }, [applicants, showHighMatchOnly, searchTerm, employerId]); // ensure employerId is included in the dependency array      

    // Handler for the search input field
    const handleSearchTermChange = (event) => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
        const fetchJob = async () => {
            try {
                const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/api/jobs/${jobId}`);
                setJob(response.data);
            } catch (error) {
                console.error('Error fetching job:', error);
                setJob({}); // Set to an empty object in case of an error
            }
        };

        if (jobId) {
            fetchJob();
        }
    }, [jobId]);

    useEffect(() => {
        fetchComparisons();
    }, [jobId]);

    const fetchComparisons = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/comparisons/${jobId}`);
            setComparisons(response.data);
        } catch (error) {
            console.error('Error fetching comparison data:', error);
            // Handle errors appropriately
        }
    };

    const fetchEvaluation = async () => {
        if (!currentApplicant || !currentApplicant._id) return;

        try {
            const response = await axiosInstance.get(`/applicant/${currentApplicant._id}/evaluation`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            if (response.data && response.data.employerId) {
                setExistingEvaluation(response.data);
                setIsEditing(true);
            } else {
                setExistingEvaluation(null);
                setIsEditing(false);
            }
        } catch (error) {
            console.error('Error fetching evaluation:', error);
            setExistingEvaluation(null);
            setIsEditing(false);
        }
    };

    useEffect(() => {
        fetchEvaluation();
    }, [currentApplicant, token]);

    useEffect(() => {
        if (jobId) {
            fetchAssessmentIdAndQuestions();
        }
    }, [jobId]);

    const updateApplicantAssessment = async (applicantId, assessmentData, applicant) => {
        try {
            const updateResponse = await axiosInstance.put(`/applicants/${applicantId}/assessment`, assessmentData, {
                headers: { Authorization: `Bearer ${token}` },
            });

            if (updateResponse.data) {
                toast({
                    title: 'Assessment Sent',
                    description: 'The applicant\'s assessment has been sent successfully.',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: "bottom",
                });

                const userId = applicant.jobSeeker ? applicant.jobSeeker.jobSeekerId : undefined;
                const jobId = applicant.job ? applicant.job.jobId : undefined;
                const title = applicant.job ? applicant.job.jobTitle : undefined;
                const companyName = applicant.company ? applicant.company.companyName : undefined;

                const notificationData = {
                    userId,
                    jobId,
                    title,
                    applicantId,
                    companyName,
                    type: 'assessment invite',
                };
                console.log('notification details', notificationData)

                await axiosInstance.post('/create-notification', notificationData, {
                    headers: { Authorization: `Bearer ${token}` }
                });

            }
        } catch (error) {
            console.error('Error updating applicant assessment:', error);
            toast({
                title: 'Error',
                description: 'Failed to send the applicant\'s assessment. Please try again later.',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: "bottom",
            });
        }
    };

    const handleAssessmentSubmit = async (applicantId, applicant) => {

        const questionsArray = [
            questions.question1,
            questions.question2,
            questions.question3,
            questions.question4,
            questions.question5,
        ];

        const assessmentData = {
            questions: questionsArray,
            isSent: true,
            assessmentId: assessmentId,

        };

        await updateApplicantAssessment(applicantId, assessmentData, applicant);
    };

    const handleNoteChange = (e) => {
        setNote(e.target.value);
    };

    const handleSelectApplicant = (applicantId) => {
        if (selectedApplicants.includes(applicantId)) {
            setSelectedApplicants(selectedApplicants.filter(id => id !== applicantId));
        } else if (selectedApplicants.length < 2) {
            setSelectedApplicants([...selectedApplicants, applicantId]);
        }
    };

    const handleCompareApplicants = async () => {
        setComparing(true);
        if (selectedApplicants.length === 2) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/compare-applicants`, {
                    applicantOneId: selectedApplicants[0],
                    applicantTwoId: selectedApplicants[1]
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                });

                console.log('Comparison data:', response.data);
                // Handle the response
            } catch (error) {
                console.error('Error:', error.response || error.message);
                // Handle the error
            }
        } else {
            alert('Please select exactly 2 applicants for comparison.');
        }
        await fetchComparisons();
        setComparing(false);
    };

    const handleSubmitNote = async () => {
        if (!currentApplicant || !currentApplicant._id) {
            console.error('Current Applicant ID is undefined');
            return;
        }

        try {
            const response = await axiosInstance.put(`/applicants/${currentApplicant._id}/comment`, {
                content: note,
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });

            const newComment = response.data.comment;
            setComments(prevComments => [...prevComments, newComment]);
            setNote('');
        } catch (error) {
            console.error('Error submitting comment:', error);
            // Handle the error (e.g., show an error message)
        }
    };

    const handleFormSubmit = async (scores) => {
        const apiUrl = `/applicants/${currentApplicant._id}/evaluations`;

        const scoredItems = scores.filter(item => item.score > 0);

        try {
            const response = await axiosInstance.post(
                apiUrl,
                { items: scoredItems },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            console.log('Success:', response.data);
            toast({ title: 'Evaluation updated successfully.', status: 'success', duration: 5000, isClosable: true });

        } catch (error) {
            console.error('Error submitting evaluation:', error);

        }
    };

    const deleteEvaluation = async () => {
        try {
            const response = await axiosInstance.delete(`/applicants/${currentApplicant._id}/evaluations`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            console.log(response.data); // Or handle this in your UI, e.g., by showing a success message
            // Update state to reflect the deletion
            setExistingEvaluation(null);
            setIsEditing(false);
            // Optionally refresh the list of applicants or evaluations here
        } catch (error) {
            console.error('Error deleting evaluation:', error);
            // Handle error (e.g., show an error message)
        }
    };

    const handleCriteriaSubmit = (selectedCriteria) => {
        console.log('Handle criteria submit:', selectedCriteria); // Debug line to be removed
        setSelectedItems(selectedCriteria);
        setExistingEvaluation(null); // Ensure that the existing evaluation is cleared if creating new
        setIsEditing(true);
    };

    const itemsToEvaluate = isEditing && existingEvaluation ? existingEvaluation.items.map(item => ({ criteria: item.criteria })) : evaluationItems;

    useEffect(() => {
        socket.on('new_comment', (data) => {
            if (data.applicantId === currentApplicant?._id) {
                setComments(prevComments => {
                    const updatedComments = [...prevComments, data.comment];
                    console.log('Updated comments:', updatedComments);
                    return updatedComments;
                });
            }
        });

        return () => {
            socket.off('new_comment');
        };
    }, [currentApplicant]);

    const fetchApplicants = useCallback(async (tabId) => {
        if (!tabId) return [];
        try {
            const response = await axiosInstance.get(`/tabs/${tabId}/applicants`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return response.data || [];
        } catch (error) {
            console.error('Error fetching applicants:', error.response ? error.response.data : error.message);
            return [];
        }
    }, [token]);

    const fetchTabs = useCallback(async () => {
        try {
            const response = await axiosInstance.get(`/jobs/${jobId}/tabs`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const fetchedTabs = response.data;
            setTabs(fetchedTabs);

            if (fetchedTabs.length > 0) {
                const defaultTab = fetchedTabs[0];
                setSelectedTab(defaultTab);
                // If defaultTab has _id, fetch its applicants immediately
                if (defaultTab._id) {
                    const fetchedApplicants = await fetchApplicants(defaultTab._id);
                    setCurrentTabApplicants(fetchedApplicants);
                }
            }
        } catch (error) {
            console.error('Error fetching tabs:', error.response ? error.response.data.error : error.message);
        }
    }, [token, jobId, fetchApplicants]);

    useEffect(() => {
        fetchEmployerId();
        fetchTabs();
    }, [token, jobId, fetchTabs]);


    useEffect(() => {
        if (tabs.length > 0 && !selectedTab) {
            const defaultTab = tabs[0];
            setSelectedTab(defaultTab); // Automatically select the first tab
        }
    }, [tabs]);

    const handleCreateTab = async () => {
        if (newTabName) {
            try {
                const response = await axiosInstance.post(
                    `/tabs`,
                    { name: newTabName, jobId },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                const newTab = response.data;
                setTabs(prevTabs => [...prevTabs, newTab]);
                setSelectedTab(newTab);
                const fetchedApplicants = await fetchApplicants(newTab._id);
                setCurrentTabApplicants(fetchedApplicants);
                newTabDisclosure.onClose();

            } catch (error) {
                console.error('Error creating new tab:', error.response ? error.response.data.error : error.message);
            }
        } else {
            console.error('Tab name is required');
        }
    };


    const fetchData = useCallback(async () => {
        if (!token || !jobId) return;

        try {
            const response = await axiosInstance.get(`/job/${jobId}/applicants`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            if (Array.isArray(response.data.applicants)) {
                setApplicants(response.data.applicants);
                // Reset the current tab's applicants
                setCurrentTabApplicants(response.data.applicants);
            } else {
                console.error('Unexpected data format:', response.data);
                // Reset to empty array
                setApplicants([]);
                setCurrentTabApplicants([]);
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                console.log('No applicants found for the specified job ID.');
            } else {
                console.error('Error fetching applicants:', error.response ? error.response.data.message : error.message);
            }
            // Handle error by resetting the current tab's applicants
            setCurrentTabApplicants([]);
        }
    }, [token, jobId]);

    // Modify the handleTabClick to handle the applicants for the custom tabs
    const handleTabClick = async (tabName) => {
        const selectedTab = tabs.find(tab => tab.name === tabName);
        setSelectedTab(selectedTab);

        if (selectedTab && !selectedTab._id) {
            if (tabName === 'Applicants') {
                await fetchData();
            } else if (tabName === 'Revealed Applicants') {
                await fetchData();

            }
        } else if (selectedTab && selectedTab._id) {
            const fetchedApplicants = await fetchApplicants(selectedTab._id);
            setCurrentTabApplicants(fetchedApplicants);
        }
    };

    useEffect(() => {
        if (selectedTab && selectedTab._id) {
            // If it's a custom tab, we need to fetch the applicants for that tab
            fetchApplicants(selectedTab._id);
        } else {
            // Otherwise, fetch default data
            fetchData();
        }
    }, [selectedTab, fetchData, fetchApplicants]);

    useEffect(() => {
        if (currentApplicant) {
            console.log('Current Applicant updated:', currentApplicant);
        }
    }, [currentApplicant]);

    const refreshApplicants = async () => {
        try {
            const response = await axiosInstance.get(`/job/${jobId}/applicants`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            // Assuming the response structure is { data: { applicants: [...] } }
            if (response.data && Array.isArray(response.data.applicants)) {
                setApplicants(response.data.applicants);
            } else {
                console.error('Unexpected response structure:', response);
            }
        } catch (error) {
            console.error('Error refreshing applicants:', error);
        }
    };

    const handleMoveApplicantToTab = async (newTabId) => {
        if (currentApplicant && selectedTab && newTabId) {
            setIsProcessing(true); // Start processing
            try {
                await moveApplicant(currentApplicant._id, selectedTab, newTabId);
                setIsProcessing(false); // End processing
            } catch (error) {
                console.error("Error in moving applicant:", error);
                setIsProcessing(false); // End processing in case of error
            }
        }
    };

    const moveApplicant = async (applicantId, oldTabId, newTabId) => {
        if (!applicantId || !oldTabId || !newTabId) {
            console.error("Required IDs are undefined", { applicantId, oldTabId, newTabId });
            return;
        }

        console.log("Moving Applicant ID:", applicantId);

        try {
            const response = await axiosInstance.put(
                `/applicants/move/${applicantId}`,
                { oldTabId, newTabId },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            // Update the UI with the new tab name
            const newTabName = response.data.tabName;
            setApplicantTabName(newTabName);

            // Fetch new data
            fetchTabs();
            fetchApplicants(newTabId);
            refreshApplicants();

            // Success toast message
            toast({
                title: 'Applicant moved successfully!',
                description: `Applicant is now under the "${newTabName}" tab.`,
                status: 'success',
                duration: 9000,
                isClosable: true,
            });

        } catch (error) {
            // Log and show error toast message
            const errorMessage = error.response ? error.response.data.error : error.message;
            console.error('Error moving applicant:', errorMessage);

            toast({
                title: 'Error moving applicant',
                description: errorMessage,
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleDeleteTab = async () => {
        const confirmation = window.prompt(`Confirm deletion by entering the tab name "${selectedTab.name}"`);
        if (confirmation === selectedTab.name) {
            await deleteTab(selectedTab._id);
        } else {
            alert('Tab name does not match. Deletion cancelled.');
        }
    };

    const deleteTab = async (tabId) => {
        try {
            await axiosInstance.delete(`/tabs/${tabId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            fetchTabs();  // Updated to call fetchTabs
        } catch (error) {
            console.error('Error deleting tab:', error);
        }
    };

    const fetchAIInterview = async (applicantId) => {
        console.log('fetchAIInterview applicantId', applicantId);
        try {
            const response = await axiosInstance.get(`/employer/ai-interview/${applicantId}`, {
                headers: { "Authorization": `Bearer ${token}` }
            });
            if (response.data) {
                // Process the fetched AIInterview data
                console.log('Fetched AIInterview:', response.data);
                setInterview(response.data);
            } else {

            }
        } catch (error) {

        }
    };

    const fetchMessages = async (applicantId) => {
        try {
            const response = await axiosInstance.get(`/messages/${applicantId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            setMessages(response.data.messages);
        } catch (error) {
            console.error('Error fetching messages:', error.response ? error.response.data.error : error.message);
        }
    };

    const messageRef = useRef();

    // Frontend: Function to handle sending messages
    const handleSendMessage = async (message) => {
        console.log(currentApplicant);
        if (!currentApplicant || !employerId) {
            console.error('currentApplicant or employer is null or undefined');
            return;
        }
        if (message) {
            try {
                const response = await axiosInstance.post('/messages', {
                    recipientId: currentApplicant.jobSeeker.jobSeekerId,
                    content: message
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                });

                console.log(response);
                console.log(response.data.message);

                // Update the messages state with the new message
                setMessages(prevMessages => [...prevMessages, response.data.message]);

                try {
                    await axiosInstance.post('/email/message', {
                        recipientEmail: currentApplicant.jobSeeker.email,
                        content: message
                    }, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    });
                    console.log('Email sent successfully');
                } catch (emailError) {
                    console.error('Error sending email:', emailError.response ? emailError.response.data.error : emailError.message);
                }

            } catch (error) {
                console.error('Error sending message:', error.response ? error.response.data.error : error.message);
            }
        }
    };

    const handleApplicantClick = (applicant) => {
        if (!applicant || !applicant._id) {
            console.error('Applicant or applicant._id is undefined');
            return;
        }
        setCurrentApplicant(applicant);
        setComments([]);
        setSubTotal(applicant.job.subTotal);
        setSelectedTab(applicant.currentTabId);
        drawerDisclosure.onOpen();
        markAsSeen(applicant._id);
        fetchMessages(applicant._id);
        fetchEvaluation(applicant._id);
        fetchAIInterview(applicant._id);
    };

    const openMessageModal = () => {
        console.log('Button clicked');
        console.log('Is modal open before calling onOpen?', messageModalDisclosure.isOpen);
        messageModalDisclosure.onOpen();
        console.log('Is modal open after calling onOpen?', messageModalDisclosure.isOpen);
        markMessagesAsRead(currentApplicant, employerId);
    };

    // <MessageModal
    //     ref={messageRef}
    //     isOpen={messageModalDisclosure.isOpen}
    //    onClose={messageModalDisclosure.onClose}
    //     onSend={handleSendMessage}
    //     messages={messages}
    //     currentUserId={employerId}
    //     MessageList={MessageList}
    // />

    const revealedApplicants = Array.isArray(applicants) ? applicants.filter(applicant => applicant.isRevealed) : [];
    const unrevealedApplicants = Array.isArray(applicants) ? applicants.filter(applicant => !applicant.isRevealed) : [];

    const reveal = async (applicant) => {
        setIsLoading(true);
        setCurrentApplicant(applicant);
        try {

            setTimeout(async () => {
                if (currentApplicant && currentApplicant._id) {
                    await revealApplicant(currentApplicant._id);
                } else {
                    console.error('Error: currentApplicant._id is undefined');
                }
            }, 0);
        } catch (error) {
            console.error('Error revealing applicant or refreshing applicants:', error);
        }
        setIsLoading(false);
    };

    const revealApplicant = async (applicantId) => {
        setIsLoading(true);
        if (!applicantId) {
            console.error("Applicant ID is undefined");
            return;
        }

        // Define the charge amount
        const chargeAmount = subTotal * 100;

        try {
            // Call the backend endpoint to charge the employer's card
            const chargeResponse = await axiosInstance.post('/charge-customer', {
                employerId,
                amount: chargeAmount,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (chargeResponse.data.success) {
                // Continue with the revealing process
                const revealResponse = await axiosInstance.put(`/applicants/reveal/${applicantId}`, {
                    chargedAmount: chargeAmount,
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                refreshApplicants();
                fetchData();

                await axiosInstance.put(`/applicants/update-status/${applicantId}`, {
                    status: 'has been revealed'
                }, {
                    headers: { Authorization: `Bearer ${token}` }
                });

                const { applicant } = revealResponse.data;
                console.log('applicant:', applicant)
                if (!applicant) {
                    console.error('No applicant found with the given _id:', applicant);
                    return;
                }

                setCurrentApplicant(applicant);
                fetchEmployerId();
                fetchTabs();

                const userId = applicant.jobSeeker ? applicant.jobSeeker.jobSeekerId : undefined;
                const jobId = applicant.job ? applicant.job.jobId : undefined;
                console.log('Job ID:', jobId);
                const title = applicant.job ? applicant.job.jobTitle : undefined;
                const companyName = applicant.company ? applicant.company.companyName : undefined;

                console.log('CREATE NOTIFICATION')
                console.log({ userId, jobId, title, companyName, token });  // Log data before sending request

                // Now create the notification
                await axiosInstance.post('/create-notification', {
                    userId,
                    jobId: jobId._id,
                    title,
                    applicantId,
                    companyName,
                    type: 'revealed',
                    timestamp: new Date(),
                    isFresh: true,
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })

                if (jobId) {
                    await axiosInstance.put(`/jobs/update-fees/${jobId._id}`, {
                        subTotal: chargeAmount / 100
                    }, {
                        headers: { Authorization: `Bearer ${token}` }
                    })
                        .then(response => {
                            console.log('Recruiter fees updated successfully:', response.data);
                        })
                        .catch(error => {
                            console.error('Error updating recruiter fees:', error);
                        });
                }

            } else {
                console.error('Charge was not successful');
                // Handle charge failure (e.g., show an error message)
            }

        } catch (error) {
            if (error.response && error.response.data) {
                // Specific error from the backend
                console.error('Error:', error.response.data.message);
            } else {
                // General error (network error, timeout, etc)
                console.error('Error revealing applicant or fetching employer details:', error);
            }
        }
        setIsLoading(false);
    };

    const markAsSeen = async (applicantId) => {
        try {
            await axiosInstance.put(`/applicants/markAsSeen/${applicantId}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            refreshApplicants();
        } catch (error) {
            console.error('Error marking applicant as seen:', error.response ? error.response.data : error.message);
        }
    };

    const markMessagesAsRead = async (currentApplicant, employerId) => {
        try {
            // Check if currentApplicant is defined before trying to access its connectionId property
            if (currentApplicant) {
                const connectionId = currentApplicant.connectionId; // Assuming the connection ID is stored in connectionId property of currentApplicant

                // Make the API request to mark the messages as read
                await axiosInstance.put(`/messages/mark-as-read`, { connectionId }, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                // Update the unreadCounts state immediately without relying on the previous state
                const updatedUnreadCounts = {
                    ...unreadCounts,
                    [connectionId]: 0,
                };
                setUnreadCounts(updatedUnreadCounts);
            } else {
                console.warn('currentApplicant is undefined');
            }
        } catch (error) {
            console.error('Error marking messages as read:', error);
        }
    };

    const handleRevealedApplicantClick = (applicant) => {
        setCurrentApplicant(applicant);
        setComments([]);
        markAsSeen(applicant._id);
        console.log('Revealed Applicant set:', applicant);
        drawerDisclosure.onOpen();
        fetchEvaluation(applicant._id);
        fetchMessages(applicant.jobSeeker.jobSeekerId);
        fetchAIInterview(applicant._id);
    };

    useEffect(() => {
        const fetchUnreadCounts = async () => {
            if (!Array.isArray(applicants)) {
                console.error('applicants is not an array:', applicants);
                return;  // Exit the function early if applicants is not an array
            }

            try {
                const connectionIds = applicants.map(applicant => applicant.connectionId);
                const response = await axiosInstance.post(`/messages/unreadCounts`, { connectionIds }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const unreadCounts = response.data.unreadCounts;
                setUnreadCounts(unreadCounts);
            } catch (error) {
                console.error('Error fetching unread counts:', error);
            }
        };

        fetchUnreadCounts();
    }, [applicants, token]);

    const handleFavorite = async (applicantId, userId) => {
        setIsFavoriting(prev => ({ ...prev, [applicantId]: true }));
        // Optimistic update
        setApplicants(prevApplicants => prevApplicants.map(applicant =>
            applicant._id === applicantId
                ? { ...applicant, favorited: [...applicant.favorited, { userId: userId }] }
                : applicant
        ));

        try {
            await axiosInstance.post(`/applicants/${applicantId}/favorite`, { userId });
        } catch (error) {
            console.error('Error favoriting applicant:', error);
            // Revert optimistic update if error occurs
            setApplicants(prevApplicants => prevApplicants.map(applicant =>
                applicant._id === applicantId
                    ? { ...applicant, favorited: applicant.favorited.filter(fav => fav.userId !== userId) }
                    : applicant
            ));
        }
        await refreshApplicants(); // Assuming refreshApplicants returns a promise
        await fetchData(); // Assuming fetchData returns a promise
        setIsFavoriting(prev => ({ ...prev, [applicantId]: false }));
    };

    const handleUnfavorite = async (applicantId, userId) => {
        setIsFavoriting(prev => ({ ...prev, [applicantId]: true }));
        // Optimistic update
        setApplicants(prevApplicants => prevApplicants.map(applicant =>
            applicant._id === applicantId
                ? { ...applicant, favorited: applicant.favorited.filter(fav => fav.userId !== userId) }
                : applicant
        ));

        try {
            await axiosInstance.delete(`/applicants/${applicantId}/unfavorite`, { data: { userId } });
        } catch (error) {
            console.error('Error unfavoriting applicant:', error);
            // Revert optimistic update if error occurs
            setApplicants(prevApplicants => prevApplicants.map(applicant =>
                applicant._id === applicantId
                    ? { ...applicant, favorited: [...applicant.favorited, { userId: userId }] }
                    : applicant
            ));
        }
        await refreshApplicants(); // Assuming refreshApplicants returns a promise
        await fetchData(); // Assuming fetchData returns a promise
        setIsFavoriting(prev => ({ ...prev, [applicantId]: false }));
    };

    const renderApplicantBrief = (applicant, handleClick, userId) => {
        const matchScore = applicant?.aiJobMatchScan?.matchScore || 0;
        const rejected = !!applicant?.rejectionLetter;
        const skillAssessmentScore = applicant?.jobSeekerAssessment?.skillAssessmentScore;
        const isFavorited = applicant.favorited?.some(fav => fav.userId === employerId) || false;
        console.log(applicant._id);

        const isCurrentlyFavoriting = isFavoriting[applicant._id];

        return (
            <Box
                borderRadius="3xl"
                p={2}
                w={"100%"}
                bg={"white"}
                _hover={{ bg: "whiteAlpha.800" }}
                shadow={applicant.isFresh ? "dark-lg" : "md"}
                //borderWidth={applicant.isFresh ? "2px" : "2px"}
                //borderColor={rejected ? "red" : (applicant.isFresh ? "#118AB2" : "gray.200")}
                onClick={() => handleClick(applicant)}
                cursor="pointer"
            >
                {applicant.isFresh && (
                    <Badge fontSize='0.9em' borderRadius={"md"} variant='outline' colorScheme='blue' >New Applicant</Badge>
                )}
                <Flex justify={"space-between"} >
                    <Flex>
                        {rejected ? (
                            <Text color={"red"}>Rejected</Text>
                        ) : (
                            isCurrentlyFavoriting ? (
                                <FontAwesomeIcon icon={faSpinner} size="lg" spin />
                            ) : (
                                <FontAwesomeIcon
                                    icon={faHeart}
                                    size="lg"
                                    style={{
                                        cursor: 'pointer',
                                        color: isFavorited ? "#01BF02" : "gray"
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevent the click from triggering the parent's onClick
                                        isFavorited ? handleUnfavorite(applicant._id, userId) : handleFavorite(applicant._id, userId);
                                    }}
                                />
                            )
                        )}
                        <Text ml={2} fontSize={"xl"} fontWeight={"bold"}>
                            {applicant.jobSeeker.firstName} {applicant.jobSeeker.lastName}
                        </Text>
                    </Flex>
                    {applicant.isRevealed && (
                        <Box mt={-4} display="flex" alignItems="center">
                            <EmailIcon />
                            <Text
                                color={unreadCounts[applicant.connectionId] > 0 ? 'green.500' : 'black'}
                                fontSize="md"
                                fontWeight="bold"
                                mr={2}
                                ml={2}
                            >
                                {unreadCounts[applicant.connectionId] || 0}
                            </Text>
                        </Box>
                    )}
                </Flex>
                <Flex align={"center"} justify={"space-between"} >
                    <Box>
                        <Text ml={4} fontSize={"sm"} fontWeight={"bold"}>
                            {jobTitle.length > 25 ? `${jobTitle.substring(0, 20)}...` : jobTitle}
                        </Text>
                    </Box>
                    <Box>
                        <Text color={matchScore > 74 ? "#118AB2" : matchScore < 59 ? "#EF476F" : "#FFD166"} fontWeight={"bold"} fontSize={"md"}>
                            Match Score: {matchScore}%
                        </Text>
                        {applicant?.jobSeekerAssessment?.skillAssessmentScore && (
                            <Text color={skillAssessmentScore > 74 ? "#118AB2" : skillAssessmentScore < 59 ? "#EF476F" : "#FFD166"} fontWeight={"bold"} fontSize={"md"}>
                                Skill Assessment: {skillAssessmentScore}%
                            </Text>
                        )}
                    </Box>
                </Flex>
                <Flex w={"100%"} justify={"flex-end"} >
                    {applicant.isRevealed && (
                        <Flex wrap="wrap" mt={2}>
                            {applicant.tabName && (<Text> Located in the
                                <Tag color={"white"} shadow={"md"} borderColor={"white"} border={"1px"} bg={"#118AB2"} key={applicant._id} borderRadius="lg" variant="outline" ml={1} >
                                    {applicant.tabName}
                                </Tag> tab
                            </Text>
                            )}
                        </Flex>
                    )}
                </Flex>
            </Box >
        );
    };

    const sortedUnrevealedApplicants = useMemo(() => {
        return [...unrevealedApplicants].sort((a, b) => {
            // Check if 'a' is favorited
            const aIsFavorited = a.favorited?.some(fav => fav.userId === employerId);
            // Check if 'b' is favorited
            const bIsFavorited = b.favorited?.some(fav => fav.userId === employerId);

            if (aIsFavorited && !bIsFavorited) {
                return -1; // 'a' should come before 'b'
            }
            if (!aIsFavorited && bIsFavorited) {
                return 1; // 'b' should come before 'a'
            }
            // If both or neither are favorited, sort by matchScore
            return b.matchScore - a.matchScore;
        });
    }, [unrevealedApplicants, employerId]);

    const sortedRevealedApplicants = useMemo(() => {
        return [...revealedApplicants].sort((a, b) => {
            // Check if 'a' is favorited
            const aIsFavorited = a.favorited?.some(fav => fav.userId === employerId);
            // Check if 'b' is favorited
            const bIsFavorited = b.favorited?.some(fav => fav.userId === employerId);

            if (aIsFavorited && !bIsFavorited) {
                return -1;
            }
            if (!aIsFavorited && bIsFavorited) {
                return 1;
            }
            // If both or neither are favorited, sort by matchScore
            return b.matchScore - a.matchScore;
        });
    }, [revealedApplicants, employerId]);

    const openModal = (id) => {
        setApplicantId(id);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setRejectionLetter('');
    };

    const handleAddToShortlist = async (applicant) => {
        setIsShortlist(true);
        const applicantId = applicant._id;
        try {
            const response = await axiosInstance.put(`${process.env.REACT_APP_API_URL}/api/shortlist/${applicantId}`, { applicantId });
            console.log('Success:', response.data);
            await refreshApplicants();
            toast({
                title: 'SUCCESS!',
                description: `Applicant added to shortlist`,
                status: 'success',
                duration: 7000,
                isClosable: true,
            });

            const notificationResponse = await axiosInstance.post('/create-notification', {
                userId: applicant.jobSeeker.jobSeekerId,
                jobId: applicant.job.jobId,
                title: applicant.job.jobTitle,
                applicantId,
                companyName: applicant.company.companyName,
                type: 'shortlisted',
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log('Notification Response:', notificationResponse.data);

            toast({
                title: 'SUCCESS!',
                description: `Applicant added to shortlist and notification sent`,
                status: 'success',
                duration: 7000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error:', error);
            // Handle error (e.g., show an error message)
        }
        setIsShortlist(false);
    };

    const generateDraftLetter = async (applicant) => {
        if (!applicant || !applicant.jobSeeker || !applicant.jobSeeker.firstName || !applicant.jobSeeker.lastName) {
            console.error('Applicant information is incomplete.');
            return;
        }

        const applicantInfo = {
            applicantFirstName: applicant.jobSeeker.firstName,
            applicantLastName: applicant.jobSeeker.lastName,
            jobTitle: applicant.job.jobTitle,
            companyName: applicant.company.companyName,
            evaluations: applicant.evaluations,
            comments: applicant.comments,
            notes: applicant.notes,
            matchScore: applicant.aiJobMatchScan.matchScore,
            matchScore: applicant.aiJobMatchScan.matchScoreLogic,
            skillAssessment: applicant.jobSeekerAssessment.skillAssessmentScore,
            skillAssessment: applicant.jobSeekerAssessment.skillAssessmentTakeaways,
        };

        console.log('applicant', applicantInfo);

        try {
            const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/api/generate-rejection-letter`, applicantInfo);

            setRejectionLetter(response.data.generatedRejectionLetter);
        } catch (error) {
            console.error('Error generating draft rejection letter:', error);
        }
    };

    const handleReject = async (applicant) => {
        if (!applicant) {
            console.error("Applicant ID is undefined");
            return;
        }
        // Send the rejection letter and applicant ID to the backend
        try {
            // First, update the applicant's status and save the rejection letter
            const updateResponse = await axiosInstance.post(`/applicants/reject/${applicant._id}`, { rejectionLetter });

            if (updateResponse.data.success) {
                console.log('Rejection sent and letter saved successfully');

                // Construct the notification content with the rejection letter
                const notificationContent = `"${rejectionLetter}"`;

                // Create the rejection notification including the rejection letter content
                const notificationResponse = await axiosInstance.post(`/reject/create-notification`, {
                    userId: applicant.jobSeeker.jobSeekerId, // The ID of the job seeker
                    jobId: applicant.job.jobId, // The ID of the job
                    title: applicant.job.jobTitle, // The title of the job
                    applicantId: applicant._id, // The ID of the applicant
                    companyName: applicant.company.companyName, // The name of the company
                    type: 'rejection', // The type of notification
                    comment: notificationContent, // The rejection letter content
                    // Add any other fields required by your notification schema
                });

                if (notificationResponse.status === 201) {
                    console.log('Notification created successfully');
                    // Handle additional logic if needed, such as updating state or UI
                } else {
                    console.error('Failed to create notification:', notificationResponse.data.message);
                }
            } else {
                console.error('Failed to send rejection:', updateResponse.data.message);
            }
        } catch (error) {
            console.error('Error sending rejection or creating notification:', error);
        }
        await refreshApplicants();
        closeModal();
    };

    const renderInterviewQuestions = (interviewData) => {
        return interviewData?.questions?.map((item, index) => (
            <Box bg={"white"} shadow={"lg"} key={item._id.$oid} p={4} borderWidth="1px" borderRadius="lg">
                <Heading as="h3" size="md">Message {index + 1}</Heading>
                <Text mt={2}><strong>Interviewer:</strong> {item?.questionText}</Text>
                <Flex w={"100%"} justify={"flex-end"}>
                    <CustomAudioPlayer audioURL={item?.speechUrl} />
                </Flex>
                {item?.response?.audioUrl && (
                    <>
                        <Text mt={2}><strong>{interview?.candidateName}:</strong> {item?.response?.transcript}</Text>
                        <Flex w={"100%"} justify={"flex-end"}>
                            <CustomAudioPlayer audioURL={item?.response?.audioUrl} />
                        </Flex>
                    </>
                )}
            </Box>
        ));
    };

    const renderApplicant = (applicant) => {
        // Check if applicant and nested objects exist before trying to access their properties
        if (!applicant || !applicant.jobSeeker) {
            console.error("Applicant or applicant.jobSeeker is undefined");
            return null; // Or some fallback UI
        }

        // Now you can safely use properties of applicant.jobSeeker
        const jobTitle = applicant.job.jobTitle || "No Job Title"; // Provide a default value
        const matchScore = applicant.aiJobMatchScan?.matchScore || 0;
        const subTotal = applicant.job?.subTotal || null;
        const matchScoreLogic = applicant.aiJobMatchScan?.matchScoreLogic || "No logic provided";
        return (
            <Box
                mt={4}
                borderRadius="2xl"
                p={3}
                bg={"whiteAlpha.800"}
                shadow={applicant.isFresh ? "dark-lg" : "md"}
                borderWidth={applicant.isFresh ? "2px" : "1px"}
                borderColor={applicant.isFresh ? "#118AB2" : "gray.200"}
            >
                <Flex direction="column" h="full">
                    {applicant.isFresh && (
                        <Flex >
                            <Badge bg={"white"} fontSize='1.1em' borderRadius={"md"} variant='outline' colorScheme='blue'>New Applicant</Badge>
                        </Flex>
                    )}
                    <HStack align={"flex-start"} justify={"space-between"} >
                        <VStack>
                            <Flex align={"flex-start"} >
                                <Text ml={2} fontSize={"2xl"} fontWeight={"bold"}>
                                    {jobTitle}
                                </Text>
                            </Flex>
                            <Flex w={"100%"} justify={"center"} >
                                {applicant.isRevealed && (
                                    <Flex wrap="wrap" mt={2}>
                                        {applicant.tabName && (<Text> Located in the
                                            <Tag color={"white"} shadow={"md"} borderColor={"white"} border={"1px"} bg={"#118AB2"} key={applicant._id} borderRadius="lg" variant="outline" ml={1} >
                                                {applicant.tabName}
                                            </Tag> tab
                                        </Text>
                                        )}
                                    </Flex>
                                )}
                            </Flex>
                        </VStack>
                        <Box>
                            <HStack>
                                <Text fontWeight={"bold"} fontSize={"2xl"} >
                                    Match Score:
                                </Text>
                                <Text color={matchScore > 74 ? "#118AB2" : matchScore < 59 ? "#EF476F" : "FFD166"} fontWeight={"bold"} fontSize={"2xl"}>
                                    <Center>{matchScore}%</Center>
                                </Text>
                            </HStack>
                            {
                                applicant.isRevealed ? (
                                    <FormControl>
                                        <HStack w={"fit-content"}>
                                            <FormLabel>Move to tab</FormLabel>
                                            {isProcessing && <Spinner />}
                                            <Select
                                                w={"fit-content"}
                                                bg={"white"}
                                                placeholder="Select One"
                                                disabled={isProcessing}
                                                onChange={(e) => {
                                                    const newTabName = e.target.value;
                                                    const newTab = tabs.find(tab => tab.name === newTabName);
                                                    if (newTab && newTab._id && applicant) {
                                                        setIsProcessing(true);
                                                        handleMoveApplicantToTab(newTab._id)
                                                            .then(() => {
                                                                setIsProcessing(false);
                                                            })
                                                            .catch((error) => {
                                                                console.error("Error in moving applicant", error);
                                                                setIsProcessing(false);
                                                            });
                                                    }
                                                }}
                                            >
                                                {tabs.map((tab) => (
                                                    <option key={tab._id} value={tab.name}>
                                                        {tab.name}
                                                    </option>
                                                ))}
                                            </Select>
                                        </HStack>
                                    </FormControl>
                                ) : (
                                    <Flex justify={"flex-end"}>
                                        <Box>
                                            <Button
                                                size="sm"
                                                w="100%"
                                                bg="#FFD166"
                                                color="black"
                                                onClick={() => reveal(applicant)}
                                                _hover={{ bg: "gold" }}
                                                isLoading={isLoading}
                                                disabled={isLoading}
                                            >
                                                {isLoading ? (
                                                    <Spinner size="sm" />
                                                ) : (
                                                    <>
                                                        Reveal Applicant
                                                        <Box m={1}>
                                                            {subTotal && (
                                                                <>
                                                                    for $
                                                                    {subTotal}
                                                                </>
                                                            )}
                                                        </Box>
                                                    </>
                                                )}
                                            </Button>
                                        </Box>
                                    </Flex>
                                )}
                            <Flex mt={2} w={"100%"} justify={"flex-end"}>
                                {
                                    applicant.rejectionLetter ? (
                                        <Text fontWeight={"bold"} fontSize={"xl"} color="red.500">Rejected</Text>
                                    ) : (
                                        <Button
                                            size="sm"
                                            color="white"
                                            bg="#EF476F"
                                            _hover={{ bg: "red.500" }}
                                            onClick={() => {
                                                if (applicant) {
                                                    openModal();
                                                } else {
                                                    console.error('No applicant data available.');
                                                }
                                            }}
                                        >
                                            Reject Applicant
                                        </Button>
                                    )
                                }
                                <Modal isOpen={isModalOpen} size={"xl"} onClose={closeModal}>
                                    <ModalOverlay />
                                    <ModalContent>
                                        <ModalHeader>Rejection Letter</ModalHeader>
                                        <ModalCloseButton />
                                        <ModalBody>
                                            <Text color={"red"} fontSize={"sm"}>Required</Text>
                                            <ReactQuill
                                                value={rejectionLetter}
                                                onChange={setRejectionLetter}
                                                placeholder="Write your rejection letter here..."
                                            />
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button colorScheme="blue" mr={3} onClick={() => generateDraftLetter(applicant)}>
                                                AI Draft Letter
                                            </Button>
                                            <Button
                                                colorScheme="blue"
                                                mr={3}
                                                onClick={() => handleReject(applicant)}
                                                disabled={!rejectionLetter.trim()}  // This will disable the button if rejectionLetter is empty or only has whitespace
                                            >
                                                Send
                                            </Button>
                                            <Button variant="ghost" onClick={closeModal}>Cancel</Button>
                                        </ModalFooter>
                                    </ModalContent>
                                </Modal>
                            </Flex>
                        </Box>
                    </HStack>
                    <Box mt={4} mb={4} p={3} bg={"blue.100"} borderRadius={"md"} shadow={"dark-lg"} >
                        <Box>
                            <Text fontWeight={"bold"} fontSize={"md"} >
                                Match Score Logic
                            </Text>
                            <Text fontSize={"sm"}>
                                {matchScoreLogic}
                            </Text>
                        </Box>
                    </Box>
                    <Flex w={"100%"} justify={"flex-end"} >
                        <Flex>
                            <Tooltip label={!applicant.isRevealed ? "Reveal to send a message" : ""} isDisabled={applicant.isRevealed}>
                                <Button
                                    size={"md"}
                                    mb={2}
                                    mr={4}
                                    bg={"#118AB2"}
                                    color={"white"}
                                    _hover={{ bg: "#01BF02" }}
                                    onClick={openMessageModal}
                                    isDisabled={!applicant.isRevealed}
                                >
                                    {unreadCounts[applicant.connectionId] > 0 ? 'Read Messages' : 'Send Message'}
                                </Button>
                            </Tooltip>
                        </Flex>
                        <Flex>
                            <Button
                                size={"md"}
                                mb={2}
                                bg={"#01BF02"}
                                color={"white"}
                                isLoading={isShortlist}
                                isDisabled={applicant.status === 'is in their shortlist'}
                                _hover={{ bg: "#FFD166" }}
                                leftIcon={<FontAwesomeIcon icon={faListOl} />}
                                onClick={() => handleAddToShortlist(applicant)}
                            >
                                Add to Shortlist
                            </Button>

                        </Flex>
                        {applicant.isRevealed && assessmentId && (
                            <Flex>
                                <Tooltip
                                    label={applicant.jobSeekerAssessment.isSent ? "Skill Assessment has been sent" : "Click to send Skill Assessment"}
                                    shouldWrapChildren
                                    hasArrow
                                >
                                    <Button
                                        bg={"black"} color={"white"} ml={4} _hover={{ bg: "#118AB2" }}
                                        onClick={() => handleAssessmentSubmit(applicant._id, applicant)}
                                        isDisabled={isButtonDisabled || applicant.jobSeekerAssessment.isSent}
                                    >
                                        Send Skill Assessment
                                    </Button>
                                </Tooltip>
                            </Flex>
                        )}
                    </Flex>

                    <Tabs mt={4}>
                        <TabList >
                            <Tab borderColor={"white"} border={"1px"} m={1} borderRadius={"lg"} shadow={"md"} color='white' bg={"black"} _selected={{ color: 'white', bg: '#118AB2' }} ><Text>Applicant Information</Text></Tab>
                            {applicant.isRevealed && (
                                <Tab borderColor={"white"} border={"1px"} m={1} borderRadius={"lg"} shadow={"md"} color='white' bg={"black"} _selected={{ color: 'white', bg: '#118AB2' }} ><Text>Resume</Text></Tab>
                            )}
                            <Tab borderColor={"white"} border={"1px"} m={1} borderRadius={"lg"} shadow={"md"} color='white' bg={"black"} _selected={{ color: 'white', bg: '#118AB2' }} ><Text>Comments</Text></Tab>
                            <Tab borderColor={"white"} border={"1px"} m={1} borderRadius={"lg"} shadow={"md"} color='white' bg={"black"} _selected={{ color: 'white', bg: '#118AB2' }} ><Text>Evaluation Form</Text></Tab>
                            {applicant.isPaidPerJobOption && (
                                <Tab borderColor={"white"} border={"1px"} m={1} borderRadius={"lg"} shadow={"md"} color='white' bg={"black"} _selected={{ color: 'white', bg: '#118AB2' }} ><Text mr={2}>Ai Interview</Text>{interview && <FontAwesomeIcon color='#01bf02' icon={faSquareCheck} />}</Tab>
                            )}
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <Box mb={4} >
                                    {applicant.isRevealed && (
                                        <Box bg={"white"} borderRadius={"2xl"} mt={4} mb={4} p={2} shadow="md" borderWidth="1px">
                                            <Box w={"100%"} mb={4} >
                                                <HStack w={"100%"} justify={"space-between"}>
                                                    <HStack w={"100%"}>
                                                        <Text fontWeight={"bold"} fontSize={"xl"} >Name:</Text>
                                                        <Text fontSize={"xl"} >{applicant.jobSeeker.firstName} {applicant.jobSeeker.lastName}</Text>
                                                    </HStack>
                                                    <Flex justify={"flex-end"} w={"100%"}>
                                                        <Box style={{ textDecoration: 'none' }}>
                                                            <Badge
                                                                bg={"#118AB2"} color={"white"}
                                                                borderRadius="full"
                                                                px={2}
                                                                py={1}
                                                                _hover={{
                                                                    bg: "#01BF02",
                                                                    borderRadius: "full",
                                                                    cursor: "pointer"
                                                                }}
                                                                onClick={() => navigate(`/user/${currentApplicant.jobSeeker.jobSeekerId}`)}
                                                            >
                                                                <Center>
                                                                    View Profile
                                                                    <ChevronRightIcon boxSize={6} />
                                                                </Center>
                                                            </Badge>
                                                        </Box>
                                                    </Flex>
                                                </HStack>
                                                <Text>{applicant.jobSeeker.locationCity}, {applicant.jobSeeker.locationState}</Text>
                                            </Box>
                                            <HStack mb={4} justify={"space-evenly"} spacing={4}>
                                                <Box>
                                                    <HStack spacing={2} >
                                                        <Button size="xs" bg={"#01BF02"} _hover={{ bg: "#01BF02" }} color={"white"} onClick={() => {/* code to send SMS */ }}>Send SMS</Button>
                                                        <Text fontWeight={"bold"} fontSize={"lg"} >Phone:</Text>
                                                        <Text>{applicant.jobSeeker.phoneNumber}</Text>
                                                    </HStack>
                                                </Box>
                                                <Box>
                                                    <HStack>
                                                        <Button size="xs" bg={"#118AB2"} _hover={{ bg: "#118AB2" }} color={"white"} onClick={() => {/* code to send Email */ }}>Send Email</Button>
                                                        <Text fontWeight={"bold"} fontSize={"lg"} >Email:</Text>
                                                        <Text>{applicant.jobSeeker.email}</Text>
                                                    </HStack>
                                                </Box>
                                            </HStack>
                                            {
                                                applicant.jobSeeker.socialProfiles && applicant.jobSeeker.socialProfiles.length > 0 && (
                                                    <>
                                                        <Divider mt={2} mb={2} />
                                                        <Text fontWeight={"bold"} fontSize={"lg"}>Social Media: </Text>
                                                        <Grid templateColumns="repeat(4, 1fr)" ml={4} gap={2}>
                                                            {applicant.jobSeeker.socialProfiles.map((profile, index) => (
                                                                profile.platform && profile.url && (
                                                                    <Box key={index}>
                                                                        <HStack>
                                                                            <Text fontWeight={"semibold"}>{profile.platform}:</Text>
                                                                            <Link color={"blue.600"} href={profile.url} isExternal>
                                                                                {profile.url}
                                                                            </Link>
                                                                        </HStack>
                                                                    </Box>
                                                                )
                                                            ))}
                                                        </Grid>
                                                    </>
                                                )
                                            }

                                        </Box>
                                    )}
                                </Box>
                                <MessageModal
                                    ref={messageRef}
                                    isOpen={messageModalDisclosure.isOpen}
                                    onClose={messageModalDisclosure.onClose}
                                    onSend={handleSendMessage}
                                    messages={messages}
                                    user={user}
                                    connectionId={applicant.connectionId}
                                    currentUserId={employerId}
                                    applicantId={applicant._id}
                                />
                                <Box>
                                    {applicant.jobSeekerAssessment.skillAssessmentScore && (
                                        <Box
                                            w={"100%"}
                                            bg={applicant.jobSeekerAssessment.skillAssessmentScore >= 80 ? "green.100" :
                                                (applicant.jobSeekerAssessment.skillAssessmentScore >= 70 ? "orange.100" : "red.100")}
                                            borderRadius={"2xl"}
                                            mt={4} mb={4} p={2}
                                            shadow="md"
                                            borderWidth="1px"
                                        >
                                            <Box w={"100%"} >
                                                <Text mb={2} fontWeight={"bold"} fontSize={"md"}>Skill Assessment Score: {applicant.jobSeekerAssessment.skillAssessmentScore}</Text>
                                                <Text>Takeaways: {applicant.jobSeekerAssessment.skillAssessmentTakeaways}</Text>
                                            </Box>
                                        </Box>
                                    )}
                                    <Box p={2} bg={"white"} borderRadius={"2xl"} maxH={"200px"} overflowY={"scroll"} mb={2}>
                                        <Text fontWeight={"bold"} fontSize={"md"} >
                                            Candidate Summary
                                        </Text>
                                        <Flex ml={4}>
                                            {applicant.jobSeeker.candidateSummary}
                                        </Flex>
                                    </Box>
                                </Box>
                                <Box p={2} bg={"white"} borderRadius={"2xl"} maxH={"200px"} overflowY={"scroll"} mb={2}>
                                    <Text fontWeight={"bold"} fontSize={"lg"}>
                                        Education
                                    </Text>
                                    <Grid templateColumns="repeat(3, 1fr)" ml={4} gap={6}>
                                        {applicant.jobSeeker.education?.map((edu, index) => (
                                            <Box key={index} p={2}>
                                                <Text>{edu.degree} from {edu.institution}</Text>
                                                {edu.fieldOfStudy && (
                                                    <Text fontWeight={"semibold"}>Field of Study: {edu.fieldOfStudy}</Text>
                                                )}
                                            </Box>
                                        ))}
                                    </Grid>
                                </Box>
                                <Box p={2} bg={"white"} borderRadius={"2xl"} maxH={"200px"} overflowY={"scroll"} mb={2}>
                                    <Text fontWeight={"bold"} fontSize={"lg"}>
                                        Work History
                                    </Text>
                                    <Grid templateColumns="repeat(3, 1fr)" ml={4} gap={6}>
                                        {applicant.jobSeeker.workHistory?.map((job, index) => (
                                            <Box key={index} p={2}>
                                                <Text>{job.title}</Text>
                                                <HStack>
                                                    <Text>for {job.company}</Text>
                                                </HStack>
                                                <Text>{job.dates}</Text>
                                            </Box>
                                        ))}
                                    </Grid>
                                </Box>
                                {
                                    applicant.jobSeeker.skills && applicant.jobSeeker.skills.length > 0 && (
                                        <Box p={2} bg={"white"} borderRadius={"2xl"} maxH={"200px"} overflowY={"scroll"} mb={2}>
                                            <Text fontWeight={"bold"} fontSize={"lg"}>
                                                Skills
                                            </Text>
                                            <Grid templateColumns="repeat(4, 1fr)" ml={4} gap={2}>
                                                {applicant.jobSeeker.skills?.map((skill, index) => (
                                                    <Text key={index}>{skill}</Text>
                                                ))}
                                            </Grid>
                                        </Box>
                                    )
                                }
                                {
                                    applicant.jobSeeker.softwareExperience && applicant.jobSeeker.softwareExperience.length > 0 && (
                                        <Box p={2} bg={"white"} borderRadius={"2xl"} maxH={"200px"} overflowY={"scroll"} mb={2}>
                                            <Text fontWeight={"bold"} fontSize={"lg"}>
                                                Software Experience
                                            </Text>
                                            <Grid templateColumns="repeat(4, 1fr)" ml={4} gap={2}>
                                                {applicant.jobSeeker.softwareExperience.map((software, index) => (
                                                    <Text key={index}>{software.softwareName}</Text>
                                                ))}
                                            </Grid>
                                        </Box>
                                    )
                                }
                                {
                                    applicant.jobSeeker.licenses && applicant.jobSeeker.licenses.length > 0 && (
                                        <Box p={2} bg={"white"} borderRadius={"2xl"} maxH={"200px"} overflowY={"scroll"} mb={2}>
                                            <Text fontWeight={"bold"} fontSize={"lg"}>
                                                Licenses
                                            </Text>
                                            <Grid templateColumns="repeat(3, 1fr)" ml={4} gap={6}>
                                                {applicant.jobSeeker.licenses.map((license, index) => (
                                                    <Text key={index}>{license}</Text>
                                                ))}
                                            </Grid>
                                        </Box>
                                    )
                                }
                                {
                                    applicant.jobSeeker.certifications && applicant.jobSeeker.certifications.length > 0 && (
                                        <Box p={2} bg={"white"} borderRadius={"2xl"} maxH={"200px"} overflowY={"scroll"} mb={2}>
                                            <Text fontWeight={"bold"} fontSize={"lg"}>
                                                Certifications
                                            </Text>
                                            <Grid templateColumns="repeat(3, 1fr)" ml={4} gap={2}>
                                                {applicant.jobSeeker.certifications.map((certification, index) => (
                                                    <Box key={index}>
                                                        <Text fontWeight={"semibold"}>{certification.name}</Text>
                                                        <Text fontSize={"sm"}>Issued by: {certification.issuingOrganization}</Text>
                                                        <Text fontSize={"sm"}>
                                                            Date Issued: {certification.dateIssued ? new Date(certification.dateIssued).toLocaleDateString() : 'N/A'}
                                                        </Text>
                                                        <Text fontSize={"sm"}>
                                                            Expiration Date: {certification.expirationDate ? new Date(certification.expirationDate).toLocaleDateString() : 'N/A'}
                                                        </Text>
                                                    </Box>
                                                ))}
                                            </Grid>
                                        </Box>
                                    )
                                }
                                {
                                    applicant.jobSeeker.languages && applicant.jobSeeker.languages.length > 0 && (
                                        <Box w={"fit-content"} p={2} bg={"white"} borderRadius={"2xl"} maxH={"200px"} overflowY={"scroll"} mb={2}>
                                            <Text fontWeight={"bold"} fontSize={"lg"}>
                                                Languages
                                            </Text>
                                            <Grid templateColumns="repeat(3, 1fr)" ml={4} gap={2}>
                                                {applicant.jobSeeker.languages.map((language, index) => (
                                                    <Text key={index}>{language}</Text>
                                                ))}
                                            </Grid>
                                        </Box>
                                    )
                                }
                                {
                                    applicant.jobSeeker.industryExperience && applicant.jobSeeker.industryExperience.length > 0 && (
                                        <Box p={2} bg={"white"} borderRadius={"2xl"} maxH={"200px"} overflowY={"scroll"} mb={2}>
                                            <Text fontWeight={"bold"} fontSize={"lg"}>
                                                Industry Experience
                                            </Text>
                                            <Grid templateColumns="repeat(3, 1fr)" ml={4} gap={2}>
                                                {applicant.jobSeeker.industryExperience.map((experience, index) => (
                                                    <Text key={index}>{experience.industry}</Text>
                                                ))}
                                            </Grid>
                                        </Box>
                                    )
                                }
                                {
                                    applicant.jobSeeker.careerTraining && applicant.jobSeeker.careerTraining.length > 0 && (
                                        <Box p={2} bg={"white"} borderRadius={"2xl"} maxH={"200px"} overflowY={"scroll"} mb={2}>
                                            <Text fontWeight={"bold"} fontSize={"lg"}>
                                                Carrer Training
                                            </Text>
                                            <Grid templateColumns="repeat(3, 1fr)" ml={4} gap={2}>
                                                {applicant.jobSeeker.careerTraining.map((training, index) => (
                                                    <Text key={index}>{training.programName}</Text>
                                                ))}
                                            </Grid>
                                        </Box>
                                    )
                                }
                                {
                                    applicant.jobSeeker.awards && applicant.jobSeeker.awards.length > 0 && (
                                        <Box p={2} bg={"white"} borderRadius={"2xl"} maxH={"200px"} overflowY={"scroll"} mb={2}>
                                            <Text fontWeight={"bold"} fontSize={"lg"}>
                                                Awards
                                            </Text>
                                            <Grid templateColumns="repeat(3, 1fr)" ml={4} gap={2}>
                                                {applicant.jobSeeker.awards.map((award, index) => (
                                                    <Box key={index} p={2}>
                                                        {award.title}
                                                        {award.awardedBy}
                                                    </Box>
                                                ))}
                                            </Grid>
                                        </Box>
                                    )
                                }
                                {
                                    applicant.jobSeeker.hobbies && applicant.jobSeeker.hobbies.length > 0 && (
                                        <Box p={2} bg={"white"} borderRadius={"2xl"} maxH={"200px"} overflowY={"scroll"} mb={2}>
                                            <Text fontWeight={"bold"} fontSize={"lg"}>
                                                Hobbies
                                            </Text>
                                            <Grid templateColumns="repeat(2, 1fr)" ml={4} gap={2}>
                                                {applicant.jobSeeker.hobbies.map((hobby, index) => (
                                                    <Text key={index}>{hobby}</Text>
                                                ))}
                                            </Grid>
                                        </Box>
                                    )
                                }
                            </TabPanel>
                            {applicant.isRevealed && (
                                <TabPanel>
                                    {applicant.jobSeeker.resumePath && (
                                        <Box maxW="100%" h="auto" overflow="hidden" borderWidth="1px" borderRadius="lg" borderColor="gray.200">
                                            <iframe
                                                src={applicant.jobSeeker.resumePath}
                                                title="Resume"
                                                style={{ width: '100%', height: '500px' }} // You can adjust the height as needed
                                            ></iframe>
                                        </Box>
                                    )}
                                </TabPanel>
                            )}
                            <TabPanel>
                                <VStack spacing={4} align="stretch">
                                    <Box>
                                        <Text fontWeight="bold" mb={2}>Add a Comment</Text>
                                        <Textarea
                                            value={note}
                                            bg={"white"}
                                            onChange={handleNoteChange}
                                            placeholder="Write your note here..."
                                        />
                                        <Flex w={"100%"} justify={"flex-end"}>
                                            <Button
                                                mt={2}
                                                bg={"#118AB2"}
                                                color={"white"}
                                                onClick={handleSubmitNote}
                                            >
                                                Submit Note
                                            </Button>
                                        </Flex>
                                    </Box>
                                    <Divider />
                                    <Flex justify={"flex-start"} w={"100%"}>
                                        <VStack align="stretch" spacing={3}>
                                            <Flex w={"100%"} justify={"flex-start"} >
                                                <Text fontSize={"xl"} fontWeight="bold" mb={2}>Comments</Text>
                                            </Flex>
                                            <Box maxH={"400px"} overflowY={"auto"} >
                                                {applicant.comments.map((oldcomment, index) => ( // Use the comments state here
                                                    <Box w={"fit-content"} mb={2} key={index} bg={"whiteAlpha.800"} borderRadius={"lg"} p={3}>
                                                        <Flex direction={"column"} align="start">
                                                            <HStack w={"fit-content"} spacing={2} mb={1}>
                                                                <Box w={"fit-content"}>
                                                                    <Text mr={2} fontWeight="bold">{oldcomment.author}:</Text>
                                                                </Box>
                                                                <Flex maxW={"80%"} justify={"flex-end"}>
                                                                    <Text >{oldcomment.content}</Text>
                                                                </Flex>
                                                            </HStack>
                                                            <Text mb={-2} fontSize="sm" color="gray.500">{new Date(oldcomment.date).toLocaleDateString()}</Text>
                                                        </Flex>
                                                    </Box>
                                                ))}
                                                {comments.map((comment, index) => ( // Use the comments state here
                                                    <Box w={"fit-content"} mb={2} key={index} bg={"whiteAlpha.800"} borderRadius={"lg"} p={3}>
                                                        <Flex direction={"column"} align="start">
                                                            <HStack w={"fit-content"} spacing={2} mb={1}>
                                                                <Box w={"fit-content"}>
                                                                    <Text mr={2} fontWeight="bold">{comment.author}:</Text>
                                                                </Box>
                                                                <Flex maxW={"80%"} justify={"flex-end"}>
                                                                    <Text >{comment.content}</Text>
                                                                </Flex>
                                                            </HStack>
                                                            <Text mb={-2} fontSize="sm" color="gray.500">{new Date(comment.date).toLocaleDateString()}</Text>
                                                        </Flex>
                                                    </Box>
                                                ))}
                                            </Box>
                                        </VStack>
                                    </Flex>
                                </VStack>
                            </TabPanel>
                            <TabPanel w={"100%"}>
                                <Container w={"100%"} maxW="container.lg">
                                    {isEditing && (existingEvaluation || selectedItems.length) ? (
                                        <EvaluationForm
                                            items={selectedItems.length > 0 ? selectedItems : existingEvaluation?.items.map(item => ({ criteria: item.criteria }))}
                                            existingScores={existingEvaluation?.items ?? []}
                                            existingEvaluation={existingEvaluation} // Make sure to pass this prop
                                            onSubmit={handleFormSubmit}
                                            onDelete={() => deleteEvaluation(existingEvaluation._id)} // Pass the function like this if you need the ID
                                        />
                                    ) : (
                                        <CriteriaSelection
                                            evaluationItems={evaluationItems}
                                            onSubmit={handleCriteriaSubmit}
                                        />
                                    )}
                                </Container>
                            </TabPanel>
                            <TabPanel>
                                <Box>
                                    {interview ? (
                                        <><VStack maxH={"500px"} overflowY={"scroll"} spacing={4} mt={4}>
                                            {renderInterviewQuestions(interview)}
                                            <Text>Interview Summary: {interview?.interviewEvaluation}</Text>
                                        </VStack>
                                        </>
                                    ) : (
                                        <Text>No Interview</Text>
                                    )}
                                </Box>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Flex>
                {console.log('Is applicant fresh?', applicant.isFresh)}
            </Box >
        );
    };

    return (
        <Flex direction="column" h="100vh" w="100%" bgImage={Background} bgSize="cover" bgRepeat="no-repeat" bgPosition="center">
            <Flex flex="1" overflowY="auto" pl={"300px"}>
                <Flex w={"100%"} justify={"center"} flex="1" overflowY="auto" >
                    <VStack w={"100%"} align={"center"} >
                        <Text mt={4} fontSize={"4xl"} color={"white"} fontWeight={"black"}>{jobTitle}</Text>
                        <Box w={"85%"} p={4}>
                            <Tabs size='md' variant='enclosed' p={2} borderRadius={"2xl"} bg={"blackAlpha.600"}>
                                <Flex w={"100%"} justify={"space-between"}>
                                    <Button mb={4} bg={"#FFD166"}
                                        color={"black"} onClick={() => navigate(`/job-listings/${businessId}`)}>
                                        Back
                                    </Button>
                                </Flex>
                                <HStack justify={"space-between"} >
                                    <HStack align={"flex-start"}>
                                        <Tab border={"1px"} borderColor={"white"} borderRadius={"full"} shadow={"lg"} color='white' bg={"black"} _selected={{ color: 'white', bg: '#118AB2' }} ><FontAwesomeIcon color='#01BF02' size='sm' icon={faMagnifyingGlass} /><Text ml={2}>Search All</Text></Tab>
                                        {job && !job.isPaidPerJobOption && (
                                            <>
                                                <Tab border={"1px"} borderColor={"white"} borderRadius={"xl"} shadow={"lg"} color='white' bg={"black"} _selected={{ color: 'white', bg: '#118AB2' }}>
                                                    Initial Applicants
                                                </Tab>
                                                <Tab border={"1px"} borderColor={"white"} borderRadius={"xl"} shadow={"lg"} color='white' bg={"black"} _selected={{ color: 'white', bg: '#118AB2' }}>
                                                    Revealed Applicants
                                                </Tab>
                                            </>
                                        )}
                                        <Tab border={"1px"} borderColor={"white"} borderRadius={"xl"} shadow={"lg"} color='white' bg={"black"} _selected={{ color: 'white', bg: '#118AB2' }}>
                                            Shortlist
                                        </Tab>
                                        <Divider h={"40px"} borderColor={"white"} alignSelf={"center"} orientation='vertical' />
                                        {tabs.map((tab, index) => (
                                            <Tab key={index} shadow={"lg"} border={"1px"} borderColor={"white"} borderRadius={"xl"} color='white' bg={"black"} _selected={{ color: 'white', bg: '#118AB2' }} onClick={() => handleTabClick(tab.name)}>
                                                {tab.name}
                                            </Tab>
                                        ))}
                                    </HStack>
                                    <Tab
                                        bg={"white"}
                                        borderRadius={"full"}
                                        shadow={"lg"}
                                        fontSize={"xl"}
                                        color={"white"}
                                        onClick={newTabDisclosure.onOpen}
                                    >
                                        <AddIcon color={"#01BF02"} boxSize={4} />
                                    </Tab>
                                </HStack>
                                <TabPanels>
                                    <TabPanel>
                                        <Flex mt={4} mb={4} w={"100%"} justify={"flex-start"}>
                                            <Flex bg={"white"} p={1} borderRadius={"lg"} w={"30%"}>
                                                <InputGroup>
                                                    <InputLeftElement
                                                        pointerEvents="none"
                                                        children={<Icon as={FaSearch} color="#118AB2" />}
                                                    />
                                                    <Input
                                                        type="text"

                                                        value={searchTerm}
                                                        onChange={handleSearchTermChange}
                                                        placeholder="Search by name"
                                                        variant="flushed"
                                                    />
                                                </InputGroup>
                                            </Flex>
                                        </Flex>
                                        <Divider border={"1px"} borderColor={"black"} mt={2} mb={2} />
                                        <SimpleGrid maxH={"950px"} overflowY={"scroll"} columns={{ base: 1, sm: 1, md: 4 }} spacing={7}>
                                            {filteredApplicants.map(applicant => (
                                                <Box key={applicant.id} shadow="md" bg={applicant.rejectionLetter ? "#EF476F" : "#118AB2"}
                                                    h={"fit-content"} borderWidth="1px" borderRadius="2xl">
                                                    {renderApplicantBrief(applicant, applicant.isRevealed ? handleRevealedApplicantClick : handleApplicantClick)}
                                                </Box>
                                            ))}
                                        </SimpleGrid>
                                    </TabPanel>
                                    {job && !job.isPaidPerJobOption && (
                                        <>
                                            <TabPanel>
                                                <SimpleGrid maxH={"950px"} overflowY={"scroll"} columns={{ base: 1, sm: 1, md: 4 }} spacing={7}>
                                                    {sortedUnrevealedApplicants.map(applicant => (
                                                        <Box key={applicant.id} shadow="md" bg={applicant.rejectionLetter ? "#EF476F" : "#118AB2"}
                                                            h={"fit-content"} borderWidth="1px" borderRadius="2xl">
                                                            {renderApplicantBrief(applicant, handleApplicantClick)}
                                                        </Box>
                                                    ))}
                                                </SimpleGrid>
                                            </TabPanel>
                                        </>
                                    )}
                                    {job && !job.isPaidPerJobOption && (
                                        <>
                                            <TabPanel>
                                                <SimpleGrid maxH={"950px"} overflowY={"scroll"} columns={{ base: 1, sm: 1, md: 4 }} spacing={7}>
                                                    {sortedRevealedApplicants.map(applicant => (
                                                        <Box key={applicant.id} p={1} shadow="md" bg={applicant.rejectionLetter ? "#EF476F" : "#118AB2"}
                                                            h={"fit-content"} borderWidth="1px" borderRadius="2xl">
                                                            {renderApplicantBrief(applicant, handleRevealedApplicantClick)}
                                                        </Box>
                                                    ))}
                                                </SimpleGrid>
                                            </TabPanel>
                                        </>
                                    )}
                                    <TabPanel>
                                        <Flex mb={4} mt={2} w={"100%"} justify={"flex-end"}>
                                            <Button isLoading={comparing} color={"white"} bg={"#118AB2"} _hover={{ bg: "blue" }} onClick={handleCompareApplicants}><FontAwesomeIcon size='lg' icon={faPeopleArrows} /><Text ml={4}>Compare Selected Applicants</Text></Button>
                                        </Flex>
                                        <SimpleGrid maxH={"950px"} overflowY={"scroll"} columns={{ base: 1, sm: 1, md: 4 }} spacing={7}>
                                            {filteredApplicants
                                                .filter(applicant => applicant.status === 'is in their shortlist')
                                                .map(applicant => (
                                                    <Box key={applicant.id} p={1} shadow="md"
                                                        bg={applicant.rejectionLetter ? "#EF476F" : "black"}
                                                        h={"fit-content"} borderWidth="1px" borderRadius="2xl">
                                                        <Checkbox mb={2} ml={2} color={"white"} borderColor={"white"} onChange={() => handleSelectApplicant(applicant._id)}>Select for Comparison</Checkbox>
                                                        {renderApplicantBrief(applicant, applicant.isRevealed ? handleRevealedApplicantClick : handleApplicantClick)}
                                                    </Box>
                                                ))}
                                        </SimpleGrid>
                                        <Box mt={4} w={"100%"}>
                                            <Box p={2} mt={2} bg={"whiteSmoke"} borderRadius={"xl"} w={"100%"}>
                                                <Text mb={2} fontSize={"xl"} fontWeight={"semibold"}>Comparisons</Text>
                                                <Box maxH={"600px"} overflowY={"scroll"} w={"100%"}>
                                                    {comparisons.map((comparison) => (
                                                        <Box mt={4} mb={4} shadow={"lg"} borderRadius={"lg"} p={4} bg={"white"} key={comparison._id}>
                                                            <Heading size={"md"}>{comparison.bestTalent}</Heading>
                                                            <Text>{comparison.analysis}</Text>
                                                            {/* Add more details as needed */}
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </TabPanel>
                                    {Array.isArray(tabs) && tabs.map((tab, index) => (
                                        <TabPanel w={"100%"} key={index}>
                                            <Box w={"100%"}>
                                                <Flex align={"flex-start"} justify={"flex-end"} w={"100%"} >
                                                    <Button size={"sm"} colorScheme="red" onClick={handleDeleteTab}>Delete Tab</Button>
                                                </Flex>
                                                <SimpleGrid maxH={"950px"} overflowY={"scroll"} columns={{ base: 1, sm: 1, md: 4 }} spacing={7}>
                                                    {Array.isArray(tab.applicants) && tab.applicants.map(applicantId => {
                                                        const applicant = Array.isArray(applicants) ? applicants.find(app => app._id === applicantId) : null;
                                                        return applicant && (
                                                            <Box key={applicant._id} p={1} shadow="md" bg={applicant.rejectionLetter ? "#EF476F" : "#118AB2"}
                                                                borderWidth="1px" h={"fit-content"} borderRadius="md">
                                                                {renderApplicantBrief(applicant, handleRevealedApplicantClick)}
                                                            </Box>
                                                        );
                                                    })}
                                                </SimpleGrid>
                                            </Box>
                                        </TabPanel>
                                    ))}
                                </TabPanels>
                            </Tabs>
                            <Modal isOpen={newTabDisclosure.isOpen} onClose={newTabDisclosure.onClose}>
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalHeader>Create a new tab</ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        <FormControl>
                                            <FormLabel>Tab Name</FormLabel>
                                            <Input
                                                placeholder="Tab Name"
                                                value={newTabName}
                                                onChange={(e) => setNewTabName(e.target.value)}  // Update newTabName state when the input value changes
                                            />
                                        </FormControl>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button colorScheme="blue" onClick={handleCreateTab}>  {/* Call handleCreateTab when the button is clicked */}
                                            Create
                                        </Button>
                                        <Button variant="ghost" onClick={newTabDisclosure.onClose}>Cancel</Button>
                                    </ModalFooter>
                                </ModalContent>
                            </Modal>
                        </Box>
                    </VStack>
                </Flex>
                <Drawer
                    isOpen={drawerDisclosure.isOpen}
                    placement="right"
                    onClose={drawerDisclosure.onClose}
                    size="xl"
                >
                    <DrawerOverlay />
                    <DrawerContent bg={"blackAlpha.600"} >
                        <DrawerCloseButton size={"lg"} color={"white"} />
                        <DrawerHeader fontSize={"2xl"} color={"white"} >Applicant Details</DrawerHeader>
                        <DrawerBody>
                            {console.log('Drawer rendering with currentApplicant:', currentApplicant)}
                            {currentApplicant && renderApplicant(currentApplicant)}
                        </DrawerBody>
                        <DrawerFooter>
                            {/* Include any footer content or actions here */}
                        </DrawerFooter>
                    </DrawerContent>
                </Drawer>
            </Flex>
        </Flex >
    );
};

const MessageList = ({ messages, currentUserId }) => {
    console.log('Current userId:', currentUserId);
    return (
        <VStack align="stretch" spacing={4}>
            {messages.map((message, index) => (
                <Flex
                    key={index}
                    justifyContent={message.sender === currentUserId ? 'flex-end' : 'flex-start'}
                >
                    <Box
                        p={2}
                        borderRadius="md"
                        bg={message.sender === currentUserId ? 'blue.500' : 'gray.200'}
                        color={message.sender === currentUserId ? 'white' : 'black'}
                    >
                        {message.content}
                    </Box>
                </Flex>
            ))}
        </VStack>
    );
};

export default Applicants;