import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../components/axiosInstance';
import {
    Box,
    Flex,
    useToast,
    Text,
    VStack,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    useDisclosure,
    List,
    ListItem,
    Heading,
    Icon,
    Grid,
    Badge,
    Avatar,
    Divider,
    Tooltip,
    Wrap,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react';
import { FaFileAlt, FaCheck, FaTimes } from 'react-icons/fa';
import Background from '../../../assets/Background.webp';
import { faMessage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function FreelancerProposals() {
    const toast = useToast();
    const [projectProposals, setProjectProposals] = useState([]);
    const [freelancerId, setFreelancerId] = useState(null);
    const [balance, setBalance] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();


    const fetchProjectProposals = async () => {
        try {
            const response = await axiosInstance.get('/freelancer/proposals', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
                },
            });
            setProjectProposals(response.data.projects);
            setFreelancerId(response.data.freelancerId);

            // Fetch the user's Stripe balance
            const balanceResponse = await axiosInstance.get('/freelancer/stripe-balance', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
                },
            });
            setBalance(balanceResponse.data.balance);

            setLoading(false);
        } catch (error) {
            toast({
                title: 'Error fetching project proposals.',
                description: 'Unable to load project proposals. Please try again later.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        fetchProjectProposals();
    }, [toast]);

    const handleFreelancerApproval = async (proposalId, milestoneId) => {
        try {
            await axiosInstance.put(`/freelancer/proposals/${proposalId}/milestones/${milestoneId}/approve`);
            // Refresh project proposals after approval
            fetchProjectProposals();
            toast({
                title: 'Milestone Approved',
                description: 'You have successfully approved the milestone.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: 'Error approving milestone',
                description: 'An error occurred while approving the milestone. Please try again.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    return (
        <Flex
            direction="column"
            minH="100vh"
            w="100%"
            pl="300px"
            bgImage={Background}
            bgSize="cover"
            bgRepeat="no-repeat"
            bgPosition="center"
        >
            <Flex flex="1" w="100%" align="center" p={8} justify="center">
                <Box w="100%" maxW="1200px" bg="white" borderRadius="md" p={8} boxShadow="lg" m="auto">
                    <Flex align="center" mb={8}>
                        <Icon as={FaFileAlt} boxSize={8} color="green.500" mr={4} />
                        <Text fontSize="4xl" fontWeight="bold">
                            Project Proposals
                        </Text>
                        {balance && (
                            <Box ml="auto">
                                <Text fontSize="lg" fontWeight="bold">
                                    Stripe Balance: ${balance.available[0].amount / 100}
                                </Text>
                            </Box>
                        )}
                    </Flex>
                    {loading ? (
                        <Text>Loading...</Text>
                    ) : projectProposals.length > 0 ? (
                        <Accordion allowToggle>
                            {projectProposals?.map((project) => (
                                <AccordionItem key={project._id}>
                                    <AccordionButton>
                                        <Box flex="1" textAlign="left">
                                            <Heading size="md" mb={2}>
                                                {project.name}
                                            </Heading>
                                            <Text fontSize="sm" color="gray.600">
                                                {project.description}
                                            </Text>
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                    <AccordionPanel pb={4}>
                                        {project?.proposals?.map((proposal) => (
                                            <Box key={proposal._id} mb={6} borderWidth="1px" borderRadius="md" p={4}>
                                                <Flex align="center" mb={4}>
                                                    <Badge
                                                        colorScheme={proposal.status === 'accepted' ? 'green' : 'red'}
                                                        mr={2}
                                                    >
                                                        {proposal.status}
                                                    </Badge>
                                                    <Avatar
                                                        size="sm"
                                                        src={proposal.PFP}
                                                        name={proposal.firstName}
                                                        mr={2}
                                                    />
                                                    <Text fontWeight="bold">{proposal.firstName}</Text>
                                                </Flex>
                                                <Text fontSize="sm" color="gray.600" mb={4}>
                                                    {proposal.introduction}
                                                </Text>
                                                <Box mb={4}>
                                                    <Text fontSize="sm" color="gray.500" mb={2}>
                                                        <strong>Rationale:</strong> {proposal.rationale}
                                                    </Text>
                                                    <Text fontSize="sm" color="gray.500" mb={2}>
                                                        <strong>Total Cost:</strong> ${proposal.totalCost}
                                                    </Text>
                                                    <Text fontSize="sm" color="gray.500" mb={2}>
                                                        <strong>Freelancer Payout:</strong> ${proposal.freelancerPayout}
                                                    </Text>
                                                </Box>
                                                <Box>
                                                    <Text fontSize="sm" color="gray.500" mb={2}>
                                                        <strong>Milestones:</strong>
                                                    </Text>
                                                    <List spacing={2}>
                                                        {proposal.milestones.map((milestone) => (
                                                            <ListItem key={milestone._id}>
                                                                <Box borderWidth="1px" borderRadius="md" p={2}>
                                                                    <Flex justify="space-between" mb={2}>
                                                                        <Text fontSize="sm" fontWeight="bold">
                                                                            {milestone.title}
                                                                        </Text>
                                                                        <Badge colorScheme={milestone.funded === 'funded' ? 'green' : 'red'}>
                                                                            {milestone.funded}
                                                                        </Badge>
                                                                    </Flex>
                                                                    <Text fontSize="sm" color="gray.600" mb={2}>
                                                                        {milestone.description}
                                                                    </Text>
                                                                    <Flex justify="space-between" mb={2}>
                                                                        <Text fontSize="xs" color="gray.500">
                                                                            Start: {new Date(milestone.startDate.$date).toLocaleDateString()}
                                                                        </Text>
                                                                        <Text fontSize="xs" color="gray.500">
                                                                            End: {new Date(milestone.endDate.$date).toLocaleDateString()}
                                                                        </Text>
                                                                    </Flex>
                                                                    <Flex justify="space-between" mb={2}>
                                                                        <Text fontSize="xs" color="gray.500">
                                                                            Cost: ${milestone.cost}
                                                                        </Text>
                                                                        <Text fontSize="xs" color="gray.500">
                                                                            Payout: ${milestone.freelancerPayout}
                                                                        </Text>
                                                                    </Flex>
                                                                    <Flex justify="space-between">
                                                                        <Badge
                                                                            colorScheme={milestone.freelancerApproved ? 'green' : 'red'}
                                                                            size="sm"
                                                                            cursor="pointer"
                                                                            onClick={() => handleFreelancerApproval(proposal._id, milestone._id)}
                                                                        >
                                                                            {milestone.freelancerApproved ? 'Approved' : 'Approve'}
                                                                        </Badge>
                                                                        <Badge colorScheme={milestone.customerApproved ? 'green' : 'red'} size="sm">
                                                                            Customer Approved
                                                                        </Badge>
                                                                    </Flex>
                                                                </Box>
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </Box>
                                            </Box>
                                        ))}
                                    </AccordionPanel>
                                </AccordionItem>
                            ))}
                        </Accordion>
                    ) : (
                        <Text>No project proposals found.</Text>
                    )}
                </Box>
            </Flex>
        </Flex>
    );
}

export default FreelancerProposals;