import React from 'react';
import { Flex, Button, Image, IconButton, useToast } from '@chakra-ui/react';
import { SettingsIcon } from '@chakra-ui/icons'; // Import SettingsIcon
import { useLocation, useNavigate } from 'react-router-dom';
import InfoAlert from './InfoAlert';
import Logo from '../assets/whitelogo.png';
import axiosInstance from './axiosInstance';

const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const toast = useToast();
    const authToken = localStorage.getItem('auth_token');

    const isOnUnauthenticatedPage =
        location.pathname === '/user-signup' ||
        location.pathname === '/user-login' ||
        location.pathname === '/';

    const handleAuthAction = () => {
        if (authToken) {
            // If logged in, handle logout
            handleLogout();
        } else {
            // If not logged in, navigate to login page
            navigate('/user-login');
        }
    };

    const handleLogout = async () => {
        const refreshToken = localStorage.getItem('refresh_token');
        if (refreshToken) {
            try {
                // Notify the server to invalidate the refresh token
                await axiosInstance.post('/logout', { refreshToken });
            } catch (error) {
                // Handle logout error
            }
        }

        // Remove tokens and user information from localStorage
        localStorage.clear();
        console.log('Logged out');
        navigate('/user-login');
    };

    const handleLogoClick = () => {
        navigate('/user-home');
    };

    const navigateToSettings = () => navigate('/settings'); // Function to navigate to settings

    return (
        <Flex w={"100%"} align={"center"} direction="column">
            <InfoAlert />
            <Flex
                bg="black"
                p={4}
                m={4}
                w={"50%"}
                color="white"
                borderRadius={"lg"}
                justifyContent="space-between"
                alignItems="center"
                aria-label="Header"
            >
                <Flex ml={4} alignItems="center" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
                    <Image
                        src={Logo}
                        alt="JobJar.AI Logo"
                        boxSize="150px"
                        height="auto"
                    />
                </Flex>
                {isOnUnauthenticatedPage ? null : (
                    <Flex alignItems="center">
                        <IconButton
                            icon={<SettingsIcon />}
                            size={"sm"}
                            color="white"
                            bg="transparent"
                            _hover={{ bg: "whiteAlpha.500" }}
                            onClick={navigateToSettings}
                            aria-label="Settings"
                            mr={4}
                        />
                        <Button
                            color="black"
                            bg={'white'}
                            size={"sm"}
                            _hover={{ bg: "whiteAlpha.700" }}
                            variant="solid"
                            onClick={handleAuthAction}
                            aria-label={authToken ? "Logout" : "Login"}
                        >
                            {authToken ? "Logout" : "Login"}
                        </Button>
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
};

export default Header;
