// ResetPassword.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Box, Button, useToast, FormControl, FormLabel, Input, VStack, Flex, Text, Link, Icon } from '@chakra-ui/react';
import { FaShieldAlt } from 'react-icons/fa';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import Background from '../../assets/Background.webp';
import Logo from '../../assets/whitelogo.png';

function ResetPassword() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const toast = useToast();
    const navigate = useNavigate();
    const token = localStorage.getItem('auth_token');

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleResetPassword = async () => {
        if (newPassword !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        setLoading(true);
        try {
            const response = await axios.put(process.env.REACT_APP_API_URL + '/api/reset-password/talent-signup', {
                newPassword,
            },
                {
                    headers: { Authorization: `Bearer ${token}` }
                });

            if (response.status === 200) {
                toast({
                    title: 'Password Reset',
                    description: 'Your password has been successfully reset.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });

                navigate('/edit-jobseeker');
            }
        } catch (err) {
            setError('Error resetting password.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Flex
            direction="column"
            justify="center"
            align="center"
            height="100vh"
            width="100%"
            backgroundImage={Background}
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
        >
            <VStack spacing={6} alignItems="center">
                <Box mb={4}>
                    <img src={Logo} alt="JobJar.AILogo" width="200px" />
                </Box>

                <Box
                    p={8}
                    maxW="xl"
                    borderWidth={0}
                    borderRadius="xl"
                    boxShadow="xl"
                    backgroundColor="whiteAlpha.800"
                >
                    <VStack spacing={4} alignItems="center" width="full">
                        <FormControl>
                            <FormLabel>New Password</FormLabel>
                            <Input
                                type={showPassword ? "text" : "password"}
                                bg="white"
                                placeholder="Enter new password"
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </FormControl>
                        <FormControl mt={2}>
                            <FormLabel>Confirm New Password</FormLabel>
                            <Input
                                type={showPassword ? "text" : "password"}
                                bg="white"
                                placeholder="Confirm new password"
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </FormControl>
                        <Flex w={"100%"} align={"center"} justify={"flex-end"}>
                            <Text mr={2} fontSize="sm">{showPassword ? "Hide Password" : "Show Password"}</Text>
                            <Button h="1.75rem" size="sm" onClick={togglePasswordVisibility}>
                                {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                            </Button>
                        </Flex>

                        <Button
                            mt={4}
                            height="50px"
                            onClick={handleResetPassword}
                            bg={"#118AB2"}
                            isLoading={loading}
                            color={"white"}
                            _hover={{ bg: "#01BF02" }}
                            width="full"
                            isDisabled={newPassword !== confirmPassword || newPassword === ''}
                        >
                            Reset Password
                        </Button>

                        {/* Display a message if passwords don't match */}
                        {newPassword !== confirmPassword && newPassword !== '' && (
                            <Text color="red.500" mt={2}>Passwords do not match</Text>
                        )}

                        <Flex justifyContent="center" alignItems="center" my={4}>
                            <Icon as={FaShieldAlt} w={6} h={6} color="green.500" mr={2} />
                            <Text fontSize="sm">Secure Connection</Text>
                        </Flex>
                        <Flex justifyContent="center" alignItems="center" mt={0}>
                            <Text>
                                Remember your password? <Link color="#118AB2" href="/login">Login here</Link>
                            </Text>
                        </Flex>
                    </VStack>
                </Box>
            </VStack >
        </Flex >
    );
}

export default ResetPassword;
