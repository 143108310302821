// CompanyJobs.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link, useNavigate } from 'react-router-dom';
import {
    Box,
    VStack,
    Heading,
    Text,
    Image,
    Spinner,
    useToast,
    Center,
    Badge,
    useTheme,
    Flex,
    Container,
    HStack
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faLinkedinIn, faInstagram, faDiscord, faYoutube, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faGlobe, faHandHoldingDollar, faIndustry, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import Background from '../../assets/Background.webp';


const CompanyJobs = () => {
    const [companyInfo, setCompanyInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [jobs, setJobs] = useState([]);
    const theme = useTheme();
    const toast = useToast();
    const { companyName } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCompanyInfo = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/company-info/${companyName}`);
                setCompanyInfo(response.data.company);
                setJobs(response.data.jobs);
            } catch (error) {
                toast({
                    title: 'Error',
                    description: error.response?.data?.message || "Couldn't fetch company information.",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
            setIsLoading(false);
        };

        fetchCompanyInfo();
    }, [companyName, toast]);

    console.log('Company Info:', companyInfo);
    if (isLoading) {
        return <Spinner />;
    }

    if (isLoading) {
        return (
            <Center height="100vh">
                <Spinner size="xl" label="Loading company information..." />
            </Center>
        );
    }

    const formatAddress = (address) => {
        if (!address) return '';
        const { street, city, state, country, postalCode } = address;
        return `${street}, ${city}, ${state}, ${country}, ${postalCode}`;
    };

    const handleJobClick = (jobId) => {
        navigate(`/job/${jobId}/?referralRole=JobJar`);
    };

    const renderBadge = (badge) => {
        // Ensure badge is an object and has an id before trying to render it
        if (!badge || !badge.id) return null;
        return (
            <Badge mb={2} fontSize={"lg"} colorScheme={badge.colorScheme} variant={badge.variant}>
                {badge.name}
            </Badge>
        );
    };

    const ensureHttpPrefix = (url) => {
        if (!url) return url;
        if (url.startsWith('http://') || url.startsWith('https://')) {
            return url;
        } else {
            return `http://${url}`;
        }
    };

    const getSocialMediaIcon = (name) => {
        const icons = {
            X: faXTwitter,
            Facebook: faFacebookF,
            LinkedIn: faLinkedinIn,
            Instagram: faInstagram,
            Website: faGlobe,
            Discord: faDiscord,
            YouTube: faYoutube,
        };

        const icon = icons[name];
        return icon ? <FontAwesomeIcon icon={icon} /> : null;
    };

    return (
        <Flex direction="column" minH="100vh" w="100%" bgImage={Background} bgSize="cover" bgRepeat="no-repeat" bgPosition="center">
            <Container maxW="container.xl" py={10}>
                <VStack spacing={10} align="stretch">
                    {companyInfo ? (
                        <Flex
                            direction={{ base: 'column', md: 'row' }}
                            p={8}
                            shadow="lg"
                            borderWidth="1px"
                            borderRadius="lg"
                            alignItems="center"
                            justifyContent="space-between"
                            bg="white"
                            w={"fit-content"}
                        >
                            <Flex w={"100%"} direction={{ base: 'column', md: 'row' }} alignItems="center" flex="1">
                                <Image
                                    borderRadius="md"
                                    src={companyInfo.logo || 'fallback-image-url'}
                                    alt={`${companyInfo.companyName} logo`}
                                    boxSize="150px"
                                    objectFit="contain"
                                    mr={8}
                                />
                                <Box>
                                    <Heading mb={4} color={theme.colors.brand.blue} fontSize="3xl">
                                        {companyInfo.companyName}
                                    </Heading>
                                    <Text mb={4} color={theme.colors.brand.darkBlue}>
                                        {companyInfo.description}
                                    </Text>
                                    {/*<Text fontSize="sm">{formatAddress(companyInfo.address)}</Text>*/}
                                    <Text mb={2} fontSize="sm" >Employees: {companyInfo.amountEmployees}</Text>
                                    <Text fontSize="sm">
                                        <a href={ensureHttpPrefix(companyInfo.website)} target="_blank" rel="noopener noreferrer">
                                            {companyInfo.website}
                                        </a>
                                    </Text>
                                    <HStack spacing={4}>
                                        {companyInfo.socialMediaLinks.map((link) => (
                                            <a key={link.name} href={ensureHttpPrefix(link.link)} target="_blank" rel="noopener noreferrer">
                                                <Box as="span">
                                                    {getSocialMediaIcon(link.name)}
                                                </Box>
                                            </a>
                                        ))}
                                    </HStack>
                                </Box>
                            </Flex>

                        </Flex>

                    ) : null}

                    {jobs && jobs.length > 0 ? (
                        <Box maxH={"900px"} overflowY={"scroll"}>
                            {jobs.map(job => (
                                <Box w={"100%"} mb={4} key={job._id} p={6} shadow="md" borderWidth="1px" borderRadius="lg" bg={"whiteAlpha.900"} _hover={{ bg: "white" }} cursor={"pointer"} onClick={() => handleJobClick(job._id)}>
                                    {job.badge && renderBadge(job.badge)}
                                    <HStack justify={"space-between"} w={"100%"}>
                                        <HStack mb={4} align={"center"} justify={"space-between"} w={"fit-content"}>
                                            <Heading fontSize="xl" mb={2} color={theme.colors.brand.darkBlue}>{job.jobTitle}</Heading>
                                        </HStack>
                                        <HStack mb={4} align={"start"} justify={"space-between"} w={"fit-content"}>
                                            <Text mr={4} bg={(job.employmentType)} mb={2}>{job.employmentType}</Text>
                                            <Text mr={4} bg={(job.workLocationType)} mb={2}>{job.workLocationType}</Text>
                                            <Flex align={"flex-start"} ml={2} mr={4}>
                                                <FontAwesomeIcon size='lg' icon={faIndustry} />
                                                <Text ml={2}>{job.industry}</Text>
                                            </Flex>
                                            <Flex align={"flex-start"} ml={2} mr={4}>
                                                <FontAwesomeIcon size='lg' icon={faLocationDot} />
                                                <Text ml={2}>{job.locationCity}, {job.locationState}</Text>
                                            </Flex>
                                            <Flex align={"center"} ml={2}>
                                                <FontAwesomeIcon size='lg' icon={faHandHoldingDollar} />
                                                <Text ml={2}>{job.paymentDetails.qualifier} ${job.paymentDetails.amount} - {job.paymentType}</Text>
                                            </Flex>
                                        </HStack>
                                    </HStack>
                                    <HStack align={"flex-start"} justify={"space-between"} w={"100%"}>
                                        <Text mb={4}>{job.description}</Text>
                                        <Image shadow={"md"} src={job.image} borderRadius={"lg"} maxW={"250px"} />
                                    </HStack>
                                    <Text>Skills:</Text>
                                    {job.skills && job.skills.map(skill => (
                                        <Badge key={skill.name} bg="black" color={"white"} mr={1}>{skill.name}</Badge>
                                    ))}
                                </Box>
                            ))}
                        </Box>
                    ) : <Text>No jobs found.</Text>}
                </VStack>
            </Container>
        </Flex>
    );
};

export default CompanyJobs;