import React, { useState } from 'react';
import {
    Tag,
    TagLabel,
    TagCloseButton,
    Box,
    Input,
    Button,
    Flex,
    FormLabel
} from '@chakra-ui/react';

const RecruiterTagSection = ({ formData, setFormData }) => {
    const [tagInput, setTagInput] = useState("");
    const [selectedTags, setSelectedTags] = useState([]);

    const handleAddTag = () => {
        if (tagInput && !selectedTags.includes(tagInput)) {
            const updatedTags = [...selectedTags, tagInput];
            setSelectedTags(updatedTags);
            setFormData({ ...formData, specializationTags: updatedTags });
            setTagInput("");
        }
    };

    const handleRemoveTag = (tag) => {
        const newSelectedTags = selectedTags.filter((t) => t !== tag);
        setSelectedTags(newSelectedTags);
        setFormData({ ...formData, specializationTags: newSelectedTags });
    };

    return (
        <Box>
            <FormLabel color={"white"} mt={4}>Do you specialize?</FormLabel>
            <Input
                value={tagInput}
                bg={"white"}
                onChange={(e) => setTagInput(e.target.value)}
                placeholder="Type your tag"
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        handleAddTag();
                        e.preventDefault();  // Prevent form submission
                    }
                }}
            />
            <Flex justifyContent="flex-end">
                <Button size={"xs"} bg={"#118AB2"} color={"white"} _hover={{bg: "#01bf02"}} mt={1} onClick={handleAddTag}>Add</Button>
            </Flex>
            {selectedTags.map((tag, index) => (
                <Tag
                    key={index}
                    size={"lg"}
                    variant="solid"
                    m={2}
                    bg={"#01bf02"}
                >
                    <TagLabel>{tag}</TagLabel>
                    <TagCloseButton onClick={() => handleRemoveTag(tag)} />
                </Tag>
            ))}
        </Box>
    );
};

export default RecruiterTagSection;
