import React, { useState, useEffect } from 'react';
import { Alert, AlertIcon, CloseButton, useToast, IconButton, Box, Text } from '@chakra-ui/react';
import { BellIcon } from '@chakra-ui/icons';
import axios from 'axios';
import PropTypes from 'prop-types';


const Notifications = ({ notifications: initialNotifications, onTabClick }) => {
    const [notificationsState, setNotificationsState] = useState(initialNotifications || []);
    const [loading, setLoading] = useState(true);

    const toast = useToast();
    const token = localStorage.getItem('auth_token');

    const fetchNotifications = async () => {
        try {
            setLoading(true);
            const response = await axios.get(process.env.REACT_APP_API_URL + '/api/notifications', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const sortedNotifications = response.data.sort((a, b) => b.isFresh - a.isFresh);
            setNotificationsState(sortedNotifications);
            console.log(sortedNotifications);  // Log the data
        } catch (error) {
            console.error('Error fetching notifications:', error);
        } finally {
            setLoading(false);
        }
    };

    const markAsRead = async (notificationId) => {
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/api/notifications/mark-as-read/${notificationId}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            fetchNotifications();  // Refresh notifications after marking one as read
            toast({
                title: "Marked as Read",
                description: "The notification has been marked as read.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error reading notification:', error);
            toast({
                title: "Error",
                description: "There was an error marking the notification as read.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const deleteNotification = async (notificationId) => {
        console.log('notificationId', notificationId)
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/notifications/${notificationId}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            fetchNotifications();
            toast({
                title: "Notification Deleted",
                description: "The notification has been deleted successfully.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error deleting notification:', error);
            toast({
                title: "Error",
                description: "There was an error deleting the notification.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        fetchNotifications();
    }, [token, onTabClick]);

    return (
        <div>
            {loading ? (
                <div>Loading...</div>
            ) : notificationsState.length === 0 ? (
                <div style={{ textAlign: 'center' }}>
                    <Text color={"white"}>🎉 Hooray! No new alerts or notifications.</Text>
                </div>
            ) : (
                notificationsState.map((notification, index) => {
                    console.log(notification);
                    let message;
                    switch (notification.type) {
                        case 'job-invitation':
                            message = (
                                <span>
                                    Good news! {notification.companyName} wants you to apply for another position.{' '}
                                    <a href={`https://jobjar.ai:3000/job/${notification.jobId}/JobJar`} style={{ textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer">
                                        Apply here.
                                    </a>
                                </span>
                            );
                            break;
                        case 'revealed':
                            message = `${notification.companyName} revealed your information!`;
                            break;
                        case 'interview':
                            message = `${notification.companyName} selected you for an interview for ${notification.title}.`;
                            break;
                        case 'offer':
                            message = `Congratulations! ${notification.companyName} extended a job offer for the position of ${notification.title}.`;
                            break;
                        case 'shortlisted':
                            message = `${notification.companyName} has added you to their shortlist!`;
                            break;
                        case 'job fair invite':
                            message = `You're invited! ${notification.companyName} invited you to a job fair for potential opportunities in ${notification.title}.`;
                            break;
                        case '2nd interview':
                            message = `Great news! ${notification.companyName} invited you for a second interview for the position of ${notification.title}.`;
                            break;
                        case 'onboarding schedule':
                            message = `Welcome aboard! Your onboarding at ${notification.companyName} for the position of ${notification.title} is scheduled on ${notification.date}.`;
                            break;
                        case 'document request':
                            message = `${notification.companyName} has requested additional documents for your application to the position of ${notification.title}. Please check your email for more details.`;
                            break;
                        case 'rejection':
                            message = (
                                <Box maxH={"250px"} overflowY={"scroll"} mr={4} p={2}>
                                    <span dangerouslySetInnerHTML={{ __html: notification.comment }} />
                                </Box>
                            );
                            break;
                        case 'assessment invite':
                            message = (
                                <span>
                                    {notification.companyName} invited you to take a skill assessment for the position of {notification.title}.{' '}
                                    <a href={`https://jobjar.ai:3000/take-assessment/${notification.applicantId}`} style={{ textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer">
                                        Get started.
                                    </a>
                                </span>
                            );
                            break;

                        default:
                            message = 'You have a new notification.';
                    }

                    console.log(notificationsState);  // Log the notifications state
                    return (
                        notification.isFresh ? (
                            <Alert
                                shadow={"lg"}
                                key={index}
                                bg={"white"}
                                color={"black"}
                                mt={4}
                                border={"1px"}
                                borderRadius={"md"}
                                mb={4}
                                status="success"
                                variant='left-accent'
                            >
                                <AlertIcon />
                                {message}
                                <IconButton
                                    isRound={true}
                                    variant='ghost'
                                    color={"black"}
                                    aria-label='Seen'
                                    icon={<BellIcon />}
                                    alignSelf='flex-start'
                                    position='absolute'
                                    right={1}
                                    top={3}
                                    boxSize={"6"}
                                    onClick={() => markAsRead(notification._id)}
                                />
                            </Alert>
                        ) : (
                            <Alert
                                key={index}
                                mt={4}
                                bg={"blackAlpha.500"}
                                border={"1px"}
                                color={"white"}
                                mb={4}
                                borderRadius={"md"}
                                status="success"
                                variant='left-accent'
                            >
                                <AlertIcon />
                                {message}
                                <CloseButton
                                    alignSelf='flex-start'
                                    position='absolute'
                                    right={1}
                                    top={2}
                                    onClick={() => deleteNotification(notification._id)}
                                />
                            </Alert>
                        )
                    );
                })
            )}
        </div>
    );
}

export default Notifications;

Notifications.propTypes = {
    notifications: PropTypes.arrayOf(
        PropTypes.shape({
            userId: PropTypes.string.isRequired,
            jobId: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            companyName: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            timestamp: PropTypes.instanceOf(Date).isRequired,
            isFresh: PropTypes.bool.isRequired,
        })
    ).isRequired,
};