import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Box, Text, Tabs, TabList, TabPanels, Tab, Grid, Textarea, TabPanel, Button, Flex, Badge, Tooltip, HStack, Center, useDisclosure, Divider, VStack, Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, Link, Modal, ModalBody, ModalOverlay, FormControl, FormLabel, ModalContent, ModalHeader, Input, ModalFooter, ModalCloseButton, Select, Tag, useToast, SimpleGrid, Container, InputLeftElement, InputGroup, Icon, UnorderedList, ListItem, Wrap, WrapItem } from '@chakra-ui/react';
import { EmailIcon } from '@chakra-ui/icons';
import axios from 'axios';
import InviteToInterviewModal from '../../components/InviteToInterviewModal';
import MessageModal from '../../components/MessageModal';
import { io } from "socket.io-client";
import _ from 'lodash';
import Background from '../../assets/Background.webp';
import axiosInstance from '../../components/axiosInstance';
import EvaluationForm from '../../components/EvaluationForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faMagnifyingGlass, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FaSearch } from 'react-icons/fa';
import CriteriaSelection from '../../components/CriteriaSelection';

const evaluationItems = [
    { criteria: 'Teamwork' },
    { criteria: 'Problem Solving' },
    { criteria: 'Communication Skills' },
    { criteria: 'Adaptability' },
    { criteria: 'Leadership' },
    { criteria: 'Attention to Detail' },
    { criteria: 'Creativity' },
    { criteria: 'Work Ethic' },
    { criteria: 'Time Management' },
    { criteria: 'Technical Knowledge' },
    { criteria: 'Conflict Resolution' },
    { criteria: 'Customer Service' },
    { criteria: 'Project Management' },
    { criteria: 'Innovation' },
    { criteria: 'Cultural Fit' },
    { criteria: 'Interpersonal Skills' },
    { criteria: 'Analytical Thinking' },
    { criteria: 'Resilience' },
    { criteria: 'Initiative' },
    { criteria: 'Professionalism' },
    { criteria: 'Emotional Intelligence' },
    { criteria: 'Strategic Thinking' },
    { criteria: 'Collaboration' },
    { criteria: 'Learning Agility' },
    { criteria: 'Integrity and Ethics' },
    // Add any industry-specific skills here, if needed
];


const Talent = () => {
    const token = localStorage.getItem('auth_token');
    const [activeJobListings, setActiveJobListings] = useState([]);
    const [applicants, setApplicants] = useState([]);
    const [user, setUser] = useState({});
    const [currentApplicant, setCurrentApplicant] = useState(null);
    const drawerDisclosure = useDisclosure();
    const messageModalDisclosure = useDisclosure();
    const [searchTerm, setSearchTerm] = useState("");
    const [messages, setMessages] = useState([]);
    const [filteredApplicants, setFilteredApplicants] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [showHighMatchOnly, setShowHighMatchOnly] = useState(false);
    const [existingEvaluation, setExistingEvaluation] = useState(null);
    const [selectedJob, setSelectedJob] = useState(null);
    const [note, setNote] = useState('');
    const [comments, setComments] = useState([]);
    const [jobTitles, setJobTitles] = useState([]);
    const [unreadCounts, setUnreadCounts] = useState({});
    const [employerId, setEmployerId] = useState(null);
    const businessId = localStorage.getItem('businessId');
    const toast = useToast();

    const fetchData = useCallback(async () => {
        console.log('fetchData');

        try {
            const response = await axiosInstance.get(`/${businessId}/all-applicants`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            console.log("Fetched Applicants:", response.data.applicants); // Add this line to log applicants
            setApplicants(response.data.applicants || []);
        } catch (error) {
            console.error('Error fetching applicants:', error);
            setApplicants([]);
        }
    }, [token, employerId]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get('/user');
                setUser(response.data);
            } catch (error) {
                console.error('Failed to fetch user:', error);
            }
        };

        fetchData();

        const interval = setInterval(fetchData, 3 * 60 * 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        if (employerId) {
            fetchData();
        }
    }, [employerId, fetchData]);

    const fetchJobs = async () => {
        try {
            const token = localStorage.getItem('auth_token');
            if (!token) {
                console.error('Auth token is not available in local storage.');
                return;
            }
            const activeResponse = await axiosInstance.get('/jobs/active', {
                headers: { Authorization: `Bearer ${token}` },
            });

            const { data: activeData } = activeResponse;
            if (Array.isArray(activeData)) {
                setActiveJobListings(activeData);
            } else {
                console.error('Data is not an array:', activeData);
            }
            console.log("Active jobs fetched:", activeData); // Log the fetched data
        } catch (error) {
            console.error('Failed to fetch jobs:', error);
        }
    };

    useEffect(() => {
        if (employerId) {
            fetchData(); // Assuming fetchData does not update employerId
            fetchJobs(); // Ensure this does not lead to state updates that affect employerId
        }
    }, [employerId]);

    useEffect(() => {
        if (currentApplicant) {
            console.log('Current Applicant updated:', currentApplicant);
        }
    }, [currentApplicant]);

    const socketRef = useRef(null);

    const fetchEmployerId = async () => {
        console.log('Fetching Employer ID');
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/api/employerId', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setEmployerId(response.data.employerId);
        } catch (error) {
            console.error('Error fetching employerId:', error);
        }
    };

    // Fetch employerId when the component mounts
    useEffect(() => {
        fetchEmployerId();
    }, [token]); // Dependency on token

    // Fetch applicants whenever employerId is set or changed
    useEffect(() => {
        if (employerId) {
            fetchData();
        }
    }, [employerId, fetchData]);

    // Update this useEffect block
    useEffect(() => {
        socketRef.current = io(process.env.REACT_APP_API_URL + '');

        // Listener for receiving real-time messages
        socketRef.current.on('receive_message', (message) => {
            setMessages(prevMessages => [...prevMessages, message]);
            // Update unreadCounts state when a new message is received
            setUnreadCounts(prevCounts => {
                const newCounts = { ...prevCounts };
                newCounts[message.recipient] = (newCounts[message.recipient] || 0) + 1;
                return newCounts;
            });
        });

        return () => {
            socketRef.current.disconnect();
        };
    }, []);

    const refreshApplicants = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/applicants`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            // Assuming the response structure is { data: { applicants: [...] } }
            if (response.data && Array.isArray(response.data.applicants)) {
                setApplicants(response.data.applicants);
            } else {
                console.error('Unexpected response structure:', response);
            }
        } catch (error) {
            console.error('Error refreshing applicants:', error);
        }
    };

    const fetchEvaluation = async () => {
        if (!currentApplicant || !currentApplicant._id) return;

        try {
            const response = await axiosInstance.get(`/applicant/${currentApplicant._id}/evaluation`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            if (response.data && response.data.employerId) {
                setExistingEvaluation(response.data);
                setIsEditing(true);
            } else {
                setExistingEvaluation(null);
                setIsEditing(false);
            }
        } catch (error) {
            console.error('Error fetching evaluation:', error);
            setExistingEvaluation(null);
            setIsEditing(false);
        }
    };

    useEffect(() => {
        fetchEvaluation();
    }, [currentApplicant, token]);

    const fetchMessages = async (applicantId) => {
        try {
            const response = await axiosInstance.get(`/messages/${applicantId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            setMessages(response.data.messages);
        } catch (error) {
            console.error('Error fetching messages:', error.response ? error.response.data.error : error.message);
        }
    };

    const messageRef = useRef();

    const handleFormSubmit = async (scores) => {
        const apiUrl = `/applicants/${currentApplicant._id}/evaluations`;

        const scoredItems = scores.filter(item => item.score > 0);

        try {
            const response = await axiosInstance.post(
                apiUrl,
                { items: scoredItems },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            console.log('Success:', response.data);
            toast({ title: 'Evaluation updated successfully.', status: 'success', duration: 5000, isClosable: true });

        } catch (error) {
            console.error('Error submitting evaluation:', error);

        }
    };

    const deleteEvaluation = async () => {
        try {
            const response = await axiosInstance.delete(`/applicants/${currentApplicant._id}/evaluations`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            console.log(response.data); // Or handle this in your UI, e.g., by showing a success message
            // Update state to reflect the deletion
            setExistingEvaluation(null);
            setIsEditing(false);
            // Optionally refresh the list of applicants or evaluations here
        } catch (error) {
            console.error('Error deleting evaluation:', error);
            // Handle error (e.g., show an error message)
        }
    };

    const handleCriteriaSubmit = (selectedCriteria) => {
        console.log('Handle criteria submit:', selectedCriteria); // Debug line to be removed
        setSelectedItems(selectedCriteria);
        setExistingEvaluation(null); // Ensure that the existing evaluation is cleared if creating new
        setIsEditing(true);
    };

    // Frontend: Function to handle sending messages
    const handleSendMessage = async (message) => {
        console.log(currentApplicant);
        if (!currentApplicant || !employerId) {
            console.error('currentApplicant or employer is null or undefined');
            return;
        }
        if (message) {
            try {
                const response = await axios.post(process.env.REACT_APP_API_URL + '/api/messages', {
                    recipientId: currentApplicant.jobSeeker.jobSeekerId,
                    content: message
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                });

                console.log(response);
                console.log(response.data.message);

                // Update the messages state with the new message
                setMessages(prevMessages => [...prevMessages, response.data.message]);

                socketRef.current.emit('send_message', {
                    recipientId: currentApplicant._id,
                    content: message,
                    senderId: employerId
                });

            } catch (error) {
                console.error('Error sending message:', error.response ? error.response.data.error : error.message);
            }
        }
    };

    const handleNoteChange = (e) => {
        setNote(e.target.value);
    };

    useEffect(() => {
        let filtered = applicants;

        // Filter by match score if checkbox is checked
        if (showHighMatchOnly) {
            filtered = filtered.filter(applicant => applicant.aiJobMatchScan?.matchScore > 74);
        }

        // Filter by name if there is a search term
        if (searchTerm) {
            filtered = filtered.filter(applicant =>
                applicant.jobSeeker?.firstName.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        if (searchTerm) {
            filtered = filtered.filter(applicant =>
                applicant.jobSeeker?.lastName.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        // Sort by favorited status and match score
        filtered.sort((a, b) => {
            // Assuming `employerId` is in the correct scope and is the logged-in user's ID
            const aFavorited = a.favorited?.some(fav => fav.userId && fav.userId.$oid === employerId);
            const bFavorited = b.favorited?.some(fav => fav.userId && fav.userId.$oid === employerId);
            if (aFavorited && !bFavorited) return -1;
            if (!aFavorited && bFavorited) return 1;
            return b.aiJobMatchScan?.matchScore - a.aiJobMatchScan?.matchScore;
        });

        setFilteredApplicants(filtered);
    }, [applicants, showHighMatchOnly, searchTerm, employerId]);

    // Handler for the search input field
    const handleSearchTermChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSubmitNote = async () => {
        if (!currentApplicant || !currentApplicant._id) {
            console.error('Current Applicant ID is undefined');
            return;
        }

        try {
            const response = await axiosInstance.put(`/applicants/${currentApplicant._id}/comment`, {
                content: note,
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });

            const newComment = response.data.comment;
            setComments([...comments, newComment]);
            setNote('');
        } catch (error) {
            console.error('Error submitting comment:', error);
            // Handle the error (e.g., show an error message)
        }
    };

    const handleApplicantClick = (applicant) => {
        if (!applicant || !applicant._id) {
            console.error('Applicant or applicant._id is undefined');
            return;
        }
        setCurrentApplicant(applicant);
        console.log('Current Applicant updated:', applicant);
        drawerDisclosure.onOpen();
        markAsSeen(applicant._id);
        fetchData();
        fetchMessages(applicant.jobSeekerId);
        fetchEvaluation(applicant._id);
    };

    const openMessageModal = () => {
        console.log('Button clicked');
        console.log('Is modal open before calling onOpen?', messageModalDisclosure.isOpen);
        messageModalDisclosure.onOpen();
        console.log('Is modal open after calling onOpen?', messageModalDisclosure.isOpen);
        markMessagesAsRead(currentApplicant, employerId);
    };

    const revealedApplicants = Array.isArray(applicants) ? applicants.filter(applicant => applicant.isRevealed) : [];
    const unrevealedApplicants = Array.isArray(applicants) ? applicants.filter(applicant => !applicant.isRevealed) : [];

    const reveal = async (applicant) => {
        setCurrentApplicant(applicant);
        try {
            await revealApplicant(applicant._id);

        } catch (error) {
            console.error('Error revealing applicant or refreshing applicants:', error);
        }
    };

    const revealApplicant = async (applicantId) => {
        try {
            const revealResponse = await axios.put(`${process.env.REACT_APP_API_URL}/api/applicants/reveal/${applicantId}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            fetchData();

            await axios.put(`${process.env.REACT_APP_API_URL}/api/applicants/update-status/${applicantId}`, {
                status: 'has been revealed'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });

            const { applicant } = revealResponse.data;
            console.log('applicant:', applicant)
            if (!applicant) {
                console.error('No applicant found with the given _id:', applicant);
                return;
            }

            setCurrentApplicant(applicant);

            const userId = applicant.jobSeeker ? applicant.jobSeeker.jobSeekerId : undefined;
            const jobId = applicant.job ? applicant.job.jobId : undefined;
            console.log('Job ID:', jobId);
            const title = applicant.job ? applicant.job.jobTitle : undefined;
            const companyName = applicant.company ? applicant.company.companyName : undefined;

            console.log('CREATE NOTIFICATION')
            console.log({ userId, jobId, title, companyName, token });  // Log data before sending request

            // Now create the notification
            await axios.post(process.env.REACT_APP_API_URL + '/api/create-notification', {
                userId,
                jobId: jobId._id,
                title,
                companyName,
                type: 'revealed',
                timestamp: new Date(),
                isFresh: true,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(response => {
                    console.log('Notification created:', response.data);
                })
                .catch(error => {
                    console.error('Error creating notification:', error);
                });

        } catch (error) {
            if (error.response && error.response.data) {
                // Specific error from the backend
                console.error('Error:', error.response.data.message);
            } else {
                // General error (network error, timeout, etc)
                console.error('Error revealing applicant or fetching employer details:', error);
            }
        }
    };

    useEffect(() => {
        const fetchJobTitles = async (applicantId) => {
            if (!applicantId) {
                console.log('Applicant ID is not available');
                return;
            }

            try {
                const response = await axiosInstance.get(`/applicants/${applicantId}/job-titles`);
                setJobTitles(response.data.jobTitles);
            } catch (error) {
                console.error('Error fetching job titles:', error);
            }
        };

        if (currentApplicant && currentApplicant._id) {
            fetchJobTitles(currentApplicant._id);
        }
    }, [currentApplicant]);

    const sendJobInvitationNotification = async (applicantId, applicant) => {
        console.log('applicant', applicant)
        if (!selectedJob) {
            console.error("No job selected");
            return;
        }

        const job = activeJobListings.find(j => j._id === selectedJob);
        if (!job) {
            console.error("Selected job not found in active listings");
            return;
        }

        console.log('Selected Job', selectedJob);

        if (!applicant || !applicant.jobSeeker) {
            console.error("Applicant data is incomplete");
            return;
        }

        try {
            const userId = applicant.jobSeeker.jobSeekerId;
            const notificationData = {
                userId,
                jobId: selectedJob,
                title: applicant.job.jobTitle,
                applicantId,
                companyName: applicant.company.companyName,
                type: 'job-invitation'
            };

            await axiosInstance.post('/create-notification', notificationData, {
                headers: { Authorization: `Bearer ${token}` }
            });

            const msg = {
                to: applicant.jobSeeker.email,
                from: "support@jobjar.ai",
                templateId: 'd-8e163787fc9349e185ea08d4865037a3',
                firstName: applicant.jobSeeker.firstName,
                lastName: applicant.jobSeeker.lastName,
                jobId: selectedJob,
                jobTitle: selectedJob.jobTitle,
                companyName: applicant.company.companyName,
            };

            await axiosInstance.post('/send-job-invite-email', msg, {
                headers: { Authorization: `Bearer ${token}` }
            });

            console.log('Notification sent successfully to', userId);
        } catch (error) {
            console.error('Error sending notification:', error);
        }
    };

    const markAsSeen = async (applicantId) => {
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/api/applicants/markAsSeen/${applicantId}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            refreshApplicants();
        } catch (error) {
            console.error('Error marking applicant as seen:', error.response ? error.response.data : error.message);
        }
    };

    const markMessagesAsRead = async (currentApplicant, employerId) => {
        try {
            // Check if currentApplicant is defined before trying to access its connectionId property
            if (currentApplicant) {
                const connectionId = currentApplicant.connectionId; // Assuming the connection ID is stored in connectionId property of currentApplicant

                // Make the API request to mark the messages as read
                await axiosInstance.put(`/messages/mark-as-read`, { connectionId }, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                // Update the unreadCounts state immediately without relying on the previous state
                const updatedUnreadCounts = {
                    ...unreadCounts,
                    [connectionId]: 0,
                };
                setUnreadCounts(updatedUnreadCounts);
            } else {
                console.warn('currentApplicant is undefined');
            }
        } catch (error) {
            console.error('Error marking messages as read:', error);
        }
    };

    const handleRevealedApplicantClick = (applicant) => {
        if (applicant && applicant._id) {
            setCurrentApplicant(applicant);
            console.log('Revealed Applicant set:', applicant);
            drawerDisclosure.onOpen();
            fetchMessages(applicant.jobSeeker.jobSeekerId);
            fetchEvaluation(applicant._id);
        } else {
            console.error('Invalid applicant data');
        }
    };

    useEffect(() => {
        const fetchUnreadCounts = async () => {
            if (!Array.isArray(applicants)) {
                console.error('applicants is not an array:', applicants);
                return;  // Exit the function early if applicants is not an array
            }

            try {
                const connectionIds = applicants.map(applicant => applicant.connectionId);
                const response = await axiosInstance.post(`/messages/unreadCounts`, { connectionIds }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const unreadCounts = response.data.unreadCounts;
                setUnreadCounts(unreadCounts);
            } catch (error) {
                console.error('Error fetching unread counts:', error);
            }
        };

        fetchUnreadCounts();
    }, [applicants, token]);

    const renderApplicantBrief = (applicant, handleClick) => {
        const matchScore = applicant?.aiJobMatchScan?.matchScore || 0;
        const jobTitle = applicant?.job.jobTitle || 'Unknown Job Title';
        console.log(applicant?._id);

        return (
            <Box
                borderRadius="xl"
                p={4}
                w="100%"
                bg="white"
                _hover={{ bg: "gray.50" }}
                shadow="lg"
                transition="all 0.2s"
                onClick={() => handleClick(applicant)}
                cursor="pointer"
            >
                <Flex align="center" justify="space-between" mb={2}>
                    <Flex align="center">
                        <Text fontSize="xl" fontWeight="bold" mr={2}>
                            {applicant.jobSeeker.firstName} {applicant.jobSeeker.lastName}
                        </Text>
                        {applicant.isFresh && (
                            <Badge
                                fontSize="sm"
                                borderRadius="full"
                                colorScheme="blue"
                                variant="solid"
                            >
                                New Applicant
                            </Badge>
                        )}
                    </Flex>
                    {applicant.isRevealed && (
                        <Flex align="center">
                            <EmailIcon mr={1} />
                            <Text
                                color={unreadCounts[applicant.connectionId] > 0 ? 'green.500' : 'gray.600'}
                                fontSize="sm"
                                fontWeight="medium"
                            >
                                {unreadCounts[applicant.connectionId] || 0}
                            </Text>
                        </Flex>
                    )}
                </Flex>
                <Flex align="center" justify="space-between">
                    <Text fontSize="md" color="gray.600">
                        {jobTitle.length > 25 ? `${jobTitle.substring(0, 20)}...` : jobTitle}
                    </Text>
                    <Badge
                        fontSize="sm"
                        borderRadius="full"
                        colorScheme={matchScore > 74 ? "blue" : matchScore < 59 ? "red" : "yellow"}
                        variant="solid"
                    >
                        Match Score: {matchScore}%
                    </Badge>
                </Flex>
            </Box>
        );
    };

    const sortedUnrevealedApplicants = useMemo(() => {
        // Assuming matchScore is a numeric value
        return [...unrevealedApplicants].sort((a, b) => b.aiJobMatchScan.matchScore - a.aiJobMatchScan.matchScore);
    }, [unrevealedApplicants]);

    const sortedRevealedApplicants = useMemo(() => {
        // Assuming matchScore is a numeric value
        return [...revealedApplicants].sort((a, b) => b.aiJobMatchScan.matchScore - a.aiJobMatchScan.matchScore);
    }, [revealedApplicants]);

    const renderApplicant = (applicant) => {
        const matchScore = applicant?.aiJobMatchScan?.matchScore || 0;
        const matchScoreLogic = applicant.aiJobMatchScan.matchScoreLogic || 'No logic provided';

        return (
            <Box
                maxH="100%"
                overflowY="auto"
                borderRadius="xl"
                p={4}
                bg="white"
                shadow="lg"
                borderWidth={applicant.isFresh ? "2px" : "1px"}
                borderColor={applicant.isFresh ? "blue.500" : "gray.200"}
            >
                <Flex direction="column" h="full">
                    {applicant.isFresh && (
                        <Flex mb={4}>
                            <Badge colorScheme="blue" fontSize="sm" borderRadius="md">
                                New Applicant
                            </Badge>
                        </Flex>
                    )}
                    <Flex align="flex-start" justify="space-between">
                        <Box>
                            <Text fontSize="2xl" fontWeight="bold" mb={2}>
                                {applicant.job.jobTitle}
                                {applicant.rejectionLetter && (
                                    <Text as="span" color="red.500"> (rejected)</Text>
                                )}
                            </Text>
                            {applicant.isRevealed && (
                                <Flex wrap="wrap" mt={2}>
                                    {applicant.tabName && (
                                        <Text>
                                            Located in the
                                            <Tag
                                                colorScheme="blue"
                                                borderRadius="lg"
                                                ml={1}
                                                key={applicant._id}
                                            >
                                                {applicant.tabName}
                                            </Tag>
                                            tab
                                        </Text>
                                    )}
                                </Flex>
                            )}
                            {jobTitles.length > 1 && (
                                <Box mt={4}>
                                    <Text fontWeight="bold" mb={2}>Jobs Applied for:</Text>
                                    <UnorderedList>
                                        {jobTitles.map((title, index) => (
                                            <ListItem key={index}>{title}</ListItem>
                                        ))}
                                    </UnorderedList>
                                </Box>
                            )}
                        </Box>
                        <Box>
                            <Flex align="center" mb={4}>
                                <Text fontWeight="bold" fontSize="xl" mr={2}>
                                    Match Score:
                                </Text>
                                <Badge
                                    colorScheme={matchScore > 74 ? "blue" : matchScore < 59 ? "red" : "yellow"}
                                    fontSize="xl"
                                >
                                    {matchScore}%
                                </Badge>
                            </Flex>
                            <Box bg="gray.100" borderRadius="xl" p={4} mb={4}>
                                <Select
                                    placeholder="Invite to a job"
                                    onChange={(e) => setSelectedJob(e.target.value)}
                                    value={selectedJob}
                                    mb={2}
                                >
                                    {activeJobListings.map((job) => (
                                        <option key={job._id} value={job._id}>{job.jobTitle}</option>
                                    ))}
                                </Select>
                                <Button
                                    colorScheme="blue"
                                    onClick={() => sendJobInvitationNotification(currentApplicant._id, currentApplicant)}
                                >
                                    Send Job Invitation
                                </Button>
                            </Box>
                            {!applicant.isRevealed && (
                                <Button
                                    colorScheme="yellow"
                                    size="lg"
                                    onClick={() => reveal(applicant)}
                                    mb={4}
                                >
                                    Reveal Applicant for ${applicant.job.subTotal}
                                </Button>
                            )}
                        </Box>
                    </Flex>
                    <Box bg="blue.50" borderRadius="md" p={4} mb={4}>
                        <Text fontWeight="bold" mb={2}>Match Score Logic</Text>
                        <Text>{matchScoreLogic}</Text>
                    </Box>
                    <Tooltip
                        label={!applicant.isRevealed ? "Reveal to send a message" : ""}
                        isDisabled={applicant.isRevealed}
                    >
                        <Button
                            colorScheme="blue"
                            mb={4}
                            onClick={openMessageModal}
                            isDisabled={!applicant.isRevealed}
                        >
                            {unreadCounts[applicant.connectionId] > 0 ? 'Read Messages' : 'Send Message'}
                        </Button>
                    </Tooltip>
                    <Tabs>
                        <TabList>
                            <Tab>Applicant Information</Tab>
                            {applicant.isRevealed && <Tab>Resume</Tab>}
                            <Tab>Comments</Tab>
                            <Tab>Evaluation Form</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                {applicant.isRevealed && (
                                    <Box bg="white" borderRadius="xl" p={4} mb={4} shadow="md">
                                        <Flex align="center" mb={4}>
                                            <Text fontWeight="bold" fontSize="xl" mr={2}>Name:</Text>
                                            <Text fontSize="xl">{applicant.jobSeeker.firstName} {applicant.jobSeeker.lastName}</Text>
                                        </Flex>
                                        <Text mb={4}>{applicant.jobSeeker.locationCity}, {applicant.jobSeeker.locationState}</Text>
                                        <Flex justify="space-between" mb={4}>
                                            <Box>
                                                <Flex align="center">
                                                    <Button
                                                        colorScheme="green"
                                                        size="sm"
                                                        mr={2}
                                                        onClick={() => {/* code to send SMS */ }}
                                                    >
                                                        Send SMS
                                                    </Button>
                                                    <Text fontWeight="bold" mr={1}>Phone:</Text>
                                                    <Text>{applicant.jobSeeker.phone}</Text>
                                                </Flex>
                                            </Box>
                                            <Box>
                                                <Flex align="center">
                                                    <Button
                                                        colorScheme="blue"
                                                        size="sm"
                                                        mr={2}
                                                        onClick={() => {/* code to send Email */ }}
                                                    >
                                                        Send Email
                                                    </Button>
                                                    <Text fontWeight="bold" mr={1}>Email:</Text>
                                                    <Text>{applicant.jobSeeker.email}</Text>
                                                </Flex>
                                            </Box>
                                        </Flex>
                                        {applicant.jobSeeker.socialProfiles && applicant.jobSeeker.socialProfiles.length > 0 && (
                                            <>
                                                <Divider mb={4} />
                                                <Text fontWeight="bold" fontSize="lg" mb={2}>Social Media:</Text>
                                                <SimpleGrid columns={2} spacing={4}>
                                                    {applicant.jobSeeker.socialProfiles.map((profile, index) => (
                                                        profile.platform && profile.url && (
                                                            <Flex key={index} align="center">
                                                                <Text fontWeight="semibold" mr={2}>{profile.platform}:</Text>
                                                                <Link href={profile.url} isExternal color="blue.500">
                                                                    {profile.url}
                                                                </Link>
                                                            </Flex>
                                                        )
                                                    ))}
                                                </SimpleGrid>
                                            </>
                                        )}
                                    </Box>
                                )}
                                {applicant.jobSeekerAssessment.skillAssessmentScore && (
                                    <Box
                                        bg={applicant.jobSeekerAssessment.skillAssessmentScore >= 80 ? "green.100" :
                                            (applicant.jobSeekerAssessment.skillAssessmentScore >= 70 ? "yellow.100" : "red.100")}
                                        borderRadius="xl"
                                        p={4}
                                        mb={4}
                                        shadow="md"
                                    >
                                        <Text fontWeight="bold" mb={2}>Skill Assessment Score: {applicant.jobSeekerAssessment.skillAssessmentScore}</Text>
                                        <Text>Takeaways: {applicant.jobSeekerAssessment.skillAssessmentTakeaways}</Text>
                                    </Box>
                                )}
                                <Box bg="white" borderRadius="xl" p={4} mb={4} shadow="md">
                                    <Text fontWeight="bold" fontSize="lg" mb={2}>Candidate Summary</Text>
                                    <Text>{applicant.jobSeeker.candidateSummary}</Text>
                                </Box>
                                <Box bg="white" borderRadius="xl" p={4} mb={4} shadow="md">
                                    <Text fontWeight="bold" fontSize="lg" mb={2}>Education</Text>
                                    <SimpleGrid columns={2} spacing={4}>
                                        {applicant.jobSeeker.education?.map((edu, index) => (
                                            <Box key={index}>
                                                <Text>{edu.degree} from {edu.institution}</Text>
                                                {edu.fieldOfStudy && (
                                                    <Text fontWeight="semibold">Field of Study: {edu.fieldOfStudy}</Text>
                                                )}
                                            </Box>
                                        ))}
                                    </SimpleGrid>
                                </Box>
                                <Box bg="white" borderRadius="xl" p={4} mb={4} shadow="md">
                                    <Text fontWeight="bold" fontSize="lg" mb={2}>Work History</Text>
                                    <SimpleGrid columns={2} spacing={4}>
                                        {applicant.jobSeeker.workHistory?.map((job, index) => (
                                            <Box key={index}>
                                                <Text fontWeight="bold">{job.title}</Text>
                                                <Text>for {job.company}</Text>
                                                <Text>{job.dates}</Text>
                                            </Box>
                                        ))}
                                    </SimpleGrid>
                                </Box>
                                {applicant.jobSeeker.skills && applicant.jobSeeker.skills.length > 0 && (
                                    <Box bg="white" borderRadius="xl" p={4} mb={4} shadow="md">
                                        <Text fontWeight="bold" fontSize="lg" mb={2}>Skills</Text>
                                        <Wrap spacing={2}>
                                            {applicant.jobSeeker.skills?.map((skill, index) => (
                                                <WrapItem key={index}>
                                                    <Badge>{skill}</Badge>
                                                </WrapItem>
                                            ))}
                                        </Wrap>
                                    </Box>
                                )}
                                {applicant.jobSeeker.softwareExperience && applicant.jobSeeker.softwareExperience.length > 0 && (
                                    <Box bg="white" borderRadius="xl" p={4} mb={4} shadow="md">
                                        <Text fontWeight="bold" fontSize="lg" mb={2}>Software Experience</Text>
                                        <Wrap spacing={2}>
                                            {applicant.jobSeeker.softwareExperience.map((software, index) => (
                                                <WrapItem key={index}>
                                                    <Badge>{software.softwareName}</Badge>
                                                </WrapItem>
                                            ))}
                                        </Wrap>
                                    </Box>
                                )}
                                {applicant.jobSeeker.licenses && applicant.jobSeeker.licenses.length > 0 && (
                                    <Box bg="white" borderRadius="xl" p={4} mb={4} shadow="md">
                                        <Text fontWeight="bold" fontSize="lg" mb={2}>Licenses</Text>
                                        <UnorderedList>
                                            {applicant.jobSeeker.licenses.map((license, index) => (
                                                <ListItem key={index}>{license}</ListItem>
                                            ))}
                                        </UnorderedList>
                                    </Box>
                                )}
                                {applicant.jobSeeker.certifications && applicant.jobSeeker.certifications.length > 0 && (
                                    <Box bg="white" borderRadius="xl" p={4} mb={4} shadow="md">
                                        <Text fontWeight="bold" fontSize="lg" mb={2}>Certifications</Text>
                                        <UnorderedList>
                                            {applicant.jobSeeker.certifications.map((certification, index) => (
                                                <ListItem key={index}>{certification}</ListItem>
                                            ))}
                                        </UnorderedList>
                                    </Box>
                                )}
                                {applicant.jobSeeker.languages && applicant.jobSeeker.languages.length > 0 && (
                                    <Box bg="white" borderRadius="xl" p={4} mb={4} shadow="md">
                                        <Text fontWeight="bold" fontSize="lg" mb={2}>Languages</Text>
                                        <UnorderedList>
                                            {applicant.jobSeeker.languages.map((language, index) => (
                                                <ListItem key={index}>{language}</ListItem>
                                            ))}
                                        </UnorderedList>
                                    </Box>
                                )}
                                {applicant.jobSeeker.industryExperience && applicant.jobSeeker.industryExperience.length > 0 && (
                                    <Box bg="white" borderRadius="xl" p={4} mb={4} shadow="md">
                                        <Text fontWeight="bold" fontSize="lg" mb={2}>Industry Experience</Text>
                                        <UnorderedList>
                                            {applicant.jobSeeker.industryExperience.map((experience, index) => (
                                                <ListItem key={index}>{experience.industry}</ListItem>
                                            ))}
                                        </UnorderedList>
                                    </Box>
                                )}
                                {applicant.jobSeeker.careerTraining && applicant.jobSeeker.careerTraining.length > 0 && (
                                    <Box bg="white" borderRadius="xl" p={4} mb={4} shadow="md">
                                        <Text fontWeight="bold" fontSize="lg" mb={2}>Career Training</Text>
                                        <UnorderedList>
                                            {applicant.jobSeeker.careerTraining.map((training, index) => (
                                                <ListItem key={index}>{training.programName}</ListItem>
                                            ))}
                                        </UnorderedList>
                                    </Box>
                                )}
                                {applicant.jobSeeker.awards && applicant.jobSeeker.awards.length > 0 && (
                                    <Box bg="white" borderRadius="xl" p={4} mb={4} shadow="md">
                                        <Text fontWeight="bold" fontSize="lg" mb={2}>Awards</Text>
                                        <SimpleGrid columns={2} spacing={4}>
                                            {applicant.jobSeeker.awards.map((award, index) => (
                                                <Box key={index}>
                                                    <Text fontWeight="bold">{award.title}</Text>
                                                    <Text>{award.awardedBy}</Text>
                                                </Box>
                                            ))}
                                        </SimpleGrid>
                                    </Box>
                                )}
                                {applicant.jobSeeker.hobbies && applicant.jobSeeker.hobbies.length > 0 && (
                                    <Box bg="white" borderRadius="xl" p={4} mb={4} shadow="md">
                                        <Text fontWeight="bold" fontSize="lg" mb={2}>Hobbies</Text>
                                        <UnorderedList>
                                            {applicant.jobSeeker.hobbies.map((hobby, index) => (
                                                <ListItem key={index}>{hobby}</ListItem>
                                            ))}
                                        </UnorderedList>
                                    </Box>
                                )}
                                <MessageModal
                                    ref={messageRef}
                                    isOpen={messageModalDisclosure.isOpen}
                                    onClose={messageModalDisclosure.onClose}
                                    onSend={handleSendMessage}
                                    messages={messages}
                                    user={user}
                                    connectionId={applicant.connectionId}
                                    currentUserId={employerId}
                                    applicantId={applicant._id}
                                />
                            </TabPanel>
                            {applicant.isRevealed && (
                                <TabPanel>
                                    {applicant.jobSeeker.resumePath && (
                                        <Box maxW="100%" h="auto" overflow="hidden" borderWidth="1px" borderRadius="lg" borderColor="gray.200">
                                            <iframe
                                                src={applicant.jobSeeker.resumePath}
                                                title="Resume"
                                                style={{ width: '100%', height: '600px' }}
                                            ></iframe>
                                        </Box>
                                    )}
                                </TabPanel>
                            )}
                            <TabPanel>
                                <Box mb={4}>
                                    <Text fontWeight="bold" mb={2}>Add a Note</Text>
                                    <Textarea
                                        value={note}
                                        onChange={handleNoteChange}
                                        placeholder="Write your note here..."
                                        mb={2} />
                                    <Button
                                        colorScheme="blue"
                                        onClick={handleSubmitNote}
                                    >
                                        Submit Note
                                    </Button>
                                </Box>
                                <Divider my={4} />
                                <Box>
                                    <Text fontWeight="bold" fontSize="xl" mb={4}>Comments</Text>
                                    <VStack spacing={4} align="stretch">
                                        {applicant.comments.map((oldComment, index) => (
                                            <Box key={index} bg="gray.50" borderRadius="lg" p={4}>
                                                <Flex justify="space-between" align="center" mb={2}>
                                                    <Text fontWeight="bold">{oldComment.author}:</Text>
                                                    <Text fontSize="sm" color="gray.500">
                                                        {new Date(oldComment.date).toLocaleDateString()}
                                                    </Text>
                                                </Flex>
                                                <Text>{oldComment.content}</Text>
                                            </Box>
                                        ))}
                                        {comments.map((comment, index) => (
                                            <Box key={index} bg="gray.50" borderRadius="lg" p={4}>
                                                <Flex justify="space-between" align="center" mb={2}>
                                                    <Text fontWeight="bold">{comment.author}:</Text>
                                                    <Text fontSize="sm" color="gray.500">
                                                        {new Date(comment.date).toLocaleDateString()}
                                                    </Text>
                                                </Flex>
                                                <Text>{comment.content}</Text>
                                            </Box>
                                        ))}
                                    </VStack>
                                </Box>
                            </TabPanel>
                            <TabPanel>
                                <Container maxW="container.lg">
                                    {isEditing && (existingEvaluation || selectedItems.length) ? (
                                        <EvaluationForm
                                            items={selectedItems.length > 0 ? selectedItems : existingEvaluation?.items.map(item => ({ criteria: item.criteria }))}
                                            existingScores={existingEvaluation?.items ?? []}
                                            existingEvaluation={existingEvaluation}
                                            onSubmit={handleFormSubmit}
                                            onDelete={() => deleteEvaluation(existingEvaluation._id)}
                                        />
                                    ) : (
                                        <CriteriaSelection
                                            evaluationItems={evaluationItems}
                                            onSubmit={handleCriteriaSubmit}
                                        />
                                    )}
                                </Container>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Flex>
            </Box>
        );
    };

    return (
        <Flex direction="column" h="100vh" w="100%" bgImage={Background} bgSize="cover" bgRepeat="no-repeat" bgPosition="center">
            <Flex flex="1" overflowY="auto" pl={"300px"}>
                <Flex w={"100%"} justify={"center"} flex="1" overflowY="auto" >
                    <VStack w={"100%"} align={"center"} >
                        <Text mt={4} fontSize={"4xl"} color={"white"} fontWeight={"black"}>Talent Pool</Text>
                        <Box w={"80%"} p={4}>
                            <Tabs variant='enclosed-colored' p={1} borderRadius={"2xl"} bg={"blackAlpha.600"}>
                                <HStack justify={"space-between"} >
                                    <HStack>
                                        <Tab border={"1px"} borderColor={"white"} borderRadius={"full"} shadow={"lg"} color='white' bg={"black"} _selected={{ color: 'white', bg: '#118AB2' }} ><FontAwesomeIcon color='#01BF02' size='sm' icon={faMagnifyingGlass} /><Text ml={2}>Search</Text></Tab>
                                        <Tab m={1} border={"1px"} borderColor={"white"} borderRadius={"lg"} shadow={"md"} color='white' bg={"black"} _selected={{ color: 'white', bg: '#118AB2' }} >All Applicants</Tab>
                                        <Tab m={1} border={"1px"} borderColor={"white"} borderRadius={"lg"} shadow={"md"} color='white' bg={"black"} _selected={{ color: 'white', bg: '#118AB2' }} >All Revealed Applicants</Tab>
                                    </HStack>
                                </HStack>
                                <TabPanels>
                                    <TabPanel>
                                        <Flex mb={4} w={"100%"} justify={"flex-start"}>
                                            <Flex bg={"white"} p={1} borderRadius={"lg"} w={"30%"}>
                                                <InputGroup>
                                                    <InputLeftElement
                                                        pointerEvents="none"
                                                        children={<Icon as={FaSearch} color="#118AB2" />}
                                                    />
                                                    <Input
                                                        type="text"

                                                        value={searchTerm}
                                                        onChange={handleSearchTermChange}
                                                        placeholder="Search by name"
                                                        variant="flushed"
                                                    />
                                                </InputGroup>
                                            </Flex>
                                        </Flex>
                                        <SimpleGrid maxH={"950px"} overflowY={"scroll"} columns={{ base: 1, sm: 2, md: 4 }} spacing={7}>
                                            {filteredApplicants.map(applicant => (
                                                <Box key={applicant.id} shadow="md" bg={applicant.rejectionLetter ? "#EF476F" : "#118AB2"}
                                                    h={"fit-content"} borderWidth="1px" borderRadius="2xl">
                                                    {renderApplicantBrief(applicant, applicant.isRevealed ? handleRevealedApplicantClick : handleApplicantClick)}
                                                </Box>
                                            ))}
                                        </SimpleGrid>
                                    </TabPanel>
                                    <TabPanel maxH={"1000px"} overflowY={"scroll"}>
                                        {sortedUnrevealedApplicants.length > 0 ? (
                                            <SimpleGrid maxH={"950px"} overflowY={"scroll"} columns={{ base: 1, sm: 2, md: 4 }} spacing={7}>
                                                {sortedUnrevealedApplicants.map(applicant => (
                                                    <Box key={applicant.id} shadow="md" bg={"#118AB2"} h={"fit-content"} borderWidth="1px" borderRadius="2xl">
                                                        {renderApplicantBrief(applicant, handleApplicantClick)}
                                                    </Box>
                                                ))}
                                            </SimpleGrid>
                                        ) : (
                                            <Text>No Unrevealed Applicants</Text>
                                        )}
                                    </TabPanel>
                                    <TabPanel maxH={"1000px"} overflowY={"scroll"}>
                                        <SimpleGrid maxH={"950px"} overflowY={"scroll"} columns={{ base: 1, sm: 2, md: 4 }} spacing={7}>
                                            {sortedRevealedApplicants.map(applicant => (
                                                <Box key={applicant.id} shadow="md" bg={"#118AB2"} h={"fit-content"} borderWidth="1px" borderRadius="2xl">
                                                    {renderApplicantBrief(applicant, handleRevealedApplicantClick)}
                                                </Box>
                                            ))}
                                        </SimpleGrid>
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </Box>
                    </VStack>
                </Flex>
                <Drawer
                    isOpen={drawerDisclosure.isOpen}
                    placement="right"
                    onClose={drawerDisclosure.onClose}
                    size="xl"
                >
                    <DrawerOverlay />
                    <DrawerContent bg={"blackAlpha.600"} >
                        <DrawerCloseButton size={"lg"} color={"white"} />
                        <DrawerHeader fontSize={"2xl"} color={"white"} >Applicant Details</DrawerHeader>
                        <DrawerBody>
                            {console.log('Drawer rendering with currentApplicant:', currentApplicant)}  {/* Add logging here */}
                            {currentApplicant && renderApplicant(currentApplicant)}
                        </DrawerBody>
                        <DrawerFooter>
                            {/* Include any footer content or actions here */}
                        </DrawerFooter>
                    </DrawerContent>
                </Drawer>
            </Flex>
        </Flex >
    );
};

const MessageList = ({ messages, currentUserId }) => {
    console.log('Current userId:', currentUserId);
    return (
        <VStack align="stretch" spacing={4}>
            {messages.map((message, index) => (
                <Flex
                    key={index}
                    justifyContent={message.sender === currentUserId ? 'flex-end' : 'flex-start'}
                >
                    <Box
                        p={2}
                        borderRadius="md"
                        bg={message.sender === currentUserId ? '#118AB2' : 'gray.200'}
                        color={message.sender === currentUserId ? 'white' : 'black'}
                    >
                        {message.content}
                    </Box>
                </Flex>
            ))}
        </VStack>
    );
};

export default Talent;