import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
    Box, Button, FormControl, FormLabel, Flex, useToast, Input, Stack, Grid, GridItem, FormErrorMessage, Textarea, HStack, Divider, Image
} from '@chakra-ui/react';
import CreatableSelect from 'react-select/creatable';
import Background from '../../assets/Background.webp';

export default function CompanyInfo() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [socialMediaLinks, setSocialMediaLinks] = useState([]);
    const [subAccounts, setSubAccounts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [logoFile, setLogoFile] = useState(null);
    const [industries, setIndustries] = useState([]);
    const [selectedIndustry, setSelectedIndustry] = useState(null);
    const [certificationsAndAwards, setCertificationsAndAwards] = useState([]);
    const [logoPreview, setLogoPreview] = useState();
    const [address, setAddress] = useState({
        street: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
    });

    const navigate = useNavigate();
    const toast = useToast();

    useEffect(() => {
        async function fetchIndustries() {
            setIsLoading(true);
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + '/api/industries/search');
                const industryOptions = response.data.map(industry => ({
                    label: industry.industry,
                    value: industry._id,
                }));

                setIndustries(industryOptions);
            } catch (error) {
                // Handle error appropriately
                console.error('Error fetching industries:', error);
            }
            setIsLoading(false);
        }

        fetchIndustries();
    }, []);

    useEffect(() => {
        console.log('Industries state updated:', industries);  // Add this line to log the state
    }, [industries]);

    const saveNewIndustry = async (newIndustry) => {
        const authToken = localStorage.getItem('auth_token');
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_URL + '/api/industries',
                { industry: newIndustry },
                {
                    headers: {
                        'Authorization': `Bearer ${authToken}`
                    }
                }
            );
            // Assuming the response will contain the industry object with '_id'
            const newIndustryOption = { label: newIndustry, value: response.data._id };
            setIndustries(prev => [...prev, newIndustryOption]);
            setSelectedIndustry(newIndustryOption); // Update the selectedIndustry with the new industry
        } catch (error) {
            console.error('Error saving new industry:', error);
            toast({
                title: 'Error',
                description: 'Failed to save new industry',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleIndustryChange = (selectedOption) => {
        setSelectedIndustry(selectedOption);
        if (selectedOption && selectedOption.__isNew__) {
            setIsLoading(true);
            saveNewIndustry(selectedOption.label).then(() => {
                setIsLoading(false);
            });
        }
    };

    const addCertificationOrAward = () => {
        setCertificationsAndAwards([...certificationsAndAwards, { name: '' }]);
    };

    const removeCertificationOrAward = (index) => {
        const updatedCertificationsAndAwards = [...certificationsAndAwards];
        updatedCertificationsAndAwards.splice(index, 1);
        setCertificationsAndAwards(updatedCertificationsAndAwards);
    };

    const updateCertificationOrAward = (index, newName) => {
        const updatedCertificationsAndAwards = [...certificationsAndAwards];
        updatedCertificationsAndAwards[index].name = newName;
        setCertificationsAndAwards(updatedCertificationsAndAwards);
    };

    const handleAddressChange = (e) => {
        const { name, value } = e.target;
        setAddress(prevAddress => ({
            ...prevAddress,
            [name]: value,
        }));
    };

    const handleLogoChange = (event) => {
        const file = event.target.files[0];
        setLogoFile(file);
        setLogoPreview(URL.createObjectURL(file));
    };

    useEffect(() => {
        return () => {
            logoPreview && URL.revokeObjectURL(logoPreview);
        };
    }, [logoPreview]);

    const onSubmit = async (formDataFields) => {
        setIsLoading(true);
        const formData = new FormData();

        // Append the social media links and subaccounts as JSON strings
        formData.append('socialMediaLinks', JSON.stringify(socialMediaLinks));
        formData.append('address', JSON.stringify(address));
        formData.append('certificationsAndAwards', JSON.stringify(certificationsAndAwards));
        formData.append('subAccounts', JSON.stringify(subAccounts.map(acc => ({
            personId: acc.person,
            permissionLevel: acc.permissionLevel
        }))));

        // Append the logo file if it exists
        if (logoFile) {
            formData.append('logo', logoFile);
        }

        // Append the selected industry
        formData.append('industry', selectedIndustry ? selectedIndustry.value : '');

        // Append the rest of the form fields to formData
        Object.keys(formDataFields).forEach(key => {
            if (key !== 'logo') { // Skip the logo field since it's already handled
                formData.append(key, formDataFields[key]);
            }
        });

        // Log the formData for debugging purposes
        for (let [key, value] of formData.entries()) {
            console.log(`${key}: ${value}`);
        }

        // Get the authorization token
        const authToken = localStorage.getItem('auth_token');

        try {
            // Make the POST request to your API
            const response = await axios.post(process.env.REACT_APP_API_URL + '/api/company', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${authToken}`,
                },
            });

            if (response.status === 201) {
                console.log('Logo URL:', response.data.logoUrl);
                navigate('/settings');
            }
        } catch (error) {
            console.error("Error sending data to server: ", error);
            toast({
                title: 'Error',
                description: 'An error occurred while submitting the form. Please try again later.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false); // Stop the loading indicator
        }
    };

    const addSocialMediaLink = () => {
        setSocialMediaLinks([...socialMediaLinks, { name: '', link: '' }]);
    };

    const removeSocialMediaLink = (index) => {
        const updatedLinks = [...socialMediaLinks];
        updatedLinks.splice(index, 1);
        setSocialMediaLinks(updatedLinks);
    };

    const updateSocialMediaLink = (index, updatedLink) => {
        const updatedLinks = [...socialMediaLinks];
        updatedLinks[index] = updatedLink;
        setSocialMediaLinks(updatedLinks);
    };
    const addSubAccount = () => {
        setSubAccounts([...subAccounts, { person: '', permissionLevel: '' }]);
    };

    const removeSubAccount = (index) => {
        const updatedAccounts = [...subAccounts];
        updatedAccounts.splice(index, 1);
        setSubAccounts(updatedAccounts);
    };

    const updateSubAccount = (index, updatedAccount) => {
        const updatedAccounts = [...subAccounts];
        updatedAccounts[index] = updatedAccount;
        setSubAccounts(updatedAccounts);
    };

    const styles = {
        formContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            padding: '20px',
            maxWidth: '600px',
            margin: '0 auto',
            backgroundColor: '#f5f5f5',
            borderRadius: '10px',
        },
        label: {
            fontWeight: 'bold',
            marginTop: '10px',
        },
        input: {
            padding: '5px',
            margin: '5px 0',
            borderRadius: '5px',
            border: '1px solid #ccc',
            width: '100%',
        },
        textarea: {
            width: '100%',
            height: '80px',
            padding: '5px',
            margin: '5px 0',
            borderRadius: '5px',
            border: '1px solid #ccc',
        },
        button: {
            marginTop: '10px',
            backgroundColor: '#007BFF',
            color: 'white',
            padding: '7px 15px',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
        },
        section: {
            marginTop: '20px',
        }
    };

    return (
        <Flex p={4} direction="column" minHeight="100vh" w="100%" bgImage={Background} bgSize="cover" bgRepeat="no-repeat" bgPosition="center">
            <Flex justifyContent="center" p={4}>
                <Box w="full" bg={"blackAlpha.700"} maxW="1000px" p={6} rounded="lg" shadow="lg">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>

                            {/* Left Column */}
                            <GridItem colSpan={[2, 1]}>
                                <Stack spacing={4}>
                                    <FormControl isInvalid={errors.companyName}>
                                        <FormLabel color={"white"}>Company Name</FormLabel>
                                        <Input bg={"shiteSmoke"} type="text" {...register("companyName")} />
                                        {errors.companyName && <FormErrorMessage>Company Name is required</FormErrorMessage>}
                                    </FormControl>

                                    <HStack w={"100%"} justify={"space-between"}>
                                        <FormControl>
                                            <FormLabel color={"white"}>Main Contact</FormLabel>
                                            <Input bg={"shiteSmoke"} type="text" {...register("mainContact")} />
                                        </FormControl>

                                        <FormControl>
                                            <FormLabel color={"white"}>Contact Title</FormLabel>
                                            <Input bg={"shiteSmoke"} type="text" {...register("contactTitle")} />
                                        </FormControl>
                                    </HStack>

                                    <FormControl isInvalid={errors.website}>
                                        <FormLabel color={"white"}>Website</FormLabel>
                                        <Input bg={"shiteSmoke"} type="text" {...register("website")} />
                                        {errors.website && <FormErrorMessage>{errors.website.message}</FormErrorMessage>}
                                    </FormControl>

                                    <FormControl isInvalid={errors.billingEmail}>
                                        <FormLabel color={"white"}>Billing Email</FormLabel>
                                        <Input bg={"shiteSmoke"} type="email" {...register("billingEmail")} />
                                        {errors.billingEmail && <FormErrorMessage>Billing Email is required</FormErrorMessage>}
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel color={"white"}>Company Description</FormLabel>
                                        <Textarea bg={"shiteSmoke"} {...register("description")} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel color={"white"}>Logo</FormLabel>
                                        <Flex alignItems="center">
                                            <Input type="file" accept="image/*" onChange={handleLogoChange} hidden id="logo-upload" />
                                            <FormLabel htmlFor="logo-upload" cursor="pointer" padding="5px 10px" bg="#118AB2" color="white" borderRadius="md" _hover={{ bg: "#01BF02" }}>
                                                {logoFile ? 'Change Logo' : 'Upload Logo'}
                                            </FormLabel>
                                            {logoFile && (
                                                <Box>
                                                    <Image src={logoPreview} maxH="100px" maxW="100px" alt="Logo preview" />
                                                </Box>
                                            )}
                                        </Flex>
                                    </FormControl>
                                </Stack>
                            </GridItem>

                            {/* Right Column */}
                            <GridItem colSpan={[2, 1]}>
                                <Stack spacing={4}>
                                    {/* Address Section */}
                                    <FormControl isInvalid={errors.address}>
                                        <FormLabel color={"white"}>Street Address</FormLabel>
                                        <Input bg={"shiteSmoke"} color={"white"} type="text" name="street" value={address.street} onChange={handleAddressChange} />
                                        {errors.address && <FormErrorMessage>Street Address is required</FormErrorMessage>}
                                    </FormControl>
                                    <HStack>
                                        <FormControl>
                                            <FormLabel color={"white"}>City</FormLabel>
                                            <Input bg={"shiteSmoke"} type="text" name="city" value={address.city} onChange={handleAddressChange} />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel color={"white"}>State</FormLabel>
                                            <Input bg={"shiteSmoke"} type="text" name="state" value={address.state} onChange={handleAddressChange} />
                                        </FormControl>
                                    </HStack>
                                    <HStack>
                                        <FormControl>
                                            <FormLabel color={"white"}>Country</FormLabel>
                                            <Input bg={"shiteSmoke"} type="text" name="country" value={address.country} onChange={handleAddressChange} />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel color={"white"}>Postal Code</FormLabel>
                                            <Input bg={"shiteSmoke"} type="text" name="postalCode" value={address.postalCode} onChange={handleAddressChange} />
                                        </FormControl>
                                    </HStack>
                                    <FormControl>
                                        <FormLabel color={"white"}>Number of Employees</FormLabel>
                                        <Input bg={"shiteSmoke"} type="text" {...register("amountEmployees")} />
                                    </FormControl>
                                    <FormControl id="industry" isRequired>
                                        <FormLabel color={"white"}>Industry</FormLabel>
                                        <CreatableSelect
                                            isClearable
                                            isDisabled={isLoading}
                                            bg={"shiteSmoke"}
                                            isLoading={isLoading}
                                            onCreateOption={saveNewIndustry}
                                            onChange={handleIndustryChange}
                                            options={industries}
                                            value={selectedIndustry}
                                        />
                                    </FormControl>
                                </Stack>
                            </GridItem>

                            {/* Full-width Inputs */}
                            <GridItem colSpan={2}>
                                <Box shadow={"lg"} p={2} bg={"#118AB2"} borderRadius={"lg"} mt={4} w={"100%"} maxH={"300px"} overflowY={"scroll"}>
                                    {/* Certifications and Awards Section */}
                                    {certificationsAndAwards.map((item, index) => (
                                        <HStack align={"flex-end"} key={index} mt={4}>
                                            <FormControl>
                                                <FormLabel color={"white"}>Certification/Award Name</FormLabel>
                                                <Input
                                                    type="text"
                                                    bg={"white"}
                                                    value={item.name}
                                                    onChange={(e) => updateCertificationOrAward(index, e.target.value)}
                                                />
                                            </FormControl>
                                            <Button colorScheme="red" onClick={() => removeCertificationOrAward(index)}>Remove</Button>
                                        </HStack>
                                    ))}

                                    {/* Social Media Links Section */}
                                    {socialMediaLinks.map((link, index) => (
                                        <Flex key={index} alignItems="center" justify={"flex-start"} mt={4}>
                                            <FormControl>
                                                <FormLabel color={"white"}>Platform Name</FormLabel>
                                                <Input
                                                    type="text"
                                                    bg={"white"}
                                                    value={link.name}
                                                    onChange={e => updateSocialMediaLink(index, { ...link, name: e.target.value })}
                                                    style={styles.input}
                                                />
                                            </FormControl>
                                            <FormControl ml={2}>
                                                <FormLabel color={"white"}>URL</FormLabel>
                                                <Input
                                                    type="text"
                                                    bg={"white"}
                                                    value={link.link}
                                                    onChange={e => updateSocialMediaLink(index, { ...link, link: e.target.value })}
                                                    style={styles.input}
                                                />
                                            </FormControl>
                                            <Button mt={8} ml={2} size={"sm"} colorScheme='red' onClick={() => removeSocialMediaLink(index)}>X</Button>
                                        </Flex>
                                    ))}
                                </Box>
                            </GridItem>
                        </Grid>
                        <HStack w={"100%"} justify={"space-evenly"}>
                            <Button bg={"#118AB2"} color={"white"} _hover={{ bg: "#01BF02" }} mr={4} mt={4} onClick={addCertificationOrAward}>Add Certification/Award</Button>
                            <Button bg={"#118AB2"} color={"white"} _hover={{ bg: "#01BF02" }} ml={4} mt={4} onClick={addSocialMediaLink}>Add Social Media Link</Button>
                        </HStack>

                        {/* Submit Button */}
                        <Flex w={"100%"} justify={"flex-end"}>
                            <Button
                                mt={6}
                                isLoading={isLoading}
                                type="submit"
                                bg={"#118AB2"} color={"white"} _hover={{ bg: "#01BF02" }}
                            >
                                Save Company Information
                            </Button>
                        </Flex>
                    </form>
                </Box>
            </Flex>
        </Flex>

    );
}