import React, { useState } from 'react';
import {
    Flex,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Input,
    useDisclosure,
    IconButton,
    Avatar,
    VStack,
    useToast,
    HStack
} from '@chakra-ui/react';
import { Search2Icon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { FaUsers } from 'react-icons/fa';
import axiosInstance from '../../components/axiosInstance';

const JobSeekerSearchModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const navigate = useNavigate();
    const toast = useToast();

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearch = async () => {
        if (searchTerm.length < 3) {
            toast({
                title: 'Search term too short',
                description: 'Please enter at least 3 characters.',
                status: 'info',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        try {
            // Replace with your actual API endpoint
            const response = await axiosInstance.get(`/jobseekers/search?query=${searchTerm}`);
            setSearchResults(response.data);
        } catch (error) {
            toast({
                title: 'Error searching for job seekers',
                description: error.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleResultClick = (jobSeekerId) => {
        navigate(`/user/${jobSeekerId}`);
        onClose(); // Close the modal after navigation
    };

    return (
        <>
            <Flex
                direction="column"
                align="center"
                _hover={{
                    shadow: "lg",
                    bg: "#01BF02",
                    color: "black",
                    transform: "scale(1.05)",
                }}
                borderRadius="xl"
                p={2}
                cursor="pointer"
                transition="transform 0.2s ease-in-out"
                onClick={onOpen}
            >
                <FaUsers size="24px" color="#118AB2" />
                <Text mt={2}>Find Connections</Text>
            </Flex>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Search Job Seekers</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <HStack w={"100%"} align={"flex-start"} justify={"flex-end"}>
                            <Input
                                placeholder="Search by name or email"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                mb={4}
                            />
                            <IconButton
                                aria-label="Search"
                                icon={<Search2Icon />}
                                onClick={handleSearch}
                            />
                        </HStack>
                        <VStack mt={4} spacing={4} align="stretch">
                            {searchResults.map((result) => (
                                <Flex
                                    key={result.id}
                                    align="center"
                                    p={2}
                                    cursor="pointer"
                                    _hover={{ bg: '#118AB2', color: "white" }}
                                    borderRadius={"md"}
                                    onClick={() => handleResultClick(result._id)}
                                >
                                    <Avatar
                                        src={result.PFP || undefined}
                                        name={result.name} 
                                        bg={"#01BF02"}
                                        size="sm"
                                        mr={2}
                                    />
                                    <Text>{result.name}</Text>
                                </Flex>
                            ))}
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default JobSeekerSearchModal;
