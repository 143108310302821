// ResumeUpload.js
import React, { useState, useEffect } from 'react';
import {
    FormControl,
    FormLabel,
    Flex,
    InputGroup,
    InputLeftAddon,
    Input,
    FormHelperText,
    Icon,
    Spinner,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Text,
    Center,
    VStack,
    Progress,
    useToast
} from "@chakra-ui/react";
import { MdCloudUpload } from "react-icons/md";
import axios from 'axios';

function ResumeUpload({ setSavedResume }) {
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [progress, setProgress] = useState(0);
    const [progressText, setProgressText] = useState("");
    const toast = useToast();

    const progressStages = [
        "Scanning",
        "Reading Resume",
        "Extracting Contact Information",
        "Identifying Personal Details",
        "Getting Work History",
        "Analyzing Employment Gaps",
        "Evaluating Educational Background",
        "Analyzing Your Skills",
        "Assessing Certifications",
        "Evaluating Language Proficiency",
        "Identifying Achievements and Awards",
        "Summarizing Professional Summary",
        "Classifying Job Roles",
        "Detecting Technical Skills",
        "Extracting Soft Skills",
        "Analyzing Project Experience",
        "Reviewing Leadership Qualities",
        "Assessing Team Collaboration Experience",
        "Quantifying Performance Metrics",
        "Validating References",
        "Ensuring Consistency in Experience",
        "Cross-referencing with Job Requirements",
        "Finalizing Skill Set Analysis",
        "Optimizing for Applicant Tracking Systems",
        "Finishing Up"
    ];

    useEffect(() => {
        let interval;
        if (isLoading) {
            setProgress(0);
            interval = setInterval(() => {
                setProgress(oldProgress => {
                    if (oldProgress === 100) {
                        clearInterval(interval);
                        return 100;
                    }
                    const diff = 100 / (3.25 * 60);
                    return Math.min(oldProgress + diff, 100);
                });
            }, 1000);
        } else {
            setProgress(0);
        }

        return () => {
            clearInterval(interval);
        };
    }, [isLoading]);

    useEffect(() => {
        const stageIndex = Math.min(
            Math.floor(progress / (100 / progressStages.length)),
            progressStages.length - 1
        );
        setProgressText(progressStages[stageIndex]);
    }, [progress, progressStages]);

    async function handleFileSelect(event) {
        setIsLoading(true);
        const token = localStorage.getItem('auth_token');
        const file = event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('resume', file);

            try {
                const response = await axios.post(process.env.REACT_APP_API_URL + '/api/process_resume', formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.data && response.data.parsedData && response.data.savedResume) {
                    console.log('Parsed data:', response.data.parsedData);
                    console.log('Saved resume:', response.data.savedResume);
                    setSavedResume(response.data.savedResume);
                    setIsSuccess(true);
                } else {
                    toast({
                        title: 'Processing Error',
                        description: 'There was an issue processing your resume. Please try again.',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                console.error('Error uploading and processing resume:', error);

                let errorMessage = 'An error occurred. Please try again.';
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            errorMessage = 'Invalid file. Please upload a valid resume.';
                            break;
                        case 422:
                            errorMessage = 'Error processing resume try again.';
                            break;
                        case 500:
                            errorMessage = 'Server error. Please try again later.';
                            break;
                        default:
                            errorMessage = 'Processing error. Please try again.';
                            break;
                    }
                } else {
                    // Network error or no response from server
                    errorMessage = 'Network error. Please check your connection and try again.';
                }

                toast({
                    title: 'Upload Error',
                    description: errorMessage,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            } finally {
                setIsLoading(false);
            }
        }
    }

    return (
        <FormControl id="resume" isRequired>
            <FormLabel color={"white"} fontSize="lg" fontWeight="bold">
                Upload Resume/CV
            </FormLabel>
            <Flex alignItems="center" justifyContent="center">
                <InputGroup>
                    <InputLeftAddon bg={"#118AB2"} children={<Icon color={"white"} as={MdCloudUpload} />} />
                    <Input
                        borderRadius="md"
                        px={4}
                        py={1}
                        bg="white"
                        type="file"
                        name="resume"
                        accept=".pdf,.doc,.docx,.txt"
                        onChange={handleFileSelect}
                        _hover={{ borderColor: "#118AB2" }}
                    />
                </InputGroup>
            </Flex>
            <Center>
                <VStack>
                    <FormHelperText mb={4} fontSize="sm" color="white" textAlign="center">
                        Please upload a PDF, DOCX, or TXT file.
                    </FormHelperText>
                    {!isLoading && (<Text color={"white"} fontWeight={"semibold"} mt={-4} >If you do not have a resume, click next</Text>)}
                </VStack>
            </Center>
            {isLoading && (
                <Flex mt={4} mb={4} flexDirection="column" alignItems="center">
                    <Progress hasStripe value={progress} size="md" color={"white"} width="80%" mb={4} />
                    <Spinner mb={4} color='#118AB2' size='xl' thickness='2px' speed='0.6s' />
                    <Text fontWeight={"bold"} fontSize={"xl"} colorScheme='green'>
                        {progressText}
                    </Text>
                    <Text>This may take a few minutes....</Text>
                </Flex>
            )}
            {isSuccess && (
                <Alert
                    status='success'
                    variant='subtle'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                    textAlign='center'
                    height='200px'
                >
                    <AlertIcon boxSize='40px' mr={0} />
                    <AlertTitle mt={4} mb={1} fontSize='lg'>
                        Resume processed!
                    </AlertTitle>
                    <AlertDescription maxWidth='sm'>
                        Lets finish up...
                    </AlertDescription>
                </Alert>
            )}
        </FormControl>
    );
}

export default ResumeUpload;