import React, { useEffect, useState, useRef } from 'react';
import { Modal, ModalOverlay, ModalContent, Tooltip, ModalHeader, ModalBody, ModalCloseButton, Input, Button, Box, Text, Stack, SimpleGrid, Image, useDisclosure, Flex, HStack, ModalFooter, IconButton, color, Avatar } from '@chakra-ui/react';
import { ChatIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import EmojiPicker from 'emoji-picker-react';
import { socketAtom } from '../socketAtom';
import { useAtom } from 'jotai';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../App.css';
import axiosInstance from './axiosInstance';
import { faPaperclip, faTimes } from '@fortawesome/free-solid-svg-icons';

const determineFileType = (file) => {
    if (file.type === 'application/pdf') {
        return 'pdf'; // Explicitly handle PDF files
    } else {
        return file.type.split('/')[0]; // Continue handling other files as before ('image', 'video', etc.)
    }
};

const ChatModal = ({ isOpen, onClose, connection, onMessagesRead, senderPFP, senderFirstName, senderLastName, userId }) => {
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [socket, setSocket] = useAtom(socketAtom);
    const [isTyping, setIsTyping] = useState(false);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const endOfMessagesRef = useRef(null);
    const [isUploading, setIsUploading] = useState(false);
    const [pendingFile, setPendingFile] = useState(null);
    const fileInputRef = useRef(null);

    console.log('PFP', senderPFP);

    useEffect(() => {
        if (!connection || !isOpen) return;

        const roomName = (connection.connectionId);

        // Connect and join room
        socket.emit('joinPrivateRoom', { connectionId: connection.connectionId, roomName });

        const handleNewPrivateMessage = (newMessage) => {
            console.log('New message received:', newMessage);
            setMessages((msgs) => [...msgs, newMessage]);
        };

        const handleTyping = ({ senderId }) => {
            if (senderId !== connection.targetUserId) {
                setIsTyping(true);
                setTimeout(() => {
                    setIsTyping(false);
                }, 2000); // Hide typing indicator after 2 seconds
            }
        };

        socket.on('typing', handleTyping);

        const handleExistingMessages = (existingMessages) => {
            setMessages(existingMessages);
        };

        socket.on('newPrivateMessage', handleNewPrivateMessage);
        socket.on('existingMessages', handleExistingMessages);

        // Clean up the event listeners when the component unmounts or the dependencies change
        return () => {
            socket.off('newPrivateMessage', handleNewPrivateMessage);
            socket.off('existingMessages', handleExistingMessages);
            setMessage('');
            socket.off('typing', handleTyping);
        };
    }, [isOpen, connection, socket]);

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setIsUploading(true);
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axiosInstance.post('/project/upload', formData);

            if (response.status === 200) {
                setPendingFile({
                    url: response.data.fileUrl,
                    type: determineFileType(file),
                    name: file.name,
                });
            } else {
                throw new Error(response.data.message || 'Upload failed');
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        } finally {
            setIsUploading(false);
        }
    };

    const renderMessageContent = (message) => {
        switch (message.fileType) {
            case 'image':
                return (
                    <>
                        <Text fontSize="md">{message.text}</Text>
                        <Image src={message.fileUrl} alt="Sent image" maxW="200px" maxH="200px" />
                        <br />
                        <Button as="a" href={message.fileUrl} download colorScheme="blue" size="sm">
                            Download Image
                        </Button>
                    </>
                );
            case 'pdf':
                return (
                    <>
                        <Text fontSize="md">{message.text}</Text>
                        <iframe
                            key={message._id} // Add a unique key based on the message's _id
                            src={message.fileUrl}
                            width="100%"
                            height="200px"
                            style={{ border: 'none' }}
                            title="PDF content"
                        ></iframe>
                        <br />
                        <Button as="a" href={message.fileUrl} download="file.pdf" colorScheme="blue" size="sm">
                            Download PDF
                        </Button>
                    </>
                );
            default:
                // Handling for other file types or messages without a file
                return message.text ? <Text fontSize="md">{message.text}</Text> : <Text fontSize="md">Unsupported content type</Text>;
        }
    };

    const TypingIndicator = () => {
        return (
            <Flex ml={2} mb={4} alignItems="center">
                <Text mb={4} fontSize="sm" fontWeight={"semibold"} color="#01bf02" mr={2}>
                    {connection.requesterName} is typing
                </Text>
                <Flex>
                    <Box
                        as="span"
                        w={1}
                        h={1}
                        bg="#01bf02"
                        borderRadius="full"
                        mr={1}
                        animation="bounce 1s infinite"
                    />
                    <Box
                        as="span"
                        w={1}
                        h={1}
                        bg="#01bf02"
                        borderRadius="full"
                        mr={1}
                        animation="bounce 1s infinite"
                        style={{ animationDelay: '0.2s' }}
                    />
                    <Box
                        as="span"
                        w={1}
                        h={1}
                        bg="#01bf02"
                        borderRadius="full"
                        animation="bounce 1s infinite"
                        style={{ animationDelay: '0.4s' }}
                    />
                </Flex>
            </Flex>
        );
    };

    const onEmojiClick = (emojiObject) => {
        // Append emoji to the current message state without using an event
        setMessage((prevMessage) => prevMessage + emojiObject.emoji);
        setShowEmojiPicker(false); // Hide the picker after selection
    };

    // Toggle emoji picker display
    const toggleEmojiPicker = () => setShowEmojiPicker(!showEmojiPicker);

    const sendMessage = () => {
        if (message.trim() || pendingFile) {
            const roomName = (connection.connectionId);
            socket.emit('privateMessage', {
                roomName,
                text: message,
                senderId: connection.targetUserId,
                recipientId: connection.requesterId,
                connectionId: connection.connectionId,
                senderPFP,
                senderFirstName,
                senderLastName,
                fileUrl: pendingFile?.url,
                fileType: pendingFile?.type,
            });
            setMessage('');
            setIsTyping(false);
            setPendingFile(null);
        }
    };

    useEffect(() => {
        endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    useEffect(() => {
        if (isOpen) {
            const roomName = (connection.connectionId);
            socket.emit('markMessagesAsRead', { roomName, userId });
        }
    }, [isOpen, connection.targetUserId, connection.requesterId]);

    return (
        <Modal size={"lg"} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    bg="#01bf02"
                    color="white"
                    p={4}
                    borderTopRadius="md"
                >
                    <Text fontSize="xl">Connected with {connection.requesterName}</Text>
                    <ModalCloseButton />
                </ModalHeader>
                <ModalBody bg={"black"} p={1}>
                    <Stack mb={2} spacing={4} p={8} maxH="600px" overflowY="scroll">
                        {messages.map((msg, index) => {
                            const sentTime = new Date(msg.createdAt);
                            const currentTime = new Date();
                            const timeDiff = currentTime - sentTime;
                            let timeString;

                            if (timeDiff < 60000) {
                                timeString = 'Just now';
                            } else if (timeDiff < 3600000) {
                                const minutes = Math.floor(timeDiff / 60000);
                                timeString = `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
                            } else if (timeDiff < 86400000) {
                                const hours = Math.floor(timeDiff / 3600000);
                                timeString = `${hours} hour${hours > 1 ? 's' : ''} ago`;
                            } else {
                                const options = {
                                    month: 'short',
                                    day: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    hour12: true
                                };
                                timeString = sentTime.toLocaleString('en-US', options);
                            }

                            return (
                                <Flex
                                    justify={"flex-end"}
                                    key={index}
                                    alignSelf={msg.senderId === connection.requesterId ? 'flex-start' : 'flex-end'}
                                >
                                    <Box w={"100%"}>
                                        <Flex align={"center"} mb={1}>
                                            <Avatar
                                                size="xs"
                                                name={msg.senderFirstName + ' ' + msg.senderLastName}
                                                src={msg.senderPFP || ''}
                                            />
                                            <Text ml={1} color={"white"} fontSize="xs">{msg.senderFirstName}</Text>
                                        </Flex>
                                        <Box
                                            key={index}
                                            w={"100%"}
                                            bg={msg.senderId === connection.requesterId ? 'gray.100' : '#01bf02'}
                                            p={3}
                                            color={msg.senderId === connection.requesterId ? 'black' : 'white'}
                                            borderRadius="lg"
                                            boxShadow="md"
                                        >
                                            {renderMessageContent(msg)}
                                            <Text fontSize="xs" fontWeight={"semibold"} mt={1} color={msg.senderId === connection.requesterId ? 'gray' : 'white'}>
                                                {timeString}
                                            </Text>
                                        </Box>
                                    </Box>
                                </Flex>
                            );
                        })}
                        {isTyping && <TypingIndicator />}
                        <div ref={endOfMessagesRef}></div>
                    </Stack>
                </ModalBody>
                <ModalFooter border={"1px"} bg="blackAlpha.800" p={4}>
                    <Box w={"100%"}>
                        <HStack w={"100%"}>
                            <Input
                                placeholder="Type your message..."
                                value={message}
                                bg={"white"}
                                w={"100%"}
                                onChange={(e) => {
                                    setMessage(e.target.value);
                                    socket.emit('typing', { roomName: (connection.connectionId), senderId: connection.targetUserId });
                                }}
                                onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                                borderRadius="xl"
                                px={4}
                                py={2}
                                _focus={{
                                    borderColor: "#01bf02",
                                    boxShadow: "0 0 0 1px #38A169",
                                }}
                            />
                            <Input
                                type="file"
                                onChange={handleFileChange}
                                hidden
                                ref={fileInputRef}
                            />
                            <Tooltip label="Add Images or Files">
                                <IconButton
                                    icon={<FontAwesomeIcon size="lg" color="#01bf02" icon={faPaperclip} />}
                                    variant="ghost"
                                    isLoading={isUploading}
                                    onClick={() => fileInputRef.current && fileInputRef.current.click()}
                                />
                            </Tooltip>
                            <IconButton
                                onClick={sendMessage}
                                variant="ghost"
                                icon={<FontAwesomeIcon size='lg' color='#01bf02' icon={faPaperPlane} />}
                                borderRadius="full"
                            />
                            <IconButton
                                onClick={toggleEmojiPicker}
                                variant="ghost"
                                icon={<span role="img" size='lg' aria-label="emoji">😊</span>}
                                borderRadius="full"
                            />
                        </HStack>
                        {pendingFile && (
                            <Flex bg={"whiteSmoke"} borderRadius={"lg"} p={1} w={"fit-content"} alignItems="center" justify={"flex-start"}>
                                <Text fontSize="sm" color="#02bf01">
                                    {pendingFile.name.length > 16 ? pendingFile.name.substring(0, 15) + '...' : pendingFile.name}
                                </Text>
                                <IconButton
                                    icon={<FontAwesomeIcon icon={faTimes} />}
                                    size="sm"
                                    variant="ghost"
                                    onClick={() => setPendingFile(null)}
                                    aria-label="Remove pending file"
                                />
                            </Flex>
                        )}
                        {showEmojiPicker && <Flex mt={2} w={"100%"} justify={"flex-end"}><EmojiPicker width={"100%"} onEmojiClick={onEmojiClick} /></Flex>}
                    </Box>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

const ConnectionListItem = ({ connection, newMessage, onChatStart, hasNewMessages, isTyping }) => {

    const newMessagesCount = hasNewMessages || 0;

    if (!connection || !connection.requesterName) {
        // Render nothing or a placeholder if connection or requesterName is not defined
        return null;
    }

    return (
        <Flex align="center" justify="center" p={2} bg={"white"} borderRadius="lg" shadow="md" m={1}>
            {
                connection.requesterPFP ? (
                    <Image src={connection.requesterPFP} w="45px" h="45px" borderRadius="full" />
                ) : (
                    <Box
                        w="45px"
                        h="45px"
                        borderRadius="full"
                        bg="#01BF02"
                        color="white"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        fontSize="lg"
                    >
                        {/* Ensure connection.requesterName is defined before calling split */}
                        {connection.requesterName.split(' ').map(name => name[0]).join('')}
                    </Box>
                )
            }
            <Box flex="1" ml={2}>
                <Text fontWeight="bold">
                    <Link to={`/user/${connection.requesterId}`} style={{ textDecoration: 'none', color: 'black' }}>
                        {connection.requesterName}
                    </Link>
                </Text>
                <Text fontSize="sm" color="gray.500">
                    {newMessage?.text}
                </Text>
                {isTyping && (
                    <Text fontSize="sm" color="green.500">
                        is typing...
                    </Text>
                )}
            </Box>
            <Button ml={4} size="sm" bg={newMessagesCount > 0 ? "#02bf01" : "gray"} _hover={{ bg: "green" }} color="white" leftIcon={<ChatIcon />} onClick={() => onChatStart(connection)}>
                Chat
                {newMessagesCount > 0 && <Flex justify={"center"} align={"center"} as="span" ml={2} bg="green" p={1} borderRadius="full" color="white"><Text fontSize={"xs"}>{newMessagesCount}</Text></Flex>}
            </Button>
        </Flex>
    );
};

const UserConnectionsList = ({ connections, senderPFP, senderFirstName, senderLastName, newMessage, typingIndicator, userId }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [activeConnection, setActiveConnection] = React.useState(null);
    const [socket, setSocket] = useAtom(socketAtom);
    const [newMessageIndicator, setNewMessageIndicator] = useState({});
    const [sortedConnections, setSortedConnections] = useState(connections);

    // Sort connections initially and whenever `connections` or `newMessageIndicator` changes
    useEffect(() => {
        const sorted = [...connections].sort((a, b) => {
            const aHasNewMessage = !!newMessageIndicator[a._id];
            const bHasNewMessage = !!newMessageIndicator[b._id];
            if (aHasNewMessage && !bHasNewMessage) return -1;
            if (!aHasNewMessage && bHasNewMessage) return 1;
            return 0; // Maintain existing order if neither or both have new messages
        });
        setSortedConnections(sorted);
    }, [connections, newMessageIndicator]);

    useEffect(() => {
        const fetchUnreadMessagesCount = async () => {
            try {
                const response = await axiosInstance.get('/unread-messages-count');
                const unreadMessagesCount = response.data;
                const updatedIndicator = {};
                unreadMessagesCount.forEach(({ _id: connectionId, count }) => {
                    updatedIndicator[connectionId] = count;
                    console.log('insideupdatedIndicator', updatedIndicator);
                });
                console.log('updatedIndicator', updatedIndicator);
                setNewMessageIndicator(updatedIndicator);
            } catch (error) {
                console.error('Error fetching unread messages count:', error);
            }
        };

        fetchUnreadMessagesCount();
    }, []);

    useEffect(() => {
        console.log('newMessageIndicator updated:', newMessageIndicator);
    }, [newMessageIndicator]);

    useEffect(() => {
        const handleMessage = (message) => {
            const connectionId = message.connectionId;
            const senderId = message.senderId;

            if (connectionId) {
                const connection = connections.find((conn) => conn.connectionId === connectionId);
                if (connection && senderId !== userId) {
                    setNewMessageIndicator(prevState => ({
                        ...prevState,
                        [connectionId]: (prevState[connectionId] || 0) + 1
                    }));
                }
            }
        };

        socket.on('newPrivateMessage', handleMessage);

        return () => socket.off('newPrivateMessage', handleMessage);
    }, [socket, connections]);

    useEffect(() => {
        if (newMessage) {
            console.log('newMessage updated:', newMessage);
            const connectionId = newMessage.connectionId;
            console.log('connectionId updated:', connectionId);
            setSortedConnections((prevConnections) =>
                prevConnections.map((connection) => {
                    if (connection.connectionId === connectionId) {
                        // New message belongs to this connection, update it.
                        return {
                            ...connection,
                            lastMessage: newMessage, // Assuming 'lastMessage' is the field for the most recent message.
                        };
                    }
                    // No update for connections that do not match the message connection ID.
                    return connection;
                })
            );
        }
    }, [newMessage]);

    console.log('sortedConnections', sortedConnections);

    const handleChatStart = (connection) => {
        setActiveConnection(connection);
        setNewMessageIndicator(prev => ({ ...prev, [connection.connectionId]: false }));
        onOpen();
    };

    return (
        <Box mt={1} p={1} w="100%">
            <SimpleGrid maxH={"300px"} overflowY={"scroll"} columns={{ base: 1 }} spacing={1}>
                {sortedConnections.map((connection) => (
                    <ConnectionListItem
                        key={connection._id}
                        connection={connection}
                        newMessage={connection.lastMessage && connection.lastMessage.connectionId === connection.connectionId ? connection.lastMessage : null}
                        onChatStart={handleChatStart}
                        hasNewMessages={newMessageIndicator[connection.connectionId] || 0}
                        isTyping={typingIndicator[connection.connectionId]}
                    />
                ))}

            </SimpleGrid>

            {activeConnection && (
                <ChatModal
                    isOpen={isOpen}
                    onClose={onClose}
                    connection={activeConnection}
                    senderPFP={senderPFP}
                    userId={userId}
                    senderFirstName={senderFirstName}
                    senderLastName={senderLastName}
                    onMessagesRead={() => {
                        setNewMessageIndicator(prev => ({ ...prev, [activeConnection._id]: false }));
                    }}
                />
            )}
        </Box>
    );
};

export default UserConnectionsList;