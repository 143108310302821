// ForgotPassword.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Box, Button, useToast, FormControl, FormLabel, Input, VStack, Flex, Text, Link, HStack, Icon, InputGroup, InputRightElement } from '@chakra-ui/react';
import { FaEnvelope, FaShieldAlt } from 'react-icons/fa';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import Background from '../../assets/Background.webp';
import Logo from '../../assets/whitelogo.png';

function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [emailSent, setEmailSent] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [verificationToken, setVerificationToken] = useState('');
    const [isTokenVerified, setIsTokenVerified] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const toast = useToast();
    const navigate = useNavigate();

    const validateEmail = (email) => {
        const isValid = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
        setIsEmailValid(isValid);
        return isValid;
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        validateEmail(e.target.value);
    };

    const sendVerificationEmail = async () => {
        if (!validateEmail(email)) {
            setError('Invalid email address.');
            return;
        }
        setLoading(true);
        setEmailSent(true);
        setError('');

        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/api/email', {
                email: email // Using email state
            }, {
                headers: { 'Content-Type': 'application/json' }
            });

            if (response.status === 200) {
                console.log('Email sent for verification:', response);
                // Logic to handle the response
            } else {
                console.log('Failed to send email:', response);
                setError('Failed to send verification email');
            }
        } catch (error) {
            console.error('Error:', error);
            setError('Error sending verification email');
        } finally {
            setLoading(false);
        }
    };

    const verifyEmail = async () => {
        setLoading(true);
        setError('');

        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/api/verify', {
                email: email,
                code: verificationToken
            });
            if (response.data.message === "Email verification successful") {
                setIsTokenVerified(true);
            } else {
                setError('Verification failed');
            }
        } catch (error) {
            console.error("Error verifying token:", error);
            setError('Error during verification');
        } finally {
            setLoading(false);
        }
    };

    const handleResetPassword = async () => {
        if (newPassword !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        setLoading(true);
        try {
            const response = await axios.put(process.env.REACT_APP_API_URL + '/api/reset-password', {
                email,
                newPassword,
            });

            if (response.status === 200) {
                toast({
                    title: 'Password Reset',
                    description: 'Your password has been successfully reset.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });

                navigate('/login'); // Redirect to login page
            }
        } catch (err) {
            setError('Error resetting password.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Flex
            direction="column"
            justify="center"
            align="center"
            height="100vh"
            width="100%"
            backgroundImage={Background}
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
        >
            <VStack spacing={6} alignItems="center">
                <Box mb={4}>
                    <img src={Logo} alt="JobJar.AILogo" width="200px" />
                </Box>

                <Box
                    p={8}
                    maxW="xl"
                    borderWidth={0}
                    borderRadius="xl"
                    boxShadow="xl"
                    backgroundColor="whiteAlpha.800"
                >
                    <VStack spacing={4} alignItems="center" width="full">
                        {!isTokenVerified ? (
                            <>
                                <FormControl>
                                    <FormLabel>Email</FormLabel>
                                    <InputGroup size="lg">
                                        <Input
                                            pr="4.5rem"
                                            type="email"
                                            placeholder="Enter email"
                                            bg="white"
                                            onChange={handleEmailChange}
                                            borderColor={isEmailValid === false ? "red.500" : isEmailValid ? "green.500" : null}
                                        />
                                        <InputRightElement width="4.5rem">
                                            <Icon as={FaEnvelope} />
                                        </InputRightElement>
                                    </InputGroup>
                                </FormControl>
                                {/* Button to Send Verification Email */}
                                < Button
                                    isLoading={loading}
                                    bg={"#118AB2"}
                                    color={"white"}
                                    _hover={{ bg: "#01BF02" }}
                                    onClick={sendVerificationEmail}
                                >
                                    Send Verification Email
                                </Button>
                                {emailSent && (
                                    <>
                                        <HStack alignItems={"flex-end"} w={"100%"}>
                                            <Flex align={"flex-start"}>
                                                <FormControl isHidden={!isTokenVerified}>
                                                    <FormLabel>Verification Code</FormLabel>
                                                    <Input
                                                        type="text"
                                                        bg="white"
                                                        placeholder="Enter verification code"
                                                        onChange={(e) => setVerificationToken(e.target.value)}
                                                    />
                                                </FormControl>
                                            </Flex>
                                            <Flex align={"flex-start"}>
                                                <Button bg={"#118AB2"}
                                                    color={"white"}
                                                    size={"md"}
                                                    _hover={{ bg: "#01BF02" }} mt={4} onClick={verifyEmail}>Verify</Button>
                                            </Flex>
                                        </HStack>
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                <Box>
                                    {loading && <Text>Loading...</Text>}
                                    {error && <Text color="red.500">{error}</Text>}
                                </Box>

                                <FormControl>
                                    <FormLabel>New Password</FormLabel>
                                    <Input
                                        type={showPassword ? "text" : "password"}
                                        bg="white"
                                        placeholder="Enter new password"
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                </FormControl>
                                <FormControl mt={2}>
                                    <FormLabel>Confirm New Password</FormLabel>
                                    <Input
                                        type={showPassword ? "text" : "password"}
                                        bg="white"
                                        placeholder="Confirm new password"
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                </FormControl>
                                <Flex w={"100%"} align={"center"} justify={"flex-end"}>
                                    <Text mr={2} fontSize="sm">{showPassword ? "Hide Password" : "Show Password"}</Text>
                                    <Button h="1.75rem" size="sm" onClick={togglePasswordVisibility}>
                                        {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                                    </Button>
                                </Flex>

                                <Button
                                    mt={4}
                                    height="50px"
                                    onClick={handleResetPassword}
                                    bg={"#118AB2"}
                                    color={"white"}
                                    _hover={{ bg: "#01BF02" }}
                                    width="full"
                                    isDisabled={newPassword !== confirmPassword || newPassword === ''}
                                >
                                    Reset Password
                                </Button>

                                {/* Display a message if passwords don't match */}
                                {newPassword !== confirmPassword && newPassword !== '' && (
                                    <Text color="red.500" mt={2}>Passwords do not match</Text>
                                )}

                                <Flex justifyContent="center" alignItems="center" my={4}>
                                    <Icon as={FaShieldAlt} w={6} h={6} color="green.500" mr={2} />
                                    <Text fontSize="sm">Secure Connection</Text>
                                </Flex>

                            </>
                        )}
                        <Flex justifyContent="center" alignItems="center" mt={0}>
                            <Text>
                                Remember your password? <Link color="#118AB2" href="/login">Login here</Link>
                            </Text>
                        </Flex>
                    </VStack>
                </Box>
            </VStack >
        </Flex >
    );
}

export default ForgotPassword;
