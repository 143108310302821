import React, { useState, useEffect } from 'react';
import axiosInstance from '../../components/axiosInstance';
import { useNavigate } from 'react-router-dom';
import {
    Box, Text, Button, Stat, StatLabel, StatNumber, useToast, Tooltip, Switch,
    ListItem, UnorderedList, Flex, VStack, SimpleGrid, Icon, Heading, Spacer, Center, HStack
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong, faAsterisk, faCalendarDay, faCodeCompare, faEnvelopeCircleCheck, faPowerOff, faRankingStar } from '@fortawesome/free-solid-svg-icons';
import { FaUserTie, FaPaperPlane, FaRegChartBar, FaDatabase, FaBriefcase } from 'react-icons/fa';
import { faSearchengin } from '@fortawesome/free-brands-svg-icons';

const subscriptionLevelDisplayNames = {
    basic: 'Basic',
    premium: 'Premium',
    free: 'Free'
};

const SmartMatchAi = ({ user }) => {
    const [subscriptionLevel, setSubscriptionLevel] = useState(user?.jobSeekerDetails?.subscriptionLevel || 'free');
    const [jobMatchingStats, setJobMatchingStats] = useState(user?.jobSeekerDetails?.jobMatchingStats || {});
    const [processingDay] = useState(user?.jobSeekerDetails?.processingDay || null);
    const [isSmartMatchEnabled, setIsSmartMatchEnabled] = useState(user?.jobSeekerDetails?.smartMatchActive || false);
    const token = localStorage.getItem('auth_token');
    const navigate = useNavigate();
    const toast = useToast();

    useEffect(() => {
        const updateSmartMatchStatus = async () => {
            try {
                await axiosInstance.put(`/enable/smartmatchrecruiter/`, {
                    isSmartMatchEnabled: isSmartMatchEnabled,
                }, {
                    headers: { Authorization: `Bearer ${token}` },
                });
            } catch (error) {
                console.error('Error updating SmartMatch status:', error);
            }
        };

        updateSmartMatchStatus();
    }, [isSmartMatchEnabled, token]);

    const handleSmartMatchToggle = () => {
        setIsSmartMatchEnabled(!isSmartMatchEnabled);
    };

    const handleUpgradeClick = async (newLevel) => {
        console.log(`Upgrading to ${newLevel}...`);

        // Define the price IDs for your Stripe products
        const priceIds = {
            'basic': 'price_1OUKnDEDfGoNCpuz32NKGcT8',

            'premium': 'price_1OUKnTEDfGoNCpuzR4yBZP4P',
        };

        // Get the price ID for the selected subscription level
        const priceId = priceIds[newLevel];

        if (!priceId) {
            console.error('Invalid subscription level');
            return;
        }

        try {
            // Call your backend to create the subscription
            const subscriptionResponse = await axiosInstance.post('talent/create-subscription', {
                priceId: priceId,
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });

            if (subscriptionResponse.status === 200) {
                console.log('Subscription created successfully:', subscriptionResponse.data);

                // Update job seeker's subscription level and job matching stats in your local state
                setSubscriptionLevel(newLevel);

                // Update the subscription level in the job seeker's profile
                const profileResponse = await axiosInstance.put(`/smartmatchrecruiter/`, {
                    subscriptionLevel: newLevel,
                }, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                if (profileResponse.status === 200) {
                    console.log('User profile updated successfully:', profileResponse.data);
                    setJobMatchingStats(profileResponse.data.jobMatchingStats);
                } else {
                    console.error('Failed to update user profile');
                }
            } else {
                console.error('Failed to create subscription');
            }
        } catch (error) {
            console.error('Error during subscription process:', error);
            if (error.response && error.response.status === 402) {
                toast({
                    title: "Action needed",
                    description: "Please add a card",
                    status: "warning",
                    duration: 9000,
                    isClosable: true,
                    position: "bottom",
                });
            } else {
                toast({
                    title: "Error",
                    description: "An error occurred. Please try again.",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                    position: "bottom",
                });
            }
        }
    };

    console.log("Emailed Job IDs:", jobMatchingStats.emailedJobIds);

    return (
        <Box w={"100%"}>
            <Flex w={"100%"} justify={"flex-start"}>
            <Flex border={"1px"} shadow={"md"} _hover={{bg: isSmartMatchEnabled ? 'green.300' : 'gray.300'}} borderColor={"gray.200"} color={isSmartMatchEnabled ? 'green' : 'blackAlpha.600'} cursor="pointer" onClick={handleSmartMatchToggle} p={2} bg={isSmartMatchEnabled ? 'green.200' : 'gray.200'} borderRadius={"lg"} w={isSmartMatchEnabled ? '100%' : "fit-content"} justify={"center"} align="center" mb={4}>
                <Icon
                    as={FontAwesomeIcon}
                    icon={faPowerOff}
                    color={isSmartMatchEnabled ? '#02bf01' : 'blackAlpha.300'}
                    boxSize={6} 
                    mr={2}
                />
                {isSmartMatchEnabled ? (
                    <Text fontSize="xs" fontWeight="semibold">We are hard at work finding the best job openings for you!</Text>
                ) : (
                    <Text fontSize="lg" fontWeight="bold" mr={2}>Off</Text>
                )}
            </Flex>
            </Flex>
            {isSmartMatchEnabled && (<>
                {subscriptionLevel === 'free' && (
                    <>
                        <Box w={"100%"} bg={"white"} p={4} overflow="hidden">
                            <SimpleGrid columns={{ sm: 1, md: 2, lg: 4 }} spacing={8}>
                                <Stat textAlign="center">
                                    <Tooltip label="Last date scanned">
                                        <Box>
                                            <Icon as={FaRegChartBar} w={8} h={8} color="#01BF02" />
                                            <StatNumber fontSize="sm">
                                                {jobMatchingStats?.lastScanDate
                                                    ? new Date(jobMatchingStats.lastScanDate).toLocaleDateString()
                                                    : 'N/A'}
                                            </StatNumber>
                                        </Box>
                                    </Tooltip>
                                </Stat>
                                <Stat textAlign="center">
                                    <Tooltip label="Employers we have told you about.">
                                        <Box>
                                            <Icon as={FaPaperPlane} w={8} h={8} color="#EF476F" />
                                            <StatNumber fontSize="sm">
                                                {jobMatchingStats?.jobsEmailed ?? 'N/A'}
                                            </StatNumber>
                                        </Box>
                                    </Tooltip>
                                </Stat>
                                <Stat textAlign="center">
                                    <Tooltip label="Jobs deeply analyzed by our AI">
                                        <Box>
                                            <Icon as={FaUserTie} w={8} h={8} color="#118AB2" />
                                            <StatNumber fontSize="sm">
                                                {jobMatchingStats?.jobsMeetingAIThreshold ?? 'N/A'}
                                            </StatNumber>
                                        </Box>
                                    </Tooltip>
                                </Stat>
                                <Stat textAlign="center">
                                    <Tooltip label="Total jobs scanned">
                                        <Box>
                                            <Icon as={FaDatabase} w={8} h={8} color="#FFD166" />
                                            <StatNumber fontSize="sm">
                                                {jobMatchingStats?.totalScannedJobs ?? 'N/A'}
                                            </StatNumber>
                                        </Box>
                                    </Tooltip>
                                </Stat>
                            </SimpleGrid>
                            {jobMatchingStats?.emailedJobs && jobMatchingStats.emailedJobs.length > 0 && (
                                <VStack maxH={"400px"} overflowY={"scroll"} mt={4} spacing={4} align="stretch">
                                    <Text fontSize="lg" fontWeight="semibold">
                                        Introductions Sent For:
                                    </Text>
                                    <SimpleGrid maxH={"300px"} overflowY={"scroll"} columns={{ sm: 1, md: 2, lg: 4 }} spacing={4}>
                                        {jobMatchingStats.emailedJobs.map((job) => (
                                            <Flex
                                                key={job.jobId}
                                                p={1}
                                                borderWidth="1px"
                                                borderRadius="md"
                                                justify={"center"} align="center"
                                                boxShadow="lg"
                                                bg={"white"}
                                                _hover={{ bg: '#01BF02', color: 'white', cursor: 'pointer' }}
                                                onClick={() => navigate(`/job/${job.jobId}`)}
                                            >
                                                <Flex direction={"column"} w={"fit-content"} justify={"center"} align="center">
                                                    <Text fontWeight={"medium"} fontSize={"sm"} >{job.jobTitle}</Text>
                                                </Flex>
                                            </Flex>
                                        ))}
                                    </SimpleGrid>
                                </VStack>
                            )}
                        </Box>
                    </>
                )}

                {/* Show upgrade options if on 'free' subscription */}
                {subscriptionLevel !== 'free' && (
                    <Box w={"100%"} bg="black" p={4} borderWidth="1px" borderRadius="2xl" overflow="hidden">
                        <Text fontSize="xl" mb={3} color="white" fontWeight="normal">
                            Sign up with JobJar's SmartMatch Recruiter, and take the work out of job searching. We'll find top jobs and set up your interviews.
                        </Text>
                        <Flex mb={3} p={3} w={"100%"} align={"center"} justify={"space-evenly"}>
                            <FontAwesomeIcon color='#01BF02' size='2xl' icon={faSearchengin} />
                            <FontAwesomeIcon color='white' icon={faArrowRightLong} />
                            <FontAwesomeIcon color='#01BF02' size='2xl' icon={faCodeCompare} />
                            <FontAwesomeIcon color='white' icon={faArrowRightLong} />
                            <FontAwesomeIcon color='#01BF02' size='2xl' icon={faEnvelopeCircleCheck} />
                        </Flex>
                        <Flex direction={{ base: "column", md: "row" }} w="100%" justify="space-between">
                            <Box mr={2} mb={3} p={5} shadow="md" bg="white" borderWidth="1px" flex="1" borderRadius="md" display="flex" flexDirection="column">
                                <Heading mb={4}>$5/month</Heading>
                                <Text fontSize="lg" mb={2} fontWeight="bold">Steady (Basic)</Text>
                                <UnorderedList mb={3}>
                                    <ListItem>Our AI seamlessly matches you with ideal jobs and initiates employer connections—effortlessly, every week.</ListItem>
                                </UnorderedList>
                                <Spacer />
                                <Button mt="auto" bg="#118AB2" color="white" _hover={{ transform: "scale(1.05)" }} w="full" size="lg" onClick={() => handleUpgradeClick('basic')}>
                                    Start with Basic
                                </Button>
                            </Box>

                            <Box ml={2} mb={3} p={5} shadow="md" bg="white" borderWidth="1px" flex="1" borderRadius="md">
                                <Heading mb={4}>$28/month</Heading>
                                <Text fontSize="lg" mb={2} fontWeight="bold">Aggressive (Premium)</Text>
                                <UnorderedList mb={3}>
                                    <ListItem>Daily AI-powered scans ensure you're the first to know about the latest job openings, giving you a competitive edge in your job search.</ListItem>
                                </UnorderedList>
                                <Spacer />
                                <Button mt="auto" bg="#FFD166" color="black" _hover={{ transform: "scale(1.05)" }} w="full" size="lg" onClick={() => handleUpgradeClick('premium')}>
                                    Go Premium
                                </Button>
                            </Box>
                        </Flex>
                    </Box>
                )}
            </>)}
        </Box>
    );
};

export default SmartMatchAi;