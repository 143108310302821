import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Box, Button, Center, Flex, HStack, Heading, IconButton, Progress, Spinner, Text, VStack } from '@chakra-ui/react';
import WaveSurfer from 'wavesurfer.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faEllipsis, faMicrophone, faPaperPlane, faPlay, faStop } from '@fortawesome/free-solid-svg-icons';
import { color } from 'framer-motion';
import CustomAudioPlayer from './CustomerAudioPlayer';

const OscillatingEffect = ({ audioSource, isPlaying, onPlayEnd }) => {
    const waveformRef = useRef(null);
    const wavesurferRef = useRef(null);

    useEffect(() => {
        // Only proceed if there's a waveform container and an audio source.
        if (!waveformRef.current || !audioSource) return;

        // Function to initialize WaveSurfer
        const createWaveSurfer = () => {
            const wavesurfer = WaveSurfer.create({
                container: waveformRef.current,
                waveColor: 'black',
                progressColor: '#01bf02',
                cursorColor: 'darkgray',
                barWidth: 3,
                barRadius: 10,
                responsive: true,
                height: 75,
                normalize: true,
                partialRender: false,
                backend: 'WebAudio',
            });

            // When WaveSurfer is ready, check if it should play immediately.
            wavesurfer.on('ready', () => {

                if (isPlaying) {

                    wavesurfer.play();
                }
            });

            // Handle the finish event.
            wavesurfer.on('finish', () => {

                onPlayEnd();
            });

            return wavesurfer;
        };

        // Initialize WaveSurfer if it's not already.
        if (!wavesurferRef.current) {
            wavesurferRef.current = createWaveSurfer();
        }

        // Load the audio source into WaveSurfer.

        wavesurferRef.current.load(audioSource);

        // Cleanup function to destroy WaveSurfer instance when component unmounts.
        return () => {
            if (wavesurferRef.current) {

                wavesurferRef.current.destroy();
                wavesurferRef.current = null;
            }
        };
    }, [audioSource, isPlaying]);

    // Effect to handle play/pause based on isPlaying prop.
    useEffect(() => {
        if (wavesurferRef.current) {

            if (isPlaying) {
                wavesurferRef.current.play();
            } else {
                wavesurferRef.current.pause();
            }
        }
    }, [isPlaying]);

    return <div id="waveform" ref={waveformRef}></div>;
};

const AudioRecorder = ({ interviewId, interview, onGeneratedSpeechURLChange }) => {
    const [isRecording, setIsRecording] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isThinking, setIsThinking] = useState(false);
    const [audioBlob, setAudioBlob] = useState(null); // Store the audio blob
    const [audioURL, setAudioURL] = useState('');
    const [originalAudioURL, setOriginalAudioURL] = useState('');
    const [generatedSpeechURL, setGeneratedSpeechURL] = useState('');
    const [interviewResponse, setInterviewResponse] = useState('');
    const [isFinished, setIsFinished] = useState(false);
    const [uploading, setUploading] = useState(false);

    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);

    // Start recording
    const startRecording = async () => {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            alert('Media devices not supported');
            return;
        }
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.ondataavailable = (event) => {
            audioChunksRef.current.push(event.data);
        };
        mediaRecorderRef.current.onstop = () => {
            const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
            const audioUrl = URL.createObjectURL(audioBlob);
            setAudioBlob(audioBlob);
            setAudioURL(audioUrl);
            audioChunksRef.current = [];
        };
        mediaRecorderRef.current.start();
        setIsRecording(true);
    };

    const stopRecording = () => {
        mediaRecorderRef.current.stop();
        setIsRecording(false);
    };

    const sendAnswer = () => {
        setIsThinking(true);
        if (audioBlob) {
            uploadAudio(audioBlob, interviewId);
        }
    };

    const uploadAudio = async (audioBlob, interviewId) => {
        setUploading(true);
        const formData = new FormData();
        formData.append('audio', audioBlob, 'audio.wav');
        formData.append('interviewId', interviewId);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/upload-audio`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            if (response.data.originalAudioUrl) {
                setOriginalAudioURL(response.data.originalAudioUrl);
            }
            if (response.data.generatedSpeechUrl) {
                setGeneratedSpeechURL(response.data.generatedSpeechUrl);
                onGeneratedSpeechURLChange(response.data.generatedSpeechUrl);
                setIsThinking(false);
            }
            if (response.data.interviewResponse) {
                setInterviewResponse(response.data.interviewResponse);
            }
            if (response.data.interviewFinished) {
                setIsFinished(response.data.interviewFinished);
            }
        } catch (error) {
            console.error('Error uploading audio file:', error);
        } finally {
            setUploading(false);
        }
    };

    const playAudio = () => {
        console.log("playAudio called");
        if (generatedSpeechRef.current) {

            generatedSpeechRef.current.play()
                .then(() => {

                    setIsPlaying(true); // Ensure this is set to true
                })
                .catch(err => console.error('Error playing the audio:', err));
        } else {
            console.log("generatedSpeechRef is not defined");
        }
    };

    const stopAudio = () => {
        if (generatedSpeechRef.current) {
            generatedSpeechRef.current.pause();
            setIsPlaying(false); // Ensure this is set to false
        }
    };

    useEffect(() => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.onstop = () => {
                const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
                const audioUrl = URL.createObjectURL(audioBlob);
                setAudioBlob(audioBlob); // Set the audio blob here
                setAudioURL(audioUrl);
                audioChunksRef.current = [];
            };
        }
    }, [mediaRecorderRef.current]);

    const generatedSpeechRef = useRef(null);

    useEffect(() => {
        if (generatedSpeechURL) {
            setIsThinking(false);
            playAudio();
        }
    }, [generatedSpeechURL]);

    useEffect(() => {
        if (generatedSpeechURL && generatedSpeechRef.current) {
            setIsThinking(false);
            playAudio(); // This will also set isPlaying to true
        }
    }, [generatedSpeechURL]);

    useEffect(() => {
        if (generatedSpeechRef.current) {
            generatedSpeechRef.current.onended = () => {
                setIsPlaying(false);
            };
        }
    }, []);

    const handlePlayEnd = () => {
        setIsPlaying(false);
    };

    return (
        <Flex direction={"column"} w={"100%"}>
            {!isFinished && (
                <Flex w={"100%"} justify={"center"} direction={"column"}>
                    <Flex w={"100%"} justify={"center"} direction={"row"}>
                        <HStack bg={"white"} shadow={"lg"} p={2} borderRadius={"md"} border={"1px"} w={"100%"} justify={"space-between"}>
                            <Flex justify={"flex-start"} align={"center"}>
                                <IconButton
                                    icon={isRecording ? <FontAwesomeIcon icon={faStop} /> : <FontAwesomeIcon icon={faMicrophone} />}
                                    color="white"
                                    m={2}
                                    _hover={{ bg: "black" }}
                                    borderRadius="full"
                                    bg="#01bf02"
                                    onClick={isRecording ? stopRecording : startRecording}
                                    aria-label={isRecording ? "Stop Recording" : "Start Recording"}
                                />

                                {isRecording ? <Text>Stop Recording</Text> : <Text>Start Recording</Text>}
                            </Flex>

                            {audioURL && (
                                <>
                                    <Flex>
                                        <FontAwesomeIcon color='#01bf02' size='lg' icon={faEllipsis} />
                                        <FontAwesomeIcon color='#01bf02' size='lg' icon={faChevronRight} />
                                    </Flex>
                                    <Flex align={"center"} justify={"center"}>
                                        <Text mr={2}>Your Recorded Audio:</Text>
                                        <CustomAudioPlayer audioURL={audioURL} />
                                    </Flex>
                                </>
                            )}
                            {!isFinished && (
                                <>
                                    {!isRecording && (
                                        <>
                                            <Flex>
                                                <FontAwesomeIcon color='#01bf02' size='lg' icon={faEllipsis} />
                                                <FontAwesomeIcon color='#01bf02' size='lg' icon={faChevronRight} />
                                            </Flex>
                                            <Flex mr={2} justify={"flex-end"} align={"center"}>


                                                <IconButton
                                                    icon={<FontAwesomeIcon icon={faPaperPlane} />}
                                                    color="white"
                                                    m={2}
                                                    _hover={{ bg: "black" }}
                                                    borderRadius="full"
                                                    bg="#01bf02"
                                                    onClick={sendAnswer}
                                                    aria-label="Send Answer"
                                                />
                                                <Text>Send when ready</Text>
                                            </Flex>
                                        </>
                                    )}
                                </>
                            )}
                        </HStack>
                    </Flex>

                    <Flex mt={4} justify={"center"}>
                        {interview?.questions?.length === 0 && (
                            <Text fontWeight={"semibold"}>Please record a brief introduction.</Text>
                        )}
                    </Flex>


                </Flex>
            )}
            {!isFinished && (<> {
                (isThinking || uploading) && (
                    <Progress m={4} borderRadius={"full"} colorScheme='black' bg={"#01bf02"} size='xs' isIndeterminate />
                )}
            </>
            )}
            {
                generatedSpeechURL && (
                    <Flex direction={"column"}>
                        <OscillatingEffect
                            audioSource={generatedSpeechURL}
                            isPlaying={isPlaying}
                            onPlayEnd={handlePlayEnd}
                        />
                        <Flex w={"100%"} align={"center"} justify={"flex-end"}>
                            <IconButton
                                icon={isPlaying ? <FontAwesomeIcon icon={faStop} /> : <FontAwesomeIcon icon={faPlay} />}
                                color="white"
                                m={2}
                                size={"sm"}
                                w={"20px"}
                                _hover={{ bg: "black" }}
                                borderRadius="full"
                                bg="#01bf02"
                                onClick={isPlaying ? stopAudio : playAudio}>
                                {isPlaying ? "Stop" : "Play Audio"}
                            </IconButton>
                        </Flex>
                        <audio muted ref={generatedSpeechRef} src={generatedSpeechURL} preload="auto" />
                        <Text>{interviewResponse}</Text>
                    </Flex>
                )
            }
            {!isFinished && (<> {
                (isThinking || uploading) && (
                    <Progress m={4} borderRadius={"full"} colorScheme='black' bg={"#01bf02"} size='xs' isIndeterminate />
                )}
            </>
            )}
        </Flex >
    );
};

export default AudioRecorder;
