import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Card, CardBody, Text } from "@chakra-ui/react";

export const handleAddPoints = async (pointsToAdd, setPoints) => {
    const token = localStorage.getItem('auth_token');
    try {
        const { data } = await axios.post(process.env.REACT_APP_API_URL + '/api/addPoints', { pointsToAdd }, {
            headers: { Authorization: `Bearer ${token}` }
        });
        setPoints(data.newPoints);
    } catch (error) {
        console.error('Error adding points:', error);
    }
};

const PointsComponent = () => {
    const [points, setPoints] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('auth_token');
            try {
                const res = await axios.get(process.env.REACT_APP_API_URL + '/api/getPoints', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setPoints(res.data.points);
            } catch (err) {
                console.error('Error fetching points:', err);
            }
        };
        fetchData();
    }, []);

    const handleRedeem = async () => {
        const token = localStorage.getItem('auth_token');
        try {
            await axios.post(process.env.REACT_APP_API_URL + '/api/redeemPoints', {}, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setPoints(0);
        } catch (error) {
            console.error('Error redeeming points:', error);
        }
    };

    return (
        <div>
            <Card>
                <CardBody>
                    <Text>Current Points: {points}</Text>
                    <Button onClick={() => handleAddPoints(1, setPoints)}>Apply for a Job (+1)</Button>
                    <Button onClick={() => handleAddPoints(5, setPoints)}>Take a Survey (+5)</Button>
                    <Button onClick={() => handleAddPoints(10, setPoints)}>Win an Interview (+10)</Button>
                    <Button onClick={() => handleAddPoints(10, setPoints)}> SignUp (+10)</Button>
                    {points >= 100 && <button onClick={handleRedeem}>Redeem Points</button>}
                </CardBody>
            </Card>
        </div>
    );
};

export default PointsComponent;
