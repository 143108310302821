import {
    Box,
    Heading,
    Text,
    Button,
    Container,
    SimpleGrid,
    Image,
    Stack,
    Flex,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    useColorModeValue,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    HStack,
    Input,
    Select,
    Icon,
    VStack,
    IconButton,
    AvatarGroup,
    Avatar,
    AvatarBadge,
    Center,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react";
import { FaFacebook, FaTwitter, FaLinkedin, FaPlayCircle, FaExternalLinkAlt, FaFacebookF, FaLinkedinIn, FaUserPlus, FaHandshake, FaFileUpload, FaSearch, FaUserCheck, FaSlidersH, FaThumbsUp } from "react-icons/fa";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faFacebookF, faLinkedinIn, faSquareXTwitter, faSquareFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import React from "react";
import Logo from "../../assets/blacklogo.png";
import { faArrowRight, faArrowRightLong, faArrowRightToBracket, faBriefcase, faBusinessTime, faDollarSign, faHandHoldingDollar, faHandshakeAlt, faLocationDot, faMagnifyingGlass, faPeopleGroup, faSquareCheck, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import TopWoman from '../../assets/photo-of-focused-beautiful-woman-holding-and-using-2023-11-27-05-19-25-utc.webp';
import Brunette from '../../assets/BrunetteBusinessWomen.png';
import Ellipse from '../../assets/Ellipse 4.png';
import Polygon from '../../assets/Polygon 2.png';
import { faHandshake } from "@fortawesome/free-regular-svg-icons";
import DirectResumeUpload from "../../components/DirectResumeUpload";
import TopPageAsset from "../../assets/asui.webp";
import SectionContainer from "../../assets/Container.webp";
import PBSQ from "../../assets/publicSquare.png";

function HowItWorksSection() {
    const steps = [
        {
            icon: FaFileUpload,
            title: 'Upload Your Resume',
            description: 'Start by simply uploading your most recent resume.'
        },
        {
            icon: FaUserCheck,
            title: 'Verify Information',
            description: 'Verify your details to ensure all your credentials are up to date.'
        },
        {
            icon: FaSlidersH,
            title: 'Set Job Preferences',
            description: 'Customize your job search with preferences that fit your career goals.'
        },
        {
            icon: FaThumbsUp,
            title: 'Get Offers',
            description: 'Review interview invitations and choose the ones that excite you.'
        }
    ];


    return (
        <Flex direction="column" align="center" borderRadius={"xl"} justify="center" h={"fit-content"} w="fit-content" bg="whiteAlpha.700" p={4}>
            <Heading mb={4} color="black">
                How It Works
            </Heading>
            <Stack direction={{ base: 'column', md: 'row' }} spacing={6} justify="center">
                {steps.map((step, index) => (
                    <Box key={index} p={2} shadow="md" borderWidth="1px" borderRadius="lg" bg="white">
                        <Flex direction="column" align="center" justify="center">
                            <Icon as={step.icon} w={10} h={10} color="bright yellow/orange" mb={2} />
                            <Text fontWeight="bold" mb={2}>{step.title}</Text>
                            <Text textAlign="center">{step.description}</Text>
                        </Flex>
                    </Box>
                ))}
            </Stack>
        </Flex>
    );
}

const MarketingPage = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [testimonials, setTestimonials] = useState();
    const MotionButton = motion(Button);
    const bg = useColorModeValue("white", "gray.800");
    const videoId = 'OxCcfEjlDUg';
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch testimonials data when the component mounts
        const loadTestimonials = async () => {
            const fetchedTestimonials = await fetchTestimonials();
            if (fetchedTestimonials) {
                setTestimonials(fetchedTestimonials);
            }
        };

        loadTestimonials();
    }, []);

    // Define a mock function to simulate fetching data from an API
    const fetchTestimonials = async () => {
        try {
            // Replace this URL with the actual endpoint from which you want to fetch data
            const response = await fetch('https://api.yoursite.com/testimonials');
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            return data; // This should be an array of testimonial objects
        } catch (error) {
            console.error("Could not fetch testimonials: ", error);
        }
    };

    useEffect(() => {
        fetchTestimonials().then(data => setTestimonials(data));
    }, []);

    const handleGetStartedClick = () => {
        navigate('/user-signup'); 
    };

    const handleLoginClick = () => {
        navigate('/user-login');
    };

    return (
        <Flex p={4} bg={"black"} direction={"column"} align={"center"} justify={"center"} w={"100%"}>
            <Flex direction={"column"} align={"center"} justify={"center"} w={"80%"}>
                <Flex align={"flex-start"} bgImage={TopPageAsset} bgSize="cover"
                    bgRepeat="no-repeat" borderRadius={"2xl"} direction={"column"} w={"100%"}>
                    <Flex justify={"flex-end"} align={"center"} h={"100px"} w={"100%"}>
                        <Image ml={8} mr={8} h={"fit-content"} w={"275px"} src={Logo} />
                        <HStack m={2} w={"100%"} justify={"flex-end"}>
                            <Link to="/signup"><Text fontWeight={"semibold"} m={4} as="span" cursor="pointer" _hover={{ textDecoration: "underline" }}>Job Seekers</Text></Link>
                            <Link to="/signup"><Text fontWeight={"semibold"} m={4} as="span" cursor="pointer" _hover={{ textDecoration: "underline" }}>Employers</Text></Link>
                            <Link to="/signup"><Text fontWeight={"semibold"} m={4} as="span" cursor="pointer" _hover={{ textDecoration: "underline" }}>Freelance Recruiters</Text></Link>
                            <Link to="/signup"><Text fontWeight={"semibold"} m={4} as="span" cursor="pointer" _hover={{ textDecoration: "underline" }}>Support</Text></Link>
                            <Link to="/signup"><Text fontWeight={"semibold"} m={4} as="span" cursor="pointer" _hover={{ textDecoration: "underline" }}>Jobs</Text></Link>
                            <Link to="/signup"><Text fontWeight={"semibold"} m={4} as="span" cursor="pointer" _hover={{ textDecoration: "underline" }}>SmartMatch Recruiter</Text></Link>
                        </HStack>
                        <Flex justify={"flex-end"} w={"50%"}>
                            <Flex>
                                <Button variant={"ghost"} m={2} onClick={handleLoginClick}>
                                    <FontAwesomeIcon color={"black"} icon={faArrowRightToBracket} />
                                    <Text ml={2} color={"black"}>Sign In</Text>
                                </Button>
                                <Button bg={"#01bf02"} _hover={{ bg: "black" }} m={2} onClick={handleGetStartedClick}>
                                    <FontAwesomeIcon color={"white"} icon={faBriefcase} />
                                    <Text ml={2} color={"white"}>Post Job</Text>
                                </Button>
                            </Flex>
                        </Flex>
                    </Flex>
                    {/* Header Section */}
                    <Flex mt={8} w={"100%"} align={"flex-start"} justify={"space-between"}>
                        <Box w={"50%"} p={16} textAlign="left">
                            <Flex justify={"space-between"}>
                                <Text ml={4} color={"#01BF02"} fontSize={"md"} fontWeight={"semibold"}>DISCOVER JOBS</Text>
                                <Image h={"15px"} src={Polygon} />
                            </Flex>
                            <Box ml={4} w={"100%"}>
                                <Heading color="black">Upload, Set Interests & </Heading>
                                <Flex>
                                    <Heading mr={2} color="black">Get Your</Heading><Heading color={"#01BF02"}>Dream Job</Heading>
                                </Flex>
                            </Box>
                            <Text ml={4}>
                                At JobJar, your skills and hard work define your opportunities.
                                Discover roles where your talents are valued and rewarded.
                            </Text>
                            <Flex ml={8} bg={"white"} p={2} borderRadius={"md"} w={"fit-content"} shadow={"lg"} justify={"space-between"} mt={4} alignItems="center">
                                <Flex>
                                    <Input m={1} w={"200px"} placeholder="Job Title" size="md" />
                                </Flex>
                                <Flex>
                                    <Select
                                        m={1}
                                        placeholder="Location"
                                        size="md"
                                        w="200px"
                                        icon={<FontAwesomeIcon size="sm" icon={faLocationDot} />}
                                    >
                                        <option>Alabama</option>
                                        <option>Alaska</option>
                                        <option>Arizona</option>
                                        <option>Arkansas</option>
                                        <option>California</option>
                                        <option>Colorado</option>
                                        <option>Connecticut</option>
                                        <option>Delaware</option>
                                        <option>Florida</option>
                                        <option>Georgia</option>
                                        <option>Hawaii</option>
                                        <option>Idaho</option>
                                        <option>Illinois</option>
                                        <option>Indiana</option>
                                        <option>Iowa</option>
                                        <option>Kansas</option>
                                        <option>Kentucky</option>
                                        <option>Louisiana</option>
                                        <option>Maine</option>
                                        <option>Maryland</option>
                                        <option>Massachusetts</option>
                                        <option>Michigan</option>
                                        <option>Minnesota</option>
                                        <option>Mississippi</option>
                                        <option>Missouri</option>
                                        <option>Montana</option>
                                        <option>Nebraska</option>
                                        <option>Nevada</option>
                                        <option>New Hampshire</option>
                                        <option>New Jersey</option>
                                        <option>New Mexico</option>
                                        <option>New York</option>
                                        <option>North Carolina</option>
                                        <option>North Dakota</option>
                                        <option>Ohio</option>
                                        <option>Oklahoma</option>
                                        <option>Oregon</option>
                                        <option>Pennsylvania</option>
                                        <option>Rhode Island</option>
                                        <option>South Carolina</option>
                                        <option>South Dakota</option>
                                        <option>Tennessee</option>
                                        <option>Texas</option>
                                        <option>Utah</option>
                                        <option>Vermont</option>
                                        <option>Virginia</option>
                                        <option>Washington</option>
                                        <option>West Virginia</option>
                                        <option>Wisconsin</option>
                                        <option>Wyoming</option>

                                    </Select>
                                </Flex>
                                <Flex>
                                    <Select
                                        m={1}
                                        placeholder="Employment Type"
                                        size="md"
                                        w="200px"
                                        icon={<FontAwesomeIcon size="sm" icon={faBusinessTime} />}
                                    >
                                        <option>Full Time</option>
                                        <option>Part Time</option>
                                        <option>Contract</option>
                                    </Select>
                                </Flex>
                                <Flex>
                                    <Button size="md" color={"white"} m={1} bg={"#01BF02"} _hover={{ bg: "black" }} leftIcon={<FontAwesomeIcon icon={faMagnifyingGlass} />}>
                                        SEARCH JOB
                                    </Button>
                                </Flex>
                            </Flex>
                            <Image m={8} h={"20px"} src={Ellipse} />
                            <Flex m={8} w={"100%"} justify={"center"}>
                                <Text mr={2} fontWeight={'semibold'}>
                                    Popular Searches:
                                </Text>
                                <Text>
                                    Web, App, Software, Designer
                                </Text>
                            </Flex>
                            <Flex m={8} align={"flex-start"} justify={"space-evenly"} w={"100%"}>
                                <Flex>
                                    <AvatarGroup size='md' max={7}>
                                        <Avatar name='Dan Abrahmov' src='https://bit.ly/dan-abramov'><AvatarBadge boxSize='0.80em' bg='#01bf02' /></Avatar>
                                        <Avatar name='Kola Tioluwani' src='https://bit.ly/tioluwani-kolawole'><AvatarBadge boxSize='0.80em' bg='#01bf02' /></Avatar>
                                        <Avatar name='Segun Adebayo' src='https://bit.ly/sage-adebayo'><AvatarBadge boxSize='0.80em' bg='#01bf02' /></Avatar>
                                        <Avatar name='Kent Dodds' src='https://bit.ly/kent-c-dodds'><AvatarBadge boxSize='0.80em' bg='#01bf02' /></Avatar>
                                        <Avatar name='Ryan Florence' src='https://bit.ly/ryan-florence'><AvatarBadge boxSize='0.80em' bg='#01bf02' /></Avatar>
                                        <Avatar name='Prosper Otemuyiwa' src='https://bit.ly/prosper-baba'><AvatarBadge boxSize='0.80em' bg='red.300' /></Avatar>
                                        <Avatar name='Christian Nwamba' src='https://bit.ly/code-beast'><AvatarBadge boxSize='0.80em' bg='#01bf02' /></Avatar>
                                        <Avatar name='Segun Adebayo' src='https://bit.ly/sage-adebayo' />
                                        <Avatar name='Kent Dodds' src='https://bit.ly/kent-c-dodds' />
                                        <Avatar name='Prosper Otemuyiwa' src='https://bit.ly/prosper-baba' />
                                        <Avatar name='Ryan Florence' src='https://bit.ly/ryan-florence' />
                                        <Avatar name='Dan Abrahmov' src='https://bit.ly/dan-abramov' />
                                        <Avatar name='Kola Tioluwani' src='https://bit.ly/tioluwani-kolawole' />
                                        <Avatar name='Segun Adebayo' src='https://bit.ly/sage-adebayo' />
                                        <Avatar name='Kent Dodds' src='https://bit.ly/kent-c-dodds' />
                                        <Avatar name='Ryan Florence' src='https://bit.ly/ryan-florence' />
                                        <Avatar name='Prosper Otemuyiwa' src='https://bit.ly/prosper-baba' />
                                        <Avatar name='Christian Nwamba' src='https://bit.ly/code-beast' />
                                        <Avatar name='Segun Adebayo' src='https://bit.ly/sage-adebayo' />
                                        <Avatar name='Kent Dodds' src='https://bit.ly/kent-c-dodds' />
                                        <Avatar name='Prosper Otemuyiwa' src='https://bit.ly/prosper-baba' />
                                        <Avatar name='Ryan Florence' src='https://bit.ly/ryan-florence' />
                                        <Avatar name='Dan Abrahmov' src='https://bit.ly/dan-abramov' />
                                        <Avatar name='Kola Tioluwani' src='https://bit.ly/tioluwani-kolawole' />
                                        <Avatar name='Segun Adebayo' src='https://bit.ly/sage-adebayo' />
                                        <Avatar name='Kent Dodds' src='https://bit.ly/kent-c-dodds' />
                                        <Avatar name='Ryan Florence' src='https://bit.ly/ryan-florence' />
                                        <Avatar name='Prosper Otemuyiwa' src='https://bit.ly/prosper-baba' />
                                        <Avatar name='Christian Nwamba' src='https://bit.ly/code-beast' />
                                        <Avatar name='Segun Adebayo' src='https://bit.ly/sage-adebayo' />
                                        <Avatar name='Kent Dodds' src='https://bit.ly/kent-c-dodds' />
                                        <Avatar name='Prosper Otemuyiwa' src='https://bit.ly/prosper-baba' />
                                        <Avatar name='Ryan Florence' src='https://bit.ly/ryan-florence' />
                                    </AvatarGroup>
                                </Flex>
                                <Flex>
                                    <DirectResumeUpload />
                                </Flex>
                            </Flex>
                        </Box>
                        <Flex align={"flex-end"} justify={"flex-end"} w={"75%"}>
                            <Image ml={8} h={"fit-content"} w={"1200px"} src={TopWoman} />
                        </Flex>
                    </Flex>
                </Flex>
                <Flex
                    m={8}
                    p={{ base: 4, md: 8 }}
                    align="center"
                    bgImage={SectionContainer}
                    bgPosition="center"
                    bgSize="cover"
                    bgRepeat="no-repeat"
                    borderRadius="2xl"
                    direction="column"
                    justify="center"
                    w="100%"
                >
                    <Flex w={"100%"} align={"center"} justify={"space-between"}>
                        <Box bg={"whiteAlpha.900"} borderRadius={"lg"} w={"50%"} m={4} p={4} textAlign="left">
                            <Flex justify={"space-between"}>
                                <Text ml={4} color={"#01BF02"} fontSize={"md"} fontWeight={"semibold"}>ADVANCED AI RECRUITER</Text>
                                <Image h={"15px"} src={Polygon} />
                            </Flex>
                            <Box ml={4} w={"100%"}>
                                <Flex>
                                    <Heading color="black">Smart</Heading><Heading color={"#01BF02"}>Match</Heading>
                                </Flex>
                                <Heading color={"black"}>Passive Recruiter</Heading>
                            </Box>
                            <Text color={"black"} ml={4}>
                                Tailored Job Matches. Effortless Application. Unparalleled Support.
                                At JobJar, your skills and hard work define your opportunities.
                                Discover roles where your talents are valued and rewarded.
                            </Text>
                            <Flex w={"100%"} justify={"flex-end"}>
                                <Button onClick={handleGetStartedClick} _hover={{ bg: "#01bf02" }} bg={"black"} m={4}>
                                    <Text mr={2} color={"white"}>Get Started</Text><FontAwesomeIcon color={"white"} icon={faArrowRight} />
                                </Button>
                            </Flex>
                            <Image m={2} h={"20px"} src={Ellipse} />
                        </Box>
                        <HowItWorksSection />
                    </Flex>
                </Flex>
                <Flex mt={8} mb={8} w={"100%"} justify={"center"}>
                    <Flex w={"80%"} justify={"space-between"}>
                        <Flex justify={"center"} align={"center"} p={2} borderRadius={"xl"} shadow={"lg"} bg={"white"}>
                            <Flex mr={2} justify={"center"} align={"center"} h={"50px"} w={"50px"} borderRadius={"xl"} bg={"green.200"}><FontAwesomeIcon size="xl" color="#01BF02" icon={faDollarSign} /></Flex> <Text fontWeight={"semibold"}>Post Jobs for Free</Text>
                        </Flex>
                        <Flex justify={"center"} align={"center"} p={2} borderRadius={"xl"} shadow={"lg"} bg={"white"}>
                            <Flex mr={2} justify={"center"} align={"center"} h={"50px"} w={"50px"} borderRadius={"xl"} bg={"yellow.400"}><FontAwesomeIcon size="xl" color="lightyellow" icon={faSquareCheck} /></Flex> <Text fontWeight={"semibold"}>Advanced Artificial Intelligence</Text>
                        </Flex>
                        <Flex justify={"center"} align={"center"} p={2} borderRadius={"xl"} shadow={"lg"} bg={"white"}>
                            <Flex mr={2} justify={"center"} align={"center"} h={"50px"} w={"50px"} borderRadius={"xl"} bg={"red.300"}><FontAwesomeIcon size="xl" color="white" icon={faPeopleGroup} /></Flex> <Text fontWeight={"semibold"}>Applicant Management</Text>
                        </Flex>
                        <Flex justify={"center"} align={"center"} p={2} borderRadius={"xl"} shadow={"lg"} bg={"white"}>
                            <Flex mr={2} justify={"center"} align={"center"} h={"50px"} w={"50px"} borderRadius={"xl"} bg={"blue.300"}><FontAwesomeIcon size="xl" color="white" icon={faHandshakeAlt} /></Flex> <Text fontWeight={"semibold"}>No User Licenses</Text>
                        </Flex>
                    </Flex>
                </Flex>

                {/*
                {/* Marketing Pitch Section *
                <Flex
                    direction="column"
                    justify="center"
                    mt={2}
                    h={"500px"}
                    p={2}
                    textAlign="center"
                    bg="white"
                >
                    <Text mt={2} mb={2} color="green" fontSize="md" fontWeight="semibold">
                        EXPLORE JOBS
                    </Text>
                    <Heading color="blue.600" mb={4}>
                        Explore by category
                    </Heading>
                    <Flex justify="center" overflowX="auto">
                        <Flex
                            w="auto" // Change from 100% to auto for natural width
                            minW="100%" // Ensure it takes up at least 100% width
                            px={4} // Add padding to ensure spacing inside the scroll
                            gap={4} // Use gap for spacing between items
                        >
                            <Flex m={4} justify={"center"} align={"center"} p={2} borderRadius={"lg"} shadow={"lg"} bg={"white"}>
                                <Flex mr={2} justify={"center"} align={"center"} h={"250px"} w={"250px"} borderRadius={"xl"} bg={"green.300"}><FontAwesomeIcon size="xl" color="lightgreen" icon={faDollarSign} /></Flex> <Text>Free to Use</Text>
                            </Flex>
                            <Flex m={4} justify={"center"} align={"center"} p={2} borderRadius={"lg"} shadow={"lg"} bg={"white"}>
                                <Flex mr={2} justify={"center"} align={"center"} h={"250px"} w={"250px"} borderRadius={"xl"} bg={"yellow.300"}><FontAwesomeIcon size="xl" color="lightyellow" icon={faSquareCheck} /></Flex> <Text>100% Satisfaction</Text>
                            </Flex>
                            <Flex m={4} justify={"center"} align={"center"} p={2} borderRadius={"lg"} shadow={"lg"} bg={"white"}>
                                <Flex mr={2} justify={"center"} align={"center"} h={"250px"} w={"250px"} borderRadius={"xl"} bg={"red.100"}><FontAwesomeIcon size="xl" color="white" icon={faPeopleGroup} /></Flex> <Text>Premium CRM</Text>
                            </Flex>
                            <Flex m={4} justify={"center"} align={"center"} p={2} borderRadius={"lg"} shadow={"lg"} bg={"white"}>
                                <Flex mr={2} justify={"center"} align={"center"} h={"250px"} w={"250px"} borderRadius={"xl"} bg={"blue.100"}><FontAwesomeIcon size="xl" color="white" icon={faHandshakeAlt} /></Flex> <Text>Streamlined AI</Text>
                            </Flex>
                            <Flex m={4} justify={"center"} align={"center"} p={2} borderRadius={"lg"} shadow={"lg"} bg={"white"}>
                                <Flex mr={2} justify={"center"} align={"center"} h={"250px"} w={"250px"} borderRadius={"xl"} bg={"green.300"}><FontAwesomeIcon size="xl" color="lightgreen" icon={faDollarSign} /></Flex> <Text>Free to Use</Text>
                            </Flex>
                            <Flex m={4} justify={"center"} align={"center"} p={2} borderRadius={"lg"} shadow={"lg"} bg={"white"}>
                                <Flex mr={2} justify={"center"} align={"center"} h={"250px"} w={"250px"} borderRadius={"xl"} bg={"yellow.300"}><FontAwesomeIcon size="xl" color="lightyellow" icon={faSquareCheck} /></Flex> <Text>100% Satisfaction</Text>
                            </Flex>
                            <Flex m={4} justify={"center"} align={"center"} p={2} borderRadius={"lg"} shadow={"lg"} bg={"white"}>
                                <Flex mr={2} justify={"center"} align={"center"} h={"250px"} w={"250px"} borderRadius={"xl"} bg={"red.100"}><FontAwesomeIcon size="xl" color="white" icon={faPeopleGroup} /></Flex> <Text>Premium CRM</Text>
                            </Flex>
                            <Flex m={4} justify={"center"} align={"center"} p={2} borderRadius={"lg"} shadow={"lg"} bg={"white"}>
                                <Flex mr={2} justify={"center"} align={"center"} h={"250px"} w={"250px"} borderRadius={"xl"} bg={"blue.100"}><FontAwesomeIcon size="xl" color="white" icon={faHandshakeAlt} /></Flex> <Text>Streamlined AI</Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
                */}

                <Flex w={"100%"} align={"center"} justify={"center"}>
                    <Box w={"50%"} p={8} textAlign="left">
                        <Flex justify={"space-between"}>
                            <Text ml={4} color={"#01BF02"} fontSize={"md"} fontWeight={"semibold"}>FIND JOBS</Text>
                            <Image h={"15px"} src={Polygon} />
                        </Flex>
                        <Box ml={4} w={"100%"}>
                            <Heading color="white">Build And Boost </Heading>
                            <Heading color={"#01BF02"}>Your Great Profile.</Heading>
                        </Box>
                        <Text color={"white"} ml={4}>
                            At JobJar, your skills and hard work define your opportunities.
                            Discover roles where your talents are valued and rewarded.
                        </Text>
                        <Button onClick={handleGetStartedClick} _hover={{ bg: "#01bf02" }} bg={"black"} m={4}>
                            <Text mr={2} color={"white"}>Get Started</Text><FontAwesomeIcon color={"white"} icon={faArrowRight} />
                        </Button>
                        <Image m={8} h={"20px"} src={Ellipse} />
                    </Box>
                </Flex>

                {/*

                <Flex
                    direction="column"
                    justify="center"
                    h={"500px"}
                    p={2}
                    textAlign="center"
                    bg="white"
                >
                    <Text mt={2} mb={2} color="#01BF02" fontSize="md" fontWeight="semibold">
                        FEATURED
                    </Text>
                    <Heading color="blue.600" mb={4}>
                        Featured Jobs Circulars
                    </Heading>
                    <Flex justify="center" overflowX="auto">
                        <Flex
                            w="auto" // Change from 100% to auto for natural width
                            minW="100%" // Ensure it takes up at least 100% width
                            px={4} // Add padding to ensure spacing inside the scroll
                            gap={4} // Use gap for spacing between items
                        >
                            <Flex m={4} justify={"center"} align={"center"} p={2} borderRadius={"lg"} shadow={"lg"} bg={"white"}>
                                <Flex mr={2} justify={"center"} align={"center"} h={"250px"} w={"250px"} borderRadius={"xl"} bg={"green.300"}><FontAwesomeIcon size="xl" color="lightgreen" icon={faDollarSign} /></Flex> <Text>Free to Use</Text>
                            </Flex>
                            <Flex m={4} justify={"center"} align={"center"} p={2} borderRadius={"lg"} shadow={"lg"} bg={"white"}>
                                <Flex mr={2} justify={"center"} align={"center"} h={"250px"} w={"250px"} borderRadius={"xl"} bg={"yellow.300"}><FontAwesomeIcon size="xl" color="lightyellow" icon={faSquareCheck} /></Flex> <Text>100% Satisfaction</Text>
                            </Flex>
                            <Flex m={4} justify={"center"} align={"center"} p={2} borderRadius={"lg"} shadow={"lg"} bg={"white"}>
                                <Flex mr={2} justify={"center"} align={"center"} h={"250px"} w={"250px"} borderRadius={"xl"} bg={"red.100"}><FontAwesomeIcon size="xl" color="white" icon={faPeopleGroup} /></Flex> <Text>Premium CRM</Text>
                            </Flex>
                            <Flex m={4} justify={"center"} align={"center"} p={2} borderRadius={"lg"} shadow={"lg"} bg={"white"}>
                                <Flex mr={2} justify={"center"} align={"center"} h={"250px"} w={"250px"} borderRadius={"xl"} bg={"blue.100"}><FontAwesomeIcon size="xl" color="white" icon={faHandshakeAlt} /></Flex> <Text>Streamlined AI</Text>
                            </Flex>
                            <Flex m={4} justify={"center"} align={"center"} p={2} borderRadius={"lg"} shadow={"lg"} bg={"white"}>
                                <Flex mr={2} justify={"center"} align={"center"} h={"250px"} w={"250px"} borderRadius={"xl"} bg={"green.300"}><FontAwesomeIcon size="xl" color="lightgreen" icon={faDollarSign} /></Flex> <Text>Free to Use</Text>
                            </Flex>
                            <Flex m={4} justify={"center"} align={"center"} p={2} borderRadius={"lg"} shadow={"lg"} bg={"white"}>
                                <Flex mr={2} justify={"center"} align={"center"} h={"250px"} w={"250px"} borderRadius={"xl"} bg={"yellow.300"}><FontAwesomeIcon size="xl" color="lightyellow" icon={faSquareCheck} /></Flex> <Text>100% Satisfaction</Text>
                            </Flex>
                            <Flex m={4} justify={"center"} align={"center"} p={2} borderRadius={"lg"} shadow={"lg"} bg={"white"}>
                                <Flex mr={2} justify={"center"} align={"center"} h={"250px"} w={"250px"} borderRadius={"xl"} bg={"red.100"}><FontAwesomeIcon size="xl" color="white" icon={faPeopleGroup} /></Flex> <Text>Premium CRM</Text>
                            </Flex>
                            <Flex m={4} justify={"center"} align={"center"} p={2} borderRadius={"lg"} shadow={"lg"} bg={"white"}>
                                <Flex mr={2} justify={"center"} align={"center"} h={"250px"} w={"250px"} borderRadius={"xl"} bg={"blue.100"}><FontAwesomeIcon size="xl" color="white" icon={faHandshakeAlt} /></Flex> <Text>Streamlined AI</Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>

                <Flex
                    direction="column"
                    justify="center"
                    h={"500px"}
                    p={2}
                    textAlign="center"
                    bg="white"
                >
                    <Text mt={2} mb={2} color="#01BF02" fontSize="md" fontWeight="semibold">
                        TESTIMONIALS
                    </Text>
                    <Heading color="blue.600" mb={4}>
                        What Our Customers Say
                    </Heading>
                    <Flex justify="center" overflowX="auto">
                        <Flex
                            w="auto" // Change from 100% to auto for natural width
                            minW="100%" // Ensure it takes up at least 100% width
                            px={4} // Add padding to ensure spacing inside the scroll
                            gap={4} // Use gap for spacing between items
                        >
                            <Flex m={4} justify={"center"} align={"center"} p={2} borderRadius={"lg"} shadow={"lg"} bg={"white"}>
                                <Flex mr={2} justify={"center"} align={"center"} h={"250px"} w={"250px"} borderRadius={"xl"} bg={"green.300"}><FontAwesomeIcon size="xl" color="lightgreen" icon={faDollarSign} /></Flex> <Text>Free to Use</Text>
                            </Flex>
                            <Flex m={4} justify={"center"} align={"center"} p={2} borderRadius={"lg"} shadow={"lg"} bg={"white"}>
                                <Flex mr={2} justify={"center"} align={"center"} h={"250px"} w={"250px"} borderRadius={"xl"} bg={"yellow.300"}><FontAwesomeIcon size="xl" color="lightyellow" icon={faSquareCheck} /></Flex> <Text>100% Satisfaction</Text>
                            </Flex>
                            <Flex m={4} justify={"center"} align={"center"} p={2} borderRadius={"lg"} shadow={"lg"} bg={"white"}>
                                <Flex mr={2} justify={"center"} align={"center"} h={"250px"} w={"250px"} borderRadius={"xl"} bg={"red.100"}><FontAwesomeIcon size="xl" color="white" icon={faPeopleGroup} /></Flex> <Text>Premium CRM</Text>
                            </Flex>
                            <Flex m={4} justify={"center"} align={"center"} p={2} borderRadius={"lg"} shadow={"lg"} bg={"white"}>
                                <Flex mr={2} justify={"center"} align={"center"} h={"250px"} w={"250px"} borderRadius={"xl"} bg={"blue.100"}><FontAwesomeIcon size="xl" color="white" icon={faHandshakeAlt} /></Flex> <Text>Streamlined AI</Text>
                            </Flex>
                            <Flex m={4} justify={"center"} align={"center"} p={2} borderRadius={"lg"} shadow={"lg"} bg={"white"}>
                                <Flex mr={2} justify={"center"} align={"center"} h={"250px"} w={"250px"} borderRadius={"xl"} bg={"green.300"}><FontAwesomeIcon size="xl" color="lightgreen" icon={faDollarSign} /></Flex> <Text>Free to Use</Text>
                            </Flex>
                            <Flex m={4} justify={"center"} align={"center"} p={2} borderRadius={"lg"} shadow={"lg"} bg={"white"}>
                                <Flex mr={2} justify={"center"} align={"center"} h={"250px"} w={"250px"} borderRadius={"xl"} bg={"yellow.300"}><FontAwesomeIcon size="xl" color="lightyellow" icon={faSquareCheck} /></Flex> <Text>100% Satisfaction</Text>
                            </Flex>
                            <Flex m={4} justify={"center"} align={"center"} p={2} borderRadius={"lg"} shadow={"lg"} bg={"white"}>
                                <Flex mr={2} justify={"center"} align={"center"} h={"250px"} w={"250px"} borderRadius={"xl"} bg={"red.100"}><FontAwesomeIcon size="xl" color="white" icon={faPeopleGroup} /></Flex> <Text>Premium CRM</Text>
                            </Flex>
                            <Flex m={4} justify={"center"} align={"center"} p={2} borderRadius={"lg"} shadow={"lg"} bg={"white"}>
                                <Flex mr={2} justify={"center"} align={"center"} h={"250px"} w={"250px"} borderRadius={"xl"} bg={"blue.100"}><FontAwesomeIcon size="xl" color="white" icon={faHandshakeAlt} /></Flex> <Text>Streamlined AI</Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>

                <Flex
                    direction="column"
                    justify="center"
                    h={"500px"}
                    p={2}
                    textAlign="center"
                    bg="white"
                >
                    <Text mt={2} mb={2} color="#01BF02" fontSize="md" fontWeight="semibold">
                        COMPANIES WE HELP GROW
                    </Text>

                    <Flex mt={8} mb={8} w={"100%"} justify={"center"}>
                        <Flex w={"80%"} justify={"space-between"}>
                            <Flex justify={"center"} align={"center"} p={2} borderRadius={"lg"} shadow={"lg"} bg={"white"}>
                                <Flex mr={2} justify={"center"} align={"center"} h={"50px"} w={"50px"} borderRadius={"xl"} bg={"#01bf02"}><FontAwesomeIcon size="xl" color="#01BF02" icon={faDollarSign} /></Flex> <Text fontWeight={"semibold"}>Free to Use</Text>
                            </Flex>
                            <Flex justify={"center"} align={"center"} p={2} borderRadius={"lg"} shadow={"lg"} bg={"white"}>
                                <Flex mr={2} justify={"center"} align={"center"} h={"50px"} w={"50px"} borderRadius={"xl"} bg={"yellow.300"}><FontAwesomeIcon size="xl" color="lightyellow" icon={faSquareCheck} /></Flex> <Text fontWeight={"semibold"}>100% Satisfaction</Text>
                            </Flex>
                            <Flex justify={"center"} align={"center"} p={2} borderRadius={"lg"} shadow={"lg"} bg={"white"}>
                                <Flex mr={2} justify={"center"} align={"center"} h={"50px"} w={"50px"} borderRadius={"xl"} bg={"red.100"}><FontAwesomeIcon size="xl" color="white" icon={faPeopleGroup} /></Flex> <Text fontWeight={"semibold"}>Premium CRM</Text>
                            </Flex>
                            <Flex justify={"center"} align={"center"} p={2} borderRadius={"lg"} shadow={"lg"} bg={"white"}>
                                <Flex mr={2} justify={"center"} align={"center"} h={"50px"} w={"50px"} borderRadius={"xl"} bg={"blue.100"}><FontAwesomeIcon size="xl" color="white" icon={faHandshakeAlt} /></Flex> <Text fontWeight={"semibold"}>Streamlined AI</Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>

                {/* FAQ Accordion Section *
                <Box p={8} bg={bg}>
                    <Accordion allowMultiple>
                        {/* Map through FAQ data to render AccordionItems *
                    </Accordion>
                </Box>
            */}

                {/* Call To Action Section */}
                <Box bg="black" color="white" p={8} textAlign="center">
                    <Heading>Ready to Transform Your Career?</Heading>
                    <Text>Join JobJar.AI today and step into the future of job hunting and recruitment.</Text>
                    <Button
                        color={"white"} bg={"#01BF02"} _hover={{ bg: "white", color: 'black' }}
                        mt={4}
                        onClick={handleGetStartedClick}
                    >
                        Sign Up Now
                    </Button>
                </Box>
                <Flex bg={"white"} borderRadius={"lg"} mt={8} justify="center" w="100%" py={5}>
                    <Flex direction={{ base: 'column', md: 'row' }} align="center" justify="space-between" maxW="1200px" w="100%" px={8}>
                        <Box textAlign="center" mb={{ base: 4, md: 0 }}>
                            <Image mb={4} src={Logo} w={"250px"} />
                            <Center>
                                <a href="https://www.publicsquare.com/featured/marketplace/090d7e40-cb9c-11ee-a0af-775c319db637" target="_blank" rel="noopener noreferrer">
                                    <Image href="https://www.publicsquare.com/featured/marketplace/090d7e40-cb9c-11ee-a0af-775c319db637" mb={4} src={PBSQ} w={"200px"} />
                                </a>
                            </Center>
                            <IconButton
                                size="sm"
                                borderRadius="full"
                                as="a"
                                href="https://www.facebook.com/JobJarAi/"
                                target="_blank"
                                icon={<FontAwesomeIcon size="xl" icon={faSquareFacebook} />}
                                colorScheme="facebook"
                                mr={2}
                            />
                            <IconButton
                                size="sm"
                                borderRadius="full"
                                as="a"
                                href="https://twitter.com/JobJarAI"
                                target="_blank"
                                icon={<FontAwesomeIcon size="xl" icon={faSquareXTwitter} />}
                                colorScheme="twitter"
                                mx={2}
                            />
                            <IconButton
                                size="sm"
                                borderRadius="full"
                                as="a"
                                href="https://www.linkedin.com/company/jobjar-ai/"
                                target="_blank"
                                icon={<FontAwesomeIcon size="xl" icon={faLinkedin} />}
                                colorScheme="linkedin"
                                ml={2}
                            />
                        </Box>
                        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} textAlign="center" p={8}>
                            <Box>
                                <Text fontWeight="bold">About</Text>
                                <Text>Company</Text>
                                <Text>Teams</Text>
                                <Text>Profile</Text>
                                <Text>Careers</Text>
                            </Box>
                            <Box>
                                <Text fontWeight="bold">Resources</Text>
                                <Text>Contact</Text>
                                <Text>Application</Text>
                                <Text>FAQ</Text>
                                <Text>Footage</Text>
                            </Box>
                            <Box>
                                <Text fontWeight="bold">Legals</Text>
                                <Text>Copyrights</Text>
                                <Link to="/privacy"><Text cursor="pointer" _hover={{ textDecoration: "underline" }}>Privacy Policy</Text></Link>
                                <Link to="/tos"><Text cursor="pointer" _hover={{ textDecoration: "underline" }}>Terms of Service</Text></Link>
                            </Box>
                        </SimpleGrid>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default MarketingPage;
