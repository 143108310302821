import React, { useState, useEffect } from 'react';
import axiosInstance from '../../components/axiosInstance';
import {
    Box, Heading, Flex, VStack, HStack, Select, Button, Text, useToast,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, SimpleGrid
} from '@chakra-ui/react';
import { FaUserTie } from 'react-icons/fa';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Background from '../../assets/Background.webp';

const ManageTalent = () => {
    const [jobSeekers, setJobSeekers] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [selectedJobs, setSelectedJobs] = useState({});
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    const fetchCompanyData = async () => {
        try {
            const token = localStorage.getItem('auth_token');
            if (!token) {
                console.error('Auth token is not available in local storage.');
                return;
            }

            const response = await axiosInstance.get('/company-jobseekers-jobs', { // Ensure this matches your actual API route
                headers: { Authorization: `Bearer ${token}` },
            });

            const { jobSeekers: fetchedJobSeekers, jobs: fetchedJobs } = response.data;
            console.log('Fetched Job Seekers', fetchedJobSeekers); // Corrected log
            console.log('Fetched Jobs', fetchedJobs); // Corrected log
            setJobSeekers(fetchedJobSeekers || []); // Ensure fallback to empty array
            setJobs(fetchedJobs || []); // Ensure fallback to empty array
        } catch (error) {
            console.error('Failed to fetch company data:', error);
            toast({
                title: 'Fetching Error',
                description: 'Failed to fetch company data. Please try again later.',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        fetchCompanyData();
    }, []);

    const handleJobSelection = (jobSeekerId, jobId) => {
        setSelectedJobs(prev => ({ ...prev, [jobSeekerId]: jobId }));
    };

    const applyToJob = async (jobSeekerId) => {
        const jobId = selectedJobs[jobSeekerId];
        if (!jobId) {
            console.error('No job selected for this job seeker.');
            return;
        }

        try {
            const token = localStorage.getItem('auth_token');
            const response = await axiosInstance.post('/employer/apply', { jobSeekerId, jobId }, {
                headers: { Authorization: `Bearer ${token}` },
            });

            onOpen();
            console.log('Apply response:', response);
        } catch (error) {
            console.error('Failed to apply to job:', error);
            toast({
                title: 'Application Error',
                description: 'This applicant already exists for this job.',
                status: 'info',
                duration: 9000,
                isClosable: true,
                position: 'top'
            });
        }
    };

    return (
        <Flex
            direction="column"
            align="center"
            minH="100vh"
            pl={"300px"}
            width="100%"
            backgroundImage={Background}
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
        >
            <VStack spacing={8} w="fit-content" p={5}>
                <Heading color="white" mb={4}>Company Data</Heading>
                <SimpleGrid maxH={"900px"} overflowY={"scroll"} columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
                    {jobSeekers.map((jobSeeker) => (
                        <Box
                            key={jobSeeker._id}
                            p={5}
                            shadow="md"
                            borderWidth="1px"
                            borderRadius="lg"
                            w="full"
                            bg="white"
                            _hover={{ shadow: "xl" }}
                        >
                            <HStack spacing={4} justify="space-between">
                                <Box>
                                    <FaUserTie />
                                    <Text fontWeight="bold">{jobSeeker.name}</Text>
                                    <Text fontSize="sm">{jobSeeker.phoneNumber}</Text>
                                    <Text fontSize="sm">{jobSeeker.email}</Text>
                                </Box>
                                <Select w="fit-content" placeholder="Select a job" onChange={(e) => handleJobSelection(jobSeeker._id, e.target.value)}>
                                    {jobs.map((job) => (
                                        <option key={job._id} value={job._id}>{job.jobTitle}</option>
                                    ))}
                                </Select>
                                <Button leftIcon={<FontAwesomeIcon color='white' icon={faPlus} />} colorScheme="blue" onClick={() => applyToJob(jobSeeker._id)}>
                                    Add to Job
                                </Button>
                            </HStack>
                        </Box>
                    ))}
                </SimpleGrid>
            </VStack>

            {/* Confirmation Modal */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Application Submitted</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>The job seeker has been successfully added to the job.</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex>
    );
};

export default ManageTalent;