import React, { useState, useEffect } from 'react';
import { Box, Flex, Heading, IconButton, Text } from '@chakra-ui/react';
import axios from 'axios';
import { socketAtom } from '../../socketAtom';
import { useAtom } from 'jotai';
import UserConnectionsList from '../../components/UserChat';
import UserSearchModal from './UserSearchModal';

const UserConnections = ({ onTabChange }) => {
    const [connections, setConnections] = useState([]);
    const [senderPFP, setSenderPFP] = useState('');
    const [userId, setUserId] = useState('');
    const [typingIndicator, setTypingIndicator] = useState({});
    const [message, setNewMessage] = useState(null);
    const [senderFirstName, setSenderFirstName] = useState('');
    const [senderLastName, setSenderLastName] = useState('');
    const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
    const [socket, setSocket] = useAtom(socketAtom);
    const token = localStorage.getItem('auth_token');

    const fetchConnections = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/api/user-connections', {
                headers: { Authorization: `Bearer ${token}` },
            });
            const approvedConnections = response.data.connections.filter(conn => conn.status === "approved");
            const connectionIds = approvedConnections.map(conn => conn.connectionId);
            setConnections(approvedConnections);
            setSenderPFP(response.data.senderPFP);
            console.log('PFP', senderPFP);
            setSenderFirstName(response.data.senderFirstName);
            setSenderLastName(response.data.senderLastName);
            setUserId(response.data.userId);
            connectionIds.forEach(connectionId => {
                socket.emit('joinPrivateRoom', { connectionId });
            });
        } catch (error) {
            console.error('Error fetching connections:', error);
        }
    };

    useEffect(() => {
        fetchConnections();
    }, [token, onTabChange]);

    useEffect(() => {
        const handleNewPrivateMessage = (newMessage) => {
            console.log('New message received:', newMessage);
            setNewMessage(newMessage);
        };

        console.log('New message received:', message);

        const handleTyping = ({ senderId, roomName, senderFirstName, senderLastName }) => {
            setTypingIndicator((prevState) => ({
                ...prevState,
                [roomName]: { senderId, senderFirstName, senderLastName },
            }));
            setTimeout(() => {
                setTypingIndicator((prevState) => {
                    const { [roomName]: _, ...rest } = prevState;
                    return rest;
                });
            }, 2000);
        };

        socket.on('newPrivateMessage', handleNewPrivateMessage);
        socket.on('typing', handleTyping);

        return () => {
            socket.off('newPrivateMessage', handleNewPrivateMessage);
            socket.off('typing', handleTyping);
        };
    }, []);

    const handleSearchModalClose = () => {
        setIsSearchModalOpen(false);
    };

    return (
        <Box mb={2} w="100%">
            <UserConnectionsList
                newMessage={message}
                userId={userId}
                connections={connections}
                senderPFP={senderPFP}
                senderFirstName={senderFirstName}
                senderLastName={senderLastName}
                typingIndicator={typingIndicator}
            />
            <UserSearchModal isOpen={isSearchModalOpen} onClose={handleSearchModalClose} />
        </Box>
    );
};

export default UserConnections;