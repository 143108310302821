import React from 'react';
import ParentSideBar from './ParentSidebar';

const WithSidebarLayout = ({ children }) => (
    <>
        <ParentSideBar />
        {children}
    </>
);

export default WithSidebarLayout;
