// axiosInstance.js
import axios from 'axios';
import jwtDecode from 'jwt-decode';

const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/api`,
});

let onTokenRefreshFailed;

axiosInstance.interceptors.request.use(
    async (config) => {
        let token = localStorage.getItem('auth_token');
        if (token) {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;

            if (decodedToken.exp < currentTime) {
                const refreshToken = localStorage.getItem('refresh_token');
                if (!refreshToken) {
                    if (onTokenRefreshFailed) {
                        onTokenRefreshFailed(); // Trigger the callback for token refresh failure
                    }
                    throw new Error('No refresh token available');
                }

                try {
                    const response = await axios.post(`${process.env.REACT_APP_API_URL}/refresh`, { refreshToken });
                    const { newToken } = response.data;
                    localStorage.setItem('auth_token', newToken);
                    token = newToken; // Update the token variable with the new token
                } catch (error) {
                    if (onTokenRefreshFailed) {
                        onTokenRefreshFailed();
                    }
                    throw error;
                }
            }

            // Token is valid or has been refreshed, attach it to the request
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.setTokenRefreshFailedCallback = (callback) => {
    onTokenRefreshFailed = callback;
};

export default axiosInstance;