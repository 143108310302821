import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Box, Flex, Button, SimpleGrid, Image, Text, Heading, IconButton, useToast, Spinner
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import Background from '../../assets/Background.webp'; // Make sure the path matches your project structure
import axiosInstance from '../../components/axiosInstance';

function OrganizationsPage() {
    const [isLoading, setIsLoading] = useState(true);
    const [organizations, setOrganizations] = useState([]);
    const [selectedBusinessId, setSelectedBusinessId] = useState(localStorage.getItem('businessId') || '');
    const [sectionStates, setSectionStates] = useState({ organizations: true });
    const navigate = useNavigate();
    const toast = useToast();

    useEffect(() => {
        // Fetch organizations tied to the user
        fetchAssociatedBusinesses();
    }, []);

    const fetchAssociatedBusinesses = async () => {
        try {
            const { data } = await axiosInstance.get(`/associated-businesses`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
                },
            });
            setOrganizations(data);
        } catch (error) {
            console.error('Failed to fetch associated businesses:', error);
            toast({
                title: 'Error loading associated businesses',
                description: 'Unable to load associated businesses. Please try again.',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
        setIsLoading(false);
    };

    const handleSelectOrganization = (businessId) => {
        localStorage.setItem('businessId', businessId);
        setSelectedBusinessId(businessId);
    };

    const handleSectionToggle = (section, state) => {
        setSectionStates(prev => ({ ...prev, [section]: state }));
    };

    return (
        <Flex direction="column" h="100vh" w="100%">
            {/* Background container */}
            <Box
                position="fixed"
                top={0}
                left={0}
                right={0}
                bottom={0}
                bgImage={`url(${Background})`}
                bgSize="cover"
                bgRepeat="no-repeat"
                bgPosition="center"
                zIndex={-1}
            />
            {/* Content container */}
            <Flex flex="1" w="100%" justify="center" pl="300px">
                <Flex direction="column" p={8} w="100%" overflowY="scroll">
                    {sectionStates.organizations ? (
                        <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={2} w="50%" bg="white" boxShadow="xl">
                            <Flex mb={3} w="100%" align="flex-start" justify="space-between">
                                <Heading size="sm">Select Organization</Heading>
                                <Link to="/create-business">
                                    <Button size="xs" variant="outline" colorScheme="green">
                                        Add Your Organization +
                                    </Button>
                                </Link>
                            </Flex>
                            {isLoading && (
                                <Spinner />
                            )}
                            <SimpleGrid maxH="200px" overflowY="scroll" columns={[1, 3]} spacing={4}>
                                {organizations.map((business) => (
                                    <Flex key={business._id} borderWidth="1px" borderRadius="md" justify="center" shadow="sm" p={2} _hover={{ bg: "gray.50" }}
                                        onClick={() => handleSelectOrganization(business._id)}>
                                        <Flex direction="column" align="center" borderRadius="lg">
                                            <Image w="40px" src={business.logo} alt={business.businessName} mb={2} />
                                            <Text fontSize="xl" fontWeight="bold">{business.businessName}</Text>
                                            <Text mt={1}>{business.category}</Text>
                                            {/* Conditionally render the green checkmark */}
                                            {localStorage.getItem('businessId') === business._id && (
                                                <FontAwesomeIcon icon={faCircleCheck} color="#02bf01" />
                                            )}
                                        </Flex>
                                    </Flex>
                                ))}
                            </SimpleGrid>
                            <Flex justify="flex-end">
                                <IconButton
                                    icon={<FontAwesomeIcon icon={faMinus} />}
                                    variant="ghost"
                                    onClick={() => handleSectionToggle('organizations', false)}
                                />
                            </Flex>
                        </Box>
                    ) : (
                        <Flex w="100%" bg="white" align="center" justify="space-between" p={2} borderWidth="1px" borderRadius="lg">
                            <Heading size="sm">Select Organization</Heading>
                            <IconButton
                                icon={<FontAwesomeIcon icon={faPlus} />}
                                variant="ghost"
                                onClick={() => handleSectionToggle('organizations', true)}
                            />
                        </Flex>
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
}

export default OrganizationsPage;
