import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, FormControl, Flex, FormLabel, Textarea, VStack, Heading, useToast } from '@chakra-ui/react';
import axiosInstance from '../../components/axiosInstance';
import Background from '../../assets/Background.webp';

const TakeAssessment = () => {
    const { applicantId } = useParams();
    const token = localStorage.getItem('auth_token');
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState({});
    const [started, setStarted] = useState(false);
    const toast = useToast();

    // Client-side code to fetch the questions for the assessment
    useEffect(() => {
        if (applicantId) {
            fetchAssessmentQuestions();
        }
    }, [applicantId]);

    const fetchAssessmentQuestions = async () => {
        try {
            const response = await axiosInstance.get(`/applicants/${applicantId}/assessment-questions`);
            if (response.data && response.data.questions) {
                setQuestions(response.data.questions);
            } else {
                // Handle no questions found
            }
        } catch (error) {
            // Handle error
        }
    };

    const handleAnswerChange = async (e) => {
        const { name, value } = e.target;
        setAnswers(prevAnswers => ({
            ...prevAnswers,
            [name]: value
        }));
    
        // Check if this is the first time typing an answer
        if (!started && value.trim() !== '') {
            setStarted(true);
            try {
                // Send a request to update the startedAt time
                await axiosInstance.put(`/applicants/${applicantId}/start-assessment`, {
                    startedAt: new Date()
                }, {
                    headers: { Authorization: `Bearer ${token}` },
                });
            } catch (error) {
                console.error('Error setting start time:', error);
                // Optionally handle error, e.g., show a toast notification
            }
        }
    };

    const handleSubmitAnswers = async () => {
        // Convert the answers object into an array of answers
        const submittedAnswers = Object.values(answers);
        try {
            const response = await axiosInstance.put(`/applicants/${applicantId}/submit-assessment`, {
                answers: submittedAnswers,
                completedAt: new Date()
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });
    
            if (response.data) {
                toast({
                    title: 'Assessment Submitted',
                    description: 'Your answers have been submitted successfully.',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                // Here you can handle any state updates or navigations post submission
            }
        } catch (error) {
            console.error('Error submitting assessment answers:', error);
            toast({
                title: 'Error',
                description: 'Failed to submit assessment answers.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };
    

    return (
        <Box bgImage={Background} bgSize="cover" h={"100vh"} bgRepeat="no-repeat" bgPosition="center">
            <Flex w={"100%"} justify={"center"} >
                <Box mt={8} w={"80%"} bg={"whiteAlpha.800"} borderRadius={"2xl"} p={4}>
                    <VStack mt={4} p={4} spacing={5}>
                        <Heading as="h1">Take Assessment</Heading>
                        {questions.map((question, index) => (
                            <FormControl key={index}>
                                <FormLabel>Question {index + 1}</FormLabel>
                                <Box mb={4}>{question}</Box>
                                <Textarea
                                    name={`answer${index + 1}`}
                                    value={answers[`answer${index + 1}`]}
                                    bg={"white"}
                                    onChange={handleAnswerChange}
                                    placeholder={`Your answer ${index + 1}`}
                                    size='lg'
                                />
                            </FormControl>
                        ))}
                        <Button colorScheme="blue" onClick={handleSubmitAnswers}>
                            Submit Answers
                        </Button>
                    </VStack>
                </Box>
            </Flex>
        </Box>
    );
};

export default TakeAssessment;
