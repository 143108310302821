import React, { useState, useRef } from 'react';
import { Box, Textarea, Button, useToast, Input, InputGroup, InputRightElement, HStack, Icon, Text } from '@chakra-ui/react';
import { FaImage } from 'react-icons/fa';
import axiosInstance from '../../components/axiosInstance';

const CreatePost = () => {
    const [originalContent, setOriginalContent] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const fileInputRef = useRef(null);
    const toast = useToast();

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            setFileName(file.name);
        }
    };

    const handlePost = async () => {
        const formData = new FormData();
        if (selectedFile) {
            formData.append('image', selectedFile);
        }
        formData.append('originalContent', originalContent);

        try {
            await axiosInstance.post('/create-post', formData, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('auth_token')}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            toast({
                title: 'Post created successfully',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            // Reset form
            setOriginalContent('');
            setSelectedFile(null);
            setFileName('');
        } catch (error) {
            toast({
                title: 'Error creating post',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Box w={"full"} p={4} borderRadius="lg" boxShadow="md">
            <Textarea
                placeholder="What's on your mind?"
                value={originalContent}
                onChange={(e) => setOriginalContent(e.target.value)}
                size="lg"
                resize="none"
                bg={"white"}
                borderRadius="md"
                minHeight="120px"
                _hover={{ borderColor: "#02bf01" }}
                _focus={{ borderColor: "#02bf01", boxShadow: "0 0 0 1px #48BB78" }}
            />
            <HStack mt={4} spacing={4}>
                <InputGroup>
                    <Input
                        pr="4.5rem"
                        type="text"
                        color={"white"}
                        placeholder="No file chosen"
                        value={fileName}
                        isReadOnly
                        _hover={{ borderColor: "#02bf01" }}
                        _focus={{ borderColor: "#02bf01", boxShadow: "0 0 0 1px #48BB78" }}
                    />
                    <InputRightElement width="auto">
                        <Button
                            size="sm"
                            mr={1}
                            variant="solid"
                            bg={"#02bf01"}
                            _hover={{ bg: "green" }}
                            onClick={() => fileInputRef.current.click()}
                            leftIcon={<Icon color={"white"} as={FaImage} />}
                        >
                            <Text color={"white"} display={{ base: "none", md: "inline" }}>Add Image</Text>
                        </Button>
                    </InputRightElement>
                    <Input type="file" hidden ref={fileInputRef} onChange={handleFileChange} accept="image/*" />
                </InputGroup>
                <Button
                    size="md"
                    bg={"#02bf01"}
                    _hover={{ bg: "green" }}
                    onClick={handlePost}
                    flexShrink={0}
                    color={"white"}
                >
                    Post
                </Button>
            </HStack>
        </Box>
    );
};

export default CreatePost;