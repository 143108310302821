import React from 'react';
import { Alert, AlertIcon, AlertTitle, AlertDescription, CloseButton, useDisclosure } from '@chakra-ui/react';

const InfoAlert = () => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: false }); //Turn alert on or off
    const alertTitle = 'Information';
    const alertDescription = 'This is an important update.';

    return isOpen ? (
        <Alert status="info">
            <AlertIcon />
            <AlertTitle>{alertTitle}</AlertTitle>
            <AlertDescription>{alertDescription}</AlertDescription>
            <CloseButton
                alignSelf='flex-start'
                position='relative'
                right={-1}
                top={-1}
                onClick={onClose}
            />
        </Alert>
    ) : null;
};

export default InfoAlert;
