import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
    Box, Text, Badge, VStack, Image, Flex, Avatar, Switch, useColorModeValue,
    Heading, IconButton, Stack, Spacer, HStack, Spinner, Button, Input, Divider
} from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons'
import { TimeIcon } from '@chakra-ui/icons';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { faUserPlus, faHeart, faHandPointer, faFlag, faSquareCheck, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosInstance from '../components/axiosInstance';
import UserConnectionFeeds from '../components/Connections/UserConnectionFeeds';

const ProfileLiveFeed = ({ userId }) => {
    const [posts, setPosts] = useState([]);
    const [comments, setComments] = useState({});
    const [currentUser, setCurrentUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isPosting, setIsPosting] = useState({});
    const [isReviewing, setIsReviewing] = useState(false);
    const [page, setPage] = useState(1);
    const [commentLikes, setCommentLikes] = useState({});
    const [hasMore, setHasMore] = useState(true);
    const [itemsPerPage] = useState(5);
    const navigate = useNavigate();
    const loaderRef = useRef(null);
    const [showConnectionsFeed, setShowConnectionsFeed] = useState(false);
    const [applicantCounts, setApplicantCounts] = useState({});

    const fetchposts = async () => {
        if (!hasMore || isLoading) return;

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user-reposts?userId=${userId}&page=${page}&perPage=${itemsPerPage}`);
            // If page is 1, replace the existing posts; otherwise, append
            if (page === 1) {
                setPosts(response.data);
            } else {
                setPosts(prevposts => [...prevposts, ...response.data]);
            }
            setHasMore(response.data.length === itemsPerPage);
            const newCommentLikes = {};
            response.data.forEach(post => {
                post.comments.forEach(comment => {
                    newCommentLikes[comment._id] = comment.upMarks.includes(currentUser);
                });
            });
            setCommentLikes(prevLikes => ({ ...prevLikes, ...newCommentLikes }));
        } catch (error) {
            console.error("Error fetching posts:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const refreshFeed = async () => {
        setPage(1);
        setPosts([]);
        setHasMore(true);

        try {
            await fetchposts();
        } catch (error) {
            console.error("Failed to fetch posts:", error);
            // Handle the error appropriately
        }
    };

    const observer = useRef();
    const lastRepostElementRef = useCallback(node => {
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore && !isLoading) { // Check isLoading here
                setPage(prevPage => prevPage + 1);
            }
        }, { threshold: 0.1 });
        if (node) observer.current.observe(node);
    }, [isLoading, hasMore]);

    const fetchUserId = async () => {
        try {
            const authToken = localStorage.getItem('auth_token');
            const response = await axiosInstance.get('/user/details', {
                headers: { 'Authorization': `Bearer ${authToken}` }
            });

            // Assuming the state is named currentUser
            setCurrentUser(response.data.userId);
        } catch (error) {
            console.error('Error fetching user ID:', error);
        }
    };

    const fetchJobApplicants = async (jobId) => {
        try {
            const token = localStorage.getItem('auth_token');

            const applicantResponse = await axiosInstance.get(`/getJobApplicants/${jobId}`, {
                headers: { "Authorization": `Bearer ${token}` }
            });
            const totalCount = applicantResponse.data.totalCount;

            setApplicantCounts(prevCounts => ({
                ...prevCounts,
                [jobId]: totalCount
            }));

        } catch (error) {
            console.error('Error fetching applicants:', error);
            // Assuming you have a 'toast' method for showing notifications

        }
    };

    useEffect(() => {
        fetchposts();
        fetchUserId();
    }, []);

    useEffect(() => {
        posts.forEach(post => {
            // Only fetch job applicants for posts with 'job' content type
            if (post.contentType === 'job' && post.jobId) {
                fetchJobApplicants(post.jobId);
            }
        });
    }, [posts]);

    useEffect(() => {
        if (page > 1) {
            fetchposts();
        }
    }, [page]);

    const goToUserProfile = (userId) => {
        navigate(`/user/${userId}`);
    };

    const goToJobDetails = async (jobId, userId) => {
        try {
            await axiosInstance.put(`/${userId}/referrals`, {
                jobId,
                userId,
            });

            navigate(`/job/${jobId}/${userId}`);
        } catch (error) {
            console.error('Error creating referral:', error);
            // Handle error (e.g., show a notification to the user)
        }
    };

    const handleModerateRepost = async (postId) => {
        setIsReviewing(true);
        try {
            // Assuming authToken is stored in localStorage or fetched from context/store
            const authToken = localStorage.getItem('auth_token');
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/check-post/${postId}`, {}, {
                headers: { 'Authorization': `Bearer ${authToken}` }
            });

            // Handle the response from the server
            console.log(response.data.message); // log the message or use it in UI
            // TODO: Add logic here to update UI based on moderation response if necessary
        } catch (error) {
            console.error('Error moderating repost:', error);
            // Error handling logic for moderation attempt
        }

        try {
            await refreshRepost(postId); // Attempt to refresh repost
        } catch (error) {
            console.error('Error refreshing repost:', error);
        }

        setIsReviewing(false);
    };

    const handleLike = async (postId) => {
        // Optimistic update
        setPosts(prevposts => prevposts.map(post =>
            post._id === postId
                ? { ...post, likes: [...post.likes, { userId: currentUser }] }
                : post
        ));

        try {
            await axiosInstance.post(`posts/${postId}/like`);
        } catch (error) {
            console.error('Error liking repost:', error);
            // Revert optimistic update if error occurs
            setPosts(prevposts => prevposts.map(post =>
                post._id === postId
                    ? { ...post, likes: post.likes.filter(like => like.userId !== currentUser) }
                    : post
            ));
        }
    };

    const handleToggleChange = () => {
        setShowConnectionsFeed(!showConnectionsFeed);
    };

    const handleUnlike = async (postId) => {
        // Optimistic update
        setPosts(prevposts => prevposts.map(post =>
            post._id === postId
                ? { ...post, likes: post.likes.filter(like => like.userId !== currentUser) }
                : post
        ));

        try {
            await axiosInstance.delete(`/posts/${postId}/unlike`);
        } catch (error) {
            console.error('Error unliking repost:', error);
            // Revert optimistic update if error occurs
            setPosts(prevposts => prevposts.map(post =>
                post._id === postId
                    ? { ...post, likes: [...post.likes, { userId: currentUser }] }
                    : post
            ));
        }
    };

    const handleClick = async (postId) => {
        try {
            await axiosInstance.put(`/posts/${postId}/click`);
            setPosts(prevposts =>
                prevposts.map(post =>
                    post._id === postId ? { ...post, clicks: post.clicks + 1 } : post
                )
            );
        } catch (error) {
            console.error('Error recording click:', error);
            // Handle error (e.g., show a notification to the user)
        }
    };

    const refreshRepost = async (postId) => {
        try {
            const response = await axiosInstance.get(`/posts/${postId}`);
            const updatedRepost = response.data;

            setPosts(prevposts => prevposts.map(repost =>
                repost._id === postId ? updatedRepost : repost
            ));
        } catch (error) {
            console.error(`Error refreshing repost with id ${postId}:`, error);
            await refreshFeed();
        }
    };

    const handleCommentChange = (postId, event) => {
        setComments({ ...comments, [postId]: event.target.value });
    };

    const submitComment = async (postId) => {
        setIsPosting(prevState => ({ ...prevState, [postId]: true }));
        const commentText = comments[postId];
        if (!commentText) return; // Prevent empty comments

        try {
            const authToken = localStorage.getItem('auth_token');
            await axiosInstance.post(`/posts/${postId}/comment`, {
                comment: commentText,
            }, {
                headers: { 'Authorization': `Bearer ${authToken}` }
            });
            setComments({ ...comments, [postId]: '' });
            await refreshRepost(postId);
        } catch (error) {
            console.error('Error submitting comment:', error);
        }
        setIsPosting(prevState => ({ ...prevState, [postId]: false }));
    };

    const handleCommentLikeToggle = async (postId, commentId) => {
        setIsLoading(true);
        try {
            const isLiked = commentLikes[commentId] || false;
            if (isLiked) {
                await axiosInstance.put(`/posts/${postId}/comments/${commentId}/unlike`);
                setCommentLikes(prevLikes => ({ ...prevLikes, [commentId]: false }));
            } else {
                await axiosInstance.put(`/posts/${postId}/comments/${commentId}/like`);
                setCommentLikes(prevLikes => ({ ...prevLikes, [commentId]: true }));
            }
            await refreshRepost(postId);
        } catch (error) {
            console.error('Error toggling comment like:', error);
        }
        setIsLoading(false);
    };

    const CustomSwitch = () => {
        const trackColor = useColorModeValue("#02bf01"); // Adjust for light/dark mode if needed
        const thumbColor = "white"; // Your custom color

        return (
            <Switch
                isChecked={showConnectionsFeed}
                onChange={handleToggleChange}
                colorScheme="teal" // Base color scheme, affects focus ring, etc.
                sx={{
                    '.chakra-switch__track': {
                        bg: trackColor,  // Background color of the track
                    },
                    '.chakra-switch__thumb': {
                        bg: thumbColor,  // Background color of the thumb
                    }
                }}
            />
        );
    }

    return (
        <Flex direction="column" borderRadius={"xl"} shadow={"lg"} h="fit-content" w="100%" maxH={"100vh"} overflowY={"scroll"} align="center" justify="start">
            <VStack borderRadius={"lg"} p={2} w={"100%"} minH={"300px"} overflowY={"scroll"} align="center" maxW={"600px"}>
                {posts.map((post, index) => (
                    <Box
                        ref={index === posts.length - 1 ? lastRepostElementRef : null}
                        borderRadius={"2xl"}
                        bg={"white"}
                        key={post._id}
                        p={4}
                        mb={2}
                        shadow="lg"
                        //  borderWidth="1px"
                        //  borderColor={"#02bf01"}
                        w={"95%"}
                    >
                        {/* Profile and Time Section */}
                        {/* Profile and Time Section */}
                        <Stack direction="row" spacing={2} align="center">
                            {/* Profile Picture and Name */}
                            <HStack>
                                {/* Profile Picture */}
                                {post.profilePic ? (
                                    <Image
                                        borderRadius="full"
                                        border={"1px"}
                                        shadow={"lg"}
                                        maxW={"50px"}
                                        maxH={"50px"}
                                        src={post.profilePic}
                                        alt={`Profile image of ${post.firstName} ${post.lastName}`}
                                    />
                                ) : (
                                    <Avatar bg={"#118AB2"} size={"sm"} name={`${post.firstName} ${post.lastName}` || "User"} />
                                )}
                                {/* User Name */}
                                <Heading
                                    as="button"
                                    size={"sm"}
                                    onClick={() => goToUserProfile(post.userId)}
                                    _hover={{ textDecoration: 'underline' }}
                                >
                                    {post.firstName} {post.lastName}
                                </Heading>
                            </HStack>
                            <Spacer />
                            {/* Time */}
                            <Text as="time" fontSize="sm">
                                <TimeIcon mr="2" />
                                {formatDistanceToNow(parseISO(post.repostDate), { addSuffix: true })}
                            </Text>
                            {post.moderationInfo.determination === 'Good' ? (
                                <>
                                    <FontAwesomeIcon size='xl' color='green' icon={faSquareCheck} />
                                </>
                            ) : (
                                <IconButton
                                    aria-label="Moderate Repost"
                                    size={"sm"}
                                    variant={"ghost"}
                                    isLoading={isReviewing}
                                    icon={<FontAwesomeIcon icon={faFlag} />}
                                    onClick={() => handleModerateRepost(post._id)}
                                    _hover={{ bg: "#EF476F", color: "white" }}
                                />
                            )}
                        </Stack>

                        {/* Content based on Type */}
                        {post.contentType === 'job' ? (
                            // Job Repost Content
                            <>
                                <Text mt={4} fontSize="md">{post.comment}</Text>
                                <Box
                                    p={3}
                                    mt={3}
                                    bg={"white"}
                                    borderRadius={"xl"}
                                    cursor="pointer"
                                    _hover={{ bg: "blackAlpha.300" }}
                                    onClick={(e) => {
                                        handleClick(post._id);
                                        goToJobDetails(post.jobId, post.userId, post.role);
                                        e.stopPropagation();
                                    }}
                                >
                                    {/* Job Details */}
                                    <HStack>
                                        {post.jobBadge && (
                                            <Badge colorScheme={post.jobBadge.colorScheme} variant={post.jobBadge.variant}>
                                                {post.jobBadge.firstName}
                                            </Badge>
                                        )}
                                        <Heading size="lg">{post.jobTitle}</Heading>
                                    </HStack>
                                    <HStack>
                                        <Text>{post.industry} | {post.workLocationType} | {post.employmentType}</Text>
                                    </HStack>
                                    {post.image && (
                                        <Flex w={"100%"} justify={"center"}>
                                            <Image borderRadius={"lg"} mt={4} src={post.image} alt="Job Image" style={{ maxWidth: '90%', maxHeight: '500px' }} />
                                        </Flex>
                                    )}
                                </Box>
                            </>
                        ) : (
                            // Original Content
                            <Flex w={"100%"} justify={"center"} mt={4}>
                                <Box w={"100%"}>
                                    <Text mt={4} mb={4} fontSize="md">{post.originalContent}</Text>
                                    <Flex w={"100%"} justify={"center"}>
                                        {post.originalContentImage && (
                                            <Image mb={4} borderRadius={"3xl"} src={post.originalContentImage} alt="Original Content Image" style={{ maxWidth: '90%', maxHeight: '500px' }} />
                                        )}
                                    </Flex>

                                    {post.comments && post.comments.length > 0 && (
                                        <>
                                            <Divider shadow={"lg"} border={"1px"} borderColor={"#02bf01"} />
                                            <Box borderRadius={"lg"} maxW={"100%"} maxH={"300px"} overflowY={"scroll"}>
                                                {post.comments && post.comments.map((comment, index) => (
                                                    <Box bg={"blackAlpha.200"} w={"98%"} m={2} mb={2} p={2} borderRadius={"lg"} key={index}>
                                                        <HStack w={"100%"} align={"start"} justify={"start"} >
                                                            {/* Profile Picture */}
                                                            {comment.profilePic ? (
                                                                <Image
                                                                    borderRadius="full"
                                                                    border="1px"
                                                                    bg={"white"}
                                                                    shadow="md"
                                                                    w="30px"
                                                                    src={comment.profilePic}
                                                                    alt={`Profile image of ${comment.firstName} ${comment.lastName}`}
                                                                />
                                                            ) : (
                                                                <Avatar bg={"#01BF02"} size="sm" name={`${comment.firstName} ${comment.lastName}` || "User"} />
                                                            )}

                                                            {/* Comment Content */}
                                                            <Flex w={"100%"} justify={"flex-start"} align={"flex-end"}>
                                                                <VStack w={"100%"} align="start">
                                                                    <HStack>
                                                                        <Text
                                                                            as="button"
                                                                            fontWeight={"semibold"}
                                                                            color={"black"}
                                                                            size="sm"
                                                                            onClick={() => goToUserProfile(comment.userId)}
                                                                            _hover={{ textDecoration: 'underline' }}
                                                                        >
                                                                            {comment.firstName} {comment.lastName}
                                                                        </Text>
                                                                        <Text as="time" fontSize="xs" color="gray.500">
                                                                            {formatDistanceToNow(parseISO(comment.date), { addSuffix: true })}
                                                                        </Text>
                                                                    </HStack>

                                                                    <HStack w={"100%"} justify={"space-between"}>
                                                                        <Flex w={"100%"}>
                                                                            <Text>{comment.comment}</Text>
                                                                        </Flex>
                                                                        <Flex
                                                                            justify={"center"}
                                                                            align={"center"}
                                                                            p={1}
                                                                            mr={4}
                                                                            w={"fit-content"}
                                                                            _hover={{ boxShadow: "lg", bg: "white" }}
                                                                            borderRadius={"md"}
                                                                            style={{ cursor: 'pointer' }}
                                                                            onClick={() => handleCommentLikeToggle(post._id, comment._id)}
                                                                        >
                                                                            <FontAwesomeIcon
                                                                                icon={faHeart}
                                                                                isLoading={isLoading}
                                                                                size="sm"
                                                                                style={{ color: commentLikes[comment._id] ? "#02bf01" : "gray" }}
                                                                            />
                                                                            <Text ml={1}>{comment.upMarks.length}</Text>
                                                                        </Flex>
                                                                    </HStack>
                                                                </VStack>
                                                            </Flex>
                                                        </HStack>
                                                    </Box>
                                                ))}
                                            </Box>
                                        </>
                                    )}
                                </Box>
                            </Flex>
                        )}

                        {/* Updated Interactions Bar */}
                        <Stack justify={"flex-end"} direction="row" mt="4" align="center">
                            {post.contentType === 'job' && (
                                <Flex justify={"center"} align={"center"} p={2} w={"fit-content"} borderRadius={"md"} >
                                    <FontAwesomeIcon icon={faHandPointer} size="sm" />
                                    <Text ml={2}>{post.clicks} clicks</Text>
                                </Flex>
                            )}
                            {post.contentType === 'job' && (
                                <Flex justify={"center"} align={"center"} p={2} w={"fit-content"} borderRadius={"md"}>
                                    <FontAwesomeIcon icon={faUserPlus} size="sm" />
                                    <Text ml={2}>
                                        {applicantCounts[post.jobId] ? applicantCounts[post.jobId] : 0} applicants
                                    </Text>
                                </Flex>
                            )}
                            {post.contentType === 'original' && (
                                <Flex w={"90%"}>
                                    <Input
                                        value={comments[post._id] || ''}
                                        bg={"white"}
                                        onChange={(e) => handleCommentChange(post._id, e)}
                                        placeholder="Add a comment"
                                        _hover={{ boxShadow: "md", borderColor: "#01BF02" }}
                                        _focus={{ borderColor: "#01BF02", boxShadow: "lg" }}
                                    // ... other props
                                    />
                                    <Button
                                        ml={2}
                                        isLoading={isPosting[post._id]}
                                        bg={"#01BF02"}
                                        color={"white"}
                                        _hover={{ bg: "green" }}
                                        _active={{ bg: "#01BF02" }}
                                        onClick={() => submitComment(post._id)}
                                    >
                                        Post
                                    </Button>
                                </Flex>
                            )}
                            <Flex
                                justify={"center"}
                                align={"center"}
                                p={2}
                                w={"fit-content"}
                                _hover={{ boxShadow: "lg", bg: "white" }}
                                borderRadius={"md"}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    const alreadyLiked = post.likes.some(like => like.userId === currentUser);
                                    if (alreadyLiked) {
                                        handleUnlike(post._id);
                                    } else {
                                        handleLike(post._id);
                                    }
                                }}
                            >

                                <FontAwesomeIcon
                                    icon={faHeart}
                                    size="sm"
                                    style={{ color: post.likes.some(like => like.userId === currentUser) ? "#02bf01" : "gray" }}
                                />
                                <Text ml={2}>{post.likes.length}</Text>
                            </Flex>
                        </Stack>
                    </Box>
                ))}
                <div ref={loaderRef}>
                    {isLoading && <Spinner mt={8} mb={8} size={"xl"} color='#01BF02' />}
                </div>
            </VStack>
        </Flex >
    );
};

export default ProfileLiveFeed;