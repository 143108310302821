import React from 'react';
import { Box, VStack, HStack, Text, Button, Image } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const RecommendedJobCard = ({ job }) => {
    const navigate = useNavigate();

    const handleGoToJob = () => {
        navigate(`/job/${job._id}`);
    };

    return (
        <Box p={4} boxShadow='lg' borderRadius='md' bg='white'>
            <HStack align={"flex-start"}>
                <VStack h={"fit-content"} align='start' spacing={3}>
                    <Text fontSize='xl' fontWeight='bold'>{job.jobTitle}</Text>
                    <Text fontSize='md'>{job.companyName}</Text>
                    <HStack>
                        <Text fontSize='sm'>{job.locationCity}, {job.locationState}</Text>
                    </HStack>
                    <Text noOfLines={2} fontSize='sm'>
                        {job.description}
                    </Text>
                </VStack>
                {job.image && <Image borderRadius={"md"} shadow={"md"} w={"200px"} src={job.image} alt={`${job.jobTitle} Image`} />}
            </HStack>
            <Button size='sm' onClick={handleGoToJob} colorScheme='blue'>View Details</Button>
        </Box>
    );
};

export default RecommendedJobCard;
