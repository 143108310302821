import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
    Box,
    Heading,
    VStack,
    Button,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    useToast,
    Spinner,
    Flex,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Background from '../../assets/Background.webp';

function EditJob() {
    const { jobId } = useParams();
    const [showReturnButton, setShowReturnButton] = useState(false);
    console.log('Job ID:', jobId);
    const [job, setJob] = useState({
        userId: '',
        companyId: '',
        jobTitle: '',
        industry: '',
        workLocationType: '',
        locationCity: '',
        locationState: '',
        employmentType: '',
        paymentType: 'undefined',
        paymentDetails: {
            qualifier: 'Starting at',
            amount: ''
        },
        paymentFrequency: 'bi-weekly',
        additionalCompensation: {
            commission: false,
            bonuses: false,
            other: false,
        },
        additionalPerks: '',
        skills: [{ name: '', level: 'no_experience' }],
        description: '',
        revealPrice: '',
        bountyToRecruiters: false,
        bounty: 0,
        badge: {
            id: '',
            name: '',
            colorScheme: '',
            variant: ''
        },
        subTotal: 0,
        customCriteria: [{ criteria: '', description: '' }],
        videoURL: '',
        streetAddress: '',
        postalCode: 0,
        addressCountry: '',
        tags: [],
        responsibilities: [],
        qualification: [],
        active: true,
        validThrough: new Date(Date.now() + 90 * 24 * 60 * 60 * 1000),
        views: 0,
    });

    const toast = useToast();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);

    // Define fetchJobDetails using useCallback
    const fetchJobDetails = useCallback(async () => {
        try {
            const token = localStorage.getItem('auth_token');
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/jobs/${jobId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const { data } = response;
            setJob(data); // Update the job state with the fetched data
            setIsLoading(false); // Set loading to false when data is loaded
        } catch (error) {
            console.error('Failed to fetch job details:', error);
            setIsLoading(false); // Set loading to false in case of an error
        }
    }, [jobId]);

    useEffect(() => {
        console.log('EditJob component rendered.');
        fetchJobDetails();
    }, [fetchJobDetails]);

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        try {
            const token = localStorage.getItem('auth_token');
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/jobs/${jobId}`, job, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                toast({
                    title: 'Job updated successfully.',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });

                setShowReturnButton(true); // Show the "Return to Jobs" button
            } else {
                throw new Error('Failed to update job on the server');
            }
        } catch (error) {
            console.error('Failed to update job:', error);
            toast({
                title: 'Error updating job.',
                description: 'Please try again.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleReturnClick = () => {
        navigate('/job-listings');
    };


    return (
        <Flex direction="column" h="100vh" w="100%">
            {/* Background container */}
            <Box
                position="fixed"
                top={0}
                left={0}
                right={0}
                bottom={0}
                bgImage={Background}
                bgSize="cover"
                bgRepeat="no-repeat"
                bgPosition="center"
                zIndex={-1}
            />
            <Flex flex="1" overflowY="auto" pl={"320px"}>
                <Box p={4}>
                    <Heading color={"white"}>Edit Job</Heading>
                    {isLoading ? (
                        <Spinner size="xl" />
                    ) : (
                        <form onSubmit={handleFormSubmit}>
                            <Flex p={4} minW={"lg"} mt={4} borderRadius="lg" border={"1px"} bg={"white"} flex="1" overflowY="auto">
                                <Accordion w={"100%"} allowToggle>
                                    {/* Section 1: Job Details */}
                                    <AccordionItem>
                                        <h2>
                                            <AccordionButton>
                                                <Box as="span" flex="1" textAlign="left">
                                                    Job Details
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4}>
                                            <VStack spacing={4} align="stretch">
                                                <FormControl>
                                                    <FormLabel>Job Title</FormLabel>
                                                    <Input
                                                        type="text"
                                                        bg={"white"}
                                                        value={job.jobTitle}
                                                        onChange={(e) => setJob({ ...job, jobTitle: e.target.value })}
                                                    />
                                                </FormControl>

                                                <FormControl>
                                                    <FormLabel>Industry</FormLabel>
                                                    <Input
                                                        type="text"
                                                        disabled
                                                        bg={"white"}
                                                        value={job.industry}
                                                        onChange={(e) => setJob({ ...job, industry: e.target.value })}
                                                    />
                                                </FormControl>

                                                <FormControl>
                                                    <FormLabel>Location</FormLabel>
                                                    <Input
                                                        type="text"
                                                        disabled
                                                        bg={"white"}
                                                        value={job.location}
                                                        onChange={(e) => setJob({ ...job, location: e.target.value })}
                                                    />
                                                </FormControl>

                                                <FormControl>
                                                    <FormLabel>Type</FormLabel>
                                                    <Input
                                                        type="text"
                                                        disabled
                                                        bg={"white"}
                                                        value={job.type}
                                                        onChange={(e) => setJob({ ...job, type: e.target.value })}
                                                    />
                                                </FormControl>
                                            </VStack>
                                        </AccordionPanel>
                                    </AccordionItem>

                                    {/* Section 2: Description */}
                                    <AccordionItem>
                                        <h2>
                                            <AccordionButton>
                                                <Box as="span" flex="1" textAlign="left">
                                                    Description
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4}>
                                            <VStack spacing={4} align="stretch">

                                                <FormControl>
                                                    <FormLabel>New Description</FormLabel>
                                                    <Textarea
                                                        bg={"white"}
                                                        minH={"500px"}
                                                        value={job.description}
                                                        onChange={(e) => setJob({ ...job, description: e.target.value })}
                                                    />
                                                </FormControl>
                                            </VStack>
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                            </Flex>
                            <Button w={"100%"} mt={4} type="submit" bg={"188AB2"} color={"white"}>
                                Save
                            </Button>
                            {showReturnButton && (
                                <Button
                                    w={"100%"}
                                    mt={2}
                                    colorScheme="teal"
                                    onClick={handleReturnClick}
                                >
                                    Return to Jobs
                                </Button>
                            )}
                        </form>
                    )}
                </Box>
            </Flex>
        </Flex>
    );

}

export default EditJob;
