import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Flex, Text, Input, Button, IconButton, Avatar, Spinner, Image, Tooltip, useToast, Grid, FormControl, FormLabel, InputGroup, InputRightElement, useColorModeValue, Icon, VStack, HStack, Heading, Divider } from '@chakra-ui/react';
import EmojiPicker from 'emoji-picker-react';
import { socketAtom } from '../socketAtom';
import { useAtom } from 'jotai';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../App.css';
import { faPaperclip, faShieldHeart, faTimes, faSmile, faFile, faImage, faSearch, faExternalLinkAlt, faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import axiosInstance from './axiosInstance';

const determineFileType = (file) => {
    if (file.type === 'application/pdf') {
        return 'pdf';
    } else {
        return file.type.split('/')[0];
    }
};

function MessagingPage() {
    const location = useLocation();
    const [user, setUser] = useState({});
    const searchParams = new URLSearchParams(location.search);
    const projectRoom = searchParams.get('projectRoom');
    const [isUploading, setIsUploading] = useState(false);
    const [isProtecting, setIsProtecting] = useState(false);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [socket, setSocket] = useAtom(socketAtom);
    const [isTyping, setIsTyping] = useState(false);
    const [senderId, setSenderId] = useState(null);
    const [senderFirstName, setSenderFirstName] = useState(null);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [loading, setLoading] = useState(true);
    const messagesEndRef = useRef(null);
    const [pendingFile, setPendingFile] = useState(null);
    const toast = useToast();

    useEffect(() => {
        const roomName = projectRoom;
        console.log(`Joining room: ${roomName}`);
        socket.emit('joinProjectRoom', roomName);

        const handleNewProjectMessage = (newMessage) => {
            if (newMessage.roomName === roomName) {
                console.log('New message received:', newMessage);
                setMessages((msgs) => [...msgs, newMessage]);
            }
        };

        const handleExistingMessages = (messages) => {
            const filteredMessages = messages.filter(
                (message) => message.roomName === roomName
            );
            setMessages(filteredMessages);
            setLoading(false);
        };

        socket.on('newProjectMessage', handleNewProjectMessage);
        socket.on('existingMessages', handleExistingMessages);

        const handleTyping = ({ senderId, sendFirstName, roomName: messageRoomName }) => {
            if (messageRoomName !== roomName) return;

            if (senderId !== user._id) {
                setIsTyping(true);
                setSenderId(senderId);
                setSenderFirstName(sendFirstName);
                setTimeout(() => {
                    setIsTyping(false);
                    setSenderId(null);
                }, 2000);
            }
        };

        socket.on('typing', handleTyping);

        return () => {
            console.log(`Leaving room: ${roomName}`);
            socket.off('newProjectMessage', handleNewProjectMessage, { roomName });
            socket.off('existingMessages', handleExistingMessages, { roomName });
            socket.off('typing', handleTyping, { roomName });

            setMessages([]);
            setLoading(true);
        };
    }, [user._id, socket]);

    const fetchUserData = async () => {
        try {
            const { data } = await axiosInstance.get(`/user`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
                },
            });
            setUser(data);
        } catch (error) {
            console.error('Failed to fetch user data:', error);
            toast({
                title: 'Error loading profile',
                description: 'Unable to load user data. Please try again.',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    const TypingIndicator = ({ senderFirstName }) => {
        return (
            <Flex ml={2} mb={4} alignItems="center">
                <Text fontSize="sm" fontWeight="semibold" color="gray.500">
                    {senderFirstName} is typing...
                </Text>
            </Flex>
        );
    };

    const onEmojiClick = (emojiObject) => {
        setNewMessage((prevMessage) => prevMessage + emojiObject.emoji);
        setShowEmojiPicker(false);
    };

    const toggleEmojiPicker = () => setShowEmojiPicker(!showEmojiPicker);

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setIsUploading(true);
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axiosInstance.post('/project/upload', formData);

            if (response.status === 200) {
                setPendingFile({
                    url: response.data.fileUrl,
                    type: determineFileType(file),
                    name: file.name,
                });
            } else {
                throw new Error(response.data.message || 'Upload failed');
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        } finally {
            setIsUploading(false);
        }
    };

    const handleProtectedImageChange = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setIsProtecting(true);
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axiosInstance.post('/protected/project/upload', formData);

            if (response.status === 200) {
                setPendingFile({
                    url: response.data.fileUrl,
                    type: determineFileType(file),
                    name: file.name,
                });
            } else {
                throw new Error(response.data.message || 'Upload failed');
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        } finally {
            setIsProtecting(false);
        }
    };

    const protectedImageInputRef = useRef(null);

    const sendMessage = () => {
        if (newMessage.trim() || pendingFile) {
            const roomName = projectRoom;

            console.log('Sending message with:');
            console.log('roomName:', roomName);
            console.log('text:', newMessage);
            console.log('senderId:', user._id);
            console.log('connectionId:', projectRoom);
            console.log('fileUrl:', pendingFile?.url);
            console.log('fileType:', pendingFile?.type);

            socket.emit('projectMessage', {
                roomName,
                text: newMessage,
                senderId: user._id,
                senderFirstName: user.firstName,
                senderLastName: user.lastName,
                senderPFP: user.PFP,
                connectionId: roomName,
                fileUrl: pendingFile?.url,
                fileType: pendingFile?.type,
            });

            setNewMessage('');
            setIsTyping(false);
            setPendingFile(null);
        }
    };

    const renderMessageContent = (msg) => {
        if (msg.fileUrl) {
            switch (msg.fileType) {
                case 'image':
                    return (
                        <Box id={`message-${msg._id}`}>
                            <Text fontSize="md">{msg.text}</Text>
                            <Image src={msg.fileUrl} alt="Sent image" maxW="200px" maxH="200px" borderRadius="md" />
                            <Button as="a" href={msg.fileUrl} download colorScheme="blue" size="sm" mt={2}>
                                Download Image
                            </Button>
                        </Box>
                    );
                case 'pdf':
                    return (
                        <Box id={`message-${msg._id}`}>
                            <Text fontSize="md">{msg.text}</Text>
                            <iframe src={msg.fileUrl} width="100%" height="200px" style={{ border: 'none' }} title="PDF content"></iframe>
                            <Button as="a" href={msg.fileUrl} download="file.pdf" colorScheme="blue" size="sm" mt={2}>
                                Download PDF
                            </Button>
                        </Box>
                    );
                default:
                    return (
                        <Button as="a" href={msg.fileUrl} download colorScheme="blue" size="sm">
                            Download File
                        </Button>
                    );
            }
        } else if (msg.text) {
            return (
                <Box id={`message-${msg._id}`}><Text fontSize="md">{msg.text}</Text></Box>);
        } else {
            return (
                <Box id={`message-${msg._id}`}><Text fontSize="md">Message content unavailable</Text></Box>);
        }
    };

    const goToMessage = (messageId) => {
        const messageElement = document.getElementById(`message-${messageId}`);
        if (messageElement) {
            messageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    const [sharedFiles, setSharedFiles] = useState([]);
    const [sharedImages, setSharedImages] = useState([]);

    useEffect(() => {
        const extractSharedFiles = () => {
            const files = messages.filter((msg) => msg.fileUrl && msg.fileType !== 'image');
            setSharedFiles(files);
        };

        const extractSharedImages = () => {
            const images = messages.filter((msg) => msg.fileUrl && msg.fileType === 'image');
            setSharedImages(images);
        };

        extractSharedFiles();
        extractSharedImages();
    }, [messages]);


    const fileInputRef = useRef(null);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    const bgColor = useColorModeValue('gray.100', 'gray.700');
    const inputBgColor = useColorModeValue('white', 'gray.800');
    const messageBgColor = useColorModeValue('white', 'gray.700');
    const messageTextColor = useColorModeValue('gray.800', 'white');
    const timestampColor = useColorModeValue('gray.500', 'gray.400');

    return (
        <Flex direction="column" pl={{ base: 0, md: '300px' }} minH="100vh" bg={bgColor}>
            <HStack w="100%" flex={1}>
                <Box flex={1} p={4} overflowY="auto">
                    {loading ? (
                        <Flex justify="center" align="center" h="100%">
                            <Spinner size="xl" color="blue.500" />
                        </Flex>
                    ) : messages?.length > 0 ? (
                        <Grid templateColumns="1fr" gap={4} mb={4}>
                            {messages.map((msg, index) => {
                                const sentTime = new Date(msg.createdAt);
                                const currentTime = new Date();
                                const timeDiff = currentTime - sentTime;
                                let timeString;

                                const messageContent = renderMessageContent(msg);

                                if (timeDiff < 60000) {
                                    timeString = 'Just now';
                                } else if (timeDiff < 3600000) {
                                    const minutes = Math.floor(timeDiff / 60000);
                                    timeString = `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
                                } else if (timeDiff < 86400000) {
                                    const hours = Math.floor(timeDiff / 3600000);
                                    timeString = `${hours} hour${hours > 1 ? 's' : ''} ago`;
                                } else {
                                    const options = {
                                        month: 'short',
                                        day: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        hour12: true,
                                    };
                                    timeString = sentTime.toLocaleString('en-US', options);
                                }

                                return (
                                    <Flex
                                        key={index}
                                        w={"100%"}
                                        alignItems="flex-start"
                                        justifyContent={msg.senderId === user._id ? 'flex-end' : 'flex-start'}
                                    >
                                        <Avatar
                                            size="sm"
                                            name={msg.senderFirstName}
                                            src={msg.senderPFP}
                                            mr={2}
                                            display={senderId === user._id ? 'none' : 'block'}
                                        />
                                        <Box
                                            bg={msg.senderId === user._id ? '#02bf01' : messageBgColor}
                                            color={msg.senderId === user._id ? 'white' : messageTextColor}
                                            p={3}
                                            borderRadius="lg"
                                            maxW="75%"
                                            boxShadow="md"
                                        >
                                            <Flex direction="column">
                                                <Text fontSize="sm" fontWeight="bold" mb={1}>
                                                    {msg.senderFirstName} {msg.senderLastName}
                                                </Text>
                                                {messageContent}
                                                <Text fontSize="xs" mt={2} color={timestampColor}>
                                                    {timeString}
                                                </Text>
                                            </Flex>
                                        </Box>
                                    </Flex>
                                );
                            })}
                            {isTyping && <TypingIndicator senderId={senderId} />}
                            <div ref={messagesEndRef}></div>
                        </Grid>
                    ) : (
                        <Flex justify="center" align="center" h="100%">
                            <Text fontSize="xl" color="gray.500">No messages yet</Text>
                        </Flex>
                    )}
                </Box>
                <Box
                    maxH="100vh"
                    p={4}
                    mr={2}
                    borderRadius="lg"
                    bg="blackAlpha.600"
                    position="sticky"
                    maxW="450px"
                    top="5"
                    bottom={20}
                    zIndex="sticky"
                    overflowY="auto"
                >
                    <Flex direction="column" w="100%" bg="white" p={4} boxShadow="md" borderRadius="lg">
                        <VStack spacing={6} align="stretch">
                            <Box>
                                <Heading size="md" mb={4}>
                                    Shared Files
                                </Heading>
                                {sharedFiles.length > 0 ? (
                                    <VStack spacing={4} align="stretch">
                                        {sharedFiles.map((file, index) => (
                                            <Box
                                                key={index}
                                                borderWidth={1}
                                                borderColor="gray.200"
                                                borderRadius="md"
                                                p={4}
                                                _hover={{ bg: "gray.50" }}
                                            >
                                                <Flex alignItems="center" mb={2}>
                                                    <Icon as={FontAwesomeIcon} icon={faFile} size="2x" mr={4} color="blue.500" />
                                                    <Text fontWeight="semibold" flexGrow={1}>
                                                        {file.fileType.toUpperCase()} File
                                                    </Text>
                                                </Flex>
                                                <Flex alignItems="center" justifyContent="space-between">
                                                    <Text fontSize="sm" color="gray.500">
                                                        Shared by {file.senderFirstName} {file.senderLastName}
                                                    </Text>
                                                    <Text ml={2} fontSize="sm" color="gray.500">
                                                        {new Date(file.createdAt).toLocaleDateString()}
                                                    </Text>
                                                </Flex>
                                                <Flex justifyContent="flex-end" mt={2}>
                                                    <Button
                                                        size="xs"
                                                        mr={2}
                                                        colorScheme="blue"
                                                        onClick={() => goToMessage(file._id)}
                                                    >
                                                        Go to Message
                                                    </Button>
                                                    <Button
                                                        size="xs"
                                                        colorScheme="blue"
                                                        onClick={() => window.open(file.fileUrl, "_blank")}
                                                    >
                                                        Open File
                                                    </Button>
                                                </Flex>
                                            </Box>
                                        ))}
                                    </VStack>
                                ) : (
                                    <Text fontSize="md" color="gray.500" textAlign="center">
                                        No Files
                                    </Text>
                                )}
                            </Box>
                            <Box>
                                <Heading size="md" mb={4}>
                                    Shared Images
                                </Heading>
                                {sharedImages.length > 0 ? (
                                    <Grid templateColumns="repeat(auto-fill, minmax(120px, 1fr))" gap={4}>
                                        {sharedImages.map((image, index) => (
                                            <Box
                                                key={index}
                                                borderWidth={1}
                                                borderColor="gray.200"
                                                borderRadius="md"
                                                h={"fit-content"}
                                                overflow="hidden"
                                                _hover={{ borderColor: "blue.500" }}
                                            >
                                                <Image src={image.fileUrl} alt="Shared image" objectFit="cover" w="90%" />
                                                <Box p={2}>
                                                    <Text fontSize="sm" color="gray.500">
                                                        Shared by {image.senderFirstName} {image.senderLastName}
                                                    </Text>
                                                    <Text fontSize="sm" color="gray.500">
                                                        {new Date(image.createdAt).toLocaleDateString()}
                                                    </Text>
                                                    <Flex mt={1} w={"100%"} h={"fit-content"} align={"end"} justify={"space-between"}>
                                                    <Button
                                                        size="xs"
                                                        mr={2}
                                                        colorScheme="blue"
                                                        onClick={() => goToMessage(image._id)}
                                                    >
                                                        Go to Message
                                                    </Button>
                                                    <IconButton
                                                        size="xs"
                                                        icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
                                                        colorScheme="blue"
                                                        onClick={() => window.open(image.fileUrl, "_blank")}
                                                    >
                                                        Download Image
                                                    </IconButton>
                                                    </Flex>
                                                </Box>
                                            </Box>

                                        ))}
                                    </Grid>
                                ) : (
                                    <Text fontSize="md" color="gray.500" textAlign="center">
                                        No Images
                                    </Text>
                                )}
                            </Box>
                        </VStack>
                    </Flex>
                </Box>
            </HStack>
            <Flex p={4} bg={inputBgColor} boxShadow="md" position="sticky" bottom={0}>
                <Flex w="100%">
                    <FormControl>
                        <InputGroup>
                            <Input
                                placeholder="Type your message..."
                                value={newMessage}
                                onChange={(e) => {
                                    setNewMessage(e.target.value);
                                    socket.emit('typing', { roomName: projectRoom, senderId: user._id });
                                }}
                                onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                                borderRadius="full"
                                px={4}
                                py={2}
                                _focus={{
                                    borderColor: "blue.500",
                                    boxShadow: "0 0 0 1px #3182CE",
                                }}
                            />
                            <InputRightElement width="auto">
                                <IconButton
                                    onClick={toggleEmojiPicker}
                                    variant="ghost"
                                    icon={<FontAwesomeIcon size="lg" color="blue.500" icon={faSmile} />}
                                    borderRadius="full"
                                    aria-label="Emoji picker"
                                />
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                    <Flex direction="column" w="fit-content" justify="flex-start" ml={2}>
                        <Flex w="100%" justify="space-evenly">
                            <Input
                                type="file"
                                onChange={handleFileChange}
                                hidden
                                ref={fileInputRef}
                            />
                            <Tooltip label="Add Images or Files">
                                <IconButton
                                    icon={<FontAwesomeIcon size="lg" color="blue.500" icon={faPaperclip} />}
                                    variant="ghost"
                                    isLoading={isUploading}
                                    onClick={() => fileInputRef.current && fileInputRef.current.click()}
                                    aria-label="Add images or files"
                                />
                            </Tooltip>
                            <Input
                                type="file"
                                onChange={handleProtectedImageChange}
                                hidden
                                ref={protectedImageInputRef}
                                accept="image/*"
                            />
                            <Tooltip label="Add Protected Images">
                                <IconButton
                                    icon={<FontAwesomeIcon size="lg" color="blue.500" icon={faShieldHeart} />}
                                    variant="ghost"
                                    isLoading={isProtecting}
                                    onClick={() => protectedImageInputRef.current && protectedImageInputRef.current.click()}
                                    aria-label="Upload protected image"
                                />
                            </Tooltip>
                            <Tooltip label="Send Message">
                                <IconButton
                                    onClick={sendMessage}
                                    variant="ghost"
                                    icon={<FontAwesomeIcon size="lg" color="blue.500" icon={faPaperPlane} />}
                                    borderRadius="full"
                                    aria-label="Send message"
                                />
                            </Tooltip>
                        </Flex>
                        {pendingFile && (
                            <Flex bg="gray.100" borderRadius="lg" p={2} w="fit-content" alignItems="center" justify="flex-start" mt={2}>
                                <Text fontSize="sm" color="green.500" mr={2}>
                                    {pendingFile?.name?.length > 16 ? pendingFile.name.substring(0, 15) + '...' : pendingFile.name}
                                </Text>
                                <IconButton
                                    icon={<FontAwesomeIcon icon={faTimes} />}
                                    size="sm"
                                    variant="ghost"
                                    onClick={() => setPendingFile(null)}
                                    aria-label="Remove pending file"
                                />
                            </Flex>
                        )}
                    </Flex>
                </Flex>

                {showEmojiPicker && (
                    <Box position="absolute" bottom="70px" right="20px">
                        <EmojiPicker onEmojiClick={onEmojiClick} />
                    </Box>
                )}
            </Flex>
        </Flex>
    );
}

export default MessagingPage;