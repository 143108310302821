import React, { useState } from 'react';
import {
    FormControl,
    FormLabel,
    Box,
    Input,
    Button,
    Flex,
    Grid,
    List,
    ListItem,
    Select
} from '@chakra-ui/react';
import axios from 'axios';

const SkillsInput = ({ formData, setFormData }) => {
    const [newSkill, setNewSkill] = useState('');
    const [selectedSkillLevel, setSelectedSkillLevel] = useState('');
    const [skillSuggestions, setSkillSuggestions] = useState([]);

    // Function to capitalize the first letter of each word
    const capitalizeWords = (string) => string.replace(/\b\w/g, char => char.toUpperCase());

    // Function to handle the input change for new skills
    const handleNewSkillChange = (e) => {
        setNewSkill(capitalizeWords(e.target.value));
        if (e.target.value.trim() !== '') {
            searchSkill(e.target.value);
        }
    };

    // Function to search the skill in the database as the user types
    const searchSkill = async (skill) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/search-skills?q=${skill}`);
            setSkillSuggestions(response.data); // Update the skill suggestions
        } catch (error) {
            console.error('Error searching for skills:', error);
        }
    };

    // Function to select a skill from the suggestions
    const handleSelectSuggestion = (suggestion) => {
        const updatedSkills = [
            ...(formData.skillLevels || []),
            { skill: suggestion, level: selectedSkillLevel || 'beginner' } // Default level can be 'beginner' or any other
        ];
        setFormData({ ...formData, skillLevels: updatedSkills });
        setNewSkill('');
        setSkillSuggestions([]);
    };

    // Function to handle adding a new skill
    const handleAddSkill = async (e) => {
        if (newSkill.trim() === '' || selectedSkillLevel.trim() === '') return;
        // Prevent form submission if Enter or Tab key is pressed
        if (e.type === 'keydown' && (e.key !== 'Enter' && e.key !== 'Tab')) return;
        e.preventDefault();

        const updatedSkills = [
            ...(formData.skillLevels || []),
            { skill: newSkill, level: selectedSkillLevel }
        ];
        setFormData({ ...formData, skillLevels: updatedSkills });
        // Add skill to the database if it doesn't exist
        try {
            await axios.post(process.env.REACT_APP_API_URL + '/api/add-skill', { name: newSkill });
        } catch (error) {
            console.error('Error adding new skill:', error);
        }
        setNewSkill('');
        setSelectedSkillLevel('');
    };

    // Function to handle removing an existing skill
    const handleRemoveSkill = (index) => {
        const updatedSkills = formData.skillLevels.filter((_, i) => i !== index);
        setFormData({ ...formData, skillLevels: updatedSkills });
    };

    if (!formData.skillLevels || !Array.isArray(formData.skillLevels)) {
        // Initialize formData.skillLevels as an empty array if undefined
        setFormData({ ...formData, skillLevels: [] });
    }

    return (
        <FormControl id="skills">
            <FormLabel color={"white"}>Your Skills</FormLabel>
            {formData.skillLevels && Array.isArray(formData.skillLevels) ? (
                <Box mb={4}>
                    <Grid maxH={"400px"} overflowY={"scroll"} templateColumns="repeat(2, 1fr)" gap={4}>
                        {formData.skillLevels.map((skillObj, index) => (
                            <Flex key={index} alignItems="center" gridGap={2}>
                                {/* Display skill with level */}
                                <Input
                                    name={`skills[${index}].skill`}
                                    placeholder="Skill..."
                                    value={skillObj.skill}
                                    bg="#01bf02"
                                    w={"60%"}
                                    color="white"
                                    flex="1"
                                />
                                <Select
                                    name={`skills[${index}].level`}
                                    onChange={(e) => {
                                        const updatedSkills = formData.skillLevels.map((s, i) =>
                                            i === index ? { ...s, level: e.target.value } : s
                                        );
                                        setFormData({ ...formData, skillLevels: updatedSkills });
                                    }}
                                    value={skillObj.level}
                                    bg="black"
                                    w={"35%"}
                                    color="white"
                                >
                                    <option value="">Select proficiency</option>
                                    <option value="no_experience">No Experience</option>
                                    <option value="learning">Learning</option>
                                    <option value="competent">Competent</option>
                                    <option value="proficient">Proficient</option>
                                    <option value="authority">Authority</option>
                                </Select>
                                <Button fontSize="xl" size="xs" bg={"#EF476F"}
                                    color={"white"}
                                    _hover={{ bg: "red" }} onClick={() => handleRemoveSkill(index)}>
                                    X
                                </Button>
                            </Flex>
                        ))}
                    </Grid>
                    <Flex align="center" justify="flex-end" mt={4} position="relative">
                        <Input
                            placeholder="Add a skill..."
                            value={newSkill}
                            bg="white"
                            onChange={handleNewSkillChange}
                            onKeyDown={handleAddSkill}
                            mr={2}
                        />
                        <Select mr={2} bg={"white"} placeholder="Select level" onChange={(e) => setSelectedSkillLevel(e.target.value)}>
                            <option value="">Select proficiency</option>
                            <option value="no_experience">No Experience</option>
                            <option value="learning">Learning</option>
                            <option value="competent">Competent</option>
                            <option value="proficient">Proficient</option>
                            <option value="authority">Authority</option>
                        </Select>
                        <Button bg="#118AB2"
                            color="white"
                            _hover={{ bg: "#01bf02" }} size="sm" onClick={handleAddSkill}>
                            +
                        </Button>
                        {skillSuggestions.length > 0 && (
                            <List overflowY={"visible"} position="absolute" top="100%" mt={1} width="100%" borderRadius={"lg"} zIndex="10" bg="white" boxShadow="lg">
                                {skillSuggestions.map((suggestion, index) => (
                                    <ListItem
                                        key={index}
                                        p={2}
                                        cursor="pointer"
                                        _hover={{ bg: 'gray.100' }}
                                        onClick={() => handleSelectSuggestion(suggestion.name)}
                                    >
                                        {suggestion.name}
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </Flex>
                </Box>
            ) : (
                'Loading...'
            )}
        </FormControl>
    );
};

export default SkillsInput;
