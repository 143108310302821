//JobSeekerHomePage.js
import React, { useEffect, useState, useRef } from 'react';
import {
    Box, Heading, Stack, useToast, Image, Center, Button, Input, Icon, Link,
    Flex, Text, Card, CardBody, HStack, VStack, Tabs, TabList, TabPanels, Tab, TabPanel,
    Badge, Divider, Spinner, Grid, Avatar, useDisclosure, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, Spacer, IconButton, Textarea, SimpleGrid
} from "@chakra-ui/react";
import axios from 'axios';
import { FaSearch, FaRegMoneyBillAlt, FaUsers } from 'react-icons/fa';
import Notifications from '../../components/Notifications';
import io from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import Background from '../../assets/Background.webp';
import LiveFeed from './LiveFeed';
import ConnectionsPage from './Connections';
import ConnectionsRequest from '../../components/ConnectionRequests';
import SmartMatchRecruiter from '../../components/SmartMatchRecruiter';
import Header from '../../components/Header';
import JobSeekerPoints from './JobSeekerPoints';
import JobSeekerSearchModal from './JobSeekerSearchModal';
import BillingInformation from '../../components/JobSeekerPayment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faComments, faEnvelope, faStar } from '@fortawesome/free-solid-svg-icons';
import AudioRecorder from '../../components/AudioRecorder';

const socket = io(process.env.REACT_APP_API_URL);

const JobSeekerHomePage = () => {
    const [jobSeeker, setJobSeeker] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [notifications, setNotifications] = useState([]);
    const [messages, setMessages] = useState([]);
    const [isFeedbackModalOpen, setFeedbackModalOpen] = useState(false);
    const [commentsEnabled, setCommentsEnabled] = useState({});
    const [selectedApplicant, setSelectedApplicant] = useState(null);
    const [feedbackRating, setFeedbackRating] = useState(0);
    const [feedbackComment, setFeedbackComment] = useState('');
    const [applicationStatus, setApplicationStatus] = useState('');
    const [currentConversation, setCurrentConversation] = useState(null);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [setConversations] = useState([]);
    const [applicants, setApplicants] = useState([]);

    const navigate = useNavigate();
    const token = localStorage.getItem('auth_token');
    const messageInputRef = useRef(null);
    const toast = useToast();
    const formatGreeting = (name) => `Hi, ${name}`;

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + '/api/job-seekers/info', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then((response) => {
                setJobSeeker(response.data);
            })
            .catch((error) => {
                console.error('Error fetching job seeker info:', error);
            });
    }, [token]);

    const [recommendedJobs, setRecommendedJobs] = useState([]);
    const jobSeekerPoints = jobSeeker ? jobSeeker.points : 0;

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + '/api/getRecommendations', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then((response) => {
                setRecommendedJobs(response.data);
            })
            .catch((error) => {
                console.error('Error fetching recommended jobs:', error);
            });
    }, [token]);

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + '/api/notifications', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setNotifications(response.data);
            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        };

        fetchNotifications();
    }, [token]);

    const messagesEndRef = useRef(null);
    const messagesContainerRef = useRef(null);

    const scrollToBottom = () => {
        if (messagesEndRef.current && messagesContainerRef.current) {
            const messagesEndPosition = messagesEndRef.current.offsetTop;
            messagesContainerRef.current.scrollTop = messagesEndPosition - messagesContainerRef.current.offsetTop;
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {

        socket.on('receive_message', (message) => {
            console.log('New message:', message);

            setMessages(prevMessages => [...prevMessages, message]);
        });

        return () => {
            socket.off('receive_message');
        };
    }, []);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + '/api/conversations', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                setConversations(response.data.conversations);
            })
            .catch(error => {
                console.error('Error fetching conversations:', error);
            });
    }, [token]);

    const handleConversationClick = async (conversationId) => {
        console.log('Clicked Conversation ID:', conversationId);
        try {

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/messages/conversation/${conversationId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            setMessages(response.data.messages);
            setSelectedConversation(conversationId);
            socket.emit('join_conversation', { conversationId });
            setCurrentConversation(conversationId);


            response.data.messages.forEach(async (message) => {
                if (!message.read && message.recipient.toString() === jobSeeker._id) {
                    await axios.put(`${process.env.REACT_APP_API_URL}/api/messages/${message._id}/read`, {}, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                }
            });

        } catch (error) {
            console.error('Error in conversation click:', error);
        }
    };

    useEffect(() => {
        // Listen for new messages
        socket.on('receive_message', (message) => {
            console.log('New message:', message);
            // Update your state or UI with the new message
            setMessages(prevMessages => [...prevMessages, message]);
        });

        // Cleanup listener on unmount
        return () => {
            socket.off('receive_message');
            if (currentConversation) {
                socket.emit('leave_conversation', { conversationId: currentConversation });
            }
        };
    }, [currentConversation]);

    useEffect(() => {
        fetchApplicants();
    }, [token]);

    const fetchApplicants = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/jobSeekers/applicants`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            // Filter out applicants that are hidden
            const visibleApplicants = response.data.filter(applicant => !applicant.hide);
            setApplicants(visibleApplicants);

            const newCommentsEnabled = {};
            visibleApplicants.forEach(applicant => {
                newCommentsEnabled[applicant._id] = true;
            });
            setCommentsEnabled(newCommentsEnabled);
        } catch (error) {
            console.error('Error fetching applicants:', error);
        }
    };

    const handleBackClick = () => {
        // Deselect the conversation to go back to the list
        setSelectedConversation(null);
        socket.emit('leave_conversation', { conversationId: currentConversation });
        setCurrentConversation(null);
    };

    const openFeedbackModal = (applicantId, status) => {
        setSelectedApplicant(applicantId);
        setApplicationStatus(status);
        setFeedbackModalOpen(true);
        // Reset feedback form inputs
        setFeedbackRating(0);
        setFeedbackComment('');
    };

    const submitFeedback = async () => {
        if (feedbackRating < 1 || feedbackRating > 5) {
            toast({ title: "Invalid rating", description: "Rating must be between 1 and 5", status: "error" });
            return;
        }

        try {
            // Replace 'your-api-endpoint' with the actual endpoint
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/feedback`, {
                applicantId: selectedApplicant,
                rating: feedbackRating,
                comment: feedbackComment,
                applicationStatus: applicationStatus
            }, {
                headers: {
                    Authorization: `Bearer ${token}` // Ensure you're sending the authorization token if needed
                }
            });

            // Check response from the server for success
            if (response.data.success) {
                setCommentsEnabled(prevState => ({
                    ...prevState,
                    [selectedApplicant]: false
                }));
                toast({ title: "Feedback Submitted", description: "Your feedback has been submitted successfully", status: "success" });
                // Reset feedback form or additional actions

            } else {
                // Handle any issues reported by the server
                toast({ title: "Feedback Error", description: response.data.message, status: "error" });
            }

            setFeedbackModalOpen(false);
        } catch (error) {
            console.error('Error submitting feedback:', error);
            toast({ title: "Error", description: "Failed to submit feedback", status: "error" });
        }
    };

    const handleSend = async () => {
        const messageContent = messageInputRef.current.value;
        if (messageContent && selectedConversation) {
            try {
                // Fetch the conversation to get the employer's user ID
                const conversationResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/conversations/${selectedConversation}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const employerUserId = conversationResponse.data.employer._id;

                // Now proceed to send the message
                await axios.post(process.env.REACT_APP_API_URL + '/api/messages/job-seeker', {
                    employerId: employerUserId,  // Send the employer's user ID as the recipientId
                    content: messageContent
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                // Reset the input field
                messageInputRef.current.value = '';

                // Fetch the updated messages for the conversation
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/messages/conversation/${selectedConversation}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setMessages(response.data.messages);

            } catch (error) {
                console.error('Error sending message:', error);
            }
        }
    };

    const triggerJobMatching = async () => {
        try {
            await axios.post(process.env.REACT_APP_API_URL + '/match-jobs', {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            fetchRecommendedJobs();
        } catch (error) {
            console.error('Error triggering job matching:', error);
        }
    };

    const fetchRecommendedJobs = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/api/getJobs', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setRecommendedJobs(response.data);
        } catch (error) {
            console.error('Error fetching recommended jobs:', error);
        }
    };

    useEffect(() => {
        // Set up an interceptor to catch token expired errors
        const interceptor = axios.interceptors.response.use(
            response => response,
            error => {
                // Check if it's a token expired error
                if (error.response && error.response.data === 'TokenExpiredError') {
                    // Redirect to the login page
                    navigate('/login');
                }
                return Promise.reject(error);
            }
        );

        // Clean up the interceptor
        return () => {
            axios.interceptors.response.eject(interceptor);
        };
    }, [navigate]);

    const goToJobDetails = (jobId) => {
        navigate(`/job/${jobId}`);
    };

    const jobSeekerId = jobSeeker ? jobSeeker._id : null;

    const goToProfile = (jobSeekerId) => {
        if (jobSeekerId) {
            navigate(`/user/${jobSeekerId}`);
        }
    };

    const handleEditProfileClick = () => {
        navigate('/edit-jobseeker');
    };

    const hideApplicant = async (applicantId) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/applicants/hide/${applicantId}`);
            const data = response.data;
            console.log(data.message); // Or update state to reflect the change
        } catch (error) {
            console.error('Error hiding applicant:', error);
            // Handle error, maybe display a message to the user
        }
        fetchApplicants();
    };

    return (
        <Flex direction="column" minH="100vh" w="100%" bg="white" /* bgImage={Background} bgSize="cover" bgRepeat="no-repeat" bgPosition="center" */ >
            <Header />
            {/* Main Content Area */}
            <Flex justify={"center"} w={"100%"} p={4}>
                <HStack align={"flex-start"} justify={"space-evenly"} w={"100%"}>
                    <Flex
                        position="sticky"
                        top="10" // This sets the top position when it starts sticking.
                        shadow={"lg"}
                        bg="black"
                        mb={"10"}
                        w={"30%"}
                        p={4}
                        justify={"center"}
                        borderRadius="xl"
                        h={"fit-content"}
                        overflow="hidden"
                        zIndex={10} // Ensure it stays above other content.
                    >
                        {/* Live Feed */}
                        <Box shadow={"dark-lg"} overflowY={"hidden"} w={"100%"} bg={"whiteAlpha.500"} borderRadius={"xl"}>
                            <Stack align={"stretch"} spacing={5}>
                                <LiveFeed />
                            </Stack>
                        </Box>
                    </Flex>
                    <Flex direction={"column"} w={"65%"}>
                        <Flex mb={4}>
                            <Card borderRadius={"xl"} bg={"black"} w={"100%"} >
                                <CardBody
                                    style={{
                                        maxHeight: 'auto',
                                        overflowX: "scroll",
                                    }}
                                >
                                    <HStack
                                        p={2}
                                        bg={"whiteAlpha.500"}
                                        shadow={"lg"}
                                        borderRadius={"md"}
                                        w="full"
                                        spacing={4}
                                        mb={4}
                                        alignItems="flex-start" // Align children to the top
                                    >
                                        <Flex direction={"column"} align={"flex-start"} w={"full"}>
                                            <Heading color={"white"}>{jobSeeker && formatGreeting(jobSeeker.name)}</Heading>

                                            <Card p={3} h={"100%"} bg={"white"} shadow={"lg"} borderRadius={"md"} w={"100%"}>
                                                <Flex justifyContent="space-between" alignItems="center">
                                                    {jobSeeker && (
                                                        <Flex alignItems="center">
                                                            {jobSeeker.PFP ? (
                                                                <Image
                                                                    borderRadius="full"
                                                                    maxW={"100px"}
                                                                    src={jobSeeker.PFP}
                                                                    alt={`Profile image of ${jobSeeker.name}`}
                                                                    mr={4}
                                                                />
                                                            ) : (
                                                                <Avatar
                                                                    name={jobSeeker.name}
                                                                    size={"xl"}
                                                                    bg={"#01BF02"}
                                                                    mr={4}
                                                                />
                                                            )}
                                                            <VStack m={4} align="start">
                                                                <Text fontWeight={"semibold"}>Ai Summary of Your Resume:</Text>
                                                                <Text fontSize="sm" noOfLines={5}>{jobSeeker.candidateSummary}</Text>
                                                            </VStack>
                                                        </Flex>
                                                    )}
                                                    <Flex justify={"flex-end"}>
                                                        <Box>
                                                            <Box>
                                                                <Button mb={2} size={"sm"} bg={"#118AB2"} color={"white"} _hover={{ bg: "gray.500" }} onClick={() => jobSeekerId && goToProfile(jobSeekerId)}>
                                                                    View Profile
                                                                </Button>
                                                            </Box>
                                                            <Box>
                                                                <Button size={"sm"} bg={"#01BF02"} color={"white"} _hover={{ bg: "gray.500" }} onClick={handleEditProfileClick} ml={2}>
                                                                    Edit Profile
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                    </Flex>
                                                </Flex>
                                                <Flex w={"100%"} justify={"center"} align={"center"}>
                                                    <Box display={"flex"} flexDirection={"column"} alignItems={"center"} w={"100%"}>
                                                        <Divider w={"80%"} border={"1px"} color={"black"} />
                                                        <HStack w={"80%"} justify={"space-evenly"} align={"center"} spacing={10} m={4}>
                                                            <Flex
                                                                direction={"column"}
                                                                _hover={{
                                                                    shadow: "lg",
                                                                    bg: "#01BF02",
                                                                    color: "black",
                                                                    transform: "scale(1.05)"
                                                                }}
                                                                borderRadius={"xl"}
                                                                align={"center"}
                                                                p={2}
                                                                onClick={() => navigate('/jobboard')}
                                                                cursor="pointer"
                                                                transition="transform 0.2s ease-in-out"
                                                            >
                                                                <FaSearch size={"24px"} color={"#118AB2"} />
                                                                <Text mt={2}>Browse Jobs</Text>
                                                            </Flex>
                                                            <Flex
                                                                direction="column"
                                                                align="center"
                                                                _hover={{
                                                                    shadow: "lg",
                                                                    bg: "#01BF02",
                                                                    color: "black",
                                                                    transform: "scale(1.05)",
                                                                }}
                                                                borderRadius="xl"
                                                                p={2}
                                                                cursor="pointer"
                                                                transition="transform 0.2s ease-in-out"
                                                                onClick={onOpen} // Open the modal on click
                                                            >
                                                                <FaRegMoneyBillAlt size="24px" color="#118AB2" />
                                                                <Text mt={2}>See Earnings</Text>
                                                            </Flex>

                                                            <Modal isOpen={isOpen} onClose={onClose}>
                                                                <ModalOverlay />
                                                                <ModalContent>
                                                                    <ModalCloseButton />
                                                                    <ModalBody>
                                                                        <JobSeekerPoints points={jobSeekerPoints} />
                                                                    </ModalBody>
                                                                </ModalContent>
                                                            </Modal>

                                                            <JobSeekerSearchModal />

                                                        </HStack>
                                                    </Box>
                                                </Flex>

                                            </Card>
                                        </Flex>
                                        {jobSeeker ? (
                                            <Flex direction="column" w="full">
                                                <SmartMatchRecruiter jobSeeker={jobSeeker} />

                                                {(jobSeeker.interestedIndustries.length === 0 || jobSeeker.interestedJobTitles.length === 0) && (
                                                    <Flex mt={4} w={"100%"} justify={"center"}>
                                                        <Box bg={"white"} borderRadius={"md"} p={4} textAlign="center" w="60%">
                                                            <Text fontSize="lg" color="black">
                                                                Want our SmartMatch Recruiter to find the best job matches for you? 🌟
                                                                Update your job preferences in the
                                                                <Text as="span" m={2} color="#118AB2" cursor="pointer" onClick={() => handleEditProfileClick()}>
                                                                    Edit Profile
                                                                </Text>
                                                                page now! It's essential for personalized job matching. 🚀
                                                            </Text>
                                                        </Box>
                                                    </Flex>
                                                )}
                                                {/*
                                                                    <Flex mt={4} w={"100%"} justify={"center"}>
                                                                        <Box w={"600px"}> 
                                                                            <BillingInformation />
                                                                        </Box>
                                                                    </Flex>*/}

                                                {/* Other components or code */}
                                            </Flex>
                                        ) : (
                                            <div>Loading...</div>
                                        )}
                                    </HStack>
                                    <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={4}>
                                        <Flex>
                                            <Card bg="whiteAlpha.500" w={"100%"} >
                                                <CardBody
                                                    style={{
                                                        maxHeight: '400px',
                                                        overflowY: 'scroll'
                                                    }}
                                                >
                                                    <Notifications notifications={notifications} />
                                                    <ConnectionsRequest />
                                                </CardBody>
                                            </Card>
                                        </Flex>
                                        <Flex>
                                            <Card bg="whiteAlpha.500" w={"100%"} >
                                                <CardBody
                                                    style={{
                                                        maxHeight: '400px',
                                                        overflowY: 'scroll',
                                                    }}
                                                >
                                                    {selectedConversation ? (
                                                        // Detailed view
                                                        <Box maxH={"600px"}>
                                                            <Button
                                                                cursor="pointer"
                                                                onClick={handleBackClick}
                                                                mb={2}
                                                                shadow={"md"}
                                                                color={"black"}
                                                                bg="#FFD166"
                                                                position="sticky"
                                                            >
                                                                Back
                                                            </Button>
                                                            {/* Render the messages for the selected conversation */}
                                                            <Box
                                                                bg="white"
                                                                p={4}
                                                                maxH={"500px"}
                                                                borderRadius="md"
                                                                boxShadow="xl"
                                                                mb={4}
                                                                style={{
                                                                    maxHeight: 'auto',
                                                                    overflowY: 'auto',
                                                                }}
                                                                ref={messagesContainerRef} // Add ref here
                                                            >
                                                                <MessageList
                                                                    messages={messages}
                                                                    jobSeekerId={jobSeeker ? jobSeeker._id : null}
                                                                />
                                                                <div ref={messagesEndRef} />
                                                            </Box>
                                                            {/*  */}
                                                            <Flex
                                                                align="center"
                                                                position="sticky"
                                                                bottom="0"
                                                                bg="gray.400"
                                                                p={2}
                                                                borderRadius="md"
                                                                boxShadow="xl"
                                                            >
                                                                <Input bg={"white"} ref={messageInputRef} placeholder="Type a message..." flex="1" mr={2} />
                                                                <Button cursor="pointer" bg={"#118AB2"} color={"white"}
                                                                    _hover={{ bg: "#01BF02" }} onClick={handleSend}>Send</Button>
                                                            </Flex>
                                                        </Box>
                                                    ) : (
                                                        // List view
                                                        <VStack h={"600px"} overflowY={"scroll"} spacing={4} align="stretch">
                                                            <Heading size={"lg"} color={"white"} fontWeight="bold">Conversations</Heading>
                                                            <Box cursor="pointer">
                                                                <ConversationList
                                                                    onConversationClick={handleConversationClick}
                                                                    token={token} cursor="pointer"
                                                                />
                                                                <ConnectionsPage />
                                                            </Box>
                                                        </VStack>
                                                    )}
                                                </CardBody>
                                            </Card>
                                        </Flex>
                                    </SimpleGrid>
                                    <Box w={"100%"} mb={4}>
                                        <Heading color={"white"} mt={4}>Recommended Jobs</Heading>
                                        <Flex wrap="wrap" spacing={4}>
                                            {recommendedJobs.length === 0 ? (
                                                <Box p={4} textAlign="center" w="100%">
                                                    <Text fontSize="lg" color="white">
                                                        Update your job preferences in the
                                                        <Text m={2} as="span" color="#01BF02" cursor="pointer" onClick={() => handleEditProfileClick()}>
                                                            Edit Profile
                                                        </Text>page to get personalized job recommendations! 🌟
                                                    </Text>
                                                </Box>
                                            ) : (
                                                <SimpleGrid columns={{ sm: 2, md: 3, lg: 5 }} spacing={8}>
                                                    {recommendedJobs.slice(0, 5).map((job, index) => (
                                                        <Box
                                                            key={index}
                                                            p={1}
                                                            shadow="lg"
                                                            onClick={() => goToJobDetails(job._id)}
                                                            cursor="pointer"
                                                        >
                                                            <Card maxH="400px" bg="white">
                                                                <CardBody border="1px" borderRadius="md" _hover={{ bg: "black", color: "white", border: "1px", borderRadius: "md" }}>
                                                                    <Flex direction="column" align="center" justify="center" h="100%">
                                                                        <Heading size="sm">{job.jobTitle}</Heading>
                                                                        <Text>{job.industry}</Text>
                                                                        <HStack justify="space-evenly">
                                                                            <Text>{job.employmentType}</Text>
                                                                            <Text>|</Text>
                                                                            <Text>{job.workLocationType}</Text>
                                                                        </HStack>
                                                                        {/* Conditional Image Rendering */}
                                                                        {job.image && (
                                                                            <Image maxH={"200px"} borderRadius={"md"} src={job.image} alt={`Image for ${job.jobTitle}`} />
                                                                        )}
                                                                    </Flex>
                                                                </CardBody>
                                                            </Card>
                                                        </Box>
                                                    ))}
                                                </SimpleGrid>
                                            )}
                                        </Flex>
                                    </Box>
                                    <Heading color={"white"} mb={5}>My Applications</Heading>
                                    <SimpleGrid columns={{ sm: 2, md: 3, lg: 5 }} spacing={8}>
                                        {applicants.map((applicant, index) => (
                                            <Box position="relative" key={index}> {/* Add position relative here */}
                                                <IconButton
                                                    icon={<FontAwesomeIcon shadow={"dark-lg"} color='red' size='xl' icon={faCircleXmark} />}
                                                    isRound={true}
                                                    size={"xs"}
                                                    aria-label="Close"
                                                    position="absolute" // Position the button absolutely
                                                    right="-10px" // Position from the right
                                                    top="-10px" // Position from the top
                                                    zIndex="overlay" // Ensure it's above other content
                                                    variant="solid" // No background
                                                    _hover={{
                                                        transform: "scale(1.2)", // Enlarge the button by 20% on hover
                                                        bgColor: "red.100" // Change background color on hover (if not using ghost variant)
                                                    }}
                                                    onClick={() => hideApplicant(applicant._id)}
                                                />
                                                <Box
                                                    borderRadius="lg"
                                                    bg={
                                                        applicant.status === 'has been declined'
                                                            ? 'red.100'
                                                            : applicant.status === 'is pending'
                                                                ? 'white'
                                                                : 'white'
                                                    }
                                                    color="black"
                                                    h={"fit-content"}
                                                    _hover={{ bg: "black", color: "white", border: "1px", borderRadius: "md" }}
                                                    borderWidth="1px"
                                                    borderColor="gray.200"
                                                    cursor="pointer"
                                                    shadow="md"
                                                    onClick={() => goToJobDetails(applicant.job.jobId)}
                                                    overflow="hidden"
                                                >
                                                    {applicant.job.image && (
                                                        <Image
                                                            src={applicant.job.image}
                                                            alt={`${applicant.job.jobTitle} image`}
                                                            objectFit="cover"
                                                            width="full"
                                                            height="150px"
                                                        />
                                                    )}
                                                    <HStack align={"flex-end"}>
                                                        <Box p={4}>
                                                            <Heading size="md" mb={2}>{applicant.job.jobTitle}</Heading>
                                                            <Badge colorScheme="green" mr={2}>
                                                                {applicant.job.employmentType}
                                                            </Badge>
                                                            <Badge colorScheme="blue">
                                                                {applicant.job.workLocationType}
                                                            </Badge>
                                                            <Text mt={2} fontSize="sm" _hover={{ color: "white" }} color="gray.500">
                                                                Your application {applicant.status}.
                                                            </Text>
                                                        </Box>
                                                        {commentsEnabled[applicant._id] && applicant.canComment && (
                                                            <Flex justify={"flex-end"}>
                                                                <IconButton
                                                                    mr={1}
                                                                    mb={1}
                                                                    color="#118AB2"
                                                                    bg=""
                                                                    _hover={{ bg: "#118AB2", color: "white" }}
                                                                    aria-label="Give Feedback"
                                                                    icon={<FontAwesomeIcon size='xl' icon={faComments} />}
                                                                    onClick={(event) => {
                                                                        // Stop the event from propagating to the parent
                                                                        event.stopPropagation();
                                                                        openFeedbackModal(applicant._id, applicant.status);
                                                                    }}
                                                                />
                                                            </Flex>
                                                        )}
                                                    </HStack>
                                                </Box>
                                            </Box>
                                        ))}
                                    </SimpleGrid>
                                    <Modal isOpen={isFeedbackModalOpen} onClose={() => setFeedbackModalOpen(false)}>
                                        <ModalOverlay />
                                        <ModalContent>
                                            <ModalCloseButton />
                                            <ModalBody p={4}>
                                                <Heading size="md" mb={4}>Give Feedback</Heading>
                                                <Box display="flex" mb={3}>
                                                    {[...Array(5)].map((_, index) => (
                                                        <FontAwesomeIcon
                                                            key={index}
                                                            icon={faStar}
                                                            size='xl'
                                                            onClick={() => setFeedbackRating(index + 1)}
                                                            color={feedbackRating > index ? 'gold' : 'grey'}
                                                            style={{ cursor: 'pointer', marginRight: '5px' }}
                                                        />
                                                    ))}
                                                </Box>
                                                <Textarea
                                                    placeholder="Your comment"
                                                    value={feedbackComment}
                                                    onChange={(e) => setFeedbackComment(e.target.value)}
                                                    mb={3}
                                                />
                                                <Button colorScheme="blue" onClick={submitFeedback}>
                                                    Submit Feedback
                                                </Button>
                                            </ModalBody>
                                        </ModalContent>
                                    </Modal>
                                </CardBody>
                            </Card>
                        </Flex>

                        <Center w={"100%"}>
                            <Flex shadow={"dark-lg"} mt={4} m={4} p={4} w={"100%"} bg={"whiteAlpha.500"} borderRadius={"xl"} align="center" justify="center">
                                <Flex justify={"center"} maxW={"100%"} p={2} bg={"white"} w={"100%"} align="center">
                                    <Icon as={FontAwesomeIcon} icon={faEnvelope} color="#01BF02" mr={2} /> {/* Email icon */}
                                    <Text>Customer Support Email:</Text>
                                    <Link href="mailto:support@jobjar.ai" ml={2} color="#3182CE" fontWeight="semibold">support@jobjar.ai</Link> {/* Clickable email link */}
                                </Flex>
                            </Flex>
                        </Center>
                    </Flex>
                </HStack>
            </Flex>
        </Flex >
    );
}

const ConversationList = ({ onConversationClick, token }) => {
    const [conversations, setConversations] = useState([]);

    useEffect(() => {
        const fetchConversations = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + '/api/conversations', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                const conversationsWithDetails = await Promise.all(response.data.conversations.map(async (conversation) => {
                    try {
                        const employerInfoResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/employers/${conversation.employer?._id}`, {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        });

                        const unreadMessagesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/messages/conversation/unread/${conversation._id}`, {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        });

                        return {
                            ...conversation,
                            employerName: employerInfoResponse.data.employer.firstName,
                            companyName: employerInfoResponse.data.company.companyName,
                            companyLogo: employerInfoResponse.data.company.logo,
                            hasUnreadMessages: unreadMessagesResponse.data.messages.length > 0
                        };
                    } catch (error) {
                        console.error('Error fetching conversation details:', error);
                        return conversation;
                    }
                }));

                setConversations(conversationsWithDetails);
            } catch (error) {
                console.error('Error fetching conversations:', error);
            }
        };

        fetchConversations();
    }, [token]);

    return (
        <Flex direction="column" w="full">
            {conversations.length === 0 ? (
                <Box p={4} textAlign="center" bg="#118AB2" borderRadius="md">
                    <Text fontSize="lg" color="white">
                        🎈 No conversations yet! Companies that you have applied to can message you here! 🚀
                    </Text>
                </Box>

            ) : (
                conversations.map((conversation, index) => (
                    <Box
                        key={index}
                        onClick={() => onConversationClick(conversation._id)}
                        bg={conversation.hasUnreadMessages ? "#118AB2" : "white"}
                        p={2}
                        color={conversation.hasUnreadMessages ? "white" : "black"}
                        w={"100%"}
                        borderRadius="md"
                        mb={2}
                        _hover={{
                            backgroundColor: conversation.hasUnreadMessages ? "black" : "whiteAlpha.800",
                            border: conversation.hasUnreadMessages ? "1px solid" : "none",
                            borderColor: conversation.hasUnreadMessages ? "white" : "none"
                        }}
                    >
                        <Flex align={"center"} >
                            {conversation.hasUnreadMessages && (
                                <Badge mr={4} fontSize={"sm"} color='white' variant='outline'>New</Badge>
                            )}
                            {conversation.hasUnreadMessages ? (
                                <Text>{conversation.employerName} from {conversation.companyName} sent a message.</Text>
                            ) : (
                                <HStack>
                                    <Image borderRadius="full" shadow={"lg"} src={conversation.companyLogo} maxW={"35px"} maxH={"35px"} />
                                    <Text fontWeight={"semibold"}>
                                        {conversation.employerName} from {conversation.companyName}
                                    </Text>
                                </HStack>
                            )}
                        </Flex>
                    </Box>
                ))
            )}
        </Flex>
    );
};

const MessageList = ({ messages, jobSeekerId }) => {
    // Function to convert URLs in text to clickable links
    const renderMessageWithLinks = (message) => {
        // Regex to find URLs
        const urlRegex = /(\bhttps?:\/\/[-A-Z0-9+&@#\/%?=~_|$!:,.;]*[-A-Z0-9+&@#\/%=~_|$])/ig;
        // Split message by URLs, but keep the URLs in the output array
        let parts = message.split(urlRegex);
        let linkElements = [];

        parts = parts.filter((part, i) => {
            if (part.match(urlRegex)) {
                // Push to a separate array for URLs
                linkElements.push(<Link key={`link-${i}`} href={part} isExternal>{part}</Link>);
                return false;
            }
            return true;
        });

        // Combine text parts with link elements at the end
        return [...parts, ...linkElements];
    };

    return (
        <Flex direction="column" w="full">
            {messages.map((message, index) => (
                <Flex
                    key={index}
                    bg={message.sender.toString() === jobSeekerId ? "#118AB2" : "gray.200"}
                    color={message.sender.toString() === jobSeekerId ? "white" : "black"}
                    shadow={"md"}
                    alignSelf={
                        message.sender.toString() === jobSeekerId ? "flex-end" : "flex-start"}
                    borderRadius="md"
                    p={2}
                    m={2}
                    maxW="75%"
                    direction="column" // Set direction to column to stack elements
                >
                    {/* Render message text */}
                    <Text>{renderMessageWithLinks(message.content).filter(element => !(element.type === Link))}</Text>
                    {/* Render links at the bottom */}
                    <Box mt={2}>
                        {renderMessageWithLinks(message.content).filter(element => element.type === Link)}
                    </Box>
                </Flex>
            ))}
        </Flex>
    );
};

export default JobSeekerHomePage;