import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
    Box, Button, FormControl, FormLabel, Flex, useToast, Input, Stack, Grid, GridItem, FormErrorMessage, Textarea, HStack, Divider, Image, IconButton, VStack, Heading, Select
} from '@chakra-ui/react';
import { FaPlus, FaTimes } from 'react-icons/fa';
import CreatableSelect from 'react-select/creatable';
import Background from '../../assets/Background.webp';

function StateDropdown() {
    const states = [
        'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California',
        'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia',
        'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa',
        'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland',
        'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri',
        'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
        'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio',
        'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina',
        'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont',
        'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
    ];

    return (
        <Select placeholder="Select a state">
            {states.map((state, index) => (
                <option key={index} value={state}>{state}</option>
            ))}
        </Select>
    );
}

function SocialMediaDropdown() {
    const platforms = [
        'X - (formerly Twitter)',
        'PublicSquare',
        'Facebook',
        'Instagram',
        'LinkedIn',
        'YouTube',
        'TikTok',
        'Pinterest',
        'Snapchat',
        'Reddit',
        'Tumblr',
        'Twitch',
        'Discord',
        'Medium',
        'Quora',
        'Vimeo',
        'SoundCloud',
        'Flickr',
        'Behance',
        'Dribbble',
        'GitHub',

    ];

    return (
        <Select placeholder="Select a social media platform">
            {platforms.map((platform, index) => (
                <option key={index} value={platform}>{platform}</option>
            ))}
        </Select>
    );
}

function AddBusiness() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [socialMediaLinks, setSocialMediaLinks] = useState([]);
    const [subAccounts, setSubAccounts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [logoFile, setLogoFile] = useState(null);
    const [industries, setIndustries] = useState([]);
    const [selectedIndustry, setSelectedIndustry] = useState(null);
    const [certificationsAndAwards, setCertificationsAndAwards] = useState([]);
    const [logoPreview, setLogoPreview] = useState();
    const [address, setAddress] = useState({
        street: '',
        city: '',
        state: '',
        country: 'USA',
        postalCode: '',
    });

    const navigate = useNavigate();
    const toast = useToast();

    useEffect(() => {
        async function fetchIndustries() {
            setIsLoading(true);
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + '/api/industries/search');
                const industryOptions = response.data.map(industry => ({
                    label: industry.industry,
                    value: industry._id,
                }));

                setIndustries(industryOptions);
            } catch (error) {
                // Handle error appropriately
                console.error('Error fetching industries:', error);
            }
            setIsLoading(false);
        }

        fetchIndustries();
    }, []);

    useEffect(() => {
        console.log('Industries state updated:', industries);  // Add this line to log the state
    }, [industries]);

    const saveNewIndustry = async (newIndustry) => {
        const authToken = localStorage.getItem('auth_token');
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_URL + '/api/industries',
                { industry: newIndustry },
                {
                    headers: {
                        'Authorization': `Bearer ${authToken}`
                    }
                }
            );
            // Assuming the response will contain the industry object with '_id'
            const newIndustryOption = { label: newIndustry, value: response.data._id };
            setIndustries(prev => [...prev, newIndustryOption]);
            setSelectedIndustry(newIndustryOption); // Update the selectedIndustry with the new industry
        } catch (error) {
            console.error('Error saving new industry:', error);
            toast({
                title: 'Error',
                description: 'Failed to save new industry',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleIndustryChange = (selectedOption) => {
        setSelectedIndustry(selectedOption);
        if (selectedOption && selectedOption.__isNew__) {
            setIsLoading(true);
            saveNewIndustry(selectedOption.label).then(() => {
                setIsLoading(false);
            });
        }
    };

    const addCertificationOrAward = () => {
        setCertificationsAndAwards([...certificationsAndAwards, { name: '' }]);
    };

    const removeCertificationOrAward = (index) => {
        const updatedCertificationsAndAwards = [...certificationsAndAwards];
        updatedCertificationsAndAwards.splice(index, 1);
        setCertificationsAndAwards(updatedCertificationsAndAwards);
    };

    const updateCertificationOrAward = (index, newName) => {
        const updatedCertificationsAndAwards = [...certificationsAndAwards];
        updatedCertificationsAndAwards[index].name = newName;
        setCertificationsAndAwards(updatedCertificationsAndAwards);
    };

    const handleAddressChange = (e) => {
        const { name, value } = e.target;
        setAddress(prevAddress => ({
            ...prevAddress,
            [name]: value,
        }));
    };

    const handleLogoChange = (event) => {
        const file = event.target.files[0];
        setLogoFile(file);
        setLogoPreview(URL.createObjectURL(file));
    };

    useEffect(() => {
        return () => {
            logoPreview && URL.revokeObjectURL(logoPreview);
        };
    }, [logoPreview]);

    const onSubmit = async (formDataFields) => {
        setIsLoading(true);
        const formData = new FormData();

        // Append the social media links and subaccounts as JSON strings
        formData.append('socialMediaLinks', JSON.stringify(socialMediaLinks));
        formData.append('address', JSON.stringify(address));
        formData.append('certificationsAndAwards', JSON.stringify(certificationsAndAwards));
        formData.append('subAccounts', JSON.stringify(subAccounts.map(acc => ({
            personId: acc.person,
            permissionLevel: acc.permissionLevel
        }))));

        // Append the logo file if it exists
        if (logoFile) {
            formData.append('logo', logoFile);
        }

        // Append the selected industry
        formData.append('industry', selectedIndustry ? selectedIndustry.value : '');

        // Append the rest of the form fields to formData
        Object.keys(formDataFields).forEach(key => {
            if (key !== 'logo') { // Skip the logo field since it's already handled
                formData.append(key, formDataFields[key]);
            }
        });

        // Log the formData for debugging purposes
        for (let [key, value] of formData.entries()) {
            console.log(`${key}: ${value}`);
        }

        // Get the authorization token
        const authToken = localStorage.getItem('auth_token');

        try {
            // Make the POST request to your API
            const response = await axios.post(process.env.REACT_APP_API_URL + '/api/create-business', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${authToken}`,
                },
            });

            if (response.status === 201) {
                console.log('Logo URL:', response.data.logoUrl);
                navigate('/settings');
            }
        } catch (error) {
            console.error("Error sending data to server: ", error);
            toast({
                title: 'Error',
                description: 'An error occurred while submitting the form. Please try again later.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false); // Stop the loading indicator
        }
    };

    const addSocialMediaLink = () => {
        setSocialMediaLinks([...socialMediaLinks, { name: '', link: '' }]);
    };

    const removeSocialMediaLink = (index) => {
        const updatedLinks = [...socialMediaLinks];
        updatedLinks.splice(index, 1);
        setSocialMediaLinks(updatedLinks);
    };

    const updateSocialMediaLink = (index, updatedLink) => {
        const updatedLinks = [...socialMediaLinks];
        updatedLinks[index] = updatedLink;
        setSocialMediaLinks(updatedLinks);
    };
    const addSubAccount = () => {
        setSubAccounts([...subAccounts, { person: '', permissionLevel: '' }]);
    };

    const removeSubAccount = (index) => {
        const updatedAccounts = [...subAccounts];
        updatedAccounts.splice(index, 1);
        setSubAccounts(updatedAccounts);
    };

    const updateSubAccount = (index, updatedAccount) => {
        const updatedAccounts = [...subAccounts];
        updatedAccounts[index] = updatedAccount;
        setSubAccounts(updatedAccounts);
    };

    const styles = {
        formContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            padding: '20px',
            maxWidth: '600px',
            margin: '0 auto',
            backgroundColor: '#f5f5f5',
            borderRadius: '10px',
        },
        label: {
            fontWeight: 'bold',
            marginTop: '10px',
        },
        input: {
            padding: '5px',
            margin: '5px 0',
            borderRadius: '5px',
            border: '1px solid #ccc',
            width: '100%',
        },
        textarea: {
            width: '100%',
            height: '80px',
            padding: '5px',
            margin: '5px 0',
            borderRadius: '5px',
            border: '1px solid #ccc',
        },
        button: {
            marginTop: '10px',
            backgroundColor: '#007BFF',
            color: 'white',
            padding: '7px 15px',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
        },
        section: {
            marginTop: '20px',
        }
    };

    return (
        <Flex p={4} direction="column" minHeight="100vh" w="100%" bgImage={Background} bgSize="cover" bgRepeat="no-repeat" bgPosition="center">
            <Flex justifyContent="center" p={4}>
                <Box minHeight="80%" overflowY={"scroll"} w="full" bg="white" maxW="1000px" p={8} rounded="lg" shadow="lg">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={6}>
                            {/* Company Details */}
                            <Box>
                                <Heading as="h2" size="md" mb={4}>Business Details</Heading>
                                <Grid templateColumns={["1fr", "1fr 1fr"]} gap={6}>
                                    <FormControl isInvalid={errors.businessName}>
                                        <FormLabel>Business Name</FormLabel>
                                        <Input type="text" {...register("businessName")} />
                                        <FormErrorMessage>{errors.businessName?.message}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={errors.website}>
                                        <FormLabel>Website</FormLabel>
                                        <Input type="text" {...register("website")} />
                                        <FormErrorMessage>{errors.website?.message}</FormErrorMessage>
                                    </FormControl>
                                </Grid>
                            </Box>

                            {/* Contact Information */}
                            <Box>
                                <Heading as="h2" size="md" mb={4}>Contact Information</Heading>
                                <Grid templateColumns={["1fr", "1fr 1fr"]} gap={6}>
                                    <FormControl>
                                        <FormLabel>Main Contact</FormLabel>
                                        <Input type="text" {...register("mainContact")} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Contact Title</FormLabel>
                                        <Input type="text" {...register("contactTitle")} />
                                    </FormControl>
                                    <FormControl isInvalid={errors.billingEmail}>
                                        <FormLabel>Billing Email</FormLabel>
                                        <Input type="email" {...register("billingEmail")} />
                                        <FormErrorMessage>{errors.billingEmail?.message}</FormErrorMessage>
                                    </FormControl>
                                </Grid>
                            </Box>

                            {/* Address */}
                            <Box>
                                <Heading as="h2" size="md" mb={4}>Address</Heading>
                                <VStack spacing={4} align="stretch">
                                    <FormControl isInvalid={errors.address}>
                                        <FormLabel>Street Address</FormLabel>
                                        <Input type="text" name="street" value={address.street} onChange={handleAddressChange} />
                                        <FormErrorMessage>{errors.address?.message}</FormErrorMessage>
                                    </FormControl>
                                    <Grid templateColumns={["1fr", "1fr 1fr"]} gap={6}>
                                        <FormControl>
                                            <FormLabel>City</FormLabel>
                                            <Input type="text" name="city" value={address.city} onChange={handleAddressChange} />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>State</FormLabel>
                                            <StateDropdown />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Country</FormLabel>
                                            <Input type="text" name="country" value={address.country} onChange={handleAddressChange} />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Postal Code</FormLabel>
                                            <Input type="text" name="postalCode" value={address.postalCode} onChange={handleAddressChange} />
                                        </FormControl>
                                    </Grid>
                                </VStack>
                            </Box>

                            {/* Additional Information */}
                            <Box>
                                <Heading as="h2" size="md" mb={4}>Additional Information</Heading>
                                <Grid templateColumns={["1fr", "1fr 1fr"]} gap={6}>
                                    <FormControl>
                                        <FormLabel>Description</FormLabel>
                                        <Textarea {...register("description")} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Number of Employees</FormLabel>
                                        <Input type="number" {...register("amountEmployees")} />
                                    </FormControl>
                                    <FormControl isInvalid={errors.industry}>
                                        <FormLabel>Industry</FormLabel>
                                        <CreatableSelect
                                            isClearable
                                            isDisabled={isLoading}
                                            isLoading={isLoading}
                                            onCreateOption={saveNewIndustry}
                                            onChange={handleIndustryChange}
                                            options={industries}
                                            value={selectedIndustry}
                                        />
                                        <FormErrorMessage>{errors.industry?.message}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Logo</FormLabel>
                                        <Flex alignItems="center">
                                            <Input type="file" accept="image/*" onChange={handleLogoChange} hidden id="logo-upload" />
                                            <FormLabel htmlFor="logo-upload" cursor="pointer" padding="5px 10px" bg="green.500" color="white" borderRadius="md" _hover={{ bg: "green.600" }}>
                                                {logoFile ? 'Change Logo' : 'Upload Logo'}
                                            </FormLabel>
                                            {logoFile && (
                                                <Box ml={4} boxSize="100px">
                                                    <Image src={logoPreview} alt="Logo preview" objectFit="contain" />
                                                </Box>
                                            )}
                                        </Flex>
                                    </FormControl>
                                </Grid>

                                {/* Certifications and Awards */}
                                <Box mt={6}>
                                    <Flex justify="space-between" mb={4}>
                                        <Heading as="h3" size="md">Certifications and Awards</Heading>
                                        <Button leftIcon={<FaPlus />} onClick={addCertificationOrAward} colorScheme="green" size="sm">Add</Button>
                                    </Flex>
                                    <VStack spacing={4} align="stretch" divider={<Divider />}>
                                        {certificationsAndAwards.map((item, index) => (
                                            <Flex key={index} justify="space-between" py={2} bg={index % 2 === 0 ? "gray.50" : "white"}>
                                                <Input value={item.name} onChange={(e) => updateCertificationOrAward(index, e.target.value)} />
                                                <IconButton icon={<FaTimes />} aria-label="Remove" onClick={() => removeCertificationOrAward(index)} size="sm" variant="ghost" colorScheme="red" ml={2} />
                                            </Flex>
                                        ))}
                                    </VStack>
                                </Box>

                                {/* Social Media Links */}
                                <Box mt={6}>
                                    <Flex justify="space-between" mb={4}>
                                        <Heading as="h3" size="md">Social Media Links</Heading>
                                        <Button leftIcon={<FaPlus />} onClick={addSocialMediaLink} colorScheme="green" size="sm">Add</Button>
                                    </Flex>
                                    <VStack spacing={4} align="stretch" divider={<Divider />}>
                                        {socialMediaLinks.map((link, index) => (
                                            <Flex key={index} justify="space-between" py={2} bg={index % 2 === 0 ? "gray.50" : "white"}>
                                                <SocialMediaDropdown />
                                                <Input placeholder="URL" value={link.link} onChange={e => updateSocialMediaLink(index, { ...link, link: e.target.value })} mr={2} />
                                                <IconButton icon={<FaTimes />} aria-label="Remove" onClick={() => removeSocialMediaLink(index)} size="sm" variant="ghost" colorScheme="red" />
                                            </Flex>
                                        ))}
                                    </VStack>
                                </Box>
                            </Box>

                            {/* Submit Button */}
                            <Flex justify="flex-end">
                                <Button type="submit" colorScheme="green" size="lg" isLoading={isLoading}>
                                    Save Company Information
                                </Button>
                            </Flex>
                        </Stack>
                    </form>
                </Box>
            </Flex>
        </Flex>
    );
}

export default AddBusiness;