import React, { useState, useEffect } from 'react';
import {
    Box,
    Heading,
    Divider,
    FormControl,
    FormLabel,
    Input,
    Button,
    Flex,
    Text,
    useToast,
    InputGroup,
    InputRightElement,
    Link,
    HStack
} from '@chakra-ui/react';
import InputMask from 'react-input-mask';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import axiosInstance from './axiosInstance';

const EmployerSettings = () => {
    const [employerDetails, setEmployerDetails] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const toast = useToast();
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    useEffect(() => {
        const getEmployerDetails = async () => {
            try {
                const response = await axiosInstance.get('/user', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
                    },
                });

                setEmployerDetails(response.data);

            } catch (error) {
                console.error('Error fetching employer details:', error);

                toast({
                    title: "Error",
                    description: "Error fetching employer details.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        };

        getEmployerDetails();
    }, [toast]);


    const handleInputChange = (e) => {
        let { name, value } = e.target;

        if (name === "schedulingLink") {
            value = formatSchedulingLink(value);
        }

        setEmployerDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    const formatSchedulingLink = (link) => {
        if (!link.startsWith('http://') && !link.startsWith('https://')) {
            link = 'https://' + link;
        }
        return link.replace('www.', '');
    };

    const handleSwitchChange = (name) => {
        setEmployerDetails((prevDetails) => ({
            ...prevDetails,
            [name]: !prevDetails[name],
        }));
    };

    const saveDetails = async () => {
        try {
            const { firstName, lastName, phoneNumber, schedulingLink, yourTitle } = employerDetails;
            const update = {
                firstName,
                lastName,
                phoneNumber,
                schedulingLink,
                yourTitle
            };

            const response = await axiosInstance.put('/employer-details/', update, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
                },
            });

            setIsEditing(false);

            toast({
                title: "Success",
                description: "Successfully updated employer details.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });

        } catch (error) {
            console.error('Error updating employer details:', error);

            toast({
                title: "Error",
                description: "Error updating employer details.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const toggleEditing = () => {
        setIsEditing(!isEditing);
    };

    const handleChangePassword = async () => {
        // Frontend validation
        if (!currentPassword) {
            toast({
                title: "Error",
                description: "Please enter your current password.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (newPassword !== confirmPassword) {
            toast({
                title: "Error",
                description: "New password and confirmation do not match.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (newPassword.length < 8) {
            toast({
                title: "Error",
                description: "Password should be at least 8 characters.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        // Make API request to the backend
        try {
            // Make API request to the backend
            const response = await axiosInstance.put(
                '/change-password',
                { currentPassword, newPassword },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
                    },
                }
            );
            console.log('Response:', response.data);

            // Handle successful password change
            if (response.data.success) {
                toast({
                    title: "Success",
                    description: "Password changed successfully!",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
            } else {
                toast({
                    title: "Error",
                    description: response.data.message || 'Error changing password.',
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }

        } catch (error) {
            console.error('Error changing password:', error);
            toast({
                title: "Error",
                description: "An error occurred while changing the password. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };


    return (
        <Box borderRadius="lg" p={5} boxShadow="md" bg="white">
            <Flex justifyContent="space-between">
                <Heading size="md">Account Management</Heading>
                <Button size="sm" onClick={toggleEditing}>
                    {isEditing ? 'Cancel' : 'Edit'}
                </Button>
            </Flex>
            <Divider my={3} />
            <HStack>
                <FormControl mt={4}>
                    <FormLabel fontWeight={"bold"}>First Name:</FormLabel>
                    {isEditing ? (
                        <Input
                            name="firstName"
                            value={employerDetails.firstName || ''}
                            onChange={handleInputChange}
                        />
                    ) : (
                        <Text>{employerDetails.firstName}</Text>
                    )}
                </FormControl>
                <FormControl mt={4}>
                    <FormLabel fontWeight={"bold"}>Last Name:</FormLabel>
                    {isEditing ? (
                        <Input
                            name="lastName"
                            value={employerDetails.lastName || ''}
                            onChange={handleInputChange}
                        />
                    ) : (
                        <Text>{employerDetails.lastName}</Text>
                    )}
                </FormControl>
                <FormControl mt={4}>
                    <FormLabel>Position Title</FormLabel>
                    {isEditing ? (
                        <Input
                            name="yourTitle"
                            value={employerDetails.yourTitle || ''}
                            onChange={handleInputChange}
                        />
                    ) : (
                        <Text>{employerDetails.yourTitle}</Text>
                    )}
                </FormControl>
            </HStack>

            <FormControl mt={8} mb={8}>
                {isEditing ? (
                    <>
                        <FormLabel>Enter Current Password</FormLabel>
                        <InputGroup>
                            <Input
                                type={showCurrentPassword ? "text" : "password"}
                                value={currentPassword}
                                onChange={e => setCurrentPassword(e.target.value)}
                            />
                            <InputRightElement>
                                <Button size="sm" onClick={() => setShowCurrentPassword(!showCurrentPassword)}>
                                    {showCurrentPassword ? <ViewOffIcon /> : <ViewIcon />}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                    </>
                ) : null}
            </FormControl>

            {currentPassword && isEditing ? (
                <>
                    <FormControl mt={4}>
                        <FormLabel>New Password</FormLabel>
                        <InputGroup>
                            <Input
                                type={showNewPassword ? "text" : "password"}
                                value={newPassword}
                                onChange={e => setNewPassword(e.target.value)}
                            />
                            <InputRightElement>
                                <Button size="sm" onClick={() => setShowNewPassword(!showNewPassword)}>
                                    {showNewPassword ? <ViewOffIcon /> : <ViewIcon />}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>

                    <FormControl mt={4}>
                        <FormLabel>Confirm New Password</FormLabel>
                        <InputGroup>
                            <Input
                                type={showConfirmPassword ? "text" : "password"}
                                value={confirmPassword}
                                onChange={e => setConfirmPassword(e.target.value)}
                            />
                            <InputRightElement>
                                <Button size="sm" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                    {showConfirmPassword ? <ViewOffIcon /> : <ViewIcon />}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>

                    <Button mt={4} onClick={handleChangePassword}>Change Password</Button>
                </>
            ) : <Text fontWeight={"semibold"} >******* Change Password</Text>}

            <HStack w={"100%"}>

                <FormControl mt={4}>
                    <FormLabel fontWeight={"semibold"}>Phone Number</FormLabel>
                    {isEditing ? (
                        <InputMask
                            mask="(999) 999-9999"
                            value={employerDetails.phoneNumber || ''}
                            onChange={handleInputChange}
                            name="phoneNumber"
                            className="chakra-input css-1b6z2qz" // Apply Chakra UI styling
                            maskChar={null}
                        >
                            {(inputProps) => <Input {...inputProps} />}
                        </InputMask>
                    ) : (
                        <Text>{employerDetails.phoneNumber}</Text>
                    )}
                </FormControl>
                <FormControl mt={4}>
                    <FormLabel fontWeight={"semibold"}>Scheduling Link</FormLabel>
                    {isEditing ? (
                        <Input
                            name="schedulingLink"
                            value={employerDetails.schedulingLink || ''}
                            onChange={handleInputChange}
                        />
                    ) : (
                        employerDetails.schedulingLink ? (
                            <Link href={employerDetails.schedulingLink} isExternal color="blue.500">
                                {employerDetails.schedulingLink}
                            </Link>
                        ) : (
                            <Text>No Scheduling link provided</Text>
                        )
                    )}
                </FormControl>
            </HStack>




            {isEditing && <Button mt={4} bg={"#118AB2"} color={"white"} _hover={{ bg: "#01BF02" }} onClick={saveDetails}>Save Changes</Button>}
        </Box>
    );
};

export default EmployerSettings;
