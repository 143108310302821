import React, { useState } from 'react';
import axios from 'axios';
import {
    Box, Button, FormControl, FormLabel, Input, Heading, Text, VStack
} from '@chakra-ui/react';

function PointsPoolManagement() {
    const [pointsToAdd, setPointsToAdd] = useState('');
    const [launchMessage, setLaunchMessage] = useState('');
    const [addPointsMessage, setAddPointsMessage] = useState('');

    const handleInputChange = (e) => {
        setPointsToAdd(e.target.value);
    };

    const handleLaunchPool = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/api/launch-pool', { points: pointsToAdd });
            setLaunchMessage(response.data.message);
        } catch (error) {
            setLaunchMessage('Error: Unable to launch the pool.');
            console.error('Error launching pool:', error);
        }
        setPointsToAdd('');
    };

    const handleAddPoints = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.put(process.env.REACT_APP_API_URL + '/api/points-pool/add', { points: pointsToAdd });
            setAddPointsMessage(response.data.message);
        } catch (error) {
            setAddPointsMessage('Error: Unable to add points to the pool.');
            console.error('Error adding points:', error);
        }
        setPointsToAdd('');
    };

    return (
        <Box p={5}>
            <VStack spacing={5}>
                <Heading as="h1">Points Pool Management</Heading>
                <FormControl id="points-to-add">
                    <FormLabel>Points to Add:</FormLabel>
                    <Input
                        type="number"
                        value={pointsToAdd}
                        onChange={handleInputChange}
                    />
                    <Button mt={4} colorScheme="blue" onClick={handleAddPoints}>
                        Add Points
                    </Button>
                    {addPointsMessage && <Text>{addPointsMessage}</Text>}
                </FormControl>

                <Heading as="h2">Launch Pool</Heading>
                    <Button mt={4} colorScheme="green" onClick={handleLaunchPool}>
                        Launch Pool
                    </Button>
                    {launchMessage && <Text>{launchMessage}</Text>}
            </VStack>
        </Box>
    );
}

export default PointsPoolManagement;