import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import axiosInstance from '../../../components/axiosInstance';
import {
    Box,
    Flex,
    useToast,
    Text,
    Button,
    List,
    ListItem,
    Heading,
    IconButton,
    Stack,
    Divider,
    Wrap,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Avatar,
    Badge,
    RadioGroup,
    Radio,
} from '@chakra-ui/react';
import { FaArrowLeft } from 'react-icons/fa';
import { faMessage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Background from '../../../assets/Background.webp';

function ActiveProjects() {
    const toast = useToast();
    const navigate = useNavigate();
    const [activeProjects, setActiveProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedAccountType, setSelectedAccountType] = useState('user');
    const [userStripeAccount, setUserStripeAccount] = useState(false);
    const [businessStripeAccount, setBusinessStripeAccount] = useState(false);
    const [acceptedProposal, setAcceptedProposal] = useState(null);
    const businessId = localStorage.getItem('businessId');

    useEffect(() => {
        const fetchActiveProjects = async () => {
            try {
                const response = await axiosInstance.get('/active-projects');
                setActiveProjects(response.data);
                setLoading(false);
            } catch (error) {
                toast({
                    title: 'Error fetching open projects.',
                    description: 'Unable to load open projects. Please try again later.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        };

        fetchActiveProjects();
        fetchBusinessAndUser();
    }, []);

    useEffect(() => {
        if (selectedProject) {
            const foundAcceptedProposal = selectedProject.proposals.find(proposal => proposal.status === 'accepted');
            setAcceptedProposal(foundAcceptedProposal || null);
        }
    }, [selectedProject]);

    const fetchBusinessAndUser = async () => {
        if (businessId) {
            try {
                // Corrected to use `get` method and updated the endpoint to include `api`
                const response = await axiosInstance.get(`/user/${businessId}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
                    },
                });

                // Updated to match the updated structure of the response
                setUserStripeAccount(response.data.user); // Assuming this sets the user's Stripe account info
                setBusinessStripeAccount(response.data.business); // Assuming this sets the business's Stripe account info

                setLoading(false);
            } catch (error) {
                console.error(error); // It's a good practice to log the actual error for debugging
                toast({
                    title: 'Error fetching User and Business info.',
                    description: 'Unable to fetch user & business info. Please try again later.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
    };

    const handleFundMilestone = async (milestoneId) => {
        try {
            const accountType = selectedAccountType === 'user' ? 'user' : 'business';
            const response = await axiosInstance.post(`/projects/${selectedProject._id}/milestones/${milestoneId}/fund`, {
                accountType,
            });
            const { sessionId } = response.data;
            const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
            await stripe.redirectToCheckout({ sessionId });

            // Update the milestone's funding status to "processing"
            const updatedMilestones = acceptedProposal?.milestones?.map((milestone) => {
                if (milestone._id === milestoneId) {
                    return { ...milestone, funded: 'processing' };
                }
                return milestone;
            });

            setAcceptedProposal({ ...acceptedProposal, milestones: updatedMilestones });
        } catch (error) {
            toast({
                title: 'Error funding milestone.',
                description: 'Unable to initiate the funding process. Please try again later.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleApproveCustomerMilestone = async (milestoneId) => {
        try {
            await axiosInstance.put(`/projects/${selectedProject._id}/proposals/${acceptedProposal._id}/milestones/${milestoneId}/customer-approve`);
            toast({
                title: 'Milestone Approved',
                description: 'You have successfully approved the milestone.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
    
            // Update the local state to reflect the milestone customer approval
            const updatedMilestones = acceptedProposal?.milestones?.map((milestone) => {
                if (milestone._id === milestoneId) {
                    return { ...milestone, customerApproved: true };
                }
                return milestone;
            });
    
            setAcceptedProposal({ ...acceptedProposal, milestones: updatedMilestones });
        } catch (error) {
            toast({
                title: 'Error approving milestone',
                description: 'Unable to approve the milestone. Please try again later.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleProjectClick = (project) => {
        setSelectedProject(project);
    };

    const handleMessageClick = (projectRoomIdentifier) => {
        navigate(`/messaging?projectRoom=${projectRoomIdentifier}`);
    };

    return (
        <Flex direction="column" minH="100vh" w="100%" pl="300px" bgImage={Background} bgSize="cover" bgRepeat="no-repeat" bgPosition="center">
            <Flex flex="1" w="100%" p={8}>
                <Box w="30%" bg="white" borderRadius="md" p={4} boxShadow="lg" h={"fit-content"} overflowY="auto">
                    <Heading size="md" mb={4}>Active Projects</Heading>
                    <Divider mb={4} />
                    {loading ? (
                        <Text>Loading...</Text>
                    ) : activeProjects.length > 0 ? (
                        <List>
                            {activeProjects?.map((project) => (
                                <ListItem
                                    key={project._id}
                                    onClick={() => handleProjectClick(project)}
                                    cursor="pointer"
                                    borderRadius="md"
                                    mb={1}
                                    p={4}
                                    _hover={{ bg: "gray.100" }}
                                    bg={selectedProject?._id === project._id ? "gray.100" : "white"}
                                >
                                    <Heading size="sm">{project.name}</Heading>
                                    <Text fontSize={"sm"} color="gray.500" mt={1}>{project.description}</Text>
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <Text>No open projects found.</Text>
                    )}
                </Box>
                <Box flex="1" ml={8} bg="white" borderRadius="md" p={8} boxShadow="lg" h={"fit-content"} overflowY="auto">
                    {acceptedProposal ? (
                        <Box>
                            <Flex justify="space-between" mb={4}>
                                <Heading size="lg">{acceptedProposal.firstName}'s Proposal</Heading>
                                <Button
                                    leftIcon={<FontAwesomeIcon icon={faMessage} />}
                                    colorScheme="blue"
                                    onClick={() => handleMessageClick(acceptedProposal.connectionId)}
                                >
                                    Message
                                </Button>
                            </Flex>
                            <Text mb={8}>{acceptedProposal.introduction}</Text>
                            <Tabs variant="enclosed">
                                <TabList>
                                    {acceptedProposal?.milestones?.map((milestone, index) => (
                                        <Tab key={index}>{milestone.title}</Tab>
                                    ))}
                                </TabList>
                                <TabPanels>
                                    {acceptedProposal?.milestones?.map((milestone, index) => (
                                        <TabPanel key={index}>
                                            <Heading size="sm" mb={2}>{milestone.title}</Heading>
                                            <Text mb={4}>{milestone.description}</Text>
                                            <Stack direction="row" justify="space-between" mb={4}>
                                                <Box>
                                                    <Text fontWeight="bold">Start Date:</Text>
                                                    <Text>{new Date(milestone.startDate).toLocaleDateString()}</Text>
                                                </Box>
                                                <Box>
                                                    <Text fontWeight="bold">End Date:</Text>
                                                    <Text>{new Date(milestone.endDate).toLocaleDateString()}</Text>
                                                </Box>
                                                <Box>
                                                    <Text fontWeight="bold">Cost:</Text>
                                                    <Text>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(milestone.cost)}</Text>
                                                </Box>
                                                <Box>
                                                    <Text fontWeight="bold">Funding Status:</Text>
                                                    <Badge colorScheme={
                                                        milestone.funded === 'funded'
                                                            ? 'green'
                                                            : milestone.funded === 'processing'
                                                                ? 'yellow'
                                                                : 'red'
                                                    }>
                                                        {milestone.funded}
                                                    </Badge>
                                                </Box>
                                                {(milestone.funded === 'unfunded' || milestone.funded === 'processing') && (
                                                    <>
                                                        <RadioGroup value={selectedAccountType} onChange={setSelectedAccountType}>
                                                            <Stack direction="row">
                                                                {userStripeAccount && (
                                                                    <Radio value="user">User Account</Radio>
                                                                )}
                                                                {businessStripeAccount && (
                                                                    <Radio value="business">Business Account</Radio>
                                                                )}
                                                            </Stack>
                                                        </RadioGroup>
                                                        <Button
                                                            colorScheme="blue"
                                                            onClick={() => handleFundMilestone(milestone._id)}
                                                            disabled={milestone.funded === 'processing'}
                                                        >
                                                            {milestone.funded === 'processing' ? 'Processing...' : 'Fund Milestone'}
                                                        </Button>
                                                    </>
                                                )}
                                            </Stack>
                                            {milestone.status === 'active' && milestone.funded === 'funded' && (
                                                <Box mt={4}>
                                                    <Button
                                                        colorScheme="green"
                                                        onClick={() => handleApproveCustomerMilestone(milestone._id)}
                                                        isDisabled={milestone.customerApproved}
                                                    >
                                                        {milestone.customerApproved ? 'Milestone Approved' : 'Approve Milestone'}
                                                    </Button>
                                                </Box>
                                            )}
                                        </TabPanel>
                                    ))}
                                </TabPanels>
                            </Tabs>
                            <Box mt={6}>
                                <Heading size="md">Rationale</Heading>
                                <Text mt={2}>{acceptedProposal.rationale}</Text>
                            </Box>
                            <Box mt={6}>
                                <Heading size="md">Total Cost</Heading>
                                <Text mt={2}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(acceptedProposal.totalCost)}</Text>
                            </Box>
                        </Box>
                    ) : (
                        <Flex justify="center" align="center" h="100%">
                            <Text fontSize="xl" color="gray.500">Select a project to view its accepted proposal and milestones.</Text>
                        </Flex>
                    )}
                </Box>
            </Flex>
        </Flex>
    );
}

export default ActiveProjects;

