// ReferralManagement.js
import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    SimpleGrid,
    Text,
    useToast,
    VStack,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    IconButton,
    Checkbox,
    Flex
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import axiosInstance from '../../components/axiosInstance';

const ReferralManagement = () => {
    const [revealPrice, setRevealPrice] = useState(0);
    const [bountyToRecruiters, setBountyToRecruiters] = useState(false);
    const [bounty, setBounty] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [revealedApplicants, setRevealedApplicants] = useState([]);
    const [selectedApplicant, setSelectedApplicant] = useState(null);
    const authToken = localStorage.getItem('auth_token');
    const toast = useToast();

    useEffect(() => {
        fetchRevealedApplicants();
    }, []);

    const fetchRevealedApplicants = async () => {
        try {
            const response = await axiosInstance.get('/revealed-applicants', {
                headers: { 'Authorization': `Bearer ${authToken}` }
            });
            setRevealedApplicants(response.data);
        } catch (error) {
            console.error('Error fetching revealed applicants:', error);
        }
    };

    const handleSaveChanges = async () => {
        const updatedData = {
            ...selectedApplicant,
            revealPrice: revealPrice * 100, // Convert to cents before sending
            bountyToRecruiters,
            bounty: bounty * 100,
            subTotal: subTotal * 100
            // ...other fields
        };

        try {
            const response = await axiosInstance.put(`/revealed-applicants/${selectedApplicant._id}`, updatedData, {
                headers: { 'Authorization': `Bearer ${authToken}` }
            });
            toast({
                title: 'Success',
                description: 'Revealed applicant updated successfully.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            fetchRevealedApplicants(); // Refresh the list
        } catch (error) {
            toast({
                title: 'Error',
                description: 'There was an error updating the applicant.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleSelectApplicant = (applicant) => {
        setSelectedApplicant(applicant);
        setRevealPrice(applicant.revealPrice);
        setBountyToRecruiters(applicant.bountyToRecruiters);
        setBounty(applicant.bounty);
        setSubTotal(applicant.subTotal);
        // ... set other state variables as needed
    };

    const ApplicantTableRow = ({ applicant, onSelect }) => (
        <Tr _hover={{ bg: 'gray.100' }}>
            <Td>{applicant._id}</Td>
            <Td>{applicant.companyName}</Td>
            <Td>{applicant.referralId ? applicant.referralId._id : 'N/A'}</Td>
            <Td>{applicant.referralRole}</Td>
            <Td>${(applicant.revealPrice).toFixed(2)}</Td>
            <Td>{applicant.bountyToRecruiters ? 'Yes' : 'No'}</Td>
            <Td>${(applicant.bounty).toFixed(2)}</Td>
            <Td>${(applicant.subTotal).toFixed(2)}</Td>
            <Td>${(applicant.chargedAmount / 100).toFixed(2)}</Td>
            <Td>{applicant.isCollected ? 'Yes' : 'No'}</Td>
            <Td>{applicant.isPaid ? 'Yes' : 'No'}</Td>
            <Td>{applicant.pointsPurchased ? 'Yes' : 'No'}</Td>
            <Td>
                <IconButton
                    aria-label="Edit"
                    icon={<EditIcon />}
                    onClick={() => onSelect(applicant)}
                />
            </Td>
        </Tr>
    );

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setSelectedApplicant({
            ...selectedApplicant,
            [name]: checked,
        });
        if (name === "bountyToRecruiters") {
            setBountyToRecruiters(checked);
        }
    };

    return (
        <Flex w={"100%"} justify={"center"} p={5}>
            <VStack spacing={5} align="stretch">
                <Text fontSize="xl">Update Revealed Applicant Referral</Text>
                {selectedApplicant ? (
                    <Box w={"fit-content"} p={2} shadow="md" borderWidth="1px" borderRadius="md" bg="white">
                        {selectedApplicant._id}
                        <FormControl>
                            <Checkbox
                                name="isCollected"
                                fontWeight={"bold"}
                                m={2}
                                isChecked={selectedApplicant.isCollected}
                                onChange={handleCheckboxChange}
                            >
                                Is Collected
                            </Checkbox>

                            <Checkbox
                                name="isPaid"
                                m={2}
                                fontWeight={"bold"}
                                isChecked={selectedApplicant.isPaid}
                                onChange={handleCheckboxChange}
                            >
                                Is Paid
                            </Checkbox>

                            <Checkbox
                                name="pointsPurchased"
                                m={2}
                                fontWeight={"bold"}
                                isChecked={selectedApplicant.pointsPurchased}
                                onChange={handleCheckboxChange}
                            >
                                Points Purchased
                            </Checkbox>
                            <Button
                                colorScheme="blue"
                                ml={4}
                                onClick={handleSaveChanges}
                            >
                                Save Changes
                            </Button>
                        </FormControl>
                    </Box>
                ) : (
                    <Text>Select an applicant to edit their details.</Text>
                )}

                <Box>
                    <Text fontSize="xl" mb={4}>Revealed Applicants</Text>
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th>Referral ID</Th>
                                <Th>Company Name</Th>
                                <Th>Referral ID</Th>
                                <Th>Referral Role</Th>
                                <Th>Reveal Price</Th>
                                <Th>Bounty to Recruiters</Th>
                                <Th>Bounty</Th>
                                <Th>Sub Total</Th>
                                <Th>Charged Amount</Th>
                                <Th>Is Collected</Th>
                                <Th>Is Paid</Th>
                                <Th>Points Purchased</Th>
                                <Th>Edit</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {revealedApplicants.map((applicant) => (
                                <ApplicantTableRow
                                    key={applicant._id}
                                    applicant={applicant}
                                    onSelect={handleSelectApplicant}
                                />
                            ))}
                        </Tbody>
                    </Table>
                </Box>
            </VStack>
        </Flex>
    );
};

export default ReferralManagement;