// Frontend: React component
import React, { useState, forwardRef, useEffect } from 'react';
import {
    Modal, ModalOverlay, ModalContent, ModalHeader,
    ModalFooter, ModalBody, ModalCloseButton, Button,
    Textarea
} from '@chakra-ui/react';
import MessagesContainer from './MessagesContainer';
import axiosInstance from './axiosInstance';
import { socketAtom } from '../socketAtom';
import { useAtom } from 'jotai';

const MessageModal = forwardRef(({ isOpen, onClose, user, connectionId, currentUserId, applicantId }, ref) => {
    const [inputMessage, setInputMessage] = useState('');
    const [messageHistory, setMessageHistory] = useState({});
    const [socket] = useAtom(socketAtom);
    const token = localStorage.getItem('auth_token');

    useEffect(() => {
        socket.emit('joinEmployerMeetingRoom', { connectionId });

        socket.on('existingMessages', ({ roomName, messages }) => {
            if (roomName) {
                setMessageHistory(prevMessages => {
                    const updatedMessages = {
                        ...prevMessages,
                        [roomName]: messages,
                    };
                    console.log('Updated existingMessages:', updatedMessages);
                    return updatedMessages;
                });
            }
        });

        socket.on('newEmployerMessage', (message) => {
            setMessageHistory(prevMessages => ({
                ...prevMessages,
                [message.roomName]: [...(prevMessages[message.roomName] || []), message],
            }));
        });

        return () => {
            socket.off('existingMessages');
            socket.off('newEmployerMessage');
        };
    }, [socket, connectionId]);

    const handleSendClick = () => {
        if (inputMessage.trim()) {
            socket.emit('employerMessage', {
                roomName: connectionId,
                text: inputMessage,
                senderId: user._id,
                senderPFP: user.PFP,
                senderFirstName: user.firstName,
                senderLastName: user.lastName,
                recipientId: null,
                connectionId,
            });
            setInputMessage('');
        }
    };

    const sendInviteMessage = (inviteMessage) => {
        if (inviteMessage.trim()) {
            socket.emit('employerMessage', {
                text: inviteMessage,
                roomName: connectionId,
                senderId: user._id,
                senderPFP: user.PFP,
                senderFirstName: user.firstName,
                senderLastName: user.lastName,
                recipientId: null,
                connectionId,
            });
        }
    };

    const handleSendInviteClick = async () => {
        try {
            console.log('Fetching employer info...');
            const response = await axiosInstance.get(`/employer/info`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log('Employer info response:', response.data);
            const schedulingLink = response.data.employer.schedulingLink;
            if (schedulingLink) {
                console.log('Scheduling link found:', schedulingLink);
                sendInviteMessage(`Schedule a time with me: ${schedulingLink}`);
            } else {
                console.warn('No scheduling link found in the response');
            }
        } catch (error) {
            console.error('Error fetching employer info:', error);
        }
    };    

    const handleAiInviteClick = () => {
        sendInviteMessage(`I'd like to invite you to participate in our AI-powered interview: https://jobjar.ai/ai-interview/${applicantId}`);
    };

    return (
        <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Send a Message</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <MessagesContainer messages={messageHistory[connectionId] || []} currentUserId={currentUserId} />
                    <Textarea
                        value={inputMessage}
                        onChange={(e) => setInputMessage(e.target.value)}
                        placeholder="Type your message here..."
                    />
                </ModalBody>
                <ModalFooter>
                    <Button bg={"white"} color={"black"} borderColor={"black"} border={"2px"} mr={3} onClick={handleAiInviteClick} _hover={{ bg: "black", border: "2px", borderColor: "black", color: "white" }}>AI Interview Invite</Button>
                    <Button bg={"#01BF02"} color={"white"} mr={3} onClick={handleSendInviteClick} _hover={{ bg: "#048A81" }}>Schedule Invite Link</Button>
                    <Button bg={"#118AB2"} color={"white"} mr={3} onClick={handleSendClick} _hover={{ bg: "#0D5C78" }}>Send</Button>
                    <Button bg={"#EF476F"} color={"white"} onClick={onClose} _hover={{ bg: "#D6283D" }}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
});

export default MessageModal;