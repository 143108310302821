import React from 'react';
import { Box, Text, Progress, Tooltip, IconButton, Flex, HStack } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';

const JobSeekerPoints = ({ points }) => {
    // Calculate progress percentage
    const progressPercentage = points ? (points / 1000000) * 100 : 0;

    // Format points with commas
    const formattedPoints = points ? points.toLocaleString() : 0;

    // Points information tooltip content
    const pointsInfo = (
        <Box bg={"white"} w={"fit-content"} minW={"300px"}>
            <Text><strong>Welcome to JobJar.AI's Rewarding Points System!</strong></Text>
            <Text>As you use our platform, you'll earn points for activities like applying for jobs, reposting and interacting with users.</Text>
            <Text>Reach point thresholds to unlock exciting rewards. Redeem your points for gift cards or cash out directly. The more you engage, the more you earn!</Text>
            <Text>Start accumulating points now and watch as they pave the way to amazing perks and rewards. Your career growth and activity on JobJar.ai are always valued and rewarded!</Text>
        </Box>
    );

    return (
        <Box
            align="center"
            p={4}
            mt={8}
            mb={4}
            bg="gray.300"
            borderRadius="lg"
            shadow="lg"
            position="relative"
            justify="space-between"
            maxWidth="lg"
        >
            <HStack w={"100%"}>
                <Tooltip hasArrow label={pointsInfo} bg="white" color="black">
                    <IconButton
                        aria-label="Info for Points"
                        variant="ghost"
                        icon={<InfoOutlineIcon />}
                        isRound
                    />
                </Tooltip>
                <Text fontSize="xl" fontWeight="bold">
                    Points: {formattedPoints}
                </Text>
            </HStack>
            <Box mt={4} w="full" maxW="md">
                <Progress borderRadius="full" value={progressPercentage} colorScheme="green" size="lg" />
            </Box>
        </Box>
    );
};

export default JobSeekerPoints;
