// JobBoard.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Box,
    Flex,
    Input,
    Select,
    Button,
    Spinner,
    useToast,
    Text,
    VStack,
    HStack,
    Card,
    Divider,
    Heading
} from '@chakra-ui/react';
import JobCard from '../../components/JobCard';
import SearchTags from '../../components/SearchTags';
import JobDetails from '../../components/JobDetails';
import axiosInstance from '../../components/axiosInstance';
import Header from '../../components/Header';

const UserJobBoard = () => {
    const [jobs, setJobs] = useState([]);
    const [badgeJobs, setBadgeJobs] = useState([]);
    const [selectedJobId, setSelectedJobId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null); 
    const [currentPage, setCurrentPage] = useState(1);
    const [totalJobPages, setTotalJobPages] = useState(1);
    const [totalBadgeJobPages, setTotalBadgeJobPages] = useState(1);
    const [currentBadgePage, setCurrentBadgePage] = useState(1);
    const [pageSize] = useState(3);
    const [showFiltersAndShare, setShowFiltersAndShare] = useState(true);
    const [industries, setIndustries] = useState([]);
    const [searchTags, setSearchTags] = useState([]);
    const [selectedJob, setSelectedJob] = useState(null);
    const [referrals, setReferrals] = useState([]);
    const toast = useToast();
    const [searchFilters, setSearchFilters] = useState({
        searchTerm: '',
        industry: '',
        employmentType: '',
        workLocationType: '',
        tags: '',
        sortOption: 'dateNewest',
        locationCity: '',
        locationState: '',
        locationCountry: '',
        postalCode: ''
    });

    const fetchJobs = async () => {

        const pageNum = parseInt(currentPage, 10);
        const queryParams = new URLSearchParams({
            ...searchFilters,
            tags: searchTags.join(','),
            page: pageNum,
            limit: pageSize
        }).toString();

        try {
            const response = await axiosInstance.get(`/jobs?${queryParams}`);
            setJobs(response.data.jobs);
            if (response.data.totalJobs !== undefined) {
                setTotalJobPages(Math.ceil(response.data.totalJobs / pageSize));
            } else {
                console.error('Invalid totalJobs value:', response.data.totalJobs);
                setTotalJobPages(1);
            }
        } catch (error) {
            toast({
                title: 'Error fetching jobs',
                description: error.response?.data?.message || 'Failed to fetch jobs.',
                status: 'error',
                isClosable: true,
            });
            setError(error);
        } finally {
        }
    };

    useEffect(() => {
        fetchJobs();
    }, [currentPage, searchTags]);

    useEffect(() => {
        fetchBadgeJobs();
    }, [currentBadgePage]);

    const fetchBadgeJobs = async () => {

        const pageNum = parseInt(currentBadgePage, 10);
        const queryParams = new URLSearchParams({
            ...searchFilters,
            tags: searchTags.join(','),
            page: pageNum,
            limit: pageSize,
        }).toString();

        try {
            const response = await axiosInstance.get(`/badgeJobs?${queryParams}`);
            setBadgeJobs(response.data.jobs);
            if (response.data.totalJobs !== undefined) {
                setTotalBadgeJobPages(Math.ceil(response.data.totalJobs / pageSize));
            } else {
                console.error('Invalid totalJobs value:', response.data.totalJobs);
                setTotalBadgeJobPages(1);
            }
        } catch (error) {
            // Error handling
            toast({
                title: 'Error fetching badge jobs',
                description: error.response?.data?.message || 'Failed to fetch badge jobs.',
                status: 'error',
                isClosable: true,
            });
            setError(error);
        } finally {
        }
    };

    useEffect(() => {
        setCurrentPage(1);
        setCurrentBadgePage(1);
    }, [searchFilters]);

    const fetchIndustries = async () => {
        try {
            const token = localStorage.getItem('auth_token');
            const response = await axios.get(process.env.REACT_APP_API_URL + '/api/industries/search', {
                headers: { "Authorization": `Bearer ${token}` }
            });
            console.log(response.data);
            setIndustries(response.data);
        } catch (error) {
            toast({
                title: "Error fetching industries.",
                description: "There was an issue fetching the industry data.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        fetchIndustries();
    }, []);

    useEffect(() => {
        const fetchFilteredJobs = async () => {
            setIsLoading(true);
            try {
                await fetchJobs();
                await fetchBadgeJobs();
            } finally {
                setIsLoading(false);
            }
        };

        fetchFilteredJobs();
    }, [searchFilters]);

    const handleFilterChange = (filterName, value) => {
        setSearchFilters(prev => ({
            ...prev,
            [filterName]: value,
        }));
        // Reset the pagination back to the first page
        setCurrentPage(1);
        setCurrentBadgePage(1);
    };

    const handleJobCardClick = async (jobId) => {
        try {
            const response = await axiosInstance.get(`/jobDetails/${jobId}`);
            setSelectedJob(response.data);
            fetchReferralsForJob(jobId);
            setSelectedJobId(jobId);

            await axiosInstance.put(`/incrementView/${jobId}`);
        } catch (error) {
            console.error("Error fetching job details or incrementing views:", error);
        }
    };

    const handleClearFilters = () => {
        setSearchFilters({
            searchTerm: '',
            industry: '',
            employmentType: '',
            workLocationType: '',
            tags: '',
            sortOption: 'dateNewest',
            locationCity: '',
            locationState: '',
            locationCountry: '',
            postalCode: ''
        });
        setCurrentPage(1);
        setCurrentBadgePage(1);
    };

    const handleNextPage = (type) => {
        if (type === 'jobs' && currentPage < totalJobPages) {
            setCurrentPage(prevPage => prevPage + 1);
        } else if (type === 'badgeJobs' && currentBadgePage < totalBadgeJobPages) {
            setCurrentBadgePage(prevPage => prevPage + 1);
        }
    };

    const handlePrevPage = (type) => {
        if (type === 'jobs' && currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        } else if (type === 'badgeJobs' && currentBadgePage > 1) {
            setCurrentBadgePage(prevPage => prevPage - 1);
        }
    };

    const handleShare = async (jobId, userId) => {

        if (!selectedJob) {
            console.error('Selected job is null.');
            return;
        }

        const authToken = localStorage.getItem('auth_token');

        try {
            // Track the referral and get the shareable link
            const trackResponse = await axiosInstance.post(
                '/trackReferral',
                { jobId },
                { headers: { 'Authorization': `Bearer ${authToken}` } }
            );

            const shareableLink = trackResponse.data.referralLink;
            console.log('Share this link:', shareableLink);

            // Fetch the referrals for the given jobId
            const referralResponse = await axiosInstance.get(`/getReferrals/${jobId}/:userId`, {
                headers: { "Authorization": `Bearer ${authToken}` }
            });

            console.log('Referrals fetched successfully:', referralResponse.data);
            setReferrals(referralResponse.data);

            // TODO: Display the link on your UI and provide a way for the user to copy it

        } catch (error) {
            console.error('Error in handleShare:', error.response ? error.response.data : error);
            toast({
                title: "Error",
                description: "There was an issue handling the share action.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    // Function to copy referral link to clipboard
    const copyToClipboard = (link) => {
        navigator.clipboard.writeText(link).then(() => {
            console.log('Link copied to clipboard');
            // Optionally: Show a success toast/notification
        }).catch(err => {
            console.error('Error copying link: ', err);
            // Optionally: Show an error toast/notification
        });
    };

    const fetchReferralsForJob = async (jobId) => {
        try {
            const token = localStorage.getItem('auth_token');
            const response = await axiosInstance.get(`/getReferrals/${jobId}/:userId`, {
                headers: { "Authorization": `Bearer ${token}` }
            });

            console.log('Referrals fetched successfully:', response.data);
            setReferrals(response.data);
        } catch (error) {
            console.error('Error fetching referrals:', error);
            toast({
                title: "Error fetching referrals.",
                description: "There was an issue fetching the referral data.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const sortedByRecruiter = [...referrals].sort((a, b) => (a.recruiterId > b.recruiterId) ? 1 : -1);

    return (
        <Flex minH="100vh" bg={"gray.100"} direction="column" w="100%">
            <Header />
            <Flex p={4} direction="row" justify="center" flexGrow={1} w="100%">
                <Box
                    position="sticky"
                    top="10" // This sets the top position when it starts sticking.
                    shadow={"lg"}
                    bg="black"
                    w="25%"
                    mb={"10"}
                    p={4}
                    borderRadius="xl"
                    h={"fit-content"}
                    overflow="hidden"
                    zIndex={10} // Ensure it stays above other content.
                >
                    <VStack overflow="hidden" h={"fit-content"}>
                        <Box w="100%" h={"fit-content"} overflowY="scroll">
                            <Flex justify={"center"} w={"100%"}>
                                <SearchTags onTagsChange={(tags) => setSearchTags(tags)} />
                            </Flex>
                            {/* Main Job Board */}
                            {isLoading ? (
                                <Flex w={"100%"} justify={"center"}>
                                    <Spinner
                                        thickness='4px'
                                        speed='0.65s'
                                        alignItems={"center"}
                                        justify={"center"}
                                        emptyColor='#01bf02'
                                        color='white'
                                        size='xl'
                                    />
                                </Flex>
                            ) : error ? (
                                <p>Error: {error.message}</p>
                            ) : (
                                <>
                                    <Box borderRadius={"md"} p={2} bg={"#01bf02"}>
                                        <Box borderRadius={"md"} p={2} bg={"black"}>
                                            <Heading mt={1} mb={2} size={"md"} color={"white"}>Highlighted Jobs</Heading>
                                            {badgeJobs.length > 0 ? (
                                                badgeJobs.map(job => (
                                                    <JobCard
                                                        key={job._id}
                                                        job={job}
                                                        onClick={() => handleJobCardClick(job._id)}
                                                        isSelected={job._id === selectedJobId}
                                                    />
                                                ))
                                            ) : (
                                                <Text mt={4} color="gray.500">
                                                    No badge jobs found.
                                                </Text>
                                            )}

                                            {/* Pagination Controls for Badge Jobs */}
                                            <Flex mb={2} justify="space-between" w="100%" mt={4}>
                                                <Button
                                                    variant={"ghost"}
                                                    color={"white"}
                                                    _hover={{ color: "black", bg: "white" }}
                                                    disabled={currentBadgePage === 1}
                                                    onClick={() => handlePrevPage('badgeJobs')}
                                                >
                                                    Previous
                                                </Button>
                                                <Button
                                                    variant={"ghost"}
                                                    color={"white"}
                                                    _hover={{ color: "black", bg: "white" }}
                                                    disabled={currentBadgePage >= totalBadgeJobPages}
                                                    onClick={() => handleNextPage('badgeJobs')}
                                                >
                                                    Next
                                                </Button>
                                            </Flex>
                                        </Box>
                                    </Box>

                                    {jobs.length > 0 ? (
                                        jobs.map(job => (
                                            <JobCard
                                                key={job._id}
                                                job={job}
                                                onClick={() => handleJobCardClick(job._id)}
                                                isSelected={job._id === selectedJobId}
                                            />
                                        ))
                                    ) : (
                                        <Text mt={4} color="gray.500">
                                            No jobs found.
                                        </Text>
                                    )}
                                </>
                            )}
                        </Box>

                        {/* Pagination Controls for Jobs */}
                        <Flex justify="space-between" w="100%" mt={4}>
                            <Button
                                disabled={currentPage === 1}
                                _hover={{ bg: 'black', color: "white", border: "2px", borderColor: "white" }}
                                onClick={() => handlePrevPage('jobs')}
                            >
                                Previous
                            </Button>
                            <Button
                                bg={"#01BF02"}
                                _hover={{ bg: 'black', color: "white", border: "2px", borderColor: "white" }}
                                disabled={currentPage >= totalJobPages}
                                onClick={() => handleNextPage('jobs')}
                            >
                                Next
                            </Button>
                        </Flex>
                    </VStack>
                </Box>
                {/* Center Panel for Jobs */}
                <Flex w="100%">
                    <VStack w={"100%"}>
                        <HStack w={"100%"} justify={"space-evenly"}>
                            {showFiltersAndShare && (
                                <>
                                    <Box bg="black" shadow={"lg"} borderRadius="lg" w={"90%"} p={2} overflow="hidden" >
                                        <HStack w={"100%"} justify={"space-between"} spacing={4} px={4} py={3} borderRadius="md">
                                            <Box w={"100%"}>
                                                <HStack mb={2}>
                                                    <Select
                                                        placeholder="Industry"
                                                        bg={"white"}
                                                        value={searchFilters.industry}
                                                        onChange={(e) => handleFilterChange('industry', e.target.value)}
                                                    >
                                                        {industries.map((industry) => (
                                                            <option key={industry._id} value={industry.industry}>
                                                                {industry.industry}
                                                            </option>
                                                        ))}
                                                    </Select>

                                                    <Select
                                                        placeholder="Job Type"
                                                        bg={"white"}
                                                        value={searchFilters.employmentType}
                                                        onChange={(e) => handleFilterChange('employmentType', e.target.value)}
                                                    >
                                                        <option value="Full-time">Full-time</option>
                                                        <option value="Part-time">Part-time</option>
                                                        <option value="Contract">Contract</option>
                                                    </Select>
                                                    <Select
                                                        placeholder="Work Location"
                                                        bg={"white"}
                                                        shadow={"lg"}
                                                        value={searchFilters.workLocationType}
                                                        onChange={(e) => handleFilterChange('workLocationType', e.target.value)}
                                                    >
                                                        <option value="Remote">Remote</option>
                                                        <option value="Hybrid">Hybrid</option>
                                                        <option value="On-site">On-site</option>
                                                    </Select>
                                                </HStack>
                                                <HStack mb={2}>
                                                    <Input
                                                        placeholder="City"
                                                        bg={"white"}
                                                        shadow={"lg"}
                                                        value={searchFilters.locationCity}
                                                        onChange={(e) => handleFilterChange('locationCity', e.target.value)}
                                                    />
                                                    <Input
                                                        placeholder="State"
                                                        bg={"white"}
                                                        shadow={"lg"}
                                                        value={searchFilters.locationState}
                                                        onChange={(e) => handleFilterChange('locationState', e.target.value)}
                                                    />
                                                    <Input
                                                        placeholder="Country"
                                                        bg={"white"}
                                                        shadow={"lg"}
                                                        value={searchFilters.locationCountry}
                                                        onChange={(e) => handleFilterChange('locationCountry', e.target.value)}
                                                    />
                                                    <Input
                                                        placeholder="Zip Code"
                                                        bg={"white"}
                                                        shadow={"lg"}
                                                        value={searchFilters.postalCode}
                                                        onChange={(e) => handleFilterChange('postalCode', e.target.value)}
                                                    />
                                                </HStack>
                                                <HStack w={"100%"}>
                                                    <HStack w={"70%"}>
                                                    </HStack>
                                                    <HStack justify={"flex-end"} w={"30%"} >
                                                        {/* Apply Filters Button 
                                                    <Button
                                                        bg="#01BF02"
                                                        color={"black"}
                                                        shadow={"lg"}
                                                        size={"md"}
                                                        onClick={fetchJobs}
                                                    > 
                                                        Apply Filters
                                                    </Button> */}
                                                        {/* Clear Filters Button */}
                                                        <Button
                                                            bg="#EF476F"
                                                            _hover={{ bg: 'black', color: "white", border: "2px", borderColor: "white" }}
                                                            color={"white"}
                                                            variant={"solid"}
                                                            shadow={"lg"}
                                                            size={"md"}
                                                            onClick={handleClearFilters}
                                                        >
                                                            Clear Filters
                                                        </Button>
                                                        {selectedJob && referrals.some(ref => ref.jobId === selectedJob._id) ? (
                                                            <Button bg={"#FFD166"} _hover={{ bg: 'black', color: "white", border: "2px", borderColor: "white" }} onClick={() => {
                                                                const selectedReferral = referrals.find(ref => ref.jobId === selectedJob._id);
                                                                if (selectedReferral) {
                                                                    copyToClipboard(selectedReferral.referralLink);
                                                                }
                                                            }}>
                                                                Copy & Share Link
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                colorScheme="gray"
                                                                variant={"solid"}
                                                                _hover={{ bg: 'black', color: "white", border: "2px", borderColor: "white" }}
                                                                shadow={"lg"}
                                                                size={"md"}
                                                                onClick={() => handleShare(selectedJob._id)}
                                                            >
                                                                Create Referral Link
                                                            </Button>
                                                        )}
                                                    </HStack>
                                                </HStack>
                                            </Box>
                                        </HStack>
                                    </Box>
                                </>
                            )}
                            <Flex justify={"flex-end"}>
                                <Button
                                    onClick={() => setShowFiltersAndShare(prev => !prev)}
                                    bg="white"
                                    size="sm"
                                    mt={2}
                                >
                                    {showFiltersAndShare ? 'Hide Filters' : 'Show Filters'}
                                </Button>
                            </Flex>
                        </HStack> 

                        <Divider mb={4} w={"97%"} shadow={"dark-lg"} border={"2px"} borderColor={"#01bf02"} />
                        <Box m={2} maxH={"max-content"} overflowY={"scroll"} >
                            <JobDetails selectedJobId={selectedJob?._id} selectedJob={selectedJob} onClose={() => setSelectedJob(null)} />
                        </Box>
                    </VStack>
                </Flex>
            </Flex>
        </Flex >
    );
};

export default UserJobBoard;