import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
    Box, Text, Badge, VStack, Image, Flex, Avatar, Switch, useColorModeValue,
    Heading, IconButton, Stack, Spacer, HStack, Spinner, Button, Input
} from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons'
import { TimeIcon } from '@chakra-ui/icons';
import { faUserPlus, faHeart, faHandPointer, faFlag, faCheck, faSquareCheck, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OriginalPostForm from '../components/OrginalPostForm';
import axiosInstance from '../components/axiosInstance';


const JobDetailsLiveFeed = () => {
    const [reposts, setReposts] = useState([]);
    const [comments, setComments] = useState({});
    const [currentUser, setCurrentUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isReviewing, setIsReviewing] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [itemsPerPage] = useState(5);
    const navigate = useNavigate();
    const loaderRef = useRef(null);
    const [showConnectionsFeed, setShowConnectionsFeed] = useState(false);
    const [applicantCounts, setApplicantCounts] = useState({});
    const visitorRole = localStorage.getItem('user_role');

    const fetchReposts = async () => {
        if (!hasMore || isLoading) return;

        setIsLoading(true);
        await new Promise(resolve => setTimeout(resolve, 1000));

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/posts?page=${page}&perPage=${itemsPerPage}`);
            // If page is 1, replace the existing reposts; otherwise, append
            if (page === 1) {
                setReposts(response.data);
            } else {
                setReposts(prevReposts => [...prevReposts, ...response.data]);
            }
            setHasMore(response.data.length === itemsPerPage);
        } catch (error) {
            console.error("Error fetching reposts:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const refreshFeed = async () => {
        setPage(1);
        setReposts([]);
        setHasMore(true);

        try {
            await fetchReposts();
        } catch (error) {
            console.error("Failed to fetch reposts:", error);
            // Handle the error appropriately
        }
    };


    const observer = useRef();
    const lastRepostElementRef = useCallback(node => {
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore && !isLoading) { // Check isLoading here
                setPage(prevPage => prevPage + 1);
            }
        }, { threshold: 0.1 });
        if (node) observer.current.observe(node);
    }, [isLoading, hasMore]);

    const fetchUserId = async () => {
        try {
            const authToken = localStorage.getItem('auth_token');
            const response = await axiosInstance.get('/user/details', {
                headers: { 'Authorization': `Bearer ${authToken}` }
            });

            // Assuming the state is named currentUser
            setCurrentUser(response.data.userId);
        } catch (error) {
            console.error('Error fetching user ID:', error);
        }
    };

    const fetchJobApplicants = async (jobId) => {
        try {
            const token = localStorage.getItem('auth_token');

            const applicantResponse = await axiosInstance.get(`/getJobApplicants/${jobId}`, {
                headers: { "Authorization": `Bearer ${token}` }
            });
            const totalCount = applicantResponse.data.totalCount;

            setApplicantCounts(prevCounts => ({
                ...prevCounts,
                [jobId]: totalCount
            }));

        } catch (error) {
            console.error('Error fetching applicants:', error);
            // Assuming you have a 'toast' method for showing notifications

        }
    };

    useEffect(() => {
        fetchReposts();
        fetchUserId();
    }, []);

    useEffect(() => {
        reposts.forEach(repost => {
            // Only fetch job applicants for reposts with 'job' content type
            if (repost.contentType === 'job' && repost.jobId) {
                fetchJobApplicants(repost.jobId);
            }
        });
    }, [reposts]);

    useEffect(() => {
        if (page > 1) {
            fetchReposts();
        }
    }, [page]);

    const goToUserProfile = (userId) => {
        navigate(`/user/${userId}`);
    };

    const goToJobDetails = async (jobId, userId, visitorRole) => {
        const role = 'jobseeker';
        try {
            if (!visitorRole) {
                await axiosInstance.put(`/jobSeekers/${userId}/referrals`, {
                    jobId,
                    userId,
                    role
                });
            }

            navigate(`/job/${jobId}/${userId}?referralRole=${role}`);
        } catch (error) {
            console.error('Error in goToJobDetails:', error);
            // Handle error (e.g., show a notification to the user)
        }
    };

    const handleModerateRepost = async (repostId) => {
        setIsReviewing(true);
        try {
            // Assuming authToken is stored in localStorage or fetched from context/store
            const authToken = localStorage.getItem('auth_token');
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/moderate/${repostId}`, {}, {
                headers: { 'Authorization': `Bearer ${authToken}` }
            });

            // Handle the response from the server
            console.log(response.data.message); // log the message or use it in UI
            // TODO: Add logic here to update UI based on moderation response if necessary
        } catch (error) {
            console.error('Error moderating repost:', error);
            // TODO: Add error handling logic
        }
        await refreshRepost(repostId);
        setIsReviewing(false);
    };

    const handleLike = async (repostId) => {
        // Optimistic update
        setReposts(prevReposts => prevReposts.map(repost =>
            repost._id === repostId
                ? { ...repost, likes: [...repost.likes, { userId: currentUser }] }
                : repost
        ));

        try {
            await axiosInstance.post(`posts/${repostId}/like`);
        } catch (error) {
            console.error('Error liking repost:', error);
            // Revert optimistic update if error occurs
            setReposts(prevReposts => prevReposts.map(repost =>
                repost._id === repostId
                    ? { ...repost, likes: repost.likes.filter(like => like.userId !== currentUser) }
                    : repost
            ));
        }
    };

    const handleToggleChange = () => {
        setShowConnectionsFeed(!showConnectionsFeed);
    };

    const handleUnlike = async (repostId) => {
        // Optimistic update
        setReposts(prevReposts => prevReposts.map(repost =>
            repost._id === repostId
                ? { ...repost, likes: repost.likes.filter(like => like.userId !== currentUser) }
                : repost
        ));

        try {
            await axiosInstance.delete(`/posts/${repostId}/unlike`);
        } catch (error) {
            console.error('Error unliking repost:', error);
            // Revert optimistic update if error occurs
            setReposts(prevReposts => prevReposts.map(repost =>
                repost._id === repostId
                    ? { ...repost, likes: [...repost.likes, { userId: currentUser }] }
                    : repost
            ));
        }
    };

    const handleClick = async (repostId) => {
        try {
            if (visitorRole) {
                await axiosInstance.put(`/posts/${repostId}/click`);
                setReposts(prevReposts =>
                    prevReposts.map(repost =>
                        repost._id === repostId ? { ...repost, clicks: repost.clicks + 1 } : repost
                    )
                );
            }
        } catch (error) {
            console.error('Error recording click:', error);
            // Handle error (e.g., show a notification to the user)
        }
    };

    const refreshRepost = async (repostId) => {
        try {
            const response = await axiosInstance.get(`/posts/${repostId}`);
            const updatedRepost = response.data;

            setReposts(prevReposts => prevReposts.map(repost =>
                repost._id === repostId ? updatedRepost : repost
            ));
        } catch (error) {
            console.error(`Error refreshing repost with id ${repostId}:`, error);
        }
    };

    const handleCommentChange = (repostId, event) => {
        setComments({ ...comments, [repostId]: event.target.value });
    };

    const submitComment = async (repostId) => {
        const commentText = comments[repostId];
        if (!commentText) return; // Prevent empty comments

        const role = localStorage.getItem('user_role');
        try {
            const authToken = localStorage.getItem('auth_token');
            await axiosInstance.post(`/posts/${repostId}/comment`, {
                comment: commentText,
                role,
            }, {
                headers: { 'Authorization': `Bearer ${authToken}` }
            });
            setComments({ ...comments, [repostId]: '' }); // Clear the comment input
            await refreshRepost(repostId);
        } catch (error) {
            console.error('Error submitting comment:', error);
        }
    };

    return (
        <Flex direction="column" borderRadius={"xl"} shadow={"lg"} bg={"black"} p={3} h="100vh" w="100%" align="center" justify="start">
            <HStack p={2} borderRadius={"lg"} bg={"white"} mb={1} w={"100%"} justify={"space-evenly"}>
                <Flex w={"40%"} justify={"flex-start"}>
                    <Heading color={"black"}>Live Feed</Heading>
                </Flex>
                <Flex w={"40%"} justify={"flex-end"}>
                    <IconButton
                        aria-label="Refresh Feed"
                        icon={<RepeatIcon />}
                        bg={"#01BF02"}
                        color={"black"}
                        onClick={refreshFeed}
                    />
                </Flex>
            </HStack>
            <VStack bg={"black"} borderRadius={"lg"} p={3} w={"100%"} maxH={"110vh"} overflowY={"scroll"} align="center" maxW={"800px"}>
                {reposts.map((repost, index) => (
                    <Box
                        ref={index === reposts.length - 1 ? lastRepostElementRef : null}
                        borderRadius={"2xl"}
                        bg={"whiteAlpha.900"}
                        key={repost._id}
                        p={4}
                        mb={2}
                        shadow="lg"
                        borderWidth="1px"
                        w={"95%"}
                    >
                        {/* Profile and Time Section */}
                        <Stack direction="row" spacing={2} align="center">
                            {/* Profile Picture and Name */}
                            <HStack>
                                {/* Profile Picture */}
                                {repost.profilePic ? (
                                    <Image
                                        borderRadius="full"
                                        border={"1px"}
                                        shadow={"lg"}
                                        maxW={"50px"}
                                        maxH={"50px"}
                                        src={repost.profilePic}
                                        alt={`Profile image of ${repost.name}`}
                                    />
                                ) : (
                                    <Avatar bg={"#118AB2"} size={"sm"} name={repost.name || "User"} />
                                )}
                                {/* User Name */}
                                <Heading
                                    as="button"
                                    size={"sm"}
                                    onClick={() => goToUserProfile(repost.userId)}
                                    _hover={{ textDecoration: 'underline' }}
                                >
                                    {repost.name}
                                </Heading>
                            </HStack>
                            <Spacer />
                            {/* Time */}
                            <Text as="time" fontSize="sm">
                                <TimeIcon mr="2" />
                                {new Date(repost.repostDate).toLocaleTimeString()}
                            </Text>
                            {repost.moderationInfo.determination === 'Good' ? (
                                <>
                                    <FontAwesomeIcon size='xl' color='#01BF02' icon={faSquareCheck} />
                                </>
                            ) : (
                                <IconButton
                                    aria-label="Moderate Repost"
                                    size={"sm"}
                                    isLoading={isReviewing}
                                    icon={<FontAwesomeIcon icon={faFlag} />}
                                    onClick={() => handleModerateRepost(repost._id)}
                                    _hover={{ bg: "#EF476F", color: "white" }}

                                />
                            )}
                        </Stack>

                        {/* Content based on Type */}
                        {repost.contentType === 'job' ? (
                            // Job Repost Content
                            <>
                                <Text mt={4} fontSize="md">{repost.comment}</Text>
                                <Box
                                    p={3}
                                    mt={3}
                                    bg={"blackAlpha.200"}
                                    borderRadius={"xl"}
                                    cursor="pointer"
                                    _hover={{ bg: "blackAlpha.300" }}
                                    onClick={(e) => {
                                        handleClick(repost._id);
                                        goToJobDetails(repost.jobId, repost.userId, repost.role);
                                        e.stopPropagation();
                                    }}
                                >
                                    {/* Job Details */}
                                    <HStack>
                                        {repost.jobBadge && (
                                            <Badge colorScheme={repost.jobBadge.colorScheme} variant={repost.jobBadge.variant}>
                                                {repost.jobBadge.name}
                                            </Badge>
                                        )}
                                        <Heading size="lg">{repost.jobTitle}</Heading>
                                    </HStack>
                                    <HStack>
                                        <Text>{repost.industry} | {repost.workLocationType} | {repost.employmentType}</Text>
                                    </HStack>
                                    {repost.image && (
                                        <Flex w={"100%"} justify={"center"}>
                                            <Image borderRadius={"lg"} mt={4} src={repost.image} alt="Job Image" style={{ maxWidth: '90%', maxHeight: '500px' }} />
                                        </Flex>
                                    )}
                                </Box>
                            </>
                        ) : (
                            // Original Content
                            <Flex w={"100%"} justify={"center"} mt={4}>
                                <Box w={"100%"}>
                                    <Text mt={4} mb={4} fontSize="md">{repost.originalContent}</Text>
                                    <Flex w={"100%"} justify={"center"}>
                                        {repost.originalContentImage && (
                                            <Image shadow={"lg"} mb={4} borderRadius={"3xl"} src={repost.originalContentImage} alt="Original Content Image" style={{ maxWidth: '90%', maxHeight: '500px' }} />
                                        )}
                                    </Flex>

                                    {repost.comments && repost.comments.length > 0 && (
                                        <>
                                            <Heading size={"md"}>Comments</Heading>
                                            <Box borderRadius={"lg"} bg={"whiteSmoke"} maxW={"100%"} maxH={"300px"} overflowY={"scroll"}>
                                                {repost.comments && repost.comments.map((comment, index) => (
                                                    <Box w={"fit-content"} m={2} mb={2} p={2} borderRadius={"lg"} bg={"blackAlpha.700"} shadow={"lg"} key={index}>
                                                        <HStack align={"start"} justify={"start"} >
                                                            {/* Profile Picture */}
                                                            {comment.profilePic ? (
                                                                <Image
                                                                    borderRadius="full"
                                                                    border="1px"
                                                                    bg={"white"}
                                                                    shadow="md"
                                                                    maxW="35px"
                                                                    maxH="35px"
                                                                    src={comment.profilePic}
                                                                    alt={`Profile image of ${comment.name}`}
                                                                />
                                                            ) : (
                                                                <Avatar bg={"#01BF02"} size="sm" name={comment.name || "User"} />
                                                            )}

                                                            {/* Comment Content */}
                                                            <Flex w={"fit-content"} justify={"flex-start"} align={"flex-end"}>
                                                                <VStack w={"fit-content"} align="start">
                                                                    <Heading
                                                                        as="button"
                                                                        color={"white"}
                                                                        size="sm"
                                                                        onClick={() => goToUserProfile(comment.userId)}
                                                                        _hover={{ textDecoration: 'underline' }}
                                                                    >
                                                                        {comment.name}
                                                                    </Heading>
                                                                    <Text color={"white"}>{comment.comment}</Text>
                                                                </VStack>

                                                                {/* Upvote and Downvote Buttons 

                                                        <HStack justify={"end"} w={"15%"}>
                                                            <Button color={"white"} variant={"ghost"} size="xs" onClick={() => handleVote(repost._id, comment._id, 'up')} disabled={comment.upMarks > 0}>
                                                                👍 {comment.upMarks}
                                                            </Button>
                                                            <Button color={"white"} variant={"ghost"} size="xs" onClick={() => handleVote(repost._id, comment._id, 'down')} disabled={comment.downMarks > 0}>
                                                                👎 {comment.downMarks}
                                                            </Button>
                                                        </HStack> */}
                                                            </Flex>
                                                        </HStack>

                                                    </Box>
                                                ))}
                                            </Box>
                                        </>
                                    )}
                                </Box>
                            </Flex>
                        )}

                        {/* Updated Interactions Bar */}
                        <Stack justify={"flex-end"} direction="row" mt="4" align="center">
                            {repost.contentType === 'job' && (
                                <Flex justify={"center"} align={"center"} p={2} w={"fit-content"} borderRadius={"md"} >
                                    <FontAwesomeIcon icon={faHandPointer} size="sm" />
                                    <Text ml={2}>{repost.clicks} clicks</Text>
                                </Flex>
                            )}
                            {repost.contentType === 'job' && (
                                <Flex justify={"center"} align={"center"} p={2} w={"fit-content"} borderRadius={"md"}>
                                    <FontAwesomeIcon icon={faUserPlus} size="sm" />
                                    <Text ml={2}>
                                        {applicantCounts[repost.jobId] ? applicantCounts[repost.jobId] : 0} applicants
                                    </Text>
                                </Flex>
                            )}
                            {repost.contentType === 'original' && (
                                <Flex w={"90%"}>
                                    <Input
                                        value={comments[repost._id] || ''}
                                        bg={"white"}
                                        onChange={(e) => handleCommentChange(repost._id, e)}
                                        placeholder="Add a comment"
                                        _hover={{ boxShadow: "md", borderColor: "#118AB2" }}
                                        _focus={{ outline: "#118AB2", boxShadow: "lg" }}
                                    // ... other props
                                    />
                                    <Button
                                        ml={2}
                                        bg={"#118AB2"}
                                        color={"white"}
                                        _hover={{ bg: "#01BF02" }}
                                        _active={{ bg: "teal.700" }}
                                        onClick={() => submitComment(repost._id)}
                                    >
                                        Post
                                    </Button>
                                </Flex>
                            )}
                            <Flex
                                justify={"center"}
                                align={"center"}
                                p={2}
                                w={"fit-content"}
                                _hover={{ boxShadow: "lg", bg: "white" }}
                                borderRadius={"md"}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    const alreadyLiked = repost.likes.some(like => like.userId === currentUser);
                                    if (alreadyLiked) {
                                        handleUnlike(repost._id);
                                    } else {
                                        handleLike(repost._id);
                                    }
                                }}
                            >

                                <FontAwesomeIcon
                                    icon={faHeart}
                                    size="sm"
                                    style={{ color: repost.likes.some(like => like.userId === currentUser) ? "#01BF02" : "gray" }}
                                />
                                <Text ml={2}>{repost.likes.length}</Text>
                            </Flex>
                        </Stack>
                    </Box>
                ))}
                <div ref={loaderRef}>
                    {isLoading && <Spinner mt={8} mb={8} size={"xl"} color='#01BF02' />}
                </div>
            </VStack>
            {visitorRole === 'jobseeker' && (
                <Flex w={"100%"} justify={"center"}>
                    <OriginalPostForm />
                </Flex>
            )}
        </Flex>
    );
};

export default JobDetailsLiveFeed;