import axiosInstance from './axiosInstance';
import { useEffect, useState } from 'react';

const useCurrentUser = () => {
    const [currentUserId, setCurrentUserId] = useState(null);

    useEffect(() => {
        const getCurrentUserId = async () => {
            try {
                const response = await axiosInstance.get('/current-user', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('auth_token')}`
                    }
                });
                setCurrentUserId(response.data.userId);
            } catch (error) {
                console.error('Error fetching current user ID:', error);
                // Handle error, e.g., by redirecting to login or showing an error message
            }
        };

        getCurrentUserId();
    }, []);

    return currentUserId;
};

export default useCurrentUser;
