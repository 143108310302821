// SkillEvaluation.js
import React, { useState, useEffect } from 'react';
import { Box, Text, useToast, Spinner, Flex, Textarea, Button, VStack } from '@chakra-ui/react';
import BackgroundVideo from '../../assets/tech3.mp4';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import axiosInstance from '../../components/axiosInstance';

const SkillEvaluation = () => {
    const [user, setUser] = useState({});
    const [selectedSkill, setSelectedSkill] = useState('');
    const [question, setQuestion] = useState('');
    const [questionNumber, setQuestionNumber] = useState(0);
    const [jobSeekerAnswer, setJobSeekerAnswer] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();

    useEffect(() => {
        setIsLoading(true);
        axiosInstance.get('/user')
            .then(response => {
                setUser(response.data);
            })
            .catch(error => {
                toast({
                    title: 'Error fetching user data',
                    description: error.message,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            })
            .finally(() => setIsLoading(false));
    }, []);

    const evaluateSkillHandler = (skill) => {
        setSelectedSkill(skill);
        // Start with question number 1 when a skill is selected
        fetchQuestion(skill, 1);
    };

    const fetchQuestion = async (skill, qNumber) => {
        setIsLoading(true);
        axiosInstance.post('/evaluate-skill', { skill, questionNumber: qNumber })
            .then(response => {
                console.log('Response data:', response.data);
                if (response.data.question && !response.data.assessmentCompleted) {
                    setQuestion(response.data.question);
                    setQuestionNumber(qNumber);
                    setJobSeekerAnswer(''); // Clear the jobSeekerAnswer state
                } else if (response.data.assessmentCompleted) {
                    toast({
                        title: 'Skill Assessment Completed',
                        description: 'All questions for this skill have been answered.',
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    });
                    setSelectedSkill('');
                    setQuestion('');
                    setQuestionNumber(0);
                    setJobSeekerAnswer(''); // Clear the jobSeekerAnswer state
                } else {
                    // Handle unexpected case
                    console.error('Unexpected response from server:', response.data);
                    toast({
                        title: 'Error',
                        description: 'Unexpected response from server.',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                }
            })
            .catch(error => {
                console.error('Error fetching question:', error);
                toast({
                    title: 'Error fetching question',
                    description: error.toString(),
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            })
            .finally(() => setIsLoading(false));
    };

    const handleAnswerSubmit = () => {
        if (jobSeekerAnswer.trim() === '') {
            toast({
                title: 'Empty Answer',
                description: 'Please provide an answer before submitting.',
                status: 'warning',
                duration: 5000,
                isClosable: true,
            });
            return;
        }
        // Submit the answer to the current question and potentially fetch the next question
        setIsLoading(true);
        axiosInstance.post('/evaluate-skill', { skill: selectedSkill, questionNumber, jobSeekerAnswer })
            .then(response => {
                if (response.data.question) {
                    // If there's a next question, update the question state and question number
                    setQuestion(response.data.question);
                    setQuestionNumber(response.data.questionNumber);
                    setJobSeekerAnswer(''); // Clear the jobSeekerAnswer state
                } else if (response.data.assessmentCompleted) {
                    // Mark the assessment as completed
                    toast({
                        title: 'Skill Assessment Completed',
                        description: 'Thank you for completing the assessment.',
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    });
                    setSelectedSkill('');
                    setQuestion('');
                    setQuestionNumber(0);
                    setJobSeekerAnswer(''); // Clear the jobSeekerAnswer state
                } else {
                    // Handle unexpected case
                    toast({
                        title: 'Error',
                        description: 'Unexpected response from server.',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                }
            })
            .catch(error => {
                toast({
                    title: 'Error Submitting Answer',
                    description: error.toString(),
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <Flex p={4} w={"100%"} h={"100vh"} align={"center"} justify={"center"}>
            {isLoading && (
                <Spinner size="xl" position="absolute" left="50%" top="50%" transform="translate(-50%, -50%)" />
            )}
            <video autoPlay loop muted style={{ position: "absolute", width: "100%", left: "50%", top: "50%", height: "100%", objectFit: "cover", transform: "translate(-50%, -50%)", zIndex: -1, }}>
                <source src={BackgroundVideo} type="video/mp4" />
            </video>
            <VStack spacing={6} align="stretch" w={"full"} maxW={"lg"}>
                <Flex wrap="wrap" justify="center" gap={4}>
                    {user.jobSeekerDetails?.skillLevels?.map((skill, index) => (
                        <Button
                            key={index}
                            onClick={() => evaluateSkillHandler(skill.skill)}
                            colorScheme={selectedSkill === skill.skill ? 'blue' : 'gray'}
                            rightIcon={
                                skill.tested ? (
                                    <FontAwesomeIcon icon={faCheckCircle} color="green" />
                                ) : (
                                    <FontAwesomeIcon icon={faTimesCircle} color="red" />
                                )
                            }
                        >
                            {skill.skill} - {skill.level}
                        </Button>
                    ))}
                </Flex>
                {selectedSkill && (
                    <Box bg="rgba(0, 0, 0, 0.5)" p={5} borderRadius="md">
                        <Text color="white" mb={4}>
                            Question {questionNumber}: {question}
                        </Text>
                        <Textarea
                            value={jobSeekerAnswer}
                            onChange={(e) => setJobSeekerAnswer(e.target.value)}
                            placeholder="Your answer..."
                            bg="transparent"
                            color="white"
                            borderColor="whiteAlpha.700"
                            mb={4}
                        />
                        <Button colorScheme="blue" onClick={handleAnswerSubmit}>
                            Submit
                        </Button>
                    </Box>
                )}
            </VStack>
        </Flex>
    );
};

export default SkillEvaluation;