import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Box,
    Flex,
    Heading,
    Button,
    Divider,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    FormControl,
    FormLabel,
    Input,
    Select,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    IconButton,
    useToast,
    Spinner
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import axiosInstance from './axiosInstance';

const TeamManagement = () => {
    const [isOpen, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const [newPermission, setNewPermission] = useState('');
    const [isEditingTeam, setEditingTeam] = useState(false);
    const [companyId] = useState('');
    const toast = useToast();
    const businessId = localStorage.getItem('businessId');
    const [teamMembers, setTeamMembers] = useState([]);

    // Load team members when the component mounts
    useEffect(() => {
        const loadTeamMembers = async () => {
            const authToken = localStorage.getItem('auth_token');

            try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/team-members/${businessId}`, {
                    headers: {
                        'Authorization': `Bearer ${authToken}`
                    }
                });

                const teamMembersWithPermissions = res.data.map(member => ({
                    ...member.user,  // Spread user details
                    permission: member.permissionLevel,  // Map permissionLevel to permission
                }));

                setTeamMembers(teamMembersWithPermissions);
                console.log('Team Members:', teamMembersWithPermissions);
            } catch (error) {
                toast({
                    title: 'Error',
                    description: 'Failed to fetch team members.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        };
       
        loadTeamMembers();
    }, [businessId, toast]);

    const onOpen = () => setOpen(true);
    const onClose = () => setOpen(false);
    const toggleEditTeam = () => setEditingTeam((prev) => !prev);

    const handleInvite = async () => {
        setIsLoading(true);
        const authToken = localStorage.getItem('auth_token'); // Get token from local storage
        const businessId = localStorage.getItem('businessId');

        if (!newEmail || !newPermission) {
            toast({
                title: 'Error',
                description: 'Both email and permission level are required',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        const newTeamMember = {
            email: newEmail,
            permission: newPermission,
            companyId: businessId
        };
        console.log("Sending invite with data:", newTeamMember);

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/send-invite/${businessId}`, newTeamMember, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                }
            });
            toast({
                title: 'Success',
                description: 'Invitation sent successfully!',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            // Add the new team member to the teamMembers state array
            setTeamMembers([...teamMembers, newTeamMember]);
        } catch (error) {
            if (error.response && error.response.status === 403) {
                toast({
                    title: 'Permission Error',
                    description: 'You do not have permission to make this change.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                const serverMessage = error.response?.data?.message || error.message;
                toast({
                    title: 'Error',
                    description: `Could not send invitation: ${serverMessage}`,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        };

        // Clear the input fields
        setNewEmail('');
        setNewPermission('');
        setIsLoading(false);
    };

    const handleDelete = async (employerId) => {
        setIsLoading(true);
        const authToken = localStorage.getItem('auth_token');

        try {
            await axiosInstance.delete(`/delete-employer/${employerId}`, {
                headers: { 'Authorization': `Bearer ${authToken}` }
            });

            // Remove the employer from the local state
            setTeamMembers(teamMembers.filter(member => member._id !== employerId));

            toast({
                title: 'Invitation Deleted',
                description: 'The invitation has been successfully deleted.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });

        } catch (error) {

            if (error.response && error.response.status === 403) {
                toast({
                    title: 'Error',
                    description: 'You do not have permission to make this change.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });

            } else {
                toast({
                    title: 'Error',
                    description: 'Failed to delete invitation.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        }

        setIsLoading(false);
    };

    const handlePermissionChange = (index, newPermissionValue) => {
        setTeamMembers(currentMembers =>
            currentMembers.map((member, idx) => {
                if (idx === index) {
                    // Use a property that clearly indicates this is the edited permission
                    return { ...member, editedPermission: newPermissionValue };
                }
                return member;
            })
        );
    };

    const saveTeamInfo = async () => {
        setIsLoading(true);

        for (let member of teamMembers) {
            if (member.editedPermission && member.editedPermission !== member.permission) {
                const authToken = localStorage.getItem('auth_token');
                try {
                    await axiosInstance.put(`/update-permission/${member._id}`,
                        { permissionLevel: member.editedPermission }, // Ensure this matches the backend expected field
                        { headers: { 'Authorization': `Bearer ${authToken}` } }
                    );
                    member.permission = member.editedPermission; // Update local state
                } catch (error) {
                    console.error('Error updating permission:', error);

                    if (error.response && error.response.status === 403) {
                        toast({
                            title: 'Error',
                            description: 'You do not have permission to make this change.',
                            status: 'error',
                            duration: 3000,
                            isClosable: true,
                        });
                    } else {
                        toast({
                            title: 'Error',
                            description: 'Failed to update permission.',
                            status: 'error',
                            duration: 3000,
                            isClosable: true,
                        });
                    }
                }
            }
        }

        setIsLoading(false);
        setEditingTeam(false);
        setTeamMembers([...teamMembers]); // This re-renders the component with updated permissions
    };

    return (
        <Box borderRadius="lg" p={5} boxShadow="md" bg="white">
            <Flex justifyContent="space-between">
                <Heading size="md">Team Management</Heading>
                <Button size="sm" onClick={toggleEditTeam}>
                    {isEditingTeam ? 'Cancel' : 'Edit'}
                </Button>
            </Flex>
            <Divider my={3} />
            <Button bg={"#118AB2"} color={"white"} _hover={{ bg: "#01BF02" }} onClick={onOpen}>
                Invite New Member
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Invite New Team Member</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl id="email">
                            <FormLabel>Email</FormLabel>
                            <Input
                                type="email"
                                value={newEmail}
                                onChange={(e) => setNewEmail(e.target.value)}
                            />
                        </FormControl>
                        <FormControl id="permission" mt={4}>
                            <FormLabel>Permission Level</FormLabel>
                            <Select
                                placeholder="Select permission level"
                                value={newPermission}
                                onChange={(e) => setNewPermission(e.target.value)}
                            >
                                <option value="Admin">Admin</option>
                                <option value="Business User">Business User</option>
                                <option value="Staff/User Manager">Staff/User Manager</option>
                                <option value="Recruiter/Agency">Recruiter/Agency</option>
                                <option value="Applicant/User">Applicant/User</option>
                                <option value="Viewer/Visitor">Viewer/Visitor</option>
                            </Select>
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            bg={"#118AB2"}
                            color={"white"}
                            _hover={{ bg: "#01BF02" }}
                            mr={3}
                            onClick={handleInvite}
                            disabled={isLoading}  // Disable the button when loading
                        >
                            {isLoading ? <Spinner size="sm" /> : "Send Invite"}
                        </Button>
                        <Button variant="ghost" onClick={onClose}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {isEditingTeam ? (
                <>
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th>Email</Th>
                                <Th>Permission Level</Th>
                                <Th>Delete</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {teamMembers.map((member, index) => (
                                <Tr key={index}>
                                    <Td><Input type="email" defaultValue={member.email} /></Td>
                                    <Td>
                                        <Select
                                            defaultValue={member.permission}
                                            onChange={(e) => handlePermissionChange(index, e.target.value)}
                                        >
                                            <option value="admin">Admin</option>
                                            <option value="Business User">Business User</option>
                                            <option value="Staff/User Manager">Staff/User Manager</option>
                                            <option value="Recruiter/Agency">Recruiter/Agency</option>
                                            <option value="Applicant/User">Applicant/User</option>
                                            <option value="Viewer/Visitor">Viewer/Visitor</option>
                                        </Select>
                                    </Td>
                                    <Td>
                                        <IconButton
                                            aria-label="Delete"
                                            icon={<DeleteIcon />}
                                            onClick={() => handleDelete(member._id)}
                                        />
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                    <Button mt={4} colorScheme="blue" onClick={saveTeamInfo}>
                        Save
                    </Button>
                </>
            ) : (
                <>
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th>Email</Th>
                                <Th>Permission Level</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {teamMembers.map((member, index) => (
                                <Tr key={index}>
                                    <Td>{member.email}</Td>
                                    <Td>{member.permission}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </>
            )}
        </Box >
    );
};

export default TeamManagement;