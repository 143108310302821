import React, { useState, useEffect } from 'react';
import { Box, Button, List, ListItem, Text, VStack, Image, HStack, Flex } from '@chakra-ui/react';
import axiosInstance from './axiosInstance';

const ConnectionsRequest = () => {
    const [connections, setConnections] = useState([]);
    const token = localStorage.getItem('auth_token');

    useEffect(() => {
        const fetchConnections = async () => {
            try {
                const response = await axiosInstance.get('/connections', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const pendingConnections = response.data.connections.filter(conn => conn.status === 'pending');
                setConnections(pendingConnections);
            } catch (error) {
                console.error('Error fetching connections:', error);
            }
        };

        fetchConnections();
    }, [token]);

    const handleConnectionResponse = async (connectionId, isAccepted) => {
        try {
            const response = await axiosInstance.put(
                `/connections/${connectionId}/response`,
                { accepted: isAccepted },
                { headers: { Authorization: `Bearer ${token}` } },
            );

            if (response.status === 200) {
                setConnections(connections.map(conn => conn._id === connectionId
                    ? { ...conn, status: isAccepted ? 'approved' : 'rejected' }
                    : conn));
            }
        } catch (error) {
            console.error('Error updating connection response:', error);
        }
    };

    const buttonStyles = {
        accept: { size: "sm", m: 1, color: "white", bg: "#188AB2", _hover: { bg: "#01BF02" } },
        reject: { size: "sm", m: 1, color: "white", bg: "#EF476F", _hover: { bg: "red.600" } }
    };

    return (
        <Box w={"100%"}>
            <VStack w={"100%"} spacing={4}>
                <List w={"100%"}>
                    {connections.map(({ _id, requesterPFP, requesterName, status, comment, reason }) => (
                        <ListItem key={_id}>
                            <HStack shadow={"md"} w="100%" p={2} m={1} bg="gray.300" borderRadius="lg" justify={"space-between"}>
                                {requesterPFP ? (
                                    <Image src={requesterPFP} boxSize="70px" borderRadius="full" shadow="lg" />
                                ) : (
                                    <Box
                                        boxSize="70px"
                                        borderRadius="full"
                                        bg="#01BF02"
                                        color="white"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        fontSize="2xl"
                                        shadow="lg"
                                    >
                                        {requesterName.split(' ').map(n => n[0]).join('')}
                                    </Box>
                                )}

                                <VStack>
                                    <HStack w="100%">
                                        <Text>{requesterName} wants to {reason}</Text>
                                    </HStack>
                                    <Text>"{comment}"</Text>
                                </VStack>
                                {status === 'pending' && (
                                    <VStack>
                                        <Flex justify="center">
                                            <Button {...buttonStyles.accept} onClick={() => handleConnectionResponse(_id, true)}>
                                                Accept
                                            </Button>
                                            <Button {...buttonStyles.reject} onClick={() => handleConnectionResponse(_id, false)}>
                                                Reject
                                            </Button>
                                        </Flex>
                                        <Text>Status: {status}</Text>
                                    </VStack>
                                )}
                            </HStack>
                        </ListItem>
                    ))}
                </List>
            </VStack>
        </Box>
    );
};

export default ConnectionsRequest;