import React, { useState } from 'react';
import {
    Flex,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Input,
    useDisclosure,
    IconButton,
    Avatar,
    VStack,
    useToast,
    HStack
} from '@chakra-ui/react';
import { Search2Icon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { FaUsers } from 'react-icons/fa';
import axiosInstance from '../../components/axiosInstance';

const UserSearchModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const navigate = useNavigate();
    const toast = useToast();

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearch = async () => {
        if (searchTerm.trim() === '') {
            toast({
                title: 'Search term is empty',
                description: 'Please enter a search term.',
                status: 'info',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        try {
            const response = await axiosInstance.get(`/search?query=${encodeURIComponent(searchTerm)}`);
            setSearchResults(response.data);
        } catch (error) {
            toast({
                title: 'Error searching for user',
                description: error.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleResultClick = (userId) => {
        navigate(`/user/${userId}`);
        onClose(); // Close the modal after navigation
    };

    return (
        <Flex direction="column" w={"100%"} justify={"center"} align={"center"}>
            <Flex
                direction="column"
                p={2}
                align="center"
                _hover={{
                    shadow: "lg",
                    bg: "green",
                    color: "white",
                    transform: "scale(1.05)",
                }}
                borderRadius="xl"
                cursor="pointer"
                transition="transform 0.2s ease-in-out"
                onClick={onOpen}
            >
                <FaUsers size="24px" color="#02bf01" />
                <Text fontWeight={"semibold"}>Find Connections</Text>
            </Flex>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Search...</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <HStack w={"100%"} align={"flex-start"} justify={"flex-end"}>
                            <Input
                                placeholder="Search by name or email"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                mb={4}
                            />
                            <IconButton
                                aria-label="Search"
                                icon={<Search2Icon />}
                                onClick={handleSearch}
                            />
                        </HStack>
                        <VStack mt={4} spacing={4} align="stretch">
                            {searchResults.map((result) => (
                                <Flex
                                    key={result._id}
                                    align="center"
                                    p={2}
                                    cursor="pointer"
                                    _hover={{ bg: '#118AB2', color: "white" }}
                                    borderRadius={"md"}
                                    onClick={() => handleResultClick(result._id)}
                                >
                                    <Avatar
                                        src={result.PFP || undefined}
                                        name={`${result.firstName} ${result.lastName}`}
                                        bg={"#01BF02"}
                                        size="sm"
                                        mr={2}
                                    />
                                    <Text>{`${result.firstName} ${result.lastName}`}</Text>
                                </Flex>
                            ))}
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Flex>
    );
};

export default UserSearchModal;
