import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box, Button, FormControl, FormLabel, Input, VStack, useToast, Flex, Image, Text, Link, InputGroup, InputRightElement,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Background from '../../assets/Background.webp';
import Logo from '../../assets/whitelogo.png';

const UserLogin = () => {
    const navigate = useNavigate();
    const toast = useToast();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {

            const lowercaseEmail = email.toLowerCase();

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/user-login`, { lowercaseEmail, password });
            const { token } = response.data; // JWT token

            console.log("JWT Token:", token); // Log the JWT token

            localStorage.setItem('auth_token', token);

            // Handle referrals here if needed
            const jobId = localStorage.getItem('jobId');
            if (jobId) {
                await handleReferral(token, jobId);
            }

            setIsLoading(false);

            toast({
                title: 'Login Successful.',
                description: 'You are now logged in.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });

            navigate('/user-home'); // Navigate to home regardless of role
        } catch (error) {
            setIsLoading(false);
            toast({
                title: 'Login Failed.',
                description: error.response?.data?.message || 'Failed to login, please check your credentials.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleReferral = async (token, jobId) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/referrals`, {
                jobId,
                referralId: localStorage.getItem('referralId'),
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });

            localStorage.removeItem('jobId');
            localStorage.removeItem('referralId');
        } catch (error) {
            console.error('Error handling referral:', error);
        }
    };

    return (
        <Flex
            direction="column"
            align="center"
            justify="center"
            height="100vh"
            width="100%"
            backgroundImage={Background}
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
        >
            <Box
                p={8}
                maxW="xl"
                borderWidth={3}
                borderRadius="xl"
                boxShadow="xl"
                backgroundColor="blackAlpha.700"
                borderColor={"whiteAlpha.800"}
            >
                <VStack spacing={4} align="center">
                    <Image src={Logo} w="150px" />
                    <Text fontSize="2xl" fontWeight="bold" color="white">Login</Text>
                </VStack>
                <form onSubmit={handleLogin}>
                    <VStack spacing={4} mt={4}>
                        <FormControl isRequired>
                            <FormLabel color="white">Email</FormLabel>
                            <Input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter your email"
                                bg="white"
                            />
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel color="white">Password</FormLabel>
                            <InputGroup>
                                <Input
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Enter your password"
                                    bg="white"
                                />
                                <InputRightElement>
                                    <Button _hover={{ bg: "#01bf02", color: "white" }} variant="ghost" size="sm" onClick={() => setShowPassword(!showPassword)}>
                                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                        <Button
                            isLoading={isLoading}
                            loadingText="Logging in..."
                            type="submit"
                            variant="ghost"
                            color="white"
                            size="lg"
                            fontSize="md"
                            width="full"
                            _hover={{ bg: "#01bf02", color: "white" }}
                        >
                            Log In
                        </Button>
                    </VStack>
                </form>
                <Text color="whiteAlpha.800" mt={4}>
                    Don't have an account? <Link color="#01bf02" href="/user-signup">Sign up</Link>
                </Text>
            </Box>
        </Flex>
    );
};

export default UserLogin;
