//RoleSelection.js
import React, { useState } from 'react';
import { Box, Flex, Text, useRadio } from '@chakra-ui/react';
import { FaBuilding, FaUser, FaBriefcase, FaSearch } from 'react-icons/fa';

function RoleCard({ label, description, icon, value, onClick, isSelected }) {
    const { getInputProps, getCheckboxProps } = useRadio({ value });

    const input = getInputProps();
    const checkbox = getCheckboxProps();

    return (
        <Box as="label" flex="1">
            <input {...input} checked={isSelected} />
            <Flex
                {...checkbox}
                cursor="pointer"
                height={"110px"}
                borderWidth="3px"
                borderColor={"white"}
                borderRadius="md"
                boxShadow="md"
                p={5}
                m={1}
                onClick={() => onClick(value)}
                bg={isSelected ? '#01bf02' : 'white'}
                color={isSelected ? 'black' : 'black'}
            >
                <Box as={icon} size="30px" mr={3} />
                <Flex direction="column">
                    <Text fontWeight="bold">{label}</Text>
                    <Text fontSize="sm">{description}</Text>
                </Flex>
            </Flex>
        </Box>
    );
}

export default function RoleSection({ setRole }) {
    const [selectedRole, setSelectedRole] = useState(null);

    const handleRoleCardClick = (role) => {
        const newSelectedRole = selectedRole === role ? null : role;
        setSelectedRole(newSelectedRole);
        setRole(newSelectedRole);
    };

    const roles = [
        {
            value: 'jobseeker',
            icon: FaUser,
            label: 'Talent',
            description: 'Find a full-time, part-time, or gig work.',
        },
        {
            value: 'recruiter',
            icon: FaSearch,
            label: 'Recruiter',
            description: 'Freelance Recruiting',
        },
        {
            value: 'employer',
            icon: FaBuilding,
            label: 'Employer',
            description: 'Hire for my company',
        },
    ];

    return (
            <Flex justify={"center"} align={"center"} w="100%">
                {roles.map((role) => (
                    <RoleCard
                        key={role.value}
                        value={role.value}
                        icon={role.icon}
                        label={role.label}
                        description={role.description}
                        onClick={handleRoleCardClick}
                        isSelected={selectedRole === role.value}
                    />
                ))}
            </Flex>
    );
}
