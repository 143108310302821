import React, { useState } from 'react';
import axiosInstance from '../../../components/axiosInstance';
import { Box, Flex, useToast, Text, Input, Textarea, Button, FormControl, FormLabel, Stack, Checkbox, HStack } from '@chakra-ui/react';
import Background from '../../../assets/Background.webp';

function CreateProject() {
    const toast = useToast();
    const [step, setStep] = useState(1);
    const [projectDescription, setProjectDescription] = useState('');
    const [followUpQuestions, setFollowUpQuestions] = useState([]);
    const [followUpAnswers, setFollowUpAnswers] = useState({});
    const [projectName, setProjectName] = useState('');
    const [recommendedSkills, setRecommendedSkills] = useState([]);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [budgetRange, setBudgetRange] = useState('');
    const [duration, setDuration] = useState('');

    const handleNext = async () => {
        if (step === 1) {
            try {
                const response = await axiosInstance.post('/generate-follow-up-questions', {
                    projectDescription,
                });
                setFollowUpQuestions(response.data.followUpQuestions);
                setStep((prevStep) => prevStep + 1);
            } catch (error) {
                toast({
                    title: 'Error generating follow-up questions.',
                    description: 'An error occurred while generating follow-up questions. Please try again.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        } else if (step === 2) {
            try {
                const response = await axiosInstance.post('/generate-project-details', {
                    projectDescription,
                    followUpAnswers,
                });
                setProjectName(response.data.projectName);
                setRecommendedSkills(response.data.skills);
                setDuration(response.data.duration);
                setStep((prevStep) => prevStep + 1);
            } catch (error) {
                toast({
                    title: 'Error generating project details.',
                    description: 'An error occurred while generating project details. Please try again.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        } else {
            setStep((prevStep) => prevStep + 1);
        }
    };

    const handleBack = () => {
        setStep((prevStep) => prevStep - 1);
    };

    const handleFollowUpAnswerChange = (questionId, answer) => {
        setFollowUpAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questionId]: answer,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Send the project details to the backend for final analysis
            const response = await axiosInstance.post('/analyze-project', {
                name: projectName,
                description: projectDescription,
                skills: selectedSkills,
                budgetRange,
                duration,
            });

            const { categories, subCategories, tags } = response.data;

            // Create the project with the analyzed data
            await axiosInstance.post('/projects', {
                name: projectName,
                description: projectDescription,
                skills: selectedSkills,
                budgetRange,
                duration,
                categories,
                subCategories,
                tags,
            });

            toast({
                title: 'Project created.',
                description: 'Your project has been successfully created.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            // Reset form fields and go back to step 1
            setStep(1);
            setProjectDescription('');
            setFollowUpQuestions([]);
            setFollowUpAnswers({});
            setProjectName('');
            setRecommendedSkills([]);
            setSelectedSkills([]);
            setBudgetRange('');
            setDuration('');
        } catch (error) {
            toast({
                title: 'Error creating project.',
                description: 'An error occurred while creating your project. Please try again.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const renderStep = () => {
        switch (step) {
            case 1:
                return (
                    <>
                        <Text fontSize="xl" fontWeight="bold" mb={4}>
                            👋 Hey there! Let's start by telling me about your project vision.
                        </Text>
                        <FormControl id="projectDescription" mb={6}>
                            <Textarea
                                value={projectDescription}
                                onChange={(e) => setProjectDescription(e.target.value)}
                                placeholder="Describe your project vision"
                                required
                            />
                        </FormControl>
                        <Button onClick={handleNext} colorScheme="blue" size="lg" w="100%">
                            Next
                        </Button>
                    </>
                );
            case 2:
                return (
                    <>
                        <Text fontSize="xl" fontWeight="bold" mb={4}>
                            📝 Thanks for the details! I have a few follow-up questions to better understand your project:
                        </Text>
                        {followUpQuestions?.map((question) => (
                            <FormControl key={question.id} mb={4}>
                                <FormLabel>{question.text}</FormLabel>
                                <Input
                                    type="text"
                                    value={followUpAnswers[question.id] || ''}
                                    onChange={(e) => handleFollowUpAnswerChange(question.id, e.target.value)}
                                    placeholder="Enter your answer"
                                    required
                                />
                            </FormControl>
                        ))}
                        <Flex justify="space-between">
                            <Button onClick={handleBack} size="lg">
                                Back
                            </Button>
                            <Button onClick={handleNext} colorScheme="blue" size="lg">
                                Next
                            </Button>
                        </Flex>
                    </>
                );
            case 3:
                return (
                    <>
                        <Text fontSize="xl" fontWeight="bold" mb={4}>
                            📝 Great! Here's a suggested project title and details based on your vision and answers:
                        </Text>
                        <FormControl id="projectName" mb={4}>
                            <FormLabel>Project Title</FormLabel>
                            <Input
                                type="text"
                                value={projectName}
                                onChange={(e) => setProjectName(e.target.value)}
                                placeholder="Project Title"
                                required
                            />
                        </FormControl>
                        <FormControl id="skills" mb={4}>
                            <FormLabel>Recommended Skills</FormLabel>
                            {recommendedSkills.length > 0 ? (
                                <Stack spacing={4}>
                                    {recommendedSkills?.map((skill) => (
                                        <Checkbox
                                            key={skill}
                                            value={skill}
                                            isChecked={selectedSkills.includes(skill)}
                                            onChange={(e) => {
                                                const isChecked = e.target.checked;
                                                if (isChecked) {
                                                    setSelectedSkills([...selectedSkills, skill]);
                                                } else {
                                                    setSelectedSkills(selectedSkills.filter((s) => s !== skill));
                                                }
                                            }}
                                        >
                                            {skill}
                                        </Checkbox>
                                    ))}
                                </Stack>
                            ) : (
                                <Text>No recommended skills available.</Text>
                            )}
                        </FormControl>
                        <FormControl id="budgetRange" mb={4}>
                            <HStack>
                            <FormLabel>Budget Range</FormLabel><Text fontSize={"sm"}>(will not be shared)</Text>
                            </HStack>
                            <Input
                                type="text"
                                value={budgetRange}
                                onChange={(e) => setBudgetRange(e.target.value)}
                                placeholder="Enter your budget range"
                                required
                            />
                        </FormControl>
                        <FormControl id="duration" mb={6}>
                            <FormLabel>Estimated Project Duration</FormLabel>
                            <Input
                                type="text"
                                value={duration}
                                onChange={(e) => setDuration(e.target.value)}
                                placeholder="Project Duration"
                                required
                            />
                        </FormControl>
                        <Flex justify="space-between">
                            <Button onClick={handleBack} size="lg">
                                Back
                            </Button>
                            <Button onClick={handleNext} colorScheme="blue" size="lg">
                                Next
                            </Button>
                        </Flex>
                    </>
                );
            case 4:
                return (
                    <>
                        <Text fontSize="xl" fontWeight="bold" mb={4}>
                            🎉 You're all set! Here's a summary of your project:
                        </Text>
                        <Box mb={6}>
                            <Text fontSize="lg" fontWeight="bold" mb={2}>
                                Project Title
                            </Text>
                            <Text>{projectName}</Text>
                        </Box>
                        <Box mb={6}>
                            <Text fontSize="lg" fontWeight="bold" mb={2}>
                                Project Description
                            </Text>
                            <Text>{projectDescription}</Text>
                        </Box>
                        <Box mb={6}>
                            <Text fontSize="lg" fontWeight="bold" mb={2}>
                                Selected Skills
                            </Text>
                            <Text>{selectedSkills.join(', ')}</Text>
                        </Box>
                        <Box mb={6}>
                            <Text fontSize="lg" fontWeight="bold" mb={2}>
                                Budget Range
                            </Text>
                            <Text>{budgetRange}</Text>
                        </Box>
                        <Box mb={6}>
                            <Text fontSize="lg" fontWeight="bold" mb={2}>
                                Estimated Project Duration
                            </Text>
                            <Text>{duration}</Text>
                        </Box>
                        <Flex justify="space-between">
                            <Button onClick={handleBack} size="lg">
                                Back
                            </Button>
                            <Button type="submit" colorScheme="green" size="lg">
                                Create Project
                            </Button>
                        </Flex>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <Flex direction="column" h="100vh" w="100%">
            {/* Background container */}
            <Box
                position="fixed"
                top={0}
                left={0}
                right={0}
                bottom={0}
                bgImage={Background}
                bgSize="cover"
                bgRepeat="no-repeat"
                bgPosition="center"
                zIndex={-1}
            />
            {/* Content container */}
            <Flex flex="1" w="100%" align="center" justify="center" pl="300px">
                <Box w="100%" maxW="600px" bg="white" borderRadius="md" p={8} boxShadow="lg" m="auto">
                    <Text fontSize="3xl" fontWeight="bold" mb={6}>
                        🚀 Let's Create Your Project!
                    </Text>
                    <form onSubmit={handleSubmit}>{renderStep()}</form>
                </Box>
            </Flex>
        </Flex>
    );
}

export default CreateProject;