import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box, Flex, Heading, Text, Avatar, Button, useToast, Image, FormControl, FormLabel, Input, VStack, Stack, SimpleGrid, Center, IconButton, Tooltip, Icon, FormHelperText, InputGroup, InputLeftAddon, Select, HStack, Textarea,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Badge,
} from '@chakra-ui/react';
import { socketAtom } from '../../socketAtom';
import { useAtom } from 'jotai';
import axios from 'axios';
import ReactLinkify from 'react-linkify';
import axiosInstance from '../../components/axiosInstance';
import Background from '../../assets/Background.webp';
import Header from '../../components/Header';
import UserFeed from './UserFeed';
import FreelanceModal from '../../components/FreelanceModal';
import ResumeAdd from '../../components/AddResume';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import UserConnectionsRequest from '../../components/UserConnectionRequests';
import UserConnections from './UserConnections';
import PFPEdit from './PFPEdit';
import { Link } from 'react-router-dom';
import { faCheckSquare, faCircleXmark, faComments, faCopy, faFileLines } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faCoins, faFilePen, faFileUpload, faMinus, faPlus, faStore, faStar } from '@fortawesome/free-solid-svg-icons';
import { FaBriefcase, FaCalendarAlt, FaCalendarCheck, FaEnvelope, FaInfoCircle, FaMoneyBillWave, FaPhone, FaSearch } from 'react-icons/fa';
import SwipeIndicator from '../../components/SwipeIndicator';
import SmartMatchAi from './UserMatchAI';
import ProposalForm from '../../components/ProposalForm';

const UserHome = () => {
    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
        email: '',
        yourTitle: '',
        phoneNumber: '',
        bio: '',
        currentEmployer: '',
        availability: '',
    });
    const [sectionStates, setSectionStates] = useState({
        profile: true,
        connections: true,
        jobSeeking: true,
        freelance: true,
        organizations: true,
    });
    const [editMode, setEditMode] = useState(false);
    const [isFreelanceModalOpen, setIsFreelanceModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [copied, setCopied] = useState(false);
    const [commentsEnabled, setCommentsEnabled] = useState({});
    const [isFeedbackModalOpen, setFeedbackModalOpen] = useState(false);
    const [hasSubmittedProposal, setHasSubmittedProposal] = useState({});
    const [selectedApplicant, setSelectedApplicant] = useState(null);
    const [applicationStatus, setApplicationStatus] = useState('');
    const [feedbackRating, setFeedbackRating] = useState(0);
    const [existingMessages, setExistingMessages] = useState({});
    const [isTyping, setIsTyping] = useState({});
    const [typingUser, setTypingUser] = useState({});
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [messageInput, setMessageInput] = useState({});
    const [socket, setSocket] = useAtom(socketAtom);
    const [feedbackComment, setFeedbackComment] = useState('');
    const [isMatchingProjectsLoading, setIsMatchingProjectsLoading] = useState(true);
    const [associatedBusinesses, setAssociatedBusinesses] = useState([]);
    const [matchingProjects, setMatchingProjects] = useState([]);
    const [connectionIds, setConnectionIds] = useState([]);
    const [employerConnectionRooms, setEmployerConnectionRooms] = useState([]);
    const [applicants, setApplicants] = useState([]);
    const [solanaWallet, setSolanaWallet] = useState(user.solanaWallet || "");
    const { isOpen: isPointsModalOpen, onOpen: onPointsModalOpen, onClose: onPointsModalClose } = useDisclosure();
    const { isOpen: isProposalModalOpen, onOpen: onProposalModalOpen, onClose: onProposalModalClose } = useDisclosure();
    const [selectedProject, setSelectedProject] = useState(null);
    const endOfMessagesRef = useRef(null);
    const toast = useToast();

    const navigate = useNavigate();

    // Remove the fetchMatchingProjects function call from the initial useEffect hook
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get('/user');
                setUser(response.data);
                setIsLoading(false);
                await fetchAssociatedBusinesses();
            } catch (error) {
                console.error('Failed to fetch user:', error);
                setIsLoading(false);
            }
        };

        fetchData();

        const interval = setInterval(fetchData, 3 * 60 * 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    // Add a new useEffect hook to fetch matching projects whenever the user state changes
    useEffect(() => {
        if (user.isFreelancer) {
            fetchMatchingProjects();
        }
    }, [user]);

    useEffect(() => {
        if (user.homePageSetup) {
            setSectionStates({
                profile: user.homePageSetup.profile,
                connections: user.homePageSetup.connections,
                jobSeeking: user.homePageSetup.jobSeeking,
                freelance: user.homePageSetup.freelance,
                organizations: user.homePageSetup.organizations,
            });
        }
    }, [user]);

    useEffect(() => {
        fetchApplicants();
    }, []);

    const fetchApplicants = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/jobSeekers/applicants`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('auth_token')}`
                }
            });

            // Filter out applicants that are hidden
            const visibleApplicants = response.data.filter(applicant => !applicant.hide);
            setApplicants(visibleApplicants);

            // Extract the connectionIds from the applicants
            const connectionIds = visibleApplicants.map(applicant => applicant.connectionId);
            console.log('connectionIds', connectionIds);

            // Join the employer meeting room for each connectionId
            connectionIds.forEach(connectionId => {
                socket.emit('joinEmployerMeetingRoom', { connectionId });
            });

            // Set the employer connection rooms
            setEmployerConnectionRooms(connectionIds);

            const newCommentsEnabled = {};
            visibleApplicants.forEach(applicant => {
                newCommentsEnabled[applicant._id] = true;
            });
            setCommentsEnabled(newCommentsEnabled);
        } catch (error) {
            console.error('Error fetching applicants:', error);
        }
    };

    useEffect(() => {
        socket.on('existingMessages', ({ roomName, messages }) => {
            if (roomName) {
                setExistingMessages(prevMessages => {
                    const updatedMessages = {
                        ...prevMessages,
                        [roomName]: messages,
                    };
                    console.log('Updated existingMessages:', updatedMessages);
                    return updatedMessages;
                });
            } else {
                console.log('Received existingMessages with undefined roomName:', { roomName, messages });
            }
        });

        socket.on('newEmployerMessage', (message) => {
            setExistingMessages(prevMessages => ({
                ...prevMessages,
                [message.roomName]: [...(prevMessages[message.roomName] || []), message],
            }));
        });

        socket.on('typing', ({ senderId, roomName, senderFirstName, senderLastName }) => {
            setIsTyping((prevState) => ({
                ...prevState,
                [roomName]: true,
            }));
            setTypingUser((prevState) => ({
                ...prevState,
                [roomName]: `${senderFirstName} ${senderLastName}`,
            }));

            // Clear typing status after a certain duration
            setTimeout(() => {
                setIsTyping((prevState) => ({
                    ...prevState,
                    [roomName]: false,
                }));
            }, 2000);
        });

        return () => {
            socket.off('existingMessages');
            socket.off('newEmployerMessage');
        };
    }, [socket]);

    useEffect(() => {
        if (selectedRoom && existingMessages[selectedRoom]?.length > 0) {
            const timer = setTimeout(() => {
                endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
            }, 100); // Adjust the delay as needed

            return () => clearTimeout(timer);
        }
    }, [existingMessages[selectedRoom]?.length, selectedRoom]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!user) {
        return <div>User not found.</div>;
    }

    const fetchUserData = async () => {
        try {
            const { data } = await axiosInstance.get(`/user`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
                },
            });
            setUser(data);
        } catch (error) {
            console.error('Failed to fetch user data:', error);
            toast({
                title: 'Error loading profile',
                description: 'Unable to load user data. Please try again.',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const fetchMatchingProjects = async () => {
        setIsMatchingProjectsLoading(true);
        try {
            const { data: matchingProjects } = await axiosInstance.get(`/freelancer/matching-projects`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
                },
            });
            const submittedProjectIds = new Set(user.proposals.map(p => p.projectId));
            const submittedProposalsStatus = matchingProjects.reduce((acc, project) => {
                acc[project._id] = submittedProjectIds.has(project._id);
                return acc;
            }, {});

            setHasSubmittedProposal(submittedProposalsStatus);
            setMatchingProjects(matchingProjects);
        } catch (error) {
            console.error('Failed to fetch matching projects:', error);
        } finally {
            setIsMatchingProjectsLoading(false);
        }
    };

    const fetchAssociatedBusinesses = async () => {
        try {
            const { data } = await axiosInstance.get(`/associated-businesses`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
                },
            });
            setAssociatedBusinesses(data);
        } catch (error) {
            console.error('Failed to fetch associated businesses:', error);
            toast({
                title: 'Error loading associated businesses',
                description: 'Unable to load associated businesses. Please try again.',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const openFreelanceModal = () => {
        setIsFreelanceModalOpen(true);
    };

    const handleEditProfileClick = () => {
        navigate('/edit-jobseeker');
    };

    const closeFreelanceModal = () => {
        setIsFreelanceModalOpen(false);
        fetchMatchingProjects();
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(user.solanaWallet);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    const handleFreelanceSubmit = async (formData) => {
        try {
            await axiosInstance.put(`/user/freelancer-details`, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
                },
            });
            toast({
                title: 'Freelancer Details Updated',
                description: 'Your freelancer details have been successfully updated.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            // Fetch updated user data or perform any necessary actions
            fetchUserData();
        } catch (error) {
            console.error('Failed to update freelancer details:', error);
            toast({
                title: 'Update Failed',
                description: 'Failed to update freelancer details. Please try again.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const toggleEditMode = () => {
        setEditMode(!editMode);
    };

    const handleSelectOrganization = (businessId) => {
        localStorage.setItem('businessId', businessId);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUser((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSolanaWalletAddressChange = (e) => {
        setSolanaWallet(e.target.value);
    };

    const handleSaveSolanaWalletAddress = async () => {
        try {
            await axiosInstance.put(
                `/user/solana-wallet`,
                { solanaWallet },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
                    },
                }
            );
            toast({
                title: "Solana Wallet Address Saved",
                description: "Your Solana wallet address has been successfully saved.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            await fetchUserData();
            onPointsModalClose();
        } catch (error) {
            console.error("Failed to save Solana wallet address:", error);
            toast({
                title: "Save Failed",
                description: "Failed to save Solana wallet address. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleSectionToggle = async (section, value) => {
        try {
            await axiosInstance.put(
                `/user/home-page-setup`,
                { section, value },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
                    },
                }
            );
            setSectionStates((prevStates) => ({
                ...prevStates,
                [section]: value,
            }));
        } catch (error) {
            console.error(`Failed to update ${section} preference:`, error);
            toast({
                title: 'Update Failed',
                description: `Failed to update ${section} preference. Please try again.`,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleSubmit = async () => {
        try {
            setIsLoading(true)
            await axiosInstance.put(`/user/update`, {
                ...user,
                yourTitle: user.yourTitle,
                phoneNumber: user.phoneNumber,
                currentEmployer: user.currentEmployer,
                availability: user.availability,
                bio: user.bio,
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
                },
            });
            toast({
                title: 'Profile Updated',
                description: 'Your profile has been successfully updated.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            setEditMode(false);
        } catch (error) {
            console.error('Failed to update profile:', error);
            toast({
                title: 'Update Failed',
                description: 'Failed to update profile. Please try again.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
        await fetchUserData();
        setIsLoading(false)
    };

    const openProposalModal = (project) => {
        setSelectedProject(project);
        onProposalModalOpen();
    };

    const closeProposalModal = () => {
        setSelectedProject(null);
        fetchMatchingProjects();
        onProposalModalClose();
    };

    const linkDecorator = (href, text, key) => (
        <a href={href} key={key} target="_blank" rel="noopener noreferrer">
            {text}
        </a>
    );

    const openFeedbackModal = (applicantId, status) => {
        setSelectedApplicant(applicantId);
        setApplicationStatus(status);
        setFeedbackModalOpen(true);
        // Reset feedback form inputs
        setFeedbackRating(0);
        setFeedbackComment('');
    };

    const submitFeedback = async () => {
        if (feedbackRating < 1 || feedbackRating > 5) {
            toast({ title: "Invalid rating", description: "Rating must be between 1 and 5", status: "error" });
            return;
        }

        try {
            // Replace 'your-api-endpoint' with the actual endpoint
            const response = await axiosInstance.post(`/feedback`, {
                applicantId: selectedApplicant,
                rating: feedbackRating,
                comment: feedbackComment,
                applicationStatus: applicationStatus
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
                }
            });

            // Check response from the server for success
            if (response.data.success) {
                setCommentsEnabled(prevState => ({
                    ...prevState,
                    [selectedApplicant]: false
                }));
                toast({ title: "Feedback Submitted", description: "Your feedback has been submitted successfully", status: "success" });
                // Reset feedback form or additional actions

            } else {
                // Handle any issues reported by the server
                toast({ title: "Feedback Error", description: response.data.message, status: "error" });
            }

            setFeedbackModalOpen(false);
        } catch (error) {
            console.error('Error submitting feedback:', error);
            toast({ title: "Error", description: "Failed to submit feedback", status: "error" });
        }
    };

    const goToJobDetails = (jobId) => {
        navigate(`/job/${jobId}`);
    };

    const hideApplicant = async (applicantId) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/applicants/hide/${applicantId}`);
            const data = response.data;
            console.log(data.message); // Or update state to reflect the change
        } catch (error) {
            console.error('Error hiding applicant:', error);
            // Handle error, maybe display a message to the user
        }
        fetchApplicants();
    };

    const handleMessageInputChange = (connectionId, value) => {
        setMessageInput((prevState) => ({
            ...prevState,
            [connectionId]: value,
        }));
        socket.emit('typing', { roomName: connectionId, senderId: user._id, senderFirstName: user.firstName, senderLastName: user.lastName });
    };

    const sendMessage = (connectionId) => {
        const message = messageInput[connectionId];
        if (message.trim() !== '') {
            socket.emit('employerMessage', {
                roomName: connectionId,
                text: message,
                senderId: user._id,
                senderPFP: user.PFP,
                senderFirstName: user.firstName,
                senderLastName: user.lastName,
                recipientId: null,
                connectionId,
            });
            setMessageInput((prevState) => ({
                ...prevState,
                [connectionId]: '',
            }));
        }
    };

    return (
        <Flex
            direction="column"
            w="100%"
            p={5}
            bg={"black"}
            backgroundImage={Background}
            backgroundSize="contain"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
            minH="100vh"
        >
            <Header />
            <Flex justify={"center"} w={"100%"}>
                <Flex justify="space-evenly" w="60%" align="start">
                    <Box p={2} borderRadius={"lg"} bg={"blackAlpha.600"} position="sticky" maxW={"450px"} top="5" zIndex="sticky">
                        <VStack>
                            {/* profile */}
                            {sectionStates.profile ? (
                                <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={4} w="100%" bg="white" boxShadow="xl">
                                    <Flex justifyContent="space-between" alignItems="start" mb={8}>
                                        <Avatar mr={4} size="lg" name={`${user.firstName} ${user.lastName}`} src={user.PFP} />
                                        <Box maxW={"300px"}>
                                            <Heading as="h1" size="md" >
                                                {user.firstName} {user.lastName}
                                            </Heading>
                                            <Text fontSize={"sm"}>{user.bio}</Text>
                                        </Box>
                                        <Flex justify="flex-end">
                                            <IconButton
                                                icon={<FontAwesomeIcon icon={faMinus} />}
                                                variant="ghost"
                                                size={"sm"}
                                                onClick={() => handleSectionToggle('profile', false)}
                                            />
                                        </Flex>
                                    </Flex>
                                    {editMode ? (
                                        <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={5} bg="white" boxShadow="xl">
                                            {/* Editable fields */}
                                            <Stack spacing={4}>
                                                <FormControl id="bio">
                                                    <FormLabel>
                                                        <Icon as={FaInfoCircle} mr={2} />
                                                        Bio
                                                    </FormLabel>
                                                    <Textarea w={"100%"} name="bio" value={user.bio} onChange={handleInputChange} placeholder="Enter a short bio" />
                                                    <FormHelperText>Enter a short bio about yourself</FormHelperText>
                                                </FormControl>
                                                <FormControl id="yourTitle">
                                                    <FormLabel>
                                                        <Icon as={FaBriefcase} mr={2} />
                                                        Title
                                                    </FormLabel>
                                                    <Input name="yourTitle" value={user.yourTitle} onChange={handleInputChange} placeholder="Enter your job title" />
                                                    <FormHelperText>Enter your job title</FormHelperText>
                                                </FormControl>
                                                <FormControl id="currentEmployer">
                                                    <FormLabel>
                                                        <Icon as={FaBriefcase} mr={2} />
                                                        Current Employer
                                                    </FormLabel>
                                                    <Input name="currentEmployer" value={user.currentEmployer} onChange={handleInputChange} placeholder="Enter your current employer" />
                                                    <FormHelperText>Enter your current employer</FormHelperText>
                                                </FormControl>
                                                <FormControl id="availability">
                                                    <FormLabel>
                                                        <Icon as={FaCalendarCheck} mr={2} />
                                                        Availability
                                                    </FormLabel>
                                                    <Select name="availability" value={user.availability} onChange={handleInputChange}>
                                                        <option value="">Select One</option>
                                                        <option value="Employed">Employed</option>
                                                        <option value="retired">Retired</option>
                                                        <option value="Freelance">Freelance</option>
                                                        <option value="Looking for employment">Looking for Employment</option>
                                                    </Select>
                                                    <FormHelperText>Select your current availability</FormHelperText>
                                                </FormControl>
                                                <FormControl id="phoneNumber">
                                                    <FormLabel>
                                                        <Icon as={FaPhone} mr={2} />
                                                        Phone Number
                                                    </FormLabel>
                                                    <InputGroup>
                                                        <InputLeftAddon children="+1" />
                                                        <Input name="phoneNumber" value={user.phoneNumber} onChange={handleInputChange} placeholder="Enter your 10-digit phone number" />
                                                    </InputGroup>
                                                    <FormHelperText>Enter your 10-digit phone number</FormHelperText>
                                                </FormControl>
                                            </Stack>
                                            <Flex justify="flex-end" mt={6}>
                                                <Button mr={3} onClick={toggleEditMode}>Cancel</Button>
                                                <Button colorScheme="green" isLoading={isLoading} loadingText="Saving..." onClick={handleSubmit}>
                                                    Save Changes
                                                </Button>
                                            </Flex>
                                        </Box>
                                    ) : (
                                        <>
                                            {/* Display user information */}
                                            <Stack spacing={4}>
                                                <HStack>
                                                    {user.yourTitle ? (
                                                        <Flex align="center">
                                                            <Icon size={"xs"} as={FaBriefcase} mr={2} />
                                                            <Text fontSize={"sm"}>{user.yourTitle}</Text>
                                                        </Flex>
                                                    ) : (
                                                        <Flex align="center">
                                                            <Icon size={"xs"} as={FaBriefcase} mr={2} />
                                                            <Text fontSize={"sm"}>Add Title</Text>
                                                        </Flex>
                                                    )}
                                                    {user.currentEmployer && (
                                                        <>
                                                            <Text>at</Text>
                                                            <Text >{user.currentEmployer}</Text>
                                                        </>
                                                    )}
                                                </HStack>
                                                {user.availability && (
                                                    <Flex align="center">
                                                        <Icon size={"xs"} as={FaCalendarCheck} mr={2} />
                                                        <Text fontSize={"sm"} >{user.availability}</Text>
                                                    </Flex>
                                                )}
                                                {user.phoneNumber ? (
                                                    <Flex align="center">
                                                        <Icon size={"xs"} as={FaPhone} mr={2} />
                                                        <Text fontSize={"sm"}>{user.phoneNumber}</Text>
                                                    </Flex>
                                                ) : (
                                                    <Flex align="center">
                                                        <Icon size={"xs"} as={FaPhone} mr={2} />
                                                        <Text fontSize={"sm"}>Add Phone Number</Text>
                                                    </Flex>
                                                )}
                                                {user.email && (
                                                    <Flex align="center">
                                                        <Icon size={"xs"} as={FaEnvelope} mr={2} />
                                                        <Text fontSize={"sm"}>{user.email}</Text>
                                                    </Flex>
                                                )}
                                            </Stack>
                                        </>
                                    )}
                                    <Flex w="100%" justify="flex-end" mt={2}>
                                        {/*
                                        <Tooltip label="View Earned Tokens" placement="top">
                                            <IconButton
                                                icon={<FontAwesomeIcon size="lg" icon={faCoins} />}
                                                size="xs"
                                                variant="ghost"
                                                color="#02bf01"
                                                aria-label="View Earned Tokens"
                                                transition="all 0.3s"
                                                _hover={{ transform: "translateY(-5px)", bg: "#02bf01", color: "white" }}
                                                mr={4}
                                                onClick={onPointsModalOpen}
                                            />
                                        </Tooltip>
                                                */}
                                        <Modal isOpen={isPointsModalOpen} onClose={onPointsModalClose}>
                                            <ModalOverlay />
                                            <ModalContent>
                                                <ModalHeader>Earned Tokens</ModalHeader>
                                                <ModalCloseButton />
                                                <ModalBody>
                                                    <Flex direction="column" align="center" mb={4}>
                                                        <Text fontSize="xl">JobJar Tokens Earned</Text>
                                                        <Flex mb={2}>
                                                            <Text fontSize="4xl" fontWeight="bold" color="green.500">
                                                                {Math.floor(user.points).toLocaleString()}
                                                            </Text>
                                                        </Flex>
                                                        {user.solanaWallet && (
                                                            <>
                                                                <Text fontSize="xl">Associated Solana Wallet</Text>
                                                                <Flex align="center">
                                                                    <Text fontSize={"xs"} fontWeight={"semibold"} mr={2}>
                                                                        {user.solanaWallet}
                                                                    </Text>
                                                                    <IconButton variant={"ghost"} color={"#02bf01"} size="xs" icon={<FontAwesomeIcon icon={faCopy} />} onClick={handleCopy}>
                                                                        {copied ? 'Copied!' : 'Copy'}
                                                                    </IconButton>
                                                                </Flex>
                                                            </>
                                                        )}
                                                    </Flex>
                                                    {user.solanaWallet ? (
                                                        <FormControl id="solanaWalletAddress" mb={4}>
                                                            <FormLabel mt={4}>Update Solana Wallet Address</FormLabel>
                                                            <Input
                                                                type="text"
                                                                onChange={handleSolanaWalletAddressChange}
                                                                placeholder="Enter your Solana wallet address"
                                                            />
                                                        </FormControl>
                                                    ) : (
                                                        <>
                                                            <FormControl id="solanaWalletAddress" mb={4}>
                                                                <FormLabel>Add Solana Wallet Address</FormLabel>
                                                                <Input
                                                                    type="text"
                                                                    onChange={handleSolanaWalletAddressChange}
                                                                    placeholder="Enter your Solana wallet address"
                                                                />
                                                            </FormControl>
                                                        </>
                                                    )}
                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button colorScheme="green" onClick={handleSaveSolanaWalletAddress}>
                                                        Save
                                                    </Button>
                                                </ModalFooter>
                                            </ModalContent>
                                        </Modal>
                                        {user.jobSeekerDetails && (
                                            <>
                                                <Tooltip label="See Resume" placement="top">
                                                    <a href={user.jobSeekerDetails.resumePath} target="_blank" rel="noopener noreferrer">
                                                        <IconButton
                                                            icon={<FontAwesomeIcon size='lg' icon={faFileLines} />}
                                                            size="xs"
                                                            variant="ghost"
                                                            mr={4}
                                                            colorScheme="black"
                                                            aria-label="Open Resume"
                                                            transition="all 0.3s"
                                                            _hover={{ transform: 'translateY(-5px)', bg: 'black', color: 'white' }}
                                                        />
                                                    </a>
                                                </Tooltip>
                                                {/*
                                                <Tooltip label="Update Resume" placement="top">
                                                    <Link href="/update-resume">
                                                        <IconButton
                                                            icon={<FontAwesomeIcon size='lg' icon={faFilePen} />}
                                                            size="xs"
                                                            variant="ghost"
                                                            colorScheme="orange"
                                                            aria-label="Update Resume"
                                                            transition="all 0.3s"
                                                            _hover={{ transform: 'translateY(-5px)', bg: 'orange.500', color: 'white' }}
                                                            mr={4}
                                                        />
                                                    </Link>
                                                </Tooltip>
                                        */}
                                            </>
                                        )}
                                        <Button mr={2} size={"xs"} colorScheme="green" onClick={toggleEditMode} _hover={{ bg: "green.600" }} cursor="pointer">
                                            Edit Profile
                                        </Button>
                                        <Button mr={2} size={"xs"} colorScheme="green" onClick={handleEditProfileClick} _hover={{ bg: "green.600" }} cursor="pointer">
                                            Update Resume
                                        </Button>
                                        <PFPEdit />
                                    </Flex>
                                </Box>
                            ) : (
                                <Flex w={"100%"} direction={"column"} bg="white" align="center" justify="space-between" p={2} borderWidth="1px" borderRadius="lg">
                                    <HStack w={"100%"} justify={"space-between"}>
                                        <Flex p={2}>
                                            <Avatar mr={4} size="lg" name={`${user.firstName} ${user.lastName}`} src={user.PFP} />
                                            <Box maxW={"300px"}>
                                                <Heading as="h1" size="lg" >
                                                    {user.firstName} {user.lastName}
                                                </Heading>
                                            </Box>
                                        </Flex>
                                        <Flex justify={"flex-end"}>
                                            <IconButton
                                                icon={<FontAwesomeIcon icon={faPlus} />}
                                                variant="ghost"
                                                onClick={() => handleSectionToggle('profile', true)}
                                            />
                                        </Flex>
                                    </HStack>
                                    <Flex w="100%" justify="flex-end" mt={2}>
                                        {/*
                                        <Tooltip label="View Earned Tokens" placement="top">
                                            <IconButton
                                                icon={<FontAwesomeIcon size="lg" icon={faCoins} />}
                                                size="xs"
                                                variant="ghost"
                                                color="#02bf01"
                                                aria-label="View Earned Tokens"
                                                transition="all 0.3s"
                                                _hover={{ transform: "translateY(-5px)", bg: "#02bf01", color: "white" }}
                                                mr={4}
                                                onClick={onPointsModalOpen}
                                            />
                                        </Tooltip>
                                        */}
                                        <Modal isOpen={isPointsModalOpen} onClose={onPointsModalClose}>
                                            <ModalOverlay />
                                            <ModalContent>
                                                <ModalHeader>Earned Tokens</ModalHeader>
                                                <ModalCloseButton />
                                                <ModalBody>
                                                    <Flex direction="column" align="center" mb={4}>
                                                        <Text fontSize="xl">JobJar Tokens Earned</Text>
                                                        <Flex mb={2}>
                                                            <Text fontSize="4xl" fontWeight="bold" color="green.500">
                                                                {Math.floor(user.points).toLocaleString()}
                                                            </Text>
                                                        </Flex>
                                                        {user.solanaWallet && (
                                                            <>
                                                                <Text fontSize="xl">Associated Solana Wallet</Text>
                                                                <Flex align="center">
                                                                    <Text fontSize={"xs"} fontWeight={"semibold"} mr={2}>
                                                                        {user.solanaWallet}
                                                                    </Text>
                                                                    <IconButton variant={"ghost"} color={"#02bf01"} size="xs" icon={<FontAwesomeIcon icon={faCopy} />} onClick={handleCopy}>
                                                                        {copied ? 'Copied!' : 'Copy'}
                                                                    </IconButton>
                                                                </Flex>
                                                            </>
                                                        )}
                                                    </Flex>
                                                    {user.solanaWallet ? (
                                                        <FormControl id="solanaWalletAddress" mb={4}>
                                                            <FormLabel>Update Solana Wallet Address</FormLabel>
                                                            <Input
                                                                type="text"
                                                                onChange={handleSolanaWalletAddressChange}
                                                                placeholder="Enter your Solana wallet address"
                                                            />
                                                        </FormControl>
                                                    ) : (
                                                        <>
                                                            <FormControl id="solanaWalletAddress" mb={4}>
                                                                <FormLabel>Add Solana Wallet Address</FormLabel>
                                                                <Input
                                                                    type="text"
                                                                    onChange={handleSolanaWalletAddressChange}
                                                                    placeholder="Enter your Solana wallet address"
                                                                />
                                                            </FormControl>
                                                        </>
                                                    )}
                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button colorScheme="green" onClick={handleSaveSolanaWalletAddress}>
                                                        Save
                                                    </Button>
                                                </ModalFooter>
                                            </ModalContent>
                                        </Modal>
                                        {user.jobSeekerDetails && (
                                            <Flex justify={'center'} align={'flex-end'}>
                                                <Tooltip label="See Resume" placement="top">
                                                    <a href={user.jobSeekerDetails.resumePath} target="_blank" rel="noopener noreferrer">
                                                        <IconButton
                                                            icon={<FontAwesomeIcon size='lg' icon={faFileLines} />}
                                                            size="xs"
                                                            variant="ghost"
                                                            mr={3}
                                                            colorScheme="black"
                                                            aria-label="Open Resume"
                                                            transition="all 0.3s"
                                                            _hover={{ transform: 'translateY(-2px)', bg: 'green.600', color: 'white' }}
                                                        />
                                                    </a>
                                                </Tooltip>
                                                <Button mr={2} size={"xs"} colorScheme="green" onClick={toggleEditMode} _hover={{ bg: "green.600" }} cursor="pointer">
                                                    Edit Profile
                                                </Button>
                                                <Button mr={2} size={"xs"} colorScheme="green" onClick={handleEditProfileClick} _hover={{ bg: "green.600" }} cursor="pointer">
                                                    Update Resume
                                                </Button>
                                                <PFPEdit />
                                                {/*
                                                <Tooltip label="Update Resume" placement="top">
                                                    <Link href="/update-resume">
                                                        <IconButton
                                                            icon={<FontAwesomeIcon size='lg' icon={faFilePen} />}
                                                            size="xs"
                                                            variant="ghost"
                                                            colorScheme="orange"
                                                            aria-label="Update Resume"
                                                            transition="all 0.3s"
                                                            _hover={{ transform: 'translateY(-5px)', bg: 'orange.500', color: 'white' }}
                                                            mr={4}
                                                        />
                                                    </Link>
                                                </Tooltip>
                                        */}
                                            </Flex>
                                        )}
                                    </Flex>
                                </Flex>
                            )}

                            {/* jobSeeking */}
                            {!user.jobSeekerDetails && sectionStates.jobSeeking ? (
                                <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={2} w="full" bg="white" boxShadow="xl">
                                    <Flex direction="column" align="center">
                                        <Flex mb={2} w={"100%"} justify={"flex-start"}>
                                            <Heading size="sm">Looking for work?</Heading>
                                        </Flex>
                                        <ResumeAdd />
                                    </Flex>
                                    <Flex justify="flex-end">
                                        <IconButton
                                            icon={<FontAwesomeIcon icon={faMinus} />}
                                            variant="ghost"
                                            size={"sm"}
                                            onClick={() => handleSectionToggle('jobSeeking', false)}
                                        />
                                    </Flex>
                                </Box>
                            ) : !user.jobSeekerDetails && !sectionStates.jobSeeking ? (
                                <Flex w={"100%"} bg="white" align="center" justify="space-between" p={2} borderWidth="1px" borderRadius="lg">
                                    <Heading size="sm">Small and no resume?</Heading>
                                    <IconButton
                                        icon={<FontAwesomeIcon icon={faPlus} />}
                                        variant="ghost"
                                        size={"sm"}
                                        onClick={() => handleSectionToggle('jobSeeking', true)}
                                    />
                                </Flex>
                            ) : user.jobSeekerDetails && sectionStates.jobSeeking ? (
                                <Flex w={"100%"} bg="white" align="start" justify="space-between" p={2} borderWidth="1px" borderRadius="lg">
                                    <Box w={"100%"}>
                                        <HStack w={"100%"} justify={"space-between"}>
                                            <Heading mb={2} size={"sm"} color={"black"}>SmartMatch AI Recruiter</Heading>
                                            <IconButton
                                                icon={<FontAwesomeIcon icon={faMinus} />}
                                                variant="ghost"
                                                mb={2}
                                                size={"sm"}
                                                onClick={() => handleSectionToggle('jobSeeking', false)}
                                            />
                                        </HStack>
                                        <SmartMatchAi user={user} />
                                        <Heading mt={4} size={"sm"}>Applications</Heading>
                                        <SimpleGrid columns={{ sm: 2 }} spacing={2}>
                                            {applicants.map((applicant, index) => (
                                                <Box p={2} position="relative" key={index}> {/* Add position relative here */}
                                                    <IconButton
                                                        icon={<FontAwesomeIcon shadow={"dark-lg"} color='red' size='xl' icon={faCircleXmark} />}
                                                        isRound={true}
                                                        size={"xs"}
                                                        bg={"blackAlpha.200"}
                                                        aria-label="Close"
                                                        position="absolute" // Position the button absolutely
                                                        right="0px" // Position from the right
                                                        top="0px" // Position from the top
                                                        zIndex="overlay" // Ensure it's above other content
                                                        variant="solid" // No background
                                                        _hover={{
                                                            transform: "scale(1.2)", // Enlarge the button by 20% on hover
                                                            bgColor: "red.100" // Change background color on hover (if not using ghost variant)
                                                        }}
                                                        onClick={() => hideApplicant(applicant._id)}
                                                    />
                                                    <Box
                                                        borderRadius="lg"
                                                        bg={
                                                            applicant.status === 'has been declined'
                                                                ? 'red.100'
                                                                : applicant.status === 'is pending'
                                                                    ? 'white'
                                                                    : 'white'
                                                        }
                                                        color="black"
                                                        h={"fit-content"}
                                                        _hover={{ bg: "green.200", color: "black", border: "1px", borderColor: "gray.200", borderRadius: "md" }}
                                                        borderWidth="1px"
                                                        borderColor="gray.200"
                                                        cursor="pointer"
                                                        shadow="md"
                                                        onClick={() => goToJobDetails(applicant.job.jobId)}
                                                        overflow="hidden"
                                                    >
                                                        {applicant.job.image && (
                                                            <Image
                                                                src={applicant.job.image}
                                                                alt={`${applicant.job.jobTitle} image`}
                                                                objectFit="fill"
                                                                width="full"
                                                            />
                                                        )}
                                                        <HStack align={"flex-end"}>
                                                            <Box p={4}>
                                                                <Heading size="md" mb={2}>{applicant.job.jobTitle}</Heading>
                                                                <Badge colorScheme="green" mr={2}>
                                                                    {applicant.job.employmentType}
                                                                </Badge>
                                                                <Badge colorScheme="blue">
                                                                    {applicant.job.workLocationType}
                                                                </Badge>
                                                                <Text mt={2} fontSize="sm" _hover={{ color: "white" }} color="gray.500">
                                                                    Your application {applicant.status}.
                                                                </Text>
                                                            </Box>
                                                            {commentsEnabled[applicant._id] && applicant.canComment && (
                                                                <Flex justify={"flex-end"}>
                                                                    <IconButton
                                                                        mr={1}
                                                                        mb={1}
                                                                        color="#118AB2"
                                                                        bg=""
                                                                        _hover={{ bg: "#118AB2", color: "white" }}
                                                                        aria-label="Give Feedback"
                                                                        icon={<FontAwesomeIcon size='xl' icon={faComments} />}
                                                                        onClick={(event) => {
                                                                            // Stop the event from propagating to the parent
                                                                            event.stopPropagation();
                                                                            openFeedbackModal(applicant._id, applicant.status);
                                                                        }}
                                                                    />
                                                                </Flex>
                                                            )}
                                                        </HStack>
                                                    </Box>
                                                </Box>
                                            ))}
                                        </SimpleGrid>
                                        <Modal isOpen={selectedRoom !== null} onClose={() => setSelectedRoom(null)}>
                                            <ModalOverlay />
                                            <ModalContent maxW="600px" borderRadius="lg">
                                                <ModalHeader
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    bg="gray.200"
                                                    borderRadius={"lg"}
                                                >
                                                    <Text isTruncated maxW="500px">
                                                        Employer Chat
                                                    </Text>
                                                    <ModalCloseButton />
                                                </ModalHeader>
                                                <ModalBody overflowY="auto" maxH="800px" p={4}>
                                                    {selectedRoom &&
                                                        existingMessages[selectedRoom]?.map((message, msgIndex) => {
                                                            // Parse the date using the Date constructor.
                                                            const createdAtDate = new Date(message.createdAt["$date"] || message.createdAt);
                                                            // Check if the date is valid before trying to format it.
                                                            const formattedDate = !isNaN(createdAtDate.valueOf())
                                                                ? createdAtDate.toLocaleString()
                                                                : "Time not available";

                                                            return (
                                                                <Flex
                                                                    key={msgIndex}
                                                                    direction="column"
                                                                    align={message.senderId === user._id ? "flex-end" : "flex-start"}
                                                                    m={2}
                                                                >
                                                                    <Flex align="center">
                                                                        <Tooltip hasArrow label={`${message.senderFirstName} ${message.senderLastName}`} placement="top">
                                                                            <Avatar src={message.senderPFP} size="sm" mr={2} />
                                                                        </Tooltip>
                                                                        <Box
                                                                            bg={message.senderId === user._id ? "green.200" : "gray.100"}
                                                                            borderRadius="md"
                                                                            p={3}
                                                                            boxShadow="md"
                                                                            maxWidth="80%"
                                                                        >
                                                                            <Text fontSize="sm" fontWeight="bold">
                                                                                {message.senderFirstName}
                                                                            </Text>
                                                                            <ReactLinkify componentDecorator={linkDecorator}>
                                                                                <Text>{message.text}</Text>
                                                                            </ReactLinkify>
                                                                        </Box>
                                                                    </Flex>
                                                                    <Text
                                                                        fontSize="xs"
                                                                        color="gray.500"
                                                                        alignSelf={message.senderId === user._id ? "flex-end" : "flex-start"}
                                                                        mt={1}
                                                                    >
                                                                        {formattedDate}
                                                                        {message.read && <Badge ml={2} colorScheme="green">Read</Badge>}
                                                                    </Text>
                                                                </Flex>
                                                            );
                                                        })}
                                                    <div ref={endOfMessagesRef} />
                                                </ModalBody>
                                                <ModalFooter>
                                                    <FormControl display="flex" alignItems="center" w="full">
                                                        <Input
                                                            value={messageInput[selectedRoom] || ""}
                                                            onChange={(e) => handleMessageInputChange(selectedRoom, e.target.value)}
                                                            placeholder="Type a message..."
                                                            mr={2}
                                                            borderRadius="full"
                                                            onKeyPress={(e) => {
                                                                if (e.key === "Enter") sendMessage(selectedRoom);
                                                            }}
                                                        />
                                                        <Button onClick={() => sendMessage(selectedRoom)} colorScheme="blue" borderRadius="full">
                                                            Send
                                                        </Button>
                                                    </FormControl>
                                                    {isTyping[selectedRoom] && (
                                                        <Text fontSize="sm" color="gray.500" position="absolute" bottom="10px" right="10px">
                                                            {typingUser[selectedRoom]} is typing...
                                                        </Text>
                                                    )}
                                                </ModalFooter>
                                            </ModalContent>
                                        </Modal>
                                        <Modal isOpen={isFeedbackModalOpen} onClose={() => setFeedbackModalOpen(false)}>
                                            <ModalOverlay />
                                            <ModalContent>
                                                <ModalCloseButton />
                                                <ModalBody p={4}>
                                                    <Heading size="md" mb={4}>Give Feedback</Heading>
                                                    <Box display="flex" mb={3}>
                                                        {[...Array(5)].map((_, index) => (
                                                            <FontAwesomeIcon
                                                                key={index}
                                                                icon={faStar}
                                                                size='xl'
                                                                onClick={() => setFeedbackRating(index + 1)}
                                                                color={feedbackRating > index ? 'gold' : 'grey'}
                                                                style={{ cursor: 'pointer', marginRight: '5px' }}
                                                            />
                                                        ))}
                                                    </Box>
                                                    <Textarea
                                                        placeholder="Your comment"
                                                        value={feedbackComment}
                                                        onChange={(e) => setFeedbackComment(e.target.value)}
                                                        mb={3}
                                                    />
                                                    <Button colorScheme="blue" onClick={submitFeedback}>
                                                        Submit Feedback
                                                    </Button>
                                                </ModalBody>
                                            </ModalContent>
                                        </Modal>
                                    </Box>
                                </Flex>
                            ) : user.jobSeekerDetails && !sectionStates.jobSeeking ? (
                                <Flex w={"100%"} bg="white" align="center" justify="space-between" p={2} borderWidth="1px" borderRadius="lg">
                                    <Heading size="sm">Recruitment</Heading>
                                    <IconButton
                                        icon={<FontAwesomeIcon icon={faPlus} jobSeeker={user} />}
                                        variant="ghost"
                                        size={"sm"}
                                        onClick={() => handleSectionToggle('jobSeeking', true)}
                                    />
                                </Flex>
                            ) : null}

                            {/* connections */}
                            {sectionStates.connections ? (
                                <Box borderWidth="1px" maxH={"600px"} borderRadius="lg" overflow="scroll" p={2} w="full" bg="white" boxShadow="xl">
                                    <HStack w={"100%"} justify="space-between">
                                        <Heading size="sm">Connections</Heading>
                                        <Flex justify="flex-end">
                                            <IconButton
                                                icon={<FontAwesomeIcon icon={faMinus} />}
                                                variant="ghost"
                                                size={"sm"}
                                                onClick={() => handleSectionToggle('connections', false)}
                                            />
                                        </Flex>
                                    </HStack>
                                    <UserConnectionsRequest />
                                    <UserConnections />
                                    <Box>
                                        <SimpleGrid columns={1} spacing={2}>
                                            {employerConnectionRooms.map((connectionId, index) => {
                                                const roomMessages = existingMessages[connectionId];
                                                const mostRecentMessage = roomMessages?.[roomMessages.length - 1];

                                                if (roomMessages && roomMessages.length > 0) {
                                                    // Calculate the age of the most recent message
                                                    const messageAge = new Date() - new Date(mostRecentMessage.createdAt);
                                                    const messageAgeInMinutes = Math.floor(messageAge / 60000);
                                                    const messageAgeInHours = Math.floor(messageAgeInMinutes / 60);
                                                    const messageAgeInDays = Math.floor(messageAgeInHours / 24);

                                                    let messageAgeText = '';
                                                    if (messageAgeInDays > 0) {
                                                        messageAgeText = `${messageAgeInDays}d`;
                                                    } else if (messageAgeInHours > 0) {
                                                        messageAgeText = `${messageAgeInHours}h`;
                                                    } else {
                                                        messageAgeText = `${messageAgeInMinutes}m`;
                                                    }

                                                    return (
                                                        <Box
                                                            key={index}
                                                            p={4}
                                                            bg="white"
                                                            borderRadius="md"
                                                            boxShadow="md"
                                                            cursor="pointer"
                                                            onClick={() => setSelectedRoom(connectionId)}
                                                            display="flex"
                                                            alignItems="center"
                                                        >
                                                            {mostRecentMessage && (
                                                                <>
                                                                    <Avatar src={mostRecentMessage.senderPFP} size="md" mr={4} />
                                                                    <Box flex={1}>
                                                                        <Text fontWeight="bold" mb={1}>
                                                                            {mostRecentMessage.senderFirstName} {mostRecentMessage.senderLastName}
                                                                        </Text>
                                                                        <Text fontSize="sm" color="gray.500" noOfLines={1}>
                                                                            {mostRecentMessage.text}
                                                                        </Text>
                                                                        {/* Display typing indicator */}
                                                                        {isTyping[connectionId] && (
                                                                            <Text fontSize="sm" color="gray.500" mt={1}>
                                                                                {typingUser[connectionId]} is typing...
                                                                            </Text>
                                                                        )}
                                                                    </Box>
                                                                    {/* Display message age */}
                                                                    <Text fontSize="sm" color="gray.400" ml={2}>
                                                                        {messageAgeText}
                                                                    </Text>
                                                                </>
                                                            )}
                                                        </Box>
                                                    );
                                                }

                                                return null;
                                            })}
                                        </SimpleGrid>
                                    </Box>
                                </Box>
                            ) : (
                                <Flex w={"100%"} bg="white" align="center" justify="space-between" p={2} borderWidth="1px" borderRadius="lg">
                                    <Heading size="sm">Connections</Heading>
                                    <IconButton
                                        icon={<FontAwesomeIcon icon={faPlus} />}
                                        variant="ghost"
                                        size={"sm"}
                                        onClick={() => handleSectionToggle('connections', true)}
                                    />
                                </Flex>
                            )}
                            {/* freelance 
                            {sectionStates.freelance ? (
                                <Box maxW="500px" borderWidth="1px" borderRadius="lg" overflow="hidden" p={2} w="full" bg="white" boxShadow="xl">
                                    {user.isFreelancer ? (
                                        <>
                                            <Flex w="100%" justify="space-between" align="start" mb={4}>
                                                <Heading size="sm">Matching Projects</Heading>
                                                <Flex direction={"column"} justify={"flex-end"}>
                                                    <Button variant="outline" size="sm" colorScheme="green" onClick={openFreelanceModal}>
                                                        Edit Preferences
                                                    </Button>

                                                    <Button mt={2} variant={'outline'} colorScheme='green' onClick={() => navigate('/proposals')} size={"xs"}>View Your Proposals</Button>
                                                </Flex>
                                            </Flex>
                                            {isMatchingProjectsLoading ? (
                                                <Text>Loading matching projects...</Text>
                                            ) : matchingProjects.length > 0 ? (
                                                <>
                                                    <Box mb={4}>
                                                        <Slider dots infinite speed={500} slidesToShow={1} slidesToScroll={1}>
                                                            {matchingProjects.map((project) => (
                                                                <Box mb={1} key={project._id} borderWidth="1px" borderRadius="md" p={4} bg="gray.50" _hover={{ bg: "gray.100" }} mx={2}>
                                                                    <Heading size="md" mb={2}>{project.name}</Heading>
                                                                    <Text fontSize={"md"} mb={2}>{project.description}</Text>
                                                                    <HStack >
                                                                        {hasSubmittedProposal[project._id] ? (
                                                                            <Text color="green.500">Proposal Submitted</Text>
                                                                        ) : (
                                                                            <Flex w={"100%"}>
                                                                                <Button color={"white"} bg={"#01BF02"} _hover={{ bg: "green" }} size="sm" onClick={() => openProposalModal(project)}>
                                                                                    Make Proposal
                                                                                </Button>
                                                                            </Flex>
                                                                        )}
                                                                        <SwipeIndicator />
                                                                    </HStack>
                                                                </Box>
                                                            ))}
                                                        </Slider>
                                                    </Box>
                                                </>
                                            ) : (
                                                <Flex direction="column" align="center" py={8}>
                                                    <Icon as={FaSearch} boxSize={12} color="gray.400" mb={4} />
                                                    <Text fontSize="xl" fontWeight="bold" mb={2}>
                                                        No matching projects found.
                                                    </Text>
                                                    <Text color="gray.500">
                                                        Please update your freelancer preferences to find relevant projects.
                                                    </Text>
                                                    <Button size={"sm"} onClick={fetchMatchingProjects}>
                                                        Refresh
                                                    </Button>
                                                </Flex>
                                            )}
                                        </>
                                    ) : (
                                        <Flex direction="column" align="center" py={8}>
                                            <Icon as={FaBriefcase} boxSize={12} color="gray.400" mb={4} />
                                            <Text fontSize="xl" fontWeight="bold" mb={2}>
                                                Interested in gig work?
                                            </Text>
                                            <Button size="sm" colorScheme="green" onClick={openFreelanceModal}>
                                                Get Started
                                            </Button>
                                        </Flex>
                                    )}
                                    <Flex justify="flex-end">
                                        <IconButton
                                            icon={<FontAwesomeIcon icon={faMinus} />}
                                            variant="ghost"
                                            size={"sm"}
                                            onClick={() => handleSectionToggle('freelance', false)}
                                        />
                                    </Flex>
                                </Box>
                            ) : (
                                <Flex w={"100%"} bg="white" align="center" justify="space-between" p={2} borderWidth="1px" borderRadius="lg">
                                    <Heading size="sm">Freelance</Heading>
                                    <IconButton
                                        icon={<FontAwesomeIcon icon={faPlus} />}
                                        variant="ghost"
                                        size={"sm"}
                                        onClick={() => handleSectionToggle('freelance', true)}
                                    />
                                </Flex>
                            )} 
                            <FreelanceModal isOpen={isFreelanceModalOpen} onClose={closeFreelanceModal} onSubmit={handleFreelanceSubmit} />
                            <Modal isOpen={isProposalModalOpen} onClose={closeProposalModal} size={"xl"}>
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalHeader>Create Proposal</ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        <ProposalForm
                                            project={selectedProject}
                                            freelancer={user}
                                            onClose={closeProposalModal}
                                        />
                                    </ModalBody>
                                </ModalContent>
                            </Modal>
                            */}
                            {/* organizations */}
                            {sectionStates.organizations ? (
                                <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={2} w="full" bg="white" boxShadow="xl">
                                    <Flex mb={3} w={"100%"} align={"flex-start"} justify={"space-between"}>
                                        <Heading size="sm">Organizations</Heading>
                                        <Link to="/create-business">
                                            <Button size="xs" variant={"outline"} colorScheme="green">
                                                Add Your Organization +
                                            </Button>
                                        </Link>
                                    </Flex>
                                    <SimpleGrid maxH={"200px"} overflowY={"scroll"} columns={[1, 3]} spacing={4}>
                                        {associatedBusinesses.map((business) => (
                                            <Flex onClick={() => handleSelectOrganization(business._id)} key={business._id} borderWidth="1px" borderRadius={"md"} justify={"center"} shadow={"sm"} p={2} _hover={{ bg: "gray.50" }}>
                                                <Link to={`/job-listings/${business._id}`}>
                                                    <Flex direction={"column"} align={"center"} borderRadius="lg">
                                                        <Image w={"40px"} src={business.logo} alt={business.businessName} mb={2} />
                                                        <Text fontSize="xl" fontWeight="bold">{business.businessName}</Text>
                                                        <Text mt={1}>{business.category}</Text>
                                                    </Flex>
                                                </Link>
                                            </Flex>
                                        ))}
                                    </SimpleGrid>
                                    <Flex justify="flex-end">
                                        <IconButton
                                            icon={<FontAwesomeIcon icon={faMinus} />}
                                            variant="ghost"
                                            size={"sm"}
                                            onClick={() => handleSectionToggle('organizations', false)}
                                        />
                                    </Flex>
                                </Box>
                            ) : (
                                <Flex w={"100%"} bg="white" align="center" justify="space-between" p={2} borderWidth="1px" borderRadius="lg">
                                    <Heading size="sm">Organizations</Heading>
                                    <IconButton
                                        icon={<FontAwesomeIcon icon={faPlus} />}
                                        variant="ghost"
                                        size={"sm"}
                                        onClick={() => handleSectionToggle('organizations', true)}
                                    />
                                </Flex>
                            )}
                        </VStack>
                    </Box>
                    <Box w={"40%"}>
                        <Box
                            borderWidth="1px"
                            borderRadius="lg"
                            overflow="hidden"
                            p={2}
                            mb={2}
                            w="full"
                            bg="white"
                            boxShadow="xl"
                        >
                            <Flex justify={"space-evenly"}>
                                <Flex
                                    onClick={() => navigate('/UserJobboard')}
                                    cursor="pointer"
                                    p={2}
                                    transition="all 0.3s"
                                    _hover={{ transform: 'translateY(-2px)', bg: '#02bf01', color: 'white', borderRadius: 'md' }}
                                    align="center"
                                >
                                    <Icon
                                        as={FontAwesomeIcon}
                                        icon={faBriefcase}
                                        size="lg"
                                        variant="ghost"
                                        aria-label="View Job Board"
                                        mr={4}
                                    />
                                    <Heading size="md">Browse Jobs</Heading>
                                </Flex>
                                {/*
                                <Tooltip label="View Marketplace" placement="top">
                                    <IconButton
                                        icon={<FontAwesomeIcon size='lg' icon={faStore} />}
                                        size="sm"
                                        variant="ghost"
                                        color="#02bf01"
                                        aria-label="View Marketplace"
                                        transition="all 0.3s"
                                        _hover={{ transform: 'translateY(-5px)', bg: '#02bf01', color: 'white' }}
                                        mr={4}
                                        onClick={() => navigate('/UserJobboard')}
                                    />
                                </Tooltip>
                            */}
                            </Flex>
                        </Box>
                        <Flex maxH={"100vh"} overflowY={"scroll"} align={"start"} maxW={"600px"} w={"100%"} flexDir="column">
                            <UserFeed />
                        </Flex>
                    </Box>

                    {/* <Flex direction={"column"} w={"fit-content"}>
                        <ChatRoom />
                                <DirectMessage/>
                    </Flex>*/}
                </Flex>
            </Flex>
        </Flex>
    );
};

export default UserHome;
