import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box, FormControl, FormLabel, Input, Button, VStack, useToast, Link, Icon, Flex, Text, Image, HStack, Spinner, InputGroup, InputRightElement
} from '@chakra-ui/react';
import { FaEye, FaEyeSlash, FaShieldAlt } from 'react-icons/fa';
import WelcomeMessage from '../../components/WelcomeMessage';
import axios from 'axios';
import Background from '../../assets/Background.webp';
import Logo from '../../assets/whitelogo.png';

function TalentInvite() {
    const toast = useToast();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(null);
    const [isPasswordValid, setIsPasswordValid] = useState(null);

    const validateEmail = (email) => {
        const isValid = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
        setIsEmailValid(isValid);
        return isValid;
    };

    const validatePassword = (password) => {
        const isValid = password.length >= 8;
        setIsPasswordValid(isValid);
        return isValid;
    };

    const handleLogin = async () => {
        if (validateEmail(email) && validatePassword(password)) {
            setIsLoading(true);

            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/login`, { email, password });
                console.log("Login Response:", response.data);
                const { token, refreshToken, role } = response.data; // Destructure the response

                // Debugging: log the values being set
                console.log("Token:", token);
                localStorage.setItem('refresh_token', refreshToken);
                console.log("Role:", role);

                // Save token, role, and id in localStorage
                localStorage.setItem('auth_token', token);
                localStorage.setItem('user_role', role);

                const jobId = localStorage.getItem('jobId');
                const referralId = localStorage.getItem('userId');
                const referralRole = localStorage.getItem('referralRole');

                if (jobId && referralRole !== 'null') {
                    await axios.put(`${process.env.REACT_APP_API_URL}/api/jobSeekers/referrals`, {
                        jobId,
                        referralId,
                        referralRole,
                    }, {
                        headers: { Authorization: `Bearer ${token}` }
                    });

                    navigate(`/reset-password`);
                }

                navigate(`/reset-password`);

                localStorage.removeItem('userId');
                localStorage.removeItem('jobId');
                localStorage.removeItem('role');
                localStorage.removeItem('referralRole');

                setIsLoading(false);
                toast({
                    title: 'Login Successful.',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });

            } catch (error) {
                console.error("Login Error:", error.response); // Log any errors in the response
                setIsLoading(false);
                toast({
                    title: 'Invalid Credentials.',
                    description: "Please check your email and password.",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
        } else {
            toast({
                title: 'Invalid Credentials.',
                description: "Please check your email and password.",
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    return (
        <Flex
            direction="column"
            justify="center"
            align="center"
            height="100vh"
            width="100%"
            backgroundImage={Background}
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
        >
            <HStack spacing={8} alignItems="start">
                <VStack spacing={4}>
                    {/* Logo Placement */}
                    <Box mb={10}>
                        <Image src={Logo} alt="JobJar.AILogo" width="200px" />
                    </Box>
                    <WelcomeMessage />
                </VStack>
                {/* Employer Login Card */}
                <Box
                    p={8}
                    maxW="xl"
                    borderWidth={0}
                    borderRadius="xl"
                    boxShadow="xl"
                    backgroundColor="whiteAlpha.800"
                >
                    <form onSubmit={handleLogin}>
                        <Box textAlign="center" mb={8}>
                            <Text fontSize="2xl" fontWeight="bold">Login</Text>
                        </Box>
                        <VStack spacing={4}>
                            <FormControl>
                                <FormLabel>Email</FormLabel>
                                <Input
                                    type="email"
                                    value={email}
                                    placeholder="Enter email"
                                    bg="whiteAlpha.800"
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        validateEmail(e.target.value); // Validate email in real-time
                                    }}
                                    borderColor={isEmailValid === false ? "red.500" : isEmailValid ? "green.500" : null} // Change border color based on validation state
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Temporary Password</FormLabel>
                                <InputGroup size="lg">
                                    <Input
                                        pr="4.5rem"
                                        type={showPassword ? "text" : "password"}
                                        placeholder="Enter temporary password"
                                        value={password}
                                        bg="whiteAlpha.800"
                                        onChange={(e) => {
                                            setPassword(e.target.value);
                                            validatePassword(e.target.value); // Validate password in real-time
                                        }}
                                        borderColor={isPasswordValid === false ? "red.500" : isPasswordValid ? "green.500" : null} // Change border color based on validation state
                                    />
                                    <InputRightElement width="4.5rem">
                                        <Button h="2rem" size="xs" onClick={() => setShowPassword(!showPassword)}>
                                            <Icon as={showPassword ? FaEyeSlash : FaEye} />
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                            </FormControl>
                            <Button
                                type="submit"
                                bg="#118AB2"
                                color={"white"}
                                _hover={{ bg: "#01BF02" }}
                                height="50px"
                                width="full"
                                mt={2}
                                onClick={handleLogin}
                                isLoading={isLoading}
                                loadingText="Logging in"
                                rightIcon={isLoading ? <Spinner /> : null}
                            >
                                Login
                            </Button>
                            <Box my={4} alignItems="center" display="flex">
                                <Icon as={FaShieldAlt} w={6} h={6} color="#118AB2" mr={2} />
                                <Text fontSize="sm">Secure Connection</Text>
                            </Box>
                        </VStack>
                    </form>
                </Box>
            </HStack>
        </Flex>
    )
}
export default TalentInvite;