import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Badge, HStack } from '@chakra-ui/react';

const JobCard = ({ job, onClick, isSelected }) => {
    const handleBoxClick = (e) => {
        onClick();
        e.stopPropagation();
    };

    if (!job) return <Box>No Job Available</Box>;

    return (
        <Box
            cursor="pointer"
            onClick={handleBoxClick}
            bg={isSelected ? "white" : "whiteAlpha.800"}
            borderWidth="1px"
            borderRadius="lg"
            boxShadow="md"
            p="2" my="2"
            _hover={{ backgroundColor: "white" }}
        >
            {job.badge && (
                <Badge fontSize={"md"} colorScheme={job.badge.colorScheme} variant={job.badge.variant}>
                    {job.badge.name}
                </Badge>
            )}
            <Text fontWeight="bold" fontSize="xl">
                {job.jobTitle}
            </Text>
            <Text color="gray.600" fontSize="md">
                Industry: {job.industry ? job.industry : 'No industry information available.'}
            </Text>
            <HStack justify={"space-evenly"}>
                <Text color="gray.600" fontSize="md">
                    {job.employmentType ? job.employmentType : 'No company information available.'}
                </Text>
                <Text color="gray.600" fontSize="md">
                    {job.workLocationType}
                </Text>
            </HStack>
        </Box>
    );
};

JobCard.propTypes = {
    job: PropTypes.shape({
        jobTitle: PropTypes.string.isRequired,
        industry: PropTypes.string,
        location: PropTypes.string,
        badge: PropTypes.shape({
            name: PropTypes.string,
            colorScheme: PropTypes.string,
            variant: PropTypes.string,
        }),
    }).isRequired,
    onClick: PropTypes.func.isRequired
};

export default JobCard;
