// EvaluationForm.js
import React, { useState, useEffect } from 'react';
import {
    Box, Button, Divider, FormControl, FormLabel, Heading, HStack, useToast,
    VStack, SimpleGrid, Center, Icon
} from '@chakra-ui/react';
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa';

const ScoreButton = ({ number, selectedScore, onClick }) => {
    const isSelected = number === selectedScore;
    let icon = null;

    if (isSelected) {
        if (number <= 2) {
            icon = <Icon as={FaThumbsDown} color="red.100" />;
        } else if (number >= 4) {
            icon = <Icon as={FaThumbsUp} color="green.100" />;
        } // Optionally handle the case for score of 3 if needed
    }

    return (
        <Button
            key={number}
            onClick={() => onClick(number)}
            bg={isSelected ? (number <= 2 ? '#EF476F' : '#06D6A9') : 'whiteSmoke'}
            _hover={{
                bg: isSelected ? (number <= 2 ? '#D6283D' : '#05B297') : '#E2E2E2', // Adjusted colors for hover
                transform: 'scale(1.05)', // Optional: scale up the button a bit on hover
                boxShadow: 'md', // Optional: add a shadow effect on hover
            }}
        >
            {icon || number}
        </Button>
    );
};

const EvaluationItem = ({ item, updateScore, existingScore }) => {
    const [score, setScore] = useState(existingScore || 0);  // existingScore should be a number

    useEffect(() => {
        // If there is an existing score, initialize the component state with it
        if (existingScore) {
            setScore(existingScore); // existingScore is just a number
        }
    }, [existingScore]);

    const handleScore = (newScore) => {
        setScore(newScore); // Update local state
        updateScore(item.criteria, newScore); // Update the parent component state
    };

    return (
        <FormControl w={"100%"} p={3} borderRadius={"lg"} shadow={"lg"} bg={"lightgrey"} id={item.criteria}>
            <FormLabel>{item.criteria}</FormLabel>
            <Center>
                <HStack spacing={1}>
                    {[1, 2, 3, 4, 5].map((number) => (
                        <ScoreButton key={number} number={number} selectedScore={score}
                            shadow={"lg"}
                            colorScheme={score === number ? 'teal' : 'gray'}
                            onClick={() => handleScore(number)}
                            isActive={score === number} // This will highlight the button if the score matches
                        >
                            {number}
                        </ScoreButton>
                    ))}
                </HStack>
            </Center>
        </FormControl>
    );
};

const EvaluationForm = ({ onSubmit, onDelete, items, existingScores, existingEvaluation }) => {
    const [scores, setScores] = useState(
        items.reduce((acc, item) => {
            // Find the existing score for the item
            const existingItem = existingScores.find(es => es.criteria === item.criteria);
            // Initialize the score for this item, default to 0 if not found
            acc[item.criteria] = existingItem ? existingItem.score : 0;
            return acc;
        }, {})
    );

    const toast = useToast();

    const updateScore = (criteria, score) => {
        setScores((prevScores) => ({
            ...prevScores,
            [criteria]: score,
        }));
    };

    const handleSubmit = () => {
        if (onSubmit) {
            // Transform scores object into an array of objects
            const scoresArray = Object.keys(scores).map(criteria => ({
                criteria,
                score: scores[criteria]
            }));

            onSubmit(scoresArray);
            toast({ title: 'Evaluation submitted.', status: 'success', duration: 5000, isClosable: true });
        }
    };

    return (
        <Box bg={"blackAlpha.200"} p={4} shadow="lg" borderWidth="1px" borderRadius="lg">
            <Heading size="md">Applicant Evaluation</Heading>
            <VStack w={"100%"}>
                <Divider mt={4} />
                <SimpleGrid w={"100%"} columns={{ base: 1, md: 2, lg: 3 }} spacing={2}>
                    {items.map((item) => {
                        const existingScore = existingScores.find(scoreItem => scoreItem.criteria === item.criteria)?.score || 0;
                        return (
                            <EvaluationItem
                                key={item.criteria}
                                item={item}
                                updateScore={updateScore}
                                existingScore={existingScore}
                            />
                        );
                    })}
                </SimpleGrid>
                <HStack mt={2} spacing={4}>
                    <Button size={"md"} bg={"#118AB2"} color={"white"} onClick={handleSubmit}>Save Evaluation</Button>
                    {existingEvaluation && (
                        <Button size={"md"} bg={"#EF476F"} color={"white"} onClick={onDelete}>Delete Evaluation</Button>
                    )}
                </HStack>
            </VStack>
        </Box>
    );
};

export default EvaluationForm;