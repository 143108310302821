import React, { useState, useRef } from 'react';
import { Box, Textarea, Button, useToast, Input, InputGroup, InputRightElement, HStack } from '@chakra-ui/react';
import { FaImage } from 'react-icons/fa';
import axiosInstance from './axiosInstance';

const OriginalPostForm = () => {
    const [originalContent, setOriginalContent] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileName, setFileName] = useState(''); // State for file name
    const fileInputRef = useRef(null); // Ref for the file input
    const role = localStorage.getItem('user_role');
    const toast = useToast();

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            setFileName(file.name); // Update the file name state
        }
    };

    const handlePost = async () => {
        const formData = new FormData();
        if (selectedFile) {
            formData.append('image', selectedFile);
        }
        formData.append('originalContent', originalContent);
        formData.append('role', role);

        try {
            await axiosInstance.post('/originalPost', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            toast({ title: 'Post created successfully', status: 'success' });
            // Reset form
            setOriginalContent('');
            setSelectedFile(null);
            setFileName(''); // Reset file name
        } catch (error) {
            toast({ title: 'Error creating post', description: error.message, status: 'error' });
        }
    };

    return (
        <Box p={2} w={"100%"}>
            <Textarea
                placeholder="What's on your mind?"
                bg="white"
                value={originalContent}
                onChange={(e) => setOriginalContent(e.target.value)}
                size="lg"
                resize="none"
                borderRadius="lg"
                boxShadow="base"
                minHeight="70px"
                _hover={{ boxShadow: "md", borderColor: "#118AB2", }}
                _focus={{ outline: "none", boxShadow: "lg", borderColor: "#01BF02" }}
            />
            <HStack w={"100%"}>
                <InputGroup mt={4}>
                    <Input
                        pr="4.5rem"
                        type="text"
                        bg={"gray.200"}
                        placeholder="No file chosen"
                        value={fileName}
                        isReadOnly
                        focusBorderColor="#118AB2"
                        _hover={{
                            borderColor: "#118AB2",
                        }}
                    />
                    <InputRightElement width="30%">
                        <Button
                            size="md"
                            bg={"#118AB2"}
                            color={"white"}
                            onClick={() => fileInputRef.current.click()}
                            _hover={{
                                bg: "#01BF02",
                            }}
                            leftIcon={<FaImage />}
                        >
                            Add Image
                        </Button>
                    </InputRightElement>
                    <Input
                        type="file"
                        hidden
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        accept="image/*"
                    />
                </InputGroup>
                <Button
                    mt={4}
                    bg={"#118AB2"}
                    color={"white"}
                    onClick={handlePost}
                    _hover={{ bg: "#01BF02" }}
                    _active={{ bg: "teal.700" }}
                >
                    Post
                </Button>
            </HStack>
        </Box>
    );
};

export default OriginalPostForm;
