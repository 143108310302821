import React, { useEffect, useState } from 'react';
import { Box, Text, Divider } from '@chakra-ui/react';

const messages = [
    // Your messages go here
    "A stellar match is just around the corner.",
    "The talent you seek is closer than you think.",
    "Discover exceptional minds tailored for your needs.",
    "Unearth the gem your team's been waiting for.",
    "Your team's next linchpin is here and ready.",
    "Top-tier talent is within your reach.",
    "Elevate your team with our curated professionals.",
    "The missing piece to your team's puzzle awaits.",
    "Brace yourself; greatness is on the horizon.",
    "Your team's next game-changer is just a click away.",
    "Step in, your next big discovery is just around the bend.",
    "Your search for excellence ends here.",
    "Tap into a reservoir of brilliant minds, tailored just for you.",
    "The cornerstone your team has been yearning for is within grasp.",
    "The pivotal piece of your team's success story awaits.",
    "Your team's future MVP is closer than you imagine.",
    "Supercharge your team with handpicked talent.",
    "That missing jigsaw piece? It's right here, waiting.",
    "Get ready; a wave of brilliance is about to hit.",
    "Your next strategic move is a mere click away.",
    "Sign in to advance towards your team's bright future.",
    "Dive in, your next hire might just be a click away.",
    "Where excellence meets opportunity, that's where we are.",
    "Journey towards unparalleled talent begins here.",
    "Revolutionize your team, one login at a time.",
    "The bridge to a promising tomorrow starts with a simple login.",
    "Seize the day; your next best hire awaits.",
    "Every login is a step closer to transformational talent.",
    "Make way, as the talent you've envisioned is now within sight.",
    "Sign in to continue to your account.",
    "Please log in to access your dashboard.",
    "Enter your credentials to proceed.",
    "Welcome back! Please sign in.",
    "Your account awaits. Please sign in.",
    "To get started, please log in.",
    "Sign in for a personalized experience.",
    "Log in to manage your preferences.",
    "Your journey continues. Please sign in.",
    "Reconnect with your account. Sign in now.",
    "To explore further, please log in.",
    "Your next step? Just sign in.",
    "Continue where you left off. Log in now.",
    "Access all features by signing in.",
    "For uninterrupted access, please sign in.",
    "Securely sign in to manage your account.",
    "Your portal is just a sign-in away.",
    "Stay connected. Please log in.",
    "Your workspace awaits. Sign in to continue.",
    "Get back to what matters. Log in now.",
    "Ready when you are. Please sign in.",
    "A stellar match is just around the corner.",
    "The talent you seek is closer than you think.",
    "Unlock limitless opportunities with one login.",
    "Where your ambitions and talent unite.",
    "Join us, and let’s create your next big opportunity.",
    "Your dream job or ideal candidate is just a few clicks away.",
    "Why settle? Get matched with the best.",
    "We're not just another job board; we're your career partner.",
    "Level up your career or your team, starting now.",
    "Don't just search. Connect.",
    "Make your next career or hiring move with confidence.",
    "The future of job matching is here. Be a part of it.",
    "Transforming the way you find jobs or talent.",
    "Welcome to your hub of endless opportunities.",
    "Quality over quantity. Get matched, not spammed.",
    "Let us make your job or talent hunt smoother than ever.",
    "We connect you with opportunities that resonate.",
    "Finding a job or talent doesn't have to be hard work.",
    "The smarter way to job hunt, hire, or recruit is here.",
    "Turning your aspirations into reality, one match at a time.",
    "Sign in, sit back, and let the opportunities roll in.",
    "Where job seekers, employers, and recruiters find their happy place.",
    "Why go solo? Let’s tackle your career goals together.",
    "The key to your next opportunity is just a login away.",
    "Focused on fit, committed to your success.",
    "First impressions matter. Make yours count with the right opportunity.",
    "Your skills and the right job. Let’s make the connection.",
    "Navigate your next career or hiring decision with ease.",
    "Let’s redefine how you experience job search or recruitment.",
    "Your career compass for jobs, hires, and more.",
    "Unbox a world of incredible career opportunities.",
    "Where your skillset meets its match."
];


const WelcomeMessage = () => {
    const [welcomeMessage, setWelcomeMessage] = useState('');

    useEffect(() => {
        const randomMessage = messages[Math.floor(Math.random() * messages.length)];
        setWelcomeMessage(randomMessage);
    }, []);

    return (
        <Box
            p={8}
            maxW="xl"
            borderWidth={3}
            borderColor={"white"}
            borderRadius="xl"
            boxShadow="xl"
            backgroundColor="black"
            color="white"
        >
            <Box textAlign="center" mb={4}>
                <Text fontSize="4xl" fontWeight="bold">Welcome!</Text>
            </Box>
            <Divider borderColor="white" borderWidth="3px" my={6} />
            <Box textAlign="center">
                <Text color="white">{welcomeMessage}</Text>
            </Box>
        </Box>
    );
};

export default WelcomeMessage;