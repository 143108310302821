import React from 'react';
import Header from './Header';

const WithSidebarLayout = ({ children }) => (
    <>
        <Header />
        {children}
    </>
);

export default WithSidebarLayout;
