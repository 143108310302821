import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axiosInstance from '../../components/axiosInstance';
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Text,
    VStack,
    Flex,
    Progress,
    useColorModeValue,
    SimpleGrid,
    Stat,
    StatLabel,
    StatNumber,
    Badge, HStack, Center, Divider
} from '@chakra-ui/react';
import { ChevronRightIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import Background from '../../assets/Background.webp';

const EvaluationCard = ({ evaluation }) => {
    const { jobId } = useParams();
    const bg = useColorModeValue('white', 'gray.700');
    const borderColor = useColorModeValue('gray.200', 'gray.600');

    const averageScoresArray = Object.keys(evaluation.averageScores).map(criteria => ({
        criteria,
        average: evaluation.averageScores[criteria],
    }));

    const calculateOverallScore = (evaluations) => {
        const maxScorePerItem = 5;
        const totalMaxScore = evaluations.reduce((total, ev) => total + (ev.items.length * maxScorePerItem), 0);
        const totalActualScore = evaluations.reduce((total, ev) => total + ev.items.reduce((sum, item) => sum + item.score, 0), 0);
        const scorePercentage = (totalActualScore / totalMaxScore) * 100;
        return totalMaxScore > 0 ? scorePercentage.toFixed(1) : 'N/A';
    };

    const overallScore = calculateOverallScore(evaluation.evaluations);

    const getProgressStyle = (value) => {
        const secondaryColor = '#d0d0d0';
        let fillColor;
        if (value <= 50) {
            fillColor = '#EF476F';
        } else if (value <= 75) {
            fillColor = '#FFD166';
        } else {
            fillColor = '#01BF02';
        }

        return {
            trackColor: secondaryColor,
            fillColor: fillColor,
        };
    };

    const get100PercentStyle = (scorePercentage) => {
        const secondaryColor = '#d0d0d0';
        let fillColor;

        if (scorePercentage <= 50) {
            fillColor = '#EF476F';
        } else if (scorePercentage <= 75) {
            fillColor = '#FFD166';
        } else {
            fillColor = '#01BF02';
        }

        return {
            trackColor: secondaryColor,
            fillColor: fillColor,
        };
    };

    const hoverStyle = {
        textDecoration: 'none',
        bg: 'black',
        color: 'white',
    };

    return (
        <Flex direction="column" h="100vh" w="100%">
            {/* Background container */}
            <Box
                position="fixed"
                top={0}
                left={0}
                right={0}
                bottom={0}
                bgImage={Background}
                bgSize="cover"
                bgRepeat="no-repeat"
                bgPosition="center"
                zIndex={-1}
            />
            {/* Content container */}
            <Box m={2} borderWidth="1px" w={"100%"} borderRadius="md" overflow="hidden" p={4} mb={4} shadow="dark-lg" bg={bg} borderColor={borderColor}>
                <Flex align={"start"} mb={4}>
                    <VStack w={"100%"} align="flex-start" justify={"space-between"}>
                        <Text fontWeight="bold" fontSize="xl">{evaluation.applicantInfo.name}</Text>
                        {evaluation.applicantInfo.tabName && (
                            <Link to={{
                                pathname: `/applicants/${jobId}`,
                                state: { jobTitle: evaluation.applicantInfo.jobTitle }
                            }} _hover={hoverStyle}>
                                <HStack>
                                    <Text>Found in the</Text>
                                    <Badge bg={"#118AB2"} color={"white"} border={"1px"} borderColor={"white"} borderRadius="lg" px={2} py={1} cursor="pointer" _hover={hoverStyle}>
                                        {evaluation.applicantInfo.tabName}
                                    </Badge>
                                    <Text>tab.</Text>
                                </HStack>
                            </Link>
                        )}
                    </VStack>
                    <VStack align={"flex-end"} w={"100%"}>
                        <Link to={`/user/${evaluation.applicantInfo.jobSeekerId}`} _hover={hoverStyle} style={{ textDecoration: 'none' }}>
                            <Badge bg={"#118AB2"} color={"white"} borderRadius="full" px={2} py={1} _hover={hoverStyle}>
                                <Center>
                                    View Profile
                                    <ChevronRightIcon boxSize={6} />
                                </Center>
                            </Badge>
                        </Link>
                    </VStack>
                </Flex>
                <Flex w={"100%"}>
                    <Stat>
                        <StatLabel>Team Evaluation</StatLabel>
                        <StatNumber fontSize="2xl">{overallScore}%</StatNumber>
                        <Progress
                            value={overallScore}
                            size="lg"
                            sx={{
                                '> div': {
                                    backgroundColor: get100PercentStyle(overallScore).fillColor,
                                },
                            }}
                            borderRadius="md"
                            backgroundColor={get100PercentStyle(overallScore).trackColor}
                        />

                    </Stat>
                </Flex>
                <HStack mt={2} p={2} borderRadius={"md"} w={"100%"} justify={"space-between"}>
                    <Flex justify={"flex-end"} w={"45%"}>
                        {evaluation.applicantInfo.matchScore && (
                            <Stat borderRadius="md">
                                <StatLabel>Ai Match Score</StatLabel>
                                <StatNumber>{evaluation.applicantInfo.matchScore}%</StatNumber>
                                <Progress
                                    value={overallScore}
                                    size="lg"
                                    sx={{
                                        '> div': {
                                            backgroundColor: get100PercentStyle(overallScore).fillColor,
                                        },
                                    }}
                                    borderRadius="md"
                                    backgroundColor={get100PercentStyle(overallScore).trackColor}
                                />

                            </Stat>
                        )}
                    </Flex>
                    <Flex justify="center" w="45%">
                        {'skillAssessmentScore' in evaluation.applicantInfo && typeof evaluation.applicantInfo.skillAssessmentScore === 'number' ? (
                            <Stat borderRadius="md">
                                <StatLabel>Skill Assessment</StatLabel>
                                <StatNumber>{evaluation.applicantInfo.skillAssessmentScore}%</StatNumber>
                                <Progress
                                    value={overallScore}
                                    size="lg"
                                    sx={{
                                        '> div': {
                                            backgroundColor: get100PercentStyle(overallScore).fillColor,
                                        },
                                    }}
                                    borderRadius="md"
                                    backgroundColor={get100PercentStyle(overallScore).trackColor}
                                />

                            </Stat>
                        ) : (
                            <Text>No Skill Assessment</Text>
                        )}
                    </Flex>
                </HStack>
                <Divider mt={4} mb={4} />
                <VStack maxH={"400px"} overflowY={"scroll"} bg={"whiteSmoke"} borderRadius={"md"} p={4} align="stretch" mb={4} spacing={4}>
                    <SimpleGrid columns={2} spacing={6}>
                        {averageScoresArray.map((score, index) => (
                            <Stat shadow={"lg"} key={index} p={2} bg={bg} borderRadius="md">
                                <StatLabel fontSize="lg" fontWeight="medium">{score.criteria}</StatLabel>
                                <StatNumber fontSize="2xl">
                                    <Progress
                                        value={parseFloat(score.average) * 20}
                                        size="lg"
                                        sx={{
                                            '> div': {
                                                backgroundColor: getProgressStyle(parseFloat(score.average) * 20).fillColor,
                                            },
                                        }}
                                        borderRadius="md"
                                        backgroundColor={getProgressStyle(parseFloat(score.average) * 20).trackColor}
                                    />

                                </StatNumber>
                            </Stat>
                        ))}
                    </SimpleGrid>
                </VStack>
                <Accordion allowMultiple defaultIndex={[]} borderColor={borderColor}>
                    {evaluation.evaluations.map((ev, index) => (
                        <AccordionItem key={index} borderColor={borderColor}>
                            <AccordionButton _expanded={{ bg: 'gray.100', fontWeight: 'semibold' }}>
                                <Box flex="1" textAlign="left">
                                    {ev.employerName || 'N/A'}
                                </Box>
                                <AccordionIcon as={ev.isOpen ? ChevronUpIcon : ChevronDownIcon} />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <SimpleGrid columns={2} spacing={2}>
                                    {ev.items.map((item, itemIndex) => (
                                        <Stat _hover={{ bg: "gray.100" }} key={itemIndex} p={2} bg={bg} borderRadius="md" width="100%">
                                            <HStack justifyContent="space-between">
                                                <StatLabel fontSize="lg">{item.criteria}:</StatLabel>
                                                <StatNumber fontSize="2xl">{item.score}</StatNumber>
                                            </HStack>
                                            <Divider />
                                        </Stat>
                                    ))}
                                </SimpleGrid>
                            </AccordionPanel>
                        </AccordionItem>
                    ))}
                </Accordion>
            </Box>
        </Flex>
    );
};

const EvaluationsList = () => {
    const { jobId } = useParams();
    const [evaluationsData, setEvaluationsData] = useState([]);

    useEffect(() => {
        const fetchEvaluations = async () => {
            try {
                const response = await axiosInstance.get(`/jobs/${jobId}/evaluations`);
                setEvaluationsData(response.data);
            } catch (error) {
                console.error('Error fetching evaluations:', error);
            }
        };

        fetchEvaluations();
    }, [jobId]);

    if (evaluationsData.length === 0) {
        // If there are no evaluations, return a message or a component that indicates that there are no evaluations
        return (
            <Center mt="20" p="4">
                <Text fontSize="xl">No evaluations found.</Text>
            </Center>
        );
    }

    // If there are evaluations, render them as before
    return (
        <SimpleGrid mt={4} p={4} pl={[0, null, "320px"]} columns={{ sm: 1, md: 2, lg: 3 }} spacing={4}>
            {evaluationsData.map((evalData, index) => (
                <EvaluationCard key={index} evaluation={evalData} />
            ))}
        </SimpleGrid>
    );
};

export default EvaluationsList;