import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import CreatableSelect from 'react-select/creatable';
import 'react-quill/dist/quill.snow.css';
import {
    Box, Button, Flex, Heading, VStack, HStack, FormControl, FormLabel, IconButton,
    Input, Select, Radio, Checkbox, Stack, RadioGroup, Text, Switch, Wrap,
    Tag, InputGroup, InputRightElement, TagCloseButton, InputRightAddon,
    Textarea, InputLeftElement, Badge, WrapItem, Divider, useToast, Icon, SimpleGrid, Spacer
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Background from '../../../assets/Background.webp';
import { debounce } from 'lodash';
import { CloseIcon } from '@chakra-ui/icons'
import { FaFileUpload } from 'react-icons/fa';
import { faBarsProgress, faBolt, faCircleNodes, faComment, faComments, faEye, faListCheck, faPiggyBank, faSearch, faStar, faUnlockKeyhole, faUsersLine } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const capitalizeWords = (string) => {
    if (typeof string !== 'string') {
        console.error('capitalizeWords called with non-string:', string);
        return ''; // or some default string value
    }
    return string.replace(/\b\w/g, char => char.toUpperCase());
};

const JobCreation = () => {
    const [jobData, setJobData] = useState({
        userId: '',
        companyId: '',
        jobTitle: '',
        industry: '',
        isPaidPerJobOption: false,
        workLocationType: '',
        locationCity: '',
        locationState: '',
        locationCountry: 'USA',
        employmentType: '',
        paymentType: 'undefined',
        paymentDetails: {
            qualifier: 'Starting at',
            amount: ''
        },
        paymentFrequency: 'bi-weekly',
        additionalCompensation: {
            commission: false,
            bonuses: false,
            other: false,
        },
        additionalPerks: '',
        skills: [{ name: '', level: 'no_experience' }],
        description: '',
        revealPrice: '',
        bountyToRecruiters: false,
        bounty: 0,
        badge: {
            id: '',
            name: '',
            colorScheme: '',
            variant: ''
        },
        subTotal: 0,
        customCriteria: [{ criteria: '', description: '' }],
        videoURL: '',
        image: '',
        streetAddress: '',
        postalCode: 0,
        addressCountry: '',
        tags: [],
        responsibilities: [],
        qualification: [],
        active: true,
        views: 0,
    });

    const [paymentType, setPaymentType] = useState(null);
    const [paymentFrequency, setPaymentFrequency] = useState('');
    const [showLocationInput, setShowLocationInput] = useState(false);
    const [jobTitleOptions, setJobTitleOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentTag, setCurrentTag] = useState('');
    const [currentResponsibility, setCurrentResponsibility] = useState('');
    const [currentQualification, setCurrentQualification] = useState('');
    const [skillSuggestions, setSkillSuggestions] = useState([]);
    const businessId = localStorage.getItem('businessId');
    const toast = useToast();

    const searchSkill = async (skill) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/search-skills?q=${skill}`);
            setSkillSuggestions(response.data); // Update the skill suggestions
        } catch (error) {
            console.error('Error searching for skills:', error);
        }
    };

    const handleJobTitleSearch = useCallback(debounce((inputValue) => {
        if (!inputValue) return;
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}/api/jobtitles/search?query=${inputValue}`)
            .then(response => {
                const options = response.data.map(job => ({ label: job.title, value: job.title }));
                setJobTitleOptions(options);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching job titles:', error);
                setIsLoading(false);
            });
    }, 500), []);

    const handleJobTitleChange = (newValue, actionMeta) => {
        if (!newValue) return;
        if (actionMeta.action === 'create-option') {
            axios.post(process.env.REACT_APP_API_URL + '/api/jobtitles', { title: newValue.value })
                .then(response => {
                    setJobTitleOptions(prevOptions => [...prevOptions, { label: response.data.title, value: response.data.title }]);
                })
                .catch(error => {
                    console.error('Error creating new job title:', error);
                });
        }
        setJobData(prevJobData => ({ ...prevJobData, jobTitle: newValue.value }));
    };

    const handleSkillChange = (e, index, field) => {
        const newSkills = [...jobData.skills];
        newSkills[index][field] = field === 'name' ? capitalizeWords(e.target.value) : e.target.value;
        setJobData({ ...jobData, skills: newSkills });

        // Search for skill suggestions when the name field is updated
        if (field === 'name') {
            searchSkill(e.target.value);
        }
    };

    const addSkill = () => {
        setJobData({
            ...jobData,
            skills: [...jobData.skills, { name: '', level: '' }],
        });
    };

    const handleCriteriaChange = (e, index, field) => {
        const value = e.target.value;
        setJobData(prevJobData => {
            const newCustomCriteria = [...prevJobData.customCriteria];
            newCustomCriteria[index][field] = value;
            return { ...prevJobData, customCriteria: newCustomCriteria };
        });
    };

    const handleAddCriteria = () => {
        setJobData(prevData => ({
            ...prevData,
            customCriteria: [...prevData.customCriteria, { criteria: '', description: '' }]
        }));
    };

    const handleRemoveCriteria = (index) => {
        setJobData(prevData => ({
            ...prevData,
            customCriteria: prevData.customCriteria.filter((_, i) => i !== index)
        }));
    };

    const calculateRevealPrice = () => {
        return "10";
    };

    useEffect(() => {
        const newRevealPrice = calculateRevealPrice(jobData.paymentDetails?.amount, paymentType);
        setJobData(prevJobData => ({
            ...prevJobData,
            revealPrice: newRevealPrice,
        }));
    }, [jobData.paymentDetails?.amount, paymentType]);

    const removeSkill = (index) => {
        setJobData(prevData => {
            const updatedSkills = prevData.skills.filter((_, idx) => idx !== index);
            return {
                ...prevData,
                skills: updatedSkills
            };
        });
    };

    const addQualification = () => {
        if (currentQualification) {
            setJobData(prevState => ({
                ...prevState,
                qualification: [...(prevState.qualification || []), currentQualification]
            }));
            setCurrentQualification('');
        }
    };

    const removeQualification = (index) => {
        const newQualification = [...jobData.qualification];
        newQualification.splice(index, 1);
        setJobData({
            ...jobData,
            qualification: newQualification
        });
    };

    // Allow any input from the user, including an empty string or any number
    const handleBountyChange = (e) => {
        const newValue = e.target.value;
        setJobData(prevState => ({ ...prevState, bounty: newValue }));
    };

    // No specific action on blur, just keep the entered value as is
    const handleBountyBlur = () => {
        // This function can remain empty if no special handling is required on blur
        // If you decide to add any logic later, you can do it here
    };

    // Updated handleSwitchChange with bounty reset when toggled off
    const handleSwitchChange = (field) => {
        setJobData(prevState => ({
            ...prevState,
            [field]: !prevState[field]
        }));
    };

    const handleSwitchChangeJobOption = (field) => {
        setJobData(prevState => ({
            ...prevState,
            [field]: !prevState[field]
        }));
    };


    const handleQualifierChange = (event) => {
        setJobData(prevState => ({
            ...prevState,
            paymentDetails: {
                ...prevState.paymentDetails,
                qualifier: event.target.value
            }
        }));
    };

    const handleAmountChange = (event) => {
        setJobData(prevState => ({
            ...prevState,
            paymentDetails: {
                ...prevState.paymentDetails,
                amount: event.target.value
            }
        }));
    };

    // Function to handle Checkbox changes
    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setJobData(prevJobData => ({
            ...prevJobData,
            additionalCompensation: {
                ...prevJobData.additionalCompensation,
                [name]: checked,
            },
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setJobData({ ...jobData, [name]: value });
    };

    // Add Tag
    const addTag = () => {
        if (currentTag) {
            setJobData({
                ...jobData,
                tags: [...jobData.tags, currentTag],
            });
            setCurrentTag(''); // Resetting the currentTag
        }
    };

    // Remove Tag
    const removeTag = (index) => {
        const newTags = [...jobData.tags];
        newTags.splice(index, 1);
        setJobData({
            ...jobData,
            tags: newTags,
        });
    };

    // Add Responsibility
    const addResponsibility = () => {
        if (currentResponsibility) {
            setJobData({
                ...jobData,
                responsibilities: [...jobData.responsibilities, currentResponsibility],
            });
            setCurrentResponsibility(''); // Resetting the currentResponsibility
        }
    };

    // Remove Responsibility
    const removeResponsibility = (index) => {
        const newResponsibilities = [...jobData.responsibilities];
        newResponsibilities.splice(index, 1);
        setJobData({
            ...jobData,
            responsibilities: newResponsibilities,
        });
    };

    useEffect(() => {
        let subTotal;
        if (jobData.isPaidPerJobOption) {
            subTotal = "450";
        } else {
            const revealPrice = parseFloat(jobData.revealPrice) || 0;
            const bounty = parseFloat(jobData.bounty) || 0;
            subTotal = (revealPrice + bounty).toFixed(2);
        }

        setJobData(prevData => ({ ...prevData, subTotal }));
    }, [jobData.revealPrice, jobData.bounty, jobData.isPaidPerJobOption]); // Add jobData.isPaidPerJobOption to the dependency array    

    const handleworkLocationTypeChange = (e) => {
        handleInputChange(e);
        setShowLocationInput(e.target.value !== 'Remote');
    };

    const handleJobDescriptionChange = (event) => {
        const value = event.target.value;
        setJobData(prevData => ({
            ...prevData,
            description: value
        }));
    };

    const [industries, setIndustries] = useState([]);
    const [selectedIndustry, setSelectedIndustry] = useState(null);

    // Fetch industries on component mount
    useEffect(() => {
        const fetchIndustries = async () => {
            try {
                const res = await axios.get(process.env.REACT_APP_API_URL + '/api/industries/search');
                setIndustries(res.data);
            } catch (error) {
                console.error('An error occurred while fetching data:', error);
            }
        };

        fetchIndustries();
    }, []);

    const handleIndustryChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedIndustry(selectedOption.label);  // Use label here instead of value
            setJobData({ ...jobData, industry: selectedOption.label });  // Update jobData
            if (selectedOption.__isNew__) {
                saveNewIndustry(selectedOption.label);  // Use label here instead of value
            }
        } else {
            setSelectedIndustry(null); // Set to null instead of empty string for better consistency
            setJobData({ ...jobData, industry: null });  // Update jobData
        }
    };

    const validateSkills = () => {
        const validLevels = ['no_experience', 'learning', 'competent', 'proficient', 'authority'];

        for (const skill of jobData.skills) {
            if (!validLevels.includes(skill.level)) {
                return false;  // Invalid level value found
            }
        }
        return true;  // All skill level values are valid
    }

    const saveNewIndustry = async (newIndustry) => {
        const authToken = localStorage.getItem('auth_token');
        await axios.post(
            process.env.REACT_APP_API_URL + '/api/industries',
            { industry: newIndustry },
            {
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            }
        );
        setIndustries([...industries, { label: newIndustry, value: newIndustry }]);
    };

    const [step, setStep] = useState(0);

    const goToNextStep = () => {
        setStep((prevStep) => prevStep + 1);
    };

    const goToPrevStep = () => {
        setStep((prevStep) => prevStep - 1);
    };

    const handleChatAPICall = async () => {
        // Indicate loading
        setIsLoading(true);

        const jobInfo = {
            jobTitle: jobData.jobTitle,
            skills: jobData.skills,
            industry: jobData.industry,
            workLocationType: jobData.workLocationType,
            locationCity: jobData.locationCity,
            locationState: jobData.locationState,
            employmentType: jobData.employmentType,
            responsibilities: jobData.responsibilities,
            qualification: jobData.qualification,
            customCriteria: jobData.customCriteria,
        };

        try {
            const authToken = localStorage.getItem('auth_token');
            const response = await axios.post(process.env.REACT_APP_API_URL + '/api/generate-description', jobInfo, {
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });
            const generatedDescription = response.data.generatedDescription;

            setJobData(prevData => ({
                ...prevData,
                description: generatedDescription
            }));
        } catch (error) {
            console.error('Error generating job description:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSkillAPICall = async () => {
        // Indicate loading
        setIsLoading(true);

        const jobInfo = {
            jobTitle: jobData.jobTitle,
            industry: jobData.industry,
        };

        try {
            const authToken = localStorage.getItem('auth_token');
            const response = await axios.post(process.env.REACT_APP_API_URL + '/api/generate-job-details', jobInfo, {
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });
            const { skills, responsibilities, tags, qualifications } = response.data;

            setJobData(prevData => ({
                ...prevData,
                skills: skills || prevData.skills, // Fallback to previous data if undefined
                responsibilities: responsibilities || prevData.responsibilities,
                tags: tags || prevData.tags,
                qualification: qualifications || prevData.qualification
            }));
        } catch (error) {
            console.error('Error generating job description:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const chargeCustomer = async () => {
        if (!jobData.isPaidPerJobOption) {
            console.error("Payment should only be processed for the Pay per Job option.");
            return;
        }

        const authToken = localStorage.getItem('auth_token'); // Retrieve your auth token
        const amount = jobData.subTotal * 100; // Convert dollars to cents for Stripe

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/PPJ/${businessId}/charge-customer`,
                { amount: amount },
                {
                    headers: {
                        'Authorization': `Bearer ${authToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            // Return the response so the calling function can check the success status
            return response;
        } catch (error) {
            console.error("Error in charging customer: ", error);
            toast({
                title: 'Payment Failed',
                description: `An error occurred: ${error.message}`,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && file.size > 12 * 1024 * 1024) { // 12 MB limit
            alert("File size should not exceed 12 MB");
            return;
        }
        setJobData({ ...jobData, image: file });
    };

    const canGoNext = () => step < 6;
    const canGoBack = () => step > 0;
    const navigate = useNavigate();
    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            console.log("Job details submitted!");

            const authToken = localStorage.getItem('auth_token');

            if (!validateSkills()) {
                console.error('Invalid skill level value');
                return;
            }

            if (jobData.isPaidPerJobOption) {
                const chargeResponse = await chargeCustomer();
                if (!chargeResponse || chargeResponse.status !== 200 || !chargeResponse.data.success) {
                    // If the charge was unsuccessful, show an error and do not proceed.
                    setIsLoading(false);
                    toast({
                        title: 'Payment Failed',
                        description: 'Unable to process payment.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                    return; // Exit the function early
                }
            }

            const validCustomCriteria = jobData.customCriteria.filter(
                criteriaObj => criteriaObj.criteria.trim() !== ''
            );

            const updatedJobData = {
                ...jobData,
                customCriteria: validCustomCriteria,
                bounty: jobData.bounty,
                isPaidPerJobOption: jobData.isPaidPerJobOption,
            };

            const formData = new FormData();
            Object.keys(updatedJobData).forEach(key => {
                if (key === 'tags') {
                    // Append each tag individually
                    updatedJobData[key].forEach(tag => {
                        formData.append('tags', tag);
                    });
                } else if (typeof updatedJobData[key] === 'object') {
                    formData.append(key, JSON.stringify(updatedJobData[key]));
                } else {
                    formData.append(key, updatedJobData[key]);
                }
            });

            console.log("FormData contents:", Array.from(formData.entries()));

            // Append image file if it exists
            if (jobData.image) {
                formData.append('image', jobData.image);
            }

            const response = await axios.post(process.env.REACT_APP_API_URL + `/api/${businessId}/jobs`, formData, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                }
            });

            if (response.status === 201) {
                console.log("Job posted successfully:", response.data);
                toast({
                    title: 'Job Created',
                    description: 'Your job listing has been successfully created.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });

               const jobId = response.data.jobId;
               // await axios.put(process.env.REACT_APP_API_URL + `/api/jobs/update-fees/${jobId}`, {
               //     subTotal: jobData.subTotal
              //  }, {
              //      headers: {
             //           'Authorization': `Bearer ${authToken}`
             //       }
             //   });

             //   console.log('Referral request body:', { jobId });
                const shareResponse = await axios.post(process.env.REACT_APP_API_URL + '/api/share-job', { jobId }, {
                    headers: {
                        'Authorization': `Bearer ${authToken}`
                    }
                });

                if (shareResponse.status === 201) {
                    console.log("Referral tracked successfully:", shareResponse.data);

                } else {
                    console.error('Error generating shareable link:', shareResponse.data.message);
                }

                setJobData({
                    userId: '',
                    companyId: '',
                    jobTitle: '',
                    industry: '',
                    isPaidPerJobOption: false,
                    workLocationType: '',
                    locationCity: '',
                    locationState: '',
                    locationCountry: '',
                    employmentType: '',
                    paymentType: 'undefined',
                    paymentDetails: {
                        qualifier: 'Starting at',
                        amount: ''
                    },
                    paymentFrequency: 'bi-weekly',
                    additionalCompensation: {
                        commission: false,
                        bonuses: false,
                        other: false,
                    },
                    additionalPerks: '',
                    skills: [{ name: '', level: 'no_experience' }],
                    description: '',
                    revealPrice: '',
                    bountyToRecruiters: false,
                    bounty: 0,
                    badge: {
                        id: '',
                        name: '',
                        colorScheme: '',
                        variant: ''
                    },
                    subTotal: 0,
                    customCriteria: [{ criteria: '', description: '' }],
                    videoURL: '',
                    image: '',
                    streetAddress: '',
                    postalCode: 0,
                    addressCountry: '',
                    tags: [],
                    responsibilities: [],
                    qualification: [],
                    active: true,
                    views: 0,
                });

                navigate(`/job-listings/${businessId}`);

            } else {
                console.error('Error creating job:', response.data.message);
                toast({
                    title: 'Job Creation Failed',
                    description: `Server error: ${response.data.message}`,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }

        } catch (error) {
            console.log("An error occurred while submitting job details:", error);
            toast({
                title: 'Job Creation Failed',
                description: `An error occurred: ${error.message}`,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
        setIsLoading(false);
    };

    const badges = [
        { id: '1', name: 'Urgent Hire', colorScheme: 'red', variant: 'solid' },
        { id: '2', name: 'Great Benefits', colorScheme: 'green', variant: 'outline' },
        { id: '3', name: 'High Demand', colorScheme: 'blue', variant: 'solid' },
        { id: '4', name: 'Top Company', colorScheme: 'yellow', variant: 'subtle' },
        { id: '5', name: 'Remote', colorScheme: 'purple', variant: 'solid' },
        { id: '6', name: 'Career Growth', colorScheme: 'orange', variant: 'outline' },
        { id: '7', name: 'Flexible Hours', colorScheme: 'pink', variant: 'outline' },
        { id: '8', name: 'Seasonal', colorScheme: 'teal', variant: 'solid' },
        { id: '9', name: 'Entry Level', colorScheme: 'cyan', variant: 'outline' },
        { id: '10', name: 'Innovative Culture', colorScheme: 'linkedin', variant: 'solid' },
        { id: '11', name: 'Eco-Friendly', colorScheme: 'facebook', variant: 'solid' },
        { id: '12', name: 'Looking for Greatness', colorScheme: 'messenger', variant: 'outline' },
        { id: '13', name: 'Startup', colorScheme: 'whatsapp', variant: 'solid' },
        { id: '14', name: 'Global Opportunities', colorScheme: 'twitter', variant: 'solid' },
        { id: '15', name: 'Internship Available', colorScheme: 'telegram', variant: 'outline' },
        // New badges added
        { id: '16', name: 'Health Insurance', colorScheme: 'orange', variant: 'solid' },
        { id: '17', name: 'Paid Time Off', colorScheme: 'blue', variant: 'outline' },
        { id: '18', name: 'Work-Life Balance', colorScheme: 'green', variant: 'solid' },
        { id: '19', name: 'Onsite Gym', colorScheme: 'gray', variant: 'outline' },
        { id: '20', name: 'Family Friendly', colorScheme: 'purple', variant: 'subtle' },
        // ...add as many badges as you need
    ];

    const BadgeOption = ({ badge, isSelected, onSelectBadge }) => {
        // Apply shadow if selected, otherwise none
        const shadow = isSelected ? 'dark-lg' : 'md';

        // Set the badge variant and color based on selection
        const variant = isSelected ? badge.variant : 'ghost';
        const colorScheme = isSelected ? badge.colorScheme : 'grey';

        return (
            <Badge
                as="button"
                colorScheme={colorScheme}
                variant={variant}
                p={2}
                m={1}
                onClick={() => onSelectBadge(badge.id)}
                cursor="pointer"
                boxShadow={shadow}
            >
                {badge.name}
            </Badge>
        );
    };

    const handleBadgeSelect = (badgeId) => {
        // Find the selected badge from the badges array
        const selectedBadge = badges.find(badge => badge.id === badgeId);
        // Update the local state and prepare to update the job data
        setJobData((prevJobData) => ({
            ...prevJobData,
            badge: selectedBadge, // Assuming you want to save the entire badge object
            selectedBadgeId: badgeId,
        }));
        // Here you would also handle updating the backend with the new badge info
    };

    return (
        <Flex direction="column" minH="100vh" w="100%" overflowY={"scroll"} bgImage={Background} bgSize="cover" bgRepeat="no-repeat" bgPosition="center">
            <Flex flex="1" w="100%" pl="320px">
                <Box flex="1" ml={4}>
                    <Flex height="100%" alignItems="start" mt={8} justifyContent="center">
                        <Box
                            p={8}
                            w={"70%"}
                            borderWidth={0}
                            borderRadius="xl"
                            boxShadow="xl"
                            backgroundColor="whiteAlpha.800"
                        >
                            <Heading mb={8}>Create a New Job</Heading>
                            <VStack spacing={4}>
                                {step === 0 && (
                                    <VStack w={"100%"}>
                                        <HStack w={"100%"}>
                                            <FormControl isRequired>
                                                <FormLabel>Job Title</FormLabel>
                                                <CreatableSelect
                                                    isClearable
                                                    shadow={"md"}
                                                    isMulti={false}
                                                    isLoading={isLoading}
                                                    onInputChange={handleJobTitleSearch}
                                                    onChange={handleJobTitleChange}
                                                    options={jobTitleOptions}
                                                    value={jobTitleOptions.find(option => option.value === jobData.jobTitle)}
                                                    placeholder="Search job titles"
                                                />
                                            </FormControl>
                                            <FormControl id="industry" isRequired>
                                                <FormLabel>Industry</FormLabel>
                                                <CreatableSelect
                                                    isClearable
                                                    shadow={"md"}
                                                    placeholder="Select or add industry"
                                                    onChange={handleIndustryChange}
                                                    options={industries.map((industry, index) => ({ label: industry.industry, value: industry.industry }))}
                                                    value={selectedIndustry ? { label: selectedIndustry, value: selectedIndustry } : null}
                                                    formatCreateLabel={(inputValue) => `+ Add "${inputValue}"`}
                                                />
                                            </FormControl>

                                            <FormControl isRequired>
                                                <FormLabel>Work Location</FormLabel>
                                                <Select shadow={"md"} bg={"white"} name="workLocationType" onChange={handleworkLocationTypeChange}>
                                                    <option value="">Select location type</option>
                                                    <option value="Remote">Remote</option>
                                                    <option value="Hybrid">Hybrid</option>
                                                    <option value="On-site">On-site</option>
                                                </Select>
                                            </FormControl>
                                        </HStack>
                                        <VStack w={"100%"} >
                                            <HStack w={"100%"}>
                                                <Flex w={"100%"} >
                                                    <FormControl isRequired>
                                                        <FormLabel>Street Address</FormLabel>
                                                        <Input
                                                            bg={"white"}
                                                            type="text"
                                                            w={"100%"}
                                                            shadow={"md"}
                                                            name="streetAddress"
                                                            placeholder="Enter street address"
                                                            value={jobData.streetAddress || ''}
                                                            onChange={handleInputChange}
                                                        />
                                                    </FormControl>
                                                </Flex>

                                                <Flex w={"100%"} >
                                                    <FormControl isRequired>
                                                        <FormLabel>City</FormLabel>
                                                        <Input
                                                            bg={"white"}
                                                            type="text"
                                                            shadow={"md"}
                                                            name="locationCity"
                                                            placeholder="Enter city"
                                                            value={jobData.locationCity || ''}
                                                            onChange={handleInputChange}
                                                        />
                                                    </FormControl>
                                                </Flex>
                                            </HStack>
                                            <HStack w={"100%"}>
                                                <Flex w={"100%"} >
                                                    <FormControl isRequired>
                                                        <FormLabel>State</FormLabel>
                                                        <Input
                                                            bg={"white"}
                                                            type="text"
                                                            shadow={"md"}
                                                            name="locationState"
                                                            placeholder="Enter state"
                                                            value={jobData.locationState || ''}
                                                            onChange={handleInputChange}
                                                        />
                                                    </FormControl>
                                                </Flex>

                                                <Flex w={"100%"}>
                                                    <FormControl isRequired>
                                                        <FormLabel>Country</FormLabel>
                                                        <Input
                                                            bg={"white"}
                                                            type="text"
                                                            shadow={"md"}
                                                            name="locationCountry"
                                                            placeholder="Enter country"
                                                            value={jobData.locationCountry || ''}
                                                            onChange={handleInputChange}
                                                        />
                                                    </FormControl>
                                                </Flex>
                                                <Flex w={"100%"}>
                                                    <FormControl isRequired>
                                                        <FormLabel>Postal Code</FormLabel>
                                                        <Input
                                                            bg={"white"}
                                                            type="text"
                                                            shadow={"md"}
                                                            name="postalCode"
                                                            placeholder="Enter postal code"
                                                            value={jobData.postalCode || ''}
                                                            onChange={handleInputChange}
                                                        />
                                                    </FormControl>
                                                </Flex>
                                            </HStack>
                                        </VStack>

                                        <FormControl isRequired>
                                            <FormLabel>Employment Type</FormLabel>
                                            <Select shadow={"md"} bg={"white"} name="employmentType" value={jobData.employmentType} onChange={handleInputChange}>
                                                <option value="" disabled>Select job type</option>
                                                <option value="Full-time">Full Time</option>
                                                <option value="Part-time">Part Time</option>
                                                <option value="Contract">Contract</option>
                                            </Select>
                                        </FormControl>
                                    </VStack>
                                )}

                                {step === 1 && (
                                    <VStack maxH={"800px"} overflow={"scroll"} w={"100%"} spacing={5}>
                                        <HStack align={"flex-start"} w={"100%"}>
                                            <FormControl isRequired id="skills">
                                                <FormLabel fontWeight={"bold"}>Skills</FormLabel>
                                                {jobData?.skills.map((skill, index) => (
                                                    <Flex key={index} w={"100%"} mb={2} align="center" justify={"flex-start"}>
                                                        <Input
                                                            flex="1"
                                                            bg={"white"}
                                                            shadow={"md"}
                                                            type="text"
                                                            placeholder="Enter a skill"
                                                            value={skill.name}
                                                            onChange={(e) => handleSkillChange(e, index, 'name')}
                                                            list="skill-suggestions"
                                                        />
                                                        <datalist id="skill-suggestions">
                                                            {skillSuggestions.map((suggestion, i) => (
                                                                <option key={i} value={suggestion.name} />
                                                            ))}
                                                        </datalist>
                                                        <Select
                                                            w={"40%"}
                                                            bg={"gray.50"}
                                                            shadow={"md"}
                                                            ml={1}
                                                            value={skill.level}
                                                            onChange={(e) => handleSkillChange(e, index, 'level')}
                                                            isInvalid={!skill.level}
                                                        >
                                                            <option value="">Select proficiency</option>
                                                            <option value="no_experience">No Experience</option>
                                                            <option value="learning">Learning</option>
                                                            <option value="competent">Competent</option>
                                                            <option value="proficient">Proficient</option>
                                                            <option value="authority">Authority</option>
                                                        </Select>
                                                        {jobData.skills.length > 1 && (
                                                            <IconButton
                                                                aria-label={`Remove skill ${index + 1}`}
                                                                icon={<CloseIcon />}
                                                                bg={"#EF476F"}
                                                                color={"white"}
                                                                _hover={{ bg: "red.600" }}
                                                                ml={1}
                                                                size={"xs"}
                                                                onClick={() => removeSkill(index)}
                                                            />
                                                        )}
                                                    </Flex>
                                                ))}
                                                <Flex justify={"flex-end"} >
                                                    <Button
                                                        size={"xs"}
                                                        bg={"#118AB2"}
                                                        color={"white"}
                                                        _hover={{ bg: "#01BF02" }}
                                                        aria-label="Add Skill"
                                                        onClick={addSkill}
                                                    >
                                                        Add Skill
                                                    </Button>
                                                </Flex>
                                            </FormControl>

                                            {/* Responsibilities */}
                                            <FormControl isRequired id="responsibilities">
                                                <FormLabel fontWeight={"bold"}>Responsibilities</FormLabel>
                                                <InputGroup>
                                                    <Input
                                                        type="text"
                                                        bg={"white"}
                                                        shadow={"md"}
                                                        placeholder="Enter responsibility"
                                                        value={currentResponsibility}
                                                        onChange={(e) => setCurrentResponsibility(e.target.value)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Tab' || e.key === 'Enter') {
                                                                e.preventDefault();
                                                                addResponsibility();
                                                            }
                                                        }}
                                                    />
                                                    <InputRightElement width="4.5rem">
                                                        <Button bg={"#118AB2"} color={"white"} _hover={{ bg: "#01BF02" }} h="1.75rem" size="sm" onClick={addResponsibility}>Add</Button>
                                                    </InputRightElement>
                                                </InputGroup>
                                                <Wrap maxH={"200px"} overflowY={"scroll"} m={1}>
                                                    {jobData?.responsibilities && jobData.responsibilities.map((resp, index) => (
                                                        <Tag
                                                            key={index}
                                                            size="lg"
                                                            bg={"#01BF02"}
                                                            color={"white"}
                                                            variant="solid"
                                                        >
                                                            {resp}
                                                            <TagCloseButton color={"black"} ml={2} onClick={() => removeResponsibility(index)} />
                                                        </Tag>
                                                    ))}
                                                </Wrap>
                                            </FormControl>
                                        </HStack>
                                        <HStack align={"flex-start"} w={"100%"}>
                                            {/* Tags */}
                                            <FormControl isRequired id="tags">
                                                <FormLabel fontWeight={"bold"}>Tags</FormLabel>
                                                <InputGroup>
                                                    <Input
                                                        type="text"
                                                        bg={"white"}
                                                        shadow={"md"}
                                                        placeholder="Enter tags"
                                                        value={currentTag}
                                                        onChange={(e) => setCurrentTag(e.target.value)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Tab' || e.key === 'Enter') {
                                                                e.preventDefault();
                                                                addTag();
                                                            }
                                                        }}
                                                    />
                                                    <InputRightElement width="4.5rem">
                                                        <Button bg={"#118AB2"} color={"white"} _hover={{ bg: "#01BF02" }} mr={2} h="1.75rem" size="sm" onClick={addTag}>
                                                            Add
                                                        </Button>
                                                    </InputRightElement>
                                                </InputGroup>
                                                <Wrap maxH={"200px"} overflowY={"scroll"} mt={2}>
                                                    {jobData.tags.map((tag, index) => (
                                                        <Tag
                                                            key={index}
                                                            size="lg"
                                                            bg={"#01BF02"}
                                                            color={"white"}
                                                            variant="solid"
                                                            m={1}
                                                        >
                                                            {tag}
                                                            <TagCloseButton color={"black"} ml={2} onClick={() => removeTag(index)} />
                                                        </Tag>
                                                    ))}
                                                </Wrap>
                                            </FormControl>

                                            {/* Qualification */}
                                            <FormControl isRequired id="qualification">
                                                <FormLabel fontWeight={"bold"}>Qualification</FormLabel>
                                                <InputGroup>
                                                    <Input
                                                        type="text"
                                                        shadow={"md"}
                                                        bg={"white"}
                                                        placeholder="Enter qualification"
                                                        value={currentQualification}
                                                        onChange={(e) => setCurrentQualification(e.target.value)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Tab' || e.key === 'Enter') {
                                                                e.preventDefault();
                                                                addQualification();
                                                            }
                                                        }}
                                                    />
                                                    <InputRightElement width="4.5rem">
                                                        <Button bg={"#118AB2"} color={"white"} _hover={{ bg: "#01BF02" }} h="1.75rem" size="sm" onClick={addQualification}>Add</Button>
                                                    </InputRightElement>
                                                </InputGroup>
                                                <Wrap maxH={"200px"} overflowY={"scroll"} m={1}>
                                                    {jobData?.qualification && jobData.qualification.map((qual, index) => (
                                                        <Tag
                                                            key={index}
                                                            size="lg"
                                                            bg={"#01BF02"}
                                                            color={"white"}
                                                            variant="solid"
                                                        >
                                                            {qual}
                                                            <TagCloseButton color={"black"} ml={2} onClick={() => removeQualification(index)} />
                                                        </Tag>
                                                    ))}
                                                </Wrap>
                                            </FormControl>
                                        </HStack>
                                        <Flex w="90%" alignItems="center" justify={"center"}>
                                            <Button
                                                bg={"#118AB2"}
                                                color={"white"}
                                                _hover={{ bg: "#01BF02" }}
                                                onClick={handleSkillAPICall}
                                                isLoading={isLoading}
                                                loadingText='Writing'
                                            >
                                                <FontAwesomeIcon icon={faBolt} />
                                                <Text ml={2}>Quick Fill</Text>
                                            </Button>
                                        </Flex>
                                    </VStack>
                                )}

                                {step === 2 && (
                                    <Flex direction={"column"} w={"100%"}>
                                        <Flex w={"100%"} alignItems="flex-start" justify={"space-evenly"}>
                                            <FormControl isRequired maxW="600px">
                                                <Flex direction={"column"} justify={"flex-start"}>
                                                    <FormLabel fontWeight={"bold"}>Payment Frequency</FormLabel>
                                                    <RadioGroup onChange={setPaymentFrequency} value={paymentFrequency}>
                                                        <VStack align="left">
                                                            <Radio bg={"white"} value="weekly">Weekly</Radio>
                                                            <Radio bg={"white"} value="bi-weekly">Bi-Weekly</Radio>
                                                            <Radio bg={"white"} value="other">Other</Radio>
                                                        </VStack>
                                                    </RadioGroup>
                                                </Flex>
                                            </FormControl>

                                            <FormControl isRequired ml={4}>
                                                <FormLabel fontWeight={"bold"}>Compensation Structure</FormLabel>
                                                <RadioGroup
                                                    onChange={(value) => {
                                                        setPaymentType(value);
                                                        setJobData(prevState => ({
                                                            ...prevState,
                                                            paymentType: value,
                                                        }));
                                                    }}
                                                    value={paymentType}
                                                >
                                                    <HStack ml={4} spacing={3}>
                                                        <Radio bg={"white"} value="salary">Salary</Radio>
                                                        <Radio bg={"white"} value="hourly">Hourly</Radio>
                                                    </HStack>
                                                </RadioGroup>
                                            </FormControl>
                                            <FormControl isRequired>
                                                <FormLabel fontWeight={"bold"}>
                                                    {paymentType === 'salary' ? 'Salary Range' : 'Hourly Rate'}
                                                </FormLabel>
                                                <InputGroup>
                                                    <Select
                                                        bg={"white"}
                                                        value={jobData.paymentDetails?.qualifier}
                                                        onChange={handleQualifierChange}
                                                        w="300px"
                                                        shadow={"md"}
                                                        mr={2}
                                                    >
                                                        <option value="Starting at">Starting at</option>
                                                        <option value="Up to">Up to</option>
                                                    </Select>
                                                    <InputGroup>
                                                        <InputLeftElement
                                                            pointerEvents='none'
                                                            color='black'
                                                            shadow={"md"}
                                                            fontSize="lg"
                                                            children='$'
                                                        />
                                                        <Input
                                                            type="number"
                                                            bg={"white"}
                                                            shadow={"md"}
                                                            placeholder={paymentType === 'salary' ? 'Enter salary range' : 'Enter hourly rate'}
                                                            value={jobData.paymentDetails?.amount}
                                                            onChange={handleAmountChange}
                                                            w="100%"
                                                            required
                                                        />
                                                    </InputGroup>
                                                    <InputRightAddon shadow={"md"} children={paymentType === 'salary' ? 'per year' : 'per hour'} />
                                                </InputGroup>
                                            </FormControl>
                                        </Flex>
                                        <Flex mt={4} w={"100%"} justify={"flex-start"}>
                                            <FormControl maxW="600px">
                                                <FormLabel fontWeight={"bold"}>Additional Compensation</FormLabel>
                                                <Stack direction={"row"}>
                                                    <Checkbox
                                                        sx={{ '.chakra-checkbox__control': { borderColor: '#118AB2' }, '&:hover .chakra-checkbox__control': { borderColor: '#118AB2', boxShadow: '0 0 0 1px #118AB2' } }}
                                                        name="commission"
                                                        isChecked={jobData.additionalCompensation.commission}
                                                        onChange={handleCheckboxChange}
                                                    >
                                                        Commission
                                                    </Checkbox>
                                                    <Checkbox
                                                        sx={{ '.chakra-checkbox__control': { borderColor: '#118AB2' }, '&:hover .chakra-checkbox__control': { borderColor: '#118AB2', boxShadow: '0 0 0 1px #118AB2' } }}
                                                        name="bonuses"
                                                        isChecked={jobData.additionalCompensation.bonuses}
                                                        onChange={handleCheckboxChange}
                                                    >
                                                        Bonuses
                                                    </Checkbox>
                                                    <Checkbox
                                                        sx={{ '.chakra-checkbox__control': { borderColor: '#118AB2' }, '&:hover .chakra-checkbox__control': { borderColor: '#118AB2', boxShadow: '0 0 0 1px #118AB2' } }}
                                                        name="other"
                                                        isChecked={jobData.additionalCompensation.other}
                                                        onChange={handleCheckboxChange}
                                                    >
                                                        Other
                                                    </Checkbox>

                                                </Stack>
                                            </FormControl>
                                        </Flex>
                                        <FormControl mt={4} isRequired w="100%">
                                            <FormLabel>Additional Perks & Benefits</FormLabel>
                                            <Textarea
                                                name="additionalPerks"
                                                value={jobData.additionalPerks}
                                                onChange={handleInputChange}
                                                shadow={"md"}
                                                bg={"white"}
                                                placeholder="Enter additional perks and benefits here..."
                                                size="lg"
                                                resize="vertical"
                                                minHeight="200px"
                                            />
                                        </FormControl>
                                    </Flex>
                                )}

                                {step === 3 && (
                                    <Box w={"90%"}>
                                        <Flex mb={2} justify={"center"} w={"100%"}>
                                            <Box p={2} borderRadius={"lg"} bg={"white"}>
                                                <Text fontSize={"2xl"}>
                                                    Set specific criteria for our AI to help pinpoint the best applicants.
                                                </Text>
                                            </Box>
                                        </Flex>
                                        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5} mt={2}>
                                            {jobData?.customCriteria && jobData.customCriteria.map((criteria, index) => (
                                                <Box key={index} p={4} boxShadow="md" bg="black" borderRadius="md">
                                                    <FormControl isRequired>
                                                        <FormLabel color={"white"}>Criteria</FormLabel>
                                                        <Input
                                                            bg={"white"}
                                                            type="text"
                                                            placeholder="Enter criteria"
                                                            value={criteria.criteria || ''}
                                                            onChange={(e) => handleCriteriaChange(e, index, 'criteria')}
                                                        />
                                                    </FormControl>
                                                    <FormControl isRequired mt={4}>
                                                        <FormLabel color={"white"}>Description</FormLabel>
                                                        <Textarea
                                                            bg={"white"}
                                                            placeholder="Enter criteria details"
                                                            value={criteria.description || ''}
                                                            onChange={(e) => handleCriteriaChange(e, index, 'description')}
                                                        />
                                                    </FormControl>
                                                    <Flex justify={"flex-end"}>
                                                        <Button onClick={() => handleRemoveCriteria(index)} size={"sm"} variant='solid' colorScheme="red" mt={4}>Remove</Button>
                                                    </Flex>
                                                </Box>
                                            ))}
                                        </SimpleGrid>

                                        <Flex justify={"flex-end"} >
                                            <Button onClick={handleAddCriteria} variant='ghost' size={"sm"} colorScheme="blue" mt={4}>+ Add Criteria</Button>
                                        </Flex>
                                    </Box>
                                )}

                                {step === 4 && (
                                    <VStack w={"100%"}>
                                        <FormControl isRequired w="100%">
                                            <FormLabel mt={4} fontWeight={"bold"}>Job Summary</FormLabel>
                                            <Textarea
                                                value={jobData.description}
                                                shadow={"md"}
                                                onChange={handleJobDescriptionChange}
                                                style={{
                                                    width: '100%',
                                                    minHeight: '300px',
                                                    borderRadius: '12px',
                                                    border: '2px solid #ccc',
                                                    backgroundColor: 'white',
                                                    color: 'black'
                                                }}
                                                placeholder="Summarize the job...or let us do it for you."
                                            />

                                            <style jsx global>{`
                                            .quill .ql-editor {
                                            font-size: 16px;  /* XL size */
                                            min-height: 300px;
                                            max-height: 600px;
                                            }
                                        `}</style>
                                        </FormControl>

                                        <Flex w="90%" alignItems="center" justify={"center"}>
                                            <Button
                                                bg={"#118AB2"}
                                                color={"white"}
                                                _hover={{ bg: "#01BF02" }}
                                                onClick={handleChatAPICall}
                                                isLoading={isLoading}
                                                loadingText='Generating'
                                            >
                                                <FontAwesomeIcon icon={faBolt} />
                                                <Text ml={2}>Generate Job Summary</Text>
                                            </Button>
                                        </Flex>
                                    </VStack>
                                )}

                                {step === 5 && (
                                    <FormControl isRequired w="100%">
                                        <FormLabel mt={4} fontWeight="bold">Job Image</FormLabel>
                                        <Input
                                            type="file"
                                            name="image"
                                            accept="image/*"
                                            onChange={handleFileChange}
                                            hidden // Hide the default input
                                            id="file-upload" // Reference for the label
                                        />
                                        <FormLabel
                                            htmlFor="file-upload" // Associates with the input
                                            cursor="pointer"
                                            padding="10px"
                                            borderRadius="md"
                                            bg={"#118AB2"} color={"white"} _hover={{ bg: "#01BF02" }}
                                            display="inline-flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            w="fit-content"
                                        >
                                            <Icon as={FaFileUpload} mr="2" />
                                            Upload Image
                                        </FormLabel>
                                        {jobData.image && (
                                            <Text mt={2}>
                                                {jobData.image.name}
                                            </Text>
                                        )}
                                    </FormControl>

                                )}

                                {step === 6 && (
                                    <>
                                        <Flex w={"100%"} justify={"flex-start"} align={"flex-start"}>
                                            <Flex justify={"flex-start"}>
                                                <FormControl display="flex" alignItems="center">
                                                    <FormLabel fontWeight="bold" mr={4}>
                                                        Premium Listing
                                                    </FormLabel>
                                                    <Switch
                                                        size="md"
                                                        isChecked={jobData.isPaidPerJobOption}
                                                        onChange={() => handleSwitchChange('isPaidPerJobOption')}
                                                    />
                                                </FormControl>
                                            </Flex>
                                            {!jobData.isPaidPerJobOption && (
                                                <Flex ml={4} justify={"flex-start"}>
                                                    <FormControl display="flex" alignItems="center">
                                                        <FormLabel fontWeight="bold" mr={4}>
                                                            Add an Incentive?
                                                        </FormLabel>
                                                        <Switch
                                                            size="md"
                                                            isChecked={jobData.bountyToRecruiters}
                                                            onChange={() => handleSwitchChange('bountyToRecruiters')}
                                                        />
                                                    </FormControl>
                                                </Flex>
                                            )}
                                        </Flex>

                                        {jobData.isPaidPerJobOption ?
                                            <VStack w={"100%"} spacing={4}>
                                                {/* Display the price when Paid Per Job Option is selected */}
                                                <Flex w={"100%"} justify={"flex-start"} align={"flex-start"}>
                                                    <FormControl>
                                                        <FormLabel fontWeight="bold">Premium Job Listing Cost</FormLabel>
                                                        <InputGroup>
                                                            <InputLeftElement
                                                                pointerEvents='none'
                                                                color='gray.500'
                                                                fontSize="1.2em"
                                                                children='$'
                                                            />
                                                            <Input
                                                                type="number"
                                                                w={"200px"}
                                                                bg={"white"}
                                                                value={450}
                                                                readOnly
                                                                size="lg"
                                                            />
                                                        </InputGroup>
                                                    </FormControl>
                                                </Flex>
                                                <Divider color={"black"} border={"1px"} />
                                                <Box bg="gray.800" p={6} borderWidth="1px" borderRadius="lg" overflow="hidden">
                                                    <VStack spacing={5} align="stretch">
                                                        <Flex align="center">
                                                            <FontAwesomeIcon icon={faUsersLine} color="#01bf02" />
                                                            <Text ml={2} fontSize="lg" color="white" fontWeight="bold">
                                                                All Applicants Revealed:
                                                            </Text>
                                                        </Flex>
                                                        <Text fontSize="md" color="gray.300">
                                                            Every candidate is automatically visible to you, streamlining the selection process.
                                                        </Text>

                                                        <Flex align="center">
                                                            <FontAwesomeIcon icon={faSearch} color="#01bf02" />
                                                            <Text ml={2} fontSize="lg" color="white" fontWeight="bold">
                                                                SmartMatch Recruiter:
                                                            </Text>
                                                        </Flex>
                                                        <Text fontSize="md" color="gray.300">
                                                            Our Advanced AI recruiter is always looking for the best candidates for your open position. When we find a good fit we provide you with our recommendations.
                                                        </Text>

                                                        <Flex align="center">
                                                            <FontAwesomeIcon icon={faComments} color="#01bf02" />
                                                            <Text ml={2} fontSize="lg" color="white" fontWeight="bold">
                                                                AI-Conducted Interviews:
                                                            </Text>
                                                        </Flex>
                                                        <Text fontSize="md" color="gray.300">
                                                            Leverage our AI technology to conduct initial interviews, saving you time and resources.
                                                        </Text>

                                                        <Flex align="center">
                                                            <FontAwesomeIcon icon={faStar} color="#01bf02" />
                                                            <Text ml={2} fontSize="lg" color="white" fontWeight="bold">
                                                                Preferential Placement:
                                                            </Text>
                                                        </Flex>
                                                        <Text fontSize="md" color="gray.300">
                                                            We ensure your job posting gets prime placement, making it more visible to the right candidates.
                                                        </Text>

                                                        <Flex align="center">
                                                            <FontAwesomeIcon icon={faCircleNodes} color="#01bf02" />
                                                            <Text ml={2} fontSize="lg" color="white" fontWeight="bold">
                                                                Recruiter Network Access:
                                                            </Text>
                                                        </Flex>
                                                        <Text fontSize="md" color="gray.300">
                                                            We don’t wait for talent to find you. Your listing is proactively sent to our network of specialized recruiters, leveraging their expertise to find suitable candidates for you.
                                                        </Text>

                                                        <Flex align="center">
                                                                    <FontAwesomeIcon icon={faListCheck} color="#01bf02" />
                                                                    <Text ml={2} fontSize="lg" color="white" fontWeight="bold">
                                                                        AI-powered Match Scoring:
                                                                    </Text>
                                                                </Flex>
                                                                <Text fontSize="md" color="gray.300">
                                                                    Receive detailed AI analysis and parsed resume data for every applicant.
                                                                </Text>

                                                                <Flex align="center">
                                                                    <FontAwesomeIcon icon={faBarsProgress} color="#01bf02" />
                                                                    <Text ml={2} fontSize="lg" color="white" fontWeight="bold">
                                                                        Advanced Applicant Manangement Tools:
                                                                    </Text>
                                                                </Flex>
                                                                <Text fontSize="md" color="gray.300">
                                                                    You and your team will have access to all of our premium applicant managment tools. Keeping your recruitment workflow neat and tidy.
                                                                </Text>

                                                    </VStack>
                                                </Box>



                                                <Divider m={2} color={"black"} border={"1px"} />

                                                {/* This section will be rendered only if bountyToRecruiters is true */}
                                                <FormControl>
                                                    <FormLabel fontWeight="bold" fontSize="xl">Choose a Badge for Your Job Listing</FormLabel>
                                                    <Wrap>
                                                        {badges.map((badge) => (
                                                            <WrapItem key={badge.id}>
                                                                <BadgeOption
                                                                    badge={badge}
                                                                    isSelected={jobData.selectedBadgeId === badge.id}
                                                                    onSelectBadge={handleBadgeSelect}
                                                                />
                                                            </WrapItem>
                                                        ))}
                                                    </Wrap>
                                                </FormControl>
                                            </VStack>
                                            :
                                            <VStack w={"100%"} spacing={4}>
                                                <HStack w={"100%"} justify={"flex-start"}>
                                                    <Flex justify={"flex-start"}>
                                                        <FormControl>
                                                            <FormLabel fontWeight="bold">Price To Reveal Candidate</FormLabel>
                                                            <InputGroup>
                                                                <InputLeftElement
                                                                    pointerEvents='none'
                                                                    color='gray.500'
                                                                    fontSize="1.2em"
                                                                    children='$'
                                                                />
                                                                <Input
                                                                    type="number"
                                                                    bg={"white"}
                                                                    w={"200px"}
                                                                    value={jobData.revealPrice}
                                                                    readOnly
                                                                    size="lg"
                                                                />
                                                            </InputGroup>
                                                        </FormControl>
                                                        {!jobData.bountyToRecruiters && (
                                                            <Box bg="gray.800" p={6} borderWidth="1px" borderRadius="lg" overflow="hidden">
                                                                <VStack spacing={5} align="stretch">

                                                                    <Flex align="center">
                                                                        <FontAwesomeIcon icon={faListCheck} color="#01bf02" />
                                                                        <Text ml={2} fontSize="lg" color="white" fontWeight="bold">
                                                                            AI-powered Match Scoring:
                                                                        </Text>
                                                                    </Flex>
                                                                    <Text fontSize="md" color="gray.300">
                                                                        Receive detailed AI analysis and parsed resume data for every applicant.
                                                                    </Text>

                                                                    <Flex align="center">
                                                                        <FontAwesomeIcon icon={faBarsProgress} color="#01bf02" />
                                                                        <Text ml={2} fontSize="lg" color="white" fontWeight="bold">
                                                                            Advanced Applicant Manangement Tools:
                                                                        </Text>
                                                                    </Flex>
                                                                    <Text fontSize="md" color="gray.300">
                                                                        You and your team will have access to all of our premium applicant managment tools. Keeping your recruitment workflow neat and tidy.
                                                                    </Text>

                                                                    <Flex align="center">
                                                                        <FontAwesomeIcon icon={faPiggyBank} color="#01bf02" />
                                                                        <Text ml={2} fontSize="lg" color="white" fontWeight="bold">
                                                                            No Upfront Costs:
                                                                        </Text>
                                                                    </Flex>
                                                                    <Text fontSize="md" color="gray.300">
                                                                        Our unique model allows for maximum exposure without upfront costs. You only pay $10 when you find a candidate you’re interested in and wish to initiate contact.
                                                                    </Text>

                                                                </VStack>
                                                            </Box>)}
                                                    </Flex>
                                                    {jobData.bountyToRecruiters && (
                                                        <>
                                                            <Flex>
                                                                <FormControl>
                                                                    <FormLabel fontWeight="bold">Additional Incentive</FormLabel>
                                                                    <InputGroup>
                                                                        <InputLeftElement
                                                                            pointerEvents='none'
                                                                            color='gray.500'
                                                                            fontSize="1.2em"
                                                                            children='$'
                                                                        />
                                                                        <Input
                                                                            type="number"
                                                                            bg={"white"}
                                                                            w={"200px"}
                                                                            mr={6}
                                                                            placeholder="Enter an additional incentive bonus"
                                                                            value={jobData.bounty}
                                                                            onChange={handleBountyChange}
                                                                            onBlur={handleBountyBlur}
                                                                            size="lg"
                                                                        />
                                                                    </InputGroup>
                                                                </FormControl>

                                                                <FormControl>
                                                                    <FormLabel fontWeight="bold">Total</FormLabel>
                                                                    <InputGroup>
                                                                        <InputLeftElement
                                                                            pointerEvents='none'
                                                                            color='gray.500'
                                                                            fontSize="1.2em"
                                                                            children='$'
                                                                        />
                                                                        <Input
                                                                            type="number"
                                                                            bg={"white"}
                                                                            readOnly
                                                                            w={"200px"}
                                                                            value={jobData.subTotal}
                                                                            size="lg"
                                                                        />
                                                                    </InputGroup>
                                                                </FormControl>
                                                            </Flex>
                                                        </>
                                                    )}
                                                </HStack>

                                                {jobData.bountyToRecruiters && (
                                                    <>
                                                        <Divider color={"black"} border={"1px"} />
                                                        <Box bg="gray.800" p={6} borderWidth="1px" borderRadius="lg" overflow="hidden">
                                                            <VStack spacing={5} align="stretch">

                                                                <Flex align="center">
                                                                    <FontAwesomeIcon icon={faSearch} color="#01bf02" />
                                                                    <Text ml={2} fontSize="lg" color="white" fontWeight="bold">
                                                                        Preferential Placement:
                                                                    </Text>
                                                                </Flex>
                                                                <Text fontSize="md" color="gray.300">
                                                                    We ensure it gets prime placement, making it more visible to the right candidates.
                                                                </Text>

                                                                <Flex align="center">
                                                                    <FontAwesomeIcon icon={faCircleNodes} color="#01bf02" />
                                                                    <Text ml={2} fontSize="lg" color="white" fontWeight="bold">
                                                                        Recruiter Network Access:
                                                                    </Text>
                                                                </Flex>
                                                                <Text fontSize="md" color="gray.300">
                                                                    We don’t wait for talent to find you. Your listing is proactively sent to our network of specialized recruiters, leveraging their expertise to find suitable candidates for you.
                                                                </Text>


                                                                <Flex align="center">
                                                                    <FontAwesomeIcon icon={faUnlockKeyhole} color="#01bf02" />
                                                                    <Text ml={2} fontSize="lg" color="white" fontWeight="bold">
                                                                        No Upfront Costs:
                                                                    </Text>
                                                                </Flex>
                                                                <Text fontSize="md" color="gray.300">
                                                                    Our unique model allows for maximum exposure without upfront costs. You only incur a fee when you find a candidate you’re interested in and wish to initiate contact.
                                                                </Text>

                                                                <Flex align="center">
                                                                    <FontAwesomeIcon icon={faListCheck} color="#01bf02" />
                                                                    <Text ml={2} fontSize="lg" color="white" fontWeight="bold">
                                                                        AI-powered Match Scoring:
                                                                    </Text>
                                                                </Flex>
                                                                <Text fontSize="md" color="gray.300">
                                                                    Receive detailed AI analysis and parsed resume data for every applicant.
                                                                </Text>

                                                                <Flex align="center">
                                                                    <FontAwesomeIcon icon={faBarsProgress} color="#01bf02" />
                                                                    <Text ml={2} fontSize="lg" color="white" fontWeight="bold">
                                                                        Advanced Applicant Manangement Tools:
                                                                    </Text>
                                                                </Flex>
                                                                <Text fontSize="md" color="gray.300">
                                                                    You and your team will have access to all of our premium applicant managment tools. Keeping your recruitment workflow neat and tidy.
                                                                </Text>

                                                            </VStack>
                                                        </Box>


                                                        <Divider m={2} color={"black"} border={"1px"} />

                                                        {/* This section will be rendered only if bountyToRecruiters is true */}
                                                        <FormControl>
                                                            <FormLabel fontWeight="bold" fontSize="xl">Choose a Badge for Your Job Listing</FormLabel>
                                                            <Wrap>
                                                                {badges.map((badge) => (
                                                                    <WrapItem key={badge.id}>
                                                                        <BadgeOption
                                                                            badge={badge}
                                                                            isSelected={jobData.selectedBadgeId === badge.id}
                                                                            onSelectBadge={handleBadgeSelect}
                                                                        />
                                                                    </WrapItem>
                                                                ))}
                                                            </Wrap>
                                                        </FormControl>
                                                    </>
                                                )}
                                            </VStack>
                                        }
                                    </>
                                )}
                            </VStack>
                            <Spacer />
                            <HStack w={"100%"} justify={"space-between"} mt={8}>
                                {canGoBack() && (
                                    <Button bg={"#FFD166"} color={"black"} _hover={{ bg: "yellow.300" }} onClick={goToPrevStep}>Previous</Button>
                                )}
                                {!canGoBack() && (
                                    <Flex></Flex>
                                )}
                                {canGoNext() ? (
                                    <Button bg={"#118AB2"} color={"white"} _hover={{ bg: "#01BF02" }} onClick={goToNextStep}>Next</Button>
                                ) : (
                                    <Button
                                        onClick={handleSubmit}
                                        bg={"#118AB2"} color={"white"} _hover={{ bg: "#01BF02" }}
                                        isLoading={isLoading}
                                        loadingText="Creating..."
                                        disabled={isLoading}
                                    >
                                        Create Job
                                    </Button>
                                )}
                            </HStack>
                        </Box>
                    </Flex>
                </Box>
            </Flex>
        </Flex>
    );
};

export default JobCreation;
