import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, FormControl, Flex, FormLabel, Textarea, VStack, Heading, useToast, Text, Progress, CircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import axiosInstance from '../../components/axiosInstance';
import Background from '../../assets/Background.webp';
import AudioRecorder from '../../components/AudioRecorder';
import CustomAudioPlayer from '../../components/CustomerAudioPlayer';
import Header from '../../components/Header';

const AIinterview = () => {
    const { applicantId } = useParams();
    const token = localStorage.getItem('auth_token');
    const [applicant, setApplicant] = useState([]);
    const [interviewId, setInterviewId] = useState(null);
    const [interview, setInterview] = useState([]);
    const [generatedSpeechURL, setGeneratedSpeechURL] = useState('');
    const toast = useToast();

    // Client-side code to fetch the questions for the assessment
    useEffect(() => {
        fetchApplicant(applicantId);
        fetchAIInterview();
    }, []);

    const fetchAIInterview = async () => {
        try {
            const response = await axiosInstance.get(`/ai-interview/${applicantId}`, {
                headers: { "Authorization": `Bearer ${token}` }
            });
            if (response.data) {
                // Process the fetched AIInterview data
                console.log('Fetched AIInterview:', response.data);
                setInterview(response.data);
            } else {

            }
        } catch (error) {

        }
    };

    const fetchApplicant = async (applicantId) => {
        try {
            const response = await axiosInstance.get(`/applicant/${applicantId}`, {
                headers: { "Authorization": `Bearer ${token}` }
            });
            if (response.data) {
                setApplicant(response.data);
            } else {
                toast({ title: "No applicant found", status: "error" });
            }
        } catch (error) {
            console.error(error);
            toast({ title: "Error fetching applicant", description: error.message, status: "error" });
        }
    };

    const createInterview = async () => {
        if (!applicant || !applicant.jobSeeker || !applicant.job || !applicant.company) {
            toast({ title: "Applicant data is incomplete", status: "error" });
            return;
        }

        const interviewDetails = {
            applicantId: applicantId,
            candidateName: applicant.jobSeeker.name,
            candidateEmail: applicant.jobSeeker.email,
            candidateEducation: applicant.jobSeeker.education,
            candidateWorkHistory: applicant.jobSeeker.workHistory,
            jobTitle: applicant.job.jobTitle,
            jobIndustry: applicant.job.industry,
            customCriteria: applicant.job.customCriteria,
            companyId: applicant.company.companyId,
            companyName: applicant.company.companyName,
        };

        try {
            const response = await axiosInstance.post('/create-interview', interviewDetails, {
                headers: { "Authorization": `Bearer ${token}` }
            });
            if (response.data && response.data.interviewId) {
                setInterviewId(response.data.interviewId);
                toast({ title: "Interview created successfully", status: "success" });
            } else {
                toast({ title: "Failed to create interview", status: "error" });
            }
        } catch (error) {
            console.error(error);
            toast({ title: "Error creating interview", description: error.message, status: "error" });
        }
    };

    const handleGeneratedSpeechURLChange = (newURL) => {
        setGeneratedSpeechURL(newURL);
    };

    const renderInterviewQuestions = (interviewData) => {
        return interviewData?.questions?.map((item, index) => (
            <Box bg={"white"} shadow={"lg"} key={item._id.$oid} p={4} borderWidth="1px" borderRadius="lg">
                <Heading as="h3" size="md">Message {index + 1}</Heading>
                <Text mt={2}><strong>Interviewer:</strong> {item?.questionText}</Text>
                <Flex w={"100%"} justify={"flex-end"}>
                    <CustomAudioPlayer audioURL={item?.speechUrl} />
                </Flex>
                {item?.response?.audioUrl && (
                    <>
                        <Text mt={2}><strong>{interview?.candidateName}:</strong> {item?.response?.transcript}</Text>
                        <Flex w={"100%"} justify={"flex-end"}>
                            <CustomAudioPlayer audioURL={item?.response?.audioUrl} />
                        </Flex>
                    </>
                )}
            </Box>
        ));
    };

    const getCompletionPercentage = () => {
        const totalQuestions = 7;
        const answeredQuestions = interview?.questions?.length || 0;
        return (answeredQuestions / totalQuestions) * 100;
    };

    return (
        <Box bgImage={Background} overflowY={"scroll"} bgSize="cover" h={"100vh"} bgRepeat="no-repeat" bgPosition="center">
            <Header />
            <Flex w={"100%"} justify={"center"}>
                <Flex mt={8} justify={"center"} w={"50%"} bg={"whiteAlpha.800"} borderRadius={"2xl"} p={4}>
                    <VStack w={"80%"} mt={4} p={4} spacing={5}>
                        <Heading as="h1">AI Powered Interview</Heading>
                        {applicant?.jobSeeker?.name && (
                            <Text>Welcome {applicant.jobSeeker.name}! Thank you for accepting the invitation from {applicant.company.companyName}. Today you will be speaking with the world's most advanced AI interviewing service. You will need to have enable sound and mic permissions during the session.</Text>
                        )}
                        {!interviewId && <Button colorScheme="blue" onClick={createInterview}>Get Started</Button>}
                        {interviewId && (
                            <>
                                <CircularProgress value={getCompletionPercentage()} color="#01bf02" size="100px">
                                    <CircularProgressLabel>{`${Math.round(getCompletionPercentage())}%`}</CircularProgressLabel>
                                </CircularProgress>
                                <Text>Your Interview ID: {interviewId}</Text>
                                {!interview.interviewFinished && (
                                    <AudioRecorder interviewId={interviewId} interview={interview} onGeneratedSpeechURLChange={handleGeneratedSpeechURLChange} />
                                )}
                                <VStack spacing={4} mt={4}>
                                    {renderInterviewQuestions(interview)}
                                </VStack>
                            </>
                        )}
                    </VStack>
                </Flex>
            </Flex>
        </Box>
    );
};

export default AIinterview;