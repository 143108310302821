import React, { useState, useEffect } from 'react';
import {
    Box, Flex, Heading, Text, Badge, VStack, HStack, Button, useToast, Divider,
    Image, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
    ModalCloseButton, useDisclosure, Textarea
} from '@chakra-ui/react';
import { ChatIcon } from '@chakra-ui/icons';
import JobLiveFeed from './LiveFeedJobId';
import DirectResumeUpload from './DirectResumeUpload';
import axiosInstance from './axiosInstance';
import Logo from '../assets/blacklogo.png';
import RecommendedJobCard from './RecommendedJobCard';

const JobDetails = ({ selectedJob }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [hasApplied, setHasApplied] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [recommendedJobs, setRecommendedJobs] = useState([]);
    const [comment, setComment] = useState('');
    const [applicationStatus, setApplicationStatus] = useState('');
    const [companyInfo, setCompanyInfo] = useState(null);
    const toast = useToast();
    const token = localStorage.getItem('auth_token');
    const isLoggedIn = !!token;

    useEffect(() => {
        if (!selectedJob || !isLoggedIn) return;

        const fetchApplicationStatus = async () => {
            try {
                const response = await axiosInstance.get(`/hasApplied?jobId=${selectedJob._id}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                setHasApplied(response.data.hasApplied);
                if (response.data.hasApplied) {
                    setApplicationStatus(response.data.status);
                    setCompanyInfo(response.data.company);
                    console.log('setCompanyInfo', companyInfo);
                }
            } catch (error) {
                console.error('Fetch Application Status Error:', error);
                toast({
                    title: 'Error',
                    description: 'Could not fetch application status.',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
        };

        fetchApplicationStatus();
    }, [selectedJob, isLoggedIn, toast]);

    useEffect(() => {
        if (!selectedJob || hasApplied) return;

        const fetchRecommendedJobs = async () => {
            try {
                // Pass the current job's ID as a query parameter
                const response = await axiosInstance.get(`/jobRecommendations/${selectedJob.industry}`, {
                    params: { excludeJobId: selectedJob._id }
                });
                setRecommendedJobs(response.data);
            } catch (error) {
                console.error('Error fetching recommended jobs:', error);
            }
        };

        fetchRecommendedJobs();
    }, [selectedJob, hasApplied]);

    const applyForJob = async () => {
        if (!isLoggedIn) {
            toast({
                title: "Authentication Required",
                description: "You must be logged in to apply for a job.",
                status: "warning",
                duration: 5000,
                isClosable: true,
            });
            return;
        }
        setIsLoading(true);
        try {
            await axiosInstance.post('/apply', { jobId: selectedJob._id }, {
                headers: { Authorization: `Bearer ${token}` },
            });

            toast({
                title: "Application Successful",
                description: "You have successfully applied for the job!",
                status: "success",
                duration: 3000,
                isClosable: true,
            });

            setHasApplied(true);
            setApplicationStatus('is pending');

        } catch (error) {
            toast({
                title: "Error",
                description: "Could not apply for the job.",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const getStatusStyle = (status) => {
        switch (status) {
            case 'is pending':
                return { bg: 'blue.300', color: 'white' };
            case 'has been revealed':
                return { bg: "#FFD166", color: 'black' };
            case 'is in their shortlist':
                return { bg: 'green.500', color: 'white' };
            case 'has been declined':
                return { bg: 'red.500', color: 'white' };
            default:
                return { bg: 'gray.500', color: 'white' };
        }
    };

    const renderApplicationStatus = () => {
        if (!hasApplied) {
            return (
                <Button
                    bg={"#FFD166"}
                    _hover={{ bg: 'black', color: "white", border: "2px", borderColor: "white" }}
                    onClick={applyForJob}
                    isLoading={isLoading}
                    loadingText="Applying..."
                >
                    Quick Apply
                </Button>
            );
        }

        const statusStyles = getStatusStyle(applicationStatus);
        return (
            <Box shadow="md" p={2} bg={statusStyles.bg} borderRadius="lg">
                <Text color={statusStyles.color}>
                    Your application {applicationStatus}...
                </Text>
            </Box>
        );
    };

    const renderRecommendedJobs = () => {
        return recommendedJobs.map(job => <RecommendedJobCard key={job._id} job={job} />);
    };

    const renderCompanyInfo = () => {
        if (!companyInfo) return null;

        return (
            <Flex w={"100%"} justify={"flex-start"}>
                <Box flex={{ base: 'none', lg: '3' }} borderRadius="md" bg="white" boxShadow="lg" m={4} p={6} maxWidth={{ lg: '100%' }}>
                    <HStack w={"100%"} align={"flex-start"}>
                        {companyInfo.logo && (
                            <Image
                                mb={2}
                                ml={2}
                                src={companyInfo.logo}
                                alt={`${companyInfo.companyName} logo`}
                                maxH="200px"
                                objectFit="contain"
                            />
                        )}
                        <VStack w={"80%"} align={"flex-start"} spacing={3}>
                            <Text mb={2}><strong>Company Name:</strong> {companyInfo.companyName || 'N/A'}</Text>
                            {companyInfo.website && <Text mb={2}><strong>Website:</strong> <a href={`https://${companyInfo.website}`} target="_blank" rel="noopener noreferrer">{companyInfo.website}</a></Text>}
                            {companyInfo.industry && <Text mb={2}><strong>Industry:</strong> {selectedJob.industry}</Text>}
                            {companyInfo.socialMediaLinks && companyInfo.socialMediaLinks.length > 0 && (
                                <HStack mb={2}>
                                    <strong>Social Media:</strong>
                                    {companyInfo.socialMediaLinks.map((link, index) => (
                                        <a key={index} href={link.link} target="_blank" rel="noopener noreferrer">
                                            {link.name}
                                        </a>
                                    ))}
                                </HStack>
                            )}
                            {companyInfo.description && <Text mb={2}><strong>Description:</strong> {companyInfo.description}</Text>}
                            {companyInfo.certificationsAndAwards && companyInfo.certificationsAndAwards.length > 1 && (
                                <VStack mb={2} align="start">
                                    <strong>Certifications & Awards:</strong>
                                    {companyInfo.certificationsAndAwards.map((award, index) => (
                                        <Text key={index}>{award}</Text>
                                    ))}
                                </VStack>
                            )}
                        </VStack>
                    </HStack>
                </Box>
            </Flex>
        );
    };

    const renderBadge = (badge) => {
        // Ensure badge is an object and has an id before trying to render it
        if (!badge || !badge.id) return null;
        return (
            <Badge mb={2} fontSize={"lg"} colorScheme={badge.colorScheme} variant={badge.variant}>
                {badge.name}
            </Badge>
        );
    };

    const renderAdditionalCompensation = (compensation) => {
        if (!compensation) return null;

        // Create an array of compensation texts
        const compensationTexts = [];
        if (compensation.commission) compensationTexts.push('Commission');
        if (compensation.bonuses) compensationTexts.push('Bonuses');
        if (compensation.other) compensationTexts.push('Other forms of compensation');

        // Join the array elements with a comma and space
        const compensationString = compensationTexts.join(', ');

        return (
            <Text>{compensationString}</Text>
        );
    };


    // Function to handle repost action
    const repostJob = async () => {
        try {
            const role = localStorage.getItem('user_role');
            const repostData = {
                jobId: selectedJob._id,
                image: selectedJob.image,
                jobTitle: selectedJob.jobTitle,
                jobBadge: selectedJob.badge ? {
                    name: selectedJob.badge.name,
                    colorScheme: selectedJob.badge.colorScheme,
                    variant: selectedJob.badge.variant
                } : null,
                industry: selectedJob.industry,
                employmentType: selectedJob.employmentType,
                workLocationType: selectedJob.workLocationType,
                comment,
                role
            };

            // Send the repost data to the backend
            await axiosInstance.post('/repost', repostData, {
                headers: { Authorization: `Bearer ${token}` },
            });

            // Show a success toast
            toast({
                title: "Repost Successful",
                description: "You have successfully reposted the job!",
                status: "success",
                duration: 3000,
                isClosable: true,
            });

            onClose(); // Close the modal after submitting

            // Reset the comment state after successful repost
            setComment('');

        } catch (error) {
            // Handle any errors here
            toast({
                title: "Error",
                description: "Could not repost the job. " + error.response.data.message,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleRepostSubmission = async () => {
        await repostJob();
        onClose(); // Close the modal after submission
    };

    if (!selectedJob) {
        return (
            <VStack p={4}>
                <Image src={Logo} w={"50%"} />
                <Text fontSize={"3xl"} fontWeight={"semibold"} mt={4}>Select a job to view details.</Text>
            </VStack>
        );
    }

    return (
        <Flex borderRadius={"xl"} bg={"black"} direction={{ base: 'column', lg: 'row' }} w="full" minH={"100vh"} align="start" p={4}>
            {/* Include the structured data component */}

            <VStack w={"100%"} align={"start"} justify={"start"}>
                <Box flex={{ base: 'none', lg: '3' }} borderRadius="md" bg="white" m={4} p={6} boxShadow="lg" maxWidth={{ lg: '100%' }}>
                    <VStack spacing={5}>
                        {/* Job title and badge */}
                        <Flex justify="space-between" align="center" w="full">
                            {!selectedJob.badge ? <></> : renderBadge(selectedJob.badge)}

                            {isLoggedIn && (
                                <Button _hover={{ bg: 'black', color: "white", border: "2px", borderColor: "white" }} variant='ghost' bg="#FFD166" onClick={onOpen}>
                                    <ChatIcon mr={2} />
                                    Repost
                                </Button>
                            )}
                            <Modal isOpen={isOpen} onClose={onClose}>
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalHeader>Repost Job</ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        <Textarea
                                            placeholder="Enter your comment"
                                            value={comment}
                                            onChange={(e) => setComment(e.target.value)}
                                        />
                                    </ModalBody>

                                    <ModalFooter>
                                        <Button _hover={{ bg: 'black', color: "white", border: "2px", borderColor: "white" }} color={"white"} bg={"#01BF02"} mr={3} onClick={handleRepostSubmission}>
                                            Repost
                                        </Button>
                                        <Button _hover={{ bg: 'black', color: "white", border: "2px", borderColor: "white" }} color={"white"} bg={"#EF476F"} onClick={onClose}>Cancel</Button>
                                    </ModalFooter>
                                </ModalContent>
                            </Modal>
                        </Flex>
                        <Heading as="h1" size="xl" fontWeight="bold">{selectedJob.jobTitle}</Heading>
                        <HStack>
                            <Text fontWeight="bold">Industry:</Text>
                            <Text>{selectedJob.industry}</Text>
                        </HStack>
                        {/* Job details */}
                        <Text ml={4} >{selectedJob.description}</Text>
                        <HStack align={"start"} w={"100%"} >
                            {selectedJob.image && (
                                <Image src={selectedJob.image} alt="Job Image" shadow={"lg"} borderRadius="md" ml={4} mr={4} maxW="400px" objectFit="cover" />
                            )}
                            <Box>
                                <HStack>
                                    <Text fontWeight="bold">Job Type:</Text>
                                    <Text>{selectedJob.employmentType}</Text>
                                </HStack>
                                <HStack>
                                    <Text fontWeight="bold">Work Location:</Text>
                                    <Text>{selectedJob.workLocationType}</Text>
                                </HStack>
                                <HStack>
                                    <Text fontWeight="bold">Location:</Text>
                                    <Text>{selectedJob.locationCity}, {selectedJob.locationState}</Text>
                                </HStack>
                                <Text>
                                    <strong>Skills:</strong>
                                    {selectedJob.skills.map((skill, index) => (
                                        <Box as="span" key={index} fontWeight="normal">
                                            {' '}
                                            <Box as="span" fontWeight="semibold">{skill.name}</Box>
                                            <Box as="span" fontWeight="light"> ({skill.level})</Box>
                                            {index !== selectedJob.skills.length - 1 ? ', ' : ''}
                                        </Box>
                                    ))}
                                </Text>
                            </Box>
                        </HStack>
                    </VStack>
                </Box>
                {/* Company info */}
                {hasApplied && (renderCompanyInfo())}
                {recommendedJobs.length > 0 && (
                    <>
                        <Heading size="lg" color={"white"} my={4}>Recommended {selectedJob.industry} Jobs</Heading>
                        <VStack maxH={"500px"} overflowY={"scroll"} spacing={4}>
                            {renderRecommendedJobs()}
                        </VStack>
                    </>
                )}
            </VStack>
            <Flex align="start" p={4}>
                <VStack spacing={4} align={"start"} p={4} boxShadow="lg" borderRadius="md" bg="white" w={"450px"}>
                    {isLoggedIn && renderApplicationStatus()} {!isLoggedIn && (
                        <Flex w={"100%"}>
                            <DirectResumeUpload />
                        </Flex>
                    )}
                    {/* Summary */}
                    <Box w="full">
                        <Heading size="md" mb={4}>Summary</Heading>
                        <HStack justify={"space-between"}>
                            <Text fontWeight="bold">  Payment Details: </Text>
                            <Text>{selectedJob.paymentDetails.qualifier} ${selectedJob.paymentDetails.amount} /{selectedJob.paymentType}</Text>
                        </HStack>
                        <Divider mb={1} />
                        <HStack justify={"space-between"}>
                            <Text fontWeight="bold"> Payment Frequency: </Text>
                            <Text>{selectedJob.paymentFrequency}</Text>
                        </HStack>
                        <Divider mb={1} />
                        <HStack justify={"space-between"}>
                            <Text fontWeight="bold"> Additional Compensation: </Text>
                            <Text>{renderAdditionalCompensation(selectedJob.additionalCompensation)}</Text>
                        </HStack>
                        <Divider mb={1} />
                        <HStack align={"flex-start"} justify={"space-between"}>
                            <Text fontWeight="bold">Perks: </Text>
                            <Text>{selectedJob.additionalPerks}</Text>
                        </HStack>
                        <Divider mb={1} />
                        <HStack justify={"space-between"}>
                            <Text fontWeight="bold">Created: </Text>
                            <Text>{new Date(selectedJob.createdAt).toLocaleDateString()}</Text>
                        </HStack>
                        {/* Ensure no duplicate job type entries */}
                    </Box >
                    <HStack w={"100%"} justify={"space-between"}>
                        <Flex w={"50%"} justify={"flex-start"}>
                            <Heading fontSize={"xl"} color={"black"}>Posts about this job:</Heading>
                        </Flex>

                        <></>
                    </HStack>
                    <Box maxH={"600px"} overflowY={"scroll"} w={"100%"}>
                        <JobLiveFeed key={selectedJob._id} jobId={selectedJob._id} />
                    </Box>
                </VStack>
            </Flex>
        </Flex >
    );
};

export default JobDetails;