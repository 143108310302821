import React, { useState, useEffect } from 'react';
import { Button } from '@chakra-ui/react';
import ConnectModal from './ConnectModal';
import axiosInstance from '../axiosInstance';

const ConnectUserButton = ({ targetUserId }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [connectionStatus, setConnectionStatus] = useState();
    const token = localStorage.getItem('auth_token');
    const [isPending, setIsPending] = useState(false);

    useEffect(() => {
        const checkConnection = async () => {
            try {
                const response = await axiosInstance.get(`/user/check-connection/${targetUserId}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setIsConnected(response.data.connectionExists);
                setConnectionStatus(response.data.connectionStatus); // New state for connection status
            } catch (error) {
                console.error('Error checking connection:', error);
            }
        };

        checkConnection();
    }, [targetUserId, token]);

    const handleSubmit = async (connectionData) => {
        setIsPending(true);
        const requesterUserRole = localStorage.getItem('user_role');
        const { reason, comment } = connectionData;
        const requestData = { targetUserId, reason, comment, requesterUserRole };

        try {
            await axiosInstance.put('/user-connect', requestData, {
                headers: { Authorization: `Bearer ${token}` },
            });
            console.log('Connection request sent successfully');
        } catch (error) {
            console.error('Error in process:', error);
        }

        setModalOpen(false);
    };

    const handleDeleteConnection = async () => {
        try {
            await axiosInstance.delete(`/delete-connection/${targetUserId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            console.log('Connection deleted successfully');
            setIsConnected(false); // Update the state to reflect the deletion
        } catch (error) {
            console.error('Error deleting connection:', error);
        }
    };

    // Define the onClose function
    const handleClose = () => {
        setModalOpen(false);
    };

    return (
        <>
            {
                isConnected ? (
                    connectionStatus === 'approved' ? (
                        <Button bg={"black"} color={"white"} _hover={{ bg: "white", color: "black" }} onClick={handleDeleteConnection}>Disconnect</Button>
                    ) : (
                        <Button bg={"black"} color={"white"} _hover={{ bg: "black", color: "white" }} disabled>Pending...</Button>
                    )
                ) : (
                    <Button
                        bg={isPending ? 'black' : '#01BF02'}
                        color={"white"}
                        _hover={{ bg: "black", color: "white", border: "1px" }}
                        onClick={() => { setModalOpen(true); setIsPending(true); }}
                        disabled={isPending}
                    >
                        {isPending ? 'Pending' : 'Connect'}
                    </Button>

                )}
            {modalOpen && <ConnectModal onSubmit={handleSubmit} onClose={handleClose} />}
        </>

    );
};

export default ConnectUserButton;