import React, { useState, useEffect } from 'react';
import { Box, Text } from '@chakra-ui/react';
import axios from 'axios';
import ConnectionsList from '../../components/ChatModal';

const ConnectionsPage = ({ onTabChange }) => {
    const [connections, setConnections] = useState([]);
    const token = localStorage.getItem('auth_token');

    const fetchConnections = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/api/connections', {
                headers: { Authorization: `Bearer ${token}` },
            });
            const approvedConnections = response.data.connections.filter(conn => conn.status === "approved");
            setConnections(approvedConnections);
        } catch (error) {
            console.error('Error fetching connections:', error);
        }
    };

    useEffect(() => {
        fetchConnections();
    }, [token, onTabChange]);

    return (
        <Box w="100%">
            <Text fontSize={"xl"} fontWeight={"semibold"} color={"white"}>Connections</Text>
            <ConnectionsList connections={connections} />
        </Box>
    );
};


export default ConnectionsPage;
