import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import axiosInstance from '../components/axiosInstance';
import {
    Box, Text, Badge, VStack, Image, Flex, Avatar, IconButton,
    Heading, Stack, Spacer, HStack, Spinner, Button, Input
} from '@chakra-ui/react';
import { TimeIcon } from '@chakra-ui/icons';
import { faUserPlus, faHeart, faHandPointer, faSquareCheck, faFlag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const JobLiveFeed = ({ jobId }) => {
    const [reposts, setReposts] = useState([]);
    const [comments, setComments] = useState({});
    const [currentUser, setCurrentUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isReviewing, setIsReviewing] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [itemsPerPage] = useState(6);
    const navigate = useNavigate();
    const loaderRef = useRef(null);
    const [applicantCounts, setApplicantCounts] = useState({});
    const debounceTimer = useRef(null);

    const fetchReposts = async (signal) => {
        if (!hasMore || isLoading) return;

        setIsLoading(true); 

        try {
            const url = jobId
                ? `${process.env.REACT_APP_API_URL}/api/reposts/job/${jobId}?page=${page}&perPage=${itemsPerPage}`
                : `${process.env.REACT_APP_API_URL}/api/reposts?page=${page}&perPage=${itemsPerPage}`;

            const response = await axios.get(url, { signal }); // Use the AbortController signal here
            if (signal && signal.aborted) return;

            // If page is 1, replace the existing reposts; otherwise, append
            if (page === 1) {
                setReposts(response.data);
            } else {
                setReposts(prevReposts => [...prevReposts, ...response.data]);
            }
            setHasMore(response.data.length === itemsPerPage);
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Fetch cancelled', error);
            } else {
                console.error("Error fetching reposts:", error);
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (debounceTimer.current) {
            clearTimeout(debounceTimer.current); // Clear any existing timer
        }

        debounceTimer.current = setTimeout(() => {
            const controller = new AbortController();

            setPage(1);
            setReposts([]);
            setHasMore(true);
            fetchReposts(controller.signal);

            return () => controller.abort();
        }, 500); // 500 ms debounce time

        return () => {
            clearTimeout(debounceTimer.current); // Clear on unmount/cleanup
        };
    }, [jobId, page]);

    useEffect(() => {
        if (page === 1) {
            const controller = new AbortController();
            fetchReposts(controller.signal);

            return () => controller.abort();
        }
    }, [page]);

    useEffect(() => {
        fetchUserId();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            refreshFeed();
        }, 1200000); // 1200000 milliseconds = 20 minutes

        return () => clearInterval(interval);
    }, []);

    const refreshFeed = async () => {
        setPage(1);
        setReposts([]);
        setHasMore(true);
        await fetchReposts();
    };

    useEffect(() => {

        setPage(prevPage => {
            if (prevPage !== 1) {
                setReposts([]);
                setHasMore(true);
                return 1;
            }
            return prevPage;
        });

    }, [jobId]);

    useEffect(() => {
        if (page === 1) {
            fetchReposts();
        }
    }, [page]);

    const observer = useRef();
    const lastRepostElementRef = useCallback(node => {
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore && !isLoading) { // Check isLoading here
                setPage(prevPage => prevPage + 1);
            }
        }, { threshold: 0.1 });
        if (node) observer.current.observe(node);
    }, [isLoading, hasMore]);

    const fetchUserId = async () => {
        try {
            const authToken = localStorage.getItem('auth_token');
            const response = await axios.get(process.env.REACT_APP_API_URL + '/api/user/details', {
                headers: { 'Authorization': `Bearer ${authToken}` }
            });

            // Assuming the state is named currentUser
            setCurrentUser(response.data.userId);
        } catch (error) {
            console.error('Error fetching user ID:', error);
        }
    };

    const fetchJobApplicants = async (jobId) => {
        try {
            const token = localStorage.getItem('auth_token');

            const applicantResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/getJobApplicants/${jobId}`, {
                headers: { "Authorization": `Bearer ${token}` }
            });
            const totalCount = applicantResponse.data.totalCount;

            setApplicantCounts(prevCounts => ({
                ...prevCounts,
                [jobId]: totalCount
            }));

        } catch (error) {
            console.error('Error fetching applicants:', error);
            // Assuming you have a 'toast' method for showing notifications

        }
    };

    useEffect(() => {
        fetchReposts();
        fetchUserId();
    }, []);

    useEffect(() => {
        reposts.forEach(repost => {
            // Only fetch job applicants for reposts with 'job' content type
            if (repost.contentType === 'job' && repost.jobId) {
                fetchJobApplicants(repost.jobId);
            }
        });
    }, [reposts]);

    useEffect(() => {
        if (page > 1) {
            fetchReposts();
        }
    }, [page]);

    const goToUserProfile = (userId) => {
        navigate(`/user/${userId}`);
    };

    const goToJobDetails = async (jobId, userId, role) => {
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/api/jobSeekers/${userId}/referrals`, {
                jobId,
                userId,
                role
            });

            navigate(`/job/${jobId}/${userId}`);
        } catch (error) {
            console.error('Error creating referral:', error);
            // Handle error (e.g., show a notification to the user)
        }
    };

    const handleLike = async (repostId) => {
        // Optimistic update
        setReposts(prevReposts => prevReposts.map(repost =>
            repost._id === repostId
                ? { ...repost, likes: [...repost.likes, { userId: currentUser }] }
                : repost
        ));

        try {
            await axiosInstance.post(`posts/${repostId}/like`);
        } catch (error) {
            console.error('Error liking repost:', error);
            // Revert optimistic update if error occurs
            setReposts(prevReposts => prevReposts.map(repost =>
                repost._id === repostId
                    ? { ...repost, likes: repost.likes.filter(like => like.userId !== currentUser) }
                    : repost
            ));
        }
    };

    const handleModerateRepost = async (repostId) => {
        setIsReviewing(true);
        try {
            // Assuming authToken is stored in localStorage or fetched from context/store
            const authToken = localStorage.getItem('auth_token');
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/moderate/${repostId}`, {}, {
                headers: { 'Authorization': `Bearer ${authToken}` }
            });

            // Handle the response from the server
            console.log(response.data.message); // log the message or use it in UI
            // TODO: Add logic here to update UI based on moderation response if necessary
        } catch (error) {
            console.error('Error moderating repost:', error);
            // TODO: Add error handling logic
        }
        await refreshRepost(repostId);
        setIsReviewing(false);
    };

    const handleUnlike = async (repostId) => {
        // Optimistic update
        setReposts(prevReposts => prevReposts.map(repost =>
            repost._id === repostId
                ? { ...repost, likes: repost.likes.filter(like => like.userId !== currentUser) }
                : repost
        ));

        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/posts/${repostId}/unlike`);
        } catch (error) {
            console.error('Error unliking repost:', error);
            // Revert optimistic update if error occurs
            setReposts(prevReposts => prevReposts.map(repost =>
                repost._id === repostId
                    ? { ...repost, likes: [...repost.likes, { userId: currentUser }] }
                    : repost
            ));
        }
    };

    const handleClick = async (repostId) => {
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/api/posts/${repostId}/click`);
            setReposts(prevReposts =>
                prevReposts.map(repost =>
                    repost._id === repostId ? { ...repost, clicks: repost.clicks + 1 } : repost
                )
            );
        } catch (error) {
            console.error('Error recording click:', error);
            // Handle error (e.g., show a notification to the user)
        }
    };

    const refreshRepost = async (repostId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/posts/${repostId}`);
            const updatedRepost = response.data;

            setReposts(prevReposts => prevReposts.map(repost =>
                repost._id === repostId ? updatedRepost : repost
            ));
        } catch (error) {
            console.error(`Error refreshing repost with id ${repostId}:`, error);
        }
    };

    const handleCommentChange = (repostId, event) => {
        setComments({ ...comments, [repostId]: event.target.value });
    };

    const submitComment = async (repostId) => {
        const commentText = comments[repostId];
        if (!commentText) return; // Prevent empty comments

        const role = localStorage.getItem('user_role');
        try {
            const authToken = localStorage.getItem('auth_token');
            await axios.post(`${process.env.REACT_APP_API_URL}/api/posts/${repostId}/comment`, {
                comment: commentText,
                role,
            }, {
                headers: { 'Authorization': `Bearer ${authToken}` }
            });
            setComments({ ...comments, [repostId]: '' }); // Clear the comment input
            await refreshRepost(repostId);
        } catch (error) {
            console.error('Error submitting comment:', error);
        }
    };

    return (
        <Flex direction="column" maxH="100vh" w="100%" align="center" justify="start">
            <VStack w={"100%"} overflowY={"scroll"} align="center" maxW={"800px"}>
                <Box maxH={"100%"}
                    overflowY={"scroll"}>
                    {reposts.length > 0 ? (
                        reposts.map((repost, index) => (
                            <Box
                                ref={index === reposts.length - 1 ? lastRepostElementRef : null}
                                borderRadius={"xl"}
                                bg={"whiteSmoke"}
                                key={repost._id}
                                p={4}
                                mb={3}
                                shadow="lg"
                                borderWidth="1px"
                                w="full"
                                maxW={"600px"}
                            >
                                {/* Profile and Time Section */}
                                <Stack direction="row" spacing={2} align="center">
                                    {/* Profile Picture and Name */}
                                    <HStack>
                                        {/* Profile Picture */}
                                        {repost.profilePic ? (
                                            <Image
                                                borderRadius="full"
                                                border={"1px"}
                                                shadow={"dark-lg"}
                                                maxW={"50px"}
                                                maxH={"50px"}
                                                src={repost.profilePic}
                                                alt={`Profile image of ${repost.name}`}
                                            />
                                        ) : (
                                            <Avatar bg={"#118AB2"} size={"sm"} name={repost.name || "User"} />
                                        )}
                                        {/* User Name */}
                                        <Heading
                                            as="button"
                                            size={"sm"}
                                            onClick={() => goToUserProfile(repost.userId)}
                                            _hover={{ textDecoration: 'underline' }}
                                        >
                                            {repost.name}
                                        </Heading>
                                    </HStack>
                                    <Spacer />
                                    {/* Time */}
                                    <Text as="time" fontSize="sm">
                                        <TimeIcon mr="2" />
                                        {new Date(repost.repostDate).toLocaleTimeString()}
                                    </Text>
                                    {repost.moderationInfo.determination === 'Good' ? (
                                        <>
                                            <FontAwesomeIcon size='xl' color='#01BF02' icon={faSquareCheck} />
                                        </>
                                    ) : (
                                        <IconButton
                                            aria-label="Moderate Repost"
                                            size={"sm"}
                                            isLoading={isReviewing}
                                            icon={<FontAwesomeIcon icon={faFlag} />}
                                            onClick={() => handleModerateRepost(repost._id)}
                                            _hover={{ bg: "#EF476F", color: "white" }}

                                        />
                                    )}
                                </Stack>

                                {/* Content based on Type */}
                                {repost.contentType === 'job' ? (
                                    // Job Repost Content
                                    <>
                                        <Text mt={4} fontSize="md">{repost.comment}</Text>
                                        <Box
                                            p={3}
                                            mt={3}
                                            bg={"blackAlpha.200"}
                                            borderRadius={"xl"}
                                            cursor="pointer"
                                            _hover={{ bg: "blackAlpha.300" }}
                                            onClick={(e) => {
                                                handleClick(repost._id);
                                                goToJobDetails(repost.jobId, repost.userId, repost.role);
                                                e.stopPropagation();
                                            }}
                                        >
                                            {/* Job Details */}
                                            <HStack>
                                                {repost.jobBadge && (
                                                    <Badge colorScheme={repost.jobBadge.colorScheme} variant={repost.jobBadge.variant}>
                                                        {repost.jobBadge.name}
                                                    </Badge>
                                                )}
                                                <Heading size="lg">{repost.jobTitle}</Heading>
                                            </HStack>
                                            <HStack>
                                                <Text>{repost.industry} | {repost.workLocationType} | {repost.employmentType}</Text>
                                            </HStack>
                                            {repost.image && (
                                                <Flex w={"100%"} justify={"center"}>
                                                    <Image borderRadius={"lg"} mt={4} src={repost.image} alt="Job Image" style={{ maxWidth: '90%', maxHeight: '500px' }} />
                                                </Flex>
                                            )}
                                        </Box>
                                    </>
                                ) : (
                                    // Original Content
                                    <Flex w={"100%"} justify={"center"} mt={4}>
                                        <Box w={"100%"}>
                                            <Text mt={4} mb={4} fontSize="md">{repost.originalContent}</Text>
                                            <Flex w={"100%"} justify={"center"}>
                                                {repost.originalContentImage && (
                                                    <Image shadow={"lg"} mb={4} borderRadius={"3xl"} src={repost.originalContentImage} alt="Original Content Image" style={{ maxWidth: '90%', maxHeight: '500px' }} />
                                                )}
                                            </Flex>

                                            {repost.comments && repost.comments.length > 0 && (
                                                <Box borderRadius={"lg"} bg={'blackAlpha.100'} maxW={"100%"} maxH={"300px"} overflowY={"scroll"}>
                                                    {repost.comments && repost.comments.map((comment, index) => (
                                                        <Box w={"fit-content"} m={1} p={2} borderRadius={"lg"} bg={"blackAlpha.500"} shadow={"lg"} key={index}>
                                                            <HStack align={"start"} justify={"start"} >
                                                                {/* Profile Picture */}
                                                                {comment.profilePic ? (
                                                                    <Image
                                                                        borderRadius="full"
                                                                        border="1px"
                                                                        shadow="md"
                                                                        maxW="35px"
                                                                        maxH="35px"
                                                                        src={comment.profilePic}
                                                                        alt={`Profile image of ${comment.name}`}
                                                                    />
                                                                ) : (
                                                                    <Avatar bg={"#01BF02"} size="sm" name={comment.name || "User"} />
                                                                )}

                                                                {/* Comment Content */}
                                                                <Flex w={"fit-content"} justify={"flex-start"} align={"flex-end"}>
                                                                    <VStack w={"fit-content"} align="start">
                                                                        <Heading
                                                                            as="button"
                                                                            color={"white"}
                                                                            size="sm"
                                                                            onClick={() => goToUserProfile(comment.userId)}
                                                                            _hover={{ textDecoration: 'underline' }}
                                                                        >
                                                                            {comment.name}
                                                                        </Heading>
                                                                        <Text color={"white"}>{comment.comment}</Text>
                                                                    </VStack>
                                                                </Flex>
                                                            </HStack>

                                                        </Box>
                                                    ))}
                                                </Box>
                                            )}
                                        </Box>
                                    </Flex>
                                )}

                                {/* Updated Interactions Bar */}
                                <Stack justify={"flex-end"} direction="row" mt="4" align="center">
                                    {repost.contentType === 'job' && (
                                        <Flex justify={"center"} align={"center"} p={2} w={"fit-content"} borderRadius={"md"} >
                                            <FontAwesomeIcon icon={faHandPointer} size="sm" />
                                            <Text ml={2}>{repost.clicks} clicks</Text>
                                        </Flex>
                                    )}
                                    {repost.contentType === 'job' && (
                                        <Flex justify={"center"} align={"center"} p={2} w={"fit-content"} borderRadius={"md"}>
                                            <FontAwesomeIcon icon={faUserPlus} size="sm" />
                                            <Text ml={2}>
                                                {applicantCounts[repost.jobId] ? applicantCounts[repost.jobId] : 0} applicants
                                            </Text>
                                        </Flex>
                                    )}
                                    {repost.contentType === 'original' && (
                                        <Flex w={"90%"}>
                                            <Input
                                                value={comments[repost._id] || ''}
                                                bg={"white"}
                                                onChange={(e) => handleCommentChange(repost._id, e)}
                                                placeholder="Add a comment"
                                                _hover={{ boxShadow: "md", borderColor: "teal" }}
                                                _focus={{ outline: "teal", boxShadow: "xl" }}
                                            // ... other props
                                            />
                                            <Button
                                                ml={2}
                                                bg={"#118AB2"}
                                                color={"white"}
                                                _hover={{ bg: "#01BF02" }}
                                                _active={{ bg: "teal.700" }}
                                                onClick={() => submitComment(repost._id)}
                                            >
                                                Post
                                            </Button>
                                        </Flex>
                                    )}
                                    <Flex
                                        justify={"center"}
                                        align={"center"}
                                        p={2}
                                        w={"fit-content"}
                                        _hover={{ boxShadow: "lg", bg: "white" }}
                                        borderRadius={"md"}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            const alreadyLiked = repost.likes.some(like => like.userId === currentUser);
                                            if (alreadyLiked) {
                                                handleUnlike(repost._id);
                                            } else {
                                                handleLike(repost._id);
                                            }
                                        }}
                                    >

                                        <FontAwesomeIcon
                                            icon={faHeart}
                                            size="sm"
                                            style={{ color: repost.likes.some(like => like.userId === currentUser) ? "#01BF02" : "gray" }}
                                        />
                                        <Text ml={2}>{repost.likes.length}</Text>
                                    </Flex>
                                </Stack>
                            </Box>
                        ))
                    ) : (
                        // If there are no reposts, show a call to action
                        <Box p={4} align="center">
                            <Text>No reposts yet. Be the first to share this job!</Text>
                        </Box>
                    )}
                </Box>
                <div ref={loaderRef}>
                    {isLoading && <Spinner mt={8} mb={8} size={"xl"} color='black' />}
                </div>
            </VStack>
        </Flex>
    );
};

export default JobLiveFeed;