// TalentSignUp.js
import React from 'react';
import {
    Box,
    Flex,
    HStack,
    Heading,
} from '@chakra-ui/react';
import Background from '../../assets/Background.webp';

import ManualResumeUpload from '../../components/ManualResumeUpload';

const AddTalent = () => {
    return (
        <Flex
            direction="column"
            align="center"
            minH="100vh"
            width="100%"
            backgroundImage={Background}
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
        >
            <Box mt={8} mb={4}>
                    <Heading color={"white"}>Add Talent</Heading>
                </Box>
            <HStack mt={"8"} justify={"center"} align={"flex-start"} w={"30%"} p={"4"} spacing={4}>
                <Box
                    p={4}
                    w="100%"
                    maxW="900px"
                    borderWidth={0}
                    borderRadius="lg"
                    boxShadow="lg"
                    backgroundColor="whiteAlpha.800"
                >
                    <ManualResumeUpload />
                </Box>
            </HStack>
        </Flex>
    );
};

export default AddTalent;