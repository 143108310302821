// UserSignUp.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box, Button, FormControl, FormLabel, Input, VStack, useToast, Flex, Image, Text, Link, FormErrorMessage, InputGroup, InputRightElement, List, ListItem,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEye, faEyeSlash, faPaperPlane, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Background from '../../assets/Background.webp';
import Logo from '../../assets/whitelogo.png';

export const UserSignUp = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRequirements, setPasswordRequirements] = useState({
        length: false,
        letter: false,
        number: false,
        specialChar: false,
    });
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [emailVerified, setEmailVerified] = useState(false);
    const [verificationSent, setVerificationSent] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const navigate = useNavigate();
    const toast = useToast();

    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return re.test(String(email).toLowerCase());
    };

    const validatePassword = (password) => {
        const length = password.length >= 8;
        const letter = /[a-zA-Z]/.test(password);
        const number = /[0-9]/.test(password);
        const specialChar = /[@$!%*?&]/.test(password);

        setPasswordRequirements({
            length,
            letter,
            number,
            specialChar,
        });

        return length && letter && number && specialChar;
    };

    const handleSendVerificationEmail = async () => {
        setVerificationSent(true)
        // Send a request to your backend to send a verification code to the provided email
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/email`, { email });
            if (response.status === 200) {
                toast({
                    title: 'Verification email sent.',
                    description: 'Please check your email for the verification code.',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            } else {
                throw new Error('Failed to send verification email.');
            }
        } catch (error) {
            toast({
                title: 'Error sending verification email.',
                description: error.toString(),
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleVerifyEmail = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/verify`, {
                email, code: verificationCode
            });
            if (response.data.message === "Email verification successful") {
                setEmailVerified(true);
                setVerificationSent(false);
                toast({
                    title: 'Email verified successfully.',
                    description: 'You can now create your account.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                // Optionally, you can clear the verification code state here
                setVerificationCode('');
            } else {
                // Handle any message that's not a successful verification
                throw new Error('Verification failed. Please try again.');
            }
        } catch (error) {
            toast({
                title: 'Verification error.',
                description: error.response?.data?.message || error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const isEmailValid = validateEmail(email);
        const isPasswordValid = validatePassword(password);

        if (!isEmailValid) {
            setEmailError(true);
            return;
        }

        if (!isPasswordValid) {
            setPasswordError(true);
            return;
        }

        try {
            setIsLoading(true)

            const lowercaseEmail = email.toLowerCase();
            // First, create the user account
            await axios.post(`${process.env.REACT_APP_API_URL}/api/user-signup`, {
                lowercaseEmail, password, firstName, lastName,
            });

            toast({
                title: 'Account created successfully.',
                description: 'You can now log in with your new account.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });

            navigate('/user-login');

        } catch (error) {
            toast({
                title: 'An error occurred.',
                description: error.response?.data?.error || error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
        setIsLoading(false)
    };

    return (
        <Flex
            direction="column"
            justify="center"
            align="center"
            height="100vh"
            width="100%"
            backgroundImage={Background}
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
        >
            <Box
                p={8}
                maxW="800px"
                borderWidth={3}
                borderRadius="xl"
                boxShadow="xl"
                backgroundColor="blackAlpha.700"
                borderColor={"whiteAlpha.800"}
            >
                <VStack spacing={4} align="center" mb={6}>
                    <Image src={Logo} alt="Your Logo" w="150px" />
                    <Text fontSize="2xl" fontWeight="bold" color="white">Sign Up</Text>
                </VStack>
                <form onSubmit={handleSubmit}>
                    <VStack spacing={4}>
                        <FormControl id="firstName" isRequired>
                            <FormLabel color={"white"}>First Name</FormLabel>
                            <Input
                                type="text"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                bg="white"
                                color="black"
                            />
                        </FormControl>
                        <FormControl id="lastName" isRequired>
                            <FormLabel color={"white"}>Last Name</FormLabel>
                            <Input
                                type="text"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                bg="white"
                                color="black"
                            />
                        </FormControl>
                        <FormControl id="email" isRequired isInvalid={emailError}>
                            <FormLabel color={"white"}>Email</FormLabel>
                            <InputGroup>
                                <Input
                                    type="email"
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        setEmailError(!validateEmail(e.target.value));
                                    }}
                                    bg="white"
                                    color="black"
                                    isReadOnly={emailVerified} // Make email field read-only if verified
                                />
                                <InputRightElement children={
                                    emailVerified ? <FontAwesomeIcon icon={faSquareCheck} color="green" /> :
                                        <Button _hover={{ bg: "#01bf02", color: "white" }} variant="ghost" size="sm" onClick={handleSendVerificationEmail}><FontAwesomeIcon icon={faPaperPlane} /></Button>
                                } />

                            </InputGroup>
                            {emailError && <FormErrorMessage>Email is invalid.</FormErrorMessage>}
                        </FormControl>
                        {verificationSent && (
                            <FormControl id="verificationCode" isRequired>
                                <FormLabel color={"white"}>Verification Code</FormLabel>
                                <Input
                                    type="text"
                                    value={verificationCode}
                                    onChange={(e) => setVerificationCode(e.target.value)}
                                    bg="white"
                                    color="black"
                                />
                                <Flex w={"100%"} justify={"flex-end"}>
                                    <Button size={"xs"} mt={2} onClick={handleVerifyEmail}>Verify Email</Button>
                                </Flex>
                            </FormControl>
                        )}
                        <FormControl id="password" isRequired isInvalid={passwordError}>
                            <FormLabel color={"white"}>Password</FormLabel>
                            <InputGroup>
                                <Input
                                    type={showPassword ? "text" : "password"}
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                        setPasswordError(!validatePassword(e.target.value));
                                    }}
                                    bg="white"
                                    color="black"
                                />
                                <InputRightElement>
                                    <Button _hover={{ bg: "#01bf02", color: "white" }} variant="ghost" size="sm" onClick={() => setShowPassword(!showPassword)}>
                                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                            <Flex mt={2}>
                                <Text>
                                    <List>
                                        <ListItem color="white">Password Requirements:
                                        </ListItem>
                                        <ListItem color={passwordRequirements.length ? "green.500" : "whiteAlpha.600"}>
                                            {passwordRequirements.length ? <FontAwesomeIcon icon={faCheck} /> : null} 8 Characters
                                        </ListItem>
                                        <ListItem color={passwordRequirements.letter ? "green.500" : "whiteAlpha.600"}>
                                            {passwordRequirements.letter ? <FontAwesomeIcon icon={faCheck} /> : null} Text
                                        </ListItem>
                                        <ListItem color={passwordRequirements.number ? "green.500" : "whiteAlpha.600"}>
                                            {passwordRequirements.number ? <FontAwesomeIcon icon={faCheck} /> : null} 1+ numbers
                                        </ListItem>
                                        <ListItem color={passwordRequirements.specialChar ? "green.500" : "whiteAlpha.600"}>
                                            {passwordRequirements.specialChar ? <FontAwesomeIcon icon={faCheck} /> : null} Special Character
                                        </ListItem>
                                    </List>
                                </Text>
                            </Flex>
                        </FormControl>
                        {emailVerified && (
                            <Button
                                isLoading={isLoading}
                                loadingText="Logging in..."
                                type="submit"
                                variant="ghost"
                                color="white"
                                size="lg"
                                fontSize="md"
                                width="full"
                                _hover={{ bg: "#01bf02", color: "white" }}
                            >
                                Create Account
                            </Button>
                        )}
                    </VStack>
                </form>
                <Text color={"whiteAlpha.800"} mt={6}>
                    Already have an account?{" "}
                    <Link color="#01bf02" href="/user-login">
                        Log in
                    </Link>
                </Text>
            </Box>
        </Flex>
    );
};
