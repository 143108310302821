import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartSimple, faPeopleGroup, faSackDollar, faStar as faSolidStar, faStarHalfAlt, faStar as faRegularStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarEmpty } from '@fortawesome/free-regular-svg-icons';
import {
    Box,
    Text,
    SimpleGrid,
    Stat,
    StatLabel,
    StatNumber,
    Flex,
    VStack,
    StatGroup,
    useColorModeValue,
    Button,
    Icon,
    IconButton,
    HStack,
    Heading,
    Spacer,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel
} from '@chakra-ui/react';
import Background from '../../assets/Background.webp';
import axiosInstance from '../../components/axiosInstance';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, LabelList } from 'recharts';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can log the error to an error reporting service
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}


const mockCandidateFeedback = [
    { id: 1, feedback: "The application process was straightforward and easy." },
    { id: 2, feedback: "I appreciated the timely updates on my application status." },
    // ...additional feedback
];

// Add mock data for Quality of Hire
const mockQualityOfHire = {
    metric1: "Performance Score: 8.5/10",
    metric2: "1-Year Retention: 85%"
    // ...additional metrics
};

// Add mock data for Retention Rates
const mockRetentionRates = {
    '6-month': "75%",
    '1-year': "60%",
    // ...additional rates
};

// Add mock data for Employer Branding Effectiveness
const mockEmployerBranding = {
    awareness: "High",
    perception: "Positive",
    // ...additional branding data
};

const ApplicationStatusBreakdown = ({ breakdown }) => {
    if (!breakdown) {
        // Return null or some placeholder if breakdown is undefined or null
        return <Text>No status breakdown available.</Text>;
    }

    return (
        <StatGroup>
            {Object.entries(breakdown).map(([status, count], index) => (
                <Stat m={1} key={index} p={2} borderWidth="1px" shadow={"md"} rounded="md">
                    <StatLabel>{status.charAt(0).toUpperCase() + status.slice(1)}</StatLabel>
                    <StatNumber>{count}</StatNumber>
                </Stat>
            ))}
        </StatGroup>
    );
};

const getJobDetails = async (jobIds, token) => {
    try {
        const jobDetailsResponse = await axiosInstance.post('/jobs/details', { jobIds }, {
            headers: { Authorization: `Bearer ${token}` }
        });
        console.log('Job details response:', jobDetailsResponse.data);
        return jobDetailsResponse.data.jobs;
    } catch (error) {
        console.error('Error fetching job details:', error);
        // Handle the error appropriately
        // You might want to throw the error or return a default value
        throw error;
    }
};

const ApplicationOverview = ({ setAverageTimeToHire, setAverageCosts }) => {
    const [jobApplications, setJobApplications] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const token = localStorage.getItem('auth_token');
    const navigate = useNavigate();

    const handleViewApplicants = (jobId) => {
        navigate(`/applicants/${jobId}`);
    };

    useEffect(() => {
        fetchData();
    }, [token]);

    const timeSince = (date) => {
        if (!(date instanceof Date) || isNaN(date)) {
            return "Date not available";
        }

        const seconds = Math.floor((new Date() - date) / 1000);
        let interval = seconds / 31536000;

        if (interval > 1) {
            return Math.floor(interval) + " year" + (Math.floor(interval) > 1 ? "s" : "");
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + " month" + (Math.floor(interval) > 1 ? "s" : "");
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + " day" + (Math.floor(interval) > 1 ? "s" : "");
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + " hour" + (Math.floor(interval) > 1 ? "s" : "");
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + " minute" + (Math.floor(interval) > 1 ? "s" : "");
        }
        return Math.floor(seconds) + " second" + (Math.floor(seconds) > 1 ? "s" : "");
    };

    const calculateAverageCosts = (jobsDetails) => {
        // Initialize sumCosts with all keys set to 0, including 'cost' for totalCost
        const initialCosts = jobsDetails.reduce((acc, job) => {
            Object.keys(job.recruitmentCosts).forEach(key => {
                acc[key] = 0;
            });
            acc.cost = 0; // This corresponds to the total cost
            return acc;
        }, {});

        // Accumulate each cost in sumCosts
        const sumCosts = jobsDetails.reduce((acc, job) => {
            Object.keys(job.recruitmentCosts).forEach(key => {
                acc[key] += Number(job.recruitmentCosts[key]) || 0;
            });
            acc.cost += Number(job.cost) || 0; // Accumulate the 'cost' field here
            return acc;
        }, initialCosts);

        // Calculate the average for each cost
        const averageCosts = Object.keys(sumCosts).reduce((acc, key) => {
            acc[key] = (sumCosts[key] / jobsDetails.length).toFixed(2);
            return acc;
        }, {});

        return averageCosts;
    };

    const calculateAverageTimeToHire = (jobsDetails) => {
        // Filter jobs to include only those with a completedAt date
        const completedJobs = jobsDetails.filter(job => job.completedAt);
        console.log(`Completed jobs`, completedJobs);

        if (completedJobs.length === 0) {
            console.log('No completed jobs to calculate average time to hire.');
            return null; // No completed jobs to calculate average
        }

        const totalHiringTime = completedJobs.reduce((acc, job) => {
            const createdAt = new Date(job.createdAt);
            const completedAt = new Date(job.completedAt);
            if (!isNaN(createdAt.getTime()) && !isNaN(completedAt.getTime())) {
                return acc + (completedAt - createdAt) / (1000 * 60 * 60 * 24); // Convert to days
            } else {
                return acc; // If dates are invalid, do not add to the total
            }
        }, 0);

        console.log(`Total Hiring time is ${totalHiringTime}`);

        const averageTimeToHire = (totalHiringTime / completedJobs.length).toFixed(1);
        console.log(`Average Time to Hire based on ${completedJobs.length} completed jobs: ${averageTimeToHire}`);
        return averageTimeToHire;
    };

    const fetchData = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const summaryResponse = await axiosInstance.get('/reporting/all-applicants', {
                headers: { Authorization: `Bearer ${token}` }
            });
            const jobSummaries = summaryResponse.data.jobSummaries;
            console.log('Jobs Summaries:', jobSummaries);

            // Extract all job IDs from the response
            const jobIds = jobSummaries.map(summary => summary._id.toString());

            // Fetch details for all jobs
            const jobsDetails = await getJobDetails(jobIds, token);
            console.log('Jobs Details:', jobsDetails);

            // Combine job summaries with their respective details
            const combinedData = combineData(jobSummaries, jobsDetails);
            setJobApplications(combinedData);

            // Calculate average time to hire
            const averageTime = calculateAverageTimeToHire(jobsDetails);
            setAverageTimeToHire(averageTime);
        } catch (error) {
            setError('Failed to fetch data: ' + error.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (jobApplications.length > 0) {
            const averageCosts = calculateAverageCosts(jobApplications);
            setAverageCosts(averageCosts);
        }
    }, [jobApplications]);

    const combineData = (summaries, jobsDetails) => {
        console.log('Combined Data:', combineData);
        const jobIdToDetailsMap = jobsDetails.reduce((acc, job) => {
            acc[job._id] = job;
            return acc;
        }, {});

        return summaries.map(summary => {
            const jobDetails = jobIdToDetailsMap[summary._id];
            return {
                _id: summary._id,
                jobTitle: jobDetails.jobTitle,
                createdAt: jobDetails.createdAt,
                views: jobDetails.views,
                cost: jobDetails.totalCost,
                recruitmentCosts: {
                    advertisingFees: jobDetails.recruitmentCosts?.advertisingFees,
                    recruiterFees: jobDetails.recruitmentCosts?.recruiterFees,
                    travelCosts: jobDetails.recruitmentCosts?.travelCosts,
                    internalRecruiterSalary: jobDetails.recruitmentCosts?.internalRecruiterSalary,
                    softwareCosts: jobDetails.recruitmentCosts?.softwareCosts,
                    referralBonuses: jobDetails.recruitmentCosts?.referralBonuses,
                    miscellaneousCosts: jobDetails.recruitmentCosts?.miscellaneousCosts,
                },
                completedAt: jobDetails.completedAt,
                statusBreakdown: {
                    applied: summary.totalApplicants,
                    revealed: summary.totalRevealed,
                    rejected: summary.rejected,
                    shortlisted: summary.shortlisted,
                    interviewed: summary.interviewed,
                    offered: summary.offered,
                    hired: summary.hired
                },
                applicantFeedback: jobDetails.applicantFeedback
            };
        });
    };

    const StarRating = ({ rating }) => {
        // Ensure rating is a number and within the bounds of 0 to 5
        const sanitizedRating = Math.min(Math.max(0, parseFloat(rating)), 5);

        const fullStars = Math.floor(sanitizedRating);
        const halfStar = sanitizedRating % 1 >= 0.5 ? 1 : 0;
        const emptyStars = 5 - fullStars - halfStar;

        // Generate the star elements safely
        const starElements = [];
        for (let i = 0; i < fullStars; i++) {
            starElements.push(<FontAwesomeIcon key={`full-${i}`} icon={faSolidStar} color="#FFD166" />);
        }
        if (halfStar) {
            starElements.push(<FontAwesomeIcon key={`half-1`} icon={faStarHalfAlt} color="#FFD166" />);
        }
        for (let i = 0; i < emptyStars; i++) {
            starElements.push(<FontAwesomeIcon key={`empty-${i}`} icon={faStarEmpty} color="lightgray" />);
        }

        return (
            <HStack>
                {starElements}
            </HStack>
        );
    };

    const getAverageRating = (feedback) => {
        const total = feedback.reduce((acc, item) => acc + item.rating, 0);
        return (total / feedback.length).toFixed(1);
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <Box borderRadius={"lg"} p={3} bg={"black"} shadow="md">
            <Heading color={"white"} mb={4}>Reporting Dashboard</Heading>
            <SimpleGrid p={4} maxH={"650px"} overflowY={"scroll"} columns={{ base: 1, sm: 2, md: 3, lg: 4 }} spacing={10}>
                {jobApplications.map((job, index) => (
                    <Box shadow={"md"} borderRadius={"md"} p={3} bg={"white"} key={index}>
                        <HStack mb={2} justify={"space-between"}>
                            <Text
                                fontWeight={"semibold"}
                                fontSize="lg"
                                isTruncated
                                maxWidth="100%" // Adjust as needed
                            >
                                {job.jobTitle}
                            </Text>
                            <IconButton
                                aria-label="View Applicants"
                                size={"sm"}
                                color={"white"}
                                bg={"#118AB2"}
                                _hover={{ bg: "#01BF02" }}
                                icon={<FontAwesomeIcon icon={faPeopleGroup} />}
                                onClick={() => handleViewApplicants(job._id)}
                            />
                        </HStack>
                        <ApplicationStatusBreakdown breakdown={job.statusBreakdown} />
                        <Flex mb={2} mt={2} w={"100%"} align={"flex-start"} justify={"space-between"}>
                            <Flex justify={"space-between"}>
                                <Text mr={4}>
                                    <FontAwesomeIcon icon={faChartSimple} /> Views: {job.views}
                                </Text>
                                {job.cost > 0 && (
                                    <Flex align={"center"} ml={4}>
                                        <FontAwesomeIcon icon={faSackDollar} />
                                        <Text ml={1}>Costs: ${job.cost}</Text>
                                    </Flex>
                                )}
                            </Flex>
                            <Text fontSize="sm">Created: {timeSince(new Date(job.createdAt))} ago</Text>
                        </Flex>
                        <Accordion allowToggle>
                            <AccordionItem>
                                <AccordionButton m={2} borderRadius={"md"} _expanded={{ bg: '#118AB2', color: 'white' }}>
                                    <HStack as="span" flex='1' textAlign='left'>
                                        <Text>Applicant Feedback: </Text><StarRating rating={getAverageRating(job.applicantFeedback)}
                                        />
                                    </HStack>
                                    <AccordionIcon />
                                </AccordionButton>

                                <AccordionPanel>
                                    {(job.applicantFeedback && job.applicantFeedback.length > 0) ? (
                                        job.applicantFeedback.map((feedback, index) => (
                                            <Box m={2} shadow={"md"} borderRadius={"md"} p={2} bg={"whiteSmoke"} key={index}>
                                                <StarRating rating={feedback.rating} />
                                                <Text>{feedback.comment}</Text>
                                            </Box>
                                        ))
                                    ) : (
                                        <Text>No feedback available.</Text>
                                    )}
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </Box>
                ))}
            </SimpleGrid>
        </Box>
    );
};

const TimeToHire = ({ averageTimeToHire, averageTimeToHireEntries }) => {
    const [companyData, setCompanyData] = useState(null);
    const [comparisonData, setComparisonData] = useState([]);
    const [renderChart, setRenderChart] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchAndCompareCompanyData();
    }, []);

    useEffect(() => {
        if (!companyData) return;

        let formattedData = [];
        if (companyData.averageTimeToHireEntries) {
            formattedData = companyData.averageTimeToHireEntries.map(entry => ({
                date: new Date(entry.calculatedAt.$date).toLocaleDateString(),
                averageDays: entry.averageDays,
            }));
        } else if (averageTimeToHireEntries) {
            formattedData = averageTimeToHireEntries.map(entry => ({
                date: new Date(entry.calculatedAt).toLocaleDateString('en-US'),
                averageDays: entry.averageDays,
            }));
        }
        setComparisonData(formattedData);
    }, [companyData, averageTimeToHireEntries]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setRenderChart(true);
        }, 500); // Adjust the timeout as needed

        // Clear the timer when the component unmounts
        return () => clearTimeout(timer);
    }, []);

    const fetchAndCompareCompanyData = async () => {
        try {
            const response = await axiosInstance.get('/company');
            const data = response.data;
            setCompanyData(data);

            const { averageTimeToHire, averageTimeToHireEntries } = data;

            // Comparing each entry with the lifetime average
            averageTimeToHireEntries.forEach(entry => {
                const difference = (entry.averageDays - averageTimeToHire).toFixed(2);
                console.log(`Entry ${entry._id.$oid} is ${difference} days ${difference > 0 ? 'longer' : 'shorter'} than the lifetime average (${averageTimeToHire} days).`);
            });

            // Comparing each entry with every other entry
            for (let i = 0; i < averageTimeToHireEntries.length; i++) {
                for (let j = i + 1; j < averageTimeToHireEntries.length; j++) {
                    const difference = (averageTimeToHireEntries[i].averageDays - averageTimeToHireEntries[j].averageDays).toFixed(2);
                    console.log(`Entry ${averageTimeToHireEntries[i]._id.$oid} is ${difference} days ${difference > 0 ? 'longer' : 'shorter'} than Entry ${averageTimeToHireEntries[j]._id.$oid}.`);
                }
            }
        } catch (error) {
            console.error('Error fetching company data:', error);
        }
    };

    const CustomLabel = (props) => {
        const { x, y, stroke, value, index } = props;
        // Access your data for each entry
        const date = comparisonData[index].date;

        return (
            <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
                {`${date}: ${value}`}
            </text>
        );
    };

    // Then use it in your LineChart
    // <LabelList dataKey="averageDays" content={<CustomLabel />} />

    if (loading) return <Text>Loading...</Text>;
    if (error) return <Text>Error: {error}</Text>;


    // Render the component
    return (
        <Box borderRadius={"lg"} p={5} bg={"white"} shadow="md" borderWidth="1px">
            <HStack justifyContent="flex-start" mb={4}>
                <Text fontSize="lg">Average Job Fill Time:</Text>
                <Text fontWeight={"semibold"} fontSize="lg">
                    {averageTimeToHire ? `${averageTimeToHire} days` : 'Calculating...'}
                </Text>
            </HStack>

            {/* Chart for Visualization */}
            <Box style={{ height: 310, width: '100%' }}>
                {renderChart && (
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart margin={{
                            top: 5,
                            right: 30,
                            left: 5,
                            bottom: 5,
                        }} data={comparisonData}>
                            <Line
                                type="monotone"
                                dataKey="averageDays"
                                stroke="#8884d8"
                                // Add dot to make the points visible
                                dot={{ stroke: '#8884d8', strokeWidth: 2, fill: '#8884d8' }}
                            >
                                <LabelList dataKey="averageDays" position="top" offset={10} />
                            </Line>
                            <CartesianGrid stroke="#ccc" />
                            <XAxis />
                            <YAxis />
                            <Tooltip />
                        </LineChart>
                    </ResponsiveContainer>
                )}
                <Flex mb={4} w={"100%"} justify={"flex-end"}>
                    <Text fontSize="md">*30 day ticks</Text>
                </Flex>
            </Box>
        </Box>
    );
};

const CostPerHire = ({ averageCosts }) => {
    if (!averageCosts) {
        // Return null or a placeholder if averageCosts is undefined or null
        return <Text>No average costs data available.</Text>;
    }

    // Function to capitalize each word for display
    const capitalizeWords = (str) =>
        str.replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());

    // Format cost names for display
    const formatCostName = (costName) => {
        return capitalizeWords(costName.replace(/([A-Z])/g, ' $1').replace(/([0-9]+)/g, ' $1').trim());
    };

    return (
        <Box
            h={"fit-content"}
            borderRadius={"lg"}
            p={5}
            bg={"white"}
            shadow="xl"
            borderWidth="1px"
        >
            <Text fontSize="xl" mb={6} fontWeight="bold" textAlign="center">
                Average Cost Per Job
            </Text>
            <SimpleGrid columns={{ sm: 2, md: 3, lg: 4 }} spacing={5}>
                {Object.entries(averageCosts).map(([key, value]) => {
                    // Rename the 'cost' key to 'Total Cost' for display purposes
                    const name = key === 'cost' ? 'Total Cost' : key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1');
                    return (
                        <Stat
                            key={key}
                            p={4}
                            borderWidth="1px"
                            rounded="md"
                            bg="white"
                            shadow="md"
                            _hover={{ bg: "gray.50", cursor: "pointer" }}
                            transition="background 0.3s"
                        >
                            <StatLabel fontWeight="medium">{name}</StatLabel>
                            <StatNumber fontSize="md" fontWeight="semibold">
                                ${parseFloat(value).toLocaleString()}
                            </StatNumber>
                        </Stat>
                    );
                })}
            </SimpleGrid>
        </Box>
    );    
};

const QualityAndRetention = () => {
    const bgColor = useColorModeValue("gray.100", "gray.700"); // Dark mode support
    return (
        <Box borderRadius="lg" p={5} bg={bgColor} shadow="md" borderWidth="1px">
            <Box w={"100%"} spacing={4}>
                <Box>
                    <Text fontSize="lg">Quality of Hire</Text>
                    <Text fontSize="md">{mockQualityOfHire.metric1}</Text>
                    <Text fontSize="md">{mockQualityOfHire.metric2}</Text>
                    {/* Add additional metrics as needed */}
                </Box>
                <Box>
                    <Text fontSize="lg">Retention Rates</Text>
                    <Text fontSize="md">6-month: {mockRetentionRates['6-month']}</Text>
                    <Text fontSize="md">1-year: {mockRetentionRates['1-year']}</Text>
                    {/* Add additional rates as needed */}
                </Box>
            </Box>
        </Box>
    );
};

const EmployerBrandingEffectiveness = () => (
    <Box borderRadius={"lg"} p={5} bg={"gray.100"} shadow="md" borderWidth="1px">
        <Text fontSize="lg">Employer Branding Effectiveness</Text>
        <Text fontSize="md">Awareness: {mockEmployerBranding.awareness}</Text>
        <Text fontSize="md">Perception: {mockEmployerBranding.perception}</Text>
        {/* Add additional branding insights as needed */}
    </Box>
);

const Dashboard = () => {
    const [averageTimeToHire, setAverageTimeToHire] = useState(null);
    const [averageCosts, setAverageCosts] = useState({});
    const [companyData] = useState(null);

    useEffect(() => {
        if (companyData && companyData.averageTimeToHireEntries) {
            // Calculate the averageTimeToHire based on all entries, or get the latest, etc.
            const lastEntry = companyData.averageTimeToHireEntries[companyData.averageTimeToHireEntries.length - 1];
            setAverageTimeToHire(lastEntry.averageDays);
        }
    }, [companyData]);

    const columnTemplate = {
        base: 1,
        lg: 2,

    };

    return (
        <Flex direction="column" h="100vh" w="100%">
            <Box
                position="fixed"
                top={0}
                left={0}
                right={0}
                bottom={0}
                bgImage={Background}
                bgSize="cover"
                bgRepeat="no-repeat"
                bgPosition="center"
                zIndex={-1}
            />
            <Flex flex="1" w="100%" pl="300px">
                <Flex direction="column" p={8} w="100%" overflowY="scroll">
                    <Box flex="1" ml={4}>
                        <ApplicationOverview setAverageTimeToHire={setAverageTimeToHire} setAverageCosts={setAverageCosts} />
                        <SimpleGrid columns={columnTemplate} spacing={10} mt={10}>
                            <ErrorBoundary>
                                <TimeToHire
                                    averageTimeToHire={averageTimeToHire}
                                    averageTimeToHireEntries={companyData?.averageTimeToHireEntries || []}
                                />
                            </ErrorBoundary>
                            <CostPerHire averageCosts={averageCosts} />
                        </SimpleGrid>
                    </Box>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default Dashboard;

// <QualityAndRetention />
// <EmployerBrandingEffectiveness />