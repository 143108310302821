import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
    Box, VStack, Heading, Text, useToast, Flex,
    Avatar, Wrap, Grid, Image, Link, HStack
} from '@chakra-ui/react';
import ProfileLiveFeed from '../../components/ProfileLiveFeed';
import ConnectButton from '../../components/Connections/ConnectButton';
import Header from '../../components/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faLinkedinIn, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

function getIcon(platform) {
    if (!platform) return null; // Return null or a default icon if platform is falsy

    switch (platform.toLowerCase()) {
        case 'twitter':
            return faXTwitter;
        case 'facebook':
            return faFacebookF;
        case 'linkedin':
            return faLinkedinIn;
        // Add more cases for other social platforms
        default:
            return null; // or a default icon
    }
}

// Helper function to format the portfolio URL
function formatUrl(url) {
    if (!url) return '';

    // Trim any leading whitespace and slashes
    url = url.trim();

    // Check if the URL starts with 'http://' or 'https://'
    if (!/^https?:\/\//i.test(url)) {
        // Check if it starts with 'www.', prepend 'https://' if it does
        if (/^www\./i.test(url)) {
            url = 'https://' + url;
        } else {
            // Otherwise, prepend 'https://www.' to make it a complete URL
            url = 'https://www.' + url;
        }
    }

    return url;
}

const Profile = () => {
    const { jobSeekerId } = useParams();
    const [jobSeeker, setJobSeeker] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const toast = useToast();

    useEffect(() => {
        const fetchJobSeeker = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/profile/${jobSeekerId}`);
                setJobSeeker(response.data);
            } catch (error) {
                toast({
                    title: 'Error',
                    description: 'Could not fetch job seeker information.',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            } finally {
                setIsLoading(false);
            }
        };

        fetchJobSeeker();

    }, [jobSeekerId, toast]);


    if (isLoading) {
        return <Text>Loading profile...</Text>;
    }

    if (!jobSeeker) {
        return <Text>Job seeker profile not found.</Text>;
    }

    return (
        <Flex
            direction="column"
            minH="100vh"
            w="full"
            bg={"grey"}
        >
            <Header />
            <VStack p={4} align="stretch" spacing={4}>
                {/* Profile and Live Feed Grid */}
                <Grid overflowY={"auto"} templateColumns={{ md: "3fr 2fr", sm: "1fr" }} gap={6}>
                    <VStack h={"fit-content"} p={4} bg={"black"} shadow={"lg"} borderRadius={"xl"} align="start" spacing={4}>
                        {/* Profile Header */}
                        <Flex align="center" gap={4}>
                            {jobSeeker && jobSeeker.PFP ? (
                                <Image
                                    borderRadius="full"

                                    maxW={"100px"}
                                    shadow={"dark-lg"}
                                    src={jobSeeker.PFP}
                                    alt={`Profile image of ${jobSeeker.name}`}
                                    mr={4}
                                />
                            ) : (
                                <Avatar
                                    name={jobSeeker.name}
                                    size={"xl"}
                                    bg={"#01BF02"}
                                    mr={4}
                                />
                            )}
                            <HStack>
                                <VStack align="start">
                                    <Heading color="white">{jobSeeker.name}</Heading>
                                    <Text color="white">{jobSeeker.availability}</Text>
                                    <ConnectButton targetUserId={jobSeekerId} />
                                </VStack>
                                {jobSeeker.socialProfiles && jobSeeker.socialProfiles.length > 0 && (
                                    <Wrap>
                                        {jobSeeker.socialProfiles.map((social, index) => (
                                            <Link key={index} href={social.url} isExternal p={2} borderRadius="md">
                                                <Flex align="center">
                                                    <FontAwesomeIcon icon={getIcon(social.platform)} size="lg" color="white" />
                                                </Flex>
                                            </Link>
                                        ))}
                                    </Wrap>
                                )}
                                {jobSeeker.portfolioURL && (
                                    <Link href={formatUrl(jobSeeker.portfolioURL)} isExternal p={2} borderRadius="md">
                                        <Flex align="center">
                                            <FontAwesomeIcon icon={faGlobe} size="lg" color="white" />
                                        </Flex>
                                    </Link>
                                )}
                            </HStack>
                        </Flex>
                        {/* Summary */}
                        <Text bg="white" borderRadius="md" p={4} shadow="md" borderWidth="1px">
                            {jobSeeker.candidateSummary}
                        </Text>
                        {/* Work History */}
                        <Heading color="white" size="lg">Work History</Heading>
                        <Wrap>
                            {jobSeeker.workHistory.map((work, index) => (
                                <Box key={index} bg="white" borderRadius="md" p={4} shadow="md" borderWidth="1px">
                                    <Text fontWeight="bold">{work.title}</Text>
                                    <Text>{work.company}</Text>
                                    <Text>{work.dates}</Text>
                                    {/* ... */}
                                </Box>
                            ))}
                        </Wrap>
                        {/* Education */}

                        <Heading color="white" size="lg">Education</Heading>
                        <Wrap>
                            {jobSeeker.education.map((edu, index) => (
                                <Box key={index} bg="white" borderRadius="md" p={4} shadow="md" borderWidth="1px">
                                    <Text fontWeight="bold">{edu.degree}</Text>
                                    <Text>{edu.fieldOfStudy}</Text>
                                    <Text>{edu.institution}</Text>
                                    {/* ... */}
                                </Box>
                            ))}
                        </Wrap>

                        {jobSeeker.endorsements.length && (
                            <>
                                {/* Endorsements */}
                                <Heading color="white" size="lg">Endorsements</Heading>
                                <Wrap>
                                    {jobSeeker.endorsements.map((endorsement, index) => (
                                        <Box key={index} bg="white" borderRadius="md" p={4} shadow="md" borderWidth="1px">
                                            <Text fontWeight="bold">{endorsement.endorsedBy}</Text>
                                            <Text>{endorsement.endorsement}</Text>
                                        </Box>
                                    ))}
                                </Wrap>
                            </>
                        )}

                        {jobSeeker.awards.length && (
                            <>
                                {/* Awards */}
                                <Heading color="white" size="lg" mt={4}>Awards</Heading>
                                <Wrap>
                                    {jobSeeker.awards.map((award, index) => (
                                        <Box key={index} bg="white" borderRadius="md" p={4} shadow="md" borderWidth="1px">
                                            <Text fontWeight="bold">{award.title}</Text>
                                            <Text>{award.awardedBy}</Text>
                                            <Text>{new Date(award.dateAwarded).toLocaleDateString()}</Text>
                                        </Box>
                                    ))}
                                </Wrap>
                            </>
                        )}

                        {jobSeeker.publications.length && (
                            <>
                                {/* Publications */}
                                <Heading color="white" size="lg" mt={4}>Publications</Heading>
                                <Wrap>
                                    {jobSeeker.publications.map((publication, index) => (
                                        <Box key={index} bg="white" borderRadius="md" p={4} shadow="md" borderWidth="1px">
                                            <Text fontWeight="bold">{publication.title}</Text>
                                            <Text>{publication.publishedBy}</Text>
                                            <Text>{new Date(publication.publicationDate).toLocaleDateString()}</Text>
                                            {publication.url && <Link href={publication.url} isExternal textDecoration="underline">View Publication</Link>}
                                        </Box>
                                    ))}
                                </Wrap>
                            </>
                        )}
                        {/* Skills */}
                        <Heading color="white" size="lg">Skills</Heading>
                        <Wrap>
                            {jobSeeker.skillLevels.map((skill, index) => (
                                <Box key={index} bg="white" p={4} shadow="md" borderWidth="1px" borderRadius="xl">
                                    <Text fontWeight="bold">{skill.skill} <Text fontWeight={"thin"}>({skill.level})</Text></Text>
                                </Box>
                            ))}
                        </Wrap>

                        {/* ... Additional sections */}
                    </VStack>
                    {/* Live Feed Section */}
                    <Box
                        position="sticky"
                        top="10" // This sets the top position when it starts sticking.
                        shadow={"lg"}
                        bg="black"
                        mb={"10"}
                        p={4}
                        borderRadius="xl"
                        h={"fit-content"}
                        overflow="hidden"
                        zIndex={10} // Ensure it stays above other content.
                    >
                        <ProfileLiveFeed jobSeekerId={jobSeekerId} />
                    </Box>
                </Grid>
            </VStack >
        </Flex >
    );
};

export default Profile;