import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
    Box, VStack, Heading, Text, useToast, Flex,
    Avatar, Wrap, Grid, Image, Link, HStack, Tag, Skeleton, Container, Divider
} from '@chakra-ui/react';
import ProfileLiveFeed from '../../components/ProfileLiveFeed';
import ConnectButton from '../../components/Connections/ConnectButton';
import Header from '../../components/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faLinkedinIn, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import ConnectUserButton from '../../components/Connections/ConnectUseButton';

function getIcon(platform) {
    if (!platform) return null;

    switch (platform.toLowerCase()) {
        case 'twitter':
            return faXTwitter;
        case 'facebook':
            return faFacebookF;
        case 'linkedin':
            return faLinkedinIn;
        default:
            return null;
    }
}

function formatUrl(url) {
    if (!url) return '';

    url = url.trim();

    if (!/^https?:\/\//i.test(url)) {
        if (/^www\./i.test(url)) {
            url = 'https://' + url;
        } else {
            url = 'https://www.' + url;
        }
    }

    return url;
}

const PublicProfile = () => {
    const { userId } = useParams();
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const toast = useToast();

    useEffect(() => {
        const fetchUser = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/public-profile/${userId}`);
                setUser(response.data);
            } catch (error) {
                toast({
                    title: 'Error',
                    description: 'Could not fetch user information.',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            } finally {
                setIsLoading(false);
            }
        };

        fetchUser();

    }, [userId, toast]);


    if (isLoading) {
        return (
            <Container maxW="container.lg" p={8}>
                <Skeleton height="200px" mb={6} />
                <Skeleton height="20px" mb={4} />
                <Skeleton height="20px" mb={4} />
                <Skeleton height="20px" mb={4} />
            </Container>
        );
    }

    if (!user) {
        return <Text>Profile not found.</Text>;
    }

    return (
        <Flex direction="column" minH="100vh" w="full" bg="gray.50">
            <Header />
            <Container maxW="50%" p={8}>
                <Box p={8} bg="white" shadow="md" borderRadius="lg">
                    <Flex align="center" mb={6}>
                        {user && user.PFP ? (
                            <Image
                                borderRadius="full"
                                w={"100px"}
                                src={user.PFP}
                                alt={`Profile image of ${user.firstName}`}
                                mr={6}
                            />
                        ) : (
                            <Avatar
                                name={user.firstName}
                                size="2xl"
                                bg="blue.500"
                                mr={6}
                            />
                        )}
                        <Box>
                            <Heading size="xl" mb={2}>{user.firstName} {user.lastName}</Heading>
                            <Text fontSize="lg" color="gray.600" mb={4}>{user.availability}</Text>
                            <HStack>
                                <ConnectUserButton targetUserId={userId} />
                                {user.socialProfiles?.map((social, index) => (
                                    <Link key={index} href={social.url} isExternal>
                                        <FontAwesomeIcon icon={getIcon(social.platform)} size="lg" color="gray.600" />
                                    </Link>
                                ))}
                                {user.portfolioURL && (
                                    <Link href={formatUrl(user.portfolioURL)} isExternal>
                                        <FontAwesomeIcon icon={faGlobe} size="lg" color="gray.600" />
                                    </Link>
                                )}
                            </HStack>
                        </Box>
                    </Flex>
                    <Text fontSize="lg">{user.jobSeekerDetails.candidateSummary}</Text>
                </Box>

                <Grid templateColumns={{ md: "2fr 1fr", base: "1fr" }} gap={8} mt={8}>
                    <VStack align="stretch" spacing={8}>
                        <Box p={6} bg="white" shadow="md" borderRadius="lg">
                            <Heading size="lg" mb={4}>Work History</Heading>
                            {user.jobSeekerDetails.workHistory.map((work, index) => (
                                <Box key={index} mb={6}>
                                    <Heading size="md">{work.title}</Heading>
                                    <Text fontSize="lg">{work.company}</Text>
                                    <Text color="gray.600">{work.dates}</Text>
                                    {index !== user.jobSeekerDetails.workHistory.length - 1 && <Divider my={4} />}
                                </Box>
                            ))}
                        </Box>

                        <Box p={6} bg="white" shadow="md" borderRadius="lg">
                            <Heading size="lg" mb={4}>Education</Heading>
                            {user.jobSeekerDetails.education.map((edu, index) => (
                                <Box key={index} mb={6}>
                                    <Heading size="md">{edu.degree}</Heading>
                                    <Text fontSize="lg">{edu.fieldOfStudy}</Text>
                                    <Text color="gray.600">{edu.institution}</Text>
                                    {index !== user.jobSeekerDetails.education.length - 1 && <Divider my={4} />}
                                </Box>
                            ))}
                        </Box>

                        {user.jobSeekerDetails.skillLevels.length > 0 && (
                            <Box p={6} bg="white" shadow="md" borderRadius="lg">
                                <Heading size="lg" mb={4}>Skills</Heading>
                                <Wrap>
                                    {user.jobSeekerDetails.skillLevels.map((skill, index) => {
                                        const formattedLevel = {
                                            'no_experience': 'No Experience',
                                            'learning': 'Learning',
                                            'competent': 'Competent',
                                            'proficient': 'Proficient',
                                            'authority': 'Authority'
                                        }[skill.level] || skill.level;

                                        return (
                                            <Tag key={index} size="lg" colorScheme="blue">
                                                {skill.skill} ({formattedLevel})
                                            </Tag>
                                        );
                                    })}
                                </Wrap>
                            </Box>
                        )}

                        {user.jobSeekerDetails.endorsements.length > 0 && (
                            <Box p={6} bg="white" shadow="md" borderRadius="lg">
                                <Heading size="lg" mb={4}>Endorsements</Heading>
                                {user.jobSeekerDetails.endorsements.map((endorsement, index) => (
                                    <Box key={index} mb={4}>
                                        <Text fontSize="lg" fontWeight="bold">{endorsement.endorsedBy}</Text>
                                        <Text>{endorsement.endorsement}</Text>
                                        {index !== user.jobSeekerDetails.endorsements.length - 1 && <Divider my={4} />}
                                    </Box>
                                ))}
                            </Box>
                        )}

                        {user.jobSeekerDetails.awards.length > 0 && (
                            <Box p={6} bg="white" shadow="md" borderRadius="lg">
                                <Heading size="lg" mb={4}>Awards</Heading>
                                <VStack align="stretch">
                                    {user.jobSeekerDetails.awards.map((award, index) => (
                                        <Box key={index} p={4} borderWidth="1px" borderRadius="md">
                                            <Heading size="md">{award.title}</Heading>
                                            <Text>{award.awardedBy}</Text>
                                            <Text color="gray.600">{new Date(award.dateAwarded).toLocaleDateString()}</Text>
                                        </Box>
                                    ))}
                                </VStack>
                            </Box>
                        )}

                        {user.jobSeekerDetails.publications.length > 0 && (
                            <Box p={6} bg="white" shadow="md" borderRadius="lg">
                                <Heading size="lg" mb={4}>Publications</Heading>
                                <VStack align="stretch">
                                    {user.jobSeekerDetails.publications.map((publication, index) => (
                                        <Box key={index} p={4} borderWidth="1px" borderRadius="md">
                                            <Heading size="md">{publication.title}</Heading>
                                            <Text>{publication.publishedBy}</Text>
                                            <Text color="gray.600">{new Date(publication.publicationDate).toLocaleDateString()}</Text>
                                            {publication.url && <Link href={publication.url} isExternal color="blue.500">View Publication</Link>}
                                        </Box>
                                    ))}
                                </VStack>
                            </Box>
                        )}
                    </VStack>

                    <Box w={"100%"} position="sticky" top={8}>
                        <ProfileLiveFeed userId={userId} />
                    </Box>
                </Grid>
            </Container>
        </Flex>
    );
};

export default PublicProfile;