import React, { useState } from 'react';
import { Box, Input, Tag, TagLabel, TagCloseButton } from '@chakra-ui/react';

const SearchTags = ({ onTagsChange }) => {
    const [tagInput, setTagInput] = useState('');
    const [tags, setTags] = useState([]);

    const handleAddTag = () => {
        if (tagInput && !tags.includes(tagInput)) {
            const newTags = [...tags, tagInput];
            setTags(newTags);
            onTagsChange(newTags);
            setTagInput('');
        }
    };

    const handleRemoveTag = (tagToRemove) => {
        const newTags = tags.filter(tag => tag !== tagToRemove);
        setTags(newTags);
        onTagsChange(newTags);
    };

    return (
        <Box w={"100%"}>
            <Input 
                placeholder="Add a tag and press enter..."
                value={tagInput}
                shadow={"lg"}
                bg={"white"}
                onChange={(e) => setTagInput(e.target.value)}
                onKeyUp={(e) => e.key === 'Enter' && handleAddTag()}
            />
            <Box mt={2}>
                {tags.map(tag => (
                    <Tag size="lg" key={tag} mb={2} mr={2} borderRadius="full" variant="solid" color="white" bg="#01bf02" >
                        <TagLabel>{tag}</TagLabel>
                        <TagCloseButton color={"black"} onClick={() => handleRemoveTag(tag)} />
                    </Tag>
                ))}
            </Box>
        </Box>
    );
};

export default SearchTags;