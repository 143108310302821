import React, { useState } from 'react';
import axiosInstance from './axiosInstance';
import { useNavigate } from 'react-router-dom';
import {
    Box, Text, Button, Stat, StatLabel, StatNumber, useToast,
    ListItem, UnorderedList, Flex, VStack, SimpleGrid, Icon, Heading, Spacer, Center, HStack
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong, faAsterisk, faCalendarDay, faCodeCompare, faEnvelopeCircleCheck, faRankingStar } from '@fortawesome/free-solid-svg-icons';
import { FaUserTie, FaPaperPlane, FaRegChartBar, FaDatabase, FaBriefcase } from 'react-icons/fa';
import { faSearchengin } from '@fortawesome/free-brands-svg-icons';

const subscriptionLevelDisplayNames = {
    basic: 'Basic',
    premium: 'Premium',
    free: 'Free'
};

const SmartMatchRecruiter = ({ jobSeeker }) => {
    const [subscriptionLevel, setSubscriptionLevel] = useState(jobSeeker?.subscriptionLevel || 'free');
    const [jobMatchingStats, setJobMatchingStats] = useState(jobSeeker?.jobMatchingStats || {});
    const [processingDay] = useState(jobSeeker?.processingDay || null);
    const token = localStorage.getItem('auth_token');
    const navigate = useNavigate();
    const toast = useToast();

    const handleUpgradeClick = async (newLevel) => {
        console.log(`Upgrading to ${newLevel}...`);

        // Define the price IDs for your Stripe products
        const priceIds = {
            'basic': 'price_1OUKnDEDfGoNCpuz32NKGcT8',

            'premium': 'price_1OUKnTEDfGoNCpuzR4yBZP4P',
        };

        // Get the price ID for the selected subscription level
        const priceId = priceIds[newLevel];

        if (!priceId) {
            console.error('Invalid subscription level');
            return;
        }

        try {
            // Call your backend to create the subscription
            const subscriptionResponse = await axiosInstance.post('talent/create-subscription', {
                priceId: priceId,
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });

            if (subscriptionResponse.status === 200) {
                console.log('Subscription created successfully:', subscriptionResponse.data);

                // Update job seeker's subscription level and job matching stats in your local state
                setSubscriptionLevel(newLevel);

                // Update the subscription level in the job seeker's profile
                const profileResponse = await axiosInstance.put(`/smartmatchrecruiter/`, {
                    subscriptionLevel: newLevel,
                }, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                if (profileResponse.status === 200) {
                    console.log('User profile updated successfully:', profileResponse.data);
                    setJobMatchingStats(profileResponse.data.jobMatchingStats);
                } else {
                    console.error('Failed to update user profile');
                }
            } else {
                console.error('Failed to create subscription');
            }
        } catch (error) {
            console.error('Error during subscription process:', error);
            if (error.response && error.response.status === 402) {
                toast({
                    title: "Action needed",
                    description: "Please add a card",
                    status: "warning",
                    duration: 9000,
                    isClosable: true,
                    position: "bottom",
                });
            } else {
                toast({
                    title: "Error",
                    description: "An error occurred. Please try again.",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                    position: "bottom",
                });
            }
        }
    };

    console.log("Emailed Job IDs:", jobMatchingStats.emailedJobIds);

    return (
        <Box>
            {subscriptionLevel === 'free' && (
                <>
                    <Heading color={"white"}>SmartMatch AI Recruiter</Heading>
                    <Box shadow={"dark-lg"} bg={"white"} p={4} borderWidth="1px" borderRadius="md" overflow="hidden">
                        {/*
                    <HStack justify={"space-evenly"} mb={4} w={"100%"} >
                        <Flex align={"center"} justify={"flex-start"}>
                            <FontAwesomeIcon size='lg' icon={faRankingStar} />
                            <Text m={2}>Selected Option:</Text>
                            <Text fontWeight={"bold"} fontSize={"lg"}>{subscriptionLevelDisplayNames[subscriptionLevel]}</Text>
                            {subscriptionLevel === 'basic' && (
                            <Button _hover={{color: 'white', bg: '#01BF02', transform: "scale(1.05)" }} m={2} size={"xs"} onClick={() => handleUpgradeClick('premium')}>Upgrade to Premium</Button>
                            )}
                        </Flex>
                        {subscriptionLevel === 'basic' && (
                            <Flex align={"center"} justify={"flex-start"}>
                                <FontAwesomeIcon icon={faCalendarDay} />
                                <Text m={2}>Processing Day:</Text>
                                <Text fontWeight={"bold"} fontSize={"lg"}>{processingDay}</Text>
                            </Flex>
                        )}
                        </HStack>*/}
                        <SimpleGrid columns={{ sm: 1, md: 2, lg: 4 }} spacing={8}>
                            <Stat textAlign="center">
                                <Icon as={FaRegChartBar} w={8} h={8} color="#01BF02" />
                                <Text>Last Time We Looked</Text>
                                <StatNumber fontSize="xl">
                                    {jobMatchingStats?.lastScanDate
                                        ? new Date(jobMatchingStats.lastScanDate).toLocaleDateString()
                                        : 'N/A'}
                                </StatNumber>
                            </Stat>

                            <Stat textAlign="center">
                                <Icon as={FaPaperPlane} w={8} h={8} color="#EF476F" />
                                <Text>Employers Emailed</Text>
                                <StatNumber fontSize="xl">{jobMatchingStats?.jobsEmailed ?? 'N/A'}</StatNumber>
                            </Stat>

                            <Stat textAlign="center">
                                <Icon as={FaUserTie} w={8} h={8} color="#118AB2" />
                                <Text>Deep AI Scans</Text>
                                <StatNumber fontSize="xl">{jobMatchingStats?.jobsMeetingAIThreshold ?? 'N/A'}</StatNumber>
                            </Stat>

                            <Stat textAlign="center">
                                <Icon as={FaDatabase} w={8} h={8} color="#FFD166" />
                                <Text>Total Scanned Jobs</Text>
                                <StatNumber fontSize="xl">{jobMatchingStats?.totalScannedJobs ?? 'N/A'}</StatNumber>
                            </Stat>
                        </SimpleGrid>
                        {jobMatchingStats?.emailedJobs && jobMatchingStats.emailedJobs.length > 0 && (
                            <VStack maxH={"400px"} overflowY={"scroll"} mt={4} spacing={4} align="stretch">
                                <Text fontSize="lg" fontWeight="semibold">Introductions Sent For:</Text>
                                <SimpleGrid maxH={"300px"} overflowY={"scroll"} columns={{ sm: 1, md: 2, lg: 4 }} spacing={4}>
                                    {jobMatchingStats.emailedJobs.map((job) => (
                                        <Box key={job.jobId} p={3} borderWidth="1px" borderRadius="md" boxShadow="lg" bg={"white"}
                                            _hover={{ bg: '#01BF02', color: 'white', cursor: 'pointer' }}
                                            onClick={() => navigate(`/job/${job.jobId}`)}>
                                            <Flex w={"fit-content"} align="center">
                                                <Icon as={FaBriefcase} mr={2} w={5} h={5} color="black" />
                                                <Text fontWeight="medium">{job.jobTitle}</Text>
                                            </Flex>
                                        </Box>
                                    ))}
                                </SimpleGrid>
                            </VStack>
                        )}
                    </Box>
                </>
            )}

            {/* Show upgrade options if on 'free' subscription */}
            {subscriptionLevel !== 'free' && (
                <Box bg="black" p={4} borderWidth="1px" borderRadius="2xl" overflow="hidden">
                    <Text fontSize="xl" mb={3} color="white" fontWeight="normal">
                        Sign up with JobJar's SmartMatch Recruiter, and take the work out of job searching. We'll find top jobs and set up your interviews.
                    </Text>
                    <Flex mb={3} p={3} w={"100%"} align={"center"} justify={"space-evenly"}>
                        <FontAwesomeIcon color='#01BF02' size='2xl' icon={faSearchengin} />
                        <FontAwesomeIcon color='white' icon={faArrowRightLong} />
                        <FontAwesomeIcon color='#01BF02' size='2xl' icon={faCodeCompare} />
                        <FontAwesomeIcon color='white' icon={faArrowRightLong} />
                        <FontAwesomeIcon color='#01BF02' size='2xl' icon={faEnvelopeCircleCheck} />
                    </Flex>
                    <Flex direction={{ base: "column", md: "row" }} w="100%" justify="space-between">
                        <Box mr={2} mb={3} p={5} shadow="md" bg="white" borderWidth="1px" flex="1" borderRadius="md" display="flex" flexDirection="column">
                            <Heading mb={4}>$5/month</Heading>
                            <Text fontSize="lg" mb={2} fontWeight="bold">Steady (Basic)</Text>
                            <UnorderedList mb={3}>
                                <ListItem>Our AI seamlessly matches you with ideal jobs and initiates employer connections—effortlessly, every week.</ListItem>
                            </UnorderedList>
                            <Spacer />
                            <Button mt="auto" bg="#118AB2" color="white" _hover={{ transform: "scale(1.05)" }} w="full" size="lg" onClick={() => handleUpgradeClick('basic')}>
                                Start with Basic
                            </Button>
                        </Box>

                        <Box ml={2} mb={3} p={5} shadow="md" bg="white" borderWidth="1px" flex="1" borderRadius="md">
                            <Heading mb={4}>$28/month</Heading>
                            <Text fontSize="lg" mb={2} fontWeight="bold">Aggressive (Premium)</Text>
                            <UnorderedList mb={3}>
                                <ListItem>Daily AI-powered scans ensure you're the first to know about the latest job openings, giving you a competitive edge in your job search.</ListItem>
                            </UnorderedList>
                            <Spacer />
                            <Button mt="auto" bg="#FFD166" color="black" _hover={{ transform: "scale(1.05)" }} w="full" size="lg" onClick={() => handleUpgradeClick('premium')}>
                                Go Premium
                            </Button>
                        </Box>
                    </Flex>
                </Box>
            )}
        </Box>
    );
};

export default SmartMatchRecruiter;