// SignUp.js
import React, { useEffect, useState } from 'react';
import {
    Box,
    Text,
    FormControl,
    FormLabel,
    Select,
    Button,
    Flex,
    VStack,
    HStack,
    Input,
    InputGroup,
    FormHelperText,
    useToast,
    Divider,
    Checkbox,
    Radio,
    RadioGroup,
    Link as ChakraLink,
    Center,
    InputLeftElement,
    Tag,
    TagCloseButton,
    TagLabel,
    Stack,
    Spinner,
    Grid,
    SimpleGrid
} from '@chakra-ui/react';
import RoleSection from '../../components/RoleSelection';
import WelcomeMessage from '../../components/WelcomeMessage';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import RecruiterTagSection from '../../components/RecruiterTagSection';
import ResumeUpload from '../../components/ResumeUpload';
import { handleAddPoints } from '../../components/Points';
import SkillsInput from '../../components/SkillInput';
import IndustryExperienceInput from '../../components/IndustryExperienceInput';
import Background from '../../assets/Background.webp';
import Logo from '../../assets/whitelogo.png';

const SignUp = () => {
    const [step, setStep] = useState(0);
    const [role, setRole] = useState(null);
    const [formData, setFormData] = useState({
        education: [],
        skills: [],
        workHistory: [],
        industryExperience: [],
        certifications: [],
        licenses: [],
        softwareExperience: [],
        languages: [],
        hobbies: [],
        socialProfiles: [],
        securityClearance: [],
        careerTraining: [],
        endorsements: [],
        awards: [],
        publications: [],
        permission: 'Admin',
        resumePath: "",
    });
    const [passwordError, setPasswordError] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [verificationToken, setVerificationToken] = useState('');
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [showVerifyToken, setShowVerifyToken] = useState(false);
    const [verificationSuccess, setVerificationSuccess] = useState(false);
    const [showLicenseField, setShowLicenseField] = useState(false);
    const [agencyStatus, setAgencyStatus] = useState('Independent');
    const [savedResume, setSavedResume] = useState(null);
    const [idealJobs, setIdealJobs] = useState([]);
    const [newJobTag, setNewJobTag] = useState('');
    const [earningsType, setEarningsType] = useState('hourly');


    useEffect(() => {
        if (savedResume) {
            console.log('useEffect triggered with savedResume:', savedResume);
            setFormData(prevFormData => ({
                ...prevFormData,
                ...Object.keys(savedResume).reduce((acc, key) => {
                    acc[key] = savedResume[key] || '';
                    return acc;
                }, {}),
            }));
        }
    }, [savedResume]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const path = name.split(/[\[\].]+/).filter(Boolean);
        setFormData((prevState) => {
            const newState = { ...prevState };

            let currentLevel = newState; // Start at the root level of the state

            // Iterate over each part of the path except for the last one
            for (let i = 0; i < path.length - 1; i++) {
                const part = path[i];
                const nextPart = path[i + 1];

                if (!isNaN(parseInt(nextPart, 10))) { // If the next part is an index, we're dealing with an array
                    if (!currentLevel[part]) {
                        currentLevel[part] = []; // Ensure the array exists
                    }
                    if (!currentLevel[part][nextPart]) {
                        currentLevel[part][nextPart] = {}; // Ensure the object at the index exists
                    }
                    currentLevel = currentLevel[part]; // Move our reference down one level
                } else if (i === path.length - 2) { // If we're at the second to last part, and it's not an array index, it's an object
                    if (!currentLevel[part]) {
                        currentLevel[part] = {}; // Ensure the object exists
                    }
                    currentLevel = currentLevel[part]; // Move our reference down one level
                }
            }

            // Handle the final part of the path, which is the field we're updating
            const lastPart = path[path.length - 1];
            currentLevel[lastPart] = value;

            return newState;
        });
    };

    const handleAddEducation = () => {
        setFormData(prevState => ({
            ...prevState,
            education: [
                ...prevState.education,
                { institution: '', degree: '', fieldOfStudy: '', startDate: '', endDate: '', description: '' },
            ],
        }));
    };

    const navigate = useNavigate();

    const handleRemoveHobby = (index) => {
        const updatedHobbies = [...formData.hobbies];
        updatedHobbies.splice(index, 1);
        setFormData({ ...formData, hobbies: updatedHobbies });
    };

    const handleAddHobby = () => {
        setFormData({
            ...formData,
            hobbies: [...formData.hobbies, ''], // Add an empty string for a new hobby
        });
    };

    const [setIndustries] = useState([]);

    const toast = useToast();

    // Fetch industries on component mount
    useEffect(() => {
        const fetchIndustries = async () => {
            try {
                const res = await axios.get(process.env.REACT_APP_API_URL + '/api/industries/search');
                setIndustries(res.data);
            } catch (error) {
                console.error('An error occurred while fetching data:', error);
            }
        };

        fetchIndustries();
    }, []);

    const validatePasswords = () => {
        if (formData.password !== formData.confirmPassword) {
            setPasswordError('Passwords do not match');
        } else {
            setPasswordError(null);
        }
    };

    const handleEarningsTypeChange = (value) => {
        setEarningsType(value);
    };

    useEffect(() => {
        // This effect will run whenever formData.confirmPassword changes
        if (formData.confirmPassword) {
            validatePasswords();
        }
    }, [formData.confirmPassword]);

    const sendVerificationEmail = async () => {
        setIsLoading(true);
        // Log data being sent
        console.log({
            email: formData.email,
            password: formData.password
        });

        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/api/email', {
                email: formData.email,
                password: formData.password
            }, {
                headers: { 'Content-Type': 'application/json' }
            });

            if (response.status === 200) {
                console.log('Email sent for verification:', response);
                setShowVerifyToken(true); // Set showVerifyToken to true when the button is clicked
                // Optionally, show a message to the user
            } else {
                console.log('Failed to send email:', response);
                // Optionally, show an error message to the user
            }
        } catch (error) {
            console.log('Error:', error);
            // Optionally, show an error message to the user
        }
        setIsLoading(false);
    };

    const verifyEmail = async () => {
        console.log("Token submit function triggered");
        console.log("Verification token in frontend:", verificationToken);  // Add this log

        try {
            console.log("Sending Token:", verificationToken);
            const response = await axios.post(process.env.REACT_APP_API_URL + '/api/verify', { email: formData.email, code: verificationToken });
            console.log("Response from token verification:", response.data);

            if (response.data.message === "Email verification successful") {
                setVerificationSuccess(true);
                setIsEmailVerified(true);
            }
        } catch (error) {
            console.error("Error verifying token:", error);
        }
    };

    const handleSubmitVerificationToken = (e) => {
        console.log("Token submit function triggered");
        e.preventDefault();

        console.log("Token value before API call:", verificationToken);

        const verificationSuccess = verifyEmail();

        if (verificationSuccess) {
            localStorage.setItem('email', formData.email);
            console.log('Email verification successful and email saved to localStorage');
        } else {
            console.error('Verification failed');
        }
    };

    const deleteSavedResume = async (savedResume) => {
        try {
            // Assuming you have a delete endpoint set up
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/resume/${savedResume._id}`);
            console.log('Resume deleted successfully');
        } catch (error) {
            console.error('Error deleting resume:', error);
        }
    };

    const handleAddSocialProfile = () => {
        setFormData(prevState => ({
            ...prevState,
            socialProfiles: [...prevState.socialProfiles, { platform: '', url: '' }]
        }));
    }

    const handleRemoveSocialProfile = (index) => {
        setFormData(prevState => {
            const newProfiles = [...prevState.socialProfiles];
            newProfiles.splice(index, 1);
            return { ...prevState, socialProfiles: newProfiles };
        });
    }

    const handleRemoveEducation = (index) => {
        setFormData(prevState => {
            const updatedEducation = prevState.education.filter((_, i) => i !== index);
            return { ...prevState, education: updatedEducation };
        });
    };

    const handleAddSecurityClearance = () => {
        setFormData({
            ...formData,
            securityClearance: [
                ...formData.securityClearance,
                {
                    level: '',
                    issuedBy: '',
                    issueDate: '',
                    expirationDate: '',
                },
            ],
        });
    };

    const handleRemoveSecurityClearance = (index) => {
        const updatedSecurityClearance = [...formData.securityClearance];
        updatedSecurityClearance.splice(index, 1);
        setFormData({ ...formData, securityClearance: updatedSecurityClearance });
    };

    // Work History Handlers
    const handleAddJob = () => {
        setFormData(prevState => ({
            ...prevState,
            workHistory: [...prevState.workHistory, {
                title: '',
                company: '',
                locationCity: '',
                locationState: '',
                dates: ''
            }]
        }));
    };

    const handleRemoveJob = (index) => {
        setFormData(prevState => {
            const updatedWorkHistory = [...prevState.workHistory];
            updatedWorkHistory.splice(index, 1);
            return { ...prevState, workHistory: updatedWorkHistory };
        });
    };

    // Function to add a new job tag to the array and update formData
    const addJobTag = () => {
        if (newJobTag.trim() !== '') {
            const updatedJobs = [...idealJobs, newJobTag];
            setIdealJobs(updatedJobs);
            setNewJobTag('');
        }
    };

    // Function to remove a job tag from the array and update formData
    const removeJobTag = (index) => {
        const updatedJobs = [...idealJobs];
        updatedJobs.splice(index, 1);
        setIdealJobs(updatedJobs);
    };

    const handleAddCertification = () => {
        setFormData(prevState => ({
            ...prevState,
            certifications: [...prevState.certifications, {
                name: '',
                issuingOrganization: '',
                dateIssued: '',
                expirationDate: ''
            }]
        }));
    };

    const handleRemoveCertification = (index) => {
        setFormData(prevState => ({
            ...prevState,
            certifications: prevState.certifications.filter((_, i) => i !== index)
        }));
    };

    const handleAddSoftwareExperience = () => {
        setFormData(prevState => ({
            ...prevState,
            softwareExperience: [...prevState.softwareExperience, {
                softwareName: '',
                proficiencyLevel: ''
            }]
        }));
    };

    const handleRemoveSoftwareExperience = (index) => {
        setFormData(prevState => ({
            ...prevState,
            softwareExperience: prevState.softwareExperience.filter((_, i) => i !== index)
        }));
    };

    // Generic Add Handler for sections like Licenses, Languages, Certifications, etc.
    const handleAddField = (field) => {
        setFormData(prevState => ({
            ...prevState,
            [field]: [...prevState[field], ''],
        }));
    };

    // Generic Remove Handler for sections like Licenses, Languages, Certifications, etc.
    const handleRemoveField = (field, index) => {
        setFormData(prevState => {
            const updatedField = [...prevState[field]];
            updatedField.splice(index, 1);
            return { ...prevState, [field]: updatedField };
        });
    };

    // Career Training Handlers
    const handleAddCareerTraining = () => {
        setFormData(prevState => ({
            ...prevState,
            careerTraining: [...prevState.careerTraining, {
                programName: '',
                institution: '',
                completionDate: ''
            }]
        }));
    };

    const handleRemoveCareerTraining = (index) => {
        setFormData(prevState => {
            const updatedCareerTraining = [...prevState.careerTraining];
            updatedCareerTraining.splice(index, 1);
            return { ...prevState, careerTraining: updatedCareerTraining };
        });
    };

    // Endorsements Handlers
    const handleAddEndorsement = () => {
        setFormData(prevState => ({
            ...prevState,
            endorsements: [...prevState.endorsements, {
                endorsement: '',
                endorsedBy: '',
                date: ''
            }]
        }));
    };

    const handleRemoveEndorsement = (index) => {
        setFormData(prevState => {
            const updatedEndorsements = [...prevState.endorsements];
            updatedEndorsements.splice(index, 1);
            return { ...prevState, endorsements: updatedEndorsements };
        });
    };

    // Awards Handlers
    const handleAddAward = () => {
        setFormData(prevState => ({
            ...prevState,
            awards: [...prevState.awards, {
                awardName: '',
                issuingOrganization: '',
                date: ''
            }]
        }));
    };

    const handleRemoveAward = (index) => {
        setFormData(prevState => {
            const updatedAwards = [...prevState.awards];
            updatedAwards.splice(index, 1);
            return { ...prevState, awards: updatedAwards };
        });
    };

    // Publications Handlers
    const handleAddPublication = () => {
        setFormData(prevState => ({
            ...prevState,
            publications: [...prevState.publications, {
                title: '',
                publication: '',
                date: ''
            }]
        }));
    };

    const handleRemovePublication = (index) => {
        setFormData(prevState => {
            const updatedPublications = [...prevState.publications];
            updatedPublications.splice(index, 1);
            return { ...prevState, publications: updatedPublications };
        });
    };

    const handleAddLicense = () => handleAddField('licenses');
    const handleRemoveLicense = (index) => handleRemoveField('licenses', index);
    const handleAddLanguage = () => handleAddField('languages');
    const handleRemoveLanguage = (index) => handleRemoveField('languages', index);

    const renderForm = () => {
        if (role === 'employer') {
            const employerSteps = [
                // Step 1
                <Box key="step1">
                    <Text color={"white"} fontSize="md" mb={2}>About You</Text>
                    <Flex w={"100%"} >
                        <HStack w={"100%"} justify={"space-between"} >
                            <FormControl id="firstName" isRequired>
                                <FormLabel color={"white"}>First Name</FormLabel>
                                <Input
                                    mb={4}
                                    mr={4}
                                    bg="white"
                                    name="firstName"
                                    placeholder="Enter your first name"
                                    onChange={handleChange}
                                    value={formData.firstName || ''}
                                />
                            </FormControl>
                            <FormControl id="lastName" isRequired>
                                <FormLabel color={"white"}>Last Name</FormLabel>
                                <Input
                                    mb={4}
                                    bg="white"
                                    name="lastName"
                                    placeholder="Enter your last name"
                                    onChange={handleChange}
                                    value={formData.lastName || ''}
                                />
                            </FormControl>
                        </HStack>
                    </Flex>
                    <FormControl id="phoneNumber" isRequired>
                        <FormLabel color={"white"}>Phone Number</FormLabel>
                        <Input
                            mb={4}
                            bg="white"
                            name="phoneNumber"
                            placeholder="Enter your phone number"
                            onChange={handleChange}
                            value={formData.phoneNumber || ''}
                        />
                    </FormControl>
                    <FormControl id="yourTitle" isRequired>
                        <FormLabel color={"white"}>Your Title</FormLabel>
                        <Input
                            mb={4}
                            bg="white"
                            name="yourTitle"
                            placeholder="Enter your title (e.g., CEO, Hiring Manager)"
                            onChange={handleChange}
                            value={formData.yourTitle || ''}
                        />
                    </FormControl>
                    <Input
                        type="hidden"
                        name="permission"
                        value={formData.permission || 'Admin'}
                    />
                </Box>,
            ];
            return employerSteps[step - 1];
        } else if (role === 'recruiter') {
            const recruiterSteps = [
                // Step 1
                <Box key="step1">
                    <Text color={"white"} fontSize="md" mb={2}>About You</Text>
                    <FormControl id="name" isRequired>
                        <FormLabel color={"white"}>Name</FormLabel>
                        <Input mb={4} bg="white" name="name" placeholder="Enter your name" onChange={handleChange} value={formData.name || ''} />
                    </FormControl>
                    <FormControl id="phoneNumber" isRequired>
                        <FormLabel color={"white"}>Phone Number</FormLabel>
                        <Input mb={4} bg="white" name="phoneNumber" placeholder="Enter phone number" onChange={handleChange} value={formData.phoneNumber || ''} />
                    </FormControl>
                    <FormControl id="yearsOfExperience">
                        <FormLabel color={"white"}>Years of Experience</FormLabel>
                        <Input mb={4} bg="white" type="number" name="yearsOfExperience" placeholder="Enter years of experience" onChange={handleChange} value={formData.yearsOfExperience || ''} />
                    </FormControl>
                </Box>,
                // Step 2
                <Box key="step2">
                    <Box mb={4}>
                        <FormControl id="agencyStatus" as="fieldset">
                            <FormLabel color={"white"} as="legend">Independent or part of a firm?</FormLabel>
                            <RadioGroup value={agencyStatus} onChange={setAgencyStatus}>
                                <Radio mx={4} value="Independent"><Text color={"white"}>Independent</Text></Radio>
                                <Radio mx={4} value="Agency"><Text color={"white"}>Part of a firm</Text></Radio>
                            </RadioGroup>
                        </FormControl>

                        {agencyStatus === 'Agency' && (
                            <FormControl id="agencyAffiliation">
                                <FormLabel color={"white"} mt={4}>Company Affiliation</FormLabel>
                                <Input
                                    bg="white"
                                    name="agencyAffiliation"
                                    placeholder="Enter agency name"
                                    onChange={handleChange}
                                    value={formData.agencyAffiliation || ''}
                                />
                            </FormControl>
                        )}
                    </Box>
                    <RecruiterTagSection formData={formData} setFormData={setFormData} />
                </Box>,
                // Step 3
                <Box key="step3">
                    <div>
                        <FormControl id="recruiterLicenseCheck">
                            <HStack>
                                <FormLabel color={"white"}>Do you have a recruiter license?</FormLabel>
                                <Checkbox onChange={(e) => setShowLicenseField(e.target.checked)} />
                            </HStack>
                        </FormControl>
                        {showLicenseField && (
                            <FormControl id="recruiterLicense">
                                <FormLabel color={"white"}>Recruiter License</FormLabel>
                                <Input
                                    mb={4}
                                    bg="white"
                                    name="recruiterLicense"
                                    placeholder="Enter license if applicable"
                                    onChange={handleChange}
                                    value={formData.recruiterLicense || ''}
                                />
                            </FormControl>
                        )}
                    </div>
                </Box>,
            ];
            return recruiterSteps[step - 1];
        } else if (role === 'jobseeker') {
            const jobSeekerSteps = [
                // Step 1
                <Box minW={"500px"} key="step1">

                    <ResumeUpload setSavedResume={setSavedResume} />

                </Box>,
                // Step 2
                <Box minW={"500px"} maxH={"700px"} overflowY={"auto"} key="step2">
                    <FormControl id="name">
                        <FormLabel color={"white"}>Full Name</FormLabel>
                        <Input
                            mb={4}
                            bg="white"
                            name="name"
                            placeholder="Enter your full name"
                            onChange={handleChange}
                            value={formData.name}
                        />
                    </FormControl>
                    <FormControl id="phoneNumber">
                        <FormLabel color={"white"}>Phone Number</FormLabel>
                        <Input
                            mb={4}
                            bg="white"
                            name="phoneNumber"
                            placeholder="Enter your phone number..."
                            onChange={handleChange}
                            value={formData.phoneNumber}
                        />
                    </FormControl>
                    <FormControl id="streetAddress">
                        <FormLabel color={"white"}>Current Address</FormLabel>
                        <Input
                            mb={4}
                            bg="white"
                            name="streetAddress"
                            placeholder="Current address..."
                            onChange={handleChange}
                            value={formData.streetAddress}
                        />
                    </FormControl>
                    <HStack>
                        <FormControl id="locationCity">
                            <FormLabel color={"white"}>City</FormLabel>
                            <Input
                                mb={4}
                                bg="white"
                                name="locationCity"
                                placeholder="City"
                                onChange={handleChange}
                                value={formData.locationCity}
                            />
                        </FormControl>
                        <FormControl id="locationState">
                            <FormLabel color={"white"}>State</FormLabel>
                            <Input
                                mb={4}
                                bg="white"
                                name="locationState"
                                placeholder="State"
                                onChange={handleChange}
                                value={formData.locationState}
                            />
                        </FormControl>
                    </HStack>
                    <HStack>
                        <FormControl id="zipCode">
                            <FormLabel color={"white"}>Zip Code</FormLabel>
                            <Input
                                mb={4}
                                bg="white"
                                name="zipCode"
                                placeholder="Zip Code"
                                onChange={handleChange}
                                value={formData.zipCode}
                            />
                        </FormControl>
                        <FormControl id="locationCountry">
                            <FormLabel color={"white"}>Country</FormLabel>
                            <Input
                                mb={4}
                                bg="white"
                                name="locationCountry"
                                placeholder="Country"
                                onChange={handleChange}
                                value={formData.locationCountry}
                            />
                        </FormControl>
                    </HStack>
                </Box>,
                // Step 3
                <Box minW={"800px"} maxH={"500px"} key="step3">
                    <SkillsInput formData={formData} setFormData={setFormData} />
                </Box>,
                // Step 4
                <Box minW={"800px"} maxH={"500px"} overflowY={"scroll"} key="step4">
                    <FormControl id="education">
                        <FormLabel color={"white"}>Education</FormLabel>
                        {Array.isArray(formData.education) ? (
                            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                                {formData.education.map((edu, index) => (
                                    <Box borderRadius={"lg"} p={3} bg={"blackAlpha.300"} mb={4} key={index}>
                                        <Input
                                            name={`education[${index}].institution`}
                                            mb={2}
                                            placeholder="Institution Name"
                                            value={edu.institution}
                                            bg={"white"}
                                            onChange={handleChange}
                                        />
                                        <Input
                                            name={`education[${index}].degree`}
                                            mb={2}
                                            placeholder="Degree..."
                                            value={edu.degree}
                                            bg={"white"}
                                            onChange={handleChange}
                                        />
                                        <Input
                                            name={`education[${index}].fieldOfStudy`}
                                            mb={2}
                                            placeholder="Field of Study..."
                                            value={edu.fieldOfStudy}
                                            bg={"white"}
                                            onChange={handleChange}
                                        />
                                        <Input
                                            name={`education[${index}].description`}
                                            mb={2}
                                            placeholder="Description..."
                                            value={edu.description}
                                            bg={"white"}
                                            onChange={handleChange}
                                        />
                                        <Center>
                                            <HStack w={"100%"}>
                                                <Text>Start Date</Text>
                                                <Input
                                                    type="date"
                                                    name={`education[${index}].startDate`}
                                                    value={edu.startDate}
                                                    bg={"white"}
                                                    onChange={handleChange}
                                                />
                                                <Text>End Date</Text>
                                                <Input
                                                    type="date"
                                                    name={`education[${index}].endDate`}
                                                    value={edu.endDate}
                                                    bg={"white"}
                                                    onChange={handleChange}
                                                />
                                            </HStack>
                                        </Center>
                                        <Flex mt={4} justifyContent="flex-end">
                                            <Button size={"sm"} colorScheme='red' onClick={() => handleRemoveEducation(index)}>
                                                Remove Institution
                                            </Button>
                                        </Flex>
                                    </Box>
                                ))}
                            </SimpleGrid>
                        ) : (
                            'Loading...'
                        )}
                        <Flex justifyContent="flex-end" mt={4}>
                            <Button size={"sm"} bg={"#118AB2"} color={"white"} _hover={{ bg: "#01bf02" }} onClick={handleAddEducation}>
                                Add Education
                            </Button>
                        </Flex>
                    </FormControl>
                </Box>,
                // Step 5
                <Box minW={"800px"} key="step5">
                    <Box maxH={"375px"} overflowY={"scroll"} >
                        <FormControl id="workHistory">
                            <FormLabel color={"white"}>Work History</FormLabel>
                            {Array.isArray(formData.workHistory) ? (
                                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                                    {formData.workHistory.map((job, index) => (
                                        <Box borderRadius={"lg"} p={3} bg={"blackAlpha.300"} mb={4} key={index}>
                                            <Input
                                                name={`workHistory[${index}].title`}
                                                mb={2}
                                                placeholder="Job Title..."
                                                value={job.title}
                                                bg={"white"}
                                                onChange={handleChange}
                                            />
                                            <Input
                                                name={`workHistory[${index}].company`}
                                                mb={2}
                                                placeholder="Company..."
                                                value={job.company}
                                                bg={"white"}
                                                onChange={handleChange}
                                            />
                                            <Input
                                                name={`workHistory[${index}].locationCity`}
                                                mb={2}
                                                placeholder="City..."
                                                value={job.locationCity}
                                                bg={"white"}
                                                onChange={handleChange}
                                            />
                                            <Input
                                                name={`workHistory[${index}].locationState`}
                                                mb={2}
                                                placeholder="State..."
                                                value={job.locationState}
                                                bg={"white"}
                                                onChange={handleChange}
                                            />
                                            <Input
                                                name={`workHistory[${index}].dates`}
                                                mb={2}
                                                placeholder="Dates of Employment..."
                                                value={job.dates}
                                                bg={"white"}
                                                onChange={handleChange}
                                            />
                                            <Flex justifyContent="flex-end">
                                                <Button size={"sm"} colorScheme='red' onClick={() => handleRemoveJob(index)}>
                                                    Remove Job
                                                </Button>
                                            </Flex>
                                        </Box>
                                    ))}
                                </SimpleGrid>
                            ) : (
                                'Loading...'
                            )}
                        </FormControl>
                    </Box>
                    <Flex justifyContent="flex-end" mt={4}>
                        <Button size={"sm"} bg={"#118AB2"} color={"white"} _hover={{ bg: "#01BF02" }} onClick={handleAddJob}>
                            Add Job
                        </Button>
                    </Flex>
                    <Divider color={"black"} border={"1px"} mt={2} mb={2} />
                    <IndustryExperienceInput
                        formData={formData}
                        setFormData={setFormData}
                    />
                </Box>,
                // Step 6
                <Box maxH={"500px"} minW={"800px"} overflowY={"scroll"} key="step6">
                    <FormControl id="certifications">
                        <FormLabel color={"white"}>Certifications</FormLabel>
                        {Array.isArray(formData.certifications) ? (
                            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                                {formData.certifications.map((certification, index) => (
                                    <Box borderRadius={"lg"} p={3} bg={"blackAlpha.300"} key={index} mb={4}>
                                        <Input
                                            name={`certifications[${index}].name`}
                                            mb={2}
                                            placeholder="Certification Name..."
                                            value={certification.name}
                                            bg={"white"}
                                            onChange={handleChange}
                                        />
                                        <Input
                                            name={`certifications[${index}].issuingOrganization`}
                                            mb={2}
                                            placeholder="Issuing Organization..."
                                            value={certification.issuingOrganization}
                                            bg={"white"}
                                            onChange={handleChange}
                                        />
                                        <Input
                                            type="date"
                                            name={`certifications[${index}].dateIssued`}
                                            mb={2}
                                            placeholder="Date Issued..."
                                            value={certification.dateIssued}
                                            bg={"white"}
                                            onChange={handleChange}
                                        />
                                        <Input
                                            type="date"
                                            name={`certifications[${index}].expirationDate`}
                                            mb={2}
                                            placeholder="Expiration Date..."
                                            value={certification.expirationDate}
                                            bg={"white"}
                                            onChange={handleChange}
                                        />
                                        <Flex justifyContent="flex-end">
                                            <Button size={"sm"} colorScheme='red' onClick={() => handleRemoveCertification(index)}>
                                                Remove Certification
                                            </Button>
                                        </Flex>
                                    </Box>
                                ))}
                            </SimpleGrid>
                        ) : 'Loading...'}
                        <Flex justifyContent="flex-end" mt={4}>
                            <Button size={"sm"} bg={"#118AB2"} color={"white"} _hover={{ bg: "#01bf02" }} onClick={handleAddCertification}>
                                Add Certification
                            </Button>
                        </Flex>
                    </FormControl>
                    <Divider mt={2} mb={2} />
                    <FormControl id="licenses">
                        <FormLabel color={"white"}>Licenses</FormLabel>
                        {Array.isArray(formData.licenses) ? (
                            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                                {formData.licenses.map((license, index) => (
                                    <Box borderRadius={"lg"} p={3} bg={"blackAlpha.300"} key={index} mb={4}>
                                        <Input
                                            name={`licenses[${index}]`}
                                            mb={2}
                                            placeholder="License..."
                                            value={license}
                                            bg={"white"}
                                            onChange={handleChange}
                                        />
                                        <Flex justifyContent="flex-end">
                                            <Button size={"sm"} colorScheme='red' onClick={() => handleRemoveLicense(index)}>
                                                Remove License
                                            </Button>
                                        </Flex>
                                    </Box>
                                ))}
                            </SimpleGrid>
                        ) : 'Loading...'}
                        <Flex justifyContent="flex-end" mt={4}>
                            <Button size={"sm"} bg={"#118AB2"} color={"white"} _hover={{ bg: "#01bf02" }} onClick={handleAddLicense}>
                                Add License
                            </Button>
                        </Flex>
                    </FormControl>
                    <Divider mt={2} mb={2} />
                    <FormControl id="languages">
                        <FormLabel color={"white"}>Languages</FormLabel>
                        {Array.isArray(formData.languages) ? (
                            <SimpleGrid columns={{ base: 1, md: 3, lg: 4 }} spacing={5}>
                                {formData.languages.map((language, index) => (
                                    <Box borderRadius={"lg"} p={3} bg={"blackAlpha.300"} key={index} mb={4}>
                                        <Input
                                            name={`languages[${index}]`}
                                            mb={2}
                                            placeholder="Language..."
                                            value={language}
                                            bg={"white"}
                                            onChange={handleChange}
                                        />
                                        <Flex justifyContent="flex-end">
                                            <Button size={"sm"} colorScheme='red' onClick={() => handleRemoveLanguage(index)}>
                                                Remove Language
                                            </Button>
                                        </Flex>
                                    </Box>
                                ))}
                            </SimpleGrid>
                        ) : 'Loading...'}
                        <Flex justifyContent="flex-end" mt={4}>
                            <Button size={"sm"} bg={"#118AB2"} color={"white"} _hover={{ bg: "#01bf02" }} onClick={handleAddLanguage}>
                                Add Language
                            </Button>
                        </Flex>
                    </FormControl>
                    <Divider mt={2} mb={2} />
                    <FormControl id="softwareExperience">
                        <FormLabel color={"white"}>Software Experience</FormLabel>
                        {Array.isArray(formData.softwareExperience) ? (
                            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={5}>
                                {formData.softwareExperience.map((software, index) => (
                                    <Box borderRadius={"lg"} p={3} bg={"blackAlpha.300"} key={index} mb={4}>
                                        <Input
                                            name={`softwareExperience[${index}].softwareName`}
                                            mb={2}
                                            placeholder="Software Name..."
                                            value={software.softwareName}
                                            bg={"white"}
                                            onChange={handleChange}
                                        />
                                        <Input
                                            name={`softwareExperience[${index}].proficiencyLevel`}
                                            mb={2}
                                            placeholder="Proficiency Level..."
                                            value={software.proficiencyLevel}
                                            bg={"white"}
                                            onChange={handleChange}
                                        />
                                        <Flex justifyContent="flex-end">
                                            <Button size={"sm"} colorScheme='red' onClick={() => handleRemoveSoftwareExperience(index)}>
                                                Remove Software
                                            </Button>
                                        </Flex>
                                    </Box>
                                ))}
                            </SimpleGrid>
                        ) : 'Loading...'}
                        <Flex justifyContent="flex-end" mt={4}>
                            <Button size={"sm"} bg={"#118AB2"} color={"white"} _hover={{ bg: "#01bf02" }} onClick={handleAddSoftwareExperience}>
                                Add Software
                            </Button>
                        </Flex>
                    </FormControl>
                </Box >,
                // Step 7
                <Box maxH={"500px"} minW={"800px"} overflowY={"scroll"} key="step7">
                    <FormControl id="idealJobs" mb={4}>
                        <FormLabel color={"white"}>Ideal Jobs</FormLabel>
                        <Flex flexWrap="wrap">
                            {idealJobs.map((job, index) => (
                                <Tag
                                    key={index}
                                    size="lg"
                                    variant="solid"
                                    bg={"#118AB2"}
                                    color={"white"}
                                    m={2}
                                    borderRadius="full"
                                >
                                    <TagLabel>{job}</TagLabel>
                                    <TagCloseButton onClick={() => removeJobTag(index)} />
                                </Tag>
                            ))}
                        </Flex>
                        <HStack>
                            <Input
                                bg="white"
                                name="idealJobs"
                                placeholder="Enter your ideal jobs..."
                                onChange={(e) => setNewJobTag(e.target.value)}
                                value={newJobTag}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' || e.key === 'Tab') {
                                        e.preventDefault();
                                        addJobTag();
                                    }
                                }}
                            />
                            <Button
                                size="sm"
                                bg={"#118AB2"}
                                color={"white"}
                                _hover={{ bg: "#01BF02" }}
                                onClick={addJobTag}
                                borderRadius="full"
                            >
                                Add Job
                            </Button>
                        </HStack>
                    </FormControl>
                    <HStack w={"100%"}>
                        <FormControl id="employmentType">
                            <FormLabel color={"white"}>Desired Employment Type</FormLabel>
                            <Select
                                mb={4}
                                bg="white"
                                name="employmentType"
                                onChange={handleChange}
                                value={formData.employmentType || ''}
                            >
                                <option value="Full-time">Full-time</option>
                                <option value="Part-time">Part-time</option>
                                <option value="Contract">Contract</option>
                            </Select>
                        </FormControl>
                        <FormControl id="workLocationType">
                            <FormLabel color={"white"}> DesiredWork Location Type</FormLabel>
                            <Select
                                mb={4}
                                bg="white"
                                name="workLocationType"
                                onChange={handleChange}
                                value={formData.workLocationType || ''}
                            >
                                <option value="Remote">Remote</option>
                                <option value="Hybrid">Hybrid</option>
                                <option value="On-site">On-site</option>
                            </Select>
                        </FormControl>
                    </HStack>
                    <HStack w={"100%"}>
                        <FormControl id="earningsType">
                            <FormLabel color={"white"}>Earnings Type</FormLabel>
                            <RadioGroup
                                name="earningsType"
                                value={earningsType}
                                mb={4}
                                onChange={handleEarningsTypeChange}
                            >
                                <Stack direction="row">
                                    <Radio value="salary"><Text color={"white"}>Salary</Text></Radio>
                                    <Radio value="hourly"><Text color={"white"}>Hourly</Text></Radio>
                                </Stack>
                            </RadioGroup>
                        </FormControl>

                        {earningsType === 'salary' ? (
                            <FormControl id="currentSalary">
                                <FormLabel color={"white"}>Current Salary</FormLabel>
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents="none"
                                        color="gray.400"
                                        fontSize="1.2em"
                                        children="$"
                                    />
                                    <Input
                                        mb={4}
                                        type='number'
                                        bg="white"
                                        name="currentSalary"
                                        placeholder="Current salary?"
                                        onChange={handleChange}
                                        value={formData.currentSalary || ''}
                                    />
                                </InputGroup>
                            </FormControl>
                        ) : (
                            <FormControl id="currentHourlyRate">
                                <FormLabel color={"white"}>Current Hourly Rate</FormLabel>
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents="none"
                                        color="gray.400"
                                        fontSize="1.2em"
                                        children="$"
                                    />
                                    <Input
                                        mb={4}
                                        bg="white"
                                        type='number'
                                        name="currentHourlyRate"
                                        placeholder="Current hourly rate?"
                                        onChange={handleChange}
                                        value={formData.currentHourlyRate || ''}
                                    />
                                </InputGroup>
                            </FormControl>
                        )}

                        {/* Desired earnings field */}
                        {earningsType === 'salary' ? (
                            <FormControl id="desiredSalary">
                                <FormLabel color={"white"}>Desired Salary</FormLabel>
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents="none"
                                        color="gray.400"
                                        fontSize="1.2em"
                                        children="$"
                                    />
                                    <Input
                                        mb={4}
                                        bg="white"
                                        name="desiredSalary"
                                        placeholder="Desired salary?"
                                        onChange={handleChange}
                                        value={formData.desiredSalary || ''}
                                    />
                                </InputGroup>
                            </FormControl>
                        ) : (
                            <FormControl id="desiredHourlyRate">
                                <FormLabel color={"white"}>Desired Hourly Rate</FormLabel>
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents="none"
                                        color="gray.400"
                                        fontSize="1.2em"
                                        children="$"
                                    />
                                    <Input
                                        mb={4}
                                        bg="white"
                                        name="desiredHourlyRate"
                                        placeholder="Desired hourly rate?"
                                        onChange={handleChange}
                                        value={formData.desiredHourlyRate || ''}
                                    />
                                </InputGroup>
                            </FormControl>
                        )}
                    </HStack>
                </Box>,
                // Step 8
                <Box maxH={"500px"} minW={"800px"} overflowY={"scroll"} key="step8">
                    <FormControl id="socialProfiles">
                        <FormLabel color={"white"}>Social Media Links</FormLabel>
                        {Array.isArray(formData.socialProfiles) ? (
                            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                                {formData.socialProfiles.map((profile, index) => (
                                    <Box borderRadius={"lg"} p={3} bg={"blackAlpha.300"} key={index} mb={4}>
                                        <Input
                                            name={`socialProfiles[${index}].platform`}
                                            mb={2}
                                            placeholder="Platform..."
                                            value={profile.platform}
                                            bg={"white"}
                                            onChange={handleChange}
                                        />
                                        <Input
                                            name={`socialProfiles[${index}].url`}
                                            mb={2}
                                            placeholder="URL..."
                                            value={profile.url}
                                            bg={"white"}
                                            onChange={handleChange}
                                        />
                                        <Flex justifyContent="flex-end">
                                            <Button size={"sm"} colorScheme='red' onClick={() => handleRemoveSocialProfile(index)}>
                                                Remove Social Profile
                                            </Button>
                                        </Flex>
                                    </Box>
                                ))}
                            </SimpleGrid>
                        ) : 'Loading...'}
                        <Flex justifyContent="flex-end" mt={4}>
                            <Button size={"sm"} bg={"#118AB2"} color={"white"} _hover={{ bg: "#01bf02" }} onClick={handleAddSocialProfile}>
                                Add Social Profile
                            </Button>
                        </Flex>
                    </FormControl>
                    <FormControl id="portfolioURL">
                        <FormLabel color={"white"}>Portfolio or Bio Link</FormLabel>
                        <Input mb={4} bg="white" name="portfolioURL" placeholder="Share your protfolio..." onChange={handleChange} value={formData.portfolioURL || ''} />
                    </FormControl>
                </Box >,
                // Step 9
                <Box minW={"800px"} maxH={"500px"} overflowY={"scroll"} key="step9">
                    {/* Hobbies Section */}
                    <FormControl id="hobbies">
                        <FormLabel color={"white"}>Hobbies</FormLabel>
                        {Array.isArray(formData.hobbies)
                            ? <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                                {formData.hobbies.map((hobby, index) => (
                                    <Box borderRadius={"lg"} p={3} bg={"blackAlpha.300"} key={index} mb={4}>
                                        <Input
                                            name={`hobbies[${index}]`}
                                            placeholder="Hobby..."
                                            onChange={handleChange}
                                            value={hobby}
                                            bg={"white"}
                                        />
                                        <Flex justify={"flex-end"} >
                                            <Button mt={2} size={"xs"} colorScheme='red' onClick={() => handleRemoveHobby(index)}>
                                                Remove Hobby
                                            </Button>
                                        </Flex>
                                    </Box>
                                ))}
                            </SimpleGrid>
                            : 'Loading...'
                        }
                        {/* Provide a way to add new hobby entries */}
                        <Button size={"sm"} bg={"#118AB2"} color={"white"} _hover={{ bg: "#01bf02" }} onClick={handleAddHobby}>
                            Add Hobby
                        </Button>
                    </FormControl>
                    <Divider mt={2} mb={2} />

                    {/* Security Clearance Section */}
                    <FormControl id="securityClearance">
                        <FormLabel color={"white"}>Security Clearance</FormLabel>
                        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                            {formData.securityClearance.map((clearance, index) => (
                                <Box borderRadius={"lg"} p={3} bg={"blackAlpha.300"} key={index} mb={4}>
                                    <Input
                                        name={`securityClearance[${index}].level`}
                                        placeholder="Security Clearance Level..."
                                        onChange={handleChange}
                                        mb={2}
                                        value={clearance.level || ''}
                                        bg="white"
                                    />
                                    <Input
                                        name={`securityClearance[${index}].issuedBy`}
                                        placeholder="Issued By..."
                                        onChange={handleChange}
                                        mb={2}
                                        value={clearance.issuedBy || ''}
                                        bg="white"
                                    />
                                    <Input
                                        type="date"
                                        name={`securityClearance[${index}].issueDate`}
                                        placeholder="Issue Date..."
                                        onChange={handleChange}
                                        mb={2}
                                        value={clearance.issueDate || ''}
                                        bg="white"
                                    />
                                    <Input
                                        type="date"
                                        name={`securityClearance[${index}].expirationDate`}
                                        placeholder="Expiration Date..."
                                        onChange={handleChange}
                                        mb={2}
                                        value={clearance.expirationDate || ''}
                                        bg="white"
                                    />
                                    {index > 0 && (
                                        <Flex justify={"flex-end"} >
                                            <Button mt={2} size={"xs"} colorScheme='red' onClick={() => handleRemoveSecurityClearance(index)}>
                                                Remove Clearance
                                            </Button>
                                        </Flex>
                                    )}
                                </Box>
                            ))}
                        </SimpleGrid>
                        <Button size={"sm"} bg={"#118AB2"} color={"white"} _hover={{ bg: "#01bf02" }} onClick={handleAddSecurityClearance}>
                            Add Clearance
                        </Button>
                    </FormControl>
                    <Divider mt={2} mb={2} />

                    {/* Career Training Section */}
                    <FormControl id="careerTraining">
                        <FormLabel color={"white"}>Career Training</FormLabel>
                        {Array.isArray(formData.careerTraining)
                            ? <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                                {formData.careerTraining.map((training, index) => (
                                    <Box borderRadius={"lg"} p={3} bg={"blackAlpha.300"} key={index} mb={4}>
                                        <Input
                                            name={`careerTraining[${index}].programName`}
                                            placeholder="Program Name..."
                                            onChange={handleChange}
                                            mb={2}
                                            value={training.programName}
                                            bg={"white"}
                                        />
                                        <Input
                                            name={`careerTraining[${index}].institution`}
                                            placeholder="Institution..."
                                            onChange={handleChange}
                                            mb={2}
                                            value={training.institution}
                                            bg={"white"}
                                        />
                                        <Input
                                            type="date"
                                            name={`careerTraining[${index}].completionDate`}
                                            placeholder="Completion Date..."
                                            onChange={handleChange}
                                            mb={2}
                                            value={training.completionDate}
                                            bg={"white"}
                                        />
                                        <Flex justify={"flex-end"} >
                                            <Button mt={2} size={"xs"} colorScheme='red' onClick={() => handleRemoveCareerTraining(index)}>
                                                Remove Training
                                            </Button>
                                        </Flex>
                                    </Box>
                                ))}
                            </SimpleGrid>
                            : 'Loading...'
                        }
                        {/* Provide a way to add new career training entries */}
                        <Button size={"sm"} bg={"#118AB2"} color={"white"} _hover={{ bg: "#01bf02" }} onClick={handleAddCareerTraining}>
                            Add Training
                        </Button>
                    </FormControl>
                    <Divider mt={2} mb={2} />

                    {/* Endorsements Section */}
                    <FormControl id="endorsements">
                        <FormLabel color={"white"}>Endorsements</FormLabel>
                        {Array.isArray(formData.endorsements)
                            ? <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                                {formData.endorsements.map((endorsement, index) => (
                                    <Box borderRadius={"lg"} p={3} bg={"blackAlpha.300"} key={index} mb={4}>
                                        <Input
                                            name={`endorsements[${index}].endorsedBy`}
                                            placeholder="Endorsed By..."
                                            onChange={handleChange}
                                            mb={2}
                                            value={endorsement.endorsedBy}
                                            bg={"white"}
                                        />
                                        <Input
                                            name={`endorsements[${index}].endorsement`}
                                            placeholder="Endorsement..."
                                            onChange={handleChange}
                                            mb={2}
                                            value={endorsement.endorsement}
                                            bg={"white"}
                                        />
                                        <Flex justify={"flex-end"} >
                                            <Button mt={2} size={"xs"} colorScheme='red' onClick={() => handleRemoveEndorsement(index)}>
                                                Remove Endorsement
                                            </Button>
                                        </Flex>
                                    </Box>
                                ))}
                            </SimpleGrid>
                            : 'Loading...'
                        }
                        {/* Provide a way to add new endorsement entries */}
                        <Button size={"sm"} bg={"#118AB2"} color={"white"} _hover={{ bg: "#01bf02" }} onClick={handleAddEndorsement}>
                            Add Endorsement
                        </Button>
                    </FormControl>
                    <Divider mt={2} mb={2} />

                    {/* Awards Section */}
                    <FormControl id="awards">
                        <FormLabel color={"white"}>Awards</FormLabel>
                        {Array.isArray(formData.awards)
                            ? <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                                {formData.awards.map((award, index) => (
                                    <Box borderRadius={"lg"} p={3} bg={"blackAlpha.300"} key={index} mb={4}>
                                        <Input
                                            name={`awards[${index}].title`}
                                            placeholder="Award Title..."
                                            onChange={handleChange}
                                            mb={2}
                                            value={award.title}
                                            bg={"white"}
                                        />
                                        <Input
                                            name={`awards[${index}].awardedBy`}
                                            placeholder="Awarded By..."
                                            onChange={handleChange}
                                            mb={2}
                                            value={award.awardedBy}
                                            bg={"white"}
                                        />
                                        <Input
                                            type="date"
                                            name={`awards[${index}].dateAwarded`}
                                            placeholder="Date Awarded..."
                                            onChange={handleChange}
                                            mb={2}
                                            value={award.dateAwarded}
                                            bg={"white"}
                                        />
                                        <Flex justify={"flex-end"} >
                                            <Button mt={2} size={"xs"} colorScheme='red' onClick={() => handleRemoveAward(index)}>
                                                Remove Award
                                            </Button>
                                        </Flex>
                                    </Box>
                                ))}
                            </SimpleGrid>
                            : 'Loading...'
                        }
                        {/* Provide a way to add new award entries */}
                        <Button size={"sm"} bg={"#118AB2"} color={"white"} _hover={{ bg: "#01bf02" }} onClick={handleAddAward}>
                            Add Award
                        </Button>
                    </FormControl>
                    <Divider mt={2} mb={2} />

                    {/* Publications Section */}
                    <FormControl id="publications">
                        <FormLabel color={"white"}>Publications</FormLabel>
                        {Array.isArray(formData.publications)
                            ? <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                                {formData.publications.map((publication, index) => (
                                    <Box borderRadius={"lg"} p={3} bg={"blackAlpha.300"} key={index} mb={4}>
                                        <Input
                                            name={`publications[${index}].title`}
                                            placeholder="Publication Title..."
                                            onChange={handleChange}
                                            mb={2}
                                            value={publication.title}
                                            bg={"white"}
                                        />
                                        <Input
                                            name={`publications[${index}].publishedBy`}
                                            placeholder="Published By..."
                                            onChange={handleChange}
                                            mb={2}
                                            value={publication.publishedBy}
                                            bg={"white"}
                                        />
                                        <Input
                                            type="date"
                                            name={`publications[${index}].publicationDate`}
                                            placeholder="Publication Date..."
                                            onChange={handleChange}
                                            mb={2}
                                            value={publication.publicationDate}
                                            bg={"white"}
                                        />
                                        <Input
                                            name={`publications[${index}].url`}
                                            placeholder="URL..."
                                            onChange={handleChange}
                                            mb={2}
                                            value={publication.url}
                                            bg={"white"}
                                        />
                                        <Flex justify={"flex-end"} >
                                            <Button mt={2} size={"xs"} colorScheme='red' onClick={() => handleRemovePublication(index)}>
                                                Remove Publication
                                            </Button>
                                        </Flex>
                                    </Box>
                                ))}
                            </SimpleGrid>
                            : 'Loading...'
                        }
                        {/* Provide a way to add new publication entries */}
                        <Button size={"sm"} bg={"#118AB2"} color={"white"} _hover={{ bg: "#01bf02" }} onClick={handleAddPublication}>
                            Add Publication
                        </Button>
                    </FormControl>
                    <Divider mt={2} mb={2} />
                </Box>,
            ];
            return jobSeekerSteps[step - 1];
        } else {
            return null;
        }
    };

    const getTotalSteps = () => {
        if (role === 'employer') return 1;
        if (role === 'recruiter') return 3;
        if (role === 'jobseeker') return 9;
        return 0;
    };

    const goToLogin = () => {
        navigate('/login');
    };

    const canGoNext = () => step < getTotalSteps();
    const canGoBack = () => step > 0;

    const handleSubmit = async () => {
        // Initialize the roleUrlSegment and url
        const roleUrlSegment = role.toLowerCase();
        console.log("Role:", role);
        const url = `${process.env.REACT_APP_API_URL}/api/signup/${roleUrlSegment}`;
        console.log("Sending this form payload:", formData);

        formData.role = role;

        try {
            // Single Axios POST request using the dynamic URL
            const response = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200 || response.status === 201) {
                console.log("Data saved: ", response);

                // Success toast
                toast({
                    title: 'Success!',
                    description: 'Data saved successfully.',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });

                const { token, role } = response.data;
                console.log("Token:", token);
                console.log("Role:", role);

                // Save token and role to localStorage
                localStorage.setItem('auth_token', token);
                localStorage.setItem('user_role', role);

                // Debug logs
                console.log("Checking role for navigation");
                console.log("Role from state: ", role);

                // Check role and navigate accordingly
                if (role.toLowerCase() === "employer") {
                    console.log("Navigating to /create-company");
                    navigate('/create-company');
                } else {
                    console.log("Navigating to /login");
                    navigate('/login');
                }

                // If role is jobseeker, delete savedResume
                if (role.toLowerCase() === 'jobseeker' && savedResume) {
                    // Assuming you have a function deleteSavedResume to handle the deletion
                    deleteSavedResume(savedResume);
                }

                if (role.toLowerCase() === 'jobseeker') {
                    handleAddPoints(10);
                }

            } else {
                // Handle other status codes and inform the user
                console.log("Something went wrong: ", response);

                toast({
                    title: 'An error occurred.',
                    description: `Received status code: ${response.status}`,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }


        } catch (error) {
            console.log("Error saving data: ", error);

            toast({
                title: 'Error',
                description: 'Could not save data. Please try again.',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
        setIsLoading(false);
    };

    return (
        <Flex
            direction="column"
            justify="center"
            align="center"
            minH="100vh"
            width="100%"
            backgroundImage={Background}
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
        >

            <HStack spacing={6} alignItems="center">
                {!canGoBack() && (
                    <VStack>
                        <Box mb={4}>
                            <img src={Logo} alt="JobJar.AILogo" width="200px" />
                        </Box>
                        <WelcomeMessage />
                    </VStack>
                )}
                <VStack spacing={4}>
                    <Box
                        p={4}
                        w="100%"
                        maxW="900px"
                        borderWidth={"3px"}
                        borderRadius="lg"
                        borderColor={"white"}
                        boxShadow="lg"
                        backgroundColor="black"
                    >
                        <Box mb={4}>
                            <Text color={"white"} fontSize="2xl" mb={4}>
                                Sign Up
                            </Text>
                        </Box>
                        {step === 0 && (
                            <>
                                <RoleSection setRole={setRole} />
                                <Box mt={4} mx={4}>
                                    <FormControl id="email" isRequired>
                                        <FormLabel color={"white"}>Email</FormLabel>
                                        <Input
                                            mb={4}
                                            bg="white"
                                            name="email"
                                            placeholder="Enter your email"
                                            onChange={handleChange}
                                            value={formData.email || ''}
                                        />
                                    </FormControl>
                                    <Button
                                        w="full"
                                        onClick={sendVerificationEmail}
                                        bg={"black"}
                                        color={"white"}
                                        border={"2px"}
                                        borderColor={"white"}
                                        _hover={{ bg: "#01bf02", border: "0px" }}
                                        isLoading={isLoading}
                                        loadingText="Sending..."
                                    >
                                        {isLoading ? <Spinner /> : "Send Verification Email"}
                                    </Button>

                                    {/* Conditionally render Verify Token */}
                                    {showVerifyToken && (
                                        <HStack align={"flex-end"} mt={4} w={"100%"}>
                                            <FormControl id="verificationToken" isRequired>
                                                <FormLabel color={"white"}>Verification Token</FormLabel>
                                                <Input
                                                    w={"100%"}
                                                    bg="white"
                                                    name="verificationToken"
                                                    placeholder="Enter Verification Token"
                                                    value={verificationToken}
                                                    onChange={(e) => setVerificationToken(e.target.value)}
                                                />
                                            </FormControl>
                                            <Button
                                                bg={"black"}
                                                color={"white"}
                                                border={"2px"}
                                                borderColor={"white"}
                                                _hover={{ bg: "#01BF02", border: "0px" }}
                                                w="full"
                                                type="submit"
                                                onClick={handleSubmitVerificationToken}
                                            >
                                                Verify Token
                                            </Button>
                                        </HStack>
                                    )}
                                </Box>
                                {/* Conditionally render password forms if verificationSuccess is true */}
                                <Box mt={4} mx={4}>
                                    {isEmailVerified && (
                                        <>
                                            <FormControl id="password" isRequired isInvalid={!!passwordError}>
                                                <FormLabel color={"white"}>Password</FormLabel>
                                                <Input
                                                    mb={4}
                                                    bg="white"
                                                    name="password"
                                                    placeholder="Create a password"
                                                    onChange={handleChange}
                                                    value={formData.password || ''}
                                                />
                                                <Flex w={"100%"} justify={"center"}>
                                                    {passwordError && <FormHelperText fontSize={"xl"} color="red.500">{passwordError}</FormHelperText>}
                                                </Flex>
                                            </FormControl>
                                            <FormControl id="confirmPassword" isRequired isInvalid={!!passwordError}>
                                                <FormLabel color={"white"}>Confirm Password</FormLabel>
                                                <Input
                                                    bg={"white"}
                                                    name="confirmPassword"
                                                    placeholder="Confirm your password"
                                                    onChange={handleChange}
                                                    value={formData.confirmPassword || ''}
                                                />
                                            </FormControl>
                                        </>
                                    )}
                                </Box>
                            </>
                        )}
                        {step > 0 && renderForm()}
                        <HStack w={"100%"} justify={"space-between"}>
                            {canGoBack() && (
                                <Button mt={4} onClick={() => setStep(step - 1)}>
                                    Back
                                </Button>
                            )}

                            {!canGoBack() && (
                                <Flex></Flex>
                            )}

                            {canGoNext() && verificationSuccess && !passwordError && (
                                <Button bg={"black"}
                                    color={"white"}
                                    border={"2px"}
                                    borderColor={"white"}
                                    _hover={{ bg: "#01BF02", border: "0px" }} mt={4} onClick={() => setStep(step + 1)}>
                                    Next
                                </Button>
                            )}

                            {role && !canGoNext() && verificationSuccess && (
                                <Button bg={"black"}
                                    color={"white"}
                                    border={"2px"}
                                    borderColor={"white"}
                                    _hover={{ bg: "#01BF02", border: "0px" }} mt={4} onClick={handleSubmit}>
                                    Submit
                                </Button>
                            )}

                            {/* If you want to show a disabled button when email is not verified */}
                            {role && !canGoNext() && !verificationSuccess && (
                                <Button bg={"black"}
                                    color={"white"}
                                    border={"2px"}
                                    borderColor={"white"}
                                    _hover={{ bg: "#01BF02", border: "0px" }} mt={4} isDisabled>
                                    Submit
                                </Button>
                            )}
                        </HStack>
                        <Box mt={2} textAlign={"center"} w={"100%"} >
                            <Text color={"white"} fontWeight={"semibold"} >Already have an account?
                                <ChakraLink ml={2} onClick={goToLogin} color="#01bf02">
                                    Login
                                </ChakraLink>
                            </Text>
                        </Box>
                    </Box>
                </VStack>
            </HStack>
        </Flex>
    );
};

export default SignUp;