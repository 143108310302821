import React from "react";
import { Text, Flex, Box } from "@chakra-ui/react";
import 'react-multi-carousel/lib/styles.css';
import Header from "../../components/Header";

const PrivacyPolicy = () => {
    return (
        <Flex p={4} bg="black" minH={"100vh"} justify="center" w="100%">
            <Flex borderRadius="2xl" bg="white" h={"fit-content"} direction="column" w="60%" mt={4} p={5}>
                <Header />
                <Box>
                    <Text fontSize="xl" fontWeight="bold">JobJar.Ai Privacy Policy</Text>
                    <Text>Last Updated: February 1st, 2024</Text>

                    <Text mt={4}>
                        <strong>1. Introduction</strong><br />
                        Welcome to JobJar.Ai. We respect your privacy and are committed to protecting your personal data. This privacy policy will inform you about how we look after your personal data when you visit our website (regardless of where you visit it from) and tell you about your privacy rights and how the law protects you.
                    </Text>

                    <Text mt={4}>
                        <strong>2. Data We Collect</strong><br />
                        We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:<br />
                        - <strong>Identity Data</strong>: includes first name, last name, username or similar identifier.<br />
                        - <strong>Contact Data</strong>: includes email address.<br />
                        - <strong>Technical Data</strong>: includes internet protocol (IP) address, your login data, browser type and version.<br />
                        - <strong>Usage Data</strong>: includes information about how you use our website and services.<br />
                        - <strong>Job Data</strong>: includes job titles, company names, and job locations that you save using our browser extension.
                    </Text>

                    <Text mt={4}>
                        <strong>3. How We Use Your Data</strong><br />
                        We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:<br />
                        - To provide and maintain our service.<br />
                        - To notify you about changes to our service.<br />
                        - To provide customer support.<br />
                        - To gather analysis or valuable information so that we can improve our service.<br />
                        - To monitor the usage of our service.
                    </Text>

                    <Text mt={4}>
                        <strong>4. Data Security</strong><br />
                        We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorized way, altered or disclosed.
                    </Text>

                    <Text mt={4}>
                        <strong>5. Data Retention</strong><br />
                        We will only retain your personal data for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.
                    </Text>

                    <Text mt={4}>
                        <strong>6. Your Legal Rights</strong><br />
                        Under certain circumstances, you have rights under data protection laws in relation to your personal data, including the right to request access, correction, erasure, restriction, transfer, or to withdraw consent.
                    </Text>

                    <Text mt={4}>
                        <strong>7. Third-Party Links</strong><br />
                        Our website may include links to third-party websites, plug-ins and applications. Clicking on those links may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements.
                    </Text>

                    <Text mt={4}>
                        <strong>8. Contact Us</strong><br />
                        If you have any questions about this privacy policy, please contact us at support@jobjar.ai.
                    </Text>
                </Box>
            </Flex>
        </Flex>
    );
};

export default PrivacyPolicy;
