import React from "react";
import { Text, Flex, Box } from "@chakra-ui/react";
import 'react-multi-carousel/lib/styles.css';
import Header from "../../components/Header";

const TermsOfService = () => {
    return (
        <Flex p={4} bg="black" minH={"100vh"} justify="center" w="100%">
            <Flex borderRadius="2xl" bg="white" h={"fit-content"} direction="column" w="60%" mt={4} p={5}>
                <Header />
                <Box>
                    <Text fontSize="xl" fontWeight="bold">JobJar.Ai Terms of Service</Text>
                    <Text>Last Updated: February 1st, 2024</Text>

                    <Text mt={4}>
                        <strong>1. Introduction</strong><br />
                        Welcome to JobJar.Ai. These terms of service outline the rules and regulations for the use of JobJar.Ai's Website and Services.
                    </Text>

                    <Text mt={4}>
                        <strong>2. Acceptance of Terms</strong><br />
                        By accessing this website we assume you accept these terms of service in full. Do not continue to use JobJar.Ai's website if you do not accept all of the terms of service stated on this page.
                    </Text>

                    <Text mt={4}>
                        <strong>3. Modifications to the Service and Prices</strong><br />
                        Prices for our products are subject to change without notice. We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.
                    </Text>

                    <Text mt={4}>
                        <strong>4. User Registration</strong><br />
                        You may be required to register with the Site. You agree to keep your password confidential and will be responsible for all use of your account and password.
                    </Text>

                    <Text mt={4}>
                        <strong>5. Intellectual Property</strong><br />
                        This Agreement does not transfer from JobJar.Ai to you any JobJar.Ai or third party intellectual property, and all right, title, and interest in and to such property will remain (as between the parties) solely with JobJar.Ai.
                    </Text>

                    <Text mt={4}>
                        <strong>6. User Comments, Feedback, and Other Submissions</strong><br />
                        If you send certain specific submissions (for example contest entries) or without a request from us you send creative ideas, suggestions, proposals, plans, or other materials, you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us.
                    </Text>

                    <Text mt={4}>
                        <strong>7. Third-Party Links & Ads</strong><br />
                        JobJar.Ai may contain links to third-party websites and services, and/or display advertisements for third parties. Such Third-Party Links & Ads are not under the control of JobJar.Ai, and JobJar.Ai is not responsible for any Third-Party Links & Ads.
                    </Text>

                    <Text mt={4}>
                        <strong>8. Contact Us</strong><br />
                        If you have any questions about these Terms of Service, please contact us at support@jobjar.ai.
                    </Text>
                </Box>
            </Flex>
        </Flex>
    );
};

export default TermsOfService;