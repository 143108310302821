import React from 'react';
import { Box, Container, Heading, Text, Button, Link } from '@chakra-ui/react';
import video from '../../assets/tech3.mp4';

const TokenInfo = () => {
  return (
    <Box position="relative" minHeight="100vh">
      <video autoPlay loop muted style={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute', zIndex: -1 }}>
        <source src={video} type="video/mp4" />
      </video>
      <Container position="absolute" top="0" maxW="container.xl" height="100%" display="flex" flexDirection="column" justifyContent="center">
        <Box padding="4" bg="rgba(255, 255, 255, 0.8)" borderRadius="lg" boxShadow="lg" maxWidth="800px" margin="auto" p={8}>
          <Heading as="h1" size="2xl" textAlign="center" marginBottom="5" color="teal.800">
            JobJar Token ($JOB)
          </Heading>
          <Text fontSize="xl" textAlign="justify" marginBottom="5" color="gray.700">
            Welcome to the official page of JobJar Token ($JOB)! Our token is designed to empower our real business, JobJar.Ai, which is an AI-assisted career growth platform. By integrating blockchain technology, we aim to revolutionize how individuals manage their career growth with transparency and efficiency.
          </Text>
          <Text fontSize="xl" textAlign="justify" marginBottom="5" color="gray.700">
            Stay tuned for more updates and how you can be a part of our journey in making career growth accessible to everyone.
          </Text>
          <Button colorScheme="teal" size="lg">
            <Link href="https://jobjar.ai" isExternal>
              Learn More
            </Link>
          </Button>
        </Box>
      </Container>
    </Box>
  );
}; 

export default TokenInfo;
